const selectStyle = {
  menu: (provided, state) => ({
    ...provided,
    color: state.selectProps.menuColor,
    padding: 20
  }),
  //Select style
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: '2px solid #707070',
    // This line disable the blue border
    boxShadow: state.isFocused ? '#707070' : 0,
    '&:hover': {
      borderBottom: '2px solid #707070'
    },
    borderRadius: '0px'
  }),
  //Options style
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected
      ? '#ffffff'
      : state.isFocused
      ? '#ffffff'
      : '#6b6e6f',
    backgroundColor: state.isSelected
      ? '#f36f39'
      : state.isFocused
      ? '#cbcbcb'
      : undefined
  }),
  input: (provided) => ({
    ...provided,
    color: '#cbcbcb',
    caretColor: '#cbcbcb'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#cbcbcb',
    opacity: '0.5'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#cbcbcb'
  })
}

export { selectStyle }
