import React, { useContext } from 'react'
import './styles/style.css'

import { LanguageContext } from '../../context/language/context'
import Typography from '../Typography'

export default function NoNotification() {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  return (
    <div className='dc-notification-item-container'>
      <Typography variant='body' align='center'>
        {lang.notFound}
      </Typography>
    </div>
  )
}
