import React from 'react'

import Card from '../../components/Card'
import StandardView from '../../layouts/StandardView'
import Typography from '../../components/Typography'
import Divider from '../../components/Divider'
import Button from '../../components/Button'
import Avatar from '../../components/Avatar'
import { IconButton } from '../../components/IconButton'

export default function TestView() {
  // const { id } = useParams();

  // const languageContext = useContext(LanguageContext);
  // const { toggleLoader, enqueueNotification, loader } = useContext(
  // 	_Utils.Context
  // );
  // const { token } = useContext(_Auth.Context);

  // var lang;
  // if (languageContext.userLanguage === "en") {
  // 	lang = require("./languages/en.json");
  // } else {
  // 	lang = require("./languages/es.json");
  // }
  // const [loading, setLoading] = useState(true)
  // const [stages, setStages] = useState([])
  // const history = useHistory();

  const loremIpsum =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut quis egestas felis. Curabitur condimentum ipsum eu tortor imperdiet eleifend. Donec id euismod tortor. In hac habitasse platea dictumst. Fusce iaculis tempus aliquet. Curabitur pretium vulputate nisl, et sollicitudin est vehicula vitae. Phasellus convallis ut risus in ultricies. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus nec diam semper, volutpat dolor sed, convallis nunc. Vestibulum tortor velit, gravida id diam vel, egestas tempus est. Maecenas mollis sed sem ac aliquam. Pellentesque eu semper leo. Pellentesque commodo volutpat quam. Nulla metus lacus, lobortis a dapibus non, porta ac purus. Vestibulum nec iaculis arcu, at dignissim velit.\n\nSed nunc tortor, auctor ac commodo sit amet, cursus nec nisl. Suspendisse a nibh ut felis pellentesque convallis. Sed id laoreet tellus, eget lacinia nunc. Mauris dictum placerat est non venenatis. Cras faucibus ipsum in mattis tristique. Maecenas finibus, risus in ultrices mattis, nunc lorem lacinia dui, viverra faucibus urna est quis nibh. Proin iaculis sapien quis nisi fermentum blandit. Quisque at justo ipsum. Nunc tellus nulla, tempor sit amet eleifend ac, tempus nec massa. Ut at felis laoreet, sagittis sapien ac, porta metus. Donec eget sem id metus dignissim ultrices eu ut nisl. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam suscipit in dui imperdiet sodales. Morbi ornare rutrum bibendum.'
  return (
    <StandardView title='COMPONENTS'>
      <Button>boton naranja</Button>
      <Button color='secondary'>boton amarillo</Button>
      <Button color='dark'>boton negro</Button>
      <Button color='grey'>boton gris</Button>

      <Button variant='underlined'>boton naranja</Button>
      <Button variant='underlined' color='secondary'>
        boton amarillo
      </Button>
      <Button variant='underlined' color='dark'>
        boton negro
      </Button>
      <Button variant='underlined' color='grey'>
        boton gris
      </Button>

      <Button variant='outlined'>boton naranja</Button>
      <Button variant='outlined' color='secondary'>
        boton amarillo
      </Button>
      <Button variant='outlined' color='dark'>
        boton negro
      </Button>
      <Button variant='outlined' color='grey'>
        boton gris
      </Button>

      {/* TEST VIEW */}
      <Card className='uk-margin-medium-bottom'>
        <StandardView title='TEST VIEW'>
          <div className='uk-flex uk-flex-between'>
            <Typography variant='h6' color='secondary'>
              Reporte
            </Typography>

            <IconButton icon='edit' />
          </div>
          <Typography variant='subtitle'>17/08/2021</Typography>
          <Typography variant='body' className='uk-margin-medium-bottom'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis id
            convallis libero. Integer nec accumsan dui. Praesent tincidunt
            pretium erat, sit amet cursus justo vestibulum eu. Aenean interdum
            rutrum sapien. Aenean hendrerit orci eros, eu malesuada est
            imperdiet non. Duis risus ante, egestas bibendum eleifend in,
            sodales quis ante. Quisque elementum non est sit amet tempor. Aenean
            ut lectus eleifend, pulvinar ligula at, aliquam lectus. Pellentesque
            neque eros, mollis dignissim magna non, convallis pretium erat.
            Nulla pellentesque purus dolor, sed lacinia lorem condimentum
            sodales. Aliquam semper lobortis nisl id laoreet. Integer egestas
            sapien non turpis varius, quis auctor diam aliquam. Quisque
            tincidunt a metus venenatis luctus.
            <br />
            <br />
            Maecenas pharetra, tellus ac tristique venenatis, massa augue
            hendrerit velit, quis consectetur lectus sem a sem. Mauris finibus,
            arcu in aliquam facilisis, turpis nisl ullamcorper lorem, id
            lobortis erat odio eget odio. Donec congue, nibh eu pulvinar
            fringilla, felis augue aliquet lorem, ut pharetra turpis felis eget
            dui. Ut pharetra felis viverra, maximus massa quis, vestibulum est.
            In a ante metus. Curabitur at velit ac nibh aliquet varius. Duis et
            libero euismod odio malesuada eleifend. Aenean at vulputate urna,
            quis euismod sem.
            <br />
            <br />
            Mauris ac lorem non ipsum volutpat posuere. Vivamus vel interdum
            nisl, a suscipit diam. Suspendisse a massa in justo molestie
            posuere. Vestibulum faucibus diam vitae dui placerat, eu elementum
            est mollis. Donec vitae nisi vulputate, maximus ligula in, euismod
            quam. Phasellus molestie ante augue, vel posuere turpis placerat
            non. Aliquam neque nunc, eleifend quis neque at, pellentesque
            sollicitudin dui. Nullam imperdiet nulla.
          </Typography>

          <Divider className='uk-margin-medium-bottom' />
        </StandardView>
      </Card>

      {/* ***************************************** TYPOGRAPHY ***************************************** */}
      <Typography variant='h6'>Typography</Typography>

      {/* VARIANT */}
      <Typography variant='subtitle'>Variant:</Typography>

      <Card className='uk-margin-medium-bottom uk-padding-large'>
        <Typography variant='h1'>h1. Heading</Typography>
        <Typography variant='h2'>h2. heading</Typography>
        <Typography variant='h3'>h3. heading</Typography>
        <Typography variant='h4'>h4. heading</Typography>
        <Typography variant='h5'>h5. heading</Typography>
        <Typography variant='h6'>h6. heading</Typography>
        <Typography variant='subtitle'>
          subtitle. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Quos blanditiis tenetur
        </Typography>
        <Typography variant='subtitle2'>
          subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Quos blanditiis tenetur
        </Typography>
        <Typography variant='body'>
          body. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore
          consectetur, neque doloribus, cupiditate numquam dignissimos laborum
          fugiat deleniti? Eum quasi quidem quibusdam.
        </Typography>
        <Typography variant='body2'>
          body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore
          consectetur, neque doloribus, cupiditate numquam dignissimos laborum
          fugiat deleniti? Eum quasi quidem quibusdam.
        </Typography>
      </Card>

      {/* COLOR */}
      <Typography variant='subtitle'>Color:</Typography>

      <Card className='uk-margin-medium-bottom'>
        <Typography variant='h6' color='primary'>
          primary
        </Typography>
        <Typography variant='h6' color='secondary'>
          Secondary
        </Typography>
      </Card>

      {/* ALIGN */}
      <Typography variant='subtitle'>Align:</Typography>

      <Card className='uk-margin-medium-bottom'>
        <Typography variant='h6'>Center:</Typography>
        <Typography align='center'>{loremIpsum}</Typography>

        <Typography variant='h6'>Inherit:</Typography>
        <Typography align='inherit'>{loremIpsum}</Typography>
        <Typography variant='h6'>Justify:</Typography>
        <Typography align='justify'>{loremIpsum}</Typography>
        <Typography variant='h6'>Left:</Typography>
        <Typography align='left'>{loremIpsum}</Typography>
        <Typography variant='h6'>Right:</Typography>
        <Typography align='right'>{loremIpsum}</Typography>
      </Card>

      <Divider className='uk-margin-medium-bottom' />

      {/* ***************************************** IconButton ***************************************** */}
      <Typography variant='h6'>IconButton</Typography>

      {/* VARIANT */}
      <Typography variant='subtitle'>Variant:</Typography>

      <Card className='uk-margin-medium-bottom'>
        <Typography variant='subtitle2'>Outlined:</Typography>

        <IconButton icon='edit' />

        <Typography variant='subtitle2'>Filled:</Typography>

        <IconButton icon='edit' variant='filled' />

        <Typography variant='subtitle2'>Borderless:</Typography>

        <IconButton icon='edit' variant='borderless' />
      </Card>

      {/* COLOR */}
      <Typography variant='subtitle'>Color:</Typography>

      <Card className='uk-margin-medium-bottom'>
        <Typography variant='subtitle2'>Grey:</Typography>

        <div className='uk-flex uk-flex-between'>
          <IconButton icon='edit' color='grey' />
          <IconButton icon='edit' variant='filled' color='grey' />
          <IconButton icon='edit' variant='borderless' color='grey' />
        </div>

        <Typography variant='subtitle2'>Dark:</Typography>

        <div className='uk-flex uk-flex-between'>
          <IconButton icon='edit' color='dark' />
          <IconButton icon='edit' variant='filled' color='dark' />
          <IconButton icon='edit' variant='borderless' color='dark' />
        </div>

        <Typography variant='subtitle2'>Primary:</Typography>

        <div className='uk-flex uk-flex-between'>
          <IconButton icon='edit' color='light' />
          <IconButton icon='edit' variant='filled' color='light' />
          <IconButton icon='edit' variant='borderless' color='light' />
        </div>

        <Typography variant='subtitle2'>Secondary:</Typography>

        <div className='uk-flex uk-flex-between'>
          <IconButton icon='edit' color='secondary' />
          <IconButton icon='edit' variant='filled' color='secondary' />
          <IconButton icon='edit' variant='borderless' color='secondary' />
        </div>
      </Card>

      <Card>
        <IconButton size='x-small' icon='create' />
        <IconButton size='small' icon='create' />
        <IconButton size='large' icon='create' />
      </Card>

      {/* SHAPE */}
      <Typography variant='subtitle'>Shape:</Typography>

      <Card className='uk-margin-medium-bottom'>
        <Typography variant='subtitle2'>Round:</Typography>

        <div className='uk-flex uk-flex-between'>
          <IconButton icon='edit' />
          <IconButton icon='edit' variant='filled' />
        </div>

        <Typography variant='subtitle2'>Square:</Typography>

        <div className='uk-flex uk-flex-between'>
          <IconButton icon='edit' shape='square' />
          <IconButton icon='edit' shape='square' variant='filled' />
        </div>
      </Card>

      <Divider className='uk-margin-medium-bottom' />

      {/* ***************************************** Button ***************************************** */}
      <Typography variant='h6'>Button</Typography>

      <Button variant='underlined'>CALENDARIO</Button>

      <Button variant='underlined' color='secondary'>
        REPORTES
      </Button>

      <Button variant='underlined' color='grey'>
        PAGOS
      </Button>

      <Divider className='uk-margin-medium-bottom' />

      {/* ***************************************** Button ***************************************** */}
      <Typography variant='h6'>Card</Typography>

      {/* VARIANT */}
      <Typography variant='subtitle'>Variant:</Typography>

      <Card variant='filled' className='uk-margin-medium-bottom' />

      <Card variant='outlined' className='uk-margin-medium-bottom' />

      <Card variant='borderless' className='uk-margin-medium-bottom' />

      {/* COLOR */}
      <Typography variant='subtitle'>Color:</Typography>

      <Card
        variant='filled'
        color='secondary'
        className='uk-margin-medium-bottom'
      />

      <Card variant='filled' color='grey' className='uk-margin-medium-bottom' />

      <Card variant='filled' color='dark' className='uk-margin-medium-bottom' />

      <Card
        variant='outlined'
        color='dark'
        hover
        hoverVariant='filled'
        hoverColor='dark'
        className='uk-margin-medium-bottom'
      />

      {/* ***************************************** Button ***************************************** */}
      <Typography variant='h6'>Avatar</Typography>

      <Avatar alt={'Oscar Waii'} />
      <Avatar size={'medium'} alt={'Oscar Waii'} />
      <Avatar size={'large'} alt={'Oscar Waii'} />
      <Avatar size={'x-large'} alt={'Oscar Waii'} />

      <Avatar
        src={
          'https://wl-genial.cf.tsp.li/resize/728x/jpg/91b/430/964a9c5ac9933cc012d0bd80be.jpg'
        }
        alt={'Oscar Waii'}
      />
      <Avatar
        src={
          'https://wl-genial.cf.tsp.li/resize/728x/jpg/91b/430/964a9c5ac9933cc012d0bd80be.jpg'
        }
        size={'medium'}
        alt={'Oscar Waii'}
      />
      <Avatar
        src={
          'https://wl-genial.cf.tsp.li/resize/728x/jpg/91b/430/964a9c5ac9933cc012d0bd80be.jpg'
        }
        size={'large'}
        alt={'Oscar Waii'}
      />
      <Avatar
        src={
          'https://wl-genial.cf.tsp.li/resize/728x/jpg/91b/430/964a9c5ac9933cc012d0bd80be.jpg'
        }
        size={'x-large'}
        alt={'Oscar Waii'}
      />

      <Avatar width={300} height={300} fontSize={200} alt={'Oscar Waii'} />

      <Avatar
        width={300}
        height={300}
        src={
          'https://wl-genial.cf.tsp.li/resize/728x/jpg/91b/430/964a9c5ac9933cc012d0bd80be.jpg'
        }
        alt={'Oscar Waii'}
      />

      <Avatar border size={'x-large'} alt={'Oscar Waii'} />

      <Avatar
        border
        src={
          'https://wl-genial.cf.tsp.li/resize/728x/jpg/91b/430/964a9c5ac9933cc012d0bd80be.jpg'
        }
        alt={'Oscar Waii'}
      />
    </StandardView>
  )
}
