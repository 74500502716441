import React, { useContext, useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import searcWhite from './assets/search-white.svg'

import { useForm } from 'react-hook-form'
import debounce from 'lodash.debounce'

import SearchBarInput from '../../components/SearchBarInput'
import { LanguageContext } from '../../context/language/context'

export default function SearchBar() {
  const { theme } = useContext(LanguageContext)
  const languageContext = useContext(LanguageContext)

  const [value, setValue] = useState()

  const history = useHistory()
  const location = useLocation()

  function onSubmitSearch() {
    const value = getValuesSearch('search')
    if (value && value.label) {
      history.push('/Search/' + value.label)
    }
  }

  const {
    control: controlSearch,
    setValue: setValueSearch,
    getValues: getValuesSearch
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      search: null
    }
  })

  const filterOptions = (array, inputValue) => {
    return array.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    )
  }

  const inputValueFormatter = (value) => {
    //trim and capitalize
    return value.replace(/\w\S*/g, (w) =>
      w.replace(/^\w/, (c) => c.toUpperCase())
    )
  }

  const getSearch = (inputValue, callback) => {
    if (!inputValue) {
      return callback([])
    }

    try {
      setValue(inputValue.trim().toLowerCase())
      fetch(
        process.env.REACT_APP_API_URL +
          'search/getRecommendations?' +
          new URLSearchParams({
            search: value
          }),
        {
          method: 'GET'
        }
      ).then((response) => {
        response.json().then((data) => {
          try {
            var results = []
            data?.Projects.forEach((result) => {
              results.push({
                value: result.title,
                label: inputValueFormatter(result.title),
                id: result.id
              })
            })
            data?.Albums.forEach((result) => {
              results.push({
                value: result.title,
                label: inputValueFormatter(result.title),
                id: result.id
              })
            })
            data?.Users.forEach((result) => {
              results.push({
                value: result.name,
                label: inputValueFormatter(result.name),
                id: result.id
              })
            })
            callback(filterOptions(results, inputValue))
          } catch (error) {
            return callback([])
          }
        })
      })
    } catch (error) {
      return callback([])
    }
  }

  const loadSearch = debounce(getSearch, 500)

  return (
    <div
      className='uk-grid'
      style={{ display: 'flex', width: '350px', marginTop: '20px' }}
    >
      <div className='uk-width-4-5'>
        <SearchBarInput
          placeholder={languageContext.dictionary.search}
          name='search'
          control={controlSearch}
          isAsync
          isClearable
          loadOptions={loadSearch}
          onChange={(option) => {
            loadSearch.cancel()
            setValueSearch('search', option)
            onSubmitSearch()
          }}
          onInputChange={(newValue) => {
            setValueSearch('search', newValue)
          }}
          useCustomOnChange
          formatCreateLabel={(iValue) => {
            return (
              <div>
                {languageContext.dictionary.searchOption} &quot;
                {inputValueFormatter(iValue)}&quot;
              </div>
            )
          }}
          blurInputOnSelect={false}
          noOptionsMessage={() => null}
          theme={location?.pathname === '/' ? 'dark' : 'default'}
        />
      </div>

      <div className='uk-width-1-5'>
        {theme === 'light' && location?.pathname !== '/' ? (
          <img
            src={searcWhite}
            style={{ height: 22, filter: 'invert(100%)' }}
            onClick={onSubmitSearch}
          />
        ) : (
          <img
            src={searcWhite}
            style={{ height: 22 }}
            onClick={onSubmitSearch}
          />
        )}
      </div>
    </div>
  )
}
