import React, { useContext, useState, useEffect } from 'react'

import './styles/style.css'

import { LanguageContext } from '../../context/language/context'
import { useLocation } from 'react-router'

export default function TermsAndConds() {
  const [privacity, setPrivacity] = useState(false)
  const data = useLocation()
  useEffect(() => {
    if (data.state && data.state.privacity) {
      setPrivacity(data.state.privacity)
    }
  }, [])

  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  return (
    <div className='uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-padding-large uk-padding-remove-left uk-padding-remove-right leo-terms-back'>
      <div className='diinco-tyc-card'>
        <ul
          className='uk-tab uk-child-width-expand'
          data-uk-tab="{connect:'#my-tab-id'}"
        >
          <li className={!privacity ? 'uk-active' : ''}>
            <a href='#' className='leo-tab'>
              {lang['terms']}
            </a>
          </li>
          <li className={privacity ? 'uk-active' : ''}>
            <a href='#' className='leo-tab'>
              {lang['privacity']}
            </a>
          </li>
          <li>
            <a href='#' className='leo-tab'>
              {lang['cookies']}
            </a>
          </li>
        </ul>
        <ul id='my-tab-id' className='uk-switcher'>
          <li className={!privacity ? 'uk-active' : ''}>
            <div className='diinco-tyc-card-title'>{lang['terms']}</div>
            <div className='diinco-tyc-card-text'>
              <h5 style={{ color: 'white' }}>I. CONDICIONES GENERALES.</h5>
              El presente contrato mercantil (el “Contrato”) establece los
              términos y condiciones generales (los “Términos y Condiciones”)
              que serán aplicables a los usuarios que tengan acceso y hagan uso
              del sitio de internet localizado en la dirección electrónica URL
              https://diinco.com/ (el “Sitio Web”) cuya titularidad corresponde
              a Diinco Tecnología De México, S.A. de C.V. y a sus empresas
              matrices, controladoras, afiliadas y subsidiarias (referidas
              conjuntamente como “Diinco”), y cualesquier portales, desarrollos
              y/o programas adicionales o auxiliares que, directa o
              indirectamente, utilice Diinco para prestar sus servicios
              (referidos todos ellos junto conjuntamente con el Sitio Web como
              los “Medios Digitales”). En el presente instrumento, Diinco podrá
              ser referida, indistintamente, como la “Empresa” o “nosotros”;
              asimismo, las frases “nuestro” o “de nosotros” se entenderán
              referidas a Diinco. Los Términos y Condiciones también podrán ser
              referidos, indistintamente, como el Contrato, el “presente
              documento” o el “presente instrumento”.
              <br />
              <br />
              Los presentes Términos y Condiciones se encuentran dirigidos a los
              clientes y/o usuarios del Sitio Web, y les resultan aplicables y
              vinculantes en todo momento que tengan la calidad de usuarios y se
              encuentran dirigidos a los usuarios que se encuentren en el
              territorio de los Estados Unidos Mexicanos (“México”). Serán
              referidos como “Usuarios” todas las personas físicas que se
              registren y posteriormente, accedan o utilicen el Sitio Web, por
              cualquier dispositivo u ordenador.
              <br />
              <br />
              En particular, se identificará como Usuarios a aquellas personas
              físicas que cumplan con cualquiera de las circunstancias: (i)
              acceda a los Medios Digitales; y/o (ii) haga uso de los servicios
              disponibles en los Medios Digitales. Los presentes Términos y
              Condiciones están destinados y dirigidos a regular las condiciones
              de registro, afiliación, acceso, uso y aprovechamiento de los
              Medios Digitales, así como las previsiones aplicables a los
              servicios que celebren los Usuarios con la Empresa (conjuntamente,
              los “Servicios”).
              <br />
              <br />
              <h5 style={{ color: 'white' }}>II. ACEPTACIÓN EXPRESA.</h5>
              De forma previa al uso de los Medios Digitales, los presentes
              Términos y Condiciones serán desplegados frente al Usuario para su
              aceptación expresa, misma que se realizará por medios electrónicos
              y mediante el llenado y/o el marcado de casillas, opciones o
              botones de aceptación expresamente habilitados en los Medios
              Digitales para tal efecto, lo anterior con fundamento en la
              segunda fracción del Artículo 1803 del Código Civil Federal y sus
              correlativos y aplicables de los Códigos Civiles de las Entidades
              Federativas de la República Mexicana.
              <br />
              <br />
              Los Usuarios declaran y garantizan que, previamente al uso directo
              o indirecto de cualquiera de los Medios Digitales, han aceptado
              expresamente todas y cada una de las disposiciones contenidas y
              previstas en los presentes Términos y Condiciones, así como la
              sujeción correspondiente a los mismos, en términos del párrafo que
              antecede. En caso de que la Empresa no tenga constancia de la
              previa aceptación de algún Usuario de lo previsto en el presente
              instrumento, no se habilitarán los Servicios.
              <br />
              <br />
              Para el supuesto de que los Usuarios por cualquier motivo no
              deseen obligarse en los exactos Términos y Condiciones
              establecidos en el presente Contrato, o de no estar conformes con
              la totalidad o una parte de las previsiones aquí contenidas,
              deberán abstenerse de realizar cualquier registro y de utilizar
              los Medios Digitales y en su caso, de utilizar los Servicios o de
              celebrar alguna Transacción, además de abandonar inmediatamente
              cualquier Medio Digital que se encuentren utilizando o que hayan
              utilizado o donde se hayan registrado. La Empresa garantizará el
              derecho de los Usuarios de revocar su consentimiento respecto de
              la aceptación del presente instrumento, en cuyo caso bastará con
              que el propio Usuario lo comunique al Centro de Atención y Soporte
              de la Empresa.
              <br />
              <br />
              <h5 style={{ color: 'white' }}>III. DEFINICIONES.</h5>
              Para mejor claridad de lo expresado en el presente instrumento,
              utilizaremos términos definidos. Para efectos de los presentes
              Términos y Condiciones, los términos que aparezcan definidos en
              mayúsculas y que no hayan sido definidos de forma distinta en
              diversas secciones del presente documento, tendrán el significado
              que a continuación se les atribuye:
              <br />
              <br />
              <strong>“Agregador Bancario”</strong> se refiere a la empresa
              especializada que ha sido contratada por Diinco para procesar los
              pagos realizados con tarjeta bancaria que realizan los Usuarios.
              <br />
              <br />
              <strong>“Código Promocional”</strong> se refiere a las claves
              alfanuméricas que la Empresa podrá difundir a través de
              cualesquier medios publicitarios para otorgar descuentos a los
              Usuarios.
              <br />
              <br />
              <strong>“Contraprestación”</strong> se refiere al importe,
              expresado en pesos mexicanos (MXN), que será sufragado por el
              Usuario y en favor de la Empresa por los Servicios, mismo que será
              el resultado de la operación aritmética de sumar los Precios del o
              de los Productos seleccionados al momento de que el Usuario elija
              la opción de pago. En su caso, al resultado de la operación
              anterior se le restará el importe que ampare algún Código
              Promocional válidamente ingresado por el Usuario.
              <br />
              <br />
              <strong>“Medio de Pago”</strong> se refiere a la tarjeta de
              crédito, tarjeta de débito, tarjeta de servicios, o cualquier otra
              modalidad de pago electrónico aceptada en el Sitio Web o por
              cualquier Agregador Bancario, misma que será utilizada por los
              Usuarios para pagar la Contraprestación. Dicho Medio de Pago será
              registrado de forma segura para pagar futuros Servicios.
              <br />
              <br />
              <strong>“Precios”</strong> se refiere a los importes unitarios
              fijados por cada Producto, mismos serán desplegados en los Medios
              Digitales, y podrán sufrir cambios o modificaciones, de tiempo en
              tiempo. Salvo que se indique expresamente lo contrario, los
              precios de los Productos incluirán el Impuesto al Valor Agregado
              (IVA), cuyo importe será expresamente desglosado en el Recibo y en
              la factura fiscal correspondientes.
              <br />
              <br />
              <strong>“Productos”</strong> se refiere a las membresías y/o
              licencias comercializadas través de los Medios Digitales,
              incluyendo de forma enunciativa pero no limitativa el acceso a la
              plataforma de Diinco y/o cualquier otro que sea publicitado por la
              Empresa en los Medios Digitales.
              <br />
              <br />
              <strong>“Recibo”</strong> se refiere al comprobante virtual que
              genere el Sitio Web por cada Servicio contratado.
              <br />
              <br />
              Los términos definidos anteriormente podrán ser utilizados a lo
              largo del presente documento, indistintamente, en singular o en
              plural; en masculino, en femenino o en neutro; sin que dicha
              utilización modifique su significado. En caso de que a los
              términos definidos en la presente sección se les haya atribuido un
              significado explicativo en otras secciones del presente documento,
              ambos significados se leerán e interpretarán conjuntamente. Los
              términos definidos exclusivamente en diversas secciones del
              presente instrumento tendrán los significados que ahí se les
              atribuye.
              <br />
              <br />
              <h5 style={{ color: 'white' }}>
                IV. ACCESO Y USO DE LOS MEDIOS DIGITALES.
              </h5>
              Para poder recibir los Servicios, los Usuarios deberán registrarse
              en el Sitio Web, con el propósito de obtener una cuenta y una
              contraseña de acceso (la “Cuenta de Usuario”) o bien registrarse
              temporalmente como invitado. Los Usuarios deberán proporcionar la
              información personal correspondiente en todos los campos, con
              datos válidos, precisos y veraces. Los Usuarios están obligados a
              mantener la antedicha información personal actualizada en todo
              momento. Diinco se reserva el derecho de solicitar, en cualquier
              momento, determinada información o documentación a los Usuarios
              con el propósito de verificar la veracidad de la información
              proporcionada, así como de suspender temporalmente las Cuentas de
              Usuario cuya información no haya podido ser corroborada, o
              aquéllas cuentas que se estimen han sido creadas con propósitos
              fraudulentos, quedando obligada la Empresa a conservar el respaldo
              documental o instrumental necesario para sustentar cualquier
              reclamación respectiva. El proceso de creación de una Cuenta de
              Usuario es el siguiente:
              <br />
              <br />
              1. El Usuario deberá elegir la opción para registrarse, e ingresar
              los siguientes datos: (i) Nombre completo; (ii) Nombre de usuario;
              (iii) Correo Electronico; (iv) Número de teléfono; (v) Fecha de
              nacimiento; (vi) Contraseña. Sin ser óbice lo anterior, Diinco
              podrá habilitar el acceso vinculado a través de cuentas de redes
              sociales o por medio de Gmail® en cuyo caso, el Usuario será
              responsable de la autenticidad de las cuentas asociadas a dichos
              accesos vinculados.
              <br />
              <br />
              2. Posteriormente, el Usuario deberá ingresar los siguientes
              datos: (i) Ocupación; (ii) Profesión; (iii) Especialidad,
              Universidad en la que se estudió; (iv) Carrera que se estudió; (v)
              Curriculum en pdf; (vi) Foto de perfil; (vii) Foto de portada;
              (viii) Link de video de youtube; (ix) Ciudad; (x) Tags; (xi) País;
              (xii) Estado; (xiii) Ciudad; (xiv) Nombre de empresa; (xv)
              Domicilio completo.
              <br />
              <br />
              2. Posteriormente, el Usuario deberá ingresar los siguientes datos
              para crear un proyecto: (i) Titulo del proyecto; (ii) Domicilio
              completo del proyecto; (iii) Fecha de inicio y terminación; (iv)
              Servicio asociado al proyecto; (v) imágenes asociadas al proyecto;
              (vi) Imágenes (aplica para la galería del proyecto, álbum, notas,
              reportes, pagos); (vii) Aceptar términos y condiciones. (viii)
              Aceptar políticas de privacidad (ix) Datos de pago para las
              suscripciones, estas se hacen a través de stripe Inc. (Número de
              tarjeta, fecha de vencimiento, número de seguridad.
              <br />
              <br />
              El Usuario podrá optar por crear su cuenta a través de registro
              asociado por medio de las siguientes cuentas de redes sociales:
              (i) Facebook; y (ii) Google. En caso de elegir dicha opción, el
              Sitio Web le direccionará al portal de la página que haya
              seleccionado, es decir, Facebook o Google. Las referidas empresas
              no se encuentran relacionadas con Diinco, por lo que usted
              reconoce que es bajo su propia responsabilidad y riesgo el iniciar
              sesión a través de dichos medios.
              <br />
              <br />
              Listo. Tu Cuenta de Usuario habrá sido creada. Los Usuarios pueden
              modificar la información proporcionada al crear sus respectivas
              cuentas, siguiendo las instrucciones previstas en los Medios
              Digitales para tal propósito.
              <br />
              <br />
              Todos los Usuarios declaran que cuentan con capacidad para
              contratar, de acuerdo con la legislación aplicable en sus
              respectivos domicilios. Únicamente podrán solicitar la
              habilitación de una Cuenta de Usuario las personas que tengan
              mayoría de edad, es decir, dieciocho (18) años cumplidos al
              momento de registrarse en el Sitio Web. En caso de que una persona
              menor de edad se registre en el Sitio Web, las personas que tengan
              patria potestad, la tutela o la supervisión del menor serán
              solidariamente responsables por cualesquier daños y/o perjuicios
              que puedan ser ocasionados por el menor.
              <br />
              <br />
              Los Usuarios serán en todo momento responsables de la custodia de
              su contraseña, asumiendo en consecuencia, cualesquier daños y
              perjuicios que pudieran derivarse de su uso indebido, así como de
              la cesión, revelación o extravío de la misma. En caso de olvido de
              la contraseña o de cualquier otra circunstancia que suponga un
              riesgo de acceso y/o utilización por parte de terceros no
              autorizados, el Usuario lo comunicará inmediatamente al Centro de
              Atención y Soporte de la Empresa, a fin de que ésta proceda
              inmediatamente al bloqueo y sustitución de la misma. En todo caso,
              cualesquier operaciones realizadas antes de dicha comunicación se
              reputarán efectuadas por el Usuario.
              <br />
              <br />
              <h5 style={{ color: 'white' }}>V. TRANSACCIONES.</h5>
              La contratación de los Servicios de Diinco se sujetará a las
              siguientes previsiones:
              <br />
              <br />
              1. Los Usuarios podrán utilizar los Medios Digitales para
              seleccionar el o los Productos de su interés, con la
              especificación del producto elegido y de la vigencia del mismo. En
              cualquier momento, el Usuario podrá elegir realizar el pago del o
              de los Productos que se encuentren seleccionados, de tiempo en
              tiempo. Al momento de seleccionar la opción de pagar o cualquier
              otra semejante, se reputará realizada una transacción y, por ende
              contratado el Servicio. Por motivo de la celebración una
              transacción, el Usuario autoriza a la Empresa realizar el cargo de
              la Contraprestación sobre el Medio de Pago designado. El Usuario
              recibirá un Recibo por motivo de cada transacción, mismo de deberá
              descargar y conservar.
              <br />
              <br />
              2. Los Precios podrán ser sujetos de cambio de momento a momento,
              quedando bajo la responsabilidad del Usuario completar la
              transacción.
              <br />
              <br />
              Una vez completada la transacción:
              <br />
              <br />
              3. Las características y especificaciones de los Productos pueden
              cambiar sin previo aviso. Las fotografías corresponden a un
              producto similar representado únicamente de manera ilustrativa. En
              caso de resultar aplicable, se entregará un Manual de Operación.
              <br />
              <br />
              4. El Usuario autoriza a la Empresa para realizar un cargo
              temporal con el objetivo de validar la idoneidad del Medio de
              Pago. Todo cargo temporal será reembolsado una vez verificado lo
              anterior.
              <br />
              <br />
              5. Todos los Productos que se ofrecen en Diinco se encuentran
              sujetos a disponibilidad. En ciertos casos, habrá Productos que
              reporten una disponibilidad inmediata y otros que tengan uno o más
              días de espera para encontrarse sujetos a disposición del Usuario.
              <br />
              <br />
              6. Los Códigos Promocionales tendrán una vigencia máxima de uso
              hasta por treinta (30) días naturales desde el día en que hayan
              sido promocionadas al público en general, salvo que en el texto de
              la promoción se indique expresamente lo contrario. Asimismo, los
              Usuarios solo podrán aplicar un (1) Código Promocional por pedido,
              por lo que se encuentra estrictamente prohibido combinar Códigos
              Promocionales, incluyendo la aplicación del saldo con un Código
              Promocional. Los Códigos Promocionales podrán ser usados en una
              ocasión por cada domicilio registrado.
              <br />
              <br />
              <h5 style={{ color: 'white' }}>VI. ACCESO.</h5>
              El Acceso a los Productos quedará sujeta a la disponibilidad de
              los Productos. La Entrega se sujetará a lo siguiente:
              <br />
              <br />
              1. Una vez contratado un Producto a través de los Medios
              Digitales, el Usuario deberá indicar las personas que tendrán
              acceso al Producto contratado. Para ello deberá indicar los
              siguientes datos: (i) Nombre completo; (ii) correo electrónico;
              (iii) contraseña asignada; y (iv) nivel de acceso. Lo anterior
              dependerá exclusivamente del Producto contratado.
              <br />
              <br />
              2. Posteriormente, la Empresa en un plazo no mayor a setenta y dos
              horas (72) generará los accesos para el personal designado.
              <br />
              <br />
              3. En caso de que el Usuario desee dar de alta, modificar y/o dar
              de baja al personal con acceso al Producto contratado, éste deberá
              contactar a la Empresa con tres (3) días de anticipación a la
              fecha esperada de modificación a efecto de indicar los cambios a
              aplicar.
              <br />
              <br />
              4. Si la Empresa al momento de realizar el alta se percata que el
              Usuario indicó un número mayor de accesos al contratado, éste se
              lo indicará al último a efecto que determine efectivamente quiénes
              recibirán acceso y/o aumente el nivel de su Producto contratado.
              <br />
              <br />
              <h5 style={{ color: 'white' }}>VI BIS.- AGREGADOR BANCARIO.</h5>
              Los Usuarios autorizan expresa e irrevocablemente a la Empresa la
              contratación con uno o más Agregadores Bancarios, con el propósito
              de que sean dichas empresas especializadas sean las que realicen
              directamente los cobros de las Contraprestaciones que paguen los
              Usuarios por conducto de los Medios de Pago. Los Agregadores
              Bancarios captarán y procesarán, de forma privada y restringida,
              los datos personales financieros o bancarios de los Usuarios que
              sean exclusivamente necesarios para realizar el cobro de las
              Contraprestaciones por conducto de tarjeta bancaria o medios
              electrónicos de pago.
              <br />
              <br />
              Actualmente, Diinco no recibe, almacena, ni mantiene ninguna clase
              de datos financieros, toda vez que Diinco utiliza los servicios de
              índole Gateway y/o aceptación de pagos electrónicos brindados por
              compañías especializadas. Se hace del conocimiento de los Usuarios
              que, a la fecha, se encuentran conectados a la Plataforma los
              siguientes Agregadores Bancarios:
              <br />
              <br />
              1. Stripe, Inc.
              <br />
              <br />
              Diinco podrá contratar los servicios de Agregadores Bancarios
              distintos de los antes enunciados, sin que ello se considere una
              modificación a los presentes Términos y Condiciones.
              <br />
              <br />
              <h5 style={{ color: 'white' }}>
                VII. GARANTÍAS Y CANCELACIONES.
              </h5>
              VII.A. Garantía.
              <br />
              <br />
              El Usuario renuncia a todas las garantías. La Empresa y no
              garantiza que (a) los Servicios y los Productos proporcionados
              bajo este acuerdo están libres de errores; (b) los Servicios
              cumplirán con sus necesidades o requisitos específicos; (c) los
              Servicios serán utilizados por la compañía, administradores o
              usuarios en cualquier momento o lugar en particular; (d)
              comerciantes específicos permitirán compras con tarjetas o métodos
              de pago emitidos por el emisor; (e) los Servicios serán
              ininterrumpidos, seguros o libres de hacking, virus o códigos
              maliciosos; y (f) cualquier defecto en los Servicios será
              corregido, incluso cuando Diinco sea informado de dichos defectos.
              <br />
              <br />
              VII.B. Cancelaciones.
              <br />
              <br />
              La presente sección establece las disposiciones generales para las
              cancelaciones, misma que resultará aplicable por un plazo máximo
              de un día hábil, contado a partir de la Transacción del (de los)
              Producto(s).
              <br />
              <br />
              Para solicitar la devolución el Usuario deberá contactar
              inmediatamente al Centro de Atención y Soporte, señalando sus
              datos de contacto, el número de pedido, así como la fecha de
              compra. Posteriormente en un plazo no mayor a veinticuatro (24)
              horas Diinco resolverá su solicitud.
              <br />
              <br />
              En caso de ser aprobada la solicitud, si su pago fue con tarjeta
              de crédito o débito, el reembolso o cancelación del cargo se hará
              a la misma tarjeta de crédito o débito con que realizó el pago.
              Este trámite puede tardar hasta quince (15) días hábiles según las
              políticas de las entidades financieras, agregadores bancarios y/o
              emisores de tarjetas.
              <br />
              <br />
              No obstante lo anterior, no se podrá realizar una cancelación si
              el Usuario tiene adeudos por menses vencidos.
              <br />
              <br />
              <h5 style={{ color: 'white' }}>
                VIII. RESPONSABILIDAD EN CASO DE ACCIDENTES Y/O LESIONES.
              </h5>
              Una vez habilitados los accesos, los Usuarios reconocen que son
              los únicos y exclusivos responsables respecto del uso que otorguen
              al Producto. En consecuencia, y en la extensión más amplia
              permitida por la legislación aplicable, los Usuarios eximen a la
              Empresa (así como a sus empresas matrices, controladoras,
              afiliadas y subsidiarias, y a sus socios, accionistas,
              representantes, gerentes, apoderados, funcionarios, directivos,
              empleados, agentes, factores, dependientes, comisionistas,
              mandatarios, asesores y/o prestadores de servicios) de cualesquier
              daños y/o perjuicios patrimoniales o morales (incluyendo, sin
              limitación, daños directos, daños indirectos, daños incidentales,
              daños especiales, daños ejemplares o punitivos, daños emergentes,
              lucro cesante, pérdida de datos, lesiones personales, daño a la
              propiedad y/o daños consecuenciales), perjuicios, gravámenes y
              gastos médicos que pudieran resultar de cualquier siniestro que
              sufran los Usuarios abordo o con relación a los Productos,
              incluyendo sin limitar pérdida de información, caídas, lesiones,
              accidentes, atropellamientos, robos y asaltos, electrocutamientos,
              y en general, cualesquier eventos de tránsito, inclusive cuando
              dichos siniestros deriven en cualquiera de las situaciones a que
              refiere el Título Noveno de la Ley Federal del Trabajo (v.gr.,
              incapacidad temporal; incapacidad permanente parcial; incapacidad
              permanente total; o muerte).
              <br />
              <br />
              <h5 style={{ color: 'white' }}>IX. LICENCIA DE USO.</h5>
              Sujeto a lo previsto en los presentes Términos y Condiciones,
              Diinco otorga a los Usuarios y los Usuarios aceptan de Diinco, una
              licencia limitada, personal, no cedible, no sublicenciable,
              intransferible, revocable y no exclusiva, para el uso del Sitio
              Web y el resto de los Medios Digitales dentro del territorio de
              México (la “Licencia de Uso”). Ningún código fuente o licencia
              para usar código fuente son otorgados bajo los términos de la
              presente sección. Los Usuarios se comprometen a hacer un uso
              adecuado y lícito de los Medios Digitales, de conformidad con la
              regulación aplicable, el presente instrumento, la moral y las
              buenas costumbres generalmente aceptadas y el orden público. Los
              Usuarios deberán abstenerse de incurrir en las siguientes
              conductas: (i) hacer un uso inadecuado o ilícito o fraudulento de
              los Medios Digitales; (ii) acceder o intentar acceder a recursos
              restringidos o no autorizados dentro de los Medios Digitales;
              (iii) utilizar los Medios Digitales con fines o propósitos
              ilícitos, ilegales, contrarios a lo establecido en el presente
              instrumento, lesivos de los derechos o intereses de terceros, o
              que de cualquier forma puedan dañar, inutilizar o sobrecargar o
              impedir la normal utilización o disfrute de los Medios Digitales;
              (iv) provocar daños en los Medios Digitales o en los sistemas de
              sus proveedores o de terceros; (v) introducir o difundir o
              realizar actos tentativos a distribuir virus informáticos o
              cualesquier otros sistemas físicos o lógicos que sean susceptibles
              de causar daños en los sistemas de Diinco, de sus proveedores o de
              terceros; (vi) intentar, acceder, utilizar y/o manipular los datos
              de Diinco; terceros proveedores y otros Usuarios; (vii) reproducir
              o copiar o distribuir cualesquier elementos insertos en los Medios
              Digitales, sin contar con la autorización correspondiente; y (vii)
              realizar o intentar realizar ingeniería inversa, descompilar o
              hacer compilación inversa, desensamblar, listar, imprimir o
              mostrar el software, firmware, middleware o cualquier otra parte
              del código fuente o compilado que integra el Sitio Web y
              cualquiera de sus programas o componentes, o por cualquier otro
              medio obtener el código fuente u otra información patrimonial de
              cualquiera de los Medios Digitales.
              <br />
              <br />
              Asimismo, queda estrictamente prohibida la realización de
              “Operaciones Fraudulentas” en el Sitio Web. Para efectos del
              presente documento, se entenderá por Operaciones Fraudulentas,
              cualquier acto realizado por los Usuarios para obtener un lucro o
              beneficio indebido, incluyendo sin limitar la suplantación de
              identidades; la creación de cuentas falsas o cuentas dobles de
              usuario; la petición o la solicitud o la realización o la
              aceptación de Transacciones simuladas o ficticias, inusuales o
              inexistentes; el ingreso de información financiera o bancaria
              falsa, o previamente sustraída a terceros; la confabulación con
              cualquier otro Usuario o con cualquier personal de Diinco para
              realizar conductas fraudulentas; utilización indebida de Códigos
              Promocionales y en general, cualquier acto aislado o reiterado que
              tenga como propósito engañar a el Sitio Web o a la Empresa para
              obtener un lucro indebido. El Usuario acepta que la disponibilidad
              y continuidad del funcionamiento de los Medios Digitales podrá
              verse interrumpida o suspendida temporalmente por: (i) la falta de
              disponibilidad o accesibilidad a los Medios Digitales; (ii) la
              interrupción en el funcionamiento de los Medios Digitales por
              fallos informáticos, averías telefónicas, desconexiones, retrasos
              o bloqueos causados por deficiencias o sobrecargas en las líneas
              telefónicas, centros de datos, en el sistema de internet o en
              otros sistemas electrónicos, producidos en el curso de su
              funcionamiento; y (iii) otros daños que puedan ser causados por
              terceros mediante intromisiones no autorizadas ajenas al control
              de la Empresa.
              <br />
              <br />
              La Empresa hará sus mejores esfuerzos por mantener el buen y
              correcto funcionamiento de los Medios Digitales, así como para
              mitigar la presencia de virus y/o de otros elementos introducidos
              a los Medios Digitales por terceros ajenos, además adoptará
              diversas medidas de protección para proteger los Medios Digitales
              contra ataques informáticos de terceros. Sin embargo, los Usuarios
              reconocen que los Medios Digitales podrán no estar disponibles
              debido a dificultades técnicas, fallas en el internet,
              afectaciones en las redes de telecomunicaciones, o por cualquier
              circunstancia ajena a la Empresa.
              <br />
              <br />
              <h5 style={{ color: 'white' }}>
                X. CENTRO DE ATENCIÓN Y SOPORTE.
              </h5>
              Para realizar cualesquier solicitudes, quejas o reportes
              relacionados con los Servicios, así como para solicitar
              cualesquier aclaraciones relacionadas con los Productos y
              Servicios, los Usuarios deberán comunicarse al Centro de Atención
              y Soporte de la Empresa, dentro del plazo máximo de cinco (5) días
              naturales posteriores al acontecimiento que deseen reportar o cuya
              aclaración soliciten (las “Aclaraciones”).
              <br />
              <br />
              Centro de Atención y Soporte
              <br />
              Teléfono: +52 443 938 8884
              <br />
              Correo electrónico: Info@diinco.com
              <br />
              WhatsApp: +52 1 56 5891 1074
              <br />
              <br />
              La Empresa contará con un plazo de diez (10) días hábiles para
              resolver las Aclaraciones correspondientes. Toda referencia
              contenida en el presente instrumento relativa a comunicaciones que
              el Usuario deberá efectuar para con la Empresa, deberán realizarse
              al Centro de Atención y Soporte.
              <br />
              <br />
              <h5 style={{ color: 'white' }}>XI. PRIVACIDAD.</h5>
              Al suscribir o aceptar los presentes Términos y Condiciones, los
              Usuarios declaran que han leído y han aceptado expresamente el
              Aviso de Privacidad de Diinco, que se encuentra a su disposición
              en el Sitio Web. Para más información sobre el tratamiento de sus
              datos personales, los Usuarios deberán consultar el Aviso de
              Privacidad.
              <br />
              <br />
              <h5 style={{ color: 'white' }}>XII. FACTURACIÓN.</h5>
              Los Usuarios podrán solicitar a la Empresa Comprobantes Fiscales
              Digitales (CFDI´s) por cada Transacción. Para tal efecto, deberán
              solicitar el CFDI correspondiente a través de los Medios
              Digitales, dentro de las setenta y dos (72) horas siguientes a que
              haya sido entregada la Transacción correspondiente enviando sus
              datos fiscales, así como el número de pedido al correo electrónico
              siguiente: facturas@diinco.com
              <br />
              <br />
              <h5 style={{ color: 'white' }}>
                XIII. DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL.
              </h5>
              Los Usuarios reconocen y aceptan que todos los derechos de
              propiedad intelectual e industrial sobre el Sitio Web y sobre
              todos los contenidos y elementos insertos en los Medios Digitales
              (incluyendo sin limitar marcas, signos distintivos, logotipos,
              figuras, nombres comerciales, textos, imágenes, gráficos, diseños,
              sonidos, bases de datos, software, diagramas de flujo,
              presentaciones, y elementos de audio y de video) pertenecen a
              Diinco (la “Propiedad Intelectual”). Se encuentra estrictamente
              prohibida cualquier reproducción, distribución, transmisión,
              copia, alteración, explotación, publicación, difusión o
              disposición de la Propiedad Intelectual, sin el consentimiento
              previo y por escrito de un representante legal de Diinco.
              Cualquier uso o explotación de la Propiedad Intelectual - no
              autorizado por un representante legal de Diinco previamente y por
              escrito - será motivo suficiente para revocar la Licencia de Uso
              prevista en el presente instrumento, sin perjuicio de las
              sanciones administrativas, civiles y penales a las que sea
              acreedor el infractor.
              <br />
              <br />
              <h5 style={{ color: 'white' }}>
                XIV. RELACIÓN ENTRE LAS PARTES.
              </h5>
              Para efectos del presente Contrato, los Usuarios y la Empresa en
              conjunto son denominadas como las “Partes”. Las Partes reconocen
              que todas ellas son independientes entre sí, por lo que consienten
              que la suscripción de los presentes Términos y Condiciones será
              una relación contractual de carácter mercantil, sin que de lugar a
              que dicha relación se interprete como una sociedad o asociación
              entre ellas.
              <br />
              <br />
              <h5 style={{ color: 'white' }}>
                XV. CASO FORTUITO O CAUSAS DE FUERZA MAYOR.
              </h5>
              La Empresa no será responsable frente a los Usuarios, conforme al
              presente instrumento, en caso de que no puedan utilizarse los
              Medios Digitales o no se puedan celebrar Transacciones por Caso
              Fortuito o Causas de Fuerza Mayor, incluyendo, pero sin estar
              limitado a, incendios, inundaciones, huracanes, tormentas,
              ciclones, terremotos, sismos, movimientos telúricos, pandemias,
              epidemias, huelgas, guerras, insurrecciones, motines, rebeliones,
              clausuras, ataques cibernéticos de hackers, la revocación general
              o limitación al público usuario en el uso del software o lenguaje
              de programación necesario para operar el Sitio Web o la Aplicación
              Móvil, así como toda clase de restricciones gubernamentales, casos
              fortuitos u otras causas de naturaleza similar o distinta.
              <br />
              <br />
              <h5 style={{ color: 'white' }}>XVI. CESIÓN.</h5>
              Los Usuarios no podrán ceder sus derechos y obligaciones asumidos
              al suscribir los presentes Términos y Condiciones, sin el
              consentimiento previo y por escrito de un representante legal de
              Diinco. Por su parte, la Empresa podrá ceder los presentes
              Términos y Condiciones a cualquier tercero que le suceda en el
              ejercicio de su negocio o en que asuma la titularidad de los
              Medios Digitales, por cualquier título posible, previa
              notificación a los Usuarios para tal efecto.
              <br />
              <br />
              <h5 style={{ color: 'white' }}>XVII. NOTIFICACIONES.</h5>
              La Empresa podrá realizar las notificaciones oportunas a través de
              una notificación general en el Sitio Web, a través de la dirección
              de correo electrónico facilitada por los Usuarios al registrar sus
              Cuentas de Usuario o mediante correo tradicional dirigido al
              domicilio indicado por los Usuarios al registrar sus Cuentas de
              Usuario. El Usuario podrá notificar a la Empresa mediante el envío
              de un correo electrónico a la dirección de contacto del Centro de
              Atención y Soporte.
              <br />
              <br />
              <h5 style={{ color: 'white' }}>XVIII. MODIFICACIONES.</h5>
              Los presentes Términos y Condiciones podrán ser modificados,
              adicionados o reformados, en cuyo caso se actualizará la fecha de
              última modificación del presente instrumento. El Usuario reconoce
              expresamente el derecho de Diinco de modificar el presente
              Contrato en cualquier tiempo, bastando con ello una notificación
              en términos del procedimiento aquí previsto, con al menos cinco
              (5) días naturales de anticipación a la fecha en que las
              modificaciones entren en vigor. En el evento de que el Usuario no
              esté de acuerdo con las modificaciones realizadas al presente
              Contrato, éste podrá solicitar a Diinco la terminación del mismo
              dentro de los treinta (30) días naturales posteriores al aviso,
              sin responsabilidad alguna a su cargo. Se entenderá que el Usuario
              acepta las modificaciones efectuadas a los presentes Términos y
              Condiciones si éste solicita o participa en cualquier Transacción
              en fecha posterior a que tales modificaciones entren en vigor,
              manteniendo vigente su derecho a dar por terminado el presente
              instrumento en términos de lo dispuesto en el párrafo anterior.
              <br />
              <br />
              <h5 style={{ color: 'white' }}>XIX. SUBSISTENCIA.</h5>
              En caso de que cualquier término, condición, sección o disposición
              prevista en los presentes Términos y Condiciones sea considerada o
              pudiera considerarse nulo, inválido, ilegal, prohibido o
              inexigible en cualquier jurisdicción, dicho término, condición,
              sección o disposición deberá separarse del presente Contrato, sin
              que lo anterior implique la nulidad o la invalidez de los términos
              o condiciones restantes. La Empresa deberá sustituir el término,
              condición, sección o disposición correspondiente, de conformidad
              con la regulación aplicable.
              <br />
              <br />
              <h5 style={{ color: 'white' }}>XX. VIGENCIA.</h5>
              Los presentes Términos y Condiciones serán vigentes, obligatorios
              y vinculantes para todos los Usuarios que tengan una Cuenta de
              Usuario habilitada en el Sitio Web y no soliciten su baja o
              cancelación, así como los Usuarios que accedan como invitados al
              Sitio Web. En caso de que algún Usuario solicite la baja o la
              cancelación de su Cuenta de Usuario, quedará relevado de cumplir
              con lo previsto en el presente documento, pero permanecerán
              vigentes todas aquellas obligaciones relacionadas con los daños,
              perjuicios o responsabilidades - de carácter civil, penal,
              administrativa o de cualquier otra índole - en que haya incurrido,
              en su caso, el Usuario en contra de otros Usuarios, de Diinco o de
              cualquier tercero.
              <br />
              <br />
              <h5 style={{ color: 'white' }}>XXI. CONTROVERSIAS.</h5>
              Las Partes en este acto expresamente se someten y acuerdan que
              cualquier disputa, controversia, reclamación, demanda, acción o
              litigio que surja, de o en relación con la interpretación o el
              cumplimiento del presente Contrato, incluyendo cualquier asunto
              relativo a su existencia, validez, cumplimiento o terminación del
              mismo (la “Controversia”), deberán ser resueltas de acuerdo a los
              procedimientos descritos en esta sección, mismos que serán los
              únicos y exclusivos procedimientos para solucionar la
              Controversia, siendo aplicable para cualquier Controversia, tanto
              para lo adjetivo como lo sustantivo, la legislación mercantil
              aplicable en México, renunciando en este acto las Partes a
              cualquier otra legislación o jurisdicción que, por motivo de sus
              domicilios presentes o futuros, pudiere corresponderles.
              <br />
              <br />
              (A) Mediación.
              <br />
              <br />
              Ante la existencia de una Controversia, las Partes deberán agotar
              el procedimiento privado de mediación en términos de la
              legislación aplicable en la Ciudad de México, designando para tal
              propósito al mediador privado número cuatrocientos noventa y cinco
              (495) de la Ciudad de México; en el entendido de que, si por
              cualquier cuestión, dicha designación no fuere viable, el mediador
              correspondiente será designado por la Comisión de Mediación y
              Arbitraje Comercial de la Cámara Nacional de Comercio de la Ciudad
              de México (la “Comisión”).
              <br />
              <br />
              El procedimiento privado de mediación dará inicio una vez que
              cualquiera de las Partes notifique por escrito al mediador privado
              la Controversia correspondiente y se regirá, en la medida
              aplicable, por lo dispuesto en la Ley de Justicia Alternativa del
              Tribunal Superior de Justicia para el Distrito Federal, aplicable
              para la Ciudad de México.
              <br />
              <br />
              (B) Jurisdicción Ordinaria.
              <br />
              <br />
              Cualquier Controversia que no haya sido resuelta a través del
              procedimiento privado de mediación acordado en el párrafo (A) de
              la presente sección, dentro de los 40 (cuarenta) días naturales
              siguientes después de referido el asunto al mediador privado, será
              finalmente sometida a la jurisdicción de los Juzgados y Tribunales
              competentes sitos en la Ciudad de México, por lo que las Partes en
              este acto renuncian expresa e irrevocablemente a cualquier otra
              jurisdicción o fuero que les pudiera corresponder por razón de sus
              domicilios presentes o futuros, o por cualquier otra razón o
              motivo.
              <br />
              <br />
            </div>
          </li>
          <li className={privacity ? 'uk-active' : ''}>
            {/* Aviso de privacidad */}
            <div className='diinco-tyc-card-title'>{lang['reach']}</div>
            <div className='diinco-tyc-card-text'>
              El presente Aviso de Privacidad describe la forma en que
              obtenemos, utilizamos, compartimos, conservamos y protegemos tus
              datos personales. Este Aviso de Privacidad resulta aplicable a
              este Sitio; asimismo, resulta aplicable a todas las aplicaciones,
              servicios y herramientas (conjuntamente los &quot;Servicios&quot;)
              en los que se haga referencia a este Aviso de Privacidad; lo
              anterior, independientemente de la manera en que se acceda o se
              usen dichos Servicios.
              <br />
              <br />
              Al usar nuestros Servicios o al registrarte como usuario, estas
              aceptando los términos establecidos en este Aviso de Privacidad y
              nuestros Términos y condiciones Diinco; asimismo, estas
              consintiendo la utilización, divulgación, conservación y
              protección de tus datos personales en los términos establecidos en
              este Aviso. Se hace notar que en caso de que decidas no
              proporcionarnos la información que según el caso es solicitada es
              posible que no podamos proporcionarte nuestros Servicios.
              <br />
              <br />
              “DESARROLLO E INNOVACION INTERNACIONAL EN NUEVAS TECNOLOGIAS EN
              BIENES RAICES S.A de C.V.”, con domicilio en Morelia Michoacán
              México, es la empresa que opera el Sitio web Diinco.com y es la
              organización responsable de la obtención, utilización,
              divulgación, conservación y protección de tus datos personales; lo
              anterior, de conformidad con nuestros estándares internacionales
              de protección de la privacidad y las leyes locales aplicables.
              Como Responsables, podemos transferir tus datos personales a otras
              empresas que pertenecen a nuestro mismo grupo corporativo, en los
              términos que se describen en este Aviso de Privacidad. Procesamos
              y conservamos tus datos personales en nuestros servidores y
              centros de datos, mismos que pueden estar ubicados en México, en
              los Estados Unidos o en cualquier otro país.
              <br />
              <br />
              Datos personales:
              <br />
              <br />
              Los datos personales son información que puede asociarse con una
              persona en particular y usarse para identificarla, ya sea a través
              de dichos datos o por medio de otra información a la que nosotros
              tengamos o podamos tener acceso. No consideramos datos personales
              datos que se proporcionan de forma anónima o que son disociados en
              forma tal que se impida el identificar a una persona específica,
              ya sea en combinación con otra información o de algún otro modo.
              <br />
              <br />
              Los datos personales sensibles son aquellos datos personales que
              afectan a la esfera más íntima de su titular, o cuya utilización
              indebida pueda dar origen a discriminación o conlleva un riesgo
              grave para éste.
              <br />
              <br />
              Cambios a este Aviso de Privacidad:
              <br />
              <br />
              Nos reservamos el derecho de modificar este Aviso de privacidad en
              cualquier tiempo. En dicho caso, publicaremos el nuevo Aviso de
              Privacidad actualizado en el Sitio. Los términos actualizados
              entrarán en vigor automáticamente al día siguiente de su
              publicación en el Sitio. El Aviso modificado será publicado en
              lugares visibles en el Sitio o podría hacérseles llegar a los
              usuarios a sus correos electrónicos registrados; por otro lado,
              nos comprometemos a notificarte la existencia de un nuevo Aviso de
              Privacidad actualizado en los siguientes casos: (i) cuando cambie
              la identidad del Responsable (ya sea el nombre o la dirección);
              (ii) cuando necesitemos recabar datos personales sensibles,
              patrimoniales o financieros adicionales a aquéllos que te
              informamos en el aviso de privacidad original, cuando los mismos
              no se obtengan de manera personal o directa de ti y se requiera tu
              consentimiento; (iii) cuando modifiquemos las finalidades que
              dieron origen o son necesarias para la relación jurídica que
              tenemos contigo; o bien, cuando incorporemos nuevas finalidades
              que requieran de tu consentimiento o; (iv) cuando modifiquemos las
              condiciones en las cuales se realizan transferencias o bien,
              cuando se vayan a realizar transferencias que no se encontraban
              previstas inicialmente y el consentimiento del titular sea
              necesario.
              <br />
              <br />
              Si no estás de acuerdo con las modificaciones, por favor
              comunícate con nosotros al info@diinco.com
              <br />
              <br />
            </div>
            <div className='diinco-tyc-card-title'>
              Estándares internacionales para la protección de la privacidad
            </div>
            <div className='diinco-tyc-card-text'>
              “DESARROLLO E INNOVACION INTERNACIONAL EN NUEVAS TECNOLOGIAS EN
              BIENES RAICES S.A de C.V.” Diinco, forman parte del mismo grupo
              corporativo. Nosotros hemos adoptado principios de privacidad
              global para la protección de la privacidad. La adopción de esta
              normatividad hace patente nuestro compromiso de proteger tus datos
              personales y de respetar nuestras obligaciones de privacidad
              independientemente como el grupo corporativo obtenga, procese o
              conserve tus datos personales.
            </div>
            <div className='diinco-tyc-card-title'>Obtención de datos</div>
            <div className='diinco-tyc-card-text'>
              Datos que nos proporcionas cuando usas nuestros Servicios o te
              registras:
              <br />
              <ul className='uk-list uk-list-disc'>
                <li>
                  Datos de identificación y contacto, tales como nombre,
                  dirección, teléfono, correo electrónico o nombre de usuario
                  (en caso de ser aplicable). En algunos casos, cuando usas
                  nuestros Servicios, es posible que proporciones datos como tu
                  edad, sexo, intereses y preferencias en productos y servicios.
                </li>
                <li>
                  Información que publiques en un anuncio o respuestas que
                  proporciones con relación a un anuncio; información que
                  proporciones durante una transacción u otro contenido
                  relacionado con transacciones de las que seas parte,
                  incluyendo cuando señalas tu localización o la localización
                  del producto o servicio que deseas ofrecer ó contratar
                </li>
                <li>
                  Contenido que pudieras generar o que se encuentre asociado con
                  tu cuenta (por ejemplo, contenido que puedas generar cuando
                  agregas artículos a Alertas).
                </li>
                <li>
                  Datos financieros (incluyendo números de tarjetas de crédito o
                  de cuentas bancarias) vinculados con una compra y que son
                  obtenidos por nuestros proveedores de Servicio.
                </li>
                <li>
                  Información que proporcionas mediante el llenado de
                  formularios web, actualizaciones o información que agregas a
                  tu cuenta, cuando participas en chats y conversaciones con la
                  comunidad o que provees cuando nos comunicamos contigo.
                </li>
                <li>
                  Grabaciones de comunicaciones telefónicas, siempre y cuando se
                  haya obtenido el consentimiento para grabarlas de acuerdo con
                  las leyes correspondientes.
                </li>
                <li>
                  Información adicional que las leyes locales correspondientes
                  nos exigen o nos autorizan a recopilar y procesar con el fin
                  de autenticar tu información o identificarte o de verificar la
                  información que hemos recopilado.
                </li>
              </ul>
              <br />
              Datos que recopilamos automáticamente cuando usas nuestros
              Servicios o te registras:
              <br></br>
              <ul className='uk-list uk-list-disc'>
                <li>
                  Información recopilada de los dispositivos que usas al
                  interactuar con nosotros, como el identificador del
                  dispositivo o el nombre de usuario único, el tipo de
                  dispositivo, el identificador para publicidad y el token o
                  código único del dispositivo.
                </li>
                <li>
                  Información de geolocalización, incluidos los datos de
                  ubicación de tu dispositivo móvil. Recuerda que la mayoría de
                  los dispositivos móviles permiten controlar el acceso de las
                  aplicaciones a los datos de ubicación desde el menú de
                  configuración del mismo dispositivo.
                </li>
                <li>
                  Datos de la computadora y de la conexión como las estadísticas
                  de las páginas que ves, el tráfico desde y a dichos sitios
                  web, la URL de referencia, los datos de los anuncios, tu
                  dirección de IP, tu historial de navegación y la información
                  de tu registro web.
                </li>
              </ul>
              <br />
              Datos que recopilamos mediante el uso de cookies, web beacons y
              otras tecnologías similares:
              <br></br>
              <ul className='uk-list uk-list-disc'>
                <li>
                  En nuestro sitio web, recabamos información empleando cookies,
                  web beacons o dispositivos similares. Las cookies, web beacons
                  y dispositivos similares utilizados en nuestro sitio web
                  simplifican la administración del sitio y la navegación a
                  través del mismo y mejoran la experiencia en línea del
                  visitante. La información obtenida incluye, entre otra,
                  información sobre páginas que visitas, enlaces a los que
                  accedes y otras acciones que realizas con relación a nuestros
                  Servicios o dentro de nuestro contenido de publicidad o de
                  correo electrónico. Para obtener más información sobre cómo
                  usamos estas tecnologías y cómo controlarlas, consulta nuestro
                  AVISO SOBRE COOKIES DIINCO.
                </li>
                <li>
                  En ocasiones también utilizamos web beacons. Utilizamos web
                  beacons en nuestros Sitios Web para contabilizar y reconocer a
                  los usuarios mediante el acceso a nuestras cookies. De esta
                  forma, podemos personalizar nuestro sitio web y mejorar su
                  experiencia de navegación. También podemos incluir web beacons
                  en forma de mensajes de correo eléctrico en formato HTML,
                  mismos que enviamos para determinar qué mensajes electrónicos
                  han sido abiertos.
                </li>
              </ul>
              <br />
              Otras fuentes:
              <br></br>
              <ul className='uk-list uk-list-disc'>
                <li>
                  Datos que podemos recopilar de terceros. Estos datos incluyen
                  información geográfica proveniente de fuentes de acceso
                  público, información adicional de contacto, información de
                  verificación de crédito e información de agencias de
                  información de crédito; lo anterior, en los términos de las
                  leyes nacionales aplicables.
                </li>
                <li>
                  Información de las redes sociales que te permitimos usar para
                  registrarte a nuestros Servicios, información que se genera
                  cuando compartes información a través de una red social o
                  cuando inicias sesión en tu cuenta a través de una de tus
                  cuentas de redes sociales. Los sitios de redes sociales pueden
                  darnos acceso automático a ciertos datos personales que
                  mantienen sobre ti (por ejemplo, el contenido que viste, el
                  contenido que hayas marcado como que te gusta y la información
                  sobre la publicidad que te han mostrado o en la que has hecho
                  &quot;clic&quot;, etc.). Si nos das acceso a sitios que tienen
                  contenido de video, aceptas que compartamos los videos que ves
                  con los sitios de redes sociales de terceros, o que obtengamos
                  información sobre los videos que viste de dichos sitios
                  durante por lo menos dos años o hasta que revoques tu
                  consentimiento. La forma en que tú como usuario interactúas
                  con las funcionalidades de un sitio en particular se rige por
                  la política de privacidad de la empresa que actúa como
                  Responsable de dichas redes sociales. Se hace notar que tú
                  eres quien controla los datos personales a los que podemos
                  tener acceso mediante la forma en que configuras tus opciones
                  de privacidad en las diversas redes sociales y los permisos
                  que nos otorgas al concedernos acceso a dicha información.
                </li>
                <li>
                  Información que otros usuarios pueden proporcionar sobre ti.
                  Nuestras políticas exigen que el usuario que proporciona
                  información concerniente a otra persona, notifique a esa
                  persona sobre nuestras políticas de obtención, utilización,
                  divulgación y conservación de datos, antes de proporcionarnos
                  sus datos personales y que obtenga de ella su consentimiento
                  expreso, previo a la transferencia.
                </li>
              </ul>
              <br />
              Datos Personales Sensibles:
              <br></br>
              <ul className='uk-list uk-list-disc'>
                <li>
                  Ocasionalmente podemos recibir de ti datos personales que son,
                  o podrían llegar a ser considerados como datos personales
                  sensibles por la legislación local. Por ejemplo, en algunos
                  países, la información de geolocalización, incluidos los datos
                  de ubicación de tu dispositivo móvil, pueden ser considerados
                  sensibles. Recuerda que la mayoría de los dispositivos móviles
                  permiten controlar el acceso de las aplicaciones a los datos
                  de ubicación. Este control suele poder realizarse desde el
                  menú de configuración del mismo dispositivo.
                </li>
                <li>
                  En caso de que vayamos a obtener de ti datos personales de
                  naturaleza sensible te lo haremos saber y te ofreceremos
                  opciones para que puedas expresar si consientes o no el
                  tratamiento de tus datos personales sensibles (por ejemplo,
                  una casilla que puede ser marcada con un &quot;clic&quot; para
                  expresar tu consentimiento; hacemos notar que tu decisión de
                  no proporcionarnos datos personales puede afectar nuestra
                  capacidad para proveerte un Servicio o continuar brindándote
                  servicios.
                </li>
              </ul>
            </div>
            <div className='diinco-tyc-card-title'>
              Uso de datos personales y conservación de información
            </div>
            <div className='diinco-tyc-card-text'>
              Usos relativos a proporcionarte nuestros Servicios; usos para
              mejorar y personalizar los Servicios
              <br />
              <ul className='uk-list uk-list-disc'>
                <li>Brindarte acceso a los Servicios y facilitar su uso.</li>
                <li>
                  Ofrecerte contenido en el Sitio que incluye artículos y
                  servicios que te puedan gustar.
                </li>
                <li>
                  Brindarte acceso a tu historial, a mensajes internos (en caso
                  de ser aplicable) y a otras funcionalidades del Sitio que
                  podríamos ofrecerte.
                </li>
                <li>
                  Personalizar en tu favor, medir y mejorar nuestros Servicios.
                </li>
                <li>
                  Prestarte otros servicios que solicites, en los términos que
                  dichos Servicios se describan cuando recopilamos la
                  información.
                </li>
                <li>
                  Utilizar la información de geolocalización para proporcionarte
                  servicios basados en la ubicación (como publicidad, resultados
                  de búsquedas y otro contenido personalizado).
                </li>
              </ul>
              <br />
              Usos para contactarte con relación a tu cuenta y brindarte
              atención a cliente:
              <br />
              <br />
              <ul className='uk-list uk-list-disc'>
                <li>
                  Contactarte sobre tu cuenta, para solucionar problemas
                  asociados a la cuenta, cobrar contraprestaciones o montos
                  adeudados por la compra de funcionalidades, obtener tu opinión
                  a través de encuestas o encuestas de atención a cliente.
                </li>
                <li>
                  Contactarte en el contexto de exigir o hacer cumplir nuestros
                  Términos y condiciones DIINCO las leyes locales aplicables y
                  cualquier otro convenio que tengamos celebrado contigo.
                </li>
                <li>
                  Recibir quejas, y dar seguimiento a solicitudes que realices.
                </li>
              </ul>
              <br />
              Podemos contactarte por correo electrónico, teléfono, mensajes de
              texto y correo postal.
              <br />
              <br />
              Uso para personalizar nuestra publicidad y nuestras comunicaciones
              de marketing:
              <br />
              <br />
              <ul className='uk-list uk-list-disc'>
                <li>Personalizar, medir y mejorar nuestra publicidad.</li>
                <li>
                  Contactarte por correo electrónico, teléfono, mensajes de
                  texto y correo postal para: ofrecerte descuentos y promociones
                  especiales e informarte sobre nuestros Servicios y de los
                  servicios de otras empresas de nuestro grupo corporativo.
                  Utilizaremos la información de contacto que nos proporciones
                  para contactarte por correo electrónico, mensajes de texto
                  (SMS) o teléfono, si nos has dado tu consentimiento. Se pueden
                  aplicar tarifas por mensajes cortos y conectividad de datos.
                </li>
                <li>
                  Ofrecer marketing personalizado, actualizaciones de servicio y
                  ofertas promocionales.
                </li>
              </ul>
              <br />
              Nosotros te facilitamos el controlar la posibilidad de no recibir
              información o llamadas de Marketing. Para nosotros el hacerte
              llegar comunicaciones de marketing no es esencial para el
              mantenimiento o la existencia de la relación jurídica que podemos
              tener contigo en un momento determinado; por lo tanto, antes de
              recopilar tus datos personales te proveeremos con opciones (tales
              como casillas para que expreses tu consentimiento) para que puedas
              decidir si deseas o no recibir dichas comunicaciones; la decisión
              que tomes no afectará tu capacidad para seguir recibiendo
              Servicios de nosotros. Para obtener más información acerca de cómo
              ejercer y controlar tus derechos de opción, por favor, consulta la
              sección titulada, &quot;Opciones&quot; en este Aviso de
              Privacidad.
              <br />
              <br />
              Tras la descarga de nuestra aplicación móvil, te facilitamos el
              controlar la posibilidad de activar la recepción de notificaciones
              push en tu dispositivo y en la aplicación. De tiempo en tiempo
              podemos enviarte notificaciones push para mantenerte informado
              sobre los eventos o promociones que se estén llevando a cabo. Si
              deseas dejar de recibir este tipo de comunicaciones, es posible
              detener su recepción desde tu dispositivo. Tu decisión respecto a
              dejar de recibir este tipo de notificaciones no afectará a los
              ajustes con respecto a las comunicaciones por correo electrónico,
              ni tu capacidad para seguir recibiendo Servicios de nosotros. Para
              obtener más información acerca de cómo ejercer y controlar tus
              derechos de opción, por favor, consulta la sección titulada,
              &quot;Opciones&quot; en este Aviso de Privacidad.
              <br />
              <br />
              Uso para prevenir, detectar, mitigar e investigar actividades
              ilegales o contrarias a nuestras políticas y términos:
              <ul className='uk-list uk-list-disc'>
                <li>
                  Impedir, detectar, mitigar e investigar fraudes, violaciones
                  de seguridad y posibles actividades prohibidas, ilícitas o
                  ilegales
                </li>
                <li>
                  Exigir el cumplimiento de nuestro Aviso de privacidad,
                  nuestros Términos y condiciones Diinco y otras políticas o
                  términos aplicables.
                </li>
              </ul>
              <br />
              Conservación de datos:
              <br />
              <br />
              Conservamos los datos personales de las cuentas activas por el
              tiempo que resulte necesario y mientras sean relevantes para
              nuestras operaciones. Podemos conservar los datos personales de
              las cuentas que hayan sido cerradas por el tiempo necesario para
              cumplir con las leyes nacionales, evitar fraudes, cobrar
              contraprestaciones pendientes, resolver problemas, ayudar en una
              investigación, exigir el cumplimiento de nuestros Terminos y
              condiciones Diinco y tomar otras acciones o medidas que se
              encuentren permitidas o que nos exijan las leyes locales
              aplicables. Cuando ya no sea necesario conservar tus datos
              personales, los eliminaremos de una manera segura, de conformidad
              con nuestras políticas de conservación y eliminación de datos.
            </div>
            <div className='diinco-tyc-card-title'>Opciones</div>
            <div className='diinco-tyc-card-text'>
              Te ofrecemos la posibilidad de tomar decisiones sobre cómo usamos
              sus datos personales; te ofrecemos diversos medios para
              informarnos acerca de tu decisión. Si no quieres recibir nuestras
              comunicaciones de marketing, puedes expresar tu voluntad a través
              de la casilla electrónica correspondiente, ya sea al crear una
              cuenta o en las oportunidades posteriores cuando se presente la
              casilla. Puedes también cancelar tu suscripción a comunicaciones
              de mercadotecnia mediante el enlace incluido en el mensaje de
              correo electrónico de mercadotecnia que recibiste. En algunos
              casos, los usuarios registrados también pueden cancelar su
              suscripción a través de las opciones de preferencias de
              comunicación que pudieran estar incluidos en sus perfiles de
              cuenta.
            </div>
            <div className='diinco-tyc-card-title'>Acceso</div>
            <div className='diinco-tyc-card-text'>
              De conformidad con la legislación local, tu gozas de los derechos
              de Acceso, Rectificación, Cancelación y Oposición al Tratamiento
              (los llamados “Derechos ARCO”); asimismo, cuentas con el derecho
              de revocar en cualquier tiempo el consentimiento otorgado para el
              tratamiento de tus datos personales en la medida que la ley lo
              permita. Para el ejercicio de tus Derechos ARCO o tu derecho de
              revocación o para formular cualquier duda o queja con relación al
              tratamiento de tus datos personales por favor contáctanos mediante
              info@diinco.com Tus solicitudes serán evaluadas en los términos
              establecidos en la legislación local y otras obligaciones
              aplicables a nosotros. Nosotros te podremos informar: (i) la
              información que se te solicitará para que te identifiques así como
              los documentos que necesitarás enviar junto con tu solicitud; (ii)
              los plazos en los que recibirás una contestación sobre tu
              solicitud; (iii) cómo debes de presentar tu solicitud, incluyendo
              los formularios que puedes usar para presentar tu solicitud, si
              los hubiere, y; (iv) la modalidad o medio en que te entregaremos
              la información. En virtud de lo anterior, nosotros cumpliremos en
              todo momento con las solicitudes que en el ejercicio de tus
              derechos previstos en la legislación local nos hagas llegar,
              mediante un correo electrónico.
              <ul className='uk-list uk-list-disc'>
                <li>
                  Para ver, revisar y cambiar tus datos personales puedes
                  iniciar sesión en tu cuenta. Actualiza tus datos personales de
                  inmediato si hay algún cambio o si la información no está
                  correcta.
                </li>
                <li>
                  Una vez que realices una publicación, es posible que no puedas
                  cambiarla ni eliminarla. Si lo solicitas, cerraremos tu cuenta
                  y eliminaremos tus datos personales lo antes posible, en
                  función de la actividad de tu cuenta y de acuerdo con las
                  leyes locales correspondientes.
                </li>
                <li>
                  Respetaremos todos los derechos que tengas de acceder,
                  modificar o borrar tus datos personales. Para solicitar acceso
                  y para averiguar si se cobran honorarios o contraprestaciones,
                  cuando las leyes locales correspondientes lo permitan,
                  comunícate con nosotros. Para ello, sigue las instrucciones de
                  la sección Preguntas Diinco.
                </li>
              </ul>
            </div>
            <div className='diinco-tyc-card-title'>
              Divulgación y transferencia de datos
            </div>
            <div className='diinco-tyc-card-text'>
              Podemos compartir tus datos personales con otras empresas que son
              arte de nuestro mismo grupo corporativo y con terceros.
              <br />A empresas de nuestro mismo grupo para que puedan:
              <ul className='uk-list uk-list-disc'>
                <li>
                  ofrecer contenido y servicios conjuntos (como registro,
                  transacciones Atención a clientes);
                </li>
                <li>
                  ayudar a detectar y evitar posibles actos fraudulentos,
                  ilícitos e ilegales, infracciones de nuestros Terminos y
                  condiciones y violaciones de la seguridad de los datos;
                </li>
                <li>ofrecerte publicidad personalizada;</li>
                <li>
                  guiar las decisiones sobre productos, sitios, aplicaciones,
                  servicios, herramientas y comunicaciones de marketing. Las
                  empresas de nuestro grupo no te enviarán comunicaciones de
                  marketing si no has dado tu consentimiento para recibirlas.
                </li>
              </ul>
              <br />A los terceros proveedores de servicios y entidades
              financieras asociadas, que pueden:
              <ul className='uk-list uk-list-disc'>
                <li>
                  ayudarnos a ofrecer nuestros Servicios, servicios de
                  procesamiento de pagos y publicidad personalizada;
                </li>
                <li>
                  ayudarnos con la prevención, detección, mitigación e
                  investigación de posibles actos ilegales, infracciones de
                  nuestros Términos y Condiciones, fraude e infracciones de
                  seguridad, cobro de facturas, programas de afiliados, premios
                  y otras operaciones comerciales.
                </li>
              </ul>
              En todos estos casos, nosotros nos aseguramos de que estas
              empresas asuman obligaciones contractuales que permitan que los
              datos personales de nuestros clientes se encuentren protegidos,
              además de que se hace de su conocimiento las obligaciones
              aplicables conforme a la legislación local.
              <br />
              <br />A las autoridades o terceros en procesos legales y según lo
              autorizado por las leyes:
              <ul className='uk-list uk-list-disc'>
                <li>
                  para cumplir requisitos legales, hacer cumplir nuestros
                  Términos y Condiciones, responder a reclamos de que un anuncio
                  u otro contenido infringe los derechos de otros o proteger los
                  derechos, la propiedad o la seguridad de alguien;
                </li>
                <li>
                  a las autoridades u organismos gubernamentales, o los terceros
                  autorizados, en respuesta a una solicitud legal relacionada
                  con una investigación de un delito o una actividad ilícita
                  supuesta o presunta, o bien cualquier otra actividad que pueda
                  causarnos riesgos, a nosotros, a ti o a otros usuarios;
                </li>
                <li>
                  a los participantes de nuestro programa de Notificación de
                  Infracciones bajo acuerdo de confidencialidad, según lo
                  consideremos necesario o adecuado en relación con una
                  investigación por fraude, infracción de propiedad intelectual,
                  piratería u otra actividad ilícita;
                </li>
                <li>
                  a las agencias u oficinas de información de crédito, según lo
                  autorizado por las leyes locales correspondientes;
                </li>
                <li>
                  a los terceros que estén por comenzar un proceso legal o sean
                  parte de uno, si nos proporcionan un requerimiento, una orden
                  judicial o un proceso legal sustancialmente similar; o bien,
                  si creemos de buena fe que la divulgación es necesaria para
                  impedir un daño físico o una pérdida financiera inminente o
                  para denunciar actividad ilegal sospechada
                </li>
              </ul>
              <br />
              Cambio de propiedad:
              <br />
              <br />
              Si ocurre una fusión con otra empresa o una adquisición por parte
              de otra empresa, podemos compartir información con esta de acuerdo
              con nuestros estándares internacionales de protección de la
              privacidad. La nueva entidad tendría que seguir este Aviso de
              Privacidad con respecto a tus datos personales. Si tus datos
              personales se recopilan, utilizan, divulgan o conservan para
              cualquier otro fin que no esté comprendido en este Aviso,
              recibirás una notificación previa sobre la manera en que se
              procesarán tus datos personales en dichos casos.
            </div>
            <div className='diinco-tyc-card-title'>Seguridad</div>
            <div className='diinco-tyc-card-text'>
              Protegemos tus datos con medidas de seguridad administrativas,
              físicas y técnicas para reducir los riesgos de pérdida, uso
              indebido, acceso no autorizado, divulgación y modificación.
              Nuestras protecciones incluyen firewalls y cifrado de datos,
              controles de acceso físico a los centros de datos y controles de
              autorización de acceso a la información. Si crees que tu cuenta se
              ha usado indebidamente, contáctanos siguiendo las instrucciones de
              la sección Preguntas.
            </div>
            <div className='diinco-tyc-card-title'>Información importante</div>
            <div className='diinco-tyc-card-text'>
              Inicio de sesión mediante redes sociales:
              <br />
              Cuando inicias sesión mediante una red social:
              <br />
              <ul className='uk-list uk-list-disc'>
                <li>
                  Si tienes una cuenta registrada con nosotros con la misma
                  dirección de correo electrónico que utilizas en la red social,
                  podrás acceder a esa cuenta registrada.
                </li>
                <li>
                  Si no tienes una cuenta registrada con nosotros con la misma
                  dirección de correo electrónico que utilizas en la red social,
                  abriremos una cuenta nueva para ti en nuestros sistemas.
                </li>
              </ul>
              <br />
              Puedes iniciar sesión mediante la red social o de forma normal,
              cada vez que abras tu cuenta. Si optas por abrir la sesión de
              forma normal, pero no tienes una contraseña porque te registraste
              a través de un inicio de sesión por red social, puedes solicitarla
              mediante el flujo de restablecimiento de contraseña.
              <br />
              <br />
              Ten en cuenta que si inicias sesión mediante una red social, se
              aplicarán las políticas para mantener la sesión abierta de la red
              social. Nosotros no tenemos control sobre estas políticas. Los
              proveedores de inicio de sesión mediante red social pueden tener
              políticas agresivas para mantener sesiones abiertas por lo que,
              por seguridad, te recomendamos lo siguiente:
              <ul className='uk-list uk-list-disc'>
                <li>
                  No uses un inicio de sesión con red social cuando accedas a
                  nuestros Servicios con un dispositivo público o compartido.
                </li>
                <li>
                  Visita el sitio del proveedor de la red social que provee el
                  servicio para obtener información sobre sus políticas y tus
                  opciones.
                </li>
              </ul>
              <br />
              Correos electrónicos amenazantes o no deseados:
              <br />
              <br />
              No toleramos el abuso de nuestros Servicios. No tienes
              autorización para agregar otros usuarios a tu lista de correo,
              llamarles o enviarles mensajes de texto para fines comerciales,
              aunque dicho usuario haya comprado algo que vendiste, a menos que
              el usuario te haya dado su consentimiento explícito para hacerlo.
              El envío de mensajes no deseados o amenazantes por correo
              electrónico o por texto va en contra de nuestros Terminos y
              Condiciones denunciar correos electrónicos no solicitados o spam,
              o bien fraudulentos, ponte en contacto con Atención a Clientes.
              <br />
              <br />
              Prácticas de privacidad de terceros
              <br />
              <br />
              Este Aviso de privacidad solo abarca la utilización y divulgación
              de los datos personales que nosotros recopilamos de ti. Si
              divulgas tus datos personales a otros, o si se eres redirigido a
              un sitio web de terceros, se aplicarán los avisos y las prácticas
              de privacidad de dichos terceros.
              <br />
              Nosotros no podemos garantizar la privacidad ni la seguridad de
              tus datos personales una vez que se los proporciones a un tercero.
              Te recomendamos revisar las políticas de privacidad y seguridad de
              tu socio comercial antes de realizar una transacción o compartir
              tus datos, incluso si los terceros son clientes inversionistas o
              profesionales en nuestro sitio.
              <br />
              <br />
              Privacidad de los menores
              <br />
              <br />
              Nuestros sitios web están destinados a un público general y
              nuestros Servicios no están dirigidos a menores de 18 años. No
              recopilamos a sabiendas datos personales de menores de 18 años.
            </div>
          </li>
          <li>
            <div className='diinco-tyc-card-title'>{lang['cookies']}</div>
            <div className='diinco-tyc-card-text'>
              Cuando visitas o utilizas nuestros sitios web, servicios,
              aplicaciones, herramientas o mensajería, nosotros, o nuestros
              proveedores de servicios autorizados, podríamos usar cookies,
              balizas Web y otras tecnologías similares para almacenar
              información con la finalidad de ofrecerte una mejor experiencia,
              más rápida y más segura, y para fines publicitarios.
              <br />
              <br />
              Está página se ha diseñado para ayudarte a conocer mejor estas
              tecnologías, y cómo las usamos en nuestros sitios web, servicios,
              aplicaciones y herramientas. En esta sección resumimos ciertos
              aspectos importantes que debes conocer sobre cómo usamos estas
              tecnologías. Para más información, consulta nuestra Notificación
              sobre cookies completa más abajo.
              <br />
              <br />
              Usamos cookies y tecnologías similares porque son necesarias para
              el funcionamiento de nuestros servicios, para ayudarnos a mejorar
              el rendimiento, para proporcionar funciones adicionales, o para
              ofrecerte publicidad personalizada y relevante. Usamos cookies y
              tecnologías similares que solo están en tu dispositivo mientras tu
              navegador esté activo (sesión), y cookies y tecnologías similares
              que permanecen en tu dispositivo más tiempo (persistentes). Puedes
              bloquear, eliminar o desactivar estas tecnologías si tu
              dispositivo lo permite. Para gestionar cookies y establecer tus
              preferencias utiliza la configuración de tu navegador o
              dispositivo.
              <br />
              <br />
              Cuando es posible, establecemos medidas de seguridad para impedir
              el acceso no autorizado a nuestras cookies y tecnologías
              similares. Para asegurarnos de que solo nosotros, o nuestros
              proveedores de servicios autorizados tenemos acceso a los datos de
              cookies utilizamos identificadores únicos.
              <br />
              <br />
              Los proveedores de servicios son empresas que nos ayudan en
              diversos aspectos de nuestro negocio, como, por ejemplo, a operar
              nuestros sitios web, servicios, aplicaciones, anuncios y
              herramientas. Trabajamos con ciertos proveedores de servicios
              autorizados que nos ayudan a ofrecerte anuncios personalizados en
              nuestros servicios y en otros sitios de Internet. Dichos
              proveedores de servicios pueden guardar cookies en tu dispositivo
              a través de nuestros servicios (cookies de terceros) y también
              pueden recopilar información que les ayude a identificar tu
              dispositivo, como, por ejemplo, tu dirección IP u otros
              identificadores únicos o de dispositivo.
              <br />
              <br />
            </div>
            <div className='diinco-tyc-card-title'>
              Qué son las cookies, balizas Web y tecnologías similares
            </div>
            <div className='diinco-tyc-card-text'>
              Como la mayoría de los sitios web y aplicaciones, usamos
              tecnologías que esencialmente son pequeños archivos de datos que
              se guardan en tu computadora, tablet, celular u otros dispositivos
              (denominados de forma genérica “dispositivo”) que nos permiten
              registrar cierta información siempre que visites nuestros sitios
              web, servicios, aplicaciones, mensajería y herramientas, o
              trabajas con ellos.
              <br />
              <br />
              Los nombres y tipos concretos de cookies, balizas Web y otras
              tecnologías similares que usamos pueden cambiar de vez en cuando.
              Para entender mejor esta notificación y nuestro uso estas
              tecnologías, consulta la siguiente lista no exhaustiva de
              terminología y definiciones:
              <br />
              <br />
              Cookies-Son pequeños archivos de texto (contienen normalmente
              letras y números) que se guardan en la memoria de tu navegador o
              dispositivo cuando visitas un sitio Web o ves un mensaje. A través
              de ellas, un sitio Web puede reconocer un dispositivo o un
              navegador concreto. Existen varios tipos:
              <br />
              <br />
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}
