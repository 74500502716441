import React, { useState, useContext, useEffect, useRef } from 'react'
import StandardView from '../../layouts/StandardView'
import { LanguageContext } from '../../context/language/context'
import Typography from '../../components/Typography'

import './styles/style.css'
import { IconButton } from '../../components/IconButton'
import Button from '../../components/Button'
import Avatar from '../../components/Avatar'
import { useHistory } from 'react-router-dom'
import _Auth from '../../context/auth'
import _Utils from '../../context/utils'
import { _handleErrorMessage } from '../../functions/error.functions'
import { serverValidation } from '../../functions/validation.functions'
import NoData from '../../components/NoData'
import { serialize } from '../../functions/validation.functions'
import DefaultBanner from '../../assets/images/banner.webp'
import SwitchInput from '../../components/SwitchInput'
import Modal from 'react-responsive-modal'
import { useForm } from 'react-hook-form'
import PlansModal from '../../components/AlbumPlansModal'

export const AlbumAdmin = () => {
  const { userLanguage } = useContext(LanguageContext)
  const languageContext = useContext(LanguageContext)
  const { token, features } = useContext(_Auth.Context)
  const { toggleLoader, enqueueNotification, notify } = useContext(
    _Utils.Context
  )
  var lang
  if (userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const [loading, setLoading] = useState(true)
  const history = useHistory()

  const [archived, setArchived] = useState(false)

  const [data, setData] = useState([])

  async function init() {
    try {
      toggleLoader(true)
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'albums/get/admin',
        {
          method: 'GET',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      await serverValidation(
        response,
        null,
        languageContext.userLanguage,
        (data) => {
          toggleLoader(false)
          setLoading(false)
          setData(data)
        },
        async (error) => {
          toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
          history.push('/dashboard')
        }
      )
    } catch (error) {
      toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
      history.push('/dashboard')
    }
    toggleLoader(false)
  }

  async function InitArchivado() {
    try {
      toggleLoader(true)
      let response = await fetch(
        process.env.REACT_APP_API_URL +
          'albums/get/admin?' +
          new URLSearchParams({
            privacity: archived
          }),
        {
          method: 'GET',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      await serverValidation(
        response,
        null,
        languageContext.userLanguage,
        (data) => {
          toggleLoader(false)
          setLoading(false)
          setData(data)
        },
        async (error) => {
          toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
          //history.push("/dashboard");
        }
      )
    } catch (error) {
      toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
      //history.push("/dashboard");
    }
    toggleLoader(false)
  }

  useEffect(() => {
    if (archived && token) {
      InitArchivado()
    } else if (!archived && token) {
      init()
    }
  }, [archived])

  useEffect(() => {
    if (token) init()
  }, [token])

  const updatePrivacity = async (id) => {
    await toggleLoader(true)
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'albums/update/privacity',
        {
          method: 'PATCH',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: id
          })
        }
      )
      await serverValidation(
        response,
        null,
        languageContext.userLanguage,
        async () => {
          await toggleLoader(false)
          notify(languageContext.dictionary['success'])
          init()
        },
        async (error) => {
          await toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    toggleLoader(false)
  }

  const archiveveAlbum = async (id) => {
    await toggleLoader(true)
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'albums/delete',
        {
          method: 'DELETE',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: id
          })
        }
      )
      await serverValidation(
        response,
        null,
        languageContext.userLanguage,
        async () => {
          await toggleLoader(false)
          notify(languageContext.dictionary['success'])
          init()
        },
        async (error) => {
          await toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    toggleLoader(false)
  }

  const restoreAlbum = async (id) => {
    await toggleLoader(true)
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'albums/restore',
        {
          method: 'DELETE',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: id
          })
        }
      )
      await serverValidation(
        response,
        null,
        languageContext.userLanguage,
        async () => {
          await toggleLoader(false)
          notify(languageContext.dictionary['success'])
          setArchived(true)
          setArchived(false)
        },
        async (error) => {
          await toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    toggleLoader(false)
  }

  const deleteAlbum = async () => {
    await toggleLoader(true)
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'albums/destroy',
        {
          method: 'DELETE',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: deleteId
          })
        }
      )
      await serverValidation(
        response,
        null,
        languageContext.userLanguage,
        async () => {
          await toggleLoader(false)
          setOpen(false)
          setArchived(true)
          setArchived(false)
          notify(languageContext.dictionary['success'])
          initCount()
        },
        async (error) => {
          await toggleLoader(false)
          setOpen(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      toggleLoader(false)
      setOpen(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    toggleLoader(false)
  }

  const [files, setFiles] = useState(null)
  const [idAlbum, setIdAlbum] = useState(null)
  const inputFile = useRef(null)
  const addFiles = (id) => {
    setIdAlbum(id)
    inputFile.current.click()
  }

  const { reset, setValue, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      files: []
    }
  })

  const changeHandler = (event) => {
    // Retrieve FileList boject
    const files = event.target.files

    // Convert FileList to array
    const arr = Array.from(files)
    setValue('files', arr)
    setFiles(arr)
  }

  useEffect(() => {
    if (files) {
      handleUpdate()
    }
  }, [files])

  const handleUpdate = async () => {
    await toggleLoader(true)
    const formData = serialize(getValues(), {
      dotsForObjectNotation: true,
      noArrayNotation: true,
      noFilesWithArrayNotation: true
    })
    formData.append('id', idAlbum)

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'albums/update',
        {
          method: 'PUT',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/formdata'
          },
          body: formData
        }
      )
      setFiles(null)
      reset({ files: [] })
      const answer = await response.json()
      if (answer.message === 'success') {
        notify(
          languageContext.dictionary['success'],
          setTimeout(() => {
            history.push('/Albums/' + idAlbum)
          }, 1000)
        )
      } else {
        if (answer.code === 3001) {
          let message = ''
          if (languageContext.userLanguage === 'es')
            message = answer.details.errors[0].msg.es
          else message = answer.details.errors[0].msg.en
          enqueueNotification(message, 'error')
        } else if (answer.code === 8001) {
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, answer),
            'error'
          )
        } else if (answer.code === 8011) {
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, answer),
            'error'
          )
          onOpenPlansModal()
        } else throw new Error('error inesperado')
      }
    } catch (error) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    await toggleLoader(false)
  }

  const [open, setOpen] = useState(false)
  const [deleteId, setDeleteId] = useState(null)

  function openModal(id) {
    setDeleteId(id)
    setOpen(true)
  }

  const [count, setCount] = useState(0)

  async function initCount() {
    try {
      toggleLoader(true)
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'albums/count',
        {
          method: 'GET',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      await serverValidation(
        response,
        null,
        languageContext.userLanguage,
        (data) => {
          toggleLoader(false)
          setLoading(false)
          setCount(data)
        },
        async (error) => {
          toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
          history.push('/dashboard')
        }
      )
    } catch (error) {
      toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
      history.push('/dashboard')
    }
    toggleLoader(false)
  }

  useEffect(() => {
    if (token) {
      initCount()
    }
  }, [token])

  function crearAlbum() {
    if (!count) {
      history.push('/Albums/Admin/Create')
    }
    if (features['profile-video'] === true) {
      setProplan(true)
    }
    history.push('/Albums/Admin/Create')
  }

  const [proplan, setProplan] = useState(false)

  const [openPlansModal, setOpenPlansModal] = useState(false)
  function onOpenPlansModal() {
    setOpenPlansModal(true)
  }

  function onClosePlansModal() {
    setOpenPlansModal(false)
  }

  return (
    <StandardView
      title={languageContext.dictionary.albumsManagement}
      loading={loading}
      links={[
        {
          link: '/Dashboard',
          title: languageContext.dictionary.dashboard
        }
      ]}
    >
      <div
        className='uk-width-1-1 uk-flex uk-flex-right'
        style={{ justifyContent: 'space-between' }}
      >
        <input
          type={'file'}
          style={{ display: 'none' }}
          multiple
          ref={inputFile}
          accept='image/*'
          onChange={changeHandler}
        />

        <Typography variant={'subtitle'} color={'secondary'}>
          {lang.profile}
        </Typography>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', marginRight: 20 }}>
            <Typography
              variant={'body2'}
              color='secondary'
              style={{ marginRight: 8, marginTop: 1 }}
            >
              {lang.seeArch}
            </Typography>
            <SwitchInput
              value={archived}
              onChange={() => setArchived(!archived)}
              className='album'
            />
          </div>
          <Button
            variant='text'
            disableMargin={true}
            color='secondary'
            containerStyle={{ width: 'fit-content' }}
            buttonStyle={{ padding: 0 }}
            onClick={() => crearAlbum()}
          >
            {lang.create}
          </Button>
        </div>
      </div>

      <div
        className='uk-grid-match uk-child-width-expand@s uk-text-center uk-margin-medium-top uk-margin-medium-bottom'
        data-uk-grid
      >
        {data && data.Albums && data.Albums.length > 0 ? (
          data.Albums.map((album, index) => {
            let tags = ''
            if (album.Tags && album.Tags.length) {
              tags = album.Tags.map((tag) => {
                return '#' + tag.name + ' '
              })
            }
            return (
              <div className='uk-width-1-3' key={index}>
                <div className='album-card-container'>
                  <div
                    className='album-card-cover'
                    onClick={() => history.push('/Albums/' + album.id)}
                  >
                    <img
                      src={
                        album.cover
                          ? process.env.REACT_APP_API_URL + album.cover
                          : DefaultBanner
                      }
                      alt='cover'
                    />
                    <div className='album-cover-gradient'></div>
                    <div className='album-cover-title'>{album.title}</div>
                    <div className='album-cover-tags'>{tags}</div>
                  </div>
                  <Typography
                    variant={'body2'}
                    style={{ textAlign: 'justify' }}
                    className={'album-description'}
                  >
                    {album.description}
                  </Typography>
                  <div className='album-buttons-container'>
                    <IconButton
                      icon={'edit'}
                      size={'mini'}
                      data-uk-tooltip={lang.edit}
                      onClick={() => history.push('/Albums/Admin/' + album.id)}
                    />
                    <IconButton
                      icon={'create'}
                      color='secondary'
                      size={'mini'}
                      data-uk-tooltip={lang.addImg}
                      onClick={() => addFiles(album.id)}
                    />
                    <IconButton
                      icon={album.public ? 'lock' : 'lockOpen'}
                      size={'mini'}
                      variant='borderless'
                      data-uk-tooltip={
                        album.public ? lang.private : lang.public
                      }
                      onClick={() => updatePrivacity(album.id)}
                    />
                    {album.deletedAt ? (
                      <IconButton
                        icon={'restore'}
                        size={'mini'}
                        variant='borderless'
                        data-uk-tooltip={lang.desarchive}
                        onClick={() => restoreAlbum(album.id)}
                      />
                    ) : (
                      <IconButton
                        icon={'box'}
                        size={'mini'}
                        variant='borderless'
                        data-uk-tooltip={lang.archive}
                        onClick={() => archiveveAlbum(album.id)}
                      />
                    )}
                    <IconButton
                      icon={'delete'}
                      size={'mini'}
                      variant='borderless'
                      data-uk-tooltip={lang.delete}
                      onClick={() => openModal(album.id)}
                    />
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <NoData />
        )}
      </div>
      <Typography
        variant={'subtitle'}
        color={'inherit'}
        style={{ color: '#ea6732' }}
      >
        {lang.project}
      </Typography>
      <div
        className='uk-grid-match uk-child-width-expand@s uk-text-center uk-margin-medium-top uk-margin-medium-bottom'
        data-uk-grid
      >
        {data && data.Projects && data.Projects.length > 0 ? (
          data.Projects.map((project, index) => {
            if (project.Stages && project.Stages.length && project.Stages[0]) {
              let album = project.Stages[0].Album
              let tags = ''
              if (album.Tags && album.Tags.length) {
                tags = album.Tags.map((tag) => {
                  return '#' + tag.name + ' '
                })
              }
              return (
                <div className='uk-width-1-3' key={index}>
                  <div className='album-card-container'>
                    <div
                      className='album-card-cover'
                      onClick={() => history.push('/Albums/' + album.id)}
                    >
                      <img
                        src={
                          album.cover
                            ? process.env.REACT_APP_API_URL + album.cover
                            : DefaultBanner
                        }
                        alt='cover'
                      />
                      <div className='album-cover-gradient'></div>
                      <div className='album-professionals'>
                        {project.User_Projects &&
                          project.User_Projects.length === 1 &&
                          project.User_Projects.map((user, index) => {
                            return (
                              <div key={'Avatar-' + index}>
                                <Avatar
                                  alt={user.User.name}
                                  src={user.User.image}
                                />
                                <div className='professional-name'>
                                  Arq. {user.User.name}
                                </div>
                              </div>
                            )
                          })}
                        {project.User_Projects &&
                          project.User_Projects.length > 1 &&
                          project.User_Projects.map((user, index) => {
                            if (index < 2) {
                              return (
                                <Avatar
                                  alt={user.User.name}
                                  src={user.User.image}
                                  key={index}
                                />
                              )
                            } else return null
                          })}
                        {project.User_Projects &&
                          project.User_Projects.length > 2 && (
                            <Avatar
                              total={project.User_Projects.length - 2}
                              className='uk-margin-small-right'
                              tooltip={
                                'pos: right; title: ' +
                                project.User_Projects.slice(2)
                                  .map((projectUser) => {
                                    return projectUser.User.name
                                  })
                                  .join('<br/>')
                              }
                            />
                          )}
                      </div>
                      <div className='album-cover-title'>{album.title}</div>
                      <div className='album-cover-tags'>{tags}</div>
                    </div>
                    <Typography
                      variant={'body2'}
                      style={{ textAlign: 'justify' }}
                      className={'album-description'}
                    >
                      {album.description}
                    </Typography>
                    <div className='album-buttons-container'>
                      <IconButton
                        icon={'edit'}
                        size={'mini'}
                        onClick={() =>
                          history.push('/Albums/Admin/' + album.id)
                        }
                      />
                      <IconButton
                        icon={'create'}
                        color='secondary'
                        size={'mini'}
                        onClick={() => addFiles(album.id)}
                      />
                      <IconButton
                        icon={album.public ? 'lock' : 'lockOpen'}
                        size={'mini'}
                        variant='borderless'
                        onClick={() => updatePrivacity(album.id)}
                      />
                      {/* <IconButton
                                                icon={"delete"}
                                                size={"mini"}
                                            /> */}
                    </div>
                  </div>
                </div>
              )
            } else return null
          })
        ) : (
          <NoData />
        )}
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        classNames={{ modal: 'modalAccept' }}
      >
        <Typography
          theme='light'
          variant={'body2'}
          fontWeight='600'
          align={'center'}
          style={{ maxWidth: '230px' }}
          className='uk-margin-small-top'
        >
          {lang.modalAccept}
        </Typography>
        <Button
          className='uk-margin-top'
          color='dark'
          onClick={() => deleteAlbum()}
          centered
        >
          {lang.accept}
        </Button>
      </Modal>
      <PlansModal
        open={openPlansModal}
        onClose={onClosePlansModal}
        proPlan={proplan}
        showClose={true}
        creating={false}
      />
    </StandardView>
  )
}
