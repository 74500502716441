import React, { useContext, useEffect, useRef, useState } from 'react'

import { LanguageContext } from '../../context/language/context'
import _Auth from '../../context/auth'
import _Utils from '../../context/utils'

import StandardView from '../StandardView'
import { CircularProgressbar } from 'react-circular-progressbar'
import { useParams } from 'react-router-dom'

import { _handleErrorMessage } from '../../functions/error.functions'
import { useHistory } from 'react-router-dom'
import DefaultBanner from '../../assets/images/banner.webp'

import './styles/style.css'
import 'react-circular-progressbar/dist/styles.css'
import Typography from '../../components/Typography'
import { IconButton } from '../../components/IconButton'
import Divider from '../../components/Divider'
import Avatar from '../../components/Avatar'
import { serverValidation } from '../../functions/validation.functions'
import Button from '../../components/Button'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import Modal from 'react-responsive-modal'
import ProjectGallery from '../../components/ProjectGallery'
import NoData from '../../components/NoData'
import { compressFilesInFormData } from '../../functions/files.functions'
import Card from '../../components/Card'
import ClickAwayListener from 'react-click-away-listener'
import { ReactComponent as DropdownIcon } from './assets/md-arrow-dropdown-3.svg'
import { ReactComponent as DropupIcon } from './assets/md-arrow-dropup-3.svg'
import PropTypes from 'prop-types'
import ProjectPlansModal from '../../components/ProjectPlansModal'
import DiincoLogo from '../../assets/images/logo_white.png'

const ProjectDashboard = ({ children }) => {
  const success = new URLSearchParams(window.location.search).get('success')
  const [openSuccess, setOpenSuccess] = useState(true)
  const onClose = () => setOpenSuccess(false)

  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const { id } = useParams()
  const history = useHistory()
  const { token, self, hasPermission, theme } = useContext(_Auth.Context)
  const { theme: tema } = languageContext
  const { enqueueNotification, notify, toggleLoader } = useContext(
    _Utils.Context
  )

  const [loading, setLoading] = useState(true)
  const [project, setProject] = useState(null)
  const [album, setAlbum] = useState(null)
  const [secret, setSecret] = useState(null)
  const [investment, setInvestment] = useState(0)
  //const [imOwner, setImOwner] = useState(false)
  const [progress, setProgress] = useState(0)
  const [projectFeatures, setProjectFeatures] = useState({})
  console.log('projectFeatures', projectFeatures, 'project', project)
  async function Init() {
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'projects/' + id,
        {
          method: 'GET',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      serverValidation(
        response,
        null,
        languageContext.userLanguage,
        (data) => {
          setLoading(false)
          setProject(data.project)
          setSecret(data.secret)
          setProjectFeatures(data.features)
          let inversion = 0
          // data?.project?.User_Projects.forEach((user_project) => {
          //   if (user_project.UserId === self.id && user_project.is_owner)
          //     setImOwner(true)
          // })
          if (data.project.Stages && data.project.Stages.length) {
            data.project.Stages.forEach((stage) => {
              if (stage.name === 'General' && stage.Album) setAlbum(stage.Album)

              if (stage.Payments && stage.Payments.length)
                stage.Payments.forEach((payment) => {
                  inversion = parseFloat(inversion) + parseFloat(payment.amount)
                })
            })
          }
          if (data.project.initial_inversion)
            inversion = inversion + data.project.initial_inversion
          setInvestment(inversion)
          if (data.project?.date_start && data.project?.date_end) {
            let start = new Date(data.project.date_start)
            let end = new Date(data.project.date_end)
            let today = new Date()
            if (today < start) {
              setProgress(0)
            } else {
              let q = Math.abs(today - start)
              let d = Math.abs(end - start)
              let _progress = Math.round((q / d) * 100)
              if (_progress < 0) _progress = 0
              if (_progress > 100) _progress = 100
              setProgress(_progress)
            }
          }
        },
        (error) => {
          setLoading(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
          history.push('/Dashboard')
        }
      )
    } catch (error) {
      setLoading(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
      history.push('/Dashboard')
    }
  }

  useEffect(() => {
    if (!token) return
    Init()
  }, [token])

  const [file, setFile] = useState(null)
  let bannerInput = useRef(null)

  function handleBannerChange() {
    let input = bannerInput.current
    input.click()
  }

  const changeHandler = (event) => {
    setFile(event.target.files[0])
  }

  useEffect(() => {
    if (file) {
      handleSubmission()
    }
  }, [file])

  const handleSubmission = async () => {
    if (project?.status === 'finished') return
    await toggleLoader(true)
    let formData = new FormData()

    formData.append('File', file)
    formData.append('id', project.id)

    formData = await compressFilesInFormData(formData, 'File')

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'projects/' + id + '/updateBanner',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/formdata'
          },

          body: formData
        }
      )

      await serverValidation(
        response,
        null,
        languageContext.userLanguage,
        async () => {
          notify(languageContext.dictionary['success'], () => {
            Init()
          })
        },
        async (error) => {
          if (error?.error?.code === 8011) {
            onOpenProjectPlansModal()
          }
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    setFile(null)
    await toggleLoader(false)
  }

  const [userModal, setUserModal] = useState(false)

  const [invitationModal, setInvitationModal] = useState(false)

  const modalRef = useRef(null)

  async function copySecret() {
    navigator.clipboard.writeText(
      secret ?
      // process.env.REACT_APP_URL + 'invitation/project/' + secret : 
      'https://diinco.com/invitation/project/' + secret : 
      ''
    )
    enqueueNotification(lang.copied, 'success')
  }

  const [modalConvert, setModalConvert] = useState(false)
  const [convertion, setConvertion] = useState('')
  const [userToConvert, setUserToConvert] = useState(null)
  //EXPEL
  const [modalExpel, setModalExpel] = useState(false)
  const [userToExpel, setUserToExpel] = useState(null)

  function openModalConvert(selection, selectedUser) {
    setConvertion(selection.toString())
    setUserToConvert(selectedUser)
    setModalConvert(true)
  }

  function openModalExpel(selectedUser) {
    setUserToExpel(selectedUser)
    setModalExpel(true)
  }

  async function submitConvertion() {
    await toggleLoader(true)
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'projects/' + project?.id + '/users',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            userId: userToConvert,
            roleSlug: convertion,
            projectId: project?.id
          })
        }
      )

      await serverValidation(
        response,
        null,
        languageContext.userLanguage,
        async () => {
          enqueueNotification(languageContext.dictionary.success, 'success')
          setModalConvert(false)
          await toggleLoader(false)
          Init()
        },
        async (error) => {
          await toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    setFile(null)
    await toggleLoader(false)
  }

  async function submitExpelation() {
    await toggleLoader(true)
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'projects/' + project?.id + '/users',
        {
          method: 'DELETE',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            userId: userToExpel,
            projectId: project?.id
          })
        }
      )

      await serverValidation(
        response,
        null,
        languageContext.userLanguage,
        async () => {
          enqueueNotification(languageContext.dictionary.success, 'success')
          setModalExpel(false)
          await toggleLoader(false)
          Init()
        },
        async (error) => {
          await toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    setFile(null)
    await toggleLoader(false)
  }

  const [openConfig, setOpenConfig] = useState(false)

  const [openProjectPlansModal, setOpenProjectPlansModal] = useState(false)
  function onOpenProjectPlansModal() {
    setOpenProjectPlansModal(true)
  }

  function onCloseProjectPlansModal() {
    setOpenProjectPlansModal(false)
  }

  return (
    <StandardView
      title={lang.panel}
      loading={loading}
      links={[
        {
          link: '/Dashboard/Projects',
          title: languageContext.dictionary.dashboard
        }
      ]}
    >
      {/* BANNER */}
      <div
        className='uk-grid uk-grid-collapse banner-relative-layout uk-width-1-1 uk-height-max-large uk-margin-small-bottom'
        style={{
          zIndex: 0,
          backgroundImage:
            // eslint-disable-next-line quotes
            "url('" +
            (project && project?.banner
              ? process.env.REACT_APP_API_URL + project?.banner
              : DefaultBanner) +
            // eslint-disable-next-line quotes
            "')"
          // backgroundImage: "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%), url('" + (project ? project?.banner ?? DefaultBanner : DefaultBanner) + "')",
        }}
      >
        {/* edit */}
        {project?.status !== 'finished' &&
          hasPermission('project-update', 'or', id, 'project') && (
            <>
              <IconButton
                icon='edit'
                style={{
                  position: 'absolute',
                  top: '12px',
                  right: '12px',
                  filter: 'drop-shadow(1px 1px 9px rgba(0, 0, 0, 0.5))'
                }}
                onClick={handleBannerChange}
              />
              <form>
                <input
                  type={'file'}
                  style={{ display: 'none' }}
                  ref={bannerInput}
                  accept='image/*'
                  onChange={changeHandler}
                />
              </form>
            </>
          )}

        {/* progress bar */}
        <div className='progress-absolute'>
          <CircularProgressbar
            value={progress}
            text={progress + '%'}
            strokeWidth={2}
            styles={{
              root: { borderRadius: '50%' },
              path: {
                stroke: '#f36f39',
                strokeLinecap: 'butt',
                transition: 'stroke-dashoffset 1.2s ease 0s',
                filter: 'drop-shadow(1px 1px 15px rgba(0, 0, 0, 1))',
                borderRadius: '50%'
              },
              trail: {
                filter: 'drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5))',
                borderRadius: '50%'
              },
              text: {
                fill: '#fff',
                fontSize: '18px',
                textShadow: '1px 1px 15px rgba(0, 0, 0, 1)'
              }
            }}
          />
        </div>

        {/* project title */}
        <Typography
          className='project-name-absolute-layout uppercase'
          variant='h6'
          align='right'
          fontWeight='bold'
          theme='dark'
        >
          {project ? project.title : 'TITLE'}
        </Typography>
      </div>

      {/* CONTENT */}
      <div
        data-uk-grid
        className='uk-padding uk-padding-remove-vertical uk-margin-remove-top'
      >
        {/* LEFT COLUMN */}
        <div className='uk-width-1-3'>
          <div
            style={{
              border: '1px solid #f9b74e',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '5px',
              borderRadius: '5px',
              marginBottom: 10
            }}
          >
            <Typography>{lang.status}&nbsp;</Typography>
            <Typography>{lang.statuses[project?.status]}</Typography>
          </div>
          {/* INVESTMENT */}
          <Typography variant='h4' fontWeight='normal' align='center'>
            $ {investment.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
          </Typography>

          <Typography variant='h6' align='center' disableMargin>
            {lang.investment}
          </Typography>

          <Divider className='uk-margin-small-bottom uk-margin-small-top' />

          {/* PROJECT MEMBERS */}
          <Typography variant='subtitle' fontWeight='normal' align='center'>
            {lang.projectMembers}
          </Typography>

          <div
            className='uk-flex uk-flex-center uk-flex-middle uk-padding-large uk-padding-remove-vertical'
            ref={modalRef}
          >
            {project &&
              project.User_Projects &&
              project.User_Projects.length ? (
              <>
                <div className='uk-inline'>
                  <div
                    className='uk-flex uk-flex-center uk-flex-middle'
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setUserModal(true)
                    }}
                  >
                    {project.User_Projects.map((projectUser, index) => {
                      //just displays first 6 users
                      if (index < 6)
                        return (
                          <Avatar
                            src={projectUser.User.image}
                            alt={projectUser.User.name}
                            key={'project-users-' + index}
                            className='uk-margin-small-right'
                            border={
                              projectUser.Role &&
                              projectUser.Role.name === 'project-admin'
                            }
                          />
                        )
                    })}
                    {project.User_Projects.length > 6 && (
                      <Avatar
                        total={project.User_Projects.length - 6}
                        className='uk-margin-small-right'
                        tooltip={
                          'pos: right; title: ' +
                          project.User_Projects.slice(6)
                            .map((projectUser) => {
                              return projectUser.User.name
                            })
                            .join('<br/>')
                        }
                      />
                    )}
                  </div>
                </div>

                <Modal
                  open={userModal}
                  onClose={() => setUserModal(false)}
                  center
                  container={modalRef.current}
                  closeIcon={<IconButton icon='close' component='div' />}
                  classNames={
                    theme !== 'dark'
                      ? { modal: 'customModalCRM-dark' }
                      : { modal: 'customModalCRM-light' }
                  }
                >
                  <Typography>
                    {project.User_Projects.length} {lang.members}
                  </Typography>

                  {project.User_Projects.map((projectUser, index) => (
                    <div
                      className='uk-flex uk-flex-middle uk-margin-bottom'
                      key={'project-users-modal-' + index}
                      style={{ position: 'relative' }}
                    >
                      <div>
                        <Avatar
                          src={projectUser.User.image}
                          alt={projectUser.User.name}
                          border={
                            projectUser.Role &&
                            projectUser.Role.name === 'project-admin'
                          }
                          className='uk-margin-small-right'
                        />
                      </div>
                      <div>
                        <Typography variant='subtitle2' disableMargin>
                          {projectUser.User.name}
                        </Typography>
                        <Typography variant='body2' disableMargin>
                          {projectUser.User.email}
                        </Typography>
                        {projectUser.Role &&
                          projectUser.Role.name === 'project-admin' && (
                            <Typography
                              color='secondary'
                              variant='body2'
                              disableMargin
                            >
                              {lang.admin}
                            </Typography>
                          )}
                      </div>
                      {!projectUser.is_owner &&
                        hasPermission('project-update', 'or', id, 'project') &&
                        projectUser.UserId !== self.id ? (
                        [
                          <a
                            key={'project-users-modal-' + index + '-1'}
                            className='column-more'
                            data-uk-icon='icon: more-vertical'
                            // style={{ position: "absolute", top: 0, right: 0 }}
                            style={
                              tema === 'light'
                                ? {
                                  filter: 'invert(100%)',
                                  position: 'absolute',
                                  top: 0,
                                  right: 0
                                }
                                : {
                                  position: 'absolute',
                                  top: 0,
                                  right: 0
                                }
                            }
                          />,
                          <div
                            data-uk-dropdown='mode: click; pos: bottom-justify'
                            key={'project-users-modal-' + index + '-2'}
                          >
                            <ul
                              className='uk-list'
                              style={{
                                marginBottom: 0
                              }}
                            >
                              <li
                                className='dropdown-option first-option uk-dropdown-close'
                                style={{
                                  textTransform: 'capitalize'
                                }}
                                onClick={() =>
                                  openModalConvert('member', projectUser.UserId)
                                }
                              >
                                {lang.member}
                              </li>
                              <li
                                className='dropdown-option uk-dropdown-close'
                                style={{
                                  textTransform: 'capitalize'
                                }}
                                onClick={() =>
                                  openModalConvert('admin', projectUser.UserId)
                                }
                              >
                                {lang.administrator}
                              </li>
                              {!projectUser.is_owner && (
                                <li
                                  className='last-option dropdown-option uk-dropdown-close'
                                  style={{
                                    textTransform: 'capitalize'
                                  }}
                                  onClick={() =>
                                    openModalExpel(projectUser.UserId)
                                  }
                                >
                                  {lang.expel}
                                </li>
                              )}
                            </ul>
                          </div>
                        ]
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </Modal>
                <Modal
                  open={modalConvert}
                  onClose={() => setModalConvert(false)}
                  center
                  container={modalRef.current}
                  closeIcon={<IconButton icon='close' component='div' />}
                  classNames={
                    theme !== 'dark'
                      ? { modal: 'customModalCRM-dark' }
                      : { modal: 'customModalCRM-light' }
                  }
                >
                  <Typography
                    color='secondary'
                    variant='h6'
                    style={{
                      marginTop: 20,
                      marginBottom: 50
                    }}
                  >
                    {lang.sureToConvert + ' "' + lang[convertion] + '"?'}
                  </Typography>
                  <div className='uk-flex uk-flex-around'>
                    <Button
                      onClick={() => setModalConvert(false)}
                      color={'grey'}
                    >
                      {languageContext.dictionary.cancel}
                    </Button>
                    <Button
                      color='secondary'
                      onClick={() => submitConvertion()}
                    >
                      {languageContext.dictionary.accept}
                    </Button>
                  </div>
                </Modal>
                <Modal
                  open={modalExpel}
                  onClose={() => setModalExpel(false)}
                  center
                  container={modalRef.current}
                  closeIcon={<IconButton icon='close' component='div' />}
                  classNames={
                    theme !== 'dark'
                      ? { modal: 'customModalCRM-dark' }
                      : { modal: 'customModalCRM-light' }
                  }
                >
                  <Typography
                    color='secondary'
                    variant='h6'
                    style={{
                      marginTop: 20,
                      marginBottom: 50
                    }}
                  >
                    {lang.sureToExpel}
                  </Typography>
                  <div className='uk-flex uk-flex-around'>
                    <Button
                      onClick={() => setModalConvert(false)}
                      color={'grey'}
                    >
                      {languageContext.dictionary.cancel}
                    </Button>
                    <Button
                      color='secondary'
                      onClick={() => submitExpelation()}
                    >
                      {languageContext.dictionary.accept}
                    </Button>
                  </div>
                </Modal>
              </>
            ) : (
              <Typography align='left' className='uk-margin-small-right'>
                {lang.noMembers}
              </Typography>
            )}
            {hasPermission('project-update', 'or', id, 'project') && (
              <IconButton
                icon='create'
                color='secondary'
                onClick={() => setInvitationModal(true)}
              />
            )}
            <Modal
              open={invitationModal}
              onClose={() => setInvitationModal(false)}
              center
              container={modalRef.current}
              closeIcon={<IconButton icon='close' component='div' />}
              classNames={
                theme !== 'dark'
                  ? { modal: 'customModalCRM-dark' }
                  : { modal: 'customModalCRM-light' }
              }
            >
              <Typography>
                {project &&
                  project.User_Projects.length > 0 &&
                  project.User_Projects.length}{' '}
                {lang.members}
              </Typography>

              {project &&
                project.User_Projects.length > 0 &&
                project.User_Projects.map((projectUser, index) => (
                  <div
                    className='uk-flex uk-flex-middle uk-margin-bottom'
                    key={'project-users-modal-' + index}
                  >
                    <div>
                      <Avatar
                        src={projectUser.User.image}
                        alt={projectUser.User.name}
                        border={
                          projectUser.Role &&
                          projectUser.Role.name === 'project-admin'
                        }
                        className='uk-margin-small-right'
                      />
                    </div>
                    <div>
                      <Typography variant='subtitle2' disableMargin>
                        {projectUser.User.name}
                      </Typography>
                      <Typography variant='body2' disableMargin>
                        {projectUser.User.email}
                      </Typography>
                      {projectUser.Role &&
                        projectUser.Role.name === 'project-admin' && (
                          <Typography
                            color='secondary'
                            variant='body2'
                            disableMargin
                          >
                            {lang.admin}
                          </Typography>
                        )}
                    </div>
                  </div>
                ))}
              <Divider className='uk-margin-small-bottom' />

              {/* If features["max-users"] is more than users in project */}
              {project?.User_Projects?.length >= 5 ? (
                <Typography variant='body' align={'center'}>
                  {lang.maxUsers}
                </Typography>
              ) : project?.User_Projects?.length >=
                projectFeatures['max-users'] ? (
                <React.Fragment>
                  <Button
                    size='auto'
                    color='secondary'
                    style={{
                      textTransform: 'capitalize'
                    }}
                    disableMargin
                    onClick={() => {
                      onOpenProjectPlansModal()
                    }}
                  >
                    {lang.invite}
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Typography variant='body' align={'center'}>
                    {lang.send}
                  </Typography>
                  <Typography
                    variant={'body2'}
                    color='secondary'
                    style={{ cursor: 'pointer' }}
                    uk-tooltip={'title: ' + lang.clickCopy + '; pos: bottom'}
                    onClick={copySecret}
                  >
                    {secret
                      ?
                      // process.env.REACT_APP_URL + 'invitation/project/' + secret
                      'https://diinco.com/invitation/project/' + secret
                      : ''}
                  </Typography>
                </React.Fragment>
              )}
            </Modal>
          </div>

          <Divider
            color='primary'
            className='uk-margin-small-bottom uk-margin-small-top'
          />

          {/* DATES AND BUTTONS */}
          <div
            data-uk-grid
            className='uk-child-width-1-1 uk-grid-small uk-padding-large uk-padding-remove-vertical'
          >
            {/* DATES */}
            <Typography align='center'>
              {lang['start']}:{' '}
              {project
                ? format(new Date(project.date_start + ' 00:00'), 'dd/MM/yyyy')
                : null}
            </Typography>

            <Typography align='center'>
              {lang['end']}:{' '}
              {project
                ? format(new Date(project.date_end + ' 00:00'), 'dd/MM/yyyy')
                : null}
            </Typography>

            {/* BUTTONS */}

            {/* edit project */}
            {/* {hasPermission(
                            "project-update",
                            "or",
                            id,
                            "project"
                        ) && (
                            <Link to={"/Projects/" + id + "/Edit"}>
                                <Button
                                    size="auto"
                                    color="secondary"
                                    disableMargin
                                >
                                    {lang["edit"]}
                                </Button>
                            </Link>
                        )} */}

            {/* <Button size="auto" disableMargin>
							{lang.seeLog}
						</Button> */}

            {/* <Link to={"/Projects/" + id + "/Stages"}>
                            <Button size="auto" color="primary" disableMargin>
                                {lang["phases"]}
                            </Button>
                        </Link>

                        <Link to={"/Projects/" + id + "/Documents"}>
                            <Button size="auto" color="grey" disableMargin>
                                {lang.documents}
                            </Button>
                        </Link> */}

            <div>
              <div
                style={{
                  position: 'relative',
                  cursor: 'pointer'
                }}
                onClick={() => setOpenConfig(!openConfig)}
                className='cj-dropdown-button-icon'
              >
                <Button
                  size='auto'
                  color='secondary'
                  disableMargin
                  style={{
                    ...(openConfig
                      ? {
                        borderBottomLeftRadius: '0px',
                        borderBottomRightRadius: '0px'
                      }
                      : {})
                    // paddingRight: "0px"
                  }}
                >
                  {languageContext.dictionary.configuration}
                </Button>
                <div
                  style={{
                    position: 'absolute',
                    top: '15%',
                    left: '85%'
                  }}
                >
                  {openConfig ? <DropupIcon /> : <DropdownIcon />}
                </div>
              </div>

              {openConfig && (
                <ClickAwayListener onClickAway={() => setOpenConfig(false)}>
                  <Card
                    padding={'0px'}
                    color='lightgrey'
                    variant={'filled'}
                    style={{
                      borderTopRightRadius: '0px',
                      borderTopLeftRadius: '0px'
                    }}
                  >
                    {hasPermission('project-update', 'or', id, 'project') && (
                      <Link
                        to={'/Projects/' + id + '/Edit'}
                        style={{
                          textDecoration: 'none'
                        }}
                      >
                        <Card
                          padding={'1px'}
                          variant={'filled'}
                          hover
                          color={'transparent'}
                          hoverColor={
                            languageContext.theme === 'light' ? 'light' : 'dark'
                          }
                          hoverVariant={'filled'}
                          style={{
                            borderRadius: '0px'
                          }}
                        >
                          <Typography
                            variant={'body'}
                            align='center'
                            style={{
                              textTransform: 'none'
                            }}
                            disableMargin
                          >
                            {lang['edit']}
                          </Typography>
                        </Card>
                      </Link>
                    )}
                    {projectFeatures['allow-stages'] ? (
                      <Link
                        to={'/Projects/' + id + '/Stages'}
                        style={{ textDecoration: 'none' }}
                      >
                        <Card
                          padding={'1px'}
                          variant={'filled'}
                          hover
                          color={'transparent'}
                          hoverColor={
                            languageContext.theme === 'light' ? 'light' : 'dark'
                          }
                          hoverVariant={'filled'}
                          style={{ borderRadius: '0px' }}
                        >
                          <Typography
                            disableMargin
                            variant={'body'}
                            align='center'
                            style={{
                              textTransform: 'none'
                            }}
                          >
                            {lang['phases']}
                          </Typography>
                        </Card>
                      </Link>
                    ) : (
                      <Card
                        padding={'1px'}
                        variant={'filled'}
                        hover
                        color={'transparent'}
                        hoverColor={
                          languageContext.theme === 'light' ? 'light' : 'dark'
                        }
                        hoverVariant={'filled'}
                        style={{ borderRadius: '0px', cursor: 'pointer' }}
                        onClick={() => {
                          onOpenProjectPlansModal()
                        }}
                      >
                        <Typography
                          disableMargin
                          variant={'body'}
                          align='center'
                          style={{
                            textTransform: 'none'
                          }}
                        >
                          {lang['phases']}
                        </Typography>
                      </Card>
                    )}

                    <Link
                      to={'/Projects/' + id + '/Documents'}
                      style={{ textDecoration: 'none' }}
                    >
                      <Card
                        padding={'1px'}
                        variant={'filled'}
                        hover
                        color={'transparent'}
                        hoverColor={
                          languageContext.theme === 'light' ? 'light' : 'dark'
                        }
                        hoverVariant={'filled'}
                        style={{ borderRadius: '0px' }}
                      >
                        <Typography
                          disableMargin
                          variant={'body'}
                          align='center'
                          style={{
                            textTransform: 'none'
                          }}
                        >
                          {lang.documents}
                        </Typography>
                      </Card>
                    </Link>
                    {project?.Prospect && project?.Prospect?.id && (
                      <Link
                        to={'/Prospect/' + project?.Prospect?.id + '/Proposal'}
                        style={{
                          textDecoration: 'none'
                        }}
                      >
                        <Card
                          padding={'1px'}
                          variant={'filled'}
                          hover
                          color={'transparent'}
                          hoverColor={
                            languageContext.theme === 'light' ? 'light' : 'dark'
                          }
                          hoverVariant={'filled'}
                          style={{
                            borderRadius: '0px'
                          }}
                        >
                          <Typography
                            disableMargin
                            variant={'body'}
                            align='center'
                            style={{
                              textTransform: 'none'
                            }}
                          >
                            {lang.proposal}
                          </Typography>
                        </Card>
                      </Link>
                    )}
                  </Card>
                </ClickAwayListener>
              )}
            </div>
            {!project?.hasPurchase && (
              <Button size='auto' onClick={onOpenProjectPlansModal}>
                {lang.improve}
              </Button>
            )}
          </div>
        </div>

        {/* RIGHT COLUMN */}
        <div className='uk-width-2-3'>
          <div data-uk-grid className='uk-grid-small'>
            <div className='uk-width-1-1'>
              <div
                data-uk-grid
                className='uk-grid-small uk-child-width-1-3'
                style={{ paddingRight: 15 }}
              >
                <Link to={'/Projects/' + id}>
                  <Button
                    variant='underlined'
                    size='auto'
                    style={{
                      color:
                        window.location.href.indexOf('Payments') <= -1 &&
                          window.location.href.indexOf('Reports') <= -1
                          ? undefined
                          : '#aeaeae',
                      textTransform: 'uppercase',
                      fontSize: '20px',
                      fontWeight: '400'
                    }}
                  >
                    {languageContext.dictionary.calendar}
                  </Button>
                </Link>

                <Link to={'/Projects/' + id + '/Reports'}>
                  <Button
                    variant='underlined'
                    color='grey'
                    size='auto'
                    style={{
                      color:
                        window.location.href.indexOf('Reports') > -1
                          ? undefined
                          : '#aeaeae',
                      textTransform: 'uppercase',
                      fontSize: '20px',
                      fontWeight: '400'
                    }}
                  >
                    {languageContext.dictionary.reports}
                  </Button>
                </Link>

                <Link to={'/Projects/' + id + '/Payments'}>
                  <Button
                    variant='underlined'
                    color='secondary'
                    size='auto'
                    style={{
                      color:
                        window.location.href.indexOf('Payments') > -1
                          ? undefined
                          : '#aeaeae',
                      textTransform: 'uppercase',
                      fontSize: '20px',
                      fontWeight: '400'
                    }}
                  >
                    {languageContext.dictionary.payments}
                  </Button>
                </Link>
              </div>
            </div>
            <div className='uk-width-1-1'>{children}</div>
          </div>
        </div>
      </div>

      {/* PROJECT GALLERY */}
      <div className='uk-margin-large-top'>
        <Typography variant='h6'>{lang.gallery}</Typography>

        <Divider className='uk-margin-medium-bottom' />

        {hasPermission('album-read', 'or', id, 'project') ? (
          <div className='uk-width-1'>
            {album &&
              ((album.Images && album.Images.length > 0) || album.cover) ? (
              <ProjectGallery album={album} projectId={project.id} />
            ) : (
              <>
                {project?.status !== 'finished' &&
                  hasPermission('album-create', 'or', id, 'project') ? (
                  <IconButton
                    icon='image'
                    color='grey'
                    shape='square'
                    size='large'
                    variant='outlined'
                    borderWidth='100%'
                    borderHeight='100%'
                    onClick={() => history.push('/Albums/Admin/' + album.id)}
                    style={{ width: 260, height: 196 }}
                  />
                ) : (
                  <NoData />
                )}
              </>
            )}
          </div>
        ) : (
          <NoData />
        )}
      </div>
      {project?.id && (
        <ProjectPlansModal
          projectId={project?.id}
          open={openProjectPlansModal}
          onClose={onCloseProjectPlansModal}
        />
      )}

      <Modal
        open={Boolean(success) && openSuccess}
        onClose={onClose}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        classNames={{ modal: 'custom-modal-buy' }}
      >
        <div data-uk-grid>
          <div>
            <Typography
              variant={'h6'}
              align={'center'}
              color={'secondary'}
              className={'uk-margin-medium-top uk-margin-medium-bottom'}
              style={{ textTransform: 'uppercase' }}
            >
              {lang.congratulations}
            </Typography>

            <img
              src={DiincoLogo}
              crossOrigin='use-credentials'
              className='dc-fit-cover'
            />

            <Typography
              variant={'h6'}
              align={'center'}
              color={'secondary'}
              className={'uk-margin-medium-top uk-margin-medium-bottom'}
              style={{ textTransform: 'uppercase' }}
            >
              {lang.enjoy}
            </Typography>
          </div>
        </div>
      </Modal>
    </StandardView>
  )
}

ProjectDashboard.defaultProps = {
  children: null
}

ProjectDashboard.propTypes = {
  children: PropTypes.node
}

export default ProjectDashboard
