import React, { useContext, useEffect, useState } from 'react'
// import _Auth from "../../context/auth";
import _Services from '../../context/services'
import _Utils from '../../context/utils'

//Components
import Slider from './Components/Slider/index'
import { CSelectInput } from '../../components/SelectInput/index'
import { useForm } from 'react-hook-form'
import './styles/style.css'

import { useHistory } from 'react-router-dom'
import { LanguageContext } from '../../context/language/context'
import Button from '../../components/Button'

import LogoEn from './assets/diinco_white.png'
import LogoEs from './assets/logo-blanco.png'
import Typography from '../../components/Typography'

import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Pagination, Autoplay])

export default function Index() {
  const { control, handleSubmit, setValue } = useForm()

  const {
    states,
    cities,
    services,
    checkUpdates,
    setSelectedCity,
    setSelectedState,
    selectedState,
    selectedCity,
    setSelectedServices
  } = useContext(_Services.Context)
  const { enqueueNotification } = useContext(_Utils.Context)

  const serevicesSelect = services.map((service) => ({
    value: service.title,
    label: service.title
  }))

  const statesSelect = states.map((state) => ({
    value: state,
    label: state.name
  }))

  const citiesSelect = cities.map((city) => ({
    value: city,
    label: city.name
  }))

  //para la version 1.0 esto en vez de ser proyectos son albumes
  // eslint-disable-next-line no-unused-vars
  const [projects, setProjects] = useState([])

  const [cambio, setCambio] = useState([0])

  async function InitFeatured() {
    fetch(process.env.REACT_APP_API_URL + 'projects/featured/home', {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        return response.json()
      })
      .then((projects) => {
        if (projects.code || projects.message) {
          throw new Error('Error')
        } else {
          let data = []
          projects.forEach((project) => {
            if (project.Users.length > 0 || project.Stage !== null)
              data.push(project)
          })
          setProjects(data)
        }
      })
      .catch(() => {
        enqueueNotification(
          languageContext.dictionary['somethingWrong'],
          'error'
        )
      })
  }

  useEffect(() => {
    async function Init() {
      await checkUpdates()
    }
    Init()
    InitFeatured()
  }, [])

  const history = useHistory()

  function onSubmit() {
    if (cambio === 1) {
      history.push('/Search/City/' + selectedCity.name + '/0')
    } else if (cambio !== 1) {
      history.push('/Search/City/' + selectedState.name + '/1')
    } else enqueueNotification('rellena como minimo el estado', 'error')
  }

  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  return (
    <div style={{ marginBottom: '30px' }}>
      <Slider />
      <div className='container-home'>
        <img
          alt='logo'
          src={languageContext.userLanguage === 'es' ? LogoEs : LogoEn}
        />
        <div
          className='uk-grid uk-width-1'
          data-uk-grid
          style={{ marginTop: 50 }}
        >
          <div className='uk-width-1'>
            <Typography
              style={{
                color: '#f9b94f',
                textTransform: 'uppercase'
              }}
              align='center'
              variant={'h6'}
            >
              {lang.startNew}
            </Typography>
          </div>
          <div className='uk-width-2-3' style={{ margin: '0px auto' }}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className='uk-grid uk-margin-top'>
                <div className='uk-width-1-2'>
                  <CSelectInput
                    name='service'
                    control={control}
                    label={lang['whatdo']}
                    className='select-face'
                    options={serevicesSelect}
                    isMulti
                    onChange={(e) => setSelectedServices(e)}
                    required
                    theme='dark'
                  />
                </div>
                <div className='uk-width-1-4'>
                  <CSelectInput
                    name='state'
                    control={control}
                    label={lang['state']}
                    className='select-face'
                    options={statesSelect}
                    onChange={(selectedOption) => {
                      setValue('city', null)

                      setSelectedState(selectedOption?.value)
                    }}
                    required
                    theme='dark'
                  />
                </div>
                <div className='uk-width-1-4'>
                  <CSelectInput
                    name='city'
                    control={control}
                    label={lang['city']}
                    className='select-face'
                    options={citiesSelect}
                    onChange={(selectedOption) => {
                      setSelectedCity(selectedOption.value)
                      setCambio(1)
                    }}
                    theme='dark'
                  />
                </div>
                <div className='uk-width-1'>
                  <Button
                    size='small'
                    color='secondary'
                    type='submit'
                    centered={true}
                  >
                    {languageContext.dictionary['search']}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <section>
                <div className="uk-container" style={{ marginTop: 100 }}>
                    <div className="uk-width-1">
                        <Typography
                            style={{
                                color: "#f9b94f",
                                textTransform: "uppercase",
                            }}
                            align="center"
                            variant={"h5"}
                        >
                            {lang.projects}
                        </Typography>
                    </div>
                    {/* PROYECTOS DESTACADOS
                    <Swiper
                        className="swiper-cards"
                        navigation
                        pagination
                        loop={true}
                        autoplay={{
                            delay: 6000,
                            disableOnInteraction: false,
                        }}
                    >
                        <SwiperSlide>
                            <div
                                className="uk-grid-match uk-child-width-expand@s uk-text-center uk-margin-medium-top uk-margin-medium-bottom uk-flex-center"
                                data-uk-grid
                            >
                                {projects &&
                                    projects.length > 0 &&
                                    projects.map((project, index) => {
                                        let tags = "";
                                        if (
                                            project.Tags &&
                                            project.Tags.length
                                        ) {
                                            tags = project.Tags.map((tag) => {
                                                return "#" + tag.name + " ";
                                            });
                                        }
                                        if (index < 3)
                                            return (
                                                <div
                                                    className="uk-width-1-3 face-cards"
                                                    key={index}
                                                >
                                                    <div className="album-card-container">
                                                        <div
                                                            className="album-card-cover"
                                                            onClick={() =>
                                                                history.push(
                                                                    "/Albums/" +
                                                                        project.id
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    process.env
                                                                        .REACT_APP_API_URL +
                                                                    project.cover
                                                                }
                                                                alt="banner"
                                                            />
                                                            <div className="album-cover-gradient"></div>
                                                            {project.Users &&
                                                                project.Users
                                                                    .length >
                                                                    0 && (
                                                                    <div className="album-professionals">
                                                                        {project.Users &&
                                                                            project
                                                                                .Users
                                                                                .length ===
                                                                                1 &&
                                                                            project.Users.map(
                                                                                (
                                                                                    user,
                                                                                    index
                                                                                ) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Avatar
                                                                                                alt={
                                                                                                    user.name
                                                                                                }
                                                                                                src={
                                                                                                    user.image
                                                                                                }
                                                                                            />
                                                                                            <div className="professional-name">
                                                                                                Arq.{" "}
                                                                                                {
                                                                                                    user.name
                                                                                                }
                                                                                            </div>
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        {project.Users &&
                                                                            project
                                                                                .Users
                                                                                .length >
                                                                                1 &&
                                                                            project.Users.map(
                                                                                (
                                                                                    user,
                                                                                    index
                                                                                ) => {
                                                                                    if (
                                                                                        index <
                                                                                        2
                                                                                    ) {
                                                                                        return (
                                                                                            <Avatar
                                                                                                alt={
                                                                                                    user.name
                                                                                                }
                                                                                                src={
                                                                                                    user.image
                                                                                                }
                                                                                                key={
                                                                                                    index
                                                                                                }
                                                                                            />
                                                                                        );
                                                                                    }
                                                                                }
                                                                            )}
                                                                        {project.Users &&
                                                                            project
                                                                                .Users
                                                                                .length >
                                                                                2 && (
                                                                                <Avatar
                                                                                    total={
                                                                                        project
                                                                                            .Users
                                                                                            .length -
                                                                                        2
                                                                                    }
                                                                                    className="uk-margin-small-right"
                                                                                    tooltip={
                                                                                        "pos: right; title: " +
                                                                                        project.User.slice(
                                                                                            2
                                                                                        )
                                                                                            .map(
                                                                                                (
                                                                                                    projectUser
                                                                                                ) => {
                                                                                                    return projectUser.name;
                                                                                                }
                                                                                            )
                                                                                            .join(
                                                                                                "<br/>"
                                                                                            )
                                                                                    }
                                                                                />
                                                                            )}
                                                                    </div>
                                                                )}
                                                            {project.Stage &&
                                                                project.Stage
                                                                    .Project
                                                                    .User_Projects &&
                                                                project.Stage
                                                                    .Project
                                                                    .User_Projects
                                                                    .length >
                                                                    0 && (
                                                                    <div className="album-professionals">
                                                                        {project
                                                                            .Stage
                                                                            .Project
                                                                            .User_Projects &&
                                                                            project
                                                                                .Stage
                                                                                .Project
                                                                                .User_Projects
                                                                                .length ===
                                                                                1 &&
                                                                            project.Stage.Project.User_Projects.map(
                                                                                (
                                                                                    relation,
                                                                                    index
                                                                                ) => {
                                                                                    let user =
                                                                                        relation.User;
                                                                                    return (
                                                                                        <>
                                                                                            <Avatar
                                                                                                alt={
                                                                                                    user.name
                                                                                                }
                                                                                                src={
                                                                                                    user.image
                                                                                                }
                                                                                            />
                                                                                            <div className="professional-name">
                                                                                                Arq.{" "}
                                                                                                {
                                                                                                    user.name
                                                                                                }
                                                                                            </div>
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        {project
                                                                            .Stage
                                                                            .Project
                                                                            .User_Projects &&
                                                                            project
                                                                                .Stage
                                                                                .Project
                                                                                .User_Projects
                                                                                .length >
                                                                                1 &&
                                                                            project.Stage.Project.User_Projects.map(
                                                                                (
                                                                                    relation,
                                                                                    index
                                                                                ) => {
                                                                                    let user =
                                                                                        relation.User;
                                                                                    if (
                                                                                        index <
                                                                                        2
                                                                                    ) {
                                                                                        return (
                                                                                            <Avatar
                                                                                                alt={
                                                                                                    user.name
                                                                                                }
                                                                                                src={
                                                                                                    user.image
                                                                                                }
                                                                                                key={
                                                                                                    index
                                                                                                }
                                                                                            />
                                                                                        );
                                                                                    }
                                                                                }
                                                                            )}
                                                                        {project
                                                                            .Stage
                                                                            .Project
                                                                            .User_Projects &&
                                                                            project
                                                                                .Stage
                                                                                .Project
                                                                                .User_Projects
                                                                                .length >
                                                                                2 && (
                                                                                <Avatar
                                                                                    total={
                                                                                        project
                                                                                            .Stage
                                                                                            .Project
                                                                                            .User_Projects
                                                                                            .length -
                                                                                        2
                                                                                    }
                                                                                    className="uk-margin-small-right"
                                                                                    tooltip={
                                                                                        "pos: right; title: " +
                                                                                        project.Stage.Project.User_Projects.slice(
                                                                                            2
                                                                                        )
                                                                                            .map(
                                                                                                (
                                                                                                    projectUser
                                                                                                ) => {
                                                                                                    return projectUser
                                                                                                        .User
                                                                                                        .name;
                                                                                                }
                                                                                            )
                                                                                            .join(
                                                                                                "<br/>"
                                                                                            )
                                                                                    }
                                                                                />
                                                                            )}
                                                                    </div>
                                                                )}
                                                            <div className="album-cover-title">
                                                                {project.title}
                                                            </div>
                                                            <div className="album-cover-tags">
                                                                {tags}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                    })}
                            </div>
                        </SwiperSlide>
                        {projects.length > 3 && (
                            <SwiperSlide>
                                <div
                                    className="uk-grid-match uk-child-width-expand@s uk-text-center uk-margin-medium-top uk-margin-medium-bottom uk-flex-center"
                                    data-uk-grid
                                >
                                    {projects &&
                                        projects.length > 0 &&
                                        projects.map((project, index) => {
                                            let tags = "";
                                            if (
                                                project.Tags &&
                                                project.Tags.length
                                            ) {
                                                tags = project.Tags.map(
                                                    (tag) => {
                                                        return (
                                                            "#" + tag.name + " "
                                                        );
                                                    }
                                                );
                                            }
                                            if (index > 2 && index < 6)
                                                return (
                                                    <div
                                                        className="uk-width-1-3 face-cards"
                                                        key={index}
                                                    >
                                                        <div className="album-card-container">
                                                            <div
                                                                className="album-card-cover"
                                                                onClick={() =>
                                                                    history.push(
                                                                        "/Albums/" +
                                                                            project.id
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        process
                                                                            .env
                                                                            .REACT_APP_API_URL +
                                                                        project.cover
                                                                    }
                                                                    alt="banner"
                                                                />
                                                                <div className="album-cover-gradient"></div>
                                                                {project.Users &&
                                                                    project
                                                                        .Users
                                                                        .length >
                                                                        0 && (
                                                                        <div className="album-professionals">
                                                                            {project.Users &&
                                                                                project
                                                                                    .Users
                                                                                    .length ===
                                                                                    1 &&
                                                                                project.Users.map(
                                                                                    (
                                                                                        user,
                                                                                        index
                                                                                    ) => {
                                                                                        return (
                                                                                            <>
                                                                                                <Avatar
                                                                                                    alt={
                                                                                                        user.name
                                                                                                    }
                                                                                                    src={
                                                                                                        user.image
                                                                                                    }
                                                                                                />
                                                                                                <div className="professional-name">
                                                                                                    Arq.{" "}
                                                                                                    {
                                                                                                        user.name
                                                                                                    }
                                                                                                </div>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            {project.Users &&
                                                                                project
                                                                                    .Users
                                                                                    .length >
                                                                                    1 &&
                                                                                project.Users.map(
                                                                                    (
                                                                                        user,
                                                                                        index
                                                                                    ) => {
                                                                                        if (
                                                                                            index <
                                                                                            2
                                                                                        ) {
                                                                                            return (
                                                                                                <Avatar
                                                                                                    alt={
                                                                                                        user.name
                                                                                                    }
                                                                                                    src={
                                                                                                        user.image
                                                                                                    }
                                                                                                    key={
                                                                                                        index
                                                                                                    }
                                                                                                />
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                )}
                                                                            {project.Users &&
                                                                                project
                                                                                    .Users
                                                                                    .length >
                                                                                    2 && (
                                                                                    <Avatar
                                                                                        total={
                                                                                            project
                                                                                                .Users
                                                                                                .length -
                                                                                            2
                                                                                        }
                                                                                        className="uk-margin-small-right"
                                                                                        tooltip={
                                                                                            "pos: right; title: " +
                                                                                            project.User.slice(
                                                                                                2
                                                                                            )
                                                                                                .map(
                                                                                                    (
                                                                                                        projectUser
                                                                                                    ) => {
                                                                                                        return projectUser.name;
                                                                                                    }
                                                                                                )
                                                                                                .join(
                                                                                                    "<br/>"
                                                                                                )
                                                                                        }
                                                                                    />
                                                                                )}
                                                                        </div>
                                                                    )}
                                                                {project.Stage &&
                                                                    project
                                                                        .Stage
                                                                        .Project
                                                                        .User_Projects &&
                                                                    project
                                                                        .Stage
                                                                        .Project
                                                                        .User_Projects
                                                                        .length >
                                                                        0 && (
                                                                        <div className="album-professionals">
                                                                            {project
                                                                                .Stage
                                                                                .Project
                                                                                .User_Projects &&
                                                                                project
                                                                                    .Stage
                                                                                    .Project
                                                                                    .User_Projects
                                                                                    .length ===
                                                                                    1 &&
                                                                                project.Stage.Project.User_Projects.map(
                                                                                    (
                                                                                        relation,
                                                                                        index
                                                                                    ) => {
                                                                                        let user =
                                                                                            relation.User;
                                                                                        return (
                                                                                            <>
                                                                                                <Avatar
                                                                                                    alt={
                                                                                                        user.name
                                                                                                    }
                                                                                                    src={
                                                                                                        user.image
                                                                                                    }
                                                                                                />
                                                                                                <div className="professional-name">
                                                                                                    Arq.{" "}
                                                                                                    {
                                                                                                        user.name
                                                                                                    }
                                                                                                </div>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            {project
                                                                                .Stage
                                                                                .Project
                                                                                .User_Projects &&
                                                                                project
                                                                                    .Stage
                                                                                    .Project
                                                                                    .User_Projects
                                                                                    .length >
                                                                                    1 &&
                                                                                project.Stage.Project.User_Projects.map(
                                                                                    (
                                                                                        relation,
                                                                                        index
                                                                                    ) => {
                                                                                        let user =
                                                                                            relation.User;
                                                                                        if (
                                                                                            index <
                                                                                            2
                                                                                        ) {
                                                                                            return (
                                                                                                <Avatar
                                                                                                    alt={
                                                                                                        user.name
                                                                                                    }
                                                                                                    src={
                                                                                                        user.image
                                                                                                    }
                                                                                                    key={
                                                                                                        index
                                                                                                    }
                                                                                                />
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                )}
                                                                            {project
                                                                                .Stage
                                                                                .Project
                                                                                .User_Projects &&
                                                                                project
                                                                                    .Stage
                                                                                    .Project
                                                                                    .User_Projects
                                                                                    .length >
                                                                                    2 && (
                                                                                    <Avatar
                                                                                        total={
                                                                                            project
                                                                                                .Stage
                                                                                                .Project
                                                                                                .User_Projects
                                                                                                .length -
                                                                                            2
                                                                                        }
                                                                                        className="uk-margin-small-right"
                                                                                        tooltip={
                                                                                            "pos: right; title: " +
                                                                                            project.Stage.Project.User_Projects.slice(
                                                                                                2
                                                                                            )
                                                                                                .map(
                                                                                                    (
                                                                                                        projectUser
                                                                                                    ) => {
                                                                                                        return projectUser
                                                                                                            .User
                                                                                                            .name;
                                                                                                    }
                                                                                                )
                                                                                                .join(
                                                                                                    "<br/>"
                                                                                                )
                                                                                        }
                                                                                    />
                                                                                )}
                                                                        </div>
                                                                    )}
                                                                <div className="album-cover-title">
                                                                    {
                                                                        project.title
                                                                    }
                                                                </div>
                                                                <div className="album-cover-tags">
                                                                    {tags}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                        })}
                                </div>
                            </SwiperSlide>
                        )}
                        {projects.length > 6 && (
                            <SwiperSlide>
                                <div
                                    className="uk-grid-match uk-child-width-expand@s uk-text-center uk-margin-medium-top uk-margin-medium-bottom uk-flex-center"
                                    data-uk-grid
                                >
                                    {projects &&
                                        projects.length > 0 &&
                                        projects.map((project, index) => {
                                            let tags = "";
                                            if (
                                                project.Tags &&
                                                project.Tags.length
                                            ) {
                                                tags = project.Tags.map(
                                                    (tag) => {
                                                        return (
                                                            "#" + tag.name + " "
                                                        );
                                                    }
                                                );
                                            }
                                            if (index > 5 && index < 8)
                                                return (
                                                    <div
                                                        className="uk-width-1-3 face-cards"
                                                        key={index}
                                                    >
                                                        <div className="album-card-container">
                                                            <div
                                                                className="album-card-cover"
                                                                onClick={() =>
                                                                    history.push(
                                                                        "/Albums/" +
                                                                            project.id
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        process
                                                                            .env
                                                                            .REACT_APP_API_URL +
                                                                        project.cover
                                                                    }
                                                                    alt="banner"
                                                                />
                                                                <div className="album-cover-gradient"></div>
                                                                {project.Users &&
                                                                    project
                                                                        .Users
                                                                        .length >
                                                                        0 && (
                                                                        <div className="album-professionals">
                                                                            {project.Users &&
                                                                                project
                                                                                    .Users
                                                                                    .length ===
                                                                                    1 &&
                                                                                project.Users.map(
                                                                                    (
                                                                                        user,
                                                                                        index
                                                                                    ) => {
                                                                                        return (
                                                                                            <>
                                                                                                <Avatar
                                                                                                    alt={
                                                                                                        user.name
                                                                                                    }
                                                                                                    src={
                                                                                                        user.image
                                                                                                    }
                                                                                                />
                                                                                                <div className="professional-name">
                                                                                                    Arq.{" "}
                                                                                                    {
                                                                                                        user.name
                                                                                                    }
                                                                                                </div>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            {project.Users &&
                                                                                project
                                                                                    .Users
                                                                                    .length >
                                                                                    1 &&
                                                                                project.Users.map(
                                                                                    (
                                                                                        user,
                                                                                        index
                                                                                    ) => {
                                                                                        if (
                                                                                            index <
                                                                                            2
                                                                                        ) {
                                                                                            return (
                                                                                                <Avatar
                                                                                                    alt={
                                                                                                        user.name
                                                                                                    }
                                                                                                    src={
                                                                                                        user.image
                                                                                                    }
                                                                                                    key={
                                                                                                        index
                                                                                                    }
                                                                                                />
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                )}
                                                                            {project.Users &&
                                                                                project
                                                                                    .Users
                                                                                    .length >
                                                                                    2 && (
                                                                                    <Avatar
                                                                                        total={
                                                                                            project
                                                                                                .Users
                                                                                                .length -
                                                                                            2
                                                                                        }
                                                                                        className="uk-margin-small-right"
                                                                                        tooltip={
                                                                                            "pos: right; title: " +
                                                                                            project.User.slice(
                                                                                                2
                                                                                            )
                                                                                                .map(
                                                                                                    (
                                                                                                        projectUser
                                                                                                    ) => {
                                                                                                        return projectUser.name;
                                                                                                    }
                                                                                                )
                                                                                                .join(
                                                                                                    "<br/>"
                                                                                                )
                                                                                        }
                                                                                    />
                                                                                )}
                                                                        </div>
                                                                    )}
                                                                {project.Stage &&
                                                                    project
                                                                        .Stage
                                                                        .Project
                                                                        .User_Projects &&
                                                                    project
                                                                        .Stage
                                                                        .Project
                                                                        .User_Projects
                                                                        .length >
                                                                        0 && (
                                                                        <div className="album-professionals">
                                                                            {project
                                                                                .Stage
                                                                                .Project
                                                                                .User_Projects &&
                                                                                project
                                                                                    .Stage
                                                                                    .Project
                                                                                    .User_Projects
                                                                                    .length ===
                                                                                    1 &&
                                                                                project.Stage.Project.User_Projects.map(
                                                                                    (
                                                                                        relation,
                                                                                        index
                                                                                    ) => {
                                                                                        let user =
                                                                                            relation.User;
                                                                                        return (
                                                                                            <>
                                                                                                <Avatar
                                                                                                    alt={
                                                                                                        user.name
                                                                                                    }
                                                                                                    src={
                                                                                                        user.image
                                                                                                    }
                                                                                                />
                                                                                                <div className="professional-name">
                                                                                                    Arq.{" "}
                                                                                                    {
                                                                                                        user.name
                                                                                                    }
                                                                                                </div>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            {project
                                                                                .Stage
                                                                                .Project
                                                                                .User_Projects &&
                                                                                project
                                                                                    .Stage
                                                                                    .Project
                                                                                    .User_Projects
                                                                                    .length >
                                                                                    1 &&
                                                                                project.Stage.Project.User_Projects.map(
                                                                                    (
                                                                                        relation,
                                                                                        index
                                                                                    ) => {
                                                                                        let user =
                                                                                            relation.User;
                                                                                        if (
                                                                                            index <
                                                                                            2
                                                                                        ) {
                                                                                            return (
                                                                                                <Avatar
                                                                                                    alt={
                                                                                                        user.name
                                                                                                    }
                                                                                                    src={
                                                                                                        user.image
                                                                                                    }
                                                                                                    key={
                                                                                                        index
                                                                                                    }
                                                                                                />
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                )}
                                                                            {project
                                                                                .Stage
                                                                                .Project
                                                                                .User_Projects &&
                                                                                project
                                                                                    .Stage
                                                                                    .Project
                                                                                    .User_Projects
                                                                                    .length >
                                                                                    2 && (
                                                                                    <Avatar
                                                                                        total={
                                                                                            project
                                                                                                .Stage
                                                                                                .Project
                                                                                                .User_Projects
                                                                                                .length -
                                                                                            2
                                                                                        }
                                                                                        className="uk-margin-small-right"
                                                                                        tooltip={
                                                                                            "pos: right; title: " +
                                                                                            project.Stage.Project.User_Projects.slice(
                                                                                                2
                                                                                            )
                                                                                                .map(
                                                                                                    (
                                                                                                        projectUser
                                                                                                    ) => {
                                                                                                        return projectUser
                                                                                                            .User
                                                                                                            .name;
                                                                                                    }
                                                                                                )
                                                                                                .join(
                                                                                                    "<br/>"
                                                                                                )
                                                                                        }
                                                                                    />
                                                                                )}
                                                                        </div>
                                                                    )}
                                                                <div className="album-cover-title">
                                                                    {
                                                                        project.title
                                                                    }
                                                                </div>
                                                                <div className="album-cover-tags">
                                                                    {tags}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                        })}
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
            </section> */}
      <section>
        <div className='uk-container' style={{ marginTop: 130 }}>
          <div className='uk-grid uk-flex-middle'>
            <div className='uk-width-1-2'>
              <div className='uk-width-1'>
                <Typography
                  style={{
                    color: '#f9b94f',
                    textTransform: 'uppercase',
                    fontSize: '50pt',
                    fontWeight: 600
                  }}
                  align='center'
                  variant={'h1'}
                >
                  DIINCO
                </Typography>
              </div>
              <div className='uk-width-1-1'>
                <Typography
                  variant={'body'}
                  style={{ fontSize: '16pt' }}
                  align={'center'}
                >
                  {lang.weAre}
                </Typography>
              </div>
              <div className='uk-width-1-1'>
                <a
                  href='https://view.forms.app/informaciondiinco/untitled-form-1'
                  target='_blank'
                  style={{ textDecoration: 'none' }}
                  rel='noreferrer'
                >
                  <Button
                    color='secondary'
                    className='uk-align-center uk-margin-medium-top'
                  >
                    {lang.scheduleDemo}
                  </Button>
                </a>
              </div>
            </div>
            <div className='uk-width-1-2'>
              <iframe
                width='560'
                height='315'
                src='https://www.youtube-nocookie.com/embed/6mgg0PyNILc'
                title='DIINCOr'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      {/* <section>
                <div
                    className="uk-container"
                    style={{ marginTop: 300, marginBottom: 80 }}
                >
                    <div className="">
                        <div className="uk-width-1-1">
                            <Typography
                                style={{ color: "#f9b94f" }}
                                align="center"
                                variant={"h5"}
                            >
                                {lang.contactUs}
                            </Typography>
                        </div>

                        <div
                            className="uk-width-2-3"
                            style={{ margin: "0px auto" }}
                        >
                            <form
                                onSubmit={handleSubmitAbout(onSubmitAbout)}
                                noValidate
                            >
                                <div className="uk-grid uk-margin-top uk-flex uk-flex-right">
                                    <div className="uk-width-1-2">
                                        <CTextInput
                                            label={lang.name}
                                            name="name"
                                            control={controlAbout}
                                            required
                                            maxLength={120}
                                        />
                                    </div>
                                    <div className="uk-width-1-2">
                                        <CTextInput
                                            label={lang.email}
                                            name="email"
                                            control={controlAbout}
                                            required
                                            type="email"
                                            maxLength={100}
                                        />
                                    </div>
                                    <div className="uk-width-1-1">
                                        <CTextInput
                                            label={lang.message}
                                            placeholder={
                                                lang.messagePlaceholder
                                            }
                                            name="message"
                                            type="textarea"
                                            control={controlAbout}
                                            required
                                            maxLength={2000}
                                            rows={5}
                                            charCount
                                        />
                                    </div>

                                    <div className="uk-width-1-1">
                                        <Button
                                            type="submit"
                                            disabled={disabled}
                                            centered={true}
                                            color="dark"
                                        >
                                            {languageContext.dictionary.send}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section> */}
      {/* PARTE DEL NEWSLETTER */}
      {/* <section>
                <div
                    className="uk-container"
                    style={{ marginTop: 230, marginBottom: 80 }}
                >
                    <div className="">
                        <div className="uk-width-1-1">
                            <Typography
                                style={{ color: "#ea6732" }}
                                align="center"
                                variant={"subtitle2"}
                            >
                                {lang.newsletter}
                            </Typography>
                        </div>

                        <div
                            className="uk-width-2-3"
                            style={{ margin: "0px auto" }}
                        >
                            <form
                                onSubmit={handleSubmitNews(onSubmitNews)}
                                noValidate
                            >
                                <div className="uk-grid uk-margin-top uk-flex uk-flex-right">
                                    <div
                                        className="uk-width-1-2"
                                        style={{ margin: "0px auto" }}
                                    >
                                        <CTextInput
                                            label={lang.email}
                                            name="email"
                                            control={controlNews}
                                            required
                                            type="email"
                                            maxLength={100}
                                        />
                                    </div>
                                    <div className="uk-width-1-1">
                                        <Button
                                            type="submit"
                                            disabled={disabled}
                                            centered={true}
                                            color="dark"
                                        >
                                            {languageContext.dictionary.send}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section> */}
    </div>
  )
}
