import React, { useContext, useEffect, useState } from 'react'
import { LanguageContext } from '../../../context/language/context'
import _Utils from '../../../context/utils'
import _Auth from '../../../context/auth'
import { _handleErrorMessage } from '../../../functions/error.functions'
import { serverValidation } from '../../../functions/validation.functions'
import NoData from '../../../components/NoData'
import Card from '../../../components/Card'
import Typography from '../../../components/Typography'
import { format } from 'date-fns'
import Avatar from '../../../components/Avatar'
import { Link } from 'react-router-dom'
import DefaultBanner from '../../../assets/images/banner.webp'
import './styles/style.css'
const ClientProjects = () => {
  const [loading, setLoading] = useState(true)
  const [projects, setProjects] = useState([])

  const { token, self } = useContext(_Auth.Context)
  const { enqueueNotification, HomeLoader } = useContext(_Utils.Context)
  const { theme, userLanguage, dictionary } = useContext(LanguageContext)

  var lang
  if (userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const Loader = (
    <div className='uk-width-1-1 uk-height-1-1'>
      <div
        className='uk-margin-auto uk-margin-medium-top uk-margin-medium-bottom uk-width-auto'
        style={{ width: 'fit-content' }}
      >
        <HomeLoader />
      </div>
    </div>
  )

  useEffect(() => {
    async function Init() {
      try {
        let response = await fetch(
          process.env.REACT_APP_API_URL + 'clients/' + self.id + '/projects',
          {
            credentials: 'include',
            mode: 'cors'
          }
        )

        await serverValidation(
          response,
          null,
          userLanguage,
          async (data) => {
            setLoading(false)
            setProjects(data)
          },
          async (error) => {
            setLoading(false)
            enqueueNotification(
              _handleErrorMessage(userLanguage, error.error),
              'error'
            )
          }
        )
      } catch (error) {
        setLoading(false)
        enqueueNotification(_handleErrorMessage(userLanguage, error), 'error')
      }
    }
    if (token) Init()
  }, [token])

  return (
    <>
      {loading ? (
        <>{Loader}</>
      ) : (
        <Card
          color={theme === 'dark' ? 'dark' : 'lightgrey'}
          variant={'filled'}
          className='uk-margin-medium-bottom uk-padding-large'
        >
          <div data-uk-grid className='uk-child-width-1-1'>
            {projects && projects.length ? (
              <>
                {projects.map((project, index) => {
                  if (index % 2 === 0)
                    return (
                      <div key={'project-' + index}>
                        <Link
                          to={'/Projects/' + project.id}
                          style={{
                            textDecoration: 'none'
                          }}
                        >
                          <Card
                            variant={'filled'}
                            padding='0px'
                            color={theme === 'dark' ? 'grey' : 'light'}
                          >
                            <div data-uk-grid>
                              <div
                                className='uk-width-1-3'
                                style={{
                                  minHeight: 217
                                }}
                              >
                                <div className='uk-padding'>
                                  <Typography>{project.title}</Typography>
                                  <Typography>
                                    {project.Location &&
                                      project.Location.City && (
                                        <>
                                          {project.Location?.City?.State?.name}{' '}
                                          {project.Location.City.State && (
                                            <>
                                              , {project.Location?.City?.name}
                                            </>
                                          )}
                                        </>
                                      )}
                                  </Typography>
                                  <Typography>
                                    {format(
                                      new Date(project.createdAt),
                                      'dd/MM/yyyy'
                                    )}
                                  </Typography>

                                  <div>
                                    {project &&
                                    project.User_Projects &&
                                    project.User_Projects.length ? (
                                      <div className='uk-inline'>
                                        <div
                                          className='uk-flex uk-flex-center uk-flex-middle'
                                          style={{
                                            cursor: 'pointer'
                                          }}
                                          // onClick={() => {setUserModal(true)}}
                                        >
                                          {project.User_Projects.forEach(
                                            (projectUser, index) => {
                                              //just displays first 6 users
                                              if (index < 3)
                                                return (
                                                  <Avatar
                                                    src={projectUser.User.image}
                                                    alt={projectUser.User.name}
                                                    key={
                                                      'project-users-' + index
                                                    }
                                                    className='uk-margin-small-right'
                                                    border={
                                                      projectUser.Role &&
                                                      projectUser.Role.name ===
                                                        'project-admin'
                                                    }
                                                  />
                                                )
                                            }
                                          )}
                                          {project.User_Projects.length > 3 && (
                                            <Avatar
                                              total={
                                                project.User_Projects.length - 3
                                              }
                                              className='uk-margin-small-right'
                                              tooltip={
                                                'pos: right; title: ' +
                                                project.User_Projects.slice(3)
                                                  .map((projectUser) => {
                                                    return projectUser.User.name
                                                  })
                                                  .join('<br/>')
                                              }
                                            />
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <Typography
                                        align='left'
                                        className='uk-margin-small-right'
                                      >
                                        {lang.noMembers}
                                      </Typography>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className='uk-width-2-3'>
                                <div
                                  style={{
                                    position: 'relative',
                                    height: '100%',
                                    backgroundImage: `url(${
                                      project?.banner
                                        ? process.env.REACT_APP_API_URL +
                                          project.banner
                                        : DefaultBanner
                                    })`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                  }}
                                >
                                  {project.status === 'finished' && (
                                    <React.Fragment>
                                      <div
                                        style={{
                                          position: 'absolute',
                                          bottom: '40%',
                                          left: '2.5%',
                                          width: '90%',
                                          padding: 10
                                        }}
                                      >
                                        <Typography
                                          disableMargin
                                          align={'center'}
                                          style={{
                                            backgroundColor:
                                              'rgba(249, 183, 78, 0.9)'
                                          }}
                                        >
                                          {dictionary.projectStatus?.finished}
                                        </Typography>
                                      </div>
                                    </React.Fragment>
                                  )}
                                </div>

                                {/* <img
                                                                    src={
                                                                        project?.banner
                                                                            ? process
                                                                                  .env
                                                                                  .REACT_APP_API_URL +
                                                                              project.banner
                                                                            : DefaultBanner
                                                                    }
                                                                    className="dc-fit-cover"
                                                                    style={{
                                                                        borderRadius:
                                                                            "0 5px 5px 0",
                                                                        height: "100%",
                                                                        width: "100%",
                                                                    }}
                                                                /> */}
                              </div>
                            </div>
                          </Card>
                        </Link>
                      </div>
                    )

                  return (
                    <div key={'project-' + index}>
                      <Link
                        to={'/Projects/' + project.id}
                        style={{
                          textDecoration: 'none'
                        }}
                      >
                        <Card
                          variant={'filled'}
                          padding='0px'
                          color={theme === 'dark' ? 'grey' : 'light'}
                        >
                          <div data-uk-grid>
                            <div className='uk-width-2-3'>
                              <div
                                style={{
                                  position: 'relative',
                                  height: '100%',
                                  backgroundImage: `url(${
                                    project?.banner
                                      ? process.env.REACT_APP_API_URL +
                                        project.banner
                                      : DefaultBanner
                                  })`,
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center'
                                }}
                              >
                                {project.status === 'finished' && (
                                  <React.Fragment>
                                    <div
                                      style={{
                                        position: 'absolute',
                                        bottom: '40%',
                                        left: '2.5%',
                                        width: '90%',
                                        padding: 10
                                      }}
                                    >
                                      <Typography
                                        disableMargin
                                        align={'center'}
                                        style={{
                                          backgroundColor:
                                            'rgba(249, 183, 78, 0.9)'
                                        }}
                                      >
                                        {dictionary.projectStatus?.finished}
                                      </Typography>
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                              {/* <img
                                                                src={
                                                                    project?.banner
                                                                        ? process
                                                                              .env
                                                                              .REACT_APP_API_URL +
                                                                          project.banner
                                                                        : DefaultBanner
                                                                }
                                                                className="dc-fit-cover"
                                                                style={{
                                                                    borderRadius:
                                                                        "5px 0 0 5px",
                                                                    height: "100%",
                                                                    width: "100%",
                                                                }}
                                                            /> */}
                            </div>
                            <div
                              className='uk-width-1-3'
                              style={{
                                minHeight: 217
                              }}
                            >
                              <div className='uk-padding'>
                                <Typography>{project.title}</Typography>
                                <Typography>
                                  {project.Location &&
                                    project.Location.City && (
                                      <>
                                        {project.Location?.City?.State?.name}{' '}
                                        {project.Location.City.State && (
                                          <>, {project.Location?.City?.name}</>
                                        )}
                                      </>
                                    )}
                                </Typography>
                                <Typography>
                                  {format(
                                    new Date(project.createdAt),
                                    'dd/MM/yyyy'
                                  )}
                                </Typography>

                                <div>
                                  {project &&
                                  project.User_Projects &&
                                  project.User_Projects.length ? (
                                    <div className='uk-inline'>
                                      <div
                                        className='uk-flex uk-flex-center uk-flex-middle'
                                        style={{
                                          cursor: 'pointer'
                                        }}
                                        // onClick={() => {setUserModal(true)}}
                                      >
                                        {project.User_Projects.forEach(
                                          (projectUser, index) => {
                                            //just displays first 6 users
                                            if (index < 3)
                                              return (
                                                <Avatar
                                                  src={projectUser.User.image}
                                                  alt={projectUser.User.name}
                                                  key={'project-users-' + index}
                                                  className='uk-margin-small-right'
                                                  border={
                                                    projectUser.Role &&
                                                    projectUser.Role.name ===
                                                      'project-admin'
                                                  }
                                                />
                                              )
                                          }
                                        )}
                                        {project.User_Projects.length > 3 && (
                                          <Avatar
                                            total={
                                              project.User_Projects.length - 3
                                            }
                                            className='uk-margin-small-right'
                                            tooltip={
                                              'pos: right; title: ' +
                                              project.User_Projects.slice(3)
                                                .map((projectUser) => {
                                                  return projectUser.User.name
                                                })
                                                .join('<br/>')
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <Typography
                                      align='left'
                                      className='uk-margin-small-right'
                                    >
                                      {lang.noMembers}
                                    </Typography>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Link>
                    </div>
                  )
                })}
              </>
            ) : (
              <NoData className='uk-margin-medium-top' />
            )}
          </div>
        </Card>
      )}
    </>
  )
}

export default ClientProjects
