import React, { useContext } from 'react'
import Modal from 'react-responsive-modal'
import { IconButton } from '../IconButton'
import Typography from '../Typography'
import './styles/style.css'
import PlansImage from './assets/plans.webp'
import Button from '../Button'
import { LanguageContext } from '../../context/language/context'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const PlansModal = ({ open, onClose }) => {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      closeIcon={<IconButton icon='close' component='div' />}
      classNames={{ modal: 'customModalCRM-dark' }}
    >
      <Typography
        color={'secondary'}
        className={'uk-margin-medium-top uk-margin-medium-bottom'}
      >
        {lang.upgrade}
      </Typography>

      <img
        alt=''
        src={PlansImage}
        crossOrigin='use-credentials'
        className='dc-fit-cover'
      />
      <Link to='/subscription'>
        <Button size='auto' className='uk-margin-medium-top'>
          {lang.button}
        </Button>
      </Link>
    </Modal>
  )
}

PlansModal.defaultProps = {
  open: false,
  onClose: () => {}
}

PlansModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default PlansModal
