import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import CustomCalendar from '../../../components/CustomCalendar'
import { LanguageContext } from '../../../context/language/context'
import { serverValidation } from '../../../functions/validation.functions'
import _Utils from '../../../context/utils'
import _Auth from '../../../context/auth'
import { _handleErrorMessage } from '../../../functions/error.functions'

const ProjectCalendar = () => {
  const { id } = useParams()
  const [events, setEvents] = useState([])
  const [status, setStatus] = useState(null)
  const calendar = useRef(null)
  const { userLanguage } = useContext(LanguageContext)

  const { token } = useContext(_Auth.Context)
  const { enqueueNotification, HomeLoader } = useContext(_Utils.Context)
  const [loading, setLoading] = useState(true)

  const Loader = (
    <div className='uk-width-1-1 uk-height-1-1'>
      <div
        className='uk-margin-auto uk-margin-medium-top uk-width-auto'
        style={{ width: 'fit-content' }}
      >
        <HomeLoader />
      </div>
    </div>
  )

  useEffect(() => {
    async function Init() {
      try {
        let response = await fetch(
          process.env.REACT_APP_API_URL + 'projects/' + id + '/events',
          {
            credentials: 'include',
            mode: 'cors'
          }
        )

        serverValidation(
          response,
          null,
          userLanguage,
          async (data) => {
            setLoading(false)
            let tmp = []

            if (data && data.events && data.events.length) {
              data.events.forEach((event) => {
                tmp.push({
                  date: new Date(event.date_end),
                  data: {
                    title: event.title,
                    date: new Date(event.date_end),
                    date_start: new Date(event.date_start),
                    date_end: new Date(event.date_end),
                    description: event.body,
                    status: event.status,
                    id: event.id,
                    stage: 0
                  }
                })
              })
            }
            setStatus(data.status)
            setEvents([...tmp])
          },
          async (error) => {
            setLoading(false)
            enqueueNotification(
              _handleErrorMessage(userLanguage, error.error),
              'error'
            )
          }
        )
      } catch (error) {
        setLoading(false)
        enqueueNotification(_handleErrorMessage(userLanguage, error), 'error')
      }
    }
    if (token) Init()
  }, [token])
  return (
    <div>
      {loading ? (
        <>{Loader}</>
      ) : (
        <CustomCalendar
          dates={events}
          inputRef={calendar}
          locale={userLanguage === 'es' ? 'es-ES' : 'en-US'}
          projectId={id}
          projectStatus={status}
        />
      )}
    </div>
  )
}

export default ProjectCalendar
