import React from 'react'

import ReportCard from '../../components/ReportCard'
import karla from './assets/karla.png'

export default function Temp() {
  return (
    <div>
      <ReportCard
        title={'Hello'}
        name={'Karla Tera'}
        img={karla}
        date={'16/11/2021'}
      />
    </div>
  )
}
