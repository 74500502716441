import React, { useContext, useState, useRef } from 'react'
import _Utils from '../../../context/utils'
import { Droppable } from 'react-beautiful-dnd'
import ArchivedTask from './archivedTask'
import '../styles/style.css'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import { format } from 'date-fns'
import Avatar from '../../Avatar'
import { _handleErrorMessage } from '../../../functions/error.functions'
import PropTypes from 'prop-types'
import { LanguageContext } from '../../../context/language/context'

import Button from '../../Button'
import Typography from '../../Typography'
import { useHistory } from 'react-router'
import { IconButton } from '../../IconButton'

const getListStyle = (isDraggingOver) => ({
  // change background colour if dragging
  transition: 'background-color 0.2s ease',
  background: isDraggingOver ? '#999999' : 'var(--card-color)',
  borderBottomRightRadius: '20px',
  borderBottomLeftRadius: '20px'
})

export default function Column(props) {
  const history = useHistory()
  const languageContext = useContext(LanguageContext)
  const { theme } = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('../languages/en.json')
  } else {
    lang = require('../languages/es.json')
  }
  const myRef = useRef(null)

  const { enqueueNotification } = useContext(_Utils.Context)

  const [openSolicitude, setOpenSolicitude] = useState(false)

  const [archived, setArchived] = useState(null)

  function onOpenSolicitude(info) {
    setArchived(info)
    setOpenSolicitude(true)
  }

  function onCloseSolicitude() {
    setOpenSolicitude(false)
  }

  async function onUnarchive() {
    try {
      let data = await fetch(
        process.env.REACT_APP_API_URL + 'prospects/unarchive',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ id: archived.id })
        }
      )
      await data.json()
      setOpenSolicitude(false)
      history.go(0)
    } catch (error) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  return (
    <div>
      <div className='yellow-column-container' style={{ borderRadius: 20 }}>
        {/* title */}
        <div className='orange-column-header' ref={myRef}>
          <h3 className={'column-title'} style={{ paddingLeft: '0px' }}>
            {lang.archived}
          </h3>
        </div>
        <Droppable droppableId={'archived'} type='task'>
          {/* task list */}
          {(provided, snapshot) => (
            <div
              className='yellow-task-list-container'
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {props.tasks.map((task, index) => (
                <div onClick={() => onOpenSolicitude(task)} key={task.id}>
                  <ArchivedTask key={task.id} task={task} index={index} />
                </div>
              ))}
              <Modal
                container={myRef.current}
                open={openSolicitude}
                onClose={onCloseSolicitude}
                center
                closeIcon={<IconButton icon='close' component='div' />}
                classNames={
                  theme === 'dark'
                    ? {
                        modal: 'customModalCRM-dark'
                      }
                    : { modal: 'customModalCRM-light' }
                }
              >
                <div className='oski-sol-container'>
                  <div className='uk-flex uk-flex-between uk-flex-wrap'>
                    <Typography variant='h6' color='secondary'>
                      {archived?.title}
                    </Typography>
                    <Typography variant='subtitle'>
                      {archived &&
                        format(
                          new Date(archived?.createdAt),
                          'dd/MM/yyyy HH:mm'
                        )}
                    </Typography>
                  </div>
                  <div className='uk-flex uk-flex-column'>
                    {archived?.User_Prospects?.map((user, index) => {
                      return (
                        <div
                          key={index}
                          className='uk-flex uk-flex-middle uk-flex-wrap uk-margin-bottom'
                        >
                          <Avatar
                            src={user.User.image}
                            alt={user.User.name}
                            style={{
                              marginRight: 10
                            }}
                          />
                          <Typography>{user.User.name}</Typography>
                        </div>
                      )
                    })}
                  </div>
                  <Typography>{archived?.description}</Typography>
                  <div className='uk-flex uk-flex-center uk-margin-large-top'>
                    <Button onClick={onUnarchive}>{lang.unarchive}</Button>
                  </div>
                </div>
              </Modal>
            </div>
          )}
        </Droppable>
      </div>
    </div>
  )
}

Column.defaultProps = {
  tasks: []
}

Column.propTypes = {
  tasks: PropTypes.array
}
