import React, { useContext } from 'react'
import './styles/style.css'
import PropTypes from 'prop-types'
import { LanguageContext } from '../../context/language/context'
import ReactDOMServer from 'react-dom/server'

// Defining the HTML tag that the component will support
const variantsMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle: 'h6',
  subtitle2: 'h6',
  body: 'p',
  body2: 'p',
  div: 'div'
}

export default function Typography(props) {
  const {
    variant,
    className,
    color,
    fontWeight,
    align,
    disableMargin,
    children,
    style,

    theme: themeFromProps,
    ...rest
  } = props
  const Component = variant ? variantsMapping[variant] ?? 'p' : 'p'

  const { theme } = useContext(LanguageContext)

  let themeProp = {}

  if (themeFromProps !== 'default') {
    if (themeFromProps === 'inverted')
      themeProp['data-input-theme'] = theme === 'light' ? 'dark' : 'light'
    else themeProp['data-input-theme'] = themeFromProps
  }
  return (
    <Component
      className={[
        'cj-text-component',
        'cj-text-component-' + variant.toLowerCase(),
        'cj-text-component-' + color.toLowerCase(),
        disableMargin ? '' : 'cj-text-component-margin',
        className ?? ''
        // theme !== "default" && "cj-text-theme-" + theme
      ].join(' ')}
      style={{
        fontWeight: fontWeight,
        textAlign: align,
        overflowWrap: 'break-word',
        hyphens: 'auto',
        ...style
      }}
      {...rest}
      {...themeProp}
      dangerouslySetInnerHTML={{
        __html:
          typeof children === 'object'
            ? ReactDOMServer.renderToStaticMarkup(children)
            : children
      }}
    >
      {/* {children} */}
    </Component>
  )
}

Typography.defaultProps = {
  variant: 'body',
  color: 'primary',
  fontWeight: undefined,
  align: 'left',
  disableMargin: false,

  className: null,
  children: null,
  style: {},
  theme: 'default'
}

Typography.propTypes = {
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle',
    'subtitle2',
    'body',
    'body2',
    'div'
  ]),
  color: PropTypes.oneOf(['primary', 'secondary', 'inverted', 'inherit']),
  fontWeight: PropTypes.string,
  align: PropTypes.oneOf(['center', 'inherit', 'justify', 'left', 'right']),
  disableMargin: PropTypes.bool,

  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  style: PropTypes.object,
  theme: PropTypes.oneOf(['default', 'light', 'dark', 'inverted'])
}
