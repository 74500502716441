import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import StandardView from '../StandardView'
import { LanguageContext } from '../../context/language/context'
import Button from '../../components/Button'
import { Link } from 'react-router-dom'

const ClientDashboard = ({ children }) => {
  const { dictionary } = useContext(LanguageContext)
  return (
    <StandardView title={dictionary.dashboard}>
      <div
        data-uk-grid
        className='uk-child-width-1-1 uk-padding-large uk-padding-remove-top'
      >
        <div>
          <div data-uk-grid className='uk-child-width-1-3'>
            <div>
              <Link to={'/Dashboard'}>
                <Button
                  size='auto'
                  variant='underlined'
                  disableMargin
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '20px',
                    fontWeight: '400'
                  }}
                >
                  {dictionary.proposals}
                </Button>
              </Link>
            </div>
            <div>
              <Link to={'/Dashboard/Calendar'}>
                <Button
                  size='auto'
                  variant='underlined'
                  color='grey'
                  disableMargin
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '20px',
                    fontWeight: '400'
                  }}
                >
                  {dictionary.calendar}
                </Button>
              </Link>
            </div>
            <div>
              <Link to={'/Dashboard/Projects'}>
                <Button
                  size='auto'
                  variant='underlined'
                  color='secondary'
                  disableMargin
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '20px',
                    fontWeight: '400'
                  }}
                >
                  {dictionary.projects}
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div>{children}</div>
      </div>
    </StandardView>
  )
}

ClientDashboard.propTypes = {
  children: PropTypes.any
}

export default ClientDashboard
