import React, { useContext, useState, useRef, useEffect } from 'react'
import _Utils from '../../../context/utils'
import { Droppable } from 'react-beautiful-dnd'
import RequestCard from './requestCard'
import '../styles/style.css'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import { format } from 'date-fns'
import Avatar from '../../Avatar'
import { _handleErrorMessage } from '../../../functions/error.functions'
import { serverValidation } from '../../../functions/validation.functions'

import { LanguageContext } from '../../../context/language/context'

import { CTextInput } from '../../../components/TextInput'
import { useForm } from 'react-hook-form'
import Button from '../../Button'
import Typography from '../../Typography'
import { useHistory } from 'react-router'
import PlansModal from '../../PlansModal'
import { IconButton } from '../../IconButton'

import PropTypes from 'prop-types'

const getListStyle = (isDraggingOver) => ({
  // change background colour if dragging
  transition: 'background-color 0.2s ease',
  background: isDraggingOver ? '#999999' : 'var(--card-color)',
  borderBottomRightRadius: '20px',
  borderBottomLeftRadius: '20px'
})

export default function Column(props) {
  const history = useHistory()
  const languageContext = useContext(LanguageContext)
  const { theme } = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('../languages/en.json')
  } else {
    lang = require('../languages/es.json')
  }
  const myRef = useRef(null)

  const { toggleLoader, enqueueNotification } = useContext(_Utils.Context)

  const [open, setOpen] = useState(false)
  const [openSolicitude, setOpenSolicitude] = useState(false)

  const [solicitude, setSolicitude] = useState(null)

  const [disableButton, setDisableButton] = useState(false)

  function onOpenModal() {
    setOpen(true)
  }

  function onCloseModal() {
    setOpen(false)
  }

  function onOpenSolicitude(info) {
    setSolicitude(info)
    setOpenSolicitude(true)
  }

  function onCloseSolicitude() {
    setOpenSolicitude(false)
    setSolicitude(null)
  }

  function handleNewCard() {
    onOpenModal()
  }

  const {
    reset: resetCard,
    control: controlCard,
    setError: setErrorCard,
    formState: formStateCard,
    handleSubmit
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: '',
      description: '',
      email: ''
    }
  })

  const [disabledButton, setDisabledButton] = useState(false)

  async function onSubmit(_data) {
    let data = _data
    data = { ...data, columnId: props.column.id }
    setDisabledButton(true)
    await toggleLoader(true)

    let response = await props.newSolicitude(
      props.column.id,
      data,
      setErrorCard
    )
    if (response) {
      resetCard()
      setOpen(false)
    }

    setDisabledButton(false)
    await toggleLoader(false)
  }

  useEffect(() => {
    if (formStateCard.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
    } else {
      window.onbeforeunload = null
    }
  }, [formStateCard.isDirty])

  const { setError } = useForm()

  const [acceptedStatus, setAcceptedStatus] = useState(null)
  const [solicitudeCheck, setSolicitudeCheck] = useState(null)
  const [openModalSolicitude, setOpenModalSolicitude] = useState(false)
  const [openPlansModal, setOpenPlansModal] = useState(false)

  function onOpenPlansModal() {
    setOpenPlansModal(true)
  }

  function onClosePlansModal() {
    setOpenPlansModal(false)
  }

  async function onOpenModalSolicitude(accepted, solicitudeId) {
    setAcceptedStatus(accepted)
    setSolicitudeCheck(solicitudeId)
    setOpenModalSolicitude(true)
  }

  async function onCloseModalSolicitude() {
    setAcceptedStatus(null)
    setSolicitudeCheck(null)
    setOpenModalSolicitude(false)
  }

  async function checkSolicitude() {
    await toggleLoader(true)
    setDisableButton(true)

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'solicitudes/check',
        {
          method: 'PATCH',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            accepted: acceptedStatus,
            solicitudeId: solicitudeCheck
          })
        }
      )
      serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        async (dataResponse) => {
          enqueueNotification(languageContext.dictionary['success'], 'success')
          await toggleLoader(false)
          setDisableButton(false)
          onCloseSolicitude()
          onCloseModalSolicitude()
          history.go(0)
          props.newCard(dataResponse)
        },
        async (error) => {
          if (error?.error?.code === 8010) {
            onOpenPlansModal()
          }
          await toggleLoader(false)
          setDisableButton(false)
          onCloseSolicitude()
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      setDisableButton(false)
      await toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  return (
    <div>
      <div className='orange-column-container' style={{ borderRadius: 20 }}>
        {/* title */}
        <div className='orange-column-header' ref={myRef}>
          <h3 className={'column-title'}>{lang.requests}</h3>
          <a className='column-more' data-uk-icon='icon: more-vertical'>
            {' '}
          </a>
          <div data-uk-dropdown='mode: click; pos: bottom-justify'>
            <ul className='uk-list' style={{ marginBottom: 0 }}>
              <li
                className='dropdown-option first-option uk-dropdown-close'
                onClick={() => handleNewCard()}
              >
                {lang.newCard}
              </li>
              <li
                className='last-option dropdown-option uk-dropdown-close'
                onClick={() => props.newColumn('0')}
              >
                {lang.newColumn}
              </li>
            </ul>
          </div>
        </div>
        {/* modal */}
        <Modal
          container={myRef.current}
          open={open}
          onClose={onCloseModal}
          center
          closeIcon={<IconButton icon='close' component='div' />}
          classNames={
            theme === 'dark'
              ? {
                  modal: 'customModalCRM-dark'
                }
              : {
                  modal: 'customModalCRM-light'
                }
          }
        >
          <h3 className='prospect-modal-title'>{lang.newProspect}</h3>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <CTextInput
              label={languageContext.dictionary.title}
              name='title'
              control={controlCard}
              required
              maxLength={255}
            />
            <CTextInput
              type='textarea'
              label={languageContext.dictionary.desc}
              name='description'
              control={controlCard}
              maxLength={2000}
              charCount
            />
            <CTextInput
              type='email'
              name='email'
              control={controlCard}
              maxLength={100}
              required
              label={'E-mail ' + lang.prospect}
            />

            <div className='create-project'>
              <Button color='secondary' type='submit' disabled={disabledButton}>
                {lang.newProspect}
              </Button>
            </div>
          </form>
        </Modal>
        <Droppable droppableId={'static'} type='task'>
          {/* task list */}
          {(provided, snapshot) => (
            <div
              className='orange-task-list-container'
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {props.tasks.map((task, index) => (
                <div
                  key={'task-static-column-' + index}
                  onClick={() => onOpenSolicitude(task)}
                >
                  <RequestCard key={task.id} task={task} index={index} />
                </div>
              ))}
              <Modal
                container={myRef.current}
                open={openSolicitude}
                onClose={onCloseSolicitude}
                center
                closeIcon={<IconButton icon='close' component='div' />}
                classNames={
                  theme === 'dark'
                    ? {
                        modal: 'customModalCRM-dark'
                      }
                    : { modal: 'customModalCRM-light' }
                }
              >
                <div className='oski-sol-container'>
                  <div className='uk-flex uk-flex-between uk-flex-wrap'>
                    <Typography variant='h6' color='secondary'>
                      {solicitude?.Solicitude?.title}
                    </Typography>
                    <Typography variant='subtitle'>
                      {solicitude &&
                        format(
                          new Date(solicitude?.Solicitude?.createdAt),
                          'dd/MM/yyyy HH:mm'
                        )}
                    </Typography>
                  </div>
                  <div className='uk-flex uk-flex-column'>
                    {solicitude?.Solicitude?.Users.map((user, index) => {
                      return (
                        <div
                          key={index}
                          className='uk-flex uk-flex-middle uk-flex-wrap uk-margin-bottom'
                        >
                          <Avatar
                            src={user.image}
                            alt={user.name}
                            style={{
                              marginRight: 10
                            }}
                          />
                          <Typography>{user.name}</Typography>
                        </div>
                      )
                    })}
                  </div>
                  <Typography>{solicitude?.Solicitude?.description}</Typography>
                  {!solicitude?.isOwner ? (
                    <div
                      className='uk-flex uk-flex-center uk-flex-middle uk-margin-medium-top'
                      style={{ gap: 30 }}
                    >
                      <Button
                        size='small'
                        color='grey'
                        style={{ color: 'white' }}
                        onClick={() =>
                          onOpenModalSolicitude(
                            false,
                            solicitude?.Solicitude?.id
                          )
                        }
                        disabled={disableButton}
                      >
                        {lang.reject}
                      </Button>
                      <Button
                        size='small'
                        onClick={() =>
                          onOpenModalSolicitude(
                            true,
                            solicitude?.Solicitude?.id
                          )
                        }
                        disabled={disableButton}
                      >
                        {languageContext.dictionary.accept}
                      </Button>
                    </div>
                  ) : (
                    <div className='uk-flex uk-flex-center uk-margin-medium-top'>
                      <Typography color='secondary'>{lang.waiting}</Typography>
                    </div>
                  )}
                </div>
              </Modal>
              <Modal
                container={myRef.current}
                open={openModalSolicitude}
                onClose={onCloseModalSolicitude}
                center
                closeIcon={<IconButton icon='close' component='div' />}
                classNames={
                  theme === 'dark'
                    ? {
                        modal: 'customModalCRM-dark'
                      }
                    : { modal: 'customModalCRM-light' }
                }
              >
                <div className='oski-sol-container uk-margin-medium-bottom uk-text-center'>
                  {acceptedStatus === true
                    ? lang.sureToAccept
                    : lang.sureToReject}
                </div>
                <div className="'uk-width-1 uk-flex uk-flex-around uk-flex-middle">
                  <Button color='grey' onClick={() => onCloseModalSolicitude()}>
                    {languageContext.dictionary.cancel}
                  </Button>
                  <Button
                    onClick={() => checkSolicitude()}
                    disable={disabledButton}
                  >
                    {languageContext.dictionary.accept}
                  </Button>
                </div>
              </Modal>
            </div>
          )}
        </Droppable>
      </div>
      <PlansModal open={openPlansModal} onClose={onClosePlansModal} />
    </div>
  )
}

Column.defaultProps = {
  column: {},
  tasks: [],
  index: 0,
  onOpenModal: () => {},
  onOpenModalSolicitude: () => {},
  onOpenPlansModal: () => {},
  newColumn: () => {},
  newCard: () => {},
  newSolicitude: () => {}
}

Column.propTypes = {
  column: PropTypes.object,
  tasks: PropTypes.array,
  index: PropTypes.number,
  onOpenModal: PropTypes.func,
  onOpenModalSolicitude: PropTypes.func,
  onOpenPlansModal: PropTypes.func,
  newColumn: PropTypes.func,
  newCard: PropTypes.func,
  newSolicitude: PropTypes.func
}
