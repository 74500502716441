import React, { useContext, useEffect, useState } from 'react'
import _Auth from '../../context/auth'
import _Utils from '../../context/utils'
import { useHistory } from 'react-router'
import './styles/style.css'

import { LanguageContext } from '../../context/language/context'
import { _handleErrorMessage } from '../../functions/error.functions'
import { serverValidation } from '../../functions/validation.functions'
import { CTextInput } from '../../components/TextInput'

import { Prompt } from 'react-router-dom'
import Button from '../../components/Button'
import CNumberFormat from '../../components/CNumberFormat'
import { useForm } from 'react-hook-form'

export default function Index() {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const history = useHistory()
  const { toggleLoader, enqueueNotification } = useContext(_Utils.Context)
  const { token } = useContext(_Auth.Context)

  const [disabled, setDisabled] = useState(false)
  const { reset, control, setError, handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      phone: ''
    }
  })

  useEffect(() => {
    if (formState.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
    } else {
      window.onbeforeunload = null
    }
  }, [formState.isDirty])

  useEffect(() => {
    if (token) {
      enqueueNotification(lang['already'], 'error')
      history.push('/Dashboard')
    }
  }, [token])

  async function onSubmit(data) {
    await toggleLoader(true)

    setDisabled(true)
    try {
      let response = await fetch(process.env.REACT_APP_API_URL + 'auth/reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      await serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        async () => {
          reset()
          await toggleLoader(false)
          setDisabled(false)
          setTimeout(() => {}, 60000)
          enqueueNotification(languageContext.dictionary['success'], 'success')
          history.push('/')
        },
        async (error) => {
          setDisabled(false)
          await toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      setDisabled(false)
      await toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error.error),
        'error'
      )
    }
  }
  return (
    <div>
      <div className='uk-container diinco-reset-container'>
        <div
          className='uk-card uk-card-default uk-card-body uk-width-1-2@m'
          style={{ backgroundColor: 'transparent' }}
        >
          <h3 className='uk-card-title'>{lang['resetPass']}</h3>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Prompt
              when={formState.isDirty}
              message={JSON.stringify({
                title: languageContext.dictionary.discard,
                message: languageContext.dictionary.areYouSure,
                accept: languageContext.dictionary.accept,
                cancel: languageContext.dictionary.cancel
              })}
            />
            <CTextInput
              label={'Email'}
              name='email'
              control={control}
              required
              type='email'
              maxLength={100}
            />

            <CNumberFormat
              name='phone'
              control={control}
              label={languageContext.dictionary.phoneNumber}
              required
              maxLength={10}
              minLength={10}
              format='(###) ###-####'
              mask='_'
            />

            <div className='uk-flex uk-flex-between'>
              <Button
                onClick={() => {
                  history.goBack()
                }}
                color={'grey'}
              >
                {languageContext.dictionary.cancel}
              </Button>
              <Button color='secondary' type='submit' disabled={disabled}>
                {languageContext.dictionary['confirm']}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
