import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'

import './styles/style.css'
import { LanguageContext } from '../../context/language/context'
import { Controller } from 'react-hook-form'
import { message } from '../../functions/validation.functions'

const CheckBox = React.forwardRef(
  (
    {
      label,
      required,
      classes,
      error,

      centered,
      containerStyle,

      checked: valueFromProps,
      onChange: onChangeFromProps,

      defaultValue,

      link,

      ...rest
    },
    ref
  ) => {
    const { containerClass } = classes

    // A component can be considered controlled when its value prop is
    // not undefined.
    const isControlled = typeof valueFromProps !== 'undefined'
    // When a component is not controlled, it can have a defaultValue.
    const hasDefaultValue = typeof defaultValue !== 'undefined'
    // If a defaultValue is specified, we will use it as our initial
    // state.  Otherwise, we will simply use an empty string.
    const [internalValue, setInternalValue] = useState(
      hasDefaultValue ? defaultValue : false
    )
    // Internally, we need to deal with some value. Depending on whether
    // the component is controlled or not, that value comes from its
    // props or from its internal state.
    const value = isControlled ? valueFromProps : internalValue
    const onChange = (e) => {
      // When the user types, we will call props.onChange if it exists.
      // We do this even if there is no props.value (and the component
      // is uncontrolled.)
      if (onChangeFromProps) {
        onChangeFromProps(e)
      }
      // If the component is uncontrolled, we need to update our
      // internal value here.
      if (!isControlled) {
        setInternalValue(e.target.checked)
      }
    }

    return [
      //Container
      <div
        ref={ref}
        key='checkbox-container-1'
        className={[
          'uk-width-1-1 cj-checkbox-input-container',
          centered && 'uk-flex uk-flex-center',
          !error && 'cj-input-margin-bottom-checkbox',
          containerClass
        ].join(' ')}
        style={containerStyle}
      >
        <label>
          <input
            className='uk-checkbox cj-checkbox-input'
            type='checkbox'
            checked={value}
            onChange={onChange}
            {...rest}
          />
          {label} {required}
        </label>
        {link}
      </div>,
      ...(error
        ? [
            <div
              key='checkbox-container-2'
              className={[
                'cj-error-message uk-width-1-1 cj-input-margin-bottom-checkbox',
                centered && 'uk-flex uk-flex-center'
              ].join(' ')}
            >
              <span data-uk-icon='icon: warning; ratio: 0.6'></span>{' '}
              {error.message}
            </div>
          ]
        : [])
    ]
  }
)

CheckBox.defaultProps = {
  label: null,
  required: false,
  classes: {},
  error: null,

  centered: true,
  containerStyle: {}
}

CheckBox.propTypes = {
  label: PropTypes.any,
  required: PropTypes.bool,
  classes: PropTypes.object,
  error: PropTypes.object,

  centered: PropTypes.bool,

  containerStyle: PropTypes.object,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any,
  link: PropTypes.node
}

export const CCheckBox = ({
  name,
  control,
  defaultValue,
  shouldUnregister,

  required,

  validate,
  setValueAs,
  disabled,
  deps,

  ...rest
}) => {
  const languageContext = useContext(LanguageContext)

  var registerOptions = {
    required: {
      value: required,
      message: message(languageContext.userLanguage, 'required')
    },

    validate: validate,
    setValueAs: setValueAs,
    disabled: disabled,
    deps: deps
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={registerOptions}
      shouldUnregister={shouldUnregister}
      render={({
        field: { onChange, onBlur, value, name },
        fieldState: { error }
      }) => {
        return (
          <CheckBox
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={error}
            name={name}
            required={required}
            {...rest}
          />
        )
      }}
    />
  )
}

CCheckBox.defaultProps = {
  defaultValue: undefined,
  shouldUnregister: false,

  required: false
}

CCheckBox.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  shouldUnregister: PropTypes.bool,

  label: PropTypes.any,
  required: PropTypes.bool,

  validate: PropTypes.object,
  setValueAs: PropTypes.any,

  disabled: PropTypes.bool,
  deps: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  link: PropTypes.node
}
CheckBox.displayName = 'CheckBox'
export default CheckBox
