import React, { useContext, useEffect, useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import Column from './Components/column'
import StaticColumn from './Components/staticColumn'
import ArchivedColumn from './Components/archivedColumn'
import Card from './Components/card'
import _Utils from '../../context/utils'
import _Auth from '../../context/auth'
import { LanguageContext } from '../../context/language/context'
import { _handleErrorMessage } from '../../functions/error.functions'
import { serverValidation } from '../../functions/validation.functions'
import Button from '../Button'
import StandardView from '../../layouts/StandardView'
import PlansModal from '../PlansModal'
import SwitchInput from '../SwitchInput'
import Typography from '../Typography'

export default function CustomCRM() {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const { enqueueNotification, toggleLoader } = useContext(_Utils.Context)
  const [board, setBoard] = useState(null)
  const [, setLastBoard] = useState(null)
  const { token, self } = useContext(_Auth.Context)
  const [deletedTask, setDeletedTask] = useState(null)
  const [updateTask, setUpdateTask] = useState(null)
  const [loading, setLoading] = useState(true)
  const [staticTasks, setStaticTasks] = useState([])

  useEffect(() => {
    async function handleDeleteCard(_ids) {
      let tmp = board
      let [columnId, taskId] = _ids

      const column = board.columns[_ids[0]]
      const newArrayOrder = Array.from(column.taskIds)
      const taskIndex = newArrayOrder.indexOf(_ids[1])
      newArrayOrder.splice(taskIndex, 1)
      const newColumn = {
        ...column,
        taskIds: newArrayOrder
      }
      const updatedBoard = {
        ...board,
        columns: {
          ...board.columns,
          [newColumn.id]: newColumn
        }
      }
      setBoard(updatedBoard)

      try {
        await fetch(process.env.REACT_APP_API_URL + 'dashboard/task', {
          method: 'DELETE',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ taskId, columnId })
        })
      } catch (error) {
        setBoard(tmp)
        enqueueNotification(
          _handleErrorMessage(languageContext?.userLanguage, error),
          'error'
        )
      }

      setDeletedTask(null)
    }

    if (deletedTask) handleDeleteCard(deletedTask)
  }, [deletedTask])

  useEffect(() => {
    if (!updateTask) return
    function onCardChange(data, id, phaseId) {
      // if(!board) return;
      const newCard = {
        id: id.toString(),
        content: (
          <Card
            id={id.toString()}
            title={data.title}
            description={data.description}
            people={data.users.map((user) => ({
              photo: user.professional
                ? user.professional.image && user.professional.image
                : user.client && user.client.image,
              name: user.name,
              phone: user.phone,
              nickname: user.nickname,
              value: user.id
            }))}
            appointment={
              data.appointment
                ? {
                    date: data.appointment.date,
                    title: data.appointment.title,
                    description: data.appointment.description
                  }
                : null
            }
            note={
              data.note
                ? {
                    title: data.note.title,
                    description: data.note.description
                  }
                : null
            }
            last_update='20/09/2021'
            deleteCard={setDeletedTask}
            colId={phaseId}
            onTaskChange={(data, id, phaseId) => {
              setUpdateTask({ data, id, phaseId })
            }}
          />
        )
      }

      let newTasks = board?.tasks
      newTasks = {
        ...newTasks,
        [newCard.id]: newCard
      }

      let newBoard = {
        ...board,
        tasks: newTasks
      }

      setBoard(newBoard)
      setUpdateTask(null)
    }
    let { data, id, phaseId } = updateTask
    onCardChange(data, id, phaseId)
  }, [updateTask])

  function buildBoard(phases, prospects, order) {
    let ordered = order.sort((a, b) => a.order - b.order)
    let tasks = {}

    prospects.forEach((prospect) => {
      tasks[prospect.id.toString()] = {
        id: prospect.id.toString(),
        content: (
          <Card
            id={prospect.id.toString()}
            title={prospect.title}
            description={prospect.description}
            people={prospect.Users?.map((user) => ({
              photo: user.image,
              name: user.name,
              phone: user.phone,
              nickname: user.nickname,
              value: user.id
            }))}
            onTaskChange={(data, id, phaseId) => {
              setUpdateTask({ data, id, phaseId })
            }}
            appointment={
              prospect.Events[0]
                ? {
                    date: prospect.Events[0].date_end,
                    location: '',
                    title: prospect.Events[0].title,
                    description: prospect.Events[0].body
                  }
                : null
            }
            note={
              prospect.Notes[0]
                ? {
                    title: prospect.Notes[0].title,
                    description: prospect.Notes[0].body
                  }
                : null
            }
            last_update='20/09/2021'
            deleteCard={setDeletedTask}
            colId={prospect.PhaseId}
          />
        )
      }
    })
    let columns = {}
    phases.forEach((phase) => {
      let orderedTasks = phase.Prospects.sort((a, b) => a.order - b.order)

      columns[phase.id.toString()] = {
        id: phase.id.toString(),
        title: phase.name,
        taskIds: orderedTasks.map((prospect) => prospect.id.toString())
      }
    })
    let columnOrder = ordered.map((phase) => phase.id.toString())

    return {
      tasks,
      columns,
      columnOrder
    }
  }

  useEffect(() => {
    async function Init() {
      try {
        let data = await fetch(
          process.env.REACT_APP_API_URL + 'dashboard/crm',
          {
            credentials: 'include',
            mode: 'cors'
          }
        )

        data = await data.json()

        let phases = data.Phases
        let prospects = []
        phases.forEach((phase) => {
          phase.Prospects.map((p) =>
            prospects.push({ ...p, phaseId: phase.id })
          )
        })
        let order = data.Phases
        let newBoard = buildBoard(phases, prospects, order)
        setLastBoard(newBoard)
        setBoard(newBoard)
        setStaticTasks(data.User_Solicitudes)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        enqueueNotification(
          _handleErrorMessage(languageContext?.userLanguage, error),
          'error'
        )
      }
    }
    if (token) {
      toggleLoader(true)
      Init()
      toggleLoader(false)
    }
  }, [token])

  // useEffect(() => {
  // 	// timer para guardar cuando se muevan las tarjetas o columnas
  // 	const timer = setTimeout(() => {
  // 		if (lastBoard != board) {
  // 			console.log("ALGO CAMBIO DENTRO DE MI");
  // 			setLastBoard(board);
  // 		}
  // 	}, 3000);
  // 	return () => {
  // 		clearTimeout(timer);
  // 	};
  // }, [board]);

  async function handleOnDragEnd(result) {
    var { destination, source, draggableId, type } = result

    draggableId = draggableId.slice(3)
    if (!destination) {
      return
    }

    //  check if the location of the draggable change
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      //  the position it's the same
      return
    }

    //  check if we are reordering a colum or a task
    if (type === 'column') {
      //  we are reordering a column
      const newColumnOrder = Array.from(board.columnOrder)
      newColumnOrder.splice(source.index, 1)
      newColumnOrder.splice(destination.index, 0, draggableId)
      const updatedBoard = {
        ...board,
        columnOrder: newColumnOrder
      }
      const tmp = board
      setBoard(updatedBoard)
      try {
        let data = await fetch(
          process.env.REACT_APP_API_URL + 'dashboard/column/reorder',
          {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              order: newColumnOrder.map((o) => parseInt(o))
            })
          }
        )
        if (data.status !== 200) throw await data.json()
        data = await data.json()
      } catch (error) {
        setBoard(tmp)
        enqueueNotification(
          _handleErrorMessage(languageContext?.userLanguage, error),
          'error'
        )
      }

      return
    }
    //  we are reordering a task
    //  reorder the taskIds array of the column
    const start = board.columns[source.droppableId]
    const finish = board.columns[destination.droppableId]

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds)
      newTaskIds.splice(source.index, 1)
      newTaskIds.splice(destination.index, 0, draggableId)

      const newColumn = {
        ...start,
        taskIds: newTaskIds
      }

      const updatedBoard = {
        ...board,
        columns: {
          ...board.columns,
          [newColumn.id]: newColumn
        }
      }
      let tmp = board
      setBoard(updatedBoard)

      try {
        let data = await fetch(
          process.env.REACT_APP_API_URL + 'dashboard/task/reorder',
          {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              order: newTaskIds.map((o) => parseInt(o)),
              columnId: start.id
            })
          }
        )
        if (data.status !== 200) throw await data.json()
        data = await data.json()
      } catch (error) {
        setBoard(tmp)
        enqueueNotification(
          _handleErrorMessage(languageContext?.userLanguage, error),
          'error'
        )
      }
      return
    }

    // moving from one list to another
    const startTaskIds = Array.from(start.taskIds)
    startTaskIds.splice(source.index, 1)
    const newStart = {
      ...start,
      taskIds: startTaskIds
    }

    const finishTaskIds = Array.from(finish.taskIds)
    finishTaskIds.splice(destination.index, 0, draggableId)
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds
    }

    const updatedBoard = {
      ...board,
      columns: {
        ...board.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    }
    let tmp = board
    setBoard(updatedBoard)
    try {
      let data = await fetch(
        process.env.REACT_APP_API_URL + 'dashboard/task/move',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            orderStart: startTaskIds.map((o) => parseInt(o)),
            startId: parseInt(start.id),
            endId: parseInt(finish.id),
            orderEnd: finishTaskIds.map((o) => parseInt(o))
          })
        }
      )
      if (data.status !== 200) throw await data.json()
      data = await data.json()
    } catch (error) {
      setBoard(tmp)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  async function handleNewColumn(_position) {
    toggleLoader(true)
    try {
      let data = await fetch(
        process.env.REACT_APP_API_URL + 'dashboard/column',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ order: _position })
        }
      )
      if (data.status !== 200) throw await data.json()
      data = await data.json()
      //	se crea la nueva columna
      const newColumn = {
        id: data.id.toString(),
        title: data.name,
        taskIds: []
      }

      const newColumnOrder = Array.from(board.columnOrder)
      newColumnOrder.splice(_position, 0, newColumn.id)
      // newColumnOrder.push(newColumn.id);

      const updatedBoard = {
        ...board,
        columns: {
          ...board.columns,
          [newColumn.id]: newColumn
        },
        columnOrder: newColumnOrder
      }
      setBoard(updatedBoard)
    } catch (error) {
      toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    toggleLoader(false)
  }

  async function handleRenameColumn(_column, _name, _setError) {
    const column = board.columns[_column]
    toggleLoader(true)
    if (_name === '') {
      enqueueNotification(languageContext.dictionary.columnNameError, 'error')
      toggleLoader(false)
    } else {
      try {
        let response = await fetch(
          process.env.REACT_APP_API_URL + 'dashboard/column',
          {
            method: 'PUT',
            credentials: 'include',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              columnId: parseInt(column.id),
              name: _name
            })
          }
        )

        serverValidation(
          response,
          _setError,
          languageContext.userLanguage,
          () => {
            const newColumn = {
              ...column,
              title: _name
            }

            const updatedBoard = {
              ...board,
              columns: {
                ...board.columns,
                [_column]: newColumn
              }
            }

            enqueueNotification(lang.renamed, 'success')
            setBoard(updatedBoard)
          },
          (error) => {
            enqueueNotification(
              _handleErrorMessage(languageContext?.userLanguage, error),
              'error'
            )
          }
        )
      } catch (error) {
        enqueueNotification(
          _handleErrorMessage(languageContext?.userLanguage, error),
          'error'
        )
      }
      toggleLoader(false)
    }
  }

  async function handleDeleteColumn(_column) {
    toggleLoader(true)
    // funcion para eliminar una columna
    const column = board.columns[_column]

    //  validate that the column doesn't have any task in it
    if (column.taskIds.length > 0) {
      enqueueNotification(lang['deleteColumnError'], 'error')
      return
    }

    try {
      let data = await fetch(
        process.env.REACT_APP_API_URL + 'dashboard/column',
        {
          method: 'DELETE',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ columnId: parseInt(column.id) })
        }
      )
      if (data.status !== 200) throw await data.json()
      data = await data.json()

      const newColumnOrder = Array.from(board.columnOrder)
      const index = newColumnOrder.indexOf(column.id)
      newColumnOrder.splice(index, 1)

      const newColumns = board.columns
      delete newColumns[column.id]

      const updatedBoard = {
        ...board,
        columns: newColumns,
        columnOrder: newColumnOrder
      }

      setBoard(updatedBoard)
    } catch (error) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    toggleLoader(false)
  }

  async function handleNewCard(_column, _data, setError) {
    let result = false
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'dashboard/task',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(_data)
        }
      )
      await serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        async (data) => {
          result = true
          await toggleLoader(false)
          enqueueNotification(languageContext.dictionary.success, 'success')
          setStaticTasks(data.User_Solicitudes)
        },
        async (error) => {
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error),
            'error'
          )
          await toggleLoader(false)
        }
      )
      return result
    } catch (error) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
      await toggleLoader(false)
      return false
    }
  }

  function handleNewCardAlt(data) {
    if (data.Users) {
      const newCard = {
        id: data.id.toString(),
        content: (
          <Card
            id={data.id.toString()}
            title={data.title}
            description={data.description}
            people={data.Users.map((user) => ({
              photo: user.image,
              name: user.name,
              phone: user.phone,
              nickname: user.nickname,
              value: user.id
            }))}
            appointment={
              data.Events[0]
                ? {
                    date: data.Events[0].date_end,
                    location: '',
                    title: data.Events[0].title,
                    description: data.Events[0].body
                  }
                : null
            }
            note={
              data.Notes[0]
                ? {
                    title: data.Notes[0].title,
                    description: data.Notes[0].body
                  }
                : null
            }
            last_update='20/09/2021'
            deleteCard={setDeletedTask}
            colId={data.PhaseId}
            onTaskChange={(data, id, phaseId) => {
              setUpdateTask({ data, id, phaseId })
            }}
          />
        )
      }

      const newTasks = {
        ...board.tasks,
        [newCard.id]: newCard
      }

      const firstCol = board.columns[data.PhaseId]

      const newTaskIds = Array.from(firstCol.taskIds)
      newTaskIds.unshift(newCard.id)

      const newColumn = {
        ...firstCol,
        taskIds: newTaskIds
      }

      const updatedBoard = {
        ...board,
        columns: {
          ...board.columns,
          [data.PhaseId]: newColumn
        },
        tasks: newTasks
      }

      data.Users.forEach((user) => {
        if (user.id === self?.id) setStaticTasks(user.User_Solicitudes)
      })
      setBoard(updatedBoard)
      return true
    }
    setStaticTasks(data.User_Solicitudes)
  }

  const [openPlansModal, setOpenPlansModal] = useState(false)
  function onOpenPlansModal() {
    setOpenPlansModal(true)
  }

  function onClosePlansModal() {
    setOpenPlansModal(false)
  }

  const [showArchived, setShowArchived] = useState(false)
  const [archivados, setArchivados] = useState([])

  async function loadArchived(status) {
    setShowArchived(status)
    setLoading(true)
    try {
      let data = await fetch(
        process.env.REACT_APP_API_URL + 'prospects/loadArchived',
        {
          credentials: 'include',
          mode: 'cors'
        }
      )
      data = await data.json()
      setArchivados(data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  return (
    <StandardView
      loading={loading}
      title={lang.process}
      links={[
        {
          link: '/Dashboard',
          title: languageContext.dictionary.dashboard
        }
      ]}
    >
      <div className='uk-flex uk-flex-right'>
        <Typography
          variant='body2'
          style={{ marginRight: 8, marginTop: 2 }}
          color={languageContext.theme === 'light' ? 'primary' : 'secondary'}
        >
          {lang.showArchived}
        </Typography>
        <SwitchInput
          value={showArchived}
          onChange={() => loadArchived(!showArchived)}
          className='album'
        />
      </div>
      <div className='crm-container'>
        {board && (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable
              droppableId='all-columns'
              direction='horizontal'
              type='column'
            >
              {(provided) => (
                // container
                <div
                  className='board-container'
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {showArchived && archivados && (
                    <ArchivedColumn column={{}} tasks={archivados} />
                  )}
                  <StaticColumn
                    column={{}}
                    tasks={staticTasks}
                    newCard={handleNewCardAlt}
                    newColumn={handleNewColumn}
                    newSolicitude={handleNewCard}
                    onOpenPlansModal={onOpenPlansModal}
                  />
                  {board.columnOrder.map((columnId, index) => {
                    const column = board.columns[columnId]
                    const tasks = column.taskIds.map(
                      (taskId) => board.tasks[taskId]
                    )
                    return (
                      <Column
                        key={column.id}
                        column={column}
                        tasks={tasks}
                        index={index}
                        newCard={handleNewCard}
                        newColumn={handleNewColumn}
                        renameColumn={handleRenameColumn}
                        deleteColumn={handleDeleteColumn}
                        onTaskChange={(data) => {
                          console.error(data, 'desde el index')
                        }}
                      />
                    )
                  })}
                  {provided.placeholder}
                  <Button
                    containerStyle={{ margin: '10px', minWidth: '244px' }}
                    onClick={() => {
                      handleNewColumn(board?.columnOrder?.length + 1 ?? 1)
                    }}
                  >
                    {lang.addColumn}
                  </Button>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>

      <PlansModal open={openPlansModal} onClose={onClosePlansModal} />
    </StandardView>
  )
}
