import React, { useContext, useEffect, useState } from 'react'
import './styles/style.css'

import _Utils from '../../context/utils'
import _Auth from '../../context/auth'

import { CTextInput } from '../../components/TextInput'
import SelectInput, { CSelectInput } from '../../components/SelectInput'

import { CDropZoneFiles } from '../../components/DropZoneFiles'
import { CDropZoneCoverImage } from '../../components/DropZoneCoverImage'
import ImageThumbsContainer from '../../components/ImageThumbsContainer'
import { useParams, useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'

import { LanguageContext } from '../../context/language/context'
import { _handleErrorMessage } from '../../functions/error.functions'
import { message, serverValidation } from '../../functions/validation.functions'
import { serialize } from '../../functions/validation.functions'

import { Prompt } from 'react-router-dom'
import Button from '../../components/Button'
import CNumberFormat from '../../components/CNumberFormat'
import StandardView from '../../layouts/StandardView'
import { compressFilesInFormData } from '../../functions/files.functions'

export default function PaymentForm() {
  const { id: projectId } = useParams()
  let history = useHistory()
  const { toggleLoader, enqueueNotification } = useContext(_Utils.Context)
  const { token } = useContext(_Auth.Context)

  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])

  const [stages, setStages] = useState(null)
  useEffect(() => {
    if (token) {
      fetch(
        process.env.REACT_APP_API_URL +
          'projects/' +
          projectId +
          '/payments/create',
        {
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then((response) => {
          return response.json()
        })
        .then((stages) => {
          if (stages.code === 2000) {
            throw new Error(2000)
          }
          if (stages.code || stages.message) {
            throw new Error('Error')
          } else {
            setStages(stages)
          }
        })
        .catch((error) => {
          if (error !== '2000') {
            enqueueNotification(
              languageContext.dictionary['somethingWrong'],
              'error'
            )
          }
        })
    }
  }, [token])

  const [stageOption, setStageOption] = useState([
    {
      value: null,
      label: lang.noPhase
    }
  ])

  const paymentOptions = [
    {
      value: 'efectivo',
      label: lang.cash
    },
    {
      value: 'cheque',
      label: lang.check
    },
    {
      value: 'transferencia',
      label: lang.transfer
    },
    {
      value: 'definir',
      label: lang.toDefine
    }
  ]

  useEffect(() => {
    if (stages && stages.length > 1) {
      var stageOption = []
      stages.forEach((stage) => {
        if (stage.name !== 'General') {
          stageOption.push({
            value: stage.id,
            label: stage.name
          })
        }
      })
      stageOption.unshift({
        value: null,
        label: lang.wPhase
      })
      setStageOption(stageOption)
    }
  }, [stages])

  const [disabledButton, setDisabledButton] = useState(false)
  const {
    reset,
    control,
    setError,
    handleSubmit,
    formState,
    getValues,
    setValue
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      concept: '',
      type: '',
      amount: '',
      files: [],
      document: []
    }
  })

  useEffect(() => {
    if (formState.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
    } else {
      window.onbeforeunload = null
    }
  }, [formState.isDirty])

  async function onSubmit(data) {
    await toggleLoader(true)
    setDisabledButton(true)
    data = { ...data }
    let formData = serialize(data, {
      dotsForObjectNotation: true,
      noArrayNotation: true,
      noFilesWithArrayNotation: true
    })

    formData = await compressFilesInFormData(formData, 'files')

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'projects/' + projectId + '/payments',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data'
          },
          body: formData
        }
      )

      await serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        async () => {
          reset()
          enqueueNotification(languageContext.dictionary['success'], 'success')
          setTimeout(() => {}, 1000)
          await toggleLoader(false)
          setDisabledButton(false)
          history.push('/projects/' + projectId)
        },
        async (error) => {
          setDisabledButton(false)
          await toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      setDisabledButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onError = () => {
    enqueueNotification(
      _handleErrorMessage(languageContext?.userLanguage, { code: 1000 }),
      'error'
    )
  }

  return (
    <StandardView
      title={lang.payment}
      links={[
        {
          link: '/Dashboard/Projects',
          title: languageContext.dictionary.dashboard
        },
        {
          link: '/Projects/' + projectId,
          title: languageContext.dictionary.projectDashboard
        }
      ]}
    >
      <div className='uk-container'>
        <div className='uk-width-1-1 uk-flex uk-flex-column'>
          <form onSubmit={handleSubmit(onSubmit, onError)} noValidate>
            <Prompt
              when={formState.isDirty}
              message={JSON.stringify({
                title: languageContext.dictionary.discard,
                message: languageContext.dictionary.areYouSure,
                accept: languageContext.dictionary.accept,
                cancel: languageContext.dictionary.cancel
              })}
            />
            <CTextInput
              label={lang.concept}
              name='concept'
              control={control}
              maxLength={45}
              required
            />

            <CSelectInput
              name='type'
              control={control}
              label={lang.type}
              options={paymentOptions}
              required
            />
            <Controller
              rules={{
                required: {
                  value: true,
                  message: message(languageContext.userLanguage, 'required')
                }
              }}
              name='stage'
              control={control}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error }
              }) => {
                return (
                  <SelectInput
                    options={stageOption}
                    onChange={(selectedOption) => {
                      onChange(selectedOption)
                    }}
                    value={value}
                    onBlur={onBlur}
                    error={error}
                    label={lang.phase}
                    name='stage'
                    required
                  />
                )
              }}
            />

            <CNumberFormat
              name='amount'
              control={control}
              label={lang.amount}
              required
              max={9999999999.0}
              prefix={'$ '}
              thousandSeparator={true}
              limit
            />

            <div className='uk-flex uk-flex-between'>
              <div className='uk-width-3-5'>
                <CDropZoneCoverImage
                  name={'files'}
                  control={control}
                  ContainerClasses='diinco-project-form-dropzone-container cj-height-dropzone-payment'
                  ImageClasses='diinco-project-form-dropzone-image'
                  onChange={() => forceUpdate()}
                />
                <ImageThumbsContainer
                  control={control}
                  thumbs={getValues('files')}
                  containerStyle='diinco-thumbs-container-project-form'
                  thumbStyle='diinco-thumbs-style-project-form'
                  deleteButtonStyle='diinco-thumbs-delete-project-form'
                  handleRemove={(event, file, index) => {
                    const newValue = getValues('files').filter(
                      (o, i) => i !== index
                    )
                    setValue('files', newValue)
                    forceUpdate()
                  }}
                />
              </div>
              <div className='uk-width-1-3 uk-height-medium'>
                <CDropZoneFiles
                  required
                  inputClasses='cj-height-dropzone-f-payment'
                  name='document'
                  control={control}
                />
              </div>
            </div>
            <div className='uk-flex uk-flex-between'>
              <Button
                onClick={() => {
                  history.goBack()
                }}
                color={'grey'}
              >
                {languageContext.dictionary.cancel}
              </Button>
              <Button type='submit' color='secondary' disabled={disabledButton}>
                {languageContext.dictionary['save']}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </StandardView>
  )
}
