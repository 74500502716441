import React, { useContext, useState, useRef, useEffect } from 'react'
import _Utils from '../../../context/utils'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import Task from './task'
import '../styles/style.css'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import UserIcon from '../assets/user_white.svg'
import TextInput from '../../../components/TextInput'
import TextAreaInput from '../../TextAreaInput'
import UserSearchInput from '../../UserSearchInput'
import imgIcon from '../assets/upload-img.svg'
import fileIcon from '../assets/file.svg'

import { LanguageContext } from '../../../context/language/context'

import ImageThumbsContainer from '../../ImageThumbsContainer'
import { CTextInput } from '../../../components/TextInput'
import { useForm } from 'react-hook-form'
import Button from '../../Button'
import { IconButton } from '../../IconButton'
import ClickAwayListener from 'react-click-away-listener'
import PropTypes from 'prop-types'

const getListStyle = (isDraggingOver, theme) => ({
  // change background colour if dragging
  transition: 'background-color 0.2s ease',
  background: isDraggingOver
    ? '#999999'
    : theme === 'light'
    ? '#aeaeae'
    : 'var(--card-color)',
  borderBottomRightRadius: '20px',
  borderBottomLeftRadius: '20px'
})

const getColumnStyle = (isDragging, draggableStyle, theme) => ({
  // change background colour if dragging
  background: isDragging
    ? 'var(--primary-color)'
    : theme === 'light'
    ? '#aeaeae'
    : 'var(--card-color)',

  // styles we need to apply on draggables
  ...draggableStyle,
  borderRadius: '20px'
  // borderBottomRightRadius: "20px"
})

export default function Column(props) {
  const languageContext = useContext(LanguageContext)
  const { theme } = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('../languages/en.json')
  } else {
    lang = require('../languages/es.json')
  }
  const myRef = useRef(null)

  const { enqueueNotification } = useContext(_Utils.Context)

  const [open, setOpen] = useState(false)
  const [openUsers, setOpenUsers] = useState(false)
  const [openAppointment, setOpenAppointment] = useState(false)
  const [openNote, setOpenNote] = useState(false)

  const [renaming, setRenaming] = useState(false)
  // const [newName, setNewName] = useState("");

  const [addingUsers, setAddingUsers] = useState(false)
  //const [addingAppointment, setAddingAppointment] = useState(false)
  //const [addingNote, setAddingNote] = useState(false)

  const [invited, setInvited] = useState(null)
  const [users, setUsers] = useState([])

  const [appointmentDateValue, setAppointmentDateValue] = useState(new Date())
  const [appointmentTitle, setAppointmentTitle] = useState('')
  const [appointmentDescription, setAppointmentDescription] = useState('')

  const [noteTitle, setNoteTitle] = useState('')
  const [noteDescription, setNoteDescription] = useState('')

  const [files, setFiles] = useState([])
  const [documents, setDocuments] = useState([])

  // function onOpenModal() {
  //   setOpen(true)
  // }

  function onCloseModal() {
    setOpen(false)
  }

  // function onOpenUsers() {
  //   setOpenUsers(true)
  //   setInvited(null)
  // }

  function onCloseUsers() {
    setOpenUsers(false)
    setInvited(null)
  }

  // function onOpenAppointment() {
  //   setOpenAppointment(true)
  // }

  function onCloseAppointment() {
    setOpenAppointment(false)
  }

  // function onOpenNote() {
  //   setOpenNote(true)
  // }

  function onCloseNote() {
    setOpenNote(false)
  }

  // function handleNewCard() {
  //   onOpenModal()

  //   // props.newCard(props.column.id);
  // }

  const { getValues, control, setError } = useForm({
    mode: 'onChange',
    defaultValues: {
      newName: props.column.title || ''
    }
  })

  const {
    reset: resetCard,
    control: controlCard,
    setError: setErrorCard,
    formState: formStateCard,
    handleSubmit
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: '',
      description: '',
      email: ''
    }
  })

  async function onSubmit(_data) {
    let data = _data
    // funcion para agregar una nueva tarjeta

    // validar que el prospecto tenga titulo y descripcion
    if (document.getElementsByName('title')[0].value === '') {
      enqueueNotification(lang.titleError, 'error')
      return
    }

    data = { ...data, columnId: props.column.id }

    let response = await props.newCard(props.column.id, data, setErrorCard)
    if (response) {
      resetCard()
      setUsers([])
      setAppointmentDateValue(new Date())
      setAppointmentTitle('')
      setAppointmentDescription('')
      setNoteTitle('')
      setNoteDescription('')
      setFiles([])
      setDocuments([])
      setOpen(false)
    }
  }

  useEffect(() => {
    if (formStateCard.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
    } else {
      window.onbeforeunload = null
    }
  }, [formStateCard.isDirty])

  function validate(e) {
    const tecla = document.all ? e.keyCode : e.which
    if (tecla === 13) {
      // funcion para renombrar una columna
      props.renameColumn(props.column.id, getValues('newName'), setError)

      setRenaming(false)
    }
  }

  function onColumnRename() {
    // setNewName(props.column.title);
    setRenaming(true)
  }

  function handleAddUsersInfo() {
    // validar que se haya ingresado algo en el input
    if (invited) {
      // validar que no se ingrese 2 veces al mismo usuario
      let flag = false
      users.map((user) => {
        if (invited.value === user.value) {
          flag = true
        }
      })

      if (flag) {
        enqueueNotification(lang.userError, 'error')
        return
      }

      // se agrega el usuario a la lista
      setAddingUsers(true)
      setOpenUsers(false)
      setUsers([...users, invited])
      setInvited(null)
    } else {
      enqueueNotification(lang.selectUser, 'error')
    }
  }

  function handleDeleteUsersInfo(_index) {
    // se elimina al usuario del array
    const tmp = Array.from(users)
    tmp.splice(_index, 1)
    // se valida si aun hay usuarios en el array o este quedo vacio
    if (tmp.length > 0) {
      setUsers(tmp)
    } else {
      setAddingUsers(false)
      setUsers([])
    }
  }

  function handleDateChange(event) {
    setAppointmentDateValue(event.target.value)
  }

  function handleAppointmentTitle(event) {
    setAppointmentTitle(event.target.value)
  }

  function handleAppointmentDescription(event) {
    setAppointmentDescription(event.target.value)
  }

  function handleAddAppointmentInfo() {
    // validar que se llenen todos los campos
    if (document.getElementById('appointment-datepicker').value === '') {
      enqueueNotification(lang.dateError, 'error')
      return
    }
    if (appointmentTitle === '') {
      enqueueNotification(lang.titleError, 'error')
      return
    }
    //setAddingAppointment(true)
    setOpenAppointment(false)
  }

  // function handleDeleteAppointmentInfo() {
  //   setAddingAppointment(false)
  //   setAppointmentDateValue(new Date())
  //   setAppointmentTitle('')
  //   setAppointmentDescription('')
  // }

  function handleNoteTitle(event) {
    setNoteTitle(event.target.value)
  }

  function handleNoteDescription(event) {
    setNoteDescription(event.target.value)
  }

  function handleAddNoteInfo() {
    // validar que se llenen los campos
    if (noteTitle === '') {
      enqueueNotification(lang.titleError, 'error')
      return
    }
    if (noteDescription === '') {
      enqueueNotification(lang.descError, 'error')
      return
    }
    //setAddingNote(true)
    setOpenNote(false)
  }

  // function handleDeleteNoteInfo() {
  //   setAddingNote(false)
  //   setNoteTitle('')
  //   setNoteDescription('')
  // }

  function loadImgs() {
    let input = document.getElementById('fotos')
    if (input.files) {
      let arr = []
      for (var i = 0; i < input.files.length; i++) {
        arr.push(input.files[i])
        arr[i].path = input.files[i].name
      }
      setFiles(arr)
    }
  }

  function loadFiles() {
    let input = document.getElementById('documentos')
    if (input.files) {
      let arr = []
      for (var i = 0; i < input.files.length; i++) {
        arr.push(input.files[i])
        arr[i].path = input.files[i].name
      }
      setDocuments(arr)
    }
  }

  return (
    <Draggable draggableId={'col' + props.column.id} index={props.index}>
      {(provided, snapshot) => (
        // container
        <div
          className='column-container'
          {...provided.draggableProps}
          ref={provided.innerRef}
          style={getColumnStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
            theme
          )}
        >
          {/* title */}
          <div className='column-header' ref={myRef}>
            <h3
              className={renaming ? ' uk-hidden' : 'column-title'}
              {...provided.dragHandleProps}
              onDoubleClick={() => onColumnRename()}
            >
              {props.column.title}
            </h3>
            {renaming && (
              <ClickAwayListener onClickAway={() => setRenaming(false)}>
                <CTextInput
                  className={'rename-input'}
                  // style={{display:'none'}}
                  type='text'
                  name='newName'
                  // value={newName}
                  control={control}
                  // onChange={handleNewName}
                  onKeyPress={validate}
                  maxLength={45}
                />
              </ClickAwayListener>
            )}
            <a className='column-more' data-uk-icon='icon: more-vertical'>
              {' '}
            </a>
            <div data-uk-dropdown='mode: click; pos: bottom-justify'>
              <ul className='uk-list' style={{ marginBottom: 0 }}>
                <li
                  className='dropdown-option uk-dropdown-close'
                  onClick={() => props.newColumn(props.index + 1)}
                >
                  {lang.newColumn}
                </li>
                <li
                  className='last-option dropdown-option uk-dropdown-close'
                  onClick={() => props.deleteColumn(props.column.id)}
                >
                  {lang.deleteColumn}
                </li>
              </ul>
            </div>
          </div>
          {/* modal */}
          <Modal
            container={myRef.current}
            open={open}
            onClose={onCloseModal}
            center
            closeIcon={<IconButton icon='close' component='div' />}
            classNames={
              theme === 'dark'
                ? {
                    modal: 'customModalCRM-dark'
                  }
                : {
                    modal: 'customModalCRM-light'
                  }
            }
          >
            <h3 className='prospect-modal-title'>{lang.newProspect}</h3>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <CTextInput
                label={languageContext.dictionary.title}
                name='title'
                control={controlCard}
                required
                maxLength={45}
              />
              <CTextInput
                type='textarea'
                label={languageContext.dictionary.desc}
                name='description'
                control={controlCard}
                maxLength={2000}
                charCount
              />
              <CTextInput
                type='email'
                name='email'
                control={controlCard}
                maxLength={100}
                required
                label={'E-mail ' + lang.prospect}
              />

              {/* <div className="icons-prospect-container">
								<img
									src={UserIcon}
									uk-tooltip={languageContext.dictionary.addUser}
									onClick={onOpenUsers}
								/>
								<img
									src={DateWhite}
									uk-tooltip={languageContext.dictionary.addAppointment}
									onClick={onOpenAppointment}
								/>
								<img
									src={NoteIcon}
									uk-tooltip={languageContext.dictionary.addNote}
									onClick={onOpenNote}
								/>
							</div> */}

              <div className={addingUsers ? 'info-container' : 'uk-hidden'}>
                {users && users.length > 0 && (
                  <div
                    className={
                      theme === 'dark' ? 'info-header-dark' : 'info-header'
                    }
                  >
                    <h5> {languageContext.dictionary.users} </h5>
                  </div>
                )}
                {users.map((user, index) => (
                  <div className='user-modal' key={'user-column-' + index}>
                    {user.data.type === 1 ? (
                      <img
                        className='user-photo-modal uk-border-circle'
                        src={user.data.client.image}
                        alt={UserIcon}
                      />
                    ) : (
                      <img
                        className='user-photo-modal uk-border-circle'
                        src={user.data.image}
                        alt={UserIcon}
                      />
                    )}
                    <div className='user-modal-container'>
                      <div>@{user.data.nickname}</div>
                      <div>{user.data.name}</div>
                    </div>
                    <span
                      className='delete-text'
                      onClick={() => handleDeleteUsersInfo(index)}
                    >
                      {languageContext.dictionary.delete}
                    </span>
                  </div>
                ))}
              </div>

              {/* <div
								className={
									addingAppointment
										? "info-container"
										: "uk-hidden"
								}
							>
								<div className={theme == "dark" ? "info-header-dark" : "info-header"}>
									<h5> {lang.appointmentInfo} </h5>
									<span
										className="delete-text"
										onClick={handleDeleteAppointmentInfo}
									>
										{languageContext.dictionary.delete}
									</span>
								</div>
								<div className="appointment-container">
									<img
										className="appointment-icon"
										src={DateWhite}
										alt="icon"
									/>
									<span className="appointment-text">
										{format(
											new Date(appointmentDateValue),
											"yyyy-MM-dd'T'hh:mm"
										)}
									</span>
								</div>
								<div className="appointment-container">
									<img
										className="appointment-icon"
										src={SquareIcon}
										alt="icon"
									/>
									<span className="appointment-text">
										{appointmentTitle}
									</span>
								</div>
								<span className="appointment-text">
									{appointmentDescription}
								</span>
								<div className="appointment-container"></div>
							</div> */}

              {/* <input
								type="hidden"
								value={appointmentDateValue}
							></input>
							<input
								type="hidden"
								value={appointmentTitle}
							></input>
							<input
								type="hidden"
								value={appointmentDescription}
							></input> */}

              {/* <div
								className={
									addingNote ? "info-container" : "uk-hidden"
								}
							>
								<div className={theme == "dark" ? "info-header-dark" : "info-header"}>
									<h5> {lang.note} </h5>
									<span
										className="delete-text"
										onClick={handleDeleteNoteInfo}
									>
										{languageContext.dictionary.delete}
									</span>
								</div>
								<div>{noteTitle}</div>
								<p>{noteDescription}</p>
							</div>

							<input
								type="hidden"
								value={noteDescription}
							></input> */}

              <div className='create-project'>
                <Button color='secondary' type='submit'>
                  {lang.newProspect}
                </Button>
              </div>
            </form>
          </Modal>
          {/* modal to add users */}
          <Modal
            open={openUsers}
            onClose={onCloseUsers}
            center
            closeIcon={<IconButton icon='close' component='div' />}
            classNames={
              theme === 'dark'
                ? {
                    modal: 'customModalCRM-dark'
                  }
                : {
                    modal: 'customModalCRM-light'
                  }
            }
          >
            <h3 className='modal-title'>
              {' '}
              {languageContext.dictionary.addUser}{' '}
            </h3>
            <UserSearchInput
              placeholder={lang.nickname}
              required
              theme='dark'
              name='user'
              value={invited}
              onChange={setInvited}
            />
            <div className='create-project'>
              <button className='diinco-2' onClick={handleAddUsersInfo}>
                {languageContext.dictionary.add}
              </button>
            </div>
          </Modal>
          {/* modal to add appointment */}
          <Modal
            open={openAppointment}
            onClose={onCloseAppointment}
            center
            closeIcon={<IconButton icon='close' component='div' />}
            classNames={
              theme === 'dark'
                ? {
                    modal: 'customModalCRM-dark'
                  }
                : {
                    modal: 'customModalCRM-light'
                  }
            }
          >
            <h3 className='modal-title'>
              {' '}
              {languageContext.dictionary.addAppointment}{' '}
            </h3>
            <TextInput
              id='appointment-datepicker'
              type='datetime-local'
              placeholder={languageContext.dictionary.date}
              theme='dark'
              name='time'
              init={appointmentDateValue}
              onChange={handleDateChange}
            />
            <TextInput
              type='text'
              placeholder={languageContext.dictionary.title}
              theme='dark'
              name='appointment-title'
              value={appointmentTitle}
              onChange={handleAppointmentTitle}
            />
            <TextInput
              type='text'
              placeholder={languageContext.dictionary.desc}
              theme='dark'
              name='appointment-description'
              value={appointmentDescription}
              onChange={handleAppointmentDescription}
            />
            <div className='create-project'>
              <button className='diinco-2' onClick={handleAddAppointmentInfo}>
                {languageContext.dictionary.add}
              </button>
            </div>
          </Modal>
          {/* modal to add note */}
          <Modal
            open={openNote}
            onClose={onCloseNote}
            center
            closeIcon={<IconButton icon='close' component='div' />}
            classNames={
              theme === 'dark'
                ? {
                    modal: 'customModalCRM-dark'
                  }
                : {
                    modal: 'customModalCRM-light'
                  }
            }
          >
            <h3 className='modal-title'>
              {' '}
              {languageContext.dictionary.addNote}{' '}
            </h3>
            <TextInput
              label={languageContext.dictionary.title}
              name='note-title'
              value={noteTitle}
              onChange={handleNoteTitle}
            />
            <TextAreaInput
              type='text'
              placeholder={languageContext.dictionary.desc}
              theme='dark'
              name='note-description'
              init={noteDescription}
              onChange={handleNoteDescription}
            />

            {/* <DropZoneCoverImage
							ContainerClasses="oski-note-images"
							ImageClasses="diinco-project-form-dropzone-image"
							handleDrop={setFiles}
						/> */}

            <div className='uk-grid uk-flex oski-flex-end oski-align-center'>
              <div data-uk-tooltip={'title: ' + lang.uploadImages}>
                <input
                  accept='image/*'
                  id='fotos'
                  type='file'
                  multiple
                  style={{ display: 'none' }}
                  onChange={loadImgs}
                />
                <label htmlFor='fotos'>
                  <img src={imgIcon} height='20px' className='oski-pointer' />
                </label>
              </div>
              <div data-uk-tooltip={'title: ' + lang.uploadFiles}>
                <input
                  accept='application/msword, application/pdf, application/txt, application/svg'
                  id='documentos'
                  type='file'
                  multiple
                  style={{ display: 'none' }}
                  onChange={loadFiles}
                />
                <label htmlFor='documentos'>
                  <img src={fileIcon} height='45px' className='oski-pointer' />
                </label>
              </div>
            </div>

            {files && files.length > 0 && (
              <ImageThumbsContainer
                control={control}
                thumbs={files}
                containerStyle='diinco-thumbs-container-project-form'
                thumbStyle='diinco-thumbs-style-project-form'
                deleteButtonStyle='diinco-thumbs-delete-project-form'
                handleRemove={(event, file, index) => {
                  setFiles(files.filter((o, i) => i !== index))
                }}
              />
            )}

            <div className='uk-grid'>
              {documents &&
                documents.length > 0 &&
                documents.map((document, index) => (
                  <div className='uk-width-1' key={'document-column-' + index}>
                    {document.name}
                  </div>
                ))}
            </div>

            {/* <DropZoneFiles
							onChange={(files) => {
								setDocuments(files);
							}}
						/> */}
            <div className='create-project'>
              <button className='diinco-2' onClick={handleAddNoteInfo}>
                {languageContext.dictionary.add}
              </button>
            </div>
          </Modal>
          <Droppable droppableId={props.column.id} type='task'>
            {/* task list */}
            {(provided, snapshot) => (
              <div
                className='task-list-container'
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={getListStyle(snapshot.isDraggingOver, theme)}
              >
                {props.tasks.map((task, index) => (
                  <Task key={task.id} task={task} index={index} />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  )
}

Column.defaultProps = {
  column: {},
  tasks: [],
  index: 0,
  newColumn: () => {},
  deleteColumn: () => {},
  renameColumn: () => {},
  newCard: () => {}
}

// definir aqui los props
Column.propTypes = {
  column: PropTypes.object,
  tasks: PropTypes.array,
  index: PropTypes.number,
  newColumn: PropTypes.func,
  deleteColumn: PropTypes.func,
  renameColumn: PropTypes.func,
  newCard: PropTypes.func
}
