import React, { useState, useEffect } from 'react'
import '../SlideShow/styles/style.css'
import 'react-responsive-modal/styles.css'

import './styles/style.css'

import SwiperCore, { Navigation, Virtual } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import PropTypes from 'prop-types'

// install Virtual module
SwiperCore.use([Virtual, Navigation])

export default function Gallery(props) {
  const [swiperRef, setSwiperRef] = useState()
  const [swiperMiniRef, setSwiperMiniRef] = useState()
  const { images, navigation, current } = props
  const [currentPhotos, setCurrentPhotos] = useState([])
  const [didMount, setDidMount] = useState(false)
  const [active, setActive] = useState(0)

  const slideTo = (index) => {
    swiperRef.slideTo(index - 1, 0)
  }

  useEffect(() => {
    if (images && images.length > 0) {
      setCurrentPhotos(images)
    }
  }, [images])

  useEffect(() => {
    setDidMount(true)
  }, [])

  useEffect(() => {
    if (
      didMount &&
      swiperRef &&
      currentPhotos &&
      currentPhotos.length > 0 &&
      current !== ''
    ) {
      swiperRef.slideTo(current, 0)
      swiperMiniRef.slideTo(current, 0)
    }
  }, [current, currentPhotos, didMount, swiperMiniRef, swiperRef])

  function slideChange() {
    swiperMiniRef.slideTo(swiperRef.activeIndex, 0)
    setActive(swiperRef.activeIndex)
  }

  return (
    <>
      {currentPhotos && currentPhotos.length > 0 && (
        <Swiper
          onSwiper={setSwiperRef}
          navigation={navigation}
          virtual
          onSlideChange={slideChange}
        >
          {currentPhotos.map((photo, index) => (
            <SwiperSlide
              key={'Current-Photo-' + index}
              virtualIndex={index}
              className='oski-customGallery-cover'
            >
              <a href={photo.url} target='_blank' rel='noreferrer'>
                <img
                  className='oski-customGallery-cover-img'
                  src={photo.url}
                  alt=''
                />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {/* other photos of the album */}
      <div className='other-photos-container uk-margin-small-top'>
        <Swiper
          spaceBetween={10}
          freeMode={true}
          slidesPerView={'auto'}
          onSwiper={setSwiperMiniRef}
        >
          {currentPhotos.map((photo, index) => (
            <SwiperSlide
              className={
                active === index
                  ? 'mini-photo-container active'
                  : 'mini-photo-container'
              }
              onClick={() => slideTo(index + 1)}
              key={'Other-Photo-' + index}
            >
              <img
                className='oski-customGallery-miniPhoto'
                src={photo.url}
                alt=''
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  )
}

Gallery.defaultProps = {
  navigation: false,
  current: '',
  images: []
}

Gallery.propTypes = {
  navigation: PropTypes.bool,
  current: PropTypes.any,
  images: PropTypes.array
}
