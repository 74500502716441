import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo
} from 'react'
import { useParams } from 'react-router'
import _Auth from '../../context/auth'
import _Utils from '../../context/utils'
import Modal from 'react-responsive-modal'

import './styles/style.css'
import Folder from './assets/folder-solid.svg'
import FolderPlus from './assets/folder-plus-solid.svg'
import FilePlus from './assets/file-medical-solid.svg'

import { LanguageContext } from '../../context/language/context'

import { _handleErrorMessage } from '../../functions/error.functions'
import { CTextInput } from '../../components/TextInput'
import { useForm } from 'react-hook-form'
import Button from '../../components/Button'
import { serialize } from '../../functions/validation.functions'
import { serverValidation } from '../../functions/validation.functions'
import StandardView from '../../layouts/StandardView'

import { useDropzone } from 'react-dropzone'
import { format } from 'date-fns'
import Typography from '../../components/Typography'
import { IconButton } from '../../components/IconButton'
import ProjectPlansModal from '../../components/ProjectPlansModal'

export default function Documents() {
  const idP = useParams().id
  const languageContext = useContext(LanguageContext)
  const { theme } = languageContext
  const { toggleLoader, enqueueNotification } = useContext(_Utils.Context)
  const { token, hasRole, hasPermission } = useContext(_Auth.Context)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const [maxStorage, setMaxStorage] = useState(0)
  const [storageUsed, setStorageUsed] = useState(0)

  const myRef = useRef(null)
  const fileRef = useRef(null)
  const [folders, setFolders] = useState(null)
  const [active, setActive] = useState(null)
  const [open, setOpen] = useState(false)
  const [loader, setLoader] = useState(true)

  const onOpenModal = () => {
    setOpen(true)
  }
  const onCloseModal = () => setOpen(false)

  const [openProjectPlansModal, setOpenProjectPlansModal] = useState(false)
  function onOpenProjectPlansModal() {
    setOpenProjectPlansModal(true)
  }

  function onCloseProjectPlansModal() {
    setOpenProjectPlansModal(false)
  }

  useEffect(() => {
    if (token) {
      init()
    }
  }, [token])

  async function init() {
    let data = { idP: idP }
    let response = await fetch(
      process.env.REACT_APP_API_URL + 'documents/getall',
      {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
          Accept: 'multipart/form-data',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }
    )

    await serverValidation(
      response,
      null,
      LanguageContext.userLanguage,
      async (data) => {
        setFolders(data?.data)
        setActive(data?.data[0])
        setMaxStorage(data?.projectFeatures['max-storage'])
        setStorageUsed(data?.projectStorage)
      },
      async (error) => {
        await toggleLoader(false)
        enqueueNotification(
          _handleErrorMessage(languageContext?.userLanguage, error.error),
          'error'
        )
      }
    )
    setLoader(false)
  }

  async function initActive(activeId) {
    let data = { idP: idP }
    fetch(process.env.REACT_APP_API_URL + 'documents/getall', {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (data) {
        setFolders(data)
        setActive(data[activeId])
      })
      .catch((error) => console.error(error))

    setLoader(false)
  }

  const { reset, control, setError, handleSubmit } = useForm({
    defaultValues: {
      folderName: ''
    }
  })

  const [disableButton, setDisableButton] = useState(false)

  async function onSubmit(data) {
    await toggleLoader(true)
    setDisableButton(true)
    data = { ...data, projectId: idP }
    const formData = serialize(data, {
      dotsForObjectNotation: true,
      noArrayNotation: true,
      noFilesWithArrayNotation: true
    })

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'documents/create',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data'
          },
          body: formData
        }
      )

      await serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        async (dataR) => {
          enqueueNotification(languageContext.dictionary['success'], 'success')
          reset()
          onCloseModal()
          setFolders([...folders, dataR.details.folder])
          await toggleLoader(false)
          setDisableButton(false)
          //history.push("/project/" + projectId);
        },
        async (e) => {
          await toggleLoader(false)
          setDisableButton(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, e.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      setDisableButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    await toggleLoader(false)
  }

  const [openEdit, setOpenEdit] = useState(false)

  const onCloseEdit = () => setOpenEdit(false)

  const {
    reset: resetEdit,
    getValues: getValuesEdit,
    control: controlEdit,
    setError: setErrorEdit,
    handleSubmit: handleSubmitEdit
  } = useForm({
    defaultValues: {
      folderName: '',
      folderId: null
    }
  })

  async function onSubmitEdit() {
    await toggleLoader(true)
    setDisableButton(true)
    //data = { ...data, "projectId": idP }
    //const formData = serialize(data, { dotsForObjectNotation: true, noArrayNotation: true, noFilesWithArrayNotation: true })

    const formData = new FormData()
    let folderId = getValuesEdit('folderId')

    formData.append('folderId', folderId)
    formData.append('folderName', getValuesEdit('folderName'))
    formData.append('projectId', idP)

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'documents/update',
        {
          method: 'PUT',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data'
          },
          body: formData
        }
      )

      await serverValidation(
        response,
        setErrorEdit,
        languageContext.userLanguage,
        async (dataR) => {
          enqueueNotification(languageContext.dictionary['success'], 'success')
          resetEdit()
          onCloseEdit()
          let edit = folders
          let indice = 0
          edit.forEach((folder, index) => {
            if (folder.id === folderId) {
              edit[index] = dataR.details.folder
              indice = index
            }
          })
          setFolders(edit)
          setActive(edit[indice])
          await toggleLoader(false)
          setDisableButton(false)
          disableInputRenameFolder(folderId)
          //history.push("/project/" + projectId);
        },
        async (e) => {
          await toggleLoader(false)
          setDisableButton(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, e.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      setDisableButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    await toggleLoader(false)
  }

  const [openDelete, setOpenDelete] = useState(false)

  const onOpenDelete = () => {
    setOpenDelete(true)
  }
  const onCloseDelete = () => setOpenDelete(false)

  const { reset: resetDelete, handleSubmit: handleSubmitDelete } = useForm({
    defaultValues: {
      folderId: null
    }
  })

  const deleteFolder = (id, isFolder) => {
    if (isFolder) {
      resetDelete({
        folderId: id
      })
      onOpenDelete()
    } else {
      enqueueNotification(lang.folderNotDeletable, 'error')
    }
  }

  async function onSubmitDelete(data) {
    await toggleLoader(true)
    setDisableButton(true)
    data = { ...data, projectId: idP }
    const formData = serialize(data, {
      dotsForObjectNotation: true,
      noArrayNotation: true,
      noFilesWithArrayNotation: true
    })

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'documents/delete',
        {
          method: 'DELETE',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data'
          },
          body: formData
        }
      )

      await serverValidation(
        response,
        setErrorEdit,
        languageContext.userLanguage,
        async () => {
          enqueueNotification(languageContext.dictionary['success'], 'success')
          resetDelete()
          onCloseDelete()

          if (active.id === data.folderId) {
            init()
          } else {
            let edit = folders
            let indice = null
            for (let i = 0; i < edit.length; i++) {
              if (edit[i].id === data.folderId) {
                indice = i
                break
              }
            }
            if (indice) {
              edit.splice(indice, 1)
            }
            for (let i = 0; i < edit.length; i++) {
              if (edit[i].id === active.id) {
                indice = i
                break
              }
            }
            initActive(indice)
          }
          await toggleLoader(false)
          setDisableButton(false)
          //history.push("/project/" + projectId);
        },
        async (e) => {
          await toggleLoader(false)
          setDisableButton(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, e.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      setDisableButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    await toggleLoader(false)
  }

  const addFiles = () => {
    var input = fileRef.current
    input.click()
  }

  const [files, setFiles] = useState([])

  function onChangeFiles(e) {
    let archivos = e.target.files
    let prov = []
    for (let i = 0; i < archivos.length; i++) {
      prov.push(archivos[i])
    }
    setFiles(prov)
  }

  useEffect(() => {
    if (files.length > 0) {
      sendFiles()
    }
  }, [files])

  async function sendFiles() {
    await toggleLoader(true)
    try {
      const data = new FormData()
      for (const file of files) {
        data.append('files', file, file.name)
      }
      data.append('projectId', idP)
      data.append('folderId', active.id)
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'documents/files/upload',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data'
          },
          body: data
        }
      )
      const file = await response.json()
      if (file.message === 'success') {
        setFiles([])
        let carpetas = folders
        let i = 0
        for (; i < carpetas.length; i++) {
          if (carpetas[i].id === file.details.folder.id) {
            break
          }
        }
        carpetas[i] = file.details.folder

        setFolders(carpetas)
        calculateSize()
        setActive(file.details.folder)
        enqueueNotification(languageContext.dictionary['success'], 'success')
        setDisableButton(false)
      } else {
        if (file.code === 8011) {
          onOpenProjectPlansModal()

          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, file),
            'error'
          )
        } else if (file.code === 3001) {
          let mensaje =
            languageContext.userLanguage === 'es'
              ? file.details.errors[0].msg.es
              : file.details.errors[0].msg.en
          enqueueNotification(mensaje, 'error')
        } else if (file.code === 8009) {
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, file),
            'error'
          )
        } else {
          throw new Error('error')
        }
      }
    } catch (error) {
      setDisableButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    await toggleLoader(false)
  }

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    if (hasRole('professional')) setFiles(acceptedFiles)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop
  })

  const activeStyle = {
    borderColor: '#f9b74e',
    borderStyle: 'dashed',
    borderRadius: '5px',
    borderWidth: '2px'
  }

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {})
    }),
    [isDragActive]
  )

  const onDragStart = (ev, id) => {
    ev.dataTransfer.setData('id', id)
    ev.currentTarget.style.opacity = '0.5'
  }

  const onDragEnd = (ev) => {
    ev.currentTarget.style.opacity = '1'
  }

  const onDragOver = (ev) => {
    ev.preventDefault()
  }

  const onDropFile = (ev) => {
    if (hasRole('professional')) {
      let id = ev.dataTransfer.getData('id')
      let folderId = ev.currentTarget.id
      if (active.id !== folderId) {
        submitFileMove(id, folderId)
      }
    }
  }

  async function submitFileMove(fileId, folderId) {
    await toggleLoader(true)
    try {
      const data = new FormData()
      data.append('fileId', fileId)
      data.append('folderId', folderId)
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'documents/files/move',
        {
          method: 'PATCH',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data'
          },
          body: data
        }
      )
      const file = await response.json()
      if (file.message === 'success') {
        const folderOld = file.details.folders[0]
        const folderNew = file.details.folders[1]
        let carpetas = folders
        let iOld = 0
        let iNew = 0
        for (let i = 0; i < carpetas.length; i++) {
          if (carpetas[i].id === folderOld.id) {
            iOld = i
          }
          if (carpetas[i].id === folderNew.id) {
            iNew = i
          }
        }
        carpetas[iOld] = folderOld
        carpetas[iNew] = folderNew
        setFolders(carpetas)
        if (active.id === folderOld.id) setActive(folderOld)
        if (active.id === folderNew.id) setActive(folderNew)
        enqueueNotification(languageContext.dictionary['success'], 'success')
        setDisableButton(false)
      } else {
        throw new Error('error')
      }
    } catch (error) {
      setDisableButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    await toggleLoader(false)
  }

  const [openDeleteFile, setOpenDeleteFile] = useState(false)

  const onOpenDeleteFile = () => {
    setOpenDeleteFile(true)
  }
  const onCloseDeleteFile = () => setOpenDeleteFile(false)

  const {
    reset: resetDeleteFile,
    setError: setErrorDeleteFile,
    handleSubmit: handleSubmitDeleteFile
  } = useForm({
    defaultValues: {
      fileId: null
    }
  })

  const deleteFolderFile = (id) => {
    resetDeleteFile({
      fileId: id
    })
    onOpenDeleteFile()
  }

  async function onSubmitDeleteFile(data) {
    await toggleLoader(true)
    setDisableButton(true)
    data = { ...data, projectId: idP, folderId: active.id }
    const formData = serialize(data, {
      dotsForObjectNotation: true,
      noArrayNotation: true,
      noFilesWithArrayNotation: true
    })

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'documents/files/delete',
        {
          method: 'DELETE',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data'
          },
          body: formData
        }
      )

      await serverValidation(
        response,
        setErrorDeleteFile,
        languageContext.userLanguage,
        async (dataR) => {
          enqueueNotification(languageContext.dictionary['success'], 'success')
          resetDeleteFile()
          onCloseDeleteFile()
          let carpetas = folders
          let i = 0
          for (; i < carpetas.length; i++) {
            if (carpetas[i].id === dataR.details.folder.id) {
              break
            }
          }
          carpetas[i] = dataR.details.folder
          setFolders(carpetas)
          calculateSize()
          setActive(dataR.details.folder)
          await toggleLoader(false)
          setDisableButton(false)
          //history.push("/project/" + projectId);
        },
        async (e) => {
          if (e?.error?.code === 8011) {
            onOpenProjectPlansModal()
          }
          await toggleLoader(false)
          setDisableButton(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, e.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      setDisableButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    await toggleLoader(false)
  }

  const changeActive = (index) => {
    setActive(folders[index])
  }

  function validateFile(e) {
    const tecla = document.all ? e.keyCode : e.which
    if (tecla === 13) {
      // funcion para renombrar una columna
      submitChangeNameFile()
    }
  }

  const {
    reset: resetFileEdit,
    getValues: getValuesFileEdit,
    control: controlFileEdit,
    setError: setErrorFileEdit
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      newName: '',
      idFile: ''
    }
  })

  const [isEditing, setIsEditing] = useState({ flag: false, id: null })

  const enableInputRename = (idFile, name) => {
    setIsEditing({ flag: true, id: idFile })
    var elements = document.getElementsByClassName('rename-file-input')
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.display = 'none'
      let newId = 'input_' + idFile
      if (elements[i].id === newId) {
        elements[i].style.display = 'block'
        elements[i].focus()
      }
    }

    elements = document.getElementsByClassName('file-title')
    for (i = 0; i < elements.length; i++) {
      elements[i].style.display = 'block'
      let newId = 'title_' + idFile
      if (elements[i].id === newId) elements[i].style.display = 'none'
    }

    resetFileEdit({
      newName: name,
      idFile: idFile
    })
  }

  const disableInputRename = (idFile) => {
    let newId = 'input_' + idFile
    var element = document.getElementById(newId)
    element.style.display = 'none'
    newId = 'title_' + idFile
    element = document.getElementById(newId)
    element.style.display = 'block'
    setIsEditing({ flag: false, id: null })
  }

  async function submitChangeNameFile() {
    await toggleLoader(true)
    setDisableButton(true)
    const data = new FormData()
    let fileId = getValuesFileEdit('idFile')

    data.append('fileId', fileId)
    data.append('newName', getValuesFileEdit('newName'))

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'documents/files/rename',
        {
          method: 'PATCH',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data'
          },
          body: data
        }
      )

      await serverValidation(
        response,
        setErrorFileEdit,
        languageContext.userLanguage,
        async (dataR) => {
          enqueueNotification(languageContext.dictionary['success'], 'success')
          resetFileEdit()
          disableInputRename(fileId)
          let carpetas = folders
          let i = 0
          for (; i < carpetas.length; i++) {
            if (carpetas[i].id === dataR.details.folder.id) {
              break
            }
          }
          carpetas[i] = dataR.details.folder
          setFolders(carpetas)
          setActive(dataR.details.folder)
          await toggleLoader(false)
          setDisableButton(false)
          //history.push("/project/" + projectId);
        },
        async (e) => {
          await toggleLoader(false)
          setDisableButton(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, e.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      setDisableButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    await toggleLoader(false)
  }

  function putTooltip(name) {
    if (name.length > 39) {
      return name
    } else {
      return ''
    }
  }

  const [isEditingFolder, setIsEditingFolder] = useState({
    flag: false,
    id: null
  })

  const enableInputRenameFolder = (idFolder, name, isFolder) => {
    if (!isFolder) {
      enqueueNotification(lang.folderNotRenameble, 'error')
      return ''
    }
    setIsEditingFolder({ flag: true, id: idFolder })
    var elements = document.getElementsByClassName('rename-folder-input')
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.display = 'none'
      let newId = 'inputFolder_' + idFolder
      if (elements[i].id === newId) {
        elements[i].style.display = 'block'
        elements[i].focus()
      }
    }

    elements = document.getElementsByClassName('folder-title')
    for (i = 0; i < elements.length; i++) {
      elements[i].style.display = 'block'
      let newId = 'titleFolder_' + idFolder
      if (elements[i].id === newId) elements[i].style.display = 'none'
    }

    resetEdit({
      folderName: name,
      folderId: idFolder
    })
  }

  const disableInputRenameFolder = (idFolder) => {
    let newId = 'inputFolder_' + idFolder
    var element = document.getElementById(newId)
    element.style.display = 'none'
    newId = 'titleFolder_' + idFolder
    element = document.getElementById(newId)
    element.style.display = 'block'
    setIsEditingFolder({ flag: false, id: null })
  }

  function validateFolder(e) {
    const tecla = document.all ? e.keyCode : e.which
    if (tecla === 13) {
      // funcion para renombrar una columna
      onSubmitEdit()
    }
  }

  //se calcula el espacio utilizado
  //   const [peso, setPeso] = useState(0)

  async function calculateSize() {
    // if (folders) {
    //   let suma = 0
    //   if (folders.length > 0) {
    //     for (let index = 0; index < folders.length; index++) {
    //       let folder = folders[index]
    //       if (folder.Documents) {
    //         for (let j = 0; j < folder.Documents.length; j++) {
    //           let document = folder.Documents[j]
    //           if (document.size) {
    //             let weight = document.size
    //             weight = weight.split(' ')
    //             if (weight[1] === 'kb') {
    //               let conversion = parseFloat(weight[0]) / 1000
    //               suma = suma + conversion
    //             } else if (weight[1] === 'MB') {
    //               suma = suma + parseFloat(weight[0])
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    //   setPeso(suma.toFixed(3))
    let data = { idP: idP }
    let response = await fetch(
      process.env.REACT_APP_API_URL + 'documents/getall',
      {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
          Accept: 'multipart/form-data',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }
    )

    await serverValidation(
      response,
      null,
      LanguageContext.userLanguage,
      async (data) => {
        setMaxStorage(data?.projectFeatures['max-storage'])
        setStorageUsed(data?.projectStorage)
      },
      async (error) => {
        await toggleLoader(false)
        enqueueNotification(
          _handleErrorMessage(languageContext?.userLanguage, error.error),
          'error'
        )
      }
    )
    setLoader(false)
  }

  useEffect(async () => {
    await calculateSize(folders)
  }, [folders])

  return (
    <StandardView
      title={lang.title}
      links={[
        {
          link: '/Dashboard/Projects',
          title: languageContext.dictionary.dashboard
        },
        {
          link: '/Projects/' + idP,
          title: languageContext.dictionary.projectDashboard
        }
      ]}
      loading={loader}
    >
      <div
        className='uk-flex uk-flex-center uk-margin-large-top'
        data-uk-grid
        ref={myRef}
      >
        <div className='uk-width-1-6'>
          <div className='leo-documents-left'>
            {folders &&
              folders.length > 0 &&
              folders.map((foo, index) => {
                let dark =
                  active?.id === foo.id ? 'folder-dark active' : 'folder-dark'
                let light =
                  active?.id === foo.id ? 'folder-light active' : 'folder-light'
                return (
                  <div
                    className={languageContext.theme === 'dark' ? dark : light}
                    key={index}
                    onDragOver={(e) => onDragOver(e)}
                    onDrop={(e) => onDropFile(e)}
                    id={foo.id}
                  >
                    <img
                      src={Folder}
                      alt='folder'
                      onClick={() => changeActive(index)}
                    />

                    <div className='folder-name'>
                      <p
                        className='folder-title'
                        id={'titleFolder_' + foo.id}
                        onDoubleClick={() => {
                          index !== 0 &&
                            foo.name !== 'General' &&
                            hasPermission(
                              'project-update',
                              'or',
                              idP,
                              'project'
                            ) &&
                            enableInputRenameFolder(
                              foo.id,
                              foo.name,
                              foo.is_folder
                            )
                        }}
                        style={{
                          cursor:
                            index !== 0 && foo.name !== 'General'
                              ? 'pointer'
                              : 'inherit'
                        }}
                      >
                        {foo.name}
                      </p>
                      <CTextInput
                        className={'rename-folder-input'}
                        classes={{
                          containerClass: 'rename-file-container'
                        }}
                        // style={{display:'none'}}
                        type='text'
                        name='folderName'
                        // value={newName}
                        control={controlEdit}
                        // onChange={handleNewName}
                        onKeyPress={validateFolder}
                        maxLength={75}
                        id={'inputFolder_' + foo.id}
                      />
                      {isEditingFolder.flag &&
                        isEditingFolder.id === foo.id && (
                          <div className='file-edit folder'>
                            <IconButton
                              icon='close'
                              size='mini'
                              onClick={() => disableInputRenameFolder(foo.id)}
                              color={theme === 'light' ? 'dark' : 'grey'}
                            />
                          </div>
                        )}
                    </div>

                    {index !== 0 &&
                      foo.name !== 'General' &&
                      hasPermission('project-update', 'or', idP, 'project') && (
                        <div className='options-folder'>
                          <button type='button'>
                            <span data-uk-icon='more-vertical'></span>
                          </button>
                          <div
                            data-uk-dropdown='mode: click; pos: right-center '
                            className='dropdownFolders'
                          >
                            <ul className='uk-list'>
                              <li className='dropdown-option first-option uk-dropdown-close'>
                                <a
                                  onClick={() =>
                                    enableInputRenameFolder(
                                      foo.id,
                                      foo.name,
                                      foo.is_folder
                                    )
                                  }
                                >
                                  {lang.rename}
                                </a>
                              </li>
                              <li className='dropdown-option first-option uk-dropdown-close'>
                                <a
                                  onClick={() =>
                                    deleteFolder(foo.id, foo.is_folder)
                                  }
                                >
                                  {lang.delete}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                  </div>
                )
              })}

            {hasRole('professional') && (
              <div
                className={
                  languageContext.theme === 'dark'
                    ? 'folder-plus-dark'
                    : 'folder-plus-light'
                }
                onClick={onOpenModal}
              >
                <img src={FolderPlus} alt='folder-plus' />
              </div>
            )}
          </div>
        </div>
        <div className='uk-width-2-3'>
          <div className='title-folder-right'>{active?.name}</div>
          <div
            className='leo-documents-right'
            {...getRootProps({
              style,
              onClick: (event) => event.stopPropagation()
            })}
          >
            <input {...getInputProps()} style={{ display: 'none' }} />
            {hasRole('professional') && (
              <img
                src={FilePlus}
                alt='file-plus'
                className={
                  languageContext.theme === 'dark'
                    ? 'leo-file-plus-dark'
                    : 'leo-file-plus'
                }
                onClick={() => addFiles()}
              />
            )}
            <input
              type={'file'}
              name='files'
              style={{ display: 'none' }}
              ref={fileRef}
              onChange={(e) => onChangeFiles(e)}
              multiple
            />
            {active &&
              active.Documents &&
              active.Documents.length > 0 &&
              active.Documents.map((file, index) => {
                return (
                  <div
                    className='file-container'
                    draggable
                    onDragStart={(e) => onDragStart(e, file.id)}
                    onDragEnd={(e) => onDragEnd(e)}
                    key={index}
                    style={{ cursor: 'move' }}
                  >
                    <div className='file-name'>
                      <p
                        className='file-title'
                        id={'title_' + file.id}
                        data-uk-tooltip={putTooltip(file.title)}
                        onClick={() => {
                          window.open(
                            process.env.REACT_APP_API_URL + file.url,
                            file.title
                          )
                        }}
                      >
                        {file.title}
                      </p>
                      <CTextInput
                        className={'rename-file-input'}
                        classes={{
                          containerClass: 'rename-file-container'
                        }}
                        // style={{display:'none'}}
                        type='text'
                        name='newName'
                        // value={newName}
                        control={controlFileEdit}
                        // onChange={handleNewName}
                        onKeyPress={validateFile}
                        maxLength={75}
                        id={'input_' + file.id}
                      />
                      {hasPermission(
                        'project-update',
                        'or',
                        idP,
                        'project'
                      ) && (
                        <div className='file-edit'>
                          {isEditing.flag ? (
                            isEditing.id === file.id ? (
                              <IconButton
                                icon='close'
                                size='mini'
                                color={theme === 'dark' ? 'grey' : 'dark'}
                                iconColor={'light'}
                                onClick={() => disableInputRename(file.id)}
                              />
                            ) : (
                              <IconButton
                                icon='edit'
                                size='mini'
                                color={theme === 'dark' ? 'dark' : 'grey'}
                                iconColor={'light'}
                                onClick={() =>
                                  enableInputRename(file.id, file.title)
                                }
                              />
                            )
                          ) : (
                            <IconButton
                              icon='edit'
                              size='mini'
                              color={theme === 'dark' ? 'grey' : 'dark'}
                              iconColor={'light'}
                              onClick={() =>
                                enableInputRename(file.id, file.title)
                              }
                            />
                          )}
                        </div>
                      )}
                      <div className='file-size'>{file.size}</div>
                    </div>
                    <div className='file-date'>
                      {format(new Date(file.updatedAt), 'dd/MM/yyyy HH:mm')}
                    </div>
                    {hasPermission('project-update', 'or', idP, 'project') && (
                      <div className='file-delete'>
                        <IconButton
                          icon='delete'
                          size='mini'
                          color={theme === 'dark' ? 'grey' : 'dark'}
                          iconColor={'light'}
                          onClick={() => deleteFolderFile(file.id)}
                        />
                      </div>
                    )}
                  </div>
                )
              })}

            <div className='file-container-footer'>
              {hasRole('professional') && lang.footer}
            </div>
          </div>
          <div className='uk-margin-top uk-flex uk-flex-center uk-flex-wrap'>
            <progress
              className='uk-progress leo-progress'
              value={storageUsed}
              max={maxStorage}
            ></progress>
            <div>
              <Typography variant={'body2'} disableMargin>
                Almacenamiento utilizado: {storageUsed} MB de {maxStorage} MB
              </Typography>
            </div>
          </div>
        </div>
      </div>

      {/* Modal new folder */}
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        container={myRef.current}
        classNames={{ modal: 'customModalFolder' }}
      >
        <div className='title-new-folder'>{lang.newFolder}</div>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <CTextInput
            label={languageContext.dictionary.name}
            name='folderName'
            control={control}
            required
            maxLength={100}
          />
          <div className='uk-flex uk-flex-center'>
            <Button
              type='submit'
              size='small'
              color='secondary'
              disabled={disableButton}
              centered={true}
              containerStyle={{ marginBottom: 0 }}
            >
              {languageContext.dictionary['save']}
            </Button>
          </div>
        </form>
      </Modal>

      {/* Modal edit folder */}
      <Modal
        open={openEdit}
        onClose={onCloseEdit}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        container={myRef.current}
        classNames={{ modal: 'customModalFolder' }}
      >
        <div className='title-new-folder'>{lang.rename}</div>
        <form onSubmit={handleSubmitEdit(onSubmitEdit)} noValidate>
          <CTextInput
            label={languageContext.dictionary.name}
            name='folderName'
            control={controlEdit}
            required
            maxLength={100}
          />
          <div className='uk-flex uk-flex-center'>
            <Button
              type='submit'
              size='small'
              color='secondary'
              disabled={disableButton}
              centered={true}
              containerStyle={{ marginBottom: 0 }}
            >
              {languageContext.dictionary['save']}
            </Button>
          </div>
        </form>
      </Modal>

      {/* Modal delete folder */}
      <Modal
        open={openDelete}
        onClose={onCloseDelete}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        container={myRef.current}
        classNames={{ modal: 'customModalDeleteFolder' }}
      >
        <div className='title-new-folder'>{lang.deleteM}</div>
        <form onSubmit={handleSubmitDelete(onSubmitDelete)} noValidate>
          <div className='uk-flex uk-flex-center'>
            <Button
              type='submit'
              size='small'
              color='dark'
              disabled={disableButton}
              centered={true}
              containerStyle={{ marginBottom: 0 }}
            >
              {languageContext.dictionary.accept}
            </Button>
          </div>
        </form>
      </Modal>

      {/* Modal delete file */}
      <Modal
        open={openDeleteFile}
        onClose={onCloseDeleteFile}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        container={myRef.current}
        classNames={{ modal: 'customModalDeleteFolder' }}
      >
        <div className='title-new-folder'>{lang.deleteM}</div>
        <form onSubmit={handleSubmitDeleteFile(onSubmitDeleteFile)} noValidate>
          <div className='uk-flex uk-flex-center'>
            <Button
              type='submit'
              size='small'
              color='dark'
              disabled={disableButton}
              centered={true}
              containerStyle={{ marginBottom: 0 }}
            >
              {languageContext.dictionary.accept}
            </Button>
          </div>
        </form>
      </Modal>

      {idP && (
        <ProjectPlansModal
          projectId={idP}
          open={openProjectPlansModal}
          onClose={onCloseProjectPlansModal}
        />
      )}
    </StandardView>
  )
}
