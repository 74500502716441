import React, { useReducer, useState } from 'react'
import Reducer from './reducer'
import Context from './context'
import './utils.css'
import Snack from '../../components/Snack/Snack'
// import logo_colour from "./assets/diinco_colour.png";
import logo_colour from '../../assets/images/logo_white.png'
import logo_black from './assets/logo_black.png'
import logo_white from './assets/diinco_white.png'
import logo_gray from './assets/diinco_gray.png'
import logo_colour_white from './assets/diinco_colour_white.png'
import NavBar from '../../components/NavBar'
import HomeLoader from '../../components/HomeLoader'
import PropTypes from 'prop-types'

const Utils = (props) => {
  const initialState = {
    loader: false,
    logo: {
      colour: logo_colour,
      black: logo_black,
      white: logo_white,
      colour_white: logo_colour_white,
      gray: logo_gray
    },
    lang: 'en'
  }
  const [state, dispatch] = useReducer(Reducer, initialState)

  const [notifications, setNotifications] = useState([])

  function toggleLoader(status = false) {
    dispatch({ type: 'TOGGLE_LOADER', payload: status })
  }
  function toggleLang(lang = 'en') {
    dispatch({ type: 'TOGGLE_LANG', payload: lang })
  }

  function enqueueNotification(
    message = 'Notification...',
    status = 'default'
  ) {
    setNotifications([...notifications, { message, status }])
  }

  async function validateExistence(values) {
    let { nickname, phone, email } = values
    try {
      let data = await fetch(
        process.env.REACT_APP_API_URL + 'updates/validate',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ nickname, phone, email })
        }
      )
      data = await data.json()
      return data
    } catch (error) {
      return { error }
    }
  }

  const [absouleNotification, setAbsoluteNotification] = useState(false)
  const [notificationText, setNotificationText] = useState('Success')

  function notify(text = 'Success', callback = () => {}) {
    let backup = notificationText
    setAbsoluteNotification(true)
    setNotificationText(text)
    setTimeout(() => {
      setAbsoluteNotification(false)
      setNotificationText(backup)
      callback()
    }, 1000)
  }

  return (
    <Context.Provider
      value={{
        ...state,
        toggleLoader,
        enqueueNotification,
        toggleLang,
        HomeLoader,
        validateExistence,
        notify
      }}
    >
      <NavBar />
      {/* <div className="diinco-utils-separator" /> */}
      {props.children}
      <div id='notification-container'>
        {notifications.map((notification, index) => (
          <Snack
            key={'notification_' + index}
            message={notification.message}
            status={notification.status}
          />
        ))}
      </div>
      {state.loader ? <div id='loader' /> : null}
      {absouleNotification ? (
        <div className='diinco-abosolute-notification-container'>
          <div>
            <p>{notificationText}</p>
          </div>
        </div>
      ) : null}
    </Context.Provider>
  )
}

Utils.defaultProps = {
  children: null
}

Utils.propTypes = {
  children: PropTypes.any
}

export default Utils
