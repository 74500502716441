import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import _Auth from '../../context/auth'
import _Utils from '../../context/utils'
import { useHistory } from 'react-router'
import './styles/style.css'
import { CTextInput } from '../../components/TextInput'
import Modal from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import ReCAPTCHA from 'react-google-recaptcha'

import { LanguageContext } from '../../context/language/context'
import { _handleErrorMessage } from '../../functions/error.functions'
import { useForm } from 'react-hook-form'
import { message, serverValidation } from '../../functions/validation.functions'
import Button from '../../components/Button'
import { IconButton } from '../../components/IconButton'

export default function Index() {
  const location = useLocation()
  let nextRoute = '/Dashboard'
  if (location && location.state && location.state.nextRoute) {
    nextRoute = location.state.nextRoute
  }
  const history = useHistory()
  const captcha = useRef(null)
  const { toggleLoader, enqueueNotification, logo } = useContext(_Utils.Context)
  const { login, codeAuth, token } = useContext(_Auth.Context)
  const [open, setOpen] = useState(false)
  const [token_data, setTokenData] = useState()
  const [areHumanMessage, setAreHumanMessage] = useState(null)
  const [disableButton, setDisableButton] = useState(false)
  const [disableButtonCode, setDisableButtonCode] = useState(false)

  const languageContext = useContext(LanguageContext)
  const { theme } = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const { control, handleSubmit, setError } = useForm({
    mode: 'onChange'
  })

  let date = new Date()
  date.setMinutes(date.getMinutes() + 5)
  date = date.toISOString()
  let timerStart = 'date: ' + date

  const onCloseModal = () => setOpen(false)

  useEffect(() => {
    if (token) {
      enqueueNotification(lang['already'], 'error')
      history.push('/Dashboard')
    }
  }, [token])

  async function onSubmit(data) {
    try {
      await toggleLoader(true)

      setDisableButton(true)

      // Display the values
      let account = data.account
      let pass = data.password

      const response = await login(account, pass)

      await serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        (data) => {
          setDisableButton(false)
          setTokenData(data.token)
          setOpen(true)
        },
        (error) => {
          if (error.error && error.error.code === 3000) setOpen(true)
          setDisableButton(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )

      await toggleLoader(false)
    } catch (error) {
      setDisableButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  const {
    control: controlCode,
    handleSubmit: handleSubmitCode,
    setError: setErrorCode
  } = useForm()

  async function handleCode(data) {
    try {
      if (!captcha.current.getValue()) {
        setAreHumanMessage(
          message(languageContext.dictionary.areHuman, 'required')
        )
        throw new Error(3001)
      }

      toggleLoader(true)
      setDisableButtonCode(true)

      let { code } = data
      const error = await codeAuth(
        code,
        token_data,
        setErrorCode,
        languageContext?.userLanguage
      )

      if (!error) {
        enqueueNotification(
          languageContext.dictionary['welcome'] + '!',
          'success'
        )
        if (nextRoute) history.push(nextRoute)
      } else {
        if (error === 'Wrong attempts') {
          enqueueNotification(languageContext.dictionary['try'] + '!', 'error')
          setOpen(false)
        } else
          enqueueNotification(
            languageContext.dictionary['somethingWrong'],
            'error'
          )
      }

      toggleLoader(false)
      setDisableButtonCode(false)
    } catch (error) {
      setDisableButtonCode(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  return (
    <div>
      <div className='diinco-background-image' />
      <div className='uk-container diinco-login-view-container'>
        <div data-uk-grid className='uk-grid uk-child-width-1-5'>
          <div className=' diinco-login-view-title uk-width-3-5'>
            <h1>{lang['projectWaiting']}...</h1>
          </div>
          <div className='uk-width-2-5'>
            <div className='uk-card uk-card-default uk-card-body diinco-alpha-card'>
              <img
                src={theme === 'dark' ? logo.colour : logo.black}
                alt='logo'
              />
              <h1 className='uk-card-title leo-login-title'>
                {languageContext.dictionary['login']}
              </h1>
          
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <CTextInput
                  type='text'
                  name='account'
                  label={lang['account']}
                  placeholder={languageContext.dictionary.accountPlaceholder}
                  control={control}
                  limit
                  required
                  maxLength={100}
                />
                <CTextInput
                  type='password'
                  name='password'
                  label={lang['pass']}
                  control={control}
                  required
                  minLength={8}
                  maxLength={120}
                />
                <div className='diinco-centered'>
                  <Button
                    size='small'
                    onClick={() => {
                      history.push('/ForgottenPassword')
                    }}
                    variant='text'
                    color='secondary'
                    centered
                    containerStyle={{
                      marginBottom: '22px',
                      textTransform: 'none'
                    }}
                  >
                    {lang.forgotPass}
                  </Button>

                  <Button
                    type='submit'
                    size='auto'
                    containerStyle={{
                      marginBottom: '22px'
                    }}
                    disabled={disableButton}
                  >
                    {languageContext.dictionary.login}
                  </Button>

                  <Button
                    size='small'
                    onClick={() => {
                      history.push('/signup')
                    }}
                    variant='text'
                    centered
                    containerStyle={{
                      marginBottom: '0px',
                      textTransform: 'none'
                    }}
                  >
                    {lang.register}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          open={open}
          onClose={onCloseModal}
          center
          classNames={{
            modal: 'dc-code-verification'
          }}
          closeOnOverlayClick={false}
          closeIcon={
            <IconButton
              color={'light'}
              iconColor={'primary'}
              icon='close'
              component='div'
            />
          }
        >
          <div className='uk-flex uk-flex-column uk-flex-middle uk-flex-center'>
            <h2 className='dc-code-verification-title'>{lang['verifCode']}</h2>
            <form onSubmit={handleSubmitCode(handleCode)} noValidate>
              <CTextInput
                name='code'
                control={controlCode}
                placeholder={lang.enterCode}
                variant='code'
                limit
                type='number'
                required
                maxLength={6}
                minLength={6}
              />
              <div className='dc-code-verification-placeholder'>
                {lang['codeMail']}
              </div>
              <div
                className='uk-flex uk-flex-center uk-margin-small-bottom'
                data-uk-countdown={timerStart}
              >
                <div>
                  <div className='uk-countdown-number uk-countdown-minutes dc-code-verification-placeholder'></div>
                </div>
                <div className='uk-countdown-separator dc-code-verification-placeholder'>
                  :
                </div>
                <div>
                  <div className='uk-countdown-number uk-countdown-seconds dc-code-verification-placeholder'></div>
                </div>
              </div>

              <div className='uk-flex uk-flex-column uk-flex-left'>
                <ReCAPTCHA
                  size='normal'
                  ref={captcha}
                  sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
                  theme={theme === 'dark' ? 'dark' : 'light'}
                />
                {areHumanMessage && (
                  <div className='leo-error-message' style={{ color: 'white' }}>
                    <span data-uk-icon='icon: warning; ratio: 0.6'></span>{' '}
                    {areHumanMessage}
                  </div>
                )}
              </div>

              <Button
                size='small'
                color='secondary'
                type='submit'
                buttonStyle={{ marginTop: '20px' }}
                containerStyle={{ marginBottom: '0px' }}
                disabled={disableButtonCode}
              >
                {languageContext.dictionary.confirm}
              </Button>
            </form>
          </div>
        </Modal>
      </div>
    </div>
  )
}
