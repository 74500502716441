import React, { useContext } from 'react'

import './styles/style.css'

import ErrorIcon from './assets/error.svg'

import { LanguageContext } from '../../context/language/context'

export default function Error() {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  return (
    <div className='dc-error-container uk-flex uk-flex-center'>
      <div className='uk-flex uk-flex-column uk-flex-center uk-flex-middle'>
        <img
          className='dc-error-img'
          src={ErrorIcon}
          alt='Error icon'
          style={{
            filter:
              'brightness(0) saturate(100%) invert(83%) sepia(65%) saturate(2787%) hue-rotate(321deg) brightness(104%) contrast(95%)'
          }}
        />
        <div className='dc-error-text'>{lang['oops']}</div>
      </div>
    </div>
  )
}
