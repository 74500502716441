import { format } from 'date-fns'
import React, { useContext, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Avatar from '../../../components/Avatar'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Divider from '../../../components/Divider'
import { IconButton } from '../../../components/IconButton'
import NoData from '../../../components/NoData'
import ProjectGallery from '../../../components/ProjectGallery'
import Typography from '../../../components/Typography'
import { LanguageContext } from '../../../context/language/context'
import PropTypes from 'prop-types'

const StageCard = ({
  stage,
  index,
  hasPermission,
  editPhase,
  onOpenPayment,
  general
}) => {
  const { id } = useParams()

  const [open, setOpen] = useState(false)

  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('../languages/en.json')
  } else {
    lang = require('../languages/es.json')
  }

  return (
    <>
      <Card hover>
        <ul data-uk-accordion style={{ margin: '10px' }}>
          <li>
            <a
              className='uk-accordion-title'
              href='#'
              onClick={() => setOpen(!open)}
            >
              <div className='uk-flex uk-flex-between uk-flex-middle'>
                <Typography
                  variant='subtitle'
                  color='secondary'
                  className='uk-flex uk-flex-middle'
                  style={{ margin: '0px' }}
                  disableMargin
                >
                  {stage.name}
                </Typography>
                {stage.date_start && stage.date_end && (
                  <Typography
                    variant='body'
                    className='uk-flex uk-flex-middle'
                    style={{ margin: '0px' }}
                    disableMargin
                  >
                    {format(
                      new Date(stage.date_start.split('T')[0] + ' 00:00'),
                      'dd/MM/yyyy'
                    )}{' '}
                    -{' '}
                    {format(
                      new Date(stage.date_end.split('T')[0] + ' 00:00'),
                      'dd/MM/yyyy'
                    )}
                  </Typography>
                )}
              </div>
            </a>
            <div className='uk-accordion-content uk-margin-small-top'>
              <div data-uk-grid=''>
                <div className='uk-width-expand'>
                  <Typography variant='body'>{stage.description}</Typography>
                </div>
                {!general &&
                  hasPermission('stage-update', 'or', id, 'project') && (
                    <div className='uk-width-auto'>
                      <IconButton
                        icon='edit'
                        onClick={() => editPhase(index)}
                      />
                    </div>
                  )}
              </div>

              <div
                data-uk-grid
                className='uk-child-width-1-1 uk-margin-medium-bottom'
              >
                <div>
                  <div data-uk-grid>
                    <div className='uk-width-1-5'>
                      <Typography
                        variant='subtitle'
                        color='secondary'
                        className='uk-margin-medium-top'
                        align={'center'}
                      >
                        {lang.payments}
                      </Typography>
                    </div>
                    <div className='uk-width-4-5'></div>
                  </div>
                </div>
              </div>

              {stage.Payments && stage.Payments.length > 0 ? (
                <div
                  data-uk-grid
                  className='uk-child-width-1-1 uk-margin-medium-bottom'
                >
                  <div>
                    <div data-uk-grid>
                      <div className='uk-width-1-5'>
                        <Typography
                          variant='subtitle'
                          align='center'
                          style={{
                            overflowWrap: 'normal',
                            width: '100%'
                          }}
                        >
                          {lang.sender}
                        </Typography>
                      </div>
                      <div className='uk-width-1-5'>
                        <Typography variant='subtitle' align='center'>
                          {languageContext.dictionary.date}
                        </Typography>
                      </div>
                      <div className='uk-width-2-5'>
                        <Typography variant='subtitle' align='center'>
                          {lang.concept}
                        </Typography>
                      </div>
                      <div className='uk-width-1-5'></div>
                    </div>
                  </div>
                  {stage.Payments.map((payment, pIndex) => {
                    return (
                      <div key={'payment-' + pIndex}>
                        <div data-uk-grid>
                          <div className='uk-width-1-5'>
                            <Avatar
                              size={'medium'}
                              src={payment.User?.image}
                              alt={payment.User?.name}
                              style={{
                                justifyContent: 'center',
                                margin: 'auto'
                              }}
                            />
                          </div>
                          <div className='uk-width-1-5'>
                            <Typography
                              variant='body'
                              className='uk-flex uk-flex-middle uk-flex-center uk-height-1-1'
                            >
                              {format(
                                new Date(
                                  payment.createdAt?.split('T')[0] + ' 00:00'
                                ),
                                'dd/MM/yyyy'
                              )}
                            </Typography>
                          </div>
                          <div className='uk-width-2-5'>
                            <Typography
                              variant='body'
                              className='uk-flex uk-flex-middle uk-flex-center uk-height-1-1'
                            >
                              {payment.concept}
                            </Typography>
                          </div>
                          <div className='uk-width-1-5'>
                            <Button
                              color='secondary'
                              onClick={() => onOpenPayment(index, pIndex)}
                              centered
                              style={{
                                margin: 'auto'
                              }}
                              disableMargin
                              size='auto'
                            >
                              {lang.seePayment}
                            </Button>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              ) : (
                <>
                  <NoData />
                </>
              )}

              <Divider />

              <div
                data-uk-grid
                className='uk-child-width-1-1 uk-margin-medium-bottom'
              >
                <div>
                  <div data-uk-grid>
                    <div className='uk-width-1-5'>
                      <Typography
                        variant='subtitle'
                        color='secondary'
                        className='uk-margin-medium-top'
                        align={'center'}
                      >
                        {lang.reports}
                      </Typography>
                    </div>
                    <div className='uk-width-4-5'></div>
                  </div>
                </div>
              </div>

              {stage.Reports && stage.Reports.length > 0 ? (
                <div
                  data-uk-grid
                  className='uk-child-width-1-1 uk-margin-medium-bottom'
                >
                  <div>
                    <div data-uk-grid>
                      <div className='uk-width-1-5'>
                        <Typography
                          variant='subtitle'
                          className='uk-table-shrink'
                          style={{
                            overflowWrap: 'normal',
                            width: '100%'
                          }}
                          align='center'
                        >
                          {lang.professional}
                        </Typography>
                      </div>
                      <div className='uk-width-1-5'>
                        <Typography variant='subtitle' align={'center'}>
                          {languageContext.dictionary.date}
                        </Typography>
                      </div>
                      <div className='uk-width-2-5'>
                        <Typography variant='subtitle' align='center'>
                          {lang.title}
                        </Typography>
                      </div>
                      <div className='uk-width-1-5'></div>
                    </div>
                  </div>
                  {stage.Reports.map((report, rIndex) => {
                    return (
                      <div key={'report-' + rIndex}>
                        <div data-uk-grid>
                          <div className='uk-width-1-5'>
                            <Avatar
                              size={'medium'}
                              src={report.User?.image}
                              alt={report.User?.name}
                              style={{
                                justifyContent: 'center',
                                margin: 'auto'
                              }}
                            />
                          </div>
                          <div className='uk-width-1-5'>
                            <Typography
                              variant='body'
                              className='uk-flex uk-flex-middle uk-flex-center uk-height-1-1'
                            >
                              {format(new Date(report.createdAt), 'dd/MM/yyyy')}
                            </Typography>
                          </div>
                          <div className='uk-width-2-5'>
                            <Typography
                              variant='body'
                              className='uk-flex uk-flex-middle uk-flex-center uk-height-1-1'
                            >
                              {report.title}
                            </Typography>
                          </div>
                          <div className='uk-width-1-5'>
                            <Link
                              to={'/Projects/' + id + '/Reports/' + report.id}
                              style={{
                                textDecoration: 'none'
                              }}
                            >
                              <Button
                                color='secondary'
                                containerStyle={{
                                  margin: '0px'
                                }}
                                centered
                                disableMargin
                                size='auto'
                              >
                                {lang.seeReport}
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              ) : (
                <>
                  <NoData />
                </>
              )}
            </div>
          </li>
        </ul>
      </Card>

      {open && stage.Album && (
        <ProjectGallery album={stage.Album} projectId={id} />
      )}
    </>
  )
}

StageCard.defaultProps = {
  general: false,
  onOpenPayment: () => {},
  editPhase: () => {},
  hasPermission: () => {},
  stage: {},
  index: 0
}

StageCard.propTypes = {
  stage: PropTypes.object,
  index: PropTypes.number,
  hasPermission: PropTypes.func,
  editPhase: PropTypes.func,
  onOpenPayment: PropTypes.func,
  general: PropTypes.bool
}

export default StageCard
