import React, { useContext, useEffect } from 'react'
import _Auth from '../../context/auth'
import _Utils from '../../context/utils'
import { useHistory } from 'react-router'

import { LanguageContext } from '../../context/language/context'
import { _handleErrorMessage } from '../../functions/error.functions'
import { serverValidation } from '../../functions/validation.functions'
import { useLocation } from 'react-router-dom'

function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

export default function Index() {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  let query = useQuery()
  const oldMail = query.get('oldMail')
  const newMail = query.get('newMail')
  const history = useHistory()
  const { enqueueNotification, toggleLoader } = useContext(_Utils.Context)
  const { reloadData } = useContext(_Auth.Context)
  useEffect(() => {
    async function Init() {
      toggleLoader(true)
      console.log(oldMail, newMail)
      try {
        let response = await fetch(
          process.env.REACT_APP_API_URL + 'auth/changeMail',
          {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ oldMail, newMail })
          }
        )
        serverValidation(
          response,
          null,
          languageContext.userLanguage,
          () => {
            toggleLoader(false)
            enqueueNotification(lang.mailChanged, 'success')
            reloadData(history.push('/login'))
          },
          (error) => {
            toggleLoader(false)
            enqueueNotification(
              _handleErrorMessage(languageContext?.userLanguage, error.error),
              'error'
            )
            history.push('/')
          }
        )
      } catch (error) {
        toggleLoader(false)
        enqueueNotification(
          _handleErrorMessage(languageContext?.userLanguage, error),
          'error'
        )
        history.push('/')
      }
    }
    Init()
  }, [])
  return <div></div>
}
