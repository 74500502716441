import React, { useContext } from 'react'
import Modal from 'react-responsive-modal'
import { IconButton } from '../IconButton'
import Typography from '../Typography'
import './styles/style.css'
import PlansImage from '../../assets/images/logo_white.png'
import Button from '../Button'
import { LanguageContext } from '../../context/language/context'
import PropTypes from 'prop-types'
import Divider from '../Divider'
import _Auth from '../../context/auth'
import _Utils from '../../context/utils'

import { serverValidation } from '../../functions/validation.functions'
import { _handleErrorMessage } from '../../functions/error.functions'

const ProjectPlansModal = ({ open, onClose, projectId }) => {
  const { hasRole } = useContext(_Auth.Context)
  const { enqueueNotification } = useContext(_Utils.Context)

  if (!hasRole('project-admin', 'or', projectId, 'project')) {
    return null
  }

  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  async function createCheckoutSession() {
    try {
      if (!projectId) return
      let response = await fetch(
        process.env.REACT_APP_API_URL +
          'stripe/create-checkout-session/project/' +
          projectId,
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      serverValidation(
        response,
        null,
        languageContext.userLanguage,
        (data) => {
          window.location.href = data.url
        },
        (error) => {
          enqueueNotification(
            _handleErrorMessage(languageContext.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      closeIcon={<IconButton icon='close' component='div' color='dark' />}
      styles={{
        modal: {
          padding: '0px',
          width: '100%',
          maxWidth: 1000
        }
      }}
      size={'large'}
    >
      <div data-uk-grid>
        <div className='uk-width-3-5'>
          <div className='uk-container uk-margin-top'>
            <Typography
              style={{ color: 'var(--bg-dark-color)' }}
              variant={'h4'}
              fontWeight={700}
            >
              {lang.title}
            </Typography>
            <Typography fontWeight={'bold'} variant={'h6'}>
              {lang.subtitle}
            </Typography>

            <Divider />

            <div className='uk-flex uk-flex-middle uk-margin-small-top uk-width-1-1'>
              <IconButton
                icon={'check'}
                variant='borderless'
                iconColor='primary'
                className='cj-text-component-margin'
              />
              <Typography
                fontWeight={'bold'}
                style={{
                  marginRight: '5px'
                }}
              >
                5GB{' '}
              </Typography>
              <Typography>{lang.storage}</Typography>
            </div>

            <div className='uk-flex uk-flex-middle uk-margin-small-top uk-width-1-1'>
              <IconButton
                icon={'check'}
                variant='borderless'
                iconColor='primary'
                className='cj-text-component-margin'
              />
              <Typography
                fontWeight={'bold'}
                style={{
                  marginRight: '5px'
                }}
              >
                5
              </Typography>
              <Typography>{lang.users}</Typography>
            </div>

            <div className='uk-flex uk-flex-middle uk-margin-small-top uk-width-1-1'>
              <IconButton
                icon={'check'}
                variant='borderless'
                iconColor='primary'
                className='cj-text-component-margin'
              />
              {languageContext.userLanguage === 'en' ? (
                <React.Fragment>
                  <Typography
                    fontWeight={'bold'}
                    style={{
                      marginRight: '5px'
                    }}
                  >
                    {lang.unlimited}
                  </Typography>
                  <Typography>{lang.reports}</Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Typography
                    style={{
                      marginRight: '5px'
                    }}
                  >
                    {lang.reports}
                  </Typography>
                  <Typography fontWeight={'bold'}>{lang.unlimited}</Typography>
                </React.Fragment>
              )}
            </div>

            <div className='uk-flex uk-flex-middle uk-margin-small-top uk-width-1-1'>
              <IconButton
                icon={'check'}
                variant='borderless'
                iconColor='primary'
                className='cj-text-component-margin'
              />

              <Typography>{lang.calendar}</Typography>
            </div>

            <div className='uk-flex uk-flex-middle uk-margin-small-top uk-width-1-1 uk-margin-large-bottom'>
              <IconButton
                icon={'check'}
                variant='borderless'
                iconColor='primary'
                className='cj-text-component-margin'
              />
              <Typography
                style={{
                  marginRight: '5px'
                }}
              >
                {lang.divide}
              </Typography>
              <Typography fontWeight={'bold'}>{lang.projectStages}</Typography>
            </div>

            <Button
              onClick={createCheckoutSession}
              size='auto'
              color='secondary'
              className='uk-margin-bottom'
            >
              {lang.obtain}
            </Button>
          </div>
        </div>
        <div
          //center the content

          className='uk-width-2-5 uk-flex uk-flex-center uk-flex-middle'
          style={{
            backgroundImage:
              'url(https://images.pexels.com/photos/4977411/pexels-photo-4977411.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
          <img
            alt=''
            src={PlansImage}
            crossOrigin='use-credentials'
            className='dc-fit-cover'
          />
        </div>
      </div>
    </Modal>
  )
}

ProjectPlansModal.defaultProps = {
  open: false,
  onClose: () => {}
}

ProjectPlansModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.number.isRequired
}

export default ProjectPlansModal
