import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './styles/style.css'
import { Controller } from 'react-hook-form'
import Typography from '../Typography'
export default function SwitchInput(props) {
  const {
    textActive,
    textInactive,
    name,
    value: valueFromProps,
    onChange: onChangeFromProps,
    defaultValue,
    error,
    className,
    disabled,
    id
  } = props

  // A component can be considered controlled when its value prop is
  // not undefined.
  const isControlled = typeof valueFromProps !== 'undefined'
  // When a component is not controlled, it can have a defaultValue.
  const hasDefaultValue = typeof defaultValue !== 'undefined'
  // If a defaultValue is specified, we will use it as our initial
  // state.  Otherwise, we will simply use an empty string.
  const [internalValue, setInternalValue] = useState(
    hasDefaultValue ? defaultValue : false
  )
  // Internally, we need to deal with some value. Depending on whether
  // the component is controlled or not, that value comes from its
  // props or from its internal state.
  const value = isControlled ? valueFromProps : internalValue
  const onChange = (e) => {
    // When the user types, we will call props.onChange if it exists.
    // We do this even if there is no props.value (and the component
    // is uncontrolled.)
    if (onChangeFromProps) {
      onChangeFromProps(e)
    }
    // If the component is uncontrolled, we need to update our
    // internal value here.
    if (!isControlled) {
      setInternalValue(e.target.checked)
    }
  }

  return (
    <>
      <div className='uk-flex uk-flex-wrap uk-flex-wrap-around'>
        <label className={'toggle-switch ' + className}>
          <input
            type='checkbox'
            checked={value}
            onChange={onChange}
            name={name}
            disabled={disabled}
            id={id}
          />
          <span className='switch' />
        </label>
        <Typography disableMargin style={{ marginLeft: 5 }}>
          {textInactive ? (value ? textActive : textInactive) : textActive}
        </Typography>
        <span className='cj-switch-error-span uk-width-1-1'>
          {error && (
            <>
              <span data-uk-icon='icon: warning; ratio: 0.6'></span>
              {error.message}
            </>
          )}
        </span>
      </div>
    </>
  )
}

SwitchInput.defaultProps = {
  onChange: undefined,
  value: undefined,
  defaultValue: undefined,
  disabled: false,
  textInactive: undefined,

  error_: undefined,
  className: '',
  id: undefined
}
SwitchInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.bool,
  defaultValue: PropTypes.bool,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  textActive: PropTypes.string,
  textInactive: PropTypes.string,

  error: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string
}

export const CSwitchInput = ({
  name,
  control,
  defaultValue,
  shouldUnregister,

  validate,
  setValueAs,
  disabled,
  deps,

  ...rest
}) => {
  var registerOptions = {
    validate: validate,
    setValueAs: setValueAs,
    disabled: disabled,
    deps: deps
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={registerOptions}
      shouldUnregister={shouldUnregister}
      render={({
        field: { onChange, onBlur, value, name },
        fieldState: { error }
      }) => {
        return (
          <SwitchInput
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={error}
            name={name}
            {...rest}
          />
        )
      }}
    />
  )
}

CSwitchInput.defaultProps = {
  defaultValue: undefined,
  shouldUnregister: false,

  required: false
}

CSwitchInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  shouldUnregister: PropTypes.bool,

  label: PropTypes.string,
  required: PropTypes.bool,
  classes: PropTypes.object,
  error: PropTypes.object,
  helperText: PropTypes.any,

  validate: PropTypes.object,
  setValueAs: PropTypes.any,
  disabled: PropTypes.bool,
  deps: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}
