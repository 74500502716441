import React, { useContext, useState, useEffect } from 'react'
import './styles/style.css'
import { Link, useHistory, useLocation } from 'react-router-dom'

import sun from './assets/sun.svg'
import moon from './assets/moon.svg'
import notificationIcon from './assets/notifications.svg'

import logo from './assets/logo_white.png'
import logoBlack from './assets/logo_black.png'
import _Auth from '../../context/auth'
import LoginCard from '../LoginCard'
import debounce from 'lodash.debounce'
import { Text, LanguageContext } from '../../context/language/context'
import { languageOptions } from '../../languages'
import Avatar from '../Avatar'
import SearchBar from '../SearchBar'
import Notification from '../Notification'
import NoNotification from '../NoNotification'
// import UserCard from "../UserCard";

const filterOptions = (array, inputValue) => {
  return array.filter((i) =>
    i.label.toLowerCase().includes(inputValue.toLowerCase())
  )
}

const inputValueFormatter = (value) => {
  //trim and capitalize
  return value.replace(/\w\S*/g, (w) =>
    w.replace(/^\w/, (c) => c.toUpperCase())
  )
}

const getSearch = (inputValue, callback) => {
  if (!inputValue) {
    return callback([])
  }

  try {
    fetch(
      process.env.REACT_APP_API_URL +
        'professional/edit?' +
        new URLSearchParams({
          university: inputValue.trim().toLowerCase()
        }),
      {
        method: 'GET',
        credentials: 'include',
        mode: 'cors'
      }
    ).then((response) => {
      response.json().then((data) => {
        try {
          var universities = []
          data?.Universities.forEach((university) => {
            universities.push({
              value: university.name,
              label: inputValueFormatter(university.name),
              id: university.id
            })
          })
          callback(filterOptions(universities, inputValue))
        } catch (error) {
          return callback([])
        }
      })
    })
  } catch (error) {
    return callback([])
  }
}

debounce(getSearch, 500)

export const capitalize = (string) => {
  try {
    return string
      .toLowerCase()
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
  } catch (error) {
    return string
  }
}

export default function NavBar() {
  const history = useHistory()
  const location = useLocation()
  const { token, self, logout, hasRole, socket, planPermissions } = useContext(
    _Auth.Context
  )
  const { userLanguageChange, theme, changeTheme } = useContext(LanguageContext)
  const languageContext = useContext(LanguageContext)
  let langs = Object.keys(languageOptions)
  langs = langs.filter((e) => e !== languageContext.userLanguage)
  const [notifications, setNotifications] = useState([])

  let classnew = ''
  if (location.pathname === '/') {
    classnew = 'home'
  }

  useEffect(() => {
    if (token) Init()
  }, [token])

  async function Init() {
    try {
      let data = await fetch(process.env.REACT_APP_API_URL + 'notifications/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        mode: 'cors'
      })
      data = await data.json()
      setNotifications(data)
    } catch (error) {
      ;('')
    }
  }

  useEffect(() => {
    const updateNotifications = (value) => {
      setNotifications(value)
    }
    if (socket) {
      socket.on('new-notification', updateNotifications)
    }
  }, [socket])

  return (
    <nav
      data-theme={location?.pathname === '/' ? 'dark' : null}
      className={
        'uk-navbar-container uk-margin uk-navbar-sticky custom-navbar uk-light ' +
        classnew
      }
      data-uk-navbar='mode: click'
    >
      <div className='uk-navbar-left uk-margin-left navbar-logo'>
        {theme !== 'light' ? (
          <img
            src={logo}
            alt=''
            srcSet={`${logo} 300w, ${logo} 768w, ${logo} 1280w,`}
            className='navbar-logo'
            onClick={() => {
              history.push('/')
            }}
          />
        ) : (
          <img
            src={logoBlack}
            alt=''
            srcSet={`${logoBlack} 300w, ${logoBlack} 768w, ${logoBlack} 1280w,`}
            className='navbar-logo'
            onClick={() => {
              history.push('/')
            }}
          />
        )}
      </div>

      <div className='uk-navbar-right'>
        <ul className='uk-navbar-nav uk-margin-right'>
          {token ? (
            <>
              <li key='searchbar'>
                <SearchBar />
              </li>
              {!planPermissions.length && (
                <li key='subscription'>
                  <button
                    onClick={() => {
                      history.push('/subscription')
                    }}
                  >
                    {languageContext.dictionary.subscription}
                  </button>
                </li>
              )}
              <li key='dashboard'>
                <button
                  onClick={
                    self?.type
                      ? () => history.push('/Home')
                      : () => history.push('/Dashboard')
                  }
                >
                  {languageContext.dictionary.dashboard}
                </button>
              </li>
              {hasRole('professional') && (
                <li key='profile'>
                  <button
                    onClick={() => {
                      history.push('/Profile/' + self.nickname)
                    }}
                  >
                    {languageContext.dictionary.profile}
                  </button>
                </li>
              )}
              {/* CAMBIAR TEMA */}
              <li key='theme'>
                <button
                  onClick={() => {
                    changeTheme()
                  }}
                >
                  {theme === 'light' ? (
                    <img
                      src={sun}
                      alt=''
                      style={{
                        height: 22,
                        filter:
                          location?.pathname !== '/'
                            ? 'invert(12%) sepia(37%) saturate(1060%) hue-rotate(252deg) brightness(88%) contrast(95%)'
                            : 'invert(100%)'
                      }}
                    />
                  ) : (
                    <img
                      src={moon}
                      alt=''
                      style={{
                        height: 22,
                        filter:
                          'invert(100%) sepia(17%) saturate(0%) hue-rotate(194deg) brightness(108%) contrast(96%)'
                      }}
                    />
                  )}
                </button>
              </li>

              <li key='notifications'>
                <a>
                  {
                    notifications.length > 0 ? (
                      <div className='container-orange-dot'>
                        <span className='orange-dot'></span>
                        {theme === 'light' && location?.pathname !== '/' ? (
                          <img
                            src={notificationIcon}
                            alt=''
                            style={{
                              height: 22,
                              filter: 'invert(100%)'
                            }}
                          />
                        ) : (
                          <img
                            alt=''
                            src={notificationIcon}
                            style={{ height: 22 }}
                          />
                        )}
                      </div>
                    ) : (
                      <>
                        {theme === 'light' && location?.pathname !== '/' ? (
                          <img
                            alt=''
                            src={notificationIcon}
                            style={{
                              height: 22,
                              filter: 'invert(100%)'
                            }}
                          />
                        ) : (
                          <img
                            alt=''
                            src={notificationIcon}
                            style={{ height: 22 }}
                          />
                        )}
                      </>
                    )
                    // <img src={notificationIcon} className="notifications-logo"/>
                  }
                </a>
                <div
                  data-uk-dropdown='animation: uk-animation-slide-top-small; animate-out: true; mode: click;'
                  className='dc-color-aq'
                >
                  <ul
                    className='dc-dropdown-notifications'
                    style={{
                      maxHeight: '60vh',
                      overflow: 'auto',
                      overflowX: 'hidden'
                    }}
                  >
                    {notifications.length > 0 ? (
                      notifications.map((notification, index) => (
                        <li key={index} onClick={Init}>
                          <Notification
                            id={notification.id}
                            userImage={notification.sender?.image}
                            userlink={notification.sender?.nickname}
                            user={notification.sender?.name}
                            type={notification.Notification.type}
                            date={notification.createdAt}
                            projectId={notification.Project?.id}
                            project={notification.Project?.title}
                          />
                        </li>
                      ))
                    ) : (
                      <NoNotification />
                    )}
                  </ul>
                </div>
              </li>
              <li key='lang'>
                <a>
                  {' '}
                  <Text tid={'lang'} />{' '}
                </a>
                <div
                  data-uk-dropdown='mode: click; pos: bottom-justify'
                  className='dropdownLang'
                >
                  <ul className='uk-list'>
                    {langs.map((lenguaje) => (
                      <li
                        key={lenguaje}
                        className='dropdown-option first-option uk-dropdown-close'
                      >
                        <a onClick={() => userLanguageChange(lenguaje)}>
                          {lenguaje}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>

              {/* <li key="logout">
                    <a
                        uk-icon="icon: sign-out"
                        onClick={() => {
                            logout();
                        }}
                    ></a>
                </li> */}
              <li key='img'>
                {/* <img
										src={
											self.type === 1
												? self.client.image
												: self.image
										}
									/> */}
                <button type='button' className='container-avatar-nav'>
                  <Avatar
                    src={self.type === 1 ? self.client.image : self.image}
                    alt={self.name}
                    disableToolTip
                    style={{
                      padding: '10px',
                      marginTop: '5px'
                    }}
                  />
                </button>
                <div
                  data-uk-dropdown='animation: uk-animation-slide-top-small; animate-out: true; mode: click'
                  style={{ backgroundColor: '#AEAEAE' }}
                >
                  <ul className='uk-nav uk-dropdown-nav'>
                    <li className='dropdown-option first-option uk-dropdown-close'>
                      <Link
                        onClick={() => {
                          history.push('/Profile/Edit')
                        }}
                        to='/Profile/Edit'
                        style={{
                          textTransform: 'none'
                        }}
                      >
                        {languageContext.dictionary.editProfile}
                      </Link>
                    </li>
                    <li
                      className='dropdown-option first-option uk-dropdown-close'
                      style={{
                        textTransform: 'none'
                      }}
                    >
                      <a
                        onClick={() => {
                          logout()
                        }}
                        style={{
                          textTransform: 'none'
                        }}
                      >
                        {languageContext.dictionary.logout}
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </>
          ) : (
            <>
              <li key='subscription'>
                <button
                  onClick={() => {
                    history.push('/subscription')
                  }}
                >
                  {languageContext.dictionary.subscription}
                </button>
              </li>
              {/* CAMBIAR TEMA */}
              <li key='theme'>
                <button
                  onClick={() => {
                    changeTheme()
                  }}
                >
                  {theme === 'light' ? (
                    <img
                      src={sun}
                      alt=''
                      style={{
                        height: 22,
                        filter:
                          location?.pathname !== '/'
                            ? 'invert(12%) sepia(37%) saturate(1060%) hue-rotate(252deg) brightness(88%) contrast(95%)'
                            : 'invert(100%)'
                      }}
                    />
                  ) : (
                    <img
                      src={moon}
                      alt=''
                      style={{
                        height: 22,
                        filter:
                          'invert(100%) sepia(17%) saturate(0%) hue-rotate(194deg) brightness(108%) contrast(96%)'
                      }}
                    />
                  )}
                </button>
              </li>
              {/* <li>
								<button
									onClick={() => {
										history.push("/Subscription");
									}}
								>
									SUSCRIPCIóN
								</button>
							</li> */}
              {/* <li key="aboutus">
								<button
									onClick={() => {
										history.push("/AboutUs");
									}}
								>
									<Text tid={"about"} />
								</button>
							</li> */}
              {/* CAMBIAR TEMA */}
              {/* <li key="theme2">
								<button
									onClick={() => {
										changeTheme()
									}}
								>
									{theme == 'light' ?
										<img src={sun} className="leo-icon" style={{ height: 22, filter: "invert(12%) sepia(37%) saturate(1060%) hue-rotate(252deg) brightness(88%) contrast(95%)" }} />
										:
										<img src={moon} className="leo-icon" style={{ height: 22, filter: "invert(100%) sepia(17%) saturate(0%) hue-rotate(194deg) brightness(108%) contrast(96%)" }} />
									}
								</button>
							</li> */}
              <li key='lang2'>
                <a>
                  {' '}
                  <Text tid={'lang'} />{' '}
                </a>
                <div
                  data-uk-dropdown='mode: click; pos: bottom-justify'
                  className='dropdownLang'
                >
                  <ul className='uk-list'>
                    {langs.map((lenguaje) => (
                      <li
                        key={lenguaje + '2'}
                        className='dropdown-option first-option uk-dropdown-close'
                      >
                        <a onClick={() => userLanguageChange(lenguaje)}>
                          {lenguaje}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
              {location.pathname === '/login' ||
              location.pathname === '/signup' ? (
                <li key='login'>
                  <a
                    onClick={() => {
                      history.push('/login')
                    }}
                  >
                    <Text tid={'login'} />
                  </a>
                </li>
              ) : (
                <li key='login'>
                  <a>
                    <Text tid={'login'} />
                  </a>
                  <div
                    // className="uk-navbar-dropdown logincard"

                    className='cj-dropdown-logincard'
                    data-uk-dropdown='mode: click;'
                    id='logincard'
                  >
                    <LoginCard />
                  </div>
                </li>
              )}
            </>
          )}
        </ul>
      </div>
    </nav>
  )
}
