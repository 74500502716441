import React from 'react'
import './styles/style.css'
import { ReactComponent as EditIcon } from './assets/editIcon.svg'
import { ReactComponent as CreateIcon } from './assets/createIcon.svg'
import { ReactComponent as DeleteteIcon } from './assets/deleteIcon.svg'
import { ReactComponent as CloseIcon } from './assets/closeIcon.svg'
import { ReactComponent as OptionsIcon } from './assets/optionsIcon.svg'
import { ReactComponent as QuestionIcon } from './assets/questionIcon.svg'
import { ReactComponent as LinkIcon } from './assets/linkIcon.svg'
import { ReactComponent as LockIcon } from './assets/lockIcon.svg'
import { ReactComponent as LockOpenIcon } from './assets/lockOpenIcon.svg'
import { ReactComponent as FileIcon } from './assets/file.svg'
import { ReactComponent as ShareIcon } from './assets/shareNodes.svg'
import { ReactComponent as RestoreIcon } from './assets/restoreSolid.svg'
import { ReactComponent as BoxIcon } from './assets/boxSolid.svg'
import { ReactComponent as ImageIcon } from './assets/image-solid.svg'
import { ReactComponent as ArrowRightIcon } from './assets/arrow-right-solid.svg'
import { ReactComponent as ArrowLeftIcon } from './assets/arrow-left-solid.svg'
import { ReactComponent as CheckIcon } from './assets/check-solid.svg'
import PropTypes from 'prop-types'
import Card from '../Card/index'
export class IconButton extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }

  initializeState = () => {
    return {
      spanStyles: {},
      count: 0
    }
  }
  state = this.initializeState()

  /* Debounce Code to call the Ripple removing function */
  callCleanUp = (cleanup, delay) => {
    this.bounce && clearTimeout(this.bounce)
    this.bounce = setTimeout(() => {
      cleanup()
    }, delay)
  }

  showRipple = (e) => {
    const rippleContainer = e.currentTarget
    const size = rippleContainer.offsetWidth
    const pos = rippleContainer.getBoundingClientRect()
    const x = e.clientX - pos.x - size / 2
    const y = e.clientY - pos.y - size / 2

    const spanStyles = {
      top: y + 'px',
      left: x + 'px',
      height: size + 'px',
      width: size + 'px'
    }
    const count = this.state.count + 1
    this.setState({
      spanStyles: { ...this.state.spanStyles, [count]: spanStyles },
      count: count
    })
  }

  cleanUp = () => {
    this.setState({
      spanStyles: {},
      count: 0
    })
  }

  renderRippleSpan = () => {
    const { spanStyles = {} } = this.state
    const spanArray = Object.keys(spanStyles)
    if (spanArray && spanArray.length > 0) {
      return spanArray.map((key, index) => {
        return (
          <span
            key={'spanCount_' + index}
            className=''
            style={{ ...spanStyles[key] }}
          ></span>
        )
      })
    } else {
      return null
    }
  }

  getIcon = (icon, children) => {
    switch (icon) {
      case 'edit':
        return <EditIcon />

      case 'create':
        return <CreateIcon />

      case 'delete':
        return <DeleteteIcon />

      case 'options':
        return <OptionsIcon />

      case 'close':
        return <CloseIcon />

      case 'link':
        return <LinkIcon />

      case 'question':
        return <QuestionIcon />

      case 'lock':
        return <LockIcon />

      case 'lockOpen':
        return <LockOpenIcon />

      case 'file':
        return <FileIcon />

      case 'share':
        return <ShareIcon />

      case 'restore':
        return <RestoreIcon />

      case 'box':
        return <BoxIcon />

      case 'image':
        return <ImageIcon />

      case 'arrowRight':
        return <ArrowRightIcon />

      case 'arrowLeft':
        return <ArrowLeftIcon />

      case 'check':
        return <CheckIcon />

      default:
        return children
    }
  }

  render() {
    const {
      children = null,
      className = null,
      onClick = null,
      style = {},
      title = null,
      disabled = false,
      type = 'button',
      disableRipple = true,

      variant = 'filled',
      color = 'lightgrey',
      iconColor,
      shape = 'round',
      icon = null,

      borderWidth = null,
      borderHeight = null,
      cardStyle = null,

      size = 'small',
      component = 'button',

      ...rest
    } = this.props

    const iconCard = (
      <>
        <Card
          padding={size === 'x-small' ? '2px' : size === 'mini' ? '7px' : '7px'}
          variant={variant}
          color={color}
          style={{
            borderRadius: shape === 'round' ? '50%' : '0px',
            height: borderHeight,
            width: borderWidth,
            ...cardStyle
          }}
          className='uk-flex uk-flex-middle uk-flex-center'
          icon
        >
          <div
            className={
              'uk-flex uk-flex-middle uk-flex-center cj-icon-button-icon-container cj-icon-button-icon-color-' +
              (iconColor ??
                (variant === 'outlined'
                  ? color
                  : color === 'dark'
                  ? 'light'
                  : 'dark')) +
              ' cj-icon-button-size-' +
              size +
              (' cj-icon-button-variant-' + variant)
            }
          >
            {icon ? <>{this.getIcon(icon, children)}</> : <>{children}</>}
          </div>
        </Card>
        {!disableRipple && (
          <div
            className='cj-rippleContainer'
            onMouseDown={(e) => this.showRipple(e)}
            onMouseUp={() => this.callCleanUp(this.cleanUp, 2000)}
          >
            {this.renderRippleSpan()}
          </div>
        )}
      </>
    )

    if (component === 'div')
      return (
        <div
          ref={this.myRef}
          className={
            (disableRipple
              ? 'cj-icon-button '
              : 'cj-icon-button cj-ripple cj-icon-button ') + (className ?? '')
          }
          onClick={onClick}
          style={style}
          title={title}
          disabled={disabled}
          type={type}
          {...rest}
        >
          {iconCard}
        </div>
      )

    return (
      <button
        ref={this.myRef}
        className={
          (disableRipple
            ? 'cj-icon-button '
            : 'cj-icon-button cj-ripple cj-icon-button ') + (className ?? '')
        }
        onClick={onClick}
        style={style}
        title={title}
        disabled={disabled}
        type={type}
        {...rest}
      >
        {iconCard}
      </button>
    )
  }
}

IconButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  disableRipple: PropTypes.bool,
  component: PropTypes.node,
  variant: PropTypes.oneOf(['filled', 'outlined', 'borderless']),
  color: PropTypes.oneOf(['grey', 'secondary', 'dark', 'light', 'primary']),
  iconColor: PropTypes.oneOf(['primary', 'secondary', 'dark', 'grey', 'light']),
  theme: PropTypes.oneOf(['default', 'inverted', 'light', 'dark']),
  shape: PropTypes.oneOf(['round', 'square']),
  borderWidth: PropTypes.string,
  borderHeight: PropTypes.string,
  cardStyle: PropTypes.object,
  size: PropTypes.oneOf(['mini', 'x-small', 'small', 'medium', 'large']),
  icon: PropTypes.oneOf([
    'close',
    'delete',
    'edit',
    'create',
    'options',
    'link',
    'question',
    'lock',
    'lockOpen',
    'share',
    'restore',
    'box',
    'file',
    'image',
    'arrowRight',
    'arrowLeft',
    'check'
  ]),
  children: PropTypes.node
}
