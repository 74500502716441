import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Redirect } from 'react-router-dom'

import _Auth from '../../context/auth'
import _Utils from '../../context/utils'
import { LanguageContext } from '../../context/language/context'

import { serverValidation } from '../../functions/validation.functions'
import { _handleErrorMessage } from '../../functions/error.functions'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import DiincoSubscription from '../../views/Subscription'

const ProtectedRoute = ({
  permissions,
  roles,
  roleRender,
  type = 'system',
  param = 'id',
  op = 'or',
  roleOp = 'or',
  redirectPath = '/',
  children,
  showErrorMessage = true
}) => {
  let location = useLocation()
  const params = useParams()
  const [loading, setLoading] = useState(true)
  const [valid, setValid] = useState(false)
  const { token, self, hasPermission, isLoaded, hasRole, recover } = useContext(
    _Auth.Context
  )
  const { HomeLoader, enqueueNotification } = useContext(_Utils.Context)
  const languageContext = useContext(LanguageContext)
  const history = useHistory()
  const Loader = (
    <div className='uk-container uk-position-center'>
      {' '}
      <HomeLoader />{' '}
    </div>
  )

  useEffect(() => {
    async function fetchData() {
      if (isLoaded && token && type === 'project') {
        await projectValidation()
      } else if (isLoaded && token && type === 'prospect') {
        await prospectValidation()
      }
    }

    fetchData()
  }, [isLoaded, location])

  useEffect(() => {
    //verificar el plan en el back
  }, [type])

  async function projectValidation() {
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL +
          'projects/' +
          params[param] +
          '/validate',
        {
          method: 'GET',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      await serverValidation(
        response,
        null,
        null,
        async () => {
          setValid(true)
          setLoading(false)
        },
        async (error) => {
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
          history.push(redirectPath)
          setLoading(false)
        }
      )
    } catch (error) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
      history.push(redirectPath)
      setLoading(false)
    }
  }

  async function prospectValidation() {
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL +
          'prospects/' +
          params[param] +
          '/validate',
        {
          method: 'GET',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      await serverValidation(
        response,
        null,
        null,
        async () => {
          setValid(true)
          setLoading(false)
        },
        async (error) => {
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
          history.push(redirectPath)
          setLoading(false)
        }
      )
    } catch (error) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
      history.push(redirectPath)
      setLoading(false)
    }
  }

  if (!isLoaded) return Loader

  if (!self) {
    if (showErrorMessage)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, { code: 4001 }),
        'error'
      )
    return <Redirect to={redirectPath} push={false} />
  }

  if (roleRender) {
    if (hasRole(roleRender.role)) return roleRender.component
    else return children
  }

  if (roles) {
    if (!hasRole(roles, roleOp)) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, { code: 4002 }),
        'error'
      )
      return <Redirect to={redirectPath} push={false} />
    }
  }

  if (type === 'plan') {
    recover()
    if (permissions && !hasPermission(permissions, op, null, 'plan')) {
      return <DiincoSubscription message />
    }
  } else if (type === 'system') {
    if (permissions && !hasPermission(permissions, op)) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, { code: 4002 }),
        'error'
      )
      return <Redirect to={redirectPath} push={false} />
    }
  } else if (type === 'project') {
    if (loading) return Loader

    if (!valid || !hasPermission(permissions, op, params[param], 'project')) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, { code: 4002 }),
        'error'
      )
      return <Redirect to={redirectPath} push={false} />
    }
  } else if (type === 'prospect') {
    if (loading) return Loader

    if (!valid || !hasPermission(permissions, op, params[param], 'prospect')) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, { code: 4002 }),
        'error'
      )
      return <Redirect to={redirectPath} push={false} />
    }
  } else {
    return <Redirect to={redirectPath} push={false} />
  }

  return children
}

ProtectedRoute.propTypes = {
  permissions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  type: PropTypes.oneOf(['system', 'project', 'prospect', 'plan']),
  param: PropTypes.string,
  op: PropTypes.string,
  redirectPath: PropTypes.string,
  children: PropTypes.node,
  roleRender: PropTypes.shape({
    role: PropTypes.string,
    component: PropTypes.node
  }),
  roles: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  roleOp: PropTypes.string,
  showErrorMessage: PropTypes.bool
}

export default ProtectedRoute
