import React, { useContext, useEffect, useState } from 'react'
import './styles/style.css'

//Components
import NoData from '../../components/NoData'
//import ProjectSearchResult from "../../components/ProjectSearchResult";
import ProfessionalCard from '../../components/ProfessionalCard'

//functions
import { _handleErrorMessage } from '../../functions/error.functions'
import { serverValidation } from '../../functions/validation.functions'

//hooks
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

//Context
import { LanguageContext } from '../../context/language/context'
import _Utils from '../../context/utils'
import StandardView from '../../layouts/StandardView'
import Typography from '../../components/Typography'
import { AlbumSearchResult, ProjectSearchResult } from '../../components/'
import Divider from '../../components/Divider'

export default function SearchAll() {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }
  const { setError } = useForm()

  const { enqueueNotification, toggleLoader } = useContext(_Utils.Context)

  const { search } = useParams()
  const [professionals, setProfessionals] = useState([])
  const [projects, setProjects] = useState([])
  const [albums, setAlbums] = useState([])
  const [otherProfessionals, setOtherProfessionals] = useState([])
  const [otherProjects, setOtherProjects] = useState([])
  const [otherAlbums, setOtherAlbums] = useState([])

  async function Init(search) {
    await toggleLoader(true)
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'search/' + search,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          mode: 'cors'
        }
      )
      await serverValidation(
        response,
        setError,
        languageContext?.userLanguage,
        (data) => {
          setProfessionals(data.professionals)
          setProjects(data.projects)
          setAlbums(data.albums)
          setOtherProfessionals(data.otherProfessionals)
          setOtherProjects(data.otherProjects)
          setOtherAlbums(data.otherAlbums)
        },
        (error) => {
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    await toggleLoader(false)
  }

  useEffect(() => {
    Init(search)
  }, [search])

  return (
    <StandardView
      title={search ? lang['Results for'] + ': ' + search : lang['search']}
    >
      {(!projects || !projects.length) &&
      (!albums || !albums.length) &&
      (!professionals || !professionals.length) ? (
        <NoData />
      ) : (
        <React.Fragment>
          {projects && projects.length !== 0 && (
            <React.Fragment>
              <Typography
                variant={'h4'}
                style={{ textTransform: 'uppercase' }}
                color='secondary'
              >
                {languageContext.dictionary['projects']}
              </Typography>
              <div className='uk-grid uk-grid-row-large'>
                {projects.map((project, index) => (
                  <div className='uk-width-1-3' key={index}>
                    <ProjectSearchResult
                      id={project.id}
                      name={project.title}
                      img={project.banner}
                      tags={project.Tags}
                      users={project.Users}
                      description={project.description}
                      city={project.Location?.City?.name}
                      state={project.Location?.City?.State?.name}
                      country={project.Location?.City?.State?.Country?.name}
                    />
                  </div>
                ))}
              </div>
            </React.Fragment>
          )}
          {albums && albums.length !== 0 && (
            <React.Fragment>
              <Typography
                variant={'h4'}
                style={{ textTransform: 'uppercase' }}
                color='secondary'
              >
                {languageContext.dictionary['albums']}
              </Typography>
              <div className='uk-grid uk-grid-row-large'>
                {albums.map((album, index) => (
                  <div className='uk-width-1-3' key={index}>
                    <AlbumSearchResult
                      id={album.id}
                      name={album.title}
                      img={album.cover}
                      tags={album.Tags}
                      users={
                        album.Users && album.Users.length
                          ? album.Users
                          : album.Stage &&
                            album.Stage.Project &&
                            album.Stage.Project.User_Projects &&
                            album.Stage.Project.User_Projects.length
                          ? [album.Stage.Project.User_Projects[0].User]
                          : []
                      }
                      description={album.description}
                      city={album.Location.City.name}
                      state={album.Location.City.State.name}
                      country={album.Location.City.State.Country.name}
                    />
                  </div>
                ))}
              </div>
            </React.Fragment>
          )}
          {professionals && professionals.length !== 0 && (
            <React.Fragment>
              <Typography
                variant={'h4'}
                style={{ textTransform: 'uppercase' }}
                color='secondary'
              >
                {languageContext.dictionary['professionals']}
              </Typography>
              <div className=' diinco-horizontal-overflow-container'>
                {professionals.map((professional, index) => (
                  <div className='uk-width-1-5' key={index + 'professional'}>
                    <ProfessionalCard
                      img={professional.image}
                      name={professional.name}
                      nickname={professional.nickname}
                      profession={professional.Services.map(
                        (service) => service.title
                      ).join(' / ')}
                      city={professional.Locations[0]?.City.name}
                    />
                  </div>
                ))}
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}

      <div className='uk-flex'>
        <Typography variant='h6' style={{ textTransform: 'uppercase' }}>
          {lang.OtherResults}
        </Typography>
      </div>
      <Divider className='uk-margin-medium-bottom' />
      {(!otherProjects || !otherProjects.length) &&
      (!otherAlbums || !otherAlbums.length) &&
      (!otherProfessionals || !otherProfessionals.length) ? (
        <NoData />
      ) : (
        <React.Fragment>
          {otherProjects && otherProjects.length !== 0 && (
            <React.Fragment>
              <Typography
                variant={'h4'}
                style={{ textTransform: 'uppercase' }}
                color='secondary'
              >
                {languageContext.dictionary['projects']}
              </Typography>
              <div className='uk-grid uk-grid-row-large'>
                {otherProjects.map((project, index) => (
                  <div className='uk-width-1-3' key={index}>
                    <ProjectSearchResult
                      id={project.id}
                      name={project.title}
                      img={project.banner}
                      tags={project.Tags}
                      users={project.Users}
                      description={project.description}
                      city={project.Location?.City?.name}
                      state={project.Location?.City?.State?.name}
                      country={project.Location?.City?.State?.Country?.name}
                    />
                  </div>
                ))}
              </div>
            </React.Fragment>
          )}
          {otherAlbums && otherAlbums.length !== 0 && (
            <React.Fragment>
              <Typography
                variant={'h4'}
                style={{ textTransform: 'uppercase' }}
                color='secondary'
              >
                {languageContext.dictionary['albums']}
              </Typography>
              <div className='uk-grid uk-grid-row-large'>
                {otherAlbums.map((album, index) => (
                  <div className='uk-width-1-3' key={index}>
                    <AlbumSearchResult
                      id={album.id}
                      name={album.title}
                      img={album.cover}
                      tags={album.Tags}
                      users={
                        album.Users && album.Users.length
                          ? album.Users
                          : album.Stage &&
                            album.Stage.Project &&
                            album.Stage.Project.User_Projects &&
                            album.Stage.Project.User_Projects.length
                          ? [album.Stage.Project.User_Projects[0].User]
                          : []
                      }
                      description={album.description}
                      city={album.Location.City.name}
                      state={album.Location.City.State.name}
                      country={album.Location.City.State.Country.name}
                    />
                  </div>
                ))}
              </div>
            </React.Fragment>
          )}
          {otherProfessionals && otherProfessionals.length !== 0 && (
            <React.Fragment>
              <Typography
                variant={'h4'}
                style={{ textTransform: 'uppercase' }}
                color='secondary'
              >
                {languageContext.dictionary['professionals']}
              </Typography>
              <div className=' diinco-horizontal-overflow-container'>
                {otherProfessionals.map((professional, index) => (
                  <div className='uk-width-1-5' key={index + 'professional'}>
                    <ProfessionalCard
                      img={professional.image}
                      name={professional.name}
                      nickname={professional.nickname}
                      profession={professional.Services.map(
                        (service) => service.title
                      ).join(' / ')}
                      city={professional.Locations[0]?.City.name}
                    />
                  </div>
                ))}
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </StandardView>
  )
}
