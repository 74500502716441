import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { selectStyle } from './styles/style.js'
import './styles/style.css'

import { LanguageContext } from '../../context/language/context'

export default function SelectInput(props) {
  const { name, placeholder, isDisabled } = props
  const [selectedOption, setSelectedOption] = useState(null)
  const [options, setOptions] = useState([])
  const [inputChange, setInputChange] = useState('')

  const languageContext = useContext(LanguageContext)

  useEffect(() => {
    if (props.value) setSelectedOption(props.value)
  }, [props.value])

  useEffect(() => {
    async function find() {
      let data = await fetch(
        process.env.REACT_APP_API_URL + 'search/user/' + inputChange,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      data = await data.json()
      setOptions(data)
    }
    if (inputChange) find()
  }, [inputChange])

  return (
    <Select
      {...props}
      className='uk-margin-medium-bottom'
      styles={selectStyle}
      name={name}
      defaultValue={selectedOption}
      onChange={(event) => {
        if (props.onChange) props.onChange(event)
        setSelectedOption(event)
      }}
      onInputChange={setInputChange}
      inputValue={inputChange}
      placeholder={placeholder}
      isDisabled={isDisabled}
      options={[
        ...options.map((option, index) => ({
          value: option.id,
          label: [
            option.name,
            <div
              key={'option-user-search-' + index + '-container'}
              className='diinco-user-search-option-container'
            >
              <img
                alt=''
                className='diinco-user-search-option-image'
                src={
                  option.type
                    ? option.client?.image
                    : option.professional?.image
                }
              />
              <div className='diinco-user-search-option-data'>
                <p>{option.nickname}</p>
                <span>{option.email}</span>
              </div>
            </div>
          ],
          data: option
        }))
      ]}
      noOptionsMessage={() => languageContext.dictionary.noOptions}
    />
  )
}

SelectInput.defaultProps = {
  name: '',
  value: null,
  onChange: console.log,
  placeholder: '',
  isDisabled: false,
  required: false,
  theme: 'default'
}
SelectInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  required: PropTypes.bool,
  theme: PropTypes.oneOf(['default', 'dark'])
}
