import React, { useContext, useState, useEffect } from 'react'
import StandardView from '../../layouts/StandardView'
import { LanguageContext } from '../../context/language/context'
import Divider from '../../components/Divider'
import Typography from '../../components/Typography'
import CustomGalleryVertical from '../../components/CustomGalleryVertical'
import _Auth from '../../context/auth'
import { _handleErrorMessage } from '../../functions/error.functions'
import _Utils from '../../context/utils'
import { useHistory, useParams } from 'react-router-dom'
import { serverValidation } from '../../functions/validation.functions'
import NoData from '../../components/NoData'
import ProfessionalCard from '../../components/ProfessionalCard'

import { SocialSharer } from '../../components/SocialSharer'

export const AlbumPublic = () => {
  const [images, setImages] = useState([])
  const [album, setAlbum] = useState(null)
  const { toggleLoader, enqueueNotification } = useContext(_Utils.Context)
  const { token } = useContext(_Auth.Context)
  const [professionals, setProfessionals] = useState([])
  const languageContext = useContext(LanguageContext)
  const { userLanguage, dictionary } = useContext(LanguageContext)
  const history = useHistory()
  const { id } = useParams()
  var lang
  if (userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  useEffect(() => {
    if (album) {
      var items = []
      if (album.cover) {
        let item = {
          url: process.env.REACT_APP_API_URL + album.cover
        }
        items.push(item)
      }
      if (album.Images) {
        album.Images.forEach((image) => {
          let item = {
            url: process.env.REACT_APP_API_URL + image.url
          }
          items.push(item)
        })
      }
      setImages(items)
    }
  }, [album])

  useEffect(() => {
    if (album) {
      let data = []
      if (album.User_Albums.length > 0) {
        album.User_Albums.forEach((user_p) => {
          data.push(user_p.User)
        })
        setProfessionals(data)
      } else if (
        album.Stage &&
        album.Stage.Project &&
        album.Stage.Project.User_Projects
      ) {
        album.Stage.Project.User_Projects.forEach((relation) => {
          data.push(relation.User)
        })
        setProfessionals(data)
      }
    }
  }, [album])

  async function init() {
    try {
      toggleLoader(true)

      if (token) {
        let response = await fetch(
          process.env.REACT_APP_API_URL + 'albums/getpublicAuth/' + id,
          {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        await serverValidation(
          response,
          null,
          languageContext.userLanguage,
          (data) => {
            toggleLoader(false)
            if (data.Location && !data.Location.int_number) {
              data.Location.int_number = ''
            }
            setAlbum(data)
          },
          async (error) => {
            toggleLoader(false)
            enqueueNotification(
              _handleErrorMessage(languageContext?.userLanguage, error.error),
              'error'
            )
            history.push('/dashboard')
          }
        )
      } else {
        let response = await fetch(
          process.env.REACT_APP_API_URL + 'albums/getpublic/' + id,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )

        await serverValidation(
          response,
          null,
          languageContext.userLanguage,
          (data) => {
            toggleLoader(false)
            if (data.Location && !data.Location.int_number) {
              data.Location.int_number = ''
            }
            setAlbum(data)
          },
          async (error) => {
            toggleLoader(false)
            enqueueNotification(
              _handleErrorMessage(languageContext?.userLanguage, error.error),
              'error'
            )
            history.push('/dashboard')
          }
        )
      }
    } catch (error) {
      toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
      history.push('/dashboard')
    }
    toggleLoader(false)
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <StandardView title={dictionary.album}>
      <div
        className='uk-width-1 uk-flex uk-flex-right'
        style={{ position: 'relative', top: 25, zIndex: 55 }}
      >
        <SocialSharer
          route={'Albums/'}
          id={id}
          url={id && process.env.REACT_APP_URL + 'Albums/' + id}
          title={album && album.title}
        />
      </div>
      <CustomGalleryVertical images={images} />
      <Typography
        variant={'h6'}
        color='secondary'
        style={{ marginTop: 20, marginBottom: 0 }}
      >
        {album && album.title}
      </Typography>
      <Typography style={{ marginTop: 0 }} variant='body2'>
        {album &&
          album.Services &&
          album.Services.map((service, i) => {
            return (i !== 0 ? '/ ' : '') + lang[service.id]
          })}
      </Typography>
      {album && album.description && (
        <>
          <Divider
            color={'light'}
            style={{ marginTop: 10, marginBottom: 10 }}
          />
          <Typography variant={'body2'}>{album.description}</Typography>
        </>
      )}

      {album && album.Tags && album.Tags.length > 0 && (
        <>
          <Divider
            color={'light'}
            style={{ marginTop: 10, marginBottom: 10 }}
          />
          <Typography variant={'body2'}>
            {album.Tags.map((tag) => {
              return '#' + tag.name + '  '
            })}
          </Typography>
        </>
      )}
      <span className='dc-face-project-title'>
        <Typography style={{ marginTop: '60px' }} color={'secondary'}>
          {languageContext.dictionary['professionals']}
        </Typography>
      </span>
      <div className=' diinco-horizontal-overflow-container'>
        {professionals.length > 0 ? (
          professionals.map((professional, index) => (
            <div className='uk-width-1-5' key={index + 'professional'}>
              <ProfessionalCard
                img={professional.image}
                name={professional.name}
                nickname={professional.nickname}
                profession={
                  professional.Services &&
                  professional.Services.map((service) => service.title).join(
                    ' / '
                  )
                }
                city={professional.Locations[0]?.City?.name}
              />
            </div>
          ))
        ) : (
          <NoData />
        )}
      </div>
    </StandardView>
  )
}
