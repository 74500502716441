import React, { useContext, useEffect, useRef, useState } from 'react'
import './styles/style.css'
import { CTextInput } from '../../components/TextInput'
import _Utils from '../../context/utils'
import _Auth from '../../context/auth'
import { useHistory, useParams } from 'react-router'
import ReCAPTCHA from 'react-google-recaptcha'

import { LanguageContext } from '../../context/language/context'
import { useForm } from 'react-hook-form'
import {
  serverValidation,
  validationRules
} from '../../functions/validation.functions'
import { Prompt } from 'react-router-dom'
import { CCheckBox } from '../../components/CheckBox'
import Button from '../../components/Button'
import { CSelectInput } from '../../components/SelectInput'
import _Services from '../../context/services'
import { _handleErrorMessage } from '../../functions/error.functions'
import CNumberFormat from '../../components/CNumberFormat'

import PropTypes from 'prop-types'

export default function NewProspectForm() {
  const dataToken = useParams().token

  const languageContext = useContext(LanguageContext)
  const { theme } = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const { toggleLoader, logo, enqueueNotification } = useContext(_Utils.Context)
  const { register: registerUser } = useContext(_Auth.Context)
  const history = useHistory()
  const type = useRef(null)
  const captcha = useRef(null)
  const [disableButton, setDisableButton] = useState(false)
  const { reset, getValues, control, setError, handleSubmit, formState } =
    useForm({
      mode: 'onChange',
      defaultValues: {
        name: '',
        nickname: '',
        email: '',
        country: null,
        phone: '',
        birth_date: '',
        password: '',
        repeat_password: '',
        terms: false
      }
    })

  async function onSubmit(data) {
    if (!captcha.current.getValue()) {
      enqueueNotification(languageContext.dictionary.areHuman, 'error')
      return
    }

    let pass = data.password
    let passr = data.repeat_password
    if (pass !== passr) {
      enqueueNotification(
        languageContext.dictionary.passwordsDifferent,
        'error'
      )
      return
    }

    setDisableButton(true)

    await toggleLoader(true)

    try {
      const response = await registerUser({
        name: data.name,
        password: data.password,
        email: data.email,
        phone: data.phone,
        nickname: data.nickname,
        country: data.country,
        birth_date: data.birth_date,
        type: true,
        prospectId: dataToken
      })

      await serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        () => {
          reset(data)
          setDisableButton(false)
          enqueueNotification(lang['success'], 'success')
          history.push('/login')
        },
        (error) => {
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
          setDisableButton(false)
        }
      )
    } catch (error) {
      setDisableButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    toggleLoader(false)
    setDisableButton(false)
  }

  useEffect(() => {
    if (formState.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
    } else {
      window.onbeforeunload = null
    }
  }, [formState.isDirty])

  const { countries } = useContext(_Services.Context)

  const CountryValue = (props) => {
    const { innerProps, innerRef } = props
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className='uk-flex uk-flex-between dc-country-value'
        style={{
          color: languageContext.theme === 'light' ? '#262626' : '#ffffff',
          gridArea: '1/1/2/3',
          marginLeft: '2px',
          marginRight: '2px',
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          boxSizing: 'border-box'
        }}
        data-uk-tooltip={
          'title: ' + props.data.name + ': ' + props.data.phone_ext
        }
      >
        {props.data.flag && (
          <img className='dc-country-value-img' alt='' src={props.data.flag} />
        )}
        {!props.data.flag && props.data.name && (
          <div>{props.data.name + ': '}</div>
        )}
        <div>{props.data.phone_ext}</div>
      </div>
    )
  }

  CountryValue.defaultProps = {
    data: {
      flag: '',
      name: '',
      phone_ext: ''
    },
    innerProps: {},
    innerRef: {}
  }

  CountryValue.propTypes = {
    innerProps: PropTypes.object,
    innerRef: PropTypes.object,
    data: PropTypes.object
  }

  const CountryOption = (props) => {
    const { innerProps, innerRef } = props
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className='uk-flex uk-flex-between dc-country-option'
      >
        {props.data.flag && (
          <img className='dc-country-option-img' alt='' src={props.data.flag} />
        )}
        {!props.data.flag && props.data.name && (
          <div>{props.data.name + ': '}</div>
        )}
        <div>{props.data.phone_ext}</div>
      </div>
    )
  }

  CountryOption.defaultProps = {
    data: {
      flag: '',
      name: '',
      phone_ext: ''
    },
    innerProps: {},
    innerRef: {}
  }

  CountryOption.propTypes = {
    innerProps: PropTypes.object,
    innerRef: PropTypes.any,
    data: PropTypes.object
  }

  return (
    <div className='register_container'>
      <div className='card register-card'>
        <img src={logo.colour} className='register_diinco' alt='' />
        <h1 className='register_diinco'>{lang['registerNow']}</h1>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <ul
            className='uk-tab uk-child-width-expand'
            data-uk-tab="{connect:'#my-id'}"
          >
            <li className='uk-active'>
              <a ref={type} aria-expanded={true} className='leo-tab'>
                {lang['asClient']}
              </a>
            </li>
          </ul>

          <Prompt
            when={formState.isDirty}
            message={JSON.stringify({
              title: languageContext.dictionary.discard,
              message: languageContext.dictionary.areYouSure,
              accept: languageContext.dictionary.accept,
              cancel: languageContext.dictionary.cancel
            })}
          />

          <CTextInput
            name='name'
            label={languageContext.dictionary.name}
            control={control}
            required
            maxLength={100}
          />

          <CTextInput
            name='nickname'
            label='Nickname'
            control={control}
            required
            validate={{
              alphaDash: (v) =>
                validationRules.alphaDash(v, languageContext.userLanguage),
              noSpaces: (v) =>
                validationRules.noSpaces(v, languageContext.userLanguage)
            }}
            maxLength={20}
          />

          <CTextInput
            name='email'
            label='email'
            control={control}
            required
            type='email'
            maxLength={100}
          />

          <div data-uk-grid className='uk-grid uk-child-width-1-3'>
            <div>
              <CSelectInput
                name='country'
                control={control}
                label={languageContext.dictionary['country']}
                options={countries.map((country) => {
                  return {
                    value: country.phone_ext,
                    name: country.name,
                    flag: country.flag,
                    phone_ext: country.phone_ext
                  }
                })}
                components={{
                  Option: CountryOption,
                  SingleValue: CountryValue
                }}
                required
              />
            </div>
            <div className='uk-width-2-3'>
              <CNumberFormat
                name='phone'
                control={control}
                label={languageContext.dictionary.phoneNumber}
                required
                maxLength={10}
                minLength={10}
                format='(###) ###-####'
                mask='_'
              />
            </div>
          </div>

          <CTextInput
            type='date'
            name='birth_date'
            label={languageContext.dictionary.birthDate}
            control={control}
            required
          />

          <CTextInput
            name='password'
            type='password'
            label={languageContext.dictionary.newPassword}
            helperText={lang['alpha']}
            control={control}
            required
            validate={{
              noSpaces: (v) =>
                validationRules.noSpaces(v, languageContext.userLanguage),
              hasUppercase: (v) =>
                validationRules.hasUppercase(v, languageContext.userLanguage),
              hasLowercase: (v) =>
                validationRules.hasLowercase(v, LanguageContext.userLanguage),
              hasNumber: (v) =>
                validationRules.hasNumber(v, languageContext.userLanguage)
            }}
            maxLength={30}
            minLength={8}
          />

          <CTextInput
            name='repeat_password'
            type='password'
            label={languageContext.dictionary.confirmPassword}
            control={control}
            required
            validate={{
              same: (v) =>
                validationRules.same(
                  v,
                  languageContext.userLanguage,
                  getValues,
                  'password',
                  'passwordMatch'
                )
            }}
          />

          <div className='recaptcha-container'>
            <ReCAPTCHA
              ref={captcha}
              sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
              theme={theme === 'dark' ? 'dark' : 'light'}
            />
          </div>

          <Button size='small' type='submit' disabled={disableButton} centered>
            {languageContext.dictionary.register}
          </Button>

          <CCheckBox
            name='terms'
            control={control}
            required
            containerStyle={{ marginBottom: '200px' }}
            label={
              <>
                {lang['acceptThe']}
                <a
                  onClick={() => {
                    window.open('/TermsAndConditions')
                  }}
                  style={{ textTransform: 'none', fontWeight: 'bold' }}
                >
                  {lang['termsAndConditions']}
                </a>
              </>
            }
          />
        </form>
      </div>
    </div>
  )
}
