import React, { useContext, useEffect, useState } from 'react'
import _Auth from '../../context/auth'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default function ShowStats() {
  const [stats, setStats] = useState([])

  const { hasPermission } = useContext(_Auth.Context)

  useEffect(() => {
    const fetchPrueba = async () => {
      console.log('fetch')
      let getStats = await fetch(process.env.REACT_APP_API_URL + 'stats', {
        credentials: 'include',
        mode: 'cors'
      }).then((r) => r.json())

      console.log(getStats)
      setStats(getStats)
    }
    fetchPrueba()
  }, [])

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: 'Numero de usuarios por mes'
      }
    }
  }

  const [data, setData] = useState(null)
  useEffect(() => {
    if (stats[6]) {
      let data = {
        labels: stats[6].map((item) => item.month + '/' + item.year),
        datasets: [
          {
            label: 'Profesionales',
            data: stats[6].map((item) => item.professionalNumber),
            backgroundColor: 'rgb(255, 99, 132)'
          },
          {
            label: 'Clientes',
            data: stats[6].map((item) => item.clientNumber),
            backgroundColor: 'rgb(54, 162, 235)'
          }
        ]
      }

      console.log(data)
      setData(data)
    }
  }, [stats])

  return (
    <div className='uk-container'>
      {' '}
      {hasPermission('event-create') ? (
        <div>
          <div>
            <div>Numero de cuentas activas: {stats[0]}</div>
            <div>Numero de profesionales: {stats[1]}</div>
            <div>Numero de clientes: {stats[2]}</div>
            <div>Numero de proyectos activos: {stats[3]}</div>
          </div>

          {stats[4] ? (
            <div style={{ marginTop: 20 }}>
              <table className='uk-table uk-table-divider'>
                <thead>
                  <tr>
                    <th>Ciudad</th>
                    <th>Cantidad de profesionales</th>
                    <th>Cantidad de clientes</th>
                    <th>Total de cuentas</th>
                    <th>Cantidad de proyectos activos</th>
                    <th>Total de proyectos</th>
                  </tr>
                </thead>
                <tbody>
                  {stats[4] &&
                    stats[4].map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.professionalNumber}</td>
                          <td>{item.clientNumber}</td>
                          <td>{item.usersNumber}</td>
                          <td>{item.activeProjectsNumber}</td>
                          <td>{item.totalProjectsNumber}</td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          ) : null}

          {stats[5] ? (
            <div style={{ marginTop: 20 }}>
              <table className='uk-table uk-table-divider'>
                <thead>
                  <tr>
                    <th>Estado</th>
                    <th>Cantidad de profesionales</th>
                    <th>Cantidad de clientes</th>
                    <th>Total de cuentas</th>
                    <th>Cantidad de proyectos activos</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {stats[5] &&
                    stats[5].map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.professionalNumber}</td>
                          <td>{item.clientNumber}</td>
                          <td>{item.usersNumber}</td>
                          <td>{item.activeProjectsNumber}</td>
                          <td>{item.totalProjectsNumber}</td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          ) : null}

          {stats[6] && data ? (
            <div style={{ marginTop: 20 }}>
              <Bar data={data} options={options} />
            </div>
          ) : null}
        </div>
      ) : (
        <div>No tienes permiso para realizar esta accion </div>
      )}
    </div>
  )
}
