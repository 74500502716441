import React, { useContext, useState, useEffect, useRef } from 'react'
import './styles/style.css'

import _Auth from '../../context/auth'
import _Utils from '../../context/utils'
import _Services from '../../context/services'

import StandardView from '../../layouts/StandardView'
import Typography from '../../components/Typography'
import Card from '../../components/Card'
import Button from '../../components/Button'
import { useForm } from 'react-hook-form'
import {
  serialize,
  serverValidation,
  validationRules
} from '../../functions/validation.functions'
import { CTextInput } from '../../components/TextInput'
import { CSelectInput } from '../../components/SelectInput'
import { CSwitchInput } from '../../components/SwitchInput'
import CNumberFormat from '../../components/CNumberFormat'

import { LanguageContext } from '../../context/language/context'
import { useHistory, Prompt } from 'react-router-dom'
import { _handleErrorMessage } from '../../functions/error.functions'

export default function ProfileForm() {
  const { token, reloadData, isLoaded, features } = useContext(_Auth.Context)
  const [user, setUser] = useState(null)
  const { toggleLoader, enqueueNotification, notify } = useContext(
    _Utils.Context
  )
  const history = useHistory()
  const [loadedData, setLoadedData] = useState({
    countries: false,
    states: false,
    cities: false,
    rest: false
  })
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const [disabledState, setDisabledState] = useState(true)
  const [disabledCity, setDisabledCity] = useState(true)

  const [disabledNewPassword, setDiabledNewPassword] = useState(true)
  const [disabledButtonProfileInfo, setDisabledButtonProfileInfo] =
    useState(true)
  const [disabledButtonContactInfo, setDisabledButtonContactInfo] =
    useState(false)
  const [disabledButtonAccountInfo, setDisabledButtonAccountInfo] =
    useState(true)
  const [disabledButtonPreferencesInfo, setDisabledButtonPreferencesInfo] =
    useState(true)

  const {
    countries,
    states,
    cities,
    checkUpdates,
    selectedCountry,
    selectedState,
    setSelectedCountry,
    setSelectedState,
    updateStates,
    updateCities
  } = useContext(_Services.Context)

  // useEffect(() => {
  // 	async function Init() {
  // 		// await toggleLoader(true);
  // 		await checkUpdates();
  // 		// await toggleLoader(false);
  // 	}
  // 	Init();
  // }, []);

  useEffect(() => {
    async function Init() {
      await toggleLoader(true)
      await checkUpdates()
      setLoadedData({ ...loadedData, countries: true })
    }
    Init()
  }, [])

  const [contador, setContador] = useState(0)
  const [storageUsage, setStorageUsage] = useState(0)

  useEffect(() => {
    async function Init() {
      if (
        isLoaded &&
        contador < 3 &&
        token &&
        !loadedData.rest &&
        loadedData.countries
      )
        try {
          setContador(contador + 1)
          await toggleLoader(true)
          let data = await fetch(
            process.env.REACT_APP_API_URL + 'auth/getone',
            {
              method: 'GET',
              credentials: 'include',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )
          data = await data.json()
          if (data.message !== 'not-found') {
            setUser(data)

            setStorageUsage(data.storage)

            //location
            const location = data.Locations.length > 0 ? data.Locations[0] : {}

            if (location.City && (!loadedData.States || !loadedData.Cities)) {
              if (
                !selectedCountry ||
                selectedCountry.id !== location.City.State.Country.id
              ) {
                setSelectedCountry(location.City.State.Country)
                setLoadedData({
                  ...loadedData,
                  countries: true,
                  states: false,
                  cities: false
                })
              } else if (
                !selectedState ||
                selectedState.id !== location.City.State.id
              ) {
                await updateStates(location.City.State.Country.id)
                setSelectedState(location.City.State)
                setLoadedData({
                  ...loadedData,
                  states: true,
                  cities: false
                })
              } else {
                await updateCities(location.City.State.id)
                setLoadedData({
                  ...loadedData,
                  countries: true,
                  states: true,
                  cities: true
                })
              }
            }

            setDisabledState(!location.City)
            setDisabledCity(!location.City)

            resetContactInfo({
              contactPhone: data.contact_phone,
              contactMail: data.contact_email
            })
            resetAccountInfo({
              nickname: data.nickname,
              accountPhone: data.phone,
              accountMail: data.email
            })
            resetProfileInfo({
              name: data.name,
              country: location.City
                ? countriesOptions.find(
                  (c) => c.value.id === location.City.State.Country.id
                )
                : null,
              state: location.City
                ? statesOptions.find(
                  (c) => c.value.id === location.City.State.id
                )
                : null,
              city: location.City
                ? citiesOptions.find((c) => c.value.id === location.City.id)
                : null
            })
            resetPreferencesInfo({
              newsLetter: data.news ? true : false
            })
            setLoadedData({
              ...loadedData,
              rest: true
            })
          } else {
            setUser(null)
          }

          await toggleLoader(false)
        } catch (error) {
          await toggleLoader(false)
        }
    }
    Init()
  }, [isLoaded, loadedData, token])

  const countriesOptions = countries.map((country) => ({
    value: country,
    label: country.name
  }))

  const statesOptions = states.map((state) => ({
    value: state,
    label: state.name
  }))

  const citiesOptions = cities.map((city) => ({
    value: city,
    label: city.name
  }))

  const {
    control: controlProfileInfo,
    setError: setErrorProfileInfo,
    reset: resetProfileInfo,
    handleSubmit: handleSubmitProfileInfo,
    setValue: setValueProfileInfo,
    getValues: getValuesProfileInfo,
    formState: formStateProfileInfo
  } = useForm({
    // mode: 'onChange',
    defaultValues: {
      name: '',
      country: null,
      state: undefined,
      city: undefined,
      photo: null
    }
  })

  useEffect(() => {
    if (formStateProfileInfo.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
      setDisabledButtonProfileInfo(false)
    } else {
      window.onbeforeunload = null
      setDisabledButtonProfileInfo(true)
    }
  }, [formStateProfileInfo.isDirty])

  //  contact information form
  const {
    control: controlContactInfo,
    reset: resetContactInfo,
    setError: setErrorContactInfo,
    handleSubmit: handleSubmitContactInfo,
    formState: formStateContactInfo
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      contactPhone: '',
      contactMail: ''
    }
  })

  useEffect(() => {
    if (formStateContactInfo.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
      setDisabledButtonContactInfo(false)
    } else {
      window.onbeforeunload = null
      setDisabledButtonContactInfo(true)
    }
  }, [formStateContactInfo.isDirty])

  //  account information form
  const {
    setError: setErrorAccountInfo,
    control: controlAccountInfo,
    reset: resetAccountInfo,
    handleSubmit: handleSubmitAccountInfo,
    setValue: setValueAccountInfo,
    getValues: getValuesAccountInfo,
    formState: formStateAccountInfo
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      nickname: '',
      accountPhone: '',
      accountMail: '',
      newPass: '',
      newPassConfirm: '',
      oldPass: undefined
    }
  })

  useEffect(() => {
    if (formStateAccountInfo.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
      setDisabledButtonAccountInfo(false)
    } else {
      window.onbeforeunload = null
      setDisabledButtonAccountInfo(true)
    }
  }, [formStateAccountInfo.isDirty])

  //  preferences form
  const {
    setError: setErrorPreferences,
    control: controlPreferences,
    reset: resetPreferencesInfo,
    handleSubmit: handleSubmitPreferences,
    formState: formStatePreferences
  } = useForm({
    // mode: 'onChange',
    defaultValues: {
      mailNotification: true,
      newsLetter: true
    }
  })

  useEffect(() => {
    if (formStatePreferences.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
      setDisabledButtonPreferencesInfo(false)
    } else {
      window.onbeforeunload = null
      setDisabledButtonPreferencesInfo(true)
    }
  }, [formStatePreferences.isDirty])

  async function onSubmitProfileInfo(data) {
    await toggleLoader(true)
    setDisabledButtonProfileInfo(true)

    try {
      const formData = serialize(data, {
        dotsForObjectNotation: true,
        noArrayNotation: true,
        noFilesWithArrayNotation: true
      })

      let response = await fetch(
        process.env.REACT_APP_API_URL + 'clients/updateProfileInfo',
        {
          method: 'PUT',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data'
          },
          body: formData
        }
      )

      await serverValidation(
        response,
        setErrorProfileInfo,
        languageContext.userLanguage,
        () => {
          resetProfileInfo(data)
          setDisabledButtonProfileInfo(false)
          notify(
            languageContext.dictionary['success'],
            setTimeout(function () {
              reloadData(() => {
                history.push('/Profile/Edit')
              })
            }, 1000)
          )
        },
        async (e) => {
          setDisabledButtonProfileInfo(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, e.error),
            'error'
          )
        }
      )
    } catch (error) {
      setDisabledButtonProfileInfo(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    await toggleLoader(false)
  }

  function onCancelProfileInfo() {
    resetProfileInfo()
  }

  async function onSubmitContactInfo(data) {
    await toggleLoader(true)
    setDisabledButtonContactInfo(true)

    try {
      const formData = serialize(data, {
        dotsForObjectNotation: true,
        noArrayNotation: true,
        noFilesWithArrayNotation: true
      })

      let response = await fetch(
        process.env.REACT_APP_API_URL + 'clients/updateContactInfo',
        {
          method: 'PUT',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data'
          },
          body: formData
        }
      )

      await serverValidation(
        response,
        setErrorContactInfo,
        languageContext.userLanguage,
        () => {
          resetContactInfo(data)
          setDisabledButtonContactInfo(false)
          notify(
            languageContext.dictionary['success'],
            setTimeout(function () {
              reloadData(() => {
                history.push('/Profile/Edit')
              })
            }, 1000)
          )
        },
        async (e) => {
          setDisabledButtonContactInfo(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, e.error),
            'error'
          )
        }
      )
    } catch (error) {
      setDisabledButtonContactInfo(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    await toggleLoader(false)
  }

  function onCancelContactInfo() {
    resetContactInfo()
  }

  async function onSubmitAccountInfo(data) {
    await toggleLoader(true)
    setDisabledButtonAccountInfo(true)

    try {
      const formData = serialize(data, {
        dotsForObjectNotation: true,
        noArrayNotation: true,
        noFilesWithArrayNotation: true
      })

      let response = await fetch(
        process.env.REACT_APP_API_URL + 'clients/updateAccountInfo',
        {
          method: 'PUT',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data'
          },
          body: formData
        }
      )

      await serverValidation(
        response,
        setErrorAccountInfo,
        languageContext.userLanguage,
        () => {
          resetAccountInfo(data)
          setDisabledButtonAccountInfo(false)
          notify(
            languageContext.dictionary['success'],
            setTimeout(function () {
              reloadData(() => {
                history.push('/Profile/Edit')
              })
            }, 1000)
          )
        },
        async (e) => {
          setDisabledButtonAccountInfo(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, e.error),
            'error'
          )
        }
      )
    } catch (error) {
      setDisabledButtonAccountInfo(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    await toggleLoader(false)
  }

  function onCancelAccountInfo() {
    resetAccountInfo()
  }

  async function onSubmitPreferences(data) {
    await toggleLoader(true)
    setDisabledButtonPreferencesInfo(true)

    try {
      const formData = serialize(data, {
        dotsForObjectNotation: true,
        noArrayNotation: true,
        noFilesWithArrayNotation: true
      })

      let response = await fetch(
        process.env.REACT_APP_API_URL + 'clients/updatePreferencesInfo',
        {
          method: 'PUT',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data'
          },
          body: formData
        }
      )

      await serverValidation(
        response,
        setErrorPreferences,
        languageContext.userLanguage,
        () => {
          resetPreferencesInfo(data)
          setDisabledButtonPreferencesInfo(false)
          notify(
            languageContext.dictionary['success'],
            setTimeout(function () {
              reloadData(() => {
                history.push('/Profile/Edit')
              })
            }, 1000)
          )
        },
        async (e) => {
          setDisabledButtonPreferencesInfo(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, e.error),
            'error'
          )
        }
      )
    } catch (error) {
      setDisabledButtonPreferencesInfo(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    await toggleLoader(false)
  }

  function onCancelPreferences() {
    resetPreferencesInfo()
  }

  // DELETE CLIENT ACCOUNT HANDLER

  // 1st ask if sure
  function openDeleteConfirm() {
    console.log('Deleting')
  }
  // 2nd delete profile fetch api
  function deleteProfile() {

  }

  function initials(name) {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')

    let initials = name ? [...name.matchAll(rgx)] || [] : []

    initials = (
      (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase()

    return name ? initials : 'U'
  }

  const inputFile = useRef(null)
  const [image, setImage] = useState(null)

  const changeHandler = (event) => {
    setValueProfileInfo('photo', event.target.files[0])
    setImage(URL.createObjectURL(event.target.files[0]))
    setDisabledButtonProfileInfo(false)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onError = () => {
    enqueueNotification(
      _handleErrorMessage(languageContext?.userLanguage, { code: 1000 }),
      'error'
    )
  }

  return (
    <StandardView
      links={[
        {
          link: '/Dashboard',
          title: languageContext.dictionary.dashboard
        }
      ]}
      title={lang.title}
    >
      <ul data-uk-accordion='multiple: true'>
        <li className='uk-open'>
          <a className='uk-accordion-title' href='#'>
            <Card
              color='secondary'
              variant='filled'
              padding='15px'
              className='fofo-accordion-title'
            >
              {lang.profileInformation}
            </Card>
          </a>
          <div className='uk-accordion-content'>
            <form
              onSubmit={handleSubmitProfileInfo(onSubmitProfileInfo, onError)}
              key={1}
              noValidate
            >
              <Prompt
                when={formStateProfileInfo.isDirty}
                message={JSON.stringify({
                  title: languageContext.dictionary.discard,
                  message: languageContext.dictionary.areYouSure,
                  accept: languageContext.dictionary.accept,
                  cancel: languageContext.dictionary.cancel
                })}
              />
              <CTextInput
                label={lang.name}
                name='name'
                control={controlProfileInfo}
                maxLength={100}
                required
              />
              <div className='uk-grid'>
                <div className='uk-width-1-3'>
                  <CSelectInput
                    label={languageContext.dictionary.country}
                    name='country'
                    control={controlProfileInfo}
                    options={countriesOptions}
                    onChange={(selectedOption) => {
                      setDisabledState(!selectedOption)
                      setDisabledCity(true)
                      setSelectedCountry(selectedOption?.value)
                      setValueProfileInfo('state', null)
                      setValueProfileInfo('city', null)
                    }}
                  />
                </div>
                <div className='uk-width-1-3'>
                  <CSelectInput
                    label={languageContext.dictionary.state}
                    name='state'
                    control={controlProfileInfo}
                    options={statesOptions}
                    disabled={disabledState}
                    onChange={(selectedOption) => {
                      setDisabledCity(!selectedOption)
                      setSelectedState(selectedOption?.value)
                      setValueProfileInfo('city', null)
                    }}
                    required
                  />
                </div>
                <div className='uk-width-1-3'>
                  <CSelectInput
                    name='city'
                    control={controlProfileInfo}
                    label={languageContext.dictionary.city}
                    disabled={disabledCity}
                    required
                    options={citiesOptions}
                  />
                </div>
                <div className='uk-width-1-1 input-photo-leo'>
                  <div className=''>
                    <div className='photo-client'>
                      <input
                        type={'file'}
                        accept='image/*'
                        ref={inputFile}
                        style={{ display: 'none' }}
                        onChange={changeHandler}
                      ></input>
                      <div className='initials'>
                        {getValuesProfileInfo('photo') ? (
                          <img src={image} alt='U' />
                        ) : user && user.image ? (
                          <img
                            src={process.env.REACT_APP_API_URL + user.image}
                            alt='U'
                          />
                        ) : (
                          user && initials(user.name)
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className=''
                    style={{
                      textDecoration: 'underline',
                      color: '#fbb857',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      inputFile.current.click()
                    }}
                  >
                    Subir nueva foto de perfil
                  </div>
                </div>
              </div>
              <div className='uk-container uk-container-xsmall uk-flex uk-flex-around'>
                <Button
                  color={'grey'}
                  containerStyle={{ width: 'fit-content' }}
                  disabled={disabledButtonProfileInfo}
                  onClick={onCancelProfileInfo}
                  type='reset'
                >
                  {languageContext.dictionary.cancel}
                </Button>
                <Button
                  color='secondary'
                  containerStyle={{ width: 'fit-content' }}
                  type='submit'
                  disabled={disabledButtonProfileInfo}
                >
                  {languageContext.dictionary.save}
                </Button>
              </div>
            </form>
          </div>
        </li>
        <li className='uk-open'>
          <a className='uk-accordion-title' href='#'>
            <Card
              color='secondary'
              variant='filled'
              padding='15px'
              className='fofo-accordion-title'
            >
              {lang.contactInformation}
            </Card>
          </a>
          <div className='uk-accordion-content'>
            <form
              onSubmit={handleSubmitContactInfo(onSubmitContactInfo, onError)}
              key={2}
              noValidate
            >
              <Prompt
                when={formStateContactInfo.isDirty}
                message={JSON.stringify({
                  title: languageContext.dictionary.discard,
                  message: languageContext.dictionary.areYouSure,
                  accept: 'start__',
                  // accept: languageContext.dictionary.accept,
                  cancel: languageContext.dictionary.cancel
                })}
              />
              <CNumberFormat
                name='contactPhone'
                control={controlContactInfo}
                label={languageContext.dictionary.phone}
                maxLength={10}
                minLength={10}
                format='(###) ###-####'
                mask='_'
              />
              <CTextInput
                label={languageContext.dictionary.email}
                name='contactMail'
                control={controlContactInfo}
                type='email'
                maxLength={100}
              />
              <div className='uk-container uk-container-xsmall uk-flex uk-flex-around'>
                <Button
                  color={'grey'}
                  containerStyle={{ width: 'fit-content' }}
                  disabled={disabledButtonContactInfo}
                  onClick={onCancelContactInfo}
                  type='reset'
                >
                  {languageContext.dictionary.cancel}
                </Button>
                <Button
                  color='secondary'
                  containerStyle={{ width: 'fit-content' }}
                  type='submit'
                  disabled={disabledButtonContactInfo}
                >
                  {languageContext.dictionary.save}
                </Button>
              </div>
            </form>
          </div>
        </li>
        <li className='uk-open'>
          <a className='uk-accordion-title' href='#'>
            <Card
              color='secondary'
              variant='filled'
              padding='15px'
              className='fofo-accordion-title'
            >
              {lang.accountInformation}
            </Card>
          </a>
          <div className='uk-accordion-content'>
            <form
              onSubmit={handleSubmitAccountInfo(onSubmitAccountInfo, onError)}
              key={3}
              noValidate
            >
              <Prompt
                when={formStateAccountInfo.isDirty}
                message={JSON.stringify({
                  title: languageContext.dictionary.discard,
                  message: languageContext.dictionary.areYouSure,
                  accept: languageContext.dictionary.accept,
                  cancel: languageContext.dictionary.cancel
                })}
              />
              <CTextInput
                label={languageContext.dictionary.userName}
                name='nickname'
                control={controlAccountInfo}
                validate={{
                  alphaDash: (v) =>
                    validationRules.alphaDash(v, languageContext.userLanguage),
                  noSpaces: (v) =>
                    validationRules.noSpaces(v, languageContext.userLanguage)
                }}
                maxLength={20}
                required
              />
              <CNumberFormat
                label={languageContext.dictionary.phone}
                name='accountPhone'
                control={controlAccountInfo}
                maxLength={10}
                minLength={10}
                format='(###) ###-####'
                mask='_'
                required
              />
              <CTextInput
                label={languageContext.dictionary.email}
                name='accountMail'
                control={controlAccountInfo}
                type='email'
                maxLength={100}
                required
              />
              <Typography variant='subtitle' color='secondary'>
                {languageContext.dictionary.changePassword}
              </Typography>
              <div className='uk-grid'>
                <div className='uk-width-1-3'>
                  <CTextInput
                    label={languageContext.dictionary.password}
                    name='oldPass'
                    control={controlAccountInfo}
                    onChange={(e) => {
                      if (e.target.value !== '') {
                        setDiabledNewPassword(false)
                        setValueAccountInfo('oldPass', e.target.value)
                      } else {
                        setDiabledNewPassword(true)
                        setValueAccountInfo('newPass', '')
                        setValueAccountInfo('newPassConfirm', '')
                        setValueAccountInfo('oldPass', '')
                      }
                    }}
                    type='password'
                    maxLength={100}
                  />
                </div>
                <div className='uk-width-1-3'>
                  <CTextInput
                    label={languageContext.dictionary.newPassword}
                    name='newPass'
                    control={controlAccountInfo}
                    type='password'
                    disabled={disabledNewPassword}
                    validate={{
                      noSpaces: (v) =>
                        validationRules.noSpaces(
                          v,
                          languageContext.userLanguage
                        ),
                      hasUppercase: (v) =>
                        validationRules.hasUppercase(
                          v,
                          languageContext.userLanguage
                        ),
                      hasLowercase: (v) =>
                        validationRules.hasLowercase(
                          v,
                          LanguageContext.userLanguage
                        ),
                      hasNumber: (v) =>
                        validationRules.hasNumber(
                          v,
                          languageContext.userLanguage
                        )
                    }}
                    maxLength={120}
                    minLength={8}
                    required={!disabledNewPassword}
                  />
                </div>
                <div className='uk-width-1-3'>
                  <CTextInput
                    label={languageContext.dictionary.confirmPassword}
                    name='newPassConfirm'
                    control={controlAccountInfo}
                    type='password'
                    disabled={disabledNewPassword}
                    validate={{
                      same: (v) =>
                        validationRules.same(
                          v,
                          languageContext.userLanguage,
                          getValuesAccountInfo,
                          'newPass',
                          'passwordMatch'
                        )
                    }}
                    maxLength={120}
                    minLength={8}
                    required={!disabledNewPassword}
                  />
                </div>
              </div>

              <div
                data-uk-grid
                className='uk-margin-remove-top uk-margin-bottom'
              >
                <div className='uk-margin-top uk-flex uk-flex-center uk-flex-wrap uk-width-1-1'>
                  <progress
                    className='uk-progress leo-progress uk-width-1-1'
                    value={storageUsage}
                    // features is an array that can contain "max-storage", max is equal to the features "max-storage", extract "max-storage" from features and set it as the max value of the progress bar
                    max={features['max-storage'] ?? 0}
                  ></progress>
                  <div>
                    <Typography variant={'body2'} disableMargin>
                      Almacenamiento utilizado: {storageUsage} MB de{' '}
                      {features['max-storage'] ?? 0} MB
                    </Typography>
                  </div>
                </div>
              </div>

              <div className='uk-container uk-container-xsmall uk-flex uk-flex-around'>
                <Button
                  color={'grey'}
                  containerStyle={{ width: 'fit-content' }}
                  disabled={disabledButtonAccountInfo}
                  onClick={onCancelAccountInfo}
                  type='reset'
                >
                  {languageContext.dictionary.cancel}
                </Button>
                <Button
                  color='secondary'
                  containerStyle={{ width: 'fit-content' }}
                  type='submit'
                  disabled={disabledButtonAccountInfo}
                >
                  {languageContext.dictionary.save}
                </Button>
              </div>
            </form>
          </div>
        </li>
        <li className='uk-open'>
          <a className='uk-accordion-title' href='#'>
            <Card
              color='secondary'
              variant='filled'
              padding='15px'
              className='fofo-accordion-title'
            >
              {lang.preferences}
            </Card>
          </a>
          <div className='uk-accordion-content'>
            <form
              onSubmit={handleSubmitPreferences(onSubmitPreferences, onError)}
              key={4}
              noValidate
            >
              <Prompt
                when={formStatePreferences.isDirty}
                message={JSON.stringify({
                  title: languageContext.dictionary.discard,
                  message: languageContext.dictionary.areYouSure,
                  accept: languageContext.dictionary.accept,
                  cancel: languageContext.dictionary.cancel
                })}
              />
              <div className='uk-container uk-container-xsmall'>
                <div className='uk-flex uk-flex-between'>
                  <Typography variant='body' align='center' color='primary'>
                    {languageContext.dictionary.emailNofication}
                  </Typography>
                  <CSwitchInput
                    name='mailNotification'
                    control={controlPreferences}
                  />
                </div>
                <div className='uk-flex uk-flex-between'>
                  <Typography variant='body' align='center' color='primary'>
                    {languageContext.dictionary.newsLetter}
                  </Typography>
                  <CSwitchInput
                    name='newsLetter'
                    control={controlPreferences}
                  />
                </div>
              </div>
              <div className='uk-container uk-container-xsmall uk-flex uk-flex-around'>
                <Button
                  color={'grey'}
                  containerStyle={{ width: 'fit-content' }}
                  disabled={disabledButtonPreferencesInfo}
                  onClick={onCancelPreferences}
                  type='reset'
                >
                  {languageContext.dictionary.cancel}
                </Button>
                <Button
                  color='secondary'
                  containerStyle={{ width: 'fit-content' }}
                  type='submit'
                  disabled={disabledButtonPreferencesInfo}
                >
                  {languageContext.dictionary.save}
                </Button>
              </div>



            </form>
          </div>

        </li>

        <li className='uk-open'>
          <a className='uk-accordion-title' href='#'>
            <Card
              color='secondary'
              variant='filled'
              padding='15px'
              className='fofo-accordion-title'
            >
              {lang.preferences}
            </Card>
          </a>
          <div className='uk-accordion-content'>
            <form
              onSubmit={handleSubmitPreferences(onSubmitPreferences, onError)}
              key={4}
              noValidate
            >
              <Prompt
                when={formStatePreferences.isDirty}
                message={JSON.stringify({
                  title: languageContext.dictionary.discard,
                  message: languageContext.dictionary.areYouSure,
                  accept: languageContext.dictionary.accept,
                  cancel: languageContext.dictionary.cancel
                })}
              />

              {/* DELETE CLIENT ACCOUNT */}
              <div className='uk-container uk-container-xsmall uk-flex uk-flex-around'>
                <Button
                  color={'grey'}
                  containerStyle={{ width: 'fit-content' }}
                  disabled={disabledButtonPreferencesInfo}
                  onClick={openDeleteConfirm}
                  type='reset'
                >
                  {/* Client Delete */}
                  {languageContext.dictionary.deleteAccount}
                </Button>
                {/* <Button
                  color='secondary'
                  containerStyle={{ width: 'fit-content' }}
                  type='submit'
                  disabled={disabledButtonPreferencesInfo}
                >
                  {languageContext.dictionary.save}
                </Button> */}
              </div>



            </form>
          </div>

        </li>
      </ul>


      {/* MODAL FOR DELETE ACCOUNT */}


    </StandardView>
  )
}
