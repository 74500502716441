export default function (state, action) {
  const { payload, type } = action
  switch (type) {
    case 'LOGIN':
      return {
        ...state,
        self: payload.self,
        token: payload.token,
        socket: payload.socket,
        chats: payload.self.Chats,
        permissions: payload.permissions,
        roles: payload.roles,
        projects: payload.projects,
        prospects: payload.prospects,
        plans: payload.plans,
        planPermissions: payload.planPermissions,
        features: payload.features,
        isLoaded: payload.isLoaded
      }

    case 'UPDATE_CHATS':
      return {
        ...state,
        chats: payload.chats
      }

    case 'LOGOUT':
      return {
        payload
      }
    case 'UPDATE_SOCKET':
      return {
        ...state,
        socket: payload
      }
    case 'LOADED':
      return {
        ...state,
        isLoaded: payload
      }
    default:
      return state
  }
}
