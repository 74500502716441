import React, { useContext, useEffect } from 'react'
import _Auth from '../../context/auth'
import _Utils from '../../context/utils'
import { useHistory, useParams } from 'react-router'

import { LanguageContext } from '../../context/language/context'

export default function Index() {
  const history = useHistory()
  const dataToken = useParams().token
  const { toggleLoader, enqueueNotification } = useContext(_Utils.Context)
  const { acceptInvitation, self } = useContext(_Auth.Context)

  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  useEffect(() => {
    async function Init() {
      toggleLoader(true)
      const error = await acceptInvitation(dataToken)
      toggleLoader(false)
      if (!error) enqueueNotification(lang['welcome'], 'success')
      else {
        enqueueNotification(
          languageContext.dictionary['somethingWrong'],
          'error'
        )
      }
      if (self) history.push('/dashboard')
      else history.push('/')
    }
    Init()
  }, [])
  return <div></div>
}
