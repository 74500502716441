import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

export default function Thumb(props) {
  useEffect(() => {
    if (typeof props.file === 'string') {
      setReaded(props.file)
    } else {
      const reader = new FileReader()
      reader.onloadend = () => {
        setReaded(reader.result)
      }
      reader.readAsDataURL(props.file)
    }
  }, [props.file])

  const [readed, setReaded] = useState(null)

  return <img src={readed} className={props.className} />
}

Thumb.defaultProps = {
  file: null,
  className: ''
}

Thumb.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string
}
