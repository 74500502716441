import React, { useState, useContext, useEffect, useRef } from 'react'

import Bicho from './assets/note_black.svg'
import { Modal } from 'react-responsive-modal'

import { CDropZoneCoverImage } from '../../components/DropZoneCoverImage'
import ImageThumbsContainer from '../ImageThumbsContainer'
import { handleOnPastCapture } from '../../functions/files.functions'

import { LanguageContext } from '../../context/language/context'

import { CTextInput } from '../../components/TextInput'
import { useForm } from 'react-hook-form'
import { serialize } from '../../functions/validation.functions'
import { Prompt } from 'react-router-dom'
import { serverValidation } from '../../functions/validation.functions'
import _Utils from '../../context/utils'
import { _handleErrorMessage } from '../../functions/error.functions'
import './styles/style.css'
import Button from '../Button'
import Typography from '../Typography'
import { IconButton } from '../IconButton'

export default function ModalBug() {
  const languageContext = useContext(LanguageContext)

  const { toggleLoader, enqueueNotification } = useContext(_Utils.Context)

  const myRef = useRef(null)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const [open, setOpen] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])

  function onOpen() {
    setOpen(true)
  }

  function onClose() {
    setOpen(false)
  }

  const {
    reset,
    setValue,
    getValues,
    control,
    setError,
    handleSubmit,

    formState
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: '',
      description: '',
      files: []
    }
  })

  useEffect(() => {
    if (formState.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
    } else {
      window.onbeforeunload = null
    }
  }, [formState.isDirty])

  async function onSubmit(data) {
    await toggleLoader(true)
    setDisableButton(true)

    const formData = serialize(data, {
      dotsForObjectNotation: true,
      noArrayNotation: true,
      noFilesWithArrayNotation: true
    })

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'services/debug',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data'
          },
          body: formData
        }
      )

      await serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        async () => {
          await toggleLoader(false)
          setDisableButton(false)
          reset()
          enqueueNotification(languageContext.dictionary['success'], 'success')
          setOpen(false)
        },
        async (error) => {
          await toggleLoader(false)
          setDisableButton(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      setDisableButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  return (
    <>
      <div className='floating-button' onClick={onOpen} ref={myRef}>
        <img src={Bicho} alt='' />
      </div>
      <Modal
        open={open}
        onClose={onClose}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        classNames={{
          modal: 'customModalDebug'
        }}
        container={myRef.current}
      >
        <Typography variant={'h6'} color='secondary'>
          {lang.bugReport}
        </Typography>
        <Typography className='uk-margin-bottom'>{lang.please}</Typography>
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          onPasteCapture={(event) => {
            let blob = handleOnPastCapture(event)
            if (blob) {
              let respaldo = getValues('files')
              respaldo.push(blob)
              setValue('files', respaldo)
            }
          }}
        >
          <Prompt
            when={formState.isDirty}
            message={JSON.stringify({
              title: languageContext.dictionary.discard,
              message: languageContext.dictionary.areYouSure,
              accept: languageContext.dictionary.accept,
              cancel: languageContext.dictionary.cancel
            })}
          />
          <CTextInput
            label={languageContext.dictionary.title}
            name='title'
            control={control}
            required
            maxLength={45}
          />
          <CTextInput
            type='textarea'
            label={languageContext.dictionary.desc}
            name='description'
            control={control}
            required
            maxLength={2000}
            charCount
          />
          <CDropZoneCoverImage
            name={'files'}
            control={control}
            required
            ContainerClasses='diinco-project-form-dropzone-container oski-drop-padding-top'
            ImageClasses='diinco-project-form-dropzone-image'
            onChange={() => forceUpdate()}
          />
          <ImageThumbsContainer
            control={control}
            thumbs={getValues('files')}
            containerStyle='diinco-thumbs-container-project-form'
            thumbStyle='diinco-thumbs-style-project-form'
            deleteButtonStyle='diinco-thumbs-delete-project-form'
            handleRemove={(event, file, index) => {
              const newValue = getValues('files').filter((o, i) => i !== index)
              setValue('files', newValue)
              forceUpdate()
            }}
          />
          <div className='send-bug'>
            <Button type='submit' color='secondary' disabled={disableButton}>
              {languageContext.dictionary.send}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}
