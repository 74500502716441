import React from 'react'
import { useHistory } from 'react-router-dom'
import './styles/style.css'
import PropTypes from 'prop-types'
//Components
import Avatar from '../Avatar'
import Typography from '../Typography'
import banner from '../../assets/images/banner.webp'

export function AlbumSearchResult(props) {
  const {
    id,
    img,
    name,
    tags = [],
    users = [],
    description,
    city,
    state,
    country
  } = props
  const history = useHistory()

  const handleClick = () => {
    history.push('/Albums/' + id)
  }

  return (
    <div className='uk-width-1-1' onClick={handleClick}>
      <div className='album-search-result-container'>
        <div className='album-search-result-cover'>
          <img
            className='album-search-result-img'
            src={img ? process.env.REACT_APP_API_URL + img : banner}
            alt={name}
          />
          <div className='album-search-result-gradient'></div>
          <div className='album-search-result-professionals'>
            {users.slice(0, 3).map((user, index) =>
              index < 2 ? (
                <>
                  <Avatar alt={user.name} src={user.image} />
                  <Typography style={{ maxWidth: 100 }}>{user.name}</Typography>
                </>
              ) : (
                <Avatar key={index + 'Avatar'} alt={'+ 3'} src='+' />
              )
            )}
          </div>
          <Typography className='album-search-result-title'>{name}</Typography>
          <div className='album-search-result-tags' style={{ marginLeft: 5 }}>
            {tags.map((tag, index) => (
              // <Tag
              //     tagName={tag.name}
              //     editable={false}
              //     key={tag.id}
              // />
              <Typography
                key={index + 'Typo'}
                theme='dark'
                variant={'body2'}
                onClick={(event) => {
                  event.stopPropagation()
                  history.push('/Tag/' + tag.name)
                }}
                style={{
                  marginLeft: 5,
                  cursor: 'pointer'
                }}
              >
                #{tag.name}
              </Typography>
            ))}
          </div>
        </div>
        {city && state && country && (
          <Typography
            color='secondary'
            variant={'body2'}
            align='right'
            className='uk-margin-small-top'
          >
            {city}, {state}, {country}
          </Typography>
        )}
        <Typography variant={'body2'}>{description}</Typography>
      </div>
    </div>
  )
}

AlbumSearchResult.defaultProps = {
  id: 0,
  img: '',
  name: '',
  tags: [],
  users: [],
  description: '',
  city: '',
  state: '',
  country: ''
}
AlbumSearchResult.propTypes = {
  id: PropTypes.number,
  img: PropTypes.string,
  name: PropTypes.string,
  tags: PropTypes.array,
  users: PropTypes.array,
  description: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string
}
