import { React, useContext } from 'react'

import _Utils from '../../context/utils'
import { LanguageContext } from '../../context/language/context'
import { _handleErrorMessage } from '../../functions/error.functions'

import StandardView from '../../layouts/StandardView'
import Typography from '../../components/Typography'
import Button from '../../components/Button'
import Divider from '../../components/Divider'

export default function MailsView() {
  const { enqueueNotification } = useContext(_Utils.Context)

  const languageContext = useContext(LanguageContext)
  // var lang;
  // if (languageContext.userLanguage == "en") {
  // 	lang = require("./languages/en.json");
  // } else {
  // 	lang = require("./languages/es.json");
  // }

  const sendMail = async (type) => {
    const formData = new FormData()

    formData.append('type', type)
    formData.append('mail', 'oscarwaiipoo@gmail.com')
    formData.append('project', 'titulo del proyecto')

    try {
      enqueueNotification('Correo enviado correctamente', 'success')
      await fetch(process.env.REACT_APP_API_URL + 'auth/mailsview', {
        method: 'POST',
        headers: {
          Accept: 'multipart/formdata'
        },

        body: formData
      })
    } catch (error) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  return (
    <StandardView title='Para probar los mails'>
      <Typography variant='h6' color='secondary'>
        Correos relacionados a usuarios
      </Typography>
      {/* <div className="uk-flex uk-flex-between uk-flex-middle uk-margin-bottom">
                <Typography
                    variant="body"
                >
                    Correo de bienvenida al crear una cuenta
                </Typography>
                <Button
                    disableMargin
                    onClick={() => sendMail("new account")}
                >
                    Enviar
                </Button>
            </div> */}
      <div className='uk-flex uk-flex-between uk-flex-middle uk-margin-bottom'>
        <Typography variant='body'>Verificar cuenta</Typography>
        <Button
          color='secondary'
          disableMargin
          onClick={() => sendMail('verify account')}
        >
          Enviar
        </Button>
      </div>
      <div className='uk-flex uk-flex-between uk-flex-middle uk-margin-bottom'>
        <Typography variant='body'>Codigo de autenticacion</Typography>
        <Button
          color='secondary'
          disableMargin
          onClick={() => sendMail('authentication code')}
        >
          Enviar
        </Button>
      </div>
      <div className='uk-flex uk-flex-between uk-flex-middle uk-margin-bottom'>
        <Typography variant='body'>Recuperacion de contrasena</Typography>
        <Button
          color='secondary'
          disableMargin
          onClick={() => sendMail('password recovery')}
        >
          Enviar
        </Button>
      </div>
      <div className='uk-flex uk-flex-between uk-flex-middle uk-margin-bottom'>
        <Typography variant='body'>Contacta con nosotros</Typography>
        <Button
          color='secondary'
          disableMargin
          onClick={() => sendMail('contact us')}
        >
          Enviar
        </Button>
      </div>

      <Typography variant='subtitle' color='secondary'>
        Invitacion a unirse a un nuevo prospecto
      </Typography>
      <div className='uk-flex uk-flex-between uk-flex-middle uk-margin-bottom'>
        <Typography variant='body'>El usuario no tiene cuenta</Typography>
        <Button
          color='secondary'
          disableMargin
          onClick={() => sendMail('join prospect new user')}
        >
          Enviar
        </Button>
      </div>
      <div className='uk-flex uk-flex-between uk-flex-middle uk-margin-bottom'>
        <Typography variant='body'>Usuario con cuenta</Typography>
        <Button
          color='secondary'
          disableMargin
          onClick={() => sendMail('join prospect')}
        >
          Enviar
        </Button>
      </div>

      <Divider className='uk-margin-bottom' />

      <Typography variant='h6' color='secondary'>
        Correos relacionados a proyectos
      </Typography>
      <div className='uk-flex uk-flex-between uk-flex-middle uk-margin-bottom'>
        <Typography variant='body'>
          Se te ha invitado a formar parte del proyecto
        </Typography>
        <Button
          color='secondary'
          disableMargin
          onClick={() => sendMail('project invitation')}
        >
          Enviar
        </Button>
      </div>
      <div className='uk-flex uk-flex-between uk-flex-middle uk-margin-bottom'>
        <Typography variant='body'>
          Se realizaron modificaciones en tu proyecto
        </Typography>
        <Button
          color='secondary'
          disableMargin
          onClick={() => sendMail('project edit')}
        >
          Enviar
        </Button>
      </div>
      <div className='uk-flex uk-flex-between uk-flex-middle uk-margin-bottom'>
        <Typography variant='body'>
          Un usuario ha subido un reporte al proyecto
        </Typography>
        <Button
          color='secondary'
          disableMargin
          onClick={() => sendMail('project new report')}
        >
          Enviar
        </Button>
      </div>
      <div className='uk-flex uk-flex-between uk-flex-middle uk-margin-bottom'>
        <Typography variant='body'>
          Un usuario ha subido un pago al proyecto
        </Typography>
        <Button
          color='secondary'
          disableMargin
          onClick={() => sendMail('project new payment')}
        >
          Enviar
        </Button>
      </div>

      <Typography variant='subtitle' color='secondary'>
        Recordatorios
      </Typography>
      <div className='uk-flex uk-flex-between uk-flex-middle uk-margin-bottom'>
        <Typography variant='body'>La fecha de pago esta proxima</Typography>
        <Button
          color='secondary'
          disableMargin
          onClick={() => sendMail('reminder payment date')}
        >
          Enviar
        </Button>
      </div>
      <div className='uk-flex uk-flex-between uk-flex-middle uk-margin-bottom'>
        <Typography variant='body'>
          El proyecto esta proximo a terminar
        </Typography>
        <Button
          color='secondary'
          disableMargin
          onClick={() => sendMail('reminder project end')}
        >
          Enviar
        </Button>
      </div>
      <div className='uk-flex uk-flex-between uk-flex-middle uk-margin-bottom'>
        <Typography variant='body'>
          Los miembros del proyecto deben evaluar a los administradores
        </Typography>
        <Button
          color='secondary'
          disableMargin
          onClick={() => sendMail('reminder project evaluation')}
        >
          Enviar
        </Button>
      </div>
    </StandardView>
  )
}
