import React, { useState, useRef, useContext, useEffect } from 'react'
import CustomCalendar from '../../../../components/CustomCalendar'
import { LanguageContext } from '../../../../context/language/context'
import _Auth from '../../../../context/auth'
import { _handleErrorMessage } from '../../../../functions/error.functions'
import _Utils from '../../../../context/utils'
import Modal from 'react-responsive-modal'
import Typography from '../../../../components/Typography'
import DiincoLogo from '../../../../assets/images/logo_white.png'
import { IconButton } from '../../../../components/IconButton'

export const Calendar = () => {
  const success = new URLSearchParams(window.location.search).get('success')
  const [events, setEvents] = useState([])
  const calendar = useRef(null)
  const { userLanguage } = useContext(LanguageContext)
  const { token } = useContext(_Auth.Context)
  const { toggleLoader, enqueueNotification } = useContext(_Utils.Context)

  var lang
  if (userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  useEffect(() => {
    async function Init() {
      toggleLoader(true)
      try {
        let data = await fetch(
          process.env.REACT_APP_API_URL + 'dashboard/events',
          {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        data = await data.json()
        let tmp = []

        if (data.Events) {
          data.Events.map((event) => {
            tmp.push({
              date: new Date(event.date_end),
              data: {
                title: event.title,
                date: new Date(event.date_end),
                date_start: new Date(event.date_start),
                date_end: new Date(event.date_end),
                description: event.body,
                status: event.status,
                id: event.id,
                stage: 0
              }
            })
          })
        }

        if (data.Projects) {
          data.Projects.map((project) => {
            if (project.Events) {
              project.Events.map((event) => {
                tmp.push({
                  date: new Date(event.date_end),
                  data: {
                    title: event.title,
                    date: new Date(event.date_end),
                    date_start: new Date(event.date_start),
                    date_end: new Date(event.date_end),
                    description: event.body,
                    status: event.status,
                    id: event.id,
                    stage: 0,
                    projectId: project.id
                  }
                })
              })
            }
          })
        }

        if (data.Prospects) {
          data.Prospects.map((prospect) => {
            if (prospect.Events) {
              prospect.Events.map((event) => {
                tmp.push({
                  date: new Date(event.date_end),
                  data: {
                    title: event.title,
                    date: new Date(event.date_end),
                    date_start: new Date(event.date_start),
                    date_end: new Date(event.date_end),
                    description: event.body,
                    status: event.status,
                    id: event.id,
                    stage: 0,
                    prospectId: prospect.id
                  }
                })
              })
            }
          })
        }
        // tmp.sort((a, b) => new Date(a.date) - new Date(b.date));
        setEvents([...tmp])
      } catch (error) {
        enqueueNotification(_handleErrorMessage(userLanguage, error), 'error')
      }
      toggleLoader(false)
    }
    if (token) Init()
  }, [token])

  const [open, setOpen] = useState(true)

  const onClose = () => setOpen(false)

  return (
    <div>
      <CustomCalendar
        dates={events}
        inputRef={calendar}
        locale={userLanguage === 'es' ? 'es-ES' : 'en-US'}
      />
      <Modal
        open={Boolean(success) && open}
        onClose={onClose}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        classNames={{ modal: 'custom-modal-buy' }}
      >
        <div data-uk-grid>
          <div>
            <Typography
              variant={'h6'}
              align={'center'}
              color={'secondary'}
              className={'uk-margin-medium-top uk-margin-medium-bottom'}
              style={{ textTransform: 'uppercase' }}
            >
              {lang.congratulations}
            </Typography>

            <img
              src={DiincoLogo}
              crossOrigin='use-credentials'
              className='dc-fit-cover'
            />

            <Typography
              variant={'h6'}
              align={'center'}
              color={'secondary'}
              className={'uk-margin-medium-top uk-margin-medium-bottom'}
              style={{ textTransform: 'uppercase' }}
            >
              {lang.enjoy}
            </Typography>
          </div>
        </div>
      </Modal>
    </div>
  )
}
