import React, { useContext, useEffect } from 'react'
import _Auth from '../../context/auth'
import _Utils from '../../context/utils'
import { useHistory, useParams } from 'react-router'

import { LanguageContext } from '../../context/language/context'

export default function Index() {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const history = useHistory()
  const dataToken = useParams().token
  const { toggleLoader, enqueueNotification, notify } = useContext(
    _Utils.Context
  )
  const { verify } = useContext(_Auth.Context)
  useEffect(() => {
    async function Init() {
      toggleLoader(true)
      const data = await verify(dataToken)
      toggleLoader(false)
      if (!data.error) {
        if (data.user.Roles[0].name === 'professional')
          notify(languageContext.dictionary['welcome'], () => {
            window.location.replace('/login')
          })
        else
          notify(languageContext.dictionary['welcome'], () => {
            window.location.replace('/login')
          })
      } else {
        if (data.error.details === 'User has been already registered') {
          enqueueNotification(lang.registered, 'error')
        } else {
          enqueueNotification(languageContext.dictionary['error'], 'error')
        }
        history.push('/login')
      }
    }
    Init()
  }, [])
  return <div></div>
}
