import React, { useContext } from 'react'
import './styles/style.css'
import PropTypes from 'prop-types'
import { LanguageContext } from '../../context/language/context'
import Typography from '../Typography'

export default function NoData(props) {
  const { className, ...rest } = props
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  return (
    <div
      className={'uk-width-1-1 dc-no-data-container ' + (className ?? '')}
      {...rest}
    >
      <Typography
        className='uk-margin-medium-bottom'
        variant='subtitle'
        align='center'
      >
        {lang.notFound}
      </Typography>
    </div>
  )
}

NoData.propTypes = {
  className: PropTypes.string
}
