import React, { useState, createContext, useContext } from 'react'
import PropTypes from 'prop-types'

import { dictionaryList } from '../../languages/index'

// create the language context with default selected language
export const LanguageContext = createContext({
  userLanguage: 'es',
  dictionary: dictionaryList.es,
  theme: 'light'
})

// it provides the language context to app
export function Tools({ children }) {
  const defaultLanguage = window.localStorage.getItem('rcml-lang')
  const [userLanguage, setUserLanguage] = useState(defaultLanguage || 'es')
  const defaultTheme = window.localStorage.getItem('rcml-theme')
  const [theme, setTheme] = useState(defaultTheme || 'light')

  const provider = {
    userLanguage,
    dictionary: dictionaryList[userLanguage],
    userLanguageChange: (selected) => {
      const newLanguage = selected
      setUserLanguage(newLanguage)
      window.localStorage.setItem('rcml-lang', newLanguage)
    },
    handleError: () => {},
    theme,
    changeTheme: () => {
      var newTheme
      if (theme === 'dark') newTheme = 'light'
      else newTheme = 'dark'
      setTheme(newTheme)
      window.localStorage.setItem('rcml-theme', newTheme)
    }
  }

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  )
}

Tools.defaultProps = {
  children: null
}

Tools.propTypes = {
  children: PropTypes.node
}

// get text according to id & current language
export function Text({ tid }) {
  const languageContext = useContext(LanguageContext)

  return languageContext.dictionary[tid] || tid
}
