import React, { useContext } from 'react'
import StandardView from '../../../layouts/StandardView'
import Avatar from '../../../components/Avatar'
import Typography from '../../../components/Typography'
import _Auth from '../../../context/auth'
import _Utils from '../../../context/utils'
import { useEffect } from 'react'
import { serverValidation } from '../../../functions/validation.functions'
import { LanguageContext } from '../../../context/language/context'
import { _handleErrorMessage } from '../../../functions/error.functions'
import { useState } from 'react'
import NoData from '../../../components/NoData'
import CustomGalleryVertical from '../../../components/CustomGalleryVertical'

const Bugs = () => {
  const { token } = useContext(_Auth.Context)
  const { enqueueNotification } = useContext(_Utils.Context)
  const languageContext = useContext(LanguageContext)

  const [loading, setLoading] = useState(true)
  const [bugs, setBugs] = useState(null)
  const [bug, setBug] = useState(null)
  const [images, setImages] = useState(null)

  async function Init() {
    try {
      let response = await fetch(process.env.REACT_APP_API_URL + 'stats/bugs', {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      serverValidation(
        response,
        null,
        LanguageContext.userLanguage,
        (data) => {
          setLoading(false)

          setBugs(data)
        },
        (error) => {
          setLoading(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error),
            'error'
          )
        }
      )
    } catch (error) {
      setLoading(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  useEffect(() => {
    if (!token) return
    Init()
  }, [token])

  return (
    <StandardView title='Bugs' loading={loading}>
      {/*Bug List Column*/}
      <div
        className='uk-grid'
        style={{
          height: 'calc(100vh - 64px)'
        }}
      >
        <div className='uk-width-1-4 uk-height-1-1 uk-overflow-auto'>
          {bugs &&
            bugs.length &&
            bugs.map((e, i) => (
              <div
                className='uk-grid uk-grid-small'
                key={i}
                style={{
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setBug(e)
                  if (e.Images) {
                    let items = []
                    e.Images.forEach((image) => {
                      let item = {
                        url: process.env.REACT_APP_API_URL + image.url
                      }
                      items.push(item)
                    })
                    setImages(items)
                  }
                }}
              >
                <div className='uk-width-1-4'>
                  <Avatar size={'medium'} alt={e?.User ? e?.User?.name : 'N'} />
                </div>
                <div className='uk-width-3-4'>
                  <Typography variant='h6'>{e.title}</Typography>
                  <Typography variant='body1'>
                    {' '}
                    {e?.User ? e?.User?.name : 'Sin Usuario'}
                  </Typography>
                </div>
              </div>
            ))}
        </div>

        {/*Bug Details Column*/}
        <div className='uk-width-3-4 '>
          {bug ? (
            <React.Fragment>
              <Typography variant='h4'>{bug.title}</Typography>

              <div className='uk-grid uk-grid-small'>
                <div className='uk-width-auto'>
                  <Avatar
                    size={'medium'}
                    alt={bug.User ? bug.User.name : 'N'}
                  />
                </div>
                <div className='uk-width-auto'>
                  <Typography variant='h6'>
                    {bug.title ? bug.title : 'Sin título'}
                  </Typography>
                  <Typography variant='body1'>
                    {bug.User ? bug.User.name : 'Sin Usuario'}
                  </Typography>
                </div>
              </div>

              <Typography variant='body1'>
                {bug.description ? bug.description : 'Sin descripción'}
              </Typography>

              <CustomGalleryVertical images={images} />
            </React.Fragment>
          ) : (
            <NoData />
          )}
        </div>
      </div>
    </StandardView>
  )
}

export default Bugs
