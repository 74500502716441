import React, { useEffect, useReducer } from 'react'
import Reducer from './reducer'
import Context from './context'
import io from 'socket.io-client'
import './styles/style.css'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

const Auth = (props) => {
  const initialState = {
    self: null,
    token: null,
    socket: null,
    chats: [],
    notifications: [],
    permissions: [],
    roles: [],
    plans: [],
    planPermissions: [],
    features: {},

    //roles and permissions per project/prospect
    projects: {},
    prospects: {},

    isLoaded: false
  }
  const history = useHistory()

  const [state, dispatch] = useReducer(Reducer, initialState)
  //on close, must be deleted from array with a callback
  useEffect(() => {
    async function Init() {
      await recover()
      dispatch({
        type: 'LOADED',
        payload: true
      })
    }
    Init()
  }, [])

  useEffect(() => {
    async function Init() {}
    if (state.token) Init()
  }, [state])

  async function login(account, password) {
    try {
      if (state.token && state.self && state.socket) throw 'Already Logged In'
      let response = await fetch(process.env.REACT_APP_API_URL + 'auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ account, password })
      })
      // data = await _handleResponse(data);
      return response
    } catch (error) {
      return error
    }
  }
  // console.log({ chats: state.chats });

  async function codeAuth(code, token, setError = null, lang = 'en') {
    let code_body = []
    let status
    let resJson
    for (let i = 0; i < code.length; i++) {
      code_body.push(parseInt(code.charAt(i)))
    }

    try {
      if (state.token && state.self && state.socket) throw 'Already Logged In'

      let response = await fetch(process.env.REACT_APP_API_URL + 'auth/code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        credentials: 'include',
        body: JSON.stringify({ code: code_body })
      })

      status = response.status

      // console.log(status);

      resJson = await response.clone().json()

      if (status !== 200) throw await response.json()
      // console.log("cookies", cookies.getAll());
      // cookies.remove("token");
      let data = await response.json()
      const socket = io(process.env.REACT_APP_API_URL, {
        withCredentials: true,
        auth: { token: data.token, user: data.user }
      })
      // cookies.set("token", data.token, { path: "/" });
      // console.log("new cookies", cookies.getAll());
      // console.log({ socket });
      sessionStorage.setItem('token', data.token)

      // console.log("dispatch-code");
      dispatch({
        type: 'LOGIN',
        payload: {
          token: data.token,
          self: data.user,
          socket,
          ...getUserPermissionsAndRoles(data.user)
        }
      })
      dispatch({
        type: 'LOADED',
        payload: true
      })
    } catch (error) {
      //show form validation errors
      if (setError && resJson && resJson.details && resJson.details.errors) {
        resJson.details.errors.forEach((error) => {
          if (error.param === '_error' && error.nestedErrors) {
            error.nestedErrors.forEach((nestedError) => {
              let errorParam = nestedError.param.replace('()', '')
              if (errorParam.indexOf('.') > 0)
                errorParam = errorParam.substr(0, errorParam.indexOf('.'))
              if (errorParam.indexOf('[') > 0)
                errorParam = errorParam.substr(0, errorParam.indexOf('['))
              setError(errorParam, {
                type: 'server',
                message: nestedError.msg[lang]
              })
            })
          } else {
            let errorParam = error.param.replace('()', '')
            if (errorParam.indexOf('.') > 0)
              errorParam = errorParam.substr(0, errorParam.indexOf('.'))
            if (errorParam.indexOf('[') > 0)
              errorParam = errorParam.substr(0, errorParam.indexOf('['))
            setError(errorParam, {
              type: 'server',
              message: error.msg[lang]
            })
          }
        })
      }
      return error
    }
  }

  async function register({
    name,
    password,
    email,
    phone,
    nickname,
    type = 1,
    phone_ext = '+52',
    birth_date,
    news = 0,
    prospectId
  }) {
    // console.log(
    // 	name,
    // 	password,
    // 	email,
    // 	phone,
    // 	nickname,
    // 	type,
    // 	phone_ext,
    // 	birth_date,
    // 	news
    // );
    try {
      if (state.token && state.self && state.socket) throw 'Already Logged In'
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'auth/signup',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            name,
            password,
            email,
            phone,
            nickname,
            type,
            phone_ext,
            birth_date,
            news,
            prospectId
          })
        }
      )
      return response

      // console.log(data);
    } catch (error) {
      console.error(error)
      return error
    }
  }

  async function verify(dataToken) {
    try {
      if (state.token && state.self && state.socket) throw 'Already Logged In'
      let data = await fetch(process.env.REACT_APP_API_URL + 'auth/verify', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: dataToken })
      })
      if (data.status !== 200) throw await data.json()
      data = await data.json()
      // console.log({ data });
      const socket = io(process.env.REACT_APP_API_URL, {
        withCredentials: true,
        auth: { token: data.token, user: data.user }
      })
      dispatch({
        type: 'LOGIN',
        payload: {
          token: data.token,
          self: data.user,
          socket,
          ...getUserPermissionsAndRoles(data.user)
        }
      })
      dispatch({
        type: 'LOADED',
        payload: true
      })
      return data
    } catch (error) {
      return { error }
    }
  }

  async function acceptInvitation(dataToken) {
    try {
      let data = await fetch(
        process.env.REACT_APP_API_URL + 'auth/acceptInvitation',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token: dataToken })
        }
      )
      if (data.status !== 200) throw await data.json()
      data = await data.json()
    } catch (error) {
      console.error(error)
      return error
    }
  }

  async function recover() {
    try {
      let data = await fetch(process.env.REACT_APP_API_URL + 'auth/recover', {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
      })
      if (data.status !== 200) throw await data.json()
      data = await data.json()
      const socket = io(process.env.REACT_APP_API_URL, {
        withCredentials: true,
        auth: { token: data.token, user: data.user }
      })
      dispatch({
        type: 'LOGIN',
        payload: {
          token: data.token,
          self: data.user,
          socket,
          ...getUserPermissionsAndRoles(data.user)
        }
      })
      dispatch({
        type: 'LOADED',
        payload: true
      })
    } catch (error) {
      console.error(error)
    }
  }

  function getUserPermissionsAndRoles(user) {
    var permissions = []
    var roles = []
    if (user && user.Roles && user.Roles.length > 0) {
      user.Roles.forEach((role) => {
        roles.push(role.name)
        if (role.Permissions && role.Permissions.length > 0) {
          role.Permissions.forEach((permission) => {
            permissions.push(permission.name)
          })
        }
      })
    }

    var projects = {}
    if (user && user.User_Projects && user.User_Projects.length > 0) {
      user.User_Projects.forEach((project) => {
        projects[project.ProjectId] = {
          roles: [],
          permissions: []
        }
        if (project.Role) {
          projects[project.ProjectId].roles.push(project.Role.name)

          if (project.Role.Permissions && project.Role.Permissions.length > 0) {
            project.Role.Permissions.forEach((permission) => {
              projects[project.ProjectId].permissions.push(permission.name)
            })
          }
        }
      })
    }

    var prospects = {}
    if (user && user.User_Prospects && user.User_Prospects.length > 0) {
      user.User_Prospects.forEach((prospect) => {
        prospects[prospect.ProspectId] = {
          roles: [],
          permissions: []
        }
        if (prospect.Role) {
          prospects[prospect.ProspectId].roles.push(prospect.Role.name)

          if (
            prospect.Role.Permissions &&
            prospect.Role.Permissions.length > 0
          ) {
            prospect.Role.Permissions.forEach((permission) => {
              prospects[prospect.ProspectId].permissions.push(permission.name)
            })
          }
        }
      })
    }

    var planPermissions = []
    var plans = []
    var features = {}
    if (user && user.Subscriptions && user.Subscriptions.length > 0) {
      for (const subscription of user.Subscriptions) {
        if (subscription && subscription.Plan && subscription.Plan.Product) {
          plans.push({
            product: subscription.Plan.Product.name,
            plan: subscription.Plan.name
          })
        }
        if (
          !subscription ||
          !subscription.Plan ||
          !subscription.Plan.Product ||
          !subscription.Plan.Product.Permissions ||
          !subscription.Plan.Product.Permissions.length
        )
          continue

        for (let j = 0; j < subscription.Plan.Product.Permissions.length; j++)
          planPermissions.push(subscription.Plan.Product.Permissions[j].name)
      }
    }

    if (user.Features) features = user.Features

    return {
      permissions,
      roles,
      projects,
      prospects,
      planPermissions,
      features,
      plans
    }
  }

  function hasPermission(
    permission,
    operator = 'or',
    id = null,
    type = 'system'
  ) {
    if (type === 'plan') {
      if (!hasPermission(permission, operator, null, 'system')) {
        if (typeof permission === 'string') {
          return state.planPermissions.includes(permission)
        } else if (typeof permission === 'object' && permission.length > 0) {
          if (operator === 'or') {
            var validator = false
            permission.forEach((perm) => {
              if (state.planPermissions.includes(perm)) {
                validator = true
              }
            })
            return validator
          } else if (operator === 'and') {
            validator = true
            permission.forEach((perm) => {
              if (!state.planPermissions.includes(perm)) {
                validator = false
              }
            })
            return validator
          }
        }
        return false
      }
      return true
    }
    if (type === 'system') {
      if (typeof permission === 'string') {
        return state.permissions.includes(permission)
      } else if (typeof permission === 'object' && permission.length > 0) {
        if (operator === 'or') {
          validator = false
          permission.forEach((perm) => {
            if (state.permissions.includes(perm)) {
              validator = true
            }
          })
          return validator
        } else if (operator === 'and') {
          validator = true
          permission.forEach((perm) => {
            if (!state.permissions.includes(perm)) {
              validator = false
            }
          })
          return validator
        }
      }
      return false
    } else {
      if (type === 'project') type = 'projects'
      else if (type === 'prospect') type = 'prospects'

      if (
        !id ||
        !state[type] ||
        !state[type][id] ||
        !state[type][id].permissions
      )
        return false

      if (typeof permission === 'string') {
        return state[type][id].permissions.includes(permission)
      } else if (typeof permission === 'object' && permission.length > 0) {
        if (operator === 'or') {
          validator = false
          permission.forEach((perm) => {
            if (state[type][id].permissions.includes(perm)) {
              validator = true
            }
          })
          return validator
        } else if (operator === 'and') {
          validator = true
          permission.forEach((perm) => {
            if (!state[type][id].permissions.includes(perm)) {
              validator = false
            }
          })
          return validator
        }
      }
      return false
    }
  }

  function hasRole(role, operator = 'or', id = null, type = 'system') {
    if (type === 'system') {
      if (typeof role === 'string') {
        return state.roles.includes(role)
      } else if (typeof role === 'object' && role.length > 0) {
        if (operator === 'or') {
          var validator = false
          role.forEach((roleF) => {
            if (state.roles.includes(roleF)) {
              validator = true
            }
          })
          return validator
        } else if (operator === 'and') {
          validator = true
          role.forEach((roleF) => {
            if (!state.roles.includes(roleF)) {
              validator = false
            }
          })
          return validator
        }
      }
      return false
    } else {
      if (type === 'project') type = 'projects'
      else if (type === 'prospect') type = 'prospects'

      if (!id || !state[type] || !state[type][id] || !state[type][id].roles)
        return false

      if (typeof role === 'string') {
        return state[type][id].roles.includes(role)
      } else if (typeof role === 'object' && role.length > 0) {
        if (operator === 'or') {
          validator = false
          role.forEach((roleF) => {
            if (state[type][id].roles.includes(roleF)) {
              validator = true
            }
          })
          return validator
        } else if (operator === 'and') {
          validator = true
          role.forEach((roleF) => {
            if (!state[type][id].roles.includes(roleF)) {
              validator = false
            }
          })
          return validator
        }
      }
      return false
    }
  }

  async function logout() {
    try {
      let data = await fetch(process.env.REACT_APP_API_URL + 'auth/logout', {
        credentials: 'include',
        mode: 'cors',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      })
      if (data.status !== 200) throw await data.json()
      data = await data.json()
      dispatch({
        type: 'LOGOUT',
        payload: initialState
      })
      // unsubscribe();
      // console.log("session closed");
      state.socket.disconnect()
      sessionStorage.removeItem('token')
      history.push('/')
    } catch (error) {
      // console.log("could not close session!");
    }
  }

  function reloadData(callback = () => {}) {
    dispatch({
      type: 'LOGOUT',
      payload: initialState
    })
    callback()
    history.go(0)
  }

  function reloadAndGo(next) {
    dispatch({
      type: 'LOGOUT',
      payload: initialState
    })
    history.push(next)
  }

  async function reloadNew() {
    await recover()
    history.push(0)
  }

  // console.log(state.chats);

  return (
    <Context.Provider
      value={{
        ...state,
        recover,
        login,
        logout,
        register,
        verify,
        codeAuth,
        reloadData,
        reloadAndGo,
        acceptInvitation,
        hasPermission,
        hasRole,
        reloadNew
      }}
    >
      {props.children}
      {/* {console.log("CHATS", openChats)}
			{console.log("ALL", state.chats)} */}
      {/* {state.self ? (
				<div className="diinco-chat-absolute-container">
					<Chat
						main
						chats={state.chats}
						onMainClick={(value) => {
							setOpenChats([...openChats, value]);
						}}
						updateChats={(chats) => {
							console.log("UPDATING", chats);
							dispatch({
								type: "UPDATE_CHATS",
								payload: { chats },
							});
						}}
					/>
					{console.log("OPEN", openChats)}
					{openChats.map((value, i) => {
						console.log({ value });
						return (
							<Chat
								key={"CHAT_TEMP_KEY_" + i}
								index={i}
								val={value}
								id={value.id}
								onDestroy={(index) => {
									setOpenChats(
										openChats.filter((x, i) => i !== index)
									);
								}}
								updateChats={(value) => {
									console.log("UPDATING", value);
									let chat_index = state.chats.findIndex(
										(chat) => chat.id === value.chatId
									);
									let chat_users =
										state.chats[chat_index].Users;
									let user_chat_index = chat_users.findIndex(
										(user) =>
											user.Chat_User.UserId ===
											state.self.id
									);
									console.log({ user_chat_index });
									chat_users[
										user_chat_index
									].Chat_User.remaining = 0;
									let tmp = state.chats;
									tmp[chat_index].Users = chat_users;
									console.log({ tmp });
									dispatch({
										type: "UPDATE_CHATS",
										payload: { chats: [...tmp] },
									});
								}}
							/>
						);
					})}
				</div>
			) : null} */}
    </Context.Provider>
  )
}

Auth.defaultProps = {
  children: null
}

Auth.propTypes = {
  children: PropTypes.node
}

export default Auth
