import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import './styles/style.css'
import Dropzone from 'react-dropzone'

import imgIcon from './assets/file.svg'
import xIcon from './assets/x.svg'

import { LanguageContext } from '../../context/language/context'
import { message } from '../../functions/validation.functions'
import { Controller } from 'react-hook-form'
import './styles/style.css'
import { IconButton } from '../IconButton'
import Typography from '../Typography'

export default function DropZoneFiles(props) {
  const {
    onChange,
    value,
    ContainerClasses,
    inputClasses,

    error,
    accept,
    maxFiles,
    icon,

    ...rest
  } = props

  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const [files, setFiles] = useState([])

  const handleDrop = (acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    )
    if (onChange) onChange(acceptedFiles)
  }

  const thumbs = files.map((file, i) => (
    <div className='uk-flex dc-file-item-container' key={i + 'document'}>
      <img className='dc-file-item-icon' src={imgIcon} alt='' />
      <Typography className='dc-file-item-text'>{file.name}</Typography>
      <button
        className='dc-buttonless'
        type='button'
        onClick={() => removeFile(i)}
      >
        <img className='dc-file-item-icon' src={xIcon} alt='' />
      </button>
    </div>
  ))

  /* Remove single items */
  const removeFile = (file) => {
    const newFiles = [...files]
    newFiles.splice(file, 1)
    setFiles(newFiles)
    if (onChange) onChange(newFiles)
  }

  useEffect(() => {
    if (value) setFiles(value)
  }, [value])

  return (
    <Dropzone
      onDrop={handleDrop}
      accept={accept}
      noClick={true}
      noKeyboard={true}
      maxFiles={maxFiles}
    >
      {({ getRootProps, getInputProps, open, fileRejections }) => (
        <section
          className={'dc-dropzone-container ' + ContainerClasses}
          {...rest}
        >
          <div {...getRootProps()}>
            <div
              className={[
                fileRejections.length > 0
                  ? 'dc-dropzone-files-rejected'
                  : 'dc-dropzone-files',
                inputClasses
              ].join(' ')}
              style={{ display: icon ? 'none' : 'block' }}
            >
              {files.length > 0 ? (
                <>
                  <input {...getInputProps()} />
                  <Typography
                    align={'center'}
                    className='dc-dropzone-files-info-text'
                  >
                    {lang.loadedFiles}
                  </Typography>
                  <ul>{thumbs}</ul>
                </>
              ) : fileRejections.length > 0 ? (
                <>
                  <input {...getInputProps()} />
                  <Typography align={'center'}>
                    {lang.onlyOnes} {maxFiles}
                    {lang.file}
                  </Typography>
                </>
              ) : (
                <>
                  <input {...getInputProps()} />
                  <img src={imgIcon} alt='' />
                  <Typography align={'center'}> {lang.drop} </Typography>
                </>
              )}
            </div>
            {error && (
              <div className='cj-error-message-DZCI'>
                <span data-k-icon='icon: warning; ratio: 0.6'></span>{' '}
                {error.message}
              </div>
            )}
            {icon ? (
              <IconButton icon='file' onClick={open} />
            ) : (
              <button
                type='button'
                className='dc-dropzone-files-button'
                onClick={open}
              >
                {lang.upload}
              </button>
            )}
          </div>
        </section>
      )}
    </Dropzone>
  )
}

DropZoneFiles.defaultProps = {
  maxFiles: 5,
  accept: '.doc, .pdf, .txt, .svg',
  ContainerClasses: '',
  inputClasses: ''
}

DropZoneFiles.propTypes = {
  maxFiles: PropTypes.number,
  accept: PropTypes.string,
  ContainerClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  icon: PropTypes.bool,
  error: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func
}

export const CDropZoneFiles = ({
  name,
  control,
  shouldUnregister,

  required,
  validate,
  setValueAs,
  disabled,
  deps,
  onChange: onChangeFromProps,
  useCustomOnChange,

  ...rest
}) => {
  const languageContext = useContext(LanguageContext)

  var registerOptions = {
    required: {
      value: required,
      message: message(languageContext.userLanguage, 'required')
    },
    validate: validate,
    setValueAs: setValueAs,
    disabled: disabled,
    deps: deps
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={registerOptions}
      shouldUnregister={shouldUnregister}
      render={({
        field: { onChange, onBlur, value, name },
        fieldState: { error }
      }) => {
        return (
          <DropZoneFiles
            value={value}
            onChange={(e) => {
              onChange(e)
              if (onChangeFromProps) {
                if (useCustomOnChange) onChange(onChangeFromProps(e))
                else onChangeFromProps(e)
              }
            }}
            onBlur={onBlur}
            error={error}
            name={name}
            {...rest}
          />
        )
      }}
    />
  )
}

CDropZoneFiles.defaultProps = {
  shouldUnregister: false,

  useCustomOnChange: false,

  maxLength: null,
  minLength: null,
  required: false
}

CDropZoneFiles.propTypes = {
  maxFiles: PropTypes.number,
  accept: PropTypes.string,

  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  shouldUnregister: PropTypes.bool,
  required: PropTypes.bool,
  useCustomOnChange: PropTypes.bool,
  onChange: PropTypes.func,
  validate: PropTypes.object,
  setValueAs: PropTypes.any,
  disabled: PropTypes.bool,
  deps: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}
