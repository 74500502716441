import React, { useContext, useState, useEffect, useRef } from 'react'
import './styles/style.css'
import EditIconWhite from './assets/edit_white.svg'
import imgIcon from './assets/upload-img.svg'
import fileIcon from './assets/file.svg'
import deleteIcon from './assets/delete_white.svg'
import { format } from 'date-fns'
import { Modal } from 'react-responsive-modal'
import { _handleErrorMessage } from '../../functions/error.functions'
import { serialize } from '../../functions/validation.functions'

import { Link, useParams } from 'react-router-dom'
import _Utils from '../../context/utils'
import _Auth from '../../context/auth'
import Folder from './assets/file.svg'
import Event from './assets/event.svg'
import { useHistory } from 'react-router'

import { CTextInput } from '../../components/TextInput'
import { useForm } from 'react-hook-form'
import Button from '../../components/Button'
import ImageThumbsContainer from '../../components/ImageThumbsContainer'
import CustomGallery from '../../components/CustomGallery'

import { LanguageContext } from '../../context/language/context'
import { serverValidation } from '../../functions/validation.functions'
import Typography from '../../components/Typography'

import Avatar from '../../components/Avatar'
import StandardView from '../../layouts/StandardView'
import Divider from '../../components/Divider'
import { CSelectInput } from '../../components/SelectInput'
import PlansModal from '../../components/PlansModal'
import { IconButton } from '../../components/IconButton'
import { compressFilesInFormData } from '../../functions/files.functions'
import PropTypes from 'prop-types'

export default function Prospect({ type }) {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const { token, self, hasPermission, hasRole, reloadData } = useContext(
    _Auth.Context
  )
  let { id } = useParams()
  const { toggleLoader, enqueueNotification } = useContext(_Utils.Context)
  const [prospect, setProspect] = useState(null)
  const [nextDate, setNextDate] = useState(null)
  const [seeDate, setSeedate] = useState(null)
  const [events, setEvents] = useState(null)
  const history = useHistory()

  useEffect(() => {
    if (token) {
      loadData()
    }
  }, [token])

  async function loadData() {
    await toggleLoader(true)
    try {
      fetch(
        process.env.REACT_APP_API_URL +
          'prospects/' +
          id +
          '?' +
          new URLSearchParams({
            type
          }),
        {
          method: 'GET',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then((response) => {
          toggleLoader(false)
          return response.json()
        })
        .then((res) => {
          reset({
            title: res.details.prospect.title,
            description: res.details.prospect.description
          })
          var eventOption = []
          if (res.details.nextDate && res.details.nextDate.length > 0) {
            setNextDate(res.details.nextDate[0])
            res.details.nextDate.forEach((event) => {
              eventOption.push({
                value: event.id,
                label:
                  format(new Date(event.date_start), 'dd/MM/yyyy HH:mm') +
                  ' ' +
                  event.title
              })
            })
          }
          setProspect(res.details.prospect)
          res.details.prospect.Events.forEach((event) => {
            eventOption.push({
              value: event.id,
              label:
                format(new Date(event.date_start), 'dd/MM/yyyy HH:mm') +
                ' ' +
                event.title
            })
          })
          eventOption.unshift({
            value: null,
            label: lang.noDate
          })
          setEvents(eventOption)
        })
        .catch(() => {
          toggleLoader(false)
          enqueueNotification(
            languageContext.dictionary['somethingWrong'],
            'error'
          )
        })
    } catch (error) {
      toggleLoader(false)
      enqueueNotification(languageContext.dictionary['somethingWrong'], 'error')
    }
    toggleLoader(false)
  }

  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])

  const [disabledButton, setDisabledButton] = useState(false)
  const [disabledButtonNote, setDisabledButtonNote] = useState(false)
  const [disabledButtonEvent, setDisabledButtonEvent] = useState(false)
  const [filesError, setFilesError] = useState(null)

  const [files, setFiles] = useState([])
  const [images, setImages] = useState([])
  const [current, setCurrent] = useState([])
  const [documents, setDocuments] = useState([])
  const [oldDocuments, setOldDocuments] = useState([])
  const [toDelete, setToDelete] = useState(null)
  const [value] = useState(new Date())
  const defaultValues = {
    title: '',
    description: '',
    time: format(new Date(value), 'HH:mm'),
    endTime: format(new Date(value), 'HH:mm'),
    date: format(new Date(value), 'yyyy-MM-dd')
  }

  const myRef = useRef(null)
  const [open, setOpen] = useState(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)
  //Edit prospect modal
  const [openEditProspect, setOpenEditProspect] = useState(false)
  const onOpenEditProspect = () => setOpenEditProspect(true)
  const onCloseEditProspect = () => setOpenEditProspect(false)

  const [openDeleteProspect, setOpenDeleteProspect] = useState(false)
  const onOpenDeleteProspect = () => setOpenDeleteProspect(true)
  const onCloseDeleteProspect = () => setOpenDeleteProspect(false)
  //Add note modal
  const [openNote, setOpenNote] = useState(false)
  function onOpenNote() {
    setOpenNote(true)
  }
  function onCloseNote() {
    setOpenNote(false)
    resetNote()
    setFiles([])
    setDocuments([])
  }
  //Edit note modal
  const [openEditNote, setOpenEditNote] = useState(false)
  function onOpenEditNote(note) {
    setFiles([])
    setDocuments([])
    setOldDocuments([])
    let imgs = []
    note.Images.forEach((image) => {
      imgs.push(image.url)
    })
    setOldDocuments(note.Documents)
    let cita = {
      value: note.Event?.id ?? null,
      label: note.Event?.title
        ? format(new Date(note.Event?.date_start), 'dd/MM/yyyy HH:mm') +
          ' ' +
          note.Event?.title
        : lang.noDate
    }
    resetEditNote({
      title: note.title || '',
      description: note.body || '',
      event: cita,
      oldImages: imgs || [],
      id: note.id
    })
    setOpenEditNote(true)
  }
  function onCloseEditNote() {
    setOpenEditNote(false)
    resetEditNote()
    setFiles([])
    setDocuments([])
  }
  //Add event modal
  const [openEvent, setOpenEvent] = useState(false)
  const onOpenEvent = () => setOpenEvent(true)
  const onCloseEvent = () => setOpenEvent(false)

  //See gallery modal
  const [openGallery, setOpenGallery] = useState(false)
  function onOpenGallery(images, image) {
    let imgs = []
    for (let i = 0; i < images.length; i++) {
      let element = []
      element.url = process.env.REACT_APP_API_URL + images[i].url
      imgs.push(element)
    }

    setImages(imgs)
    setCurrent(image)
    setOpenGallery(true)
  }
  function onCloseGallery() {
    setOpenGallery(false)
  }

  //Delete something
  const [openDelete, setOpenDelete] = useState(false)
  function onOpenDelete(id) {
    setToDelete(id)
    setOpenDelete(true)
  }
  function onCloseDelete() {
    setToDelete(null)
    setOpenDelete(false)
  }

  const [futureButton, setFutureButton] = useState(false)

  //see date modal
  const [openDate, setOpenDate] = useState(false)
  function onOpenDate(date, future) {
    if (future) setFutureButton(true)
    else setFutureButton(false)
    setSeedate(date)
    onCloseModal(false)
    setOpenDate(true)
  }
  function onCloseDate() {
    setOpenDate(false)
  }

  const { reset, control, setError, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: '',
      description: ''
    }
  })

  const {
    reset: resetEvent,
    control: controlEvent,
    setError: setErrorEvent,
    handleSubmit: handleSubmitEvent
  } = useForm({
    mode: 'onChange',
    defaultValues
  })

  const {
    reset: resetNote,
    control: controlNote,
    setError: setErrorNote,
    handleSubmit: handleSubmitNote
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: '',
      description: '',
      event: ''
    }
  })

  const {
    getValues: getNoteValues,
    setValue: setNoteValue,
    reset: resetEditNote,
    control: controlEditNote,
    setError: setErrorEditNote,
    handleSubmit: handleEditNote
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: '',
      description: '',
      event: '',
      oldImages: [],
      oldDocuments: [],
      id: null
    }
  })

  async function onSubmit(data) {
    if (prospect.Project && prospect.Project.id) {
      return
    }

    await toggleLoader(true)
    setDisabledButton(true)

    try {
      data = { ...data, prospectId: id, type }
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'prospects/' + id,
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }
      )

      serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        async (dataResponse) => {
          enqueueNotification(languageContext.dictionary['success'], 'success')
          await toggleLoader(false)
          onCloseEditProspect()
          setDisabledButton(false)

          reset({
            title: dataResponse.details.prospect.title,
            description: dataResponse.details.prospect.description
          })

          setProspect(dataResponse.details.prospect)
        },
        async (e) => {
          await toggleLoader(false)
          setDisabledButton(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, e.error.details),
            'error'
          )
        }
      )
    } catch (error) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
      await toggleLoader(false)
      setDisabledButton(false)
    }
  }

  async function onSubmitNote(data) {
    if (prospect.Project && prospect.Project.id) {
      return
    }

    await toggleLoader(true)
    setDisabledButtonNote(true)

    data = {
      ...data,
      files: files,
      documents: documents,
      type
    }
    let formData = serialize(data, {
      dotsForObjectNotation: true,
      noArrayNotation: true,
      noFilesWithArrayNotation: true
    })

    formData = await compressFilesInFormData(formData, 'files')

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'prospects/' + id + '/notes',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data'
          },
          body: formData
        }
      )

      serverValidation(
        response,
        setErrorNote,
        languageContext.userLanguage,
        async (dataResponse) => {
          enqueueNotification(languageContext.dictionary['success'], 'success')
          await toggleLoader(false)
          onCloseNote()
          setDisabledButtonNote(false)
          resetNote()
          setFilesError(null)
          setProspect(dataResponse.details.prospect)
          reloadData()
        },
        async (e) => {
          if (e?.error?.code === 8011) {
            onOpenPlansModal()
          }
          await toggleLoader(false)
          setDisabledButtonNote(false)
          e?.error?.details?.errors?.forEach((error) => {
            if (error.param === 'files' || error.param === 'documents') {
              setFilesError(error.msg[languageContext.userLanguage])
            }
          })
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, e.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      setDisabledButtonNote(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  async function onEditNote(data) {
    if (prospect.Project && prospect.Project.id) {
      return
    }

    await toggleLoader(true)
    setDisabledButtonNote(true)
    let oldDocs = []
    if (oldDocuments && oldDocuments.length > 0) {
      oldDocuments.forEach((doc) => {
        oldDocs.push(doc.url)
      })
    }
    data = {
      ...data,
      files: files,
      documents: documents,
      prospectId: id,
      oldDocuments: oldDocs,
      type
    }
    let formData = serialize(data, {
      dotsForObjectNotation: true,
      noArrayNotation: true,
      noFilesWithArrayNotation: true
    })

    formData = await compressFilesInFormData(formData, 'files')

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'prospects/' + id + '/editnote',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data'
          },
          body: formData
        }
      )

      serverValidation(
        response,
        setErrorEditNote,
        languageContext.userLanguage,
        async (dataResponse) => {
          enqueueNotification(languageContext.dictionary['success'], 'success')
          await toggleLoader(false)
          onCloseEditNote()
          setDisabledButtonNote(false)
          resetEditNote()
          setFilesError(null)
          setProspect(dataResponse.details.prospect)
          reloadData()
        },
        async (e) => {
          if (e?.error?.code === 8011) {
            onOpenPlansModal()
          }
          await toggleLoader(false)
          setDisabledButtonNote(false)
          e?.error?.details?.errors?.forEach((error) => {
            if (error.param === 'files' || error.param === 'documents') {
              setFilesError(error.msg[languageContext.userLanguage])
            }
          })
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, e.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      setDisabledButtonNote(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  async function onDeleteNote() {
    if (prospect.Project && prospect.Project.id) {
      return
    }

    let noteId = toDelete
    if (!id) enqueueNotification(languageContext.somethingWrong, 'error')
    await toggleLoader(true)
    setDisabledButton(true)
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'prospects/' + id + '/deleteNote',
        {
          method: 'DELETE',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: noteId,
            prospectId: id,
            type
          })
        }
      )
      serverValidation(
        response,
        null,
        languageContext.userLanguage,
        async (dataResponse) => {
          enqueueNotification(languageContext.dictionary['success'], 'success')
          await toggleLoader(false)
          onCloseDelete()
          setDisabledButton(false)
          setProspect(dataResponse.details.prospect)
          reloadData()
        },
        async (e) => {
          await toggleLoader(false)
          setDisabledButton(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, e.error),
            'error'
          )
        }
      )
    } catch (error) {
      setDisabledButton(false)
      await toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  function loadImgs() {
    let input = document.getElementById('fotos')
    if (input.files) {
      if (input.files.length > 5) {
        enqueueNotification(lang.maxError, 'error')
        return false
      }
      let arr = []
      for (var i = 0; i < input.files.length; i++) {
        arr.push(input.files[i])
        arr[i].path = input.files[i].name
      }
      setFiles(arr)
    }
  }

  function loadFiles() {
    let input = document.getElementById('documentos')
    if (input.files) {
      let arr = []
      for (var i = 0; i < input.files.length; i++) {
        arr.push(input.files[i])
        arr[i].path = input.files[i].name
      }
      setDocuments(arr)
    }
  }

  function loadImgsEdit() {
    let input = document.getElementById('fotosEdit')
    if (input.files) {
      if (getNoteValues('oldImages').length + input.files.length > 5) {
        enqueueNotification(lang.maxError, 'error')
        return false
      }
      let arr = []
      for (var i = 0; i < input.files.length; i++) {
        arr.push(input.files[i])
        arr[i].path = input.files[i].name
      }
      setFiles(arr)
    }
  }

  function loadFilesEdit() {
    let input = document.getElementById('documentosEdit')
    if (input.files) {
      let arr = []
      for (var i = 0; i < input.files.length; i++) {
        arr.push(input.files[i])
        arr[i].path = input.files[i].name
      }
      setDocuments(arr)
    }
  }

  async function onSubmitEvent(data) {
    if (prospect.Project && prospect.Project.id) {
      return
    }

    setDisabledButtonEvent(true)
    await toggleLoader(true)
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'prospects/' + id + '/events',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...data,
            type
          })
        }
      )
      await serverValidation(
        response,
        setErrorEvent,
        languageContext.userLanguage,
        async (data) => {
          resetEvent()
          onCloseEvent()
          setProspect(data.details.prospect)
          if (data.details.nextDate && data.details.nextDate.length > 0) {
            setNextDate(data.details.nextDate[0])
          }
          await toggleLoader(false)
          setDisabledButtonEvent(false)
          enqueueNotification(languageContext.dictionary['success'], 'success')
          var eventOption = []
          if (data.details.nextDate && data.details.nextDate.length > 0) {
            setNextDate(data.details.nextDate[0])
            data.details.nextDate.forEach((event) => {
              eventOption.push({
                value: event.id,
                label:
                  format(new Date(event.date_start), 'dd/MM/yyyy HH:mm') +
                  ' ' +
                  event.title
              })
            })
          }
          data.details.prospect.Events.forEach((event) => {
            eventOption.push({
              value: event.id,
              label:
                format(new Date(event.date_start), 'dd/MM/yyyy HH:mm') +
                ' ' +
                event.title
            })
          })
          eventOption.unshift({
            value: null,
            label: lang.noDate
          })
          setEvents(eventOption)
          reloadData()
        },
        async (error) => {
          setDisabledButtonEvent(false)
          await toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      setDisabledButtonEvent(false)
      await toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  async function submitDeleteProspect() {
    if (prospect.Project && prospect.Project.id) {
      return
    }

    await toggleLoader(true)
    setDisabledButton(true)
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'prospects/' + id,
        {
          method: 'DELETE',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      serverValidation(
        response,
        null,
        languageContext.userLanguage,
        async () => {
          enqueueNotification(languageContext.dictionary['success'], 'success')
          await toggleLoader(false)
          onCloseDeleteProspect()
          setDisabledButton(false)
          history.push('/Crm')
        },
        async (e) => {
          await toggleLoader(false)
          setDisabledButton(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, e.error),
            'error'
          )
        }
      )
    } catch (error) {
      setDisabledButton(false)
      await toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }
  const [openPlansModal, setOpenPlansModal] = useState(false)
  function onOpenPlansModal() {
    setOpenPlansModal(true)
  }

  function onClosePlansModal() {
    setOpenPlansModal(false)
  }

  const [noteToSwitch, setNoteToSwitch] = useState(null)
  const [sideToSwitch, setSideToSwitch] = useState(null)
  const [openSwitchModal, setOpenSwitchModal] = useState(false)

  async function onOpenSwitchModal(note, side) {
    setNoteToSwitch(note)
    setSideToSwitch(side)
    setOpenSwitchModal(true)
  }

  async function onCloseSwitchModal() {
    setNoteToSwitch(null)
    setSideToSwitch(null)
    setOpenSwitchModal(false)
  }

  async function switchNote() {
    if (prospect.Project && prospect.Project.id) {
      return
    }

    await toggleLoader(true)
    if (!noteToSwitch || !sideToSwitch) {
      await toggleLoader(false)
      enqueueNotification(languageContext.dictionary.somethingWrong, 'error')
      return false
    }

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'prospects/' + id + '/switchNote',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            noteId: noteToSwitch,
            side: sideToSwitch,
            type
          })
        }
      )
      serverValidation(
        response,
        null,
        languageContext.userLanguage,
        async (dataResponse) => {
          enqueueNotification(languageContext.dictionary['success'], 'success')
          await toggleLoader(false)
          setProspect(dataResponse.details.prospect)
          onCloseSwitchModal()
          reloadData()
        },
        async (e) => {
          await toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, e.errors.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  const [openCancel, setOpenCancel] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(null)

  function onOpenModalCancel(id) {
    setSelectedEvent(id)
    setOpenCancel(true)
  }

  async function submitCancelEvent() {
    if (prospect.Project && prospect.Project.id) {
      return
    }

    await toggleLoader(true)
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'events/delete',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            eventId: selectedEvent
          })
        }
      )
      serverValidation(
        response,
        null,
        languageContext.userLanguage,
        async () => {
          enqueueNotification(languageContext.dictionary['success'], 'success')
          await toggleLoader(false)
          setOpenCancel(false)
          history.go(0)
        },
        async (e) => {
          await toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, e.errors.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  // EDITAR EVENTOS ------------------------------------------------------------------------------------>

  //Add event modal
  const [openEventEdit, setOpenEventEdit] = useState(false)
  function onOpenEventEdit(date) {
    resetEventEdit({
      id: date.id,
      date: format(new Date(date.date_start), 'yyyy-MM-dd'),
      title: date.title,
      description: date.body,
      time: format(new Date(date.date_start), 'HH:mm'),
      endTime: format(new Date(date.date_end), 'HH:mm')
    })
    setOpenEventEdit(true)
  }

  function onCloseEventEdit() {
    resetEventEdit()
    setOpenEventEdit(false)
  }

  const defaultValuesEdit = {
    title: '',
    description: '',
    time: format(new Date(value), 'HH:mm'),
    endTime: format(new Date(value), 'HH:mm'),
    date: format(new Date(value), 'dd/mm/yyyy')
  }

  const {
    reset: resetEventEdit,
    control: controlEventEdit,
    setError: setErrorEventEdit,
    handleSubmit: handleSubmitEventEdit
  } = useForm({
    mode: 'onChange',
    defaultValuesEdit
  })

  async function onSubmitEventEdit(data) {
    if (prospect.Project && prospect.Project.id) {
      return
    }

    setDisabledButtonEvent(true)
    await toggleLoader(true)
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'events/update',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...data,
            projectId: undefined,
            date: data.date
          })
        }
      )
      await serverValidation(
        response,
        setErrorEventEdit,
        languageContext.userLanguage,
        async () => {
          onCloseEventEdit()
          enqueueNotification(languageContext.dictionary['success'], 'success')
          history.go(0)
        },
        async (error) => {
          setDisabledButtonEvent(false)
          await toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      setDisabledButtonEvent(false)
      await toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  return (
    <StandardView
      title={type === 'prospect' ? lang.expedient : lang.proposal}
      links={[
        ...(hasRole('professional')
          ? [
              {
                link: '/Dashboard',
                title: languageContext.dictionary.dashboard
              },
              {
                link: '/Crm',
                title: lang.process
              }
            ]
          : []),
        ...(hasRole('client')
          ? [
              {
                link: '/Dashboard',
                title: languageContext.dictionary.dashboard
              }
            ]
          : [])
      ]}
    >
      <div className='uk-container'>
        <div
          className={
            'uk-margin-bottom ' +
            (type === 'proposal' ? 'proposal-container' : '')
          }
        >
          <div data-uk-grid>
            <div className='uk-width-expand'>
              <div
                className='uk-container oski-prospect-titles-container'
                ref={myRef}
              >
                <Typography
                  color={
                    languageContext.theme === 'light' ? 'primary' : 'secondary'
                  }
                  variant='h6'
                >
                  {prospect && prospect.title}
                </Typography>
                {prospect?.createdAt && (
                  <Typography variant={'body2'}>
                    {format(
                      new Date(prospect.createdAt),
                      'dd/MM/yyyy HH:mm aaaa'
                    )}
                  </Typography>
                )}
              </div>

              <Typography>{prospect && prospect.description}</Typography>

              {/* USERS */}
              {prospect &&
                prospect.User_Prospects &&
                prospect.User_Prospects.length > 0 &&
                prospect.User_Prospects.map((user_prospect, index) => (
                  <div
                    className='uk-flex uk-flex-middle uk-margin-top uk-margin-bottom'
                    key={index}
                  >
                    <Avatar
                      size={'large'}
                      src={user_prospect.User.image}
                      alt={user_prospect.User.name}
                      border={user_prospect.User?.Roles?.some(
                        (uRole) => uRole?.name === 'professional'
                      )}
                      disableToolTip
                      style={{ marginRight: '15px' }}
                      link={
                        user_prospect.User?.Roles?.some(
                          (uRole) => uRole?.name === 'professional'
                        ) &&
                        user_prospect.User &&
                        user_prospect.User.nickname
                          ? '/Profile/' + user_prospect.User.nickname
                          : null
                      }
                    />
                    <div>
                      {user_prospect.User?.Roles?.some(
                        (uRole) => uRole?.name === 'professional'
                      ) &&
                      user_prospect.User &&
                      user_prospect.User.nickname ? (
                        <Link to={'/Profile/' + user_prospect.User.nickname}>
                          <Typography color={'secondary'}>
                            {user_prospect.User.name}{' '}
                            {user_prospect.User.id === self.id &&
                              '(' + languageContext.dictionary.you + ')'}
                          </Typography>
                        </Link>
                      ) : (
                        <Typography>
                          {user_prospect.User.name}{' '}
                          {user_prospect.User.id === self.id &&
                            '(' + languageContext.dictionary.you + ')'}
                        </Typography>
                      )}
                      {user_prospect.User &&
                        user_prospect.User.Roles.length &&
                        user_prospect.User?.Roles[0]?.name ===
                          'professional' && (
                          <>
                            <Typography variant={'body2'}>
                              {lang.professional}
                            </Typography>
                            <Typography variant={'body2'}>
                              {user_prospect.User?.contact_phone}
                            </Typography>
                            <Typography variant={'body2'}>
                              {user_prospect.User?.contact_email}
                            </Typography>
                          </>
                        )}
                    </div>
                  </div>
                ))}
              {type === 'proposal' &&
                hasPermission('prospect-update', 'or', id, 'prospect') && (
                  <div className='uk-flex uk-flex-between'>
                    {!(prospect?.Project && prospect.Project.id) &&
                      hasPermission('prospect-update', 'or', id, 'prospect') &&
                      hasPermission(
                        'prospect-delete',
                        'or',
                        id,
                        'prospect'
                      ) && (
                        <div
                          className='uk-width-1 uk-flex'
                          style={{
                            justifyContent: 'flex-start'
                          }}
                        >
                          <Button
                            variant='text'
                            onClick={() => history.push('/Prospect/' + id)}
                            buttonStyle={{
                              paddingLeft: 0,
                              paddingRight: 0
                            }}
                            disableMargin
                          >
                            {lang.goExpedient}
                          </Button>
                        </div>
                      )}
                    <Link
                      to={
                        prospect?.Project && prospect?.Project?.id
                          ? '/Projects/' + prospect?.Project?.id
                          : {
                              pathname: '/Projects/Create',
                              state: {
                                prospect: {
                                  from: 'proposal',
                                  id: id,
                                  title: prospect?.title,
                                  description: prospect?.description,
                                  users: prospect?.User_Prospects
                                }
                              }
                            }
                      }
                    >
                      <Button>
                        {prospect?.Project && prospect?.Project?.id
                          ? lang.seeProject
                          : lang.createProject}
                      </Button>
                    </Link>
                  </div>
                )}
            </div>
            <div className='uk-width-auto'>
              {!(prospect?.Project && prospect.Project.id) &&
                type !== 'proposal' &&
                hasPermission('prospect-update', 'or', id, 'prospect') &&
                hasPermission('prospect-delete', 'or', id, 'prospect') && (
                  <div
                    className='uk-width-1 uk-flex'
                    style={{ justifyContent: 'flex-end' }}
                  >
                    <Button
                      variant='text'
                      onClick={() =>
                        history.push('/Prospect/' + id + '/Proposal')
                      }
                      buttonStyle={{
                        paddingLeft: 0,
                        paddingRight: 0
                      }}
                      disableMargin
                    >
                      {lang.goProposal}
                    </Button>
                  </div>
                )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  gap: 0
                }}
              >
                {!(prospect?.Project && prospect.Project.id) &&
                  hasPermission('prospect-update', 'or', id, 'prospect') && (
                    // <img
                    //     uk-tooltip={lang.editProspect}
                    //     src={EditIconWhite}
                    //     className={
                    //         languageContext.theme === "dark"
                    //             ? "oski-edit-icon"
                    //             : "oski-edit-icon oski-icons-light"
                    //     }
                    //     onClick={() => onOpenEditProspect()}
                    // />
                    <IconButton
                      icon='edit'
                      data-uk-tooltip={lang.editProspect}
                      onClick={() => onOpenEditProspect()}
                    />
                  )}
                {!(prospect?.Project && prospect.Project.id) &&
                  hasPermission('prospect-delete', 'or', id, 'prospect') && (
                    // <img
                    //     uk-tooltip={lang.deleteProspect}
                    //     src={deleteIcon}
                    //     className={
                    //         languageContext.theme === "dark"
                    //             ? "oski-edit-icon"
                    //             : "oski-edit-icon oski-icons-light"
                    //     }
                    //     onClick={() =>
                    //         onOpenDeleteProspect()
                    //     }
                    // />
                    <IconButton
                      data-uk-tooltip={lang.deleteProspect}
                      icon='delete'
                      variant='borderless'
                      onClick={() => onOpenDeleteProspect()}
                    />
                  )}
              </div>

              <Modal
                container={myRef.current}
                open={openDeleteProspect}
                onClose={onCloseDeleteProspect}
                center
                closeIcon={<IconButton icon='close' component='div' />}
                classNames={{
                  modal: 'customModalPhase prospect-modal-edit'
                }}
              >
                <Typography
                  color={'secondary'}
                  variant='h6'
                  style={{ textTransform: 'uppercase' }}
                >
                  {lang.deleteProspect}
                </Typography>
                <Typography>{lang.sureToDelete}</Typography>
                <div className='uk-flex uk-flex-middle uk-flex-between uk-margin-large-top'>
                  <Button
                    onClick={onCloseDeleteProspect}
                    variant='contained'
                    color='grey'
                  >
                    {languageContext.dictionary.cancel}
                  </Button>
                  <Button
                    disabled={disabledButton}
                    onClick={submitDeleteProspect}
                    color='secondary'
                  >
                    {languageContext.dictionary.delete}
                  </Button>
                </div>
              </Modal>
              <Modal
                container={myRef.current}
                open={openEditProspect}
                onClose={onCloseEditProspect}
                center
                closeIcon={<IconButton icon='close' component='div' />}
                classNames={{
                  modal: 'customModalPhase prospect-modal-edit'
                }}
              >
                <Typography
                  color={'secondary'}
                  variant='h6'
                  style={{ textTransform: 'uppercase' }}
                >
                  {lang.edit}
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <CTextInput
                    label={languageContext.dictionary.title}
                    name='title'
                    control={control}
                    required
                    maxLength={2000}
                  />
                  <CTextInput
                    type='text'
                    label={languageContext.dictionary.desc}
                    name='description'
                    control={control}
                    maxLength={2000}
                  />
                  <Button
                    type='submit'
                    color='secondary'
                    disabled={disabledButton}
                    centered
                  >
                    {languageContext.dictionary['save']}
                  </Button>
                </form>
              </Modal>
            </div>
          </div>
        </div>

        {/* NEXT DATE */}
        {nextDate && (
          <div
            className='uk-flex'
            style={{ marginTop: 50, alignItems: 'center' }}
          >
            {/* <div className="oski-prospect-lastDate">
                        </div> */}
            <Typography variant={'subtitle'}>{lang.nextDate}</Typography>
            <Typography
              onClick={() => onOpenDate(nextDate, true)}
              style={{
                marginLeft: 5,
                cursor: 'pointer'
                // textDecoration: "underline",
              }}
            >
              {format(new Date(nextDate.date_start), 'dd/MM/yyyy HH:mm aaaa')}
            </Typography>
            {/* <div
                            className="oski-prospect-date"
                            onClick={() => onOpenDate(nextDate, true)}
                        ></div> */}
          </div>
        )}
        {/* MODAL TO SHOW DATES */}
        <Modal
          open={openDate}
          onClose={onCloseDate}
          center
          closeIcon={<IconButton icon='close' component='div' />}
          container={myRef.current}
          classNames={{ modal: 'customModalPhase' }}
        >
          <div className='uk-padding'>
            <div className='uk-flex uk-flex-middle'>
              <Typography variant={'h6'} color={'secondary'}>
                {languageContext.dictionary.title}:&nbsp;
              </Typography>
              <Typography>
                {seeDate && seeDate.title && seeDate.title}
              </Typography>
            </div>
            <div className='uk-flex uk-flex-middle'>
              <Typography variant={'h6'} color={'secondary'}>
                {lang.description}:&nbsp;
              </Typography>
              <Typography>{seeDate && seeDate.body && seeDate.body}</Typography>
            </div>
            <div className='uk-flex uk-flex-middle'>
              <Typography variant={'h6'} color={'secondary'}>
                {languageContext.dictionary.date}:&nbsp;
              </Typography>
              <Typography>
                {seeDate &&
                  seeDate.date_start &&
                  format(new Date(seeDate.date_start), 'dd/MM/yyyy')}
              </Typography>
            </div>
            <div className='uk-flex uk-flex-middle'>
              <Typography variant={'h6'} color={'secondary'}>
                {lang.horary}:&nbsp;
              </Typography>
              <Typography>
                {seeDate &&
                  seeDate.date_start &&
                  format(new Date(seeDate.date_start), 'HH:mm')}
                {seeDate &&
                  seeDate.date_end &&
                  ' - ' + format(new Date(seeDate.date_end), 'HH:mm')}
              </Typography>
            </div>
            {futureButton && (
              <div className='uk-flex uk-flex-center uk-width-1'>
                {hasPermission('prospect-update', 'or', id, 'prospect') && (
                  <div className='uk-width-1 uk-flex uk-flex-between uk-margin-top'>
                    <Button
                      onClick={() => onOpenModalCancel(seeDate.id)}
                      variant='contained'
                      color='grey'
                    >
                      {lang.cancelEvent}
                    </Button>
                    <Button
                      color='secondary'
                      onClick={() => onOpenEventEdit(seeDate)}
                    >
                      {lang.editEvent}
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </Modal>
        <Modal
          open={openCancel}
          onClose={() => setOpenCancel(false)}
          center
          closeIcon={<IconButton icon='close' component='div' />}
          classNames={{
            modal: 'calendarModal'
          }}
          container={myRef.current}
        >
          <Typography variant='h6' color='secondary' style={{ marginTop: 15 }}>
            {lang.sureToCancel}{' '}
          </Typography>
          <Typography style={{ marginBottom: 30, marginTop: 30 }}>
            {lang.irreversible}
          </Typography>
          <div className='uk-flex uk-width-1 uk-flex-between'>
            <Button
              type={'button'}
              onClick={() => setOpenCancel(false)}
              variant='contained'
              color='grey'
            >
              {languageContext.dictionary.cancel}
            </Button>
            {!(prospect?.Project && prospect.Project.id) && (
              <Button
                variant='contained'
                color='secondary'
                onClick={submitCancelEvent}
              >
                {languageContext.dictionary.accept}
              </Button>
            )}
          </div>
        </Modal>
        {/* LAST DATE */}
        {prospect && prospect.Events && prospect.Events.length > 0 && (
          <>
            <div
              className='uk-flex'
              style={
                nextDate
                  ? { marginTop: 15, alignItems: 'center' }
                  : { marginTop: 50, alignItems: 'center' }
              }
            >
              {/* <div className="oski-prospect-lastDate">
                            </div> */}
              <Typography variant={'subtitle'} disableMargin>
                {' '}
                {lang.lastDate}{' '}
              </Typography>
              <Typography
                disableMargin
                onClick={() => onOpenDate(prospect.Events[0])}
                style={{
                  marginLeft: 5,
                  // textDecoration: "underline",
                  cursor: 'pointer',
                  lineHeight: 'inherit'
                }}
              >
                {' '}
                {format(
                  new Date(prospect.Events[0].date_start),
                  'dd/MM/yyyy HH:mm aaaa'
                )}
              </Typography>
              {/* <div
                                className="oski-prospect-date"
                                onClick={() => onOpenDate(prospect.Events[0])}
                            ></div> */}
            </div>

            <div
              className='oski-prospect-history'
              onClick={() => onOpenModal()}
            >
              {' '}
              {lang.history}{' '}
            </div>

            <Modal
              open={open}
              onClose={onCloseModal}
              center
              closeIcon={<IconButton icon='close' component='div' />}
              container={myRef.current}
              classNames={{ modal: 'customModalPhase' }}
            >
              <div className='uk-padding'>
                {prospect.Events.map((event, index) => (
                  <div
                    className='oski-prospect-dates'
                    style={{ cursor: 'pointer' }}
                    key={index}
                    onClick={() => onOpenDate(event)}
                  >
                    {format(
                      new Date(event.date_start),
                      'dd/MM/yyyy HH:mm aaaa'
                    )}
                    {' ' + event.title}
                  </div>
                ))}
              </div>
            </Modal>
          </>
        )}
        <div
          className='uk-flex uk-flex-rigth'
          style={{ gap: 15, marginTop: 10 }}
        >
          {!(prospect?.Project && prospect.Project.id) &&
            hasPermission('event-create', 'or', id, 'prospect') && (
              <img
                data-uk-tooltip={lang.addEvent}
                src={Event}
                className={
                  languageContext.theme === 'dark'
                    ? 'oski-edit-icon oski-icons-dark'
                    : 'oski-edit-icon'
                }
                alt='icon'
                onClick={onOpenEvent}
              />
            )}
          {!(prospect?.Project && prospect.Project.id) && (
            // <img
            //     uk-tooltip={lang.addNote}
            //     src={add}
            //     onClick={() => onOpenNote()}
            //     style={{
            //         marginLeft: "auto",
            //         marginRight: 0,
            //         marginBottom: 15,
            //         cursor: "pointer",
            //     }}
            //     width={30}
            // />
            <IconButton
              onClick={() => onOpenNote()}
              data-uk-tooltip={lang.addNote}
              color='secondary'
              icon='create'
              style={{
                marginLeft: 'auto',
                marginRight: 0,
                marginBottom: 15,
                cursor: 'pointer'
              }}
            />
          )}
        </div>

        {/* NOTES */}
        {type === 'prospect' && (
          <Divider className='uk-margin-medium-bottom'></Divider>
        )}

        {prospect &&
          prospect.Notes &&
          prospect.Notes.length > 0 &&
          prospect.Notes.map((note, index) => (
            <div
              className={type === 'proposal' ? 'proposal-note' : ''}
              key={index}
            >
              <div className='oski-prospect-title-date oski-prospect-flex-between uk-flex uk-flex-top'>
                <div className='uk-flex uk-flex-middle' style={{ gap: 5 }}>
                  <Avatar
                    src={note.User.image}
                    alt={note.User.name}
                    size='medium'
                  />
                  <Typography
                    color={type === 'proposal' ? 'inherit' : 'secondary'}
                    variant='h6'
                  >
                    {note.title}
                  </Typography>
                </div>
                <div>
                  <Typography
                    color={type === 'proposal' ? 'inherit' : 'secondary'}
                    variant='h6'
                  >
                    {format(new Date(note.createdAt), 'dd/MM/yyyy HH:mm aaaa')}
                  </Typography>
                  {type === 'prospect' &&
                    hasPermission('note-update', 'or', id, 'prospect') && (
                      <div className='uk-flex uk-margin-small-top'>
                        <Button
                          onClick={() => onOpenSwitchModal(note.id, note.side)}
                          variant={
                            note.side === 'proposal' || note.side === 'both'
                              ? 'outlined'
                              : 'contained'
                          }
                          disabled={
                            note.side === 'proposal' || note.side === 'both'
                              ? true
                              : false
                          }
                        >
                          {note.side === 'proposal' || note.side === 'both'
                            ? lang.inProposal
                            : lang.sendProposal}
                        </Button>
                        <IconButton
                          icon='question'
                          data-uk-tooltip={'title: ' + lang.sideQuestion}
                          variant='borderless'
                          iconColor='secondary'
                          style={{
                            marginTop: '-25px'
                          }}
                        />
                        <Modal
                          open={openSwitchModal}
                          onClose={() => onCloseSwitchModal()}
                          center
                          closeIcon={
                            <IconButton icon='close' component='div' />
                          }
                          container={myRef.current}
                          classNames={{
                            modal: 'customModalPhase prospect-modal-edit'
                          }}
                        >
                          <Typography
                            align={'center'}
                            className='uk-margin-medium-top'
                          >
                            {lang.sureToSwitch}
                          </Typography>
                          <div className='uk-flex uk-width-1 uk-flex-between uk-flex-middle uk-margin-medium-top'>
                            <Button
                              onClick={() => onCloseSwitchModal()}
                              variant='text'
                            >
                              {languageContext.dictionary.cancel}
                            </Button>
                            {!(prospect?.Project && prospect.Project.id) && (
                              <Button
                                onClick={() => switchNote()}
                                color='secondary'
                              >
                                {languageContext.dictionary.send}
                              </Button>
                            )}
                          </div>
                        </Modal>
                      </div>
                    )}
                </div>
              </div>
              <div
                className={
                  type === 'prospect'
                    ? 'oski-prospect-note'
                    : 'oski-proposal-note'
                }
              >
                <Typography theme='light'>{note.body}</Typography>
              </div>
              {note.Event && (
                <div
                  className={
                    type === 'prospect'
                      ? 'uk-flex uk-flex-right oski-prospect-note'
                      : 'uk-flex uk-flex-right oski-proposal-note'
                  }
                >
                  <Typography theme='light' variant={'body2'}>
                    {lang.associatedDate +
                      format(
                        new Date(note.Event?.date_start),
                        'dd/MM/yyyy HH:mm'
                      )}
                  </Typography>
                </div>
              )}
              {note.Images && note.Images.length > 0 && (
                <div className='oski-note-imagesContainer'>
                  {note.Images.map((imageNote, indexImage) => (
                    <img
                      src={process.env.REACT_APP_API_URL + imageNote.url}
                      alt={imageNote.name}
                      key={indexImage}
                      onClick={() => onOpenGallery(note.Images, indexImage)}
                      className='oski-note-image'
                    />
                  ))}
                </div>
              )}
              <div className='uk-flex uk-flex-between'>
                {note.Documents && note.Documents.length > 0 ? (
                  <button
                    style={{
                      backgroundColor: 'transparent',
                      border: 'none'
                    }}
                    type='button'
                  >
                    <img
                      src={Folder}
                      alt='folder'
                      className={
                        languageContext.theme === 'dark'
                          ? 'oski-edit-icon'
                          : 'oski-file-light oski-edit-icon'
                      }
                    />
                  </button>
                ) : (
                  <div></div>
                )}
                <div
                  className='oski-documents-dropdown'
                  data-uk-dropdown='mode: click;pos: bottom-left'
                >
                  {note.Documents &&
                    note.Documents.length > 0 &&
                    note.Documents.map((document, index) => (
                      <a
                        key={index}
                        href={process.env.REACT_APP_API_URL + document.url}
                        download
                        target='_blank'
                        className='oski-documents-row'
                        rel='noreferrer'
                      >
                        {document.title}
                      </a>
                    ))}
                </div>

                {note.UserId === self?.id && (
                  <div className='uk-flex' style={{ gap: 15 }}>
                    {!(prospect?.Project && prospect.Project.id) && (
                      <img
                        data-uk-tooltip={lang.editNote}
                        src={EditIconWhite}
                        alt='edit'
                        className={
                          languageContext.theme === 'dark'
                            ? 'oski-edit-icon'
                            : 'oski-edit-icon oski-icons-light'
                        }
                        onClick={() => onOpenEditNote(note)}
                      />
                    )}
                    {!(prospect?.Project && prospect.Project.id) && (
                      <img
                        data-uk-tooltip={lang.deleteNote}
                        src={deleteIcon}
                        alt='delete'
                        className={
                          languageContext.theme === 'dark'
                            ? 'oski-edit-icon'
                            : 'oski-edit-icon oski-icons-light'
                        }
                        onClick={() => onOpenDelete(note.id)}
                      />
                    )}
                  </div>
                )}
              </div>

              {type === 'prospect' && (
                <Divider className='uk-margin-top uk-margin-medium-bottom' />
              )}
            </div>
          ))}

        {/* modal with gallery */}
        <Modal
          open={openGallery}
          onClose={onCloseGallery}
          center
          closeIcon={<IconButton icon='close' component='div' />}
          container={myRef.current}
          classNames={{ modal: 'customModalGalleryProject' }}
        >
          <CustomGallery images={images} navigation={true} current={current} />
        </Modal>

        {/* modal to add note */}
        <Modal
          open={openNote}
          onClose={onCloseNote}
          center
          closeIcon={<IconButton icon='close' component='div' />}
          container={myRef.current}
          classNames={{
            modal: 'customModalPhase prospect-modal-edit'
          }}
        >
          <Typography variant='h6' color={'secondary'}>
            {languageContext.dictionary.addNote}{' '}
          </Typography>
          <form onSubmit={handleSubmitNote(onSubmitNote)} noValidate>
            <CTextInput
              type='text'
              label={languageContext.dictionary.title}
              name='title'
              control={controlNote}
              maxLength={45}
              required
            />
            <CTextInput
              type='textarea'
              label={languageContext.dictionary.desc}
              name='description'
              control={controlNote}
              rows={2}
              maxLength={2000}
              charCount
            />

            <CSelectInput
              name='event'
              control={controlNote}
              label={lang.date}
              options={events}
            />

            <div className='uk-grid uk-flex oski-flex-end oski-align-center'>
              <div data-uk-tooltip={'title: ' + lang.uploadImages}>
                <input
                  accept='image/*'
                  id='fotos'
                  type='file'
                  multiple
                  style={{ display: 'none' }}
                  onChange={loadImgs}
                />
                <label htmlFor='fotos'>
                  <img
                    src={imgIcon}
                    height='20px'
                    alt='img'
                    className={
                      languageContext.theme === 'dark'
                        ? 'oski-pointer'
                        : 'oski-pointer oski-icons-light'
                    }
                  />
                </label>
              </div>
              <div data-uk-tooltip={'title: ' + lang.uploadFiles}>
                <input
                  accept='application/pdf, application/txt, application/svg, application/doc'
                  id='documentos'
                  type='file'
                  multiple
                  style={{ display: 'none' }}
                  onChange={loadFiles}
                />
                <label htmlFor='documentos'>
                  <img
                    src={fileIcon}
                    height='45px'
                    alt='file'
                    className={
                      languageContext.theme === 'dark'
                        ? 'oski-pointer'
                        : 'oski-pointer oski-icons-light'
                    }
                  />
                </label>
              </div>
            </div>

            {files && files.length > 0 && (
              <ImageThumbsContainer
                thumbs={files}
                control={control}
                containerStyle='diinco-thumbs-container-project-form'
                thumbStyle='diinco-thumbs-style-project-form'
                deleteButtonStyle='diinco-thumbs-delete-project-form'
                handleRemove={(event, file, index) => {
                  setFiles(files.filter((o, i) => i !== index))
                }}
              />
            )}

            <div className='uk-grid'>
              {documents &&
                documents.length > 0 &&
                documents.map((document, index) => (
                  <div
                    className='uk-width-1 oski-document-text uk-flex uk-flex-between uk-flex-middle'
                    key={index}
                  >
                    <div>{document.name}</div>
                    <div
                      style={{
                        cursor: 'pointer',
                        fontSize: '15px'
                      }}
                      onClick={() =>
                        setDocuments(documents.filter((o, i) => i !== index))
                      }
                    >
                      x
                    </div>
                  </div>
                ))}
            </div>

            <div
              style={{
                color: 'rgb(201, 70, 67)',
                fontFamily: 'OpenSans',
                fontSize: '12px',
                textAlign: 'center'
              }}
            >
              {filesError}
            </div>

            <div className='create-project'>
              <Button
                type='submit'
                color='secondary'
                disabled={disabledButtonNote}
                centered
                style={{ textTransform: 'capitalize' }}
              >
                {languageContext.dictionary.save}
              </Button>
            </div>
          </form>
        </Modal>

        {/* modal to edit note */}
        <Modal
          open={openEditNote}
          onClose={onCloseEditNote}
          center
          closeIcon={<IconButton icon='close' component='div' />}
          container={myRef.current}
          classNames={{
            modal: 'customModalPhase prospect-modal-edit'
          }}
        >
          <Typography variant='h6' color={'secondary'}>
            {lang.editNote}
          </Typography>
          <form onSubmit={handleEditNote(onEditNote)} noValidate>
            <CTextInput
              type='text'
              label={languageContext.dictionary.title}
              name='title'
              control={controlEditNote}
              maxLength={45}
              required
            />
            <CTextInput
              type='textarea'
              label={languageContext.dictionary.desc}
              name='description'
              control={controlEditNote}
              rows={2}
              maxLength={2000}
              charCount
            />

            <CSelectInput
              name='event'
              control={controlEditNote}
              label={lang.date}
              options={events}
            />

            <div className='uk-grid uk-flex oski-flex-end oski-align-center'>
              <div data-uk-tooltip={'title: ' + lang.uploadImages}>
                <input
                  accept='image/*'
                  id='fotosEdit'
                  type='file'
                  multiple
                  style={{ display: 'none' }}
                  onChange={loadImgsEdit}
                />
                <label htmlFor='fotosEdit'>
                  <img
                    src={imgIcon}
                    height='20px'
                    alt='img'
                    className={
                      languageContext.theme === 'dark'
                        ? 'oski-pointer'
                        : 'oski-pointer oski-icons-light'
                    }
                  />
                </label>
              </div>
              <div data-uk-tooltip={'title: ' + lang.uploadFiles}>
                <input
                  accept='application/pdf, application/txt, application/svg, application/doc'
                  id='documentosEdit'
                  type='file'
                  multiple
                  style={{ display: 'none' }}
                  onChange={loadFilesEdit}
                />
                <label htmlFor='documentosEdit'>
                  <img
                    src={fileIcon}
                    height='45px'
                    alt='file'
                    className={
                      languageContext.theme === 'dark'
                        ? 'oski-pointer'
                        : 'oski-pointer oski-icons-light'
                    }
                  />
                </label>
              </div>
            </div>

            {((files && files.length > 0) ||
              (getNoteValues('oldImages') &&
                getNoteValues('oldImages').length > 0)) && (
              <ImageThumbsContainer
                control={control}
                thumbs={files}
                containerStyle='diinco-thumbs-container-project-form'
                thumbStyle='diinco-thumbs-style-project-form'
                deleteButtonStyle='diinco-thumbs-delete-project-form'
                handleRemove={(event, file, index) => {
                  if (typeof file === 'string') {
                    const newValue = getNoteValues('oldImages').filter(
                      (o, i) => i !== index
                    )
                    setNoteValue('oldImages', newValue)
                    forceUpdate()
                  } else {
                    const newValue = files.filter((o, i) => i !== index)
                    setFiles(newValue)
                    forceUpdate()
                  }
                }}
                rootImages={getNoteValues('oldImages')}
              />
            )}

            <div className='uk-grid'>
              {oldDocuments &&
                oldDocuments.length > 0 &&
                oldDocuments.map((document, index) => (
                  <div
                    className='uk-width-1 oski-document-text uk-flex uk-flex-between uk-flex-middle'
                    key={index}
                  >
                    <div>{document.title}</div>
                    <div
                      style={{
                        cursor: 'pointer',
                        fontSize: '15px'
                      }}
                      onClick={() =>
                        setOldDocuments(
                          oldDocuments.filter((o, i) => i !== index)
                        )
                      }
                    >
                      x
                    </div>
                  </div>
                ))}
              {documents &&
                documents.length > 0 &&
                documents.map((document, index) => (
                  <div
                    className='uk-width-1 oski-document-text uk-flex uk-flex-between uk-flex-middle'
                    key={index}
                  >
                    <div>{document.name}</div>
                    <div
                      style={{
                        cursor: 'pointer',
                        fontSize: '15px'
                      }}
                      onClick={() =>
                        setDocuments(documents.filter((o, i) => i !== index))
                      }
                    >
                      x
                    </div>
                  </div>
                ))}
            </div>

            <div
              style={{
                color: 'rgb(201, 70, 67)',
                fontFamily: 'OpenSans',
                fontSize: '12px',
                textAlign: 'center'
              }}
            >
              {filesError}
            </div>

            <div className='create-project'>
              <Button
                type='submit'
                color='secondary'
                disabled={disabledButtonNote}
                centered
                style={{ textTransform: 'uppercase' }}
              >
                {languageContext.dictionary.save}
              </Button>
            </div>
          </form>
        </Modal>

        {/* modal to delete note */}
        <Modal
          open={openDelete}
          onClose={onCloseDelete}
          center
          closeIcon={<IconButton icon='close' component='div' />}
          container={myRef.current}
          classNames={{
            modal: 'customModalPhase prospect-modal-edit'
          }}
        >
          <Typography variant='h6' color='secondary'>
            {' '}
            {lang.deleteNote}{' '}
          </Typography>
          <Typography className='uk-width-1'>{lang.areYouSure}</Typography>
          <div className='create-project'>
            <Button
              color='secondary'
              disabled={disabledButton}
              centered
              style={{ textTransform: 'uppercase' }}
              onClick={() => onDeleteNote()}
            >
              {languageContext.dictionary.delete}
            </Button>
          </div>
        </Modal>

        {/* modal to add event */}
        <Modal
          open={openEvent}
          onClose={onCloseEvent}
          center
          closeIcon={<IconButton icon='close' component='div' />}
          classNames={{
            modal: 'calendarModal'
          }}
          container={myRef.current}
        >
          <Typography
            variant='h6'
            color='secondary'
            style={{ marginBottom: 15 }}
          >
            {lang['addEvent']}
          </Typography>
          <form onSubmit={handleSubmitEvent(onSubmitEvent)} noValidate>
            <CTextInput
              type='date'
              label={languageContext.dictionary.date}
              name='date'
              control={controlEvent}
              required
            />
            <CTextInput
              type='time'
              label={languageContext.dictionary['startHour']}
              name='time'
              control={controlEvent}
              required
            />
            <CTextInput
              type='time'
              label={languageContext.dictionary['endHour']}
              name='endTime'
              control={controlEvent}
              required
            />
            <CTextInput
              label={languageContext.dictionary['title']}
              name='title'
              control={controlEvent}
              required
              maxLength={45}
            />
            <CTextInput
              label={languageContext.dictionary['desc']}
              name='description'
              type='textarea'
              control={controlEvent}
              maxLength={2000}
              charCount
            />
            <div className='create-appointment'>
              <Button
                color='secondary'
                type='submit'
                buttonStyle={{ color: 'black' }}
                containerStyle={{ margin: 0 }}
                disabled={disabledButtonEvent}
              >
                {lang['schedule']}
              </Button>
            </div>
          </form>
        </Modal>

        {/* modal to edit event */}
        <Modal
          open={openEventEdit}
          onClose={onCloseEventEdit}
          center
          closeIcon={<IconButton icon='close' component='div' />}
          classNames={{
            modal: 'calendarModal'
          }}
          container={myRef.current}
        >
          <Typography
            variant='h6'
            color='secondary'
            style={{ marginBottom: 15 }}
          >
            {lang['editEvent']}
          </Typography>
          <form onSubmit={handleSubmitEventEdit(onSubmitEventEdit)} noValidate>
            <CTextInput
              type='date'
              label={languageContext.dictionary.date}
              name='date'
              control={controlEventEdit}
              required
            />
            <CTextInput
              type='time'
              label={languageContext.dictionary['startHour']}
              name='time'
              control={controlEventEdit}
              required
            />
            <CTextInput
              type='time'
              label={languageContext.dictionary['endHour']}
              name='endTime'
              control={controlEventEdit}
              required
            />
            <CTextInput
              label={languageContext.dictionary['title']}
              name='title'
              control={controlEventEdit}
              required
              maxLength={45}
            />
            <CTextInput
              label={languageContext.dictionary['desc']}
              name='description'
              type='textarea'
              control={controlEventEdit}
              maxLength={2000}
              charCount
            />
            <div className='create-appointment'>
              <Button
                color='secondary'
                type='submit'
                buttonStyle={{ color: 'black' }}
                containerStyle={{ margin: 0 }}
                disabled={disabledButtonEvent}
              >
                {languageContext.dictionary.save}
              </Button>
            </div>
          </form>
        </Modal>
      </div>
      <PlansModal open={openPlansModal} onClose={onClosePlansModal} />
    </StandardView>
  )
}

// proptypes
Prospect.defaultProps = {
  type: 'prospect'
}

Prospect.propTypes = {
  type: PropTypes.string
}
