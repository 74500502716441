import React, { useContext, useState } from 'react'
import './styles/style.css'
import _Auth from '../../context/auth'

import attendace_badge from '../../assets/badges/attendance.svg'
import project_badge from '../../assets/badges/project.svg'
import comunication_badge from '../../assets/badges/comunication.svg'
import quality_badge from '../../assets/badges/quality.svg'
import reports_badge from '../../assets/badges/reports.svg'
import { useHistory } from 'react-router'

import { LanguageContext } from '../../context/language/context'
import Button from '../../components/Button'
import Avatar from '../../components/Avatar'
import { Link } from 'react-router-dom'
import Typography from '../../components/Typography'
import { requestImage } from '../../functions/files.functions'
import PlansModal from '../../components/PlansModal'
import banner from '../../assets/images/banner.webp'
import PropTypes from 'prop-types'

const ProfDashboard = ({ children }) => {
  const { self, hasPermission } = useContext(_Auth.Context)
  const history = useHistory()

  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const [openPlansModal, setOpenPlansModal] = useState(false)
  function onOpenPlansModal() {
    setOpenPlansModal(true)
  }

  function onClosePlansModal() {
    setOpenPlansModal(false)
  }

  return self ? (
    !self.type ? (
      <>
        <img
          src={self?.banner ? requestImage(self.banner) : banner}
          className='diinco-ProfessionalDashboard-banner'
          crossOrigin='use-credentials'
        />
        <div
          className='uk-container uk-container-large diinco-ProfessionalDashboard-container'
          style={{ minHeight: '525px' }}
        >
          <div className='uk-grid uk-grid-medium' data-uk-grid>
            <div className='uk-width-1-4 left-column diinco-profile'>
              <div className='diinco-profile-container'>
                <Avatar alt={self.name} src={self.image} size={'x-large'} />
                <Typography
                  variant='h4'
                  style={{ marginTop: '0.35em' }}
                  align='center'
                >
                  {self.name ? self.name : 'Undefined Name'}
                </Typography>
                <Typography variant='body'>
                  {self.User_Professions && self.User_Professions.length > 0
                    ? self.User_Professions.map(
                        (user_profession) =>
                          lang.profession +
                          ' ' +
                          user_profession.Profession?.name
                      )
                    : ''}
                </Typography>
                <div className='diinco-badge-container'>
                  {self.professional && self.professional.badges ? (
                    <>
                      {/* CALIDAD DE SERVICIO */}
                      {self.professional.badges.find(
                        (badge) => badge.code === 'B01'
                      ) ? (
                        <img
                          data-uk-tooltip={lang['quality']}
                          className='diinco-badge'
                          src={quality_badge}
                        />
                      ) : null}
                      {/* SUPERVISOR DE OBRA
									{user.professional.badges.find(
										(badge) => badge.code === "B02"
									) ? (
										<img
											className="diinco-badge"
											src={attendace_badge}
										/>
									) : null} */}
                      {/* PUNTUALIDAD */}
                      {self.professional.badges.find(
                        (badge) => badge.code === 'B03'
                      ) ? (
                        <img
                          data-uk-tooltip={lang['punctuality']}
                          className='diinco-badge'
                          src={attendace_badge}
                        />
                      ) : null}
                      {/* COMUNICACION */}
                      {self.professional.badges.find(
                        (badge) => badge.code === 'B04'
                      ) ? (
                        <img
                          data-uk-tooltip={lang['communication']}
                          className='diinco-badge'
                          src={comunication_badge}
                        />
                      ) : null}
                      {/* DISEÑO */}
                      {self.professional.badges.find(
                        (badge) => badge.code === 'B05'
                      ) ? (
                        <img
                          data-uk-tooltip={lang['design']}
                          className='diinco-badge'
                          src={reports_badge}
                        />
                      ) : null}

                      {/* CONSTRUCCION */}
                      {self.professional.badges.find(
                        (badge) => badge.code === 'B06'
                      ) ? (
                        <img
                          data-uk-tooltip={lang['construction']}
                          className='diinco-badge'
                          src={project_badge}
                        />
                      ) : null}
                    </>
                  ) : null}
                </div>

                <Button
                  size='auto'
                  color={languageContext.theme === 'light' ? 'primary' : 'grey'}
                  buttonStyle={{ width: 175, height: 48 }}
                  containerStyle={{ marginBottom: 15 }}
                  onClick={() => {
                    history.push('/Profile/Edit')
                  }}
                >
                  {languageContext.dictionary.editProfile}
                </Button>

                {hasPermission('project-create', null, null, 'plan') ? (
                  <Link to={'/Projects/Create'}>
                    <Button
                      size='auto'
                      color='secondary'
                      buttonStyle={{
                        width: 175,
                        height: 48
                      }}
                      containerStyle={{
                        marginBottom: 15
                      }}
                      onClick={() => {
                        history.push('/Projects/Create')
                      }}
                    >
                      {lang.newProject}
                    </Button>
                  </Link>
                ) : (
                  <Button
                    size='auto'
                    color='secondary'
                    buttonStyle={{ width: 175, height: 48 }}
                    containerStyle={{ marginBottom: 15 }}
                    onClick={onOpenPlansModal}
                  >
                    {lang.newProject}
                  </Button>
                )}

                <Button
                  size='auto'
                  color='dark'
                  buttonStyle={{
                    width: 175
                    // height: 48,
                  }}
                  onClick={() => {
                    history.push('/Albums/Admin')
                  }}
                >
                  {lang.adminAlbums}
                </Button>
                {/* <div className="diinco-plan-update-container">
									<a
										onClick={() => {
											history.push("/subscription");
										}}
									>
										Actualizar plan
									</a>
									<div className="diinco-plan-update-divider" />
									<div className="diinco-sphere-container">
										<span className="diinco-sphere-1" />
										<span className="diinco-sphere-2" />
										<span className="diinco-sphere-3" />
									</div>
								</div> */}
              </div>
            </div>
            {/* RIGHT COLUMN */}
            <div className='uk-width-2-3'>
              <div
                data-uk-grid
                className='uk-grid-small uk-child-width-1-3 uk-margin-small-bottom'
              >
                <Link to={'/Dashboard'}>
                  <Button
                    variant='underlined'
                    size='auto'
                    style={
                      // location?.pathname == "/Dashboard"
                      //     ? {
                      //           fontFamily: "OpenSans",
                      //           fontSize: "20px",
                      //           fontWeight: "400",
                      //           textTransform:
                      //               "uppercase",
                      //           borderBottom:
                      //               "3px solid #ea6732",
                      //       }
                      //     : {
                      //           fontFamily: "OpenSans",
                      //           fontSize: "20px",
                      //           fontWeight: "400",
                      //           textTransform:
                      //               "uppercase",
                      //           borderBottom:
                      //               "3px solid #ea6732",
                      //           opacity: "0.5",
                      //       }
                      {
                        color:
                          window.location.href.indexOf('Projects') <= -1
                            ? undefined
                            : '#aeaeae',
                        textTransform: 'uppercase',
                        fontSize: '20px',
                        fontWeight: '400'
                      }
                    }
                  >
                    {languageContext.dictionary['calendar']}
                  </Button>
                </Link>

                <Link to={'/Dashboard/' + 'Projects'}>
                  <Button
                    variant='underlined'
                    color='grey'
                    size='auto'
                    style={
                      // location?.pathname ==
                      // "/Dashboard/Projects"
                      //     ? {
                      //           fontFamily: "OpenSans",
                      //           fontSize: "20px",
                      //           fontWeight: "400",
                      //           textTransform:
                      //               "uppercase",
                      //           borderBottom:
                      //               "3px solid #6b6e6f",
                      //       }
                      //     : {
                      //           fontFamily: "OpenSans",
                      //           fontSize: "20px",
                      //           fontWeight: "400",
                      //           textTransform:
                      //               "uppercase",
                      //           borderBottom:
                      //               "3px solid #6b6e6f",
                      //           opacity: "0.5",
                      //       }
                      {
                        color:
                          window.location.href.indexOf('Projects') > -1
                            ? undefined
                            : '#aeaeae',
                        textTransform: 'uppercase',
                        fontSize: '20px',
                        fontWeight: '400'
                      }
                    }
                  >
                    {languageContext.dictionary['projects']}
                  </Button>
                </Link>

                <Link to={'/Crm'}>
                  <Button
                    variant='underlined'
                    color='secondary'
                    size='auto'
                    style={
                      // location?.pathname == "/Crm"
                      //     ? {
                      //           fontFamily: "OpenSans",
                      //           fontSize: "20px",
                      //           fontWeight: "400",
                      //           textTransform:
                      //               "uppercase",
                      //           borderBottom:
                      //               "3px solid #f9b74e",
                      //       }
                      //     : {
                      //           fontFamily: "OpenSans",
                      //           fontSize: "20px",
                      //           fontWeight: "400",
                      //           textTransform:
                      //               "uppercase",
                      //           borderBottom:
                      //               "3px solid #f9b74e",
                      //           opacity: "0.5",
                      //       }
                      {
                        color:
                          window.location.href.indexOf('CRM') > -1
                            ? undefined
                            : '#aeaeae',
                        fontSize: '20px',
                        fontWeight: '400'
                      }
                    }
                  >
                    CRM
                  </Button>
                </Link>
              </div>
              {children}
            </div>
          </div>
        </div>
        <PlansModal open={openPlansModal} onClose={onClosePlansModal} />
      </>
    ) : (
      <></>
    )
  ) : (
    <></>
  )
}

ProfDashboard.defaultProps = {
  children: null
}

ProfDashboard.propTypes = {
  children: PropTypes.node
}

export default ProfDashboard
