import React, { useEffect, useContext, useState } from 'react'
import Card from '../../../../components/Card'
import './styles/style.css'
import _Auth from '../../../../context/auth'
import _Utils from '../../../../context/utils'
import { LanguageContext } from '../../../../context/language/context'
import { _handleErrorMessage } from '../../../../functions/error.functions'
// import ProjectCardLeft from '../../../../components/ProjectCardLeft'
// import ProjectCardRight from '../../../../components/ProjectCardRight'
import NoData from '../../../../components/NoData'
import { Link } from 'react-router-dom'
import Typography from '../../../../components/Typography'
import Avatar from '../../../../components/Avatar'
import { format } from 'date-fns'
import DefaultBanner from '../../../../assets/images/banner.webp'

export const Projects = () => {
  const { token } = useContext(_Auth.Context)
  const languageContext = useContext(LanguageContext)
  const { HomeLoader, toggleLoader, enqueueNotification } = useContext(
    _Utils.Context
  )
  const [projects, setProjects] = useState([])
  const [loader, setLoader] = useState(true)

  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  useEffect(() => {
    async function Init() {
      toggleLoader(true)
      try {
        let data = await fetch(
          process.env.REACT_APP_API_URL + 'dashboard/projects',
          {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        data = await data.json()
        if (data.Projects) {
          setProjects(data.Projects)
        }
      } catch (error) {
        enqueueNotification(
          _handleErrorMessage(languageContext?.userLanguage, error),
          'error'
        )
      }
      setLoader(false)
      toggleLoader(false)
    }
    if (token) Init()
  }, [token])

  const Loader = (
    <div className='uk-width-1-1 uk-height-1-1'>
      <div
        className='uk-margin-auto uk-margin-medium-top uk-width-auto'
        style={{ width: 'fit-content' }}
      >
        <HomeLoader />
      </div>
    </div>
  )

  if (loader) return Loader

  if (projects && projects.length > 0) {
    return (
      <Card
        color={languageContext.theme === 'dark' ? 'dark' : 'lightgrey'}
        className='leo-card-project uk-margin-medium-bottom uk-padding-large'
        variant='filled'
      >
        <div data-uk-grid className='uk-child-width-1-1'>
          {projects.map((project, index) => {
            if (index % 2 === 0)
              return (
                <div key={'project-' + index}>
                  <Link
                    to={'/Projects/' + project.id}
                    style={{ textDecoration: 'none' }}
                  >
                    <Card
                      variant={'filled'}
                      padding='0px'
                      color={
                        languageContext.theme === 'dark' ? 'grey' : 'light'
                      }
                    >
                      <div data-uk-grid>
                        <div
                          className='uk-width-1-3'
                          style={{ minHeight: 217 }}
                        >
                          <div className='uk-padding'>
                            <Typography>{project.title}</Typography>
                            <Typography
                              style={{
                                textTransform: 'none'
                              }}
                            >
                              {project.Location && project.Location.City && (
                                <>
                                  {project.Location?.City?.State?.name}{' '}
                                  {project.Location.City.State && (
                                    <>, {project.Location?.City?.name}</>
                                  )}
                                </>
                              )}
                            </Typography>
                            <Typography>
                              {format(
                                new Date(project.createdAt),
                                'dd/MM/yyyy'
                              )}
                            </Typography>

                            <div>
                              {project &&
                              project.Users &&
                              project.Users.length ? (
                                <div className='uk-inline'>
                                  <div
                                    className='uk-flex uk-flex-center uk-flex-middle'
                                    style={{
                                      cursor: 'pointer'
                                    }}
                                    // onClick={() => {setUserModal(true)}}
                                  >
                                    {project.Users.map((projectUser, index) => {
                                      //just displays first 6 users
                                      if (index < 3)
                                        return (
                                          <Avatar
                                            src={projectUser.image}
                                            alt={projectUser.name}
                                            key={'project-users-' + index}
                                            className='uk-margin-small-right'
                                            border={
                                              projectUser.Role &&
                                              projectUser.Role.name ===
                                                'project-admin'
                                            }
                                          />
                                        )
                                    })}
                                    {project.Users.length > 3 && (
                                      <Avatar
                                        total={project.Users.length - 3}
                                        className='uk-margin-small-right'
                                        tooltip={
                                          'pos: right; title: ' +
                                          project.Users.slice(3)
                                            .map((projectUser) => {
                                              return projectUser.name
                                            })
                                            .join('<br/>')
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <Typography
                                  align='left'
                                  className='uk-margin-small-right'
                                >
                                  {lang.noMembers}
                                </Typography>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='uk-width-2-3'>
                          <div
                            style={{
                              position: 'relative',
                              height: '100%',
                              backgroundImage: `url(${
                                project?.banner
                                  ? process.env.REACT_APP_API_URL +
                                    project.banner
                                  : DefaultBanner
                              })`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center'
                            }}
                          >
                            {project.status === 'finished' && (
                              <React.Fragment>
                                <div
                                  style={{
                                    position: 'absolute',
                                    bottom: '40%',
                                    left: '2.5%',
                                    width: '90%',
                                    padding: 10
                                  }}
                                >
                                  <Typography
                                    disableMargin
                                    align={'center'}
                                    style={{
                                      backgroundColor: 'rgba(249, 183, 78, 0.9)'
                                    }}
                                  >
                                    {
                                      languageContext.dictionary.projectStatus
                                        ?.finished
                                    }
                                  </Typography>
                                </div>
                              </React.Fragment>
                            )}
                          </div>

                          {/* <img
                                                        src={
                                                            project?.banner
                                                                ? process.env
                                                                      .REACT_APP_API_URL +
                                                                  project.banner
                                                                : DefaultBanner
                                                        }
                                                        className="dc-fit-cover"
                                                        style={{
                                                            borderRadius:
                                                                "0 5px 5px 0",
                                                            height: "100%",
                                                            width: "100%",
                                                        }}
                                                    /> */}
                        </div>
                      </div>
                    </Card>
                  </Link>
                </div>
              )

            return (
              <div key={'project-' + index}>
                <Link
                  to={'/Projects/' + project.id}
                  style={{ textDecoration: 'none' }}
                >
                  <Card
                    variant={'filled'}
                    padding='0px'
                    color={languageContext.theme === 'dark' ? 'grey' : 'light'}
                  >
                    <div data-uk-grid>
                      <div className='uk-width-2-3'>
                        <div
                          style={{
                            position: 'relative',
                            height: '100%',
                            backgroundImage: `url(${
                              project?.banner
                                ? process.env.REACT_APP_API_URL + project.banner
                                : DefaultBanner
                            })`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                          }}
                        >
                          {project.status === 'finished' && (
                            <React.Fragment>
                              <div
                                style={{
                                  position: 'absolute',
                                  left: '2.5%',
                                  bottom: '40%',
                                  width: '90%',
                                  padding: 10
                                }}
                              >
                                <Typography
                                  disableMargin
                                  align={'center'}
                                  style={{
                                    backgroundColor: 'rgba(249, 183, 78, 0.9)'
                                  }}
                                >
                                  {
                                    languageContext.dictionary.projectStatus
                                      ?.finished
                                  }
                                </Typography>
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                        {/* <img
                                                    src={
                                                        project?.banner
                                                            ? process.env
                                                                  .REACT_APP_API_URL +
                                                              project.banner
                                                            : DefaultBanner
                                                    }
                                                    className="dc-fit-cover"
                                                    style={{
                                                        borderRadius:
                                                            "5px 0 0 5px",
                                                            height: "100%",
                                                            width: "100%",
                                                    }}
                                                /> */}
                      </div>
                      <div className='uk-width-1-3' style={{ minHeight: 217 }}>
                        <div className='uk-padding'>
                          <Typography>{project.title}</Typography>
                          <Typography
                            style={{
                              textTransform: 'none'
                            }}
                          >
                            {project.Location && project.Location.City && (
                              <>
                                {project.Location?.City?.State?.name}{' '}
                                {project.Location.City.State && (
                                  <>, {project.Location?.City?.name}</>
                                )}
                              </>
                            )}
                          </Typography>
                          <Typography>
                            {format(new Date(project.createdAt), 'dd/MM/yyyy')}
                          </Typography>

                          <div>
                            {project &&
                            project.Users &&
                            project.Users.length ? (
                              <div className='uk-inline'>
                                <div
                                  className='uk-flex uk-flex-center uk-flex-middle'
                                  style={{
                                    cursor: 'pointer'
                                  }}
                                  // onClick={() => {setUserModal(true)}}
                                >
                                  {project.Users.map((projectUser, index) => {
                                    //just displays first 6 users
                                    if (index < 3)
                                      return (
                                        <Avatar
                                          src={projectUser.image}
                                          alt={projectUser.name}
                                          key={'project-users-' + index}
                                          className='uk-margin-small-right'
                                          border={
                                            projectUser.Role &&
                                            projectUser.Role.name ===
                                              'project-admin'
                                          }
                                        />
                                      )
                                  })}
                                  {project.Users.length > 3 && (
                                    <Avatar
                                      total={project.Users.length - 3}
                                      className='uk-margin-small-right'
                                      tooltip={
                                        'pos: right; title: ' +
                                        project.Users.slice(3)
                                          .map((projectUser) => {
                                            return projectUser.User.name
                                          })
                                          .join('<br/>')
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            ) : (
                              <Typography
                                align='left'
                                className='uk-margin-small-right'
                              >
                                {lang.noMembers}
                              </Typography>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Link>
              </div>
            )

            // if (index % 2 === 0) {
            //   return (
            //     <ProjectCardLeft
            //       key={'project-' + index}
            //       name={project.title}
            //       date={project.date_start + ' - ' + project.date_end}
            //       startDate={project.date_start}
            //       endDate={project.date_end}
            //       location={project.Location}
            //       img={project.banner}
            //       users={project.Users}
            //       projectId={project.id}
            //       theme={languageContext.theme}
            //     />
            //   )
            // } else {
            //   return (
            //     <ProjectCardRight
            //       key={'project-' + index}
            //       name={project.title}
            //       date={project.date_start + ' - ' + project.date_end}
            //       startDate={project.date_start}
            //       endDate={project.date_end}
            //       location={project.Location}
            //       img={project.banner}
            //       users={project.Users}
            //       projectId={project.id}
            //       theme={languageContext.theme}
            //     />
            //   )
            // }
          })}
        </div>
      </Card>
    )
  } else {
    return (
      <Card
        color={languageContext.theme === 'dark' ? 'dark' : 'lightgrey'}
        className='leo-card-project'
        variant='filled'
        style={{ minHeight: 50 }}
      >
        <NoData className='uk-margin-top' />
      </Card>
    )
  }
}
