import React, { useContext, useEffect, useRef, useState } from 'react'
import Calendar from 'react-calendar'
import './styles/Calendar.css'
import './styles/style.css'
import { differenceInCalendarDays, format } from 'date-fns'
import PropTypes from 'prop-types'
import checkIcon from './assets/check.svg'
import _Auth from '../../context/auth'
import _Utils from '../../context/utils'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import es from 'date-fns/locale/es'

import { LanguageContext } from '../../context/language/context'
import { _handleErrorMessage } from '../../functions/error.functions'

import { CTextInput } from '../../components/TextInput'
import { useForm } from 'react-hook-form'
import { serverValidation } from '../../functions/validation.functions'
import Button from '../Button'
import Typography from '../Typography'
import { IconButton } from '../IconButton'
import Card from '../Card'
import { useHistory } from 'react-router-dom'
import ProjectPlansModal from '../ProjectPlansModal'

function isSameDay(a, b) {
  return differenceInCalendarDays(a, b) === 0
}

export default function CustomCalendar(props) {
  const languageContext = useContext(LanguageContext)

  const [openProjectPlansModal, setOpenProjectPlansModal] = useState(false)
  function onOpenProjectPlansModal() {
    setOpenProjectPlansModal(true)
  }

  function onCloseProjectPlansModal() {
    setOpenProjectPlansModal(false)
  }

  var lang
  var dateLang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
    dateLang = 'en-US'
  } else {
    lang = require('./languages/es.json')
    dateLang = 'es-MX'
  }
  const history = useHistory()

  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }

  const myRef = useRef(null)
  const { hasPermission } = useContext(_Auth.Context)
  let constDate = new Date()
  constDate.setHours(0, 0, 0, 0)

  const { enqueueNotification, toggleLoader } = useContext(_Utils.Context)
  const [value, onChange] = useState(new Date())
  const [events, setEvents] = useState([])
  const [dates, setDates] = useState(props.dates)
  const [open, setOpen] = useState(false)
  const [projectId, setProjectId] = useState(props.projectId)
  const [disableButton, setDisableButton] = useState(false)
  const defaultValues = {
    title: '',
    description: '',
    time: format(
      new Date(
        value.getFullYear(),
        value.getMonth(),
        value.getDay(),
        0,
        0,
        0,
        0
      ),
      'HH:mm'
    ),
    endTime: format(
      new Date(
        value.getFullYear(),
        value.getMonth(),
        value.getDay(),
        0,
        0,
        0,
        0
      ),
      'HH:mm'
    )
  }

  useEffect(() => {
    if (props.projectId) setProjectId(props.projectId)
  }, [props.projectId])

  useEffect(() => {
    if (props.dates) onChange(new Date())
  }, [props.dates])

  function onOpen() {
    setOpen(true)
    reset(defaultValues)
  }

  function onClose() {
    setOpen(false)
    reset(defaultValues)
  }

  function tileContent({ date, view }) {
    if (view === 'month') {
      if (dates.find((dDate) => isSameDay(dDate.date, date))) {
        return <span />
      }
    }
  }
  useEffect(() => {
    setDates(props.dates)
  }, [props.dates])

  const { reset, control, setError, handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues
  })

  useEffect(() => {
    let data = dates.filter((dDate) => isSameDay(dDate.date, value))
    if (data) {
      let tmp = []
      data.map((event) => {
        return tmp.push(event.data)
      })
      tmp.sort((a, b) => new Date(a.date) - new Date(b.date))
      setEvents([...tmp])
    } else setEvents([])
  }, [value])

  async function toggleEventStatus(event, index) {
    event.preventDefault()
    let backup = [...events]
    let tmp = [...events]
    if (tmp[index].stage === 0) {
      try {
        let idproyecto = projectId
        if (Object.prototype.hasOwnProperty.call(tmp[index], 'projectId')) {
          idproyecto = tmp[index].projectId
        }
        let prospectId = undefined
        if (Object.prototype.hasOwnProperty.call(tmp[index], 'prospectId')) {
          prospectId = tmp[index].prospectId
        }
        let data = await fetch(
          process.env.REACT_APP_API_URL + 'events/updateStatus',
          {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              eventId: tmp[index].id,
              projectId: idproyecto,
              prospectId
            })
          }
        )
        if (data.status !== 200) throw await data.json()
        data = await data.json()

        tmp[index].status = !tmp[index].status
        setEvents([...tmp])
      } catch (error) {
        console.log(error, 'error')
        if (error?.code === 8014 && projectId) {
          onOpenProjectPlansModal()
        }
        setEvents(backup)
        enqueueNotification(
          _handleErrorMessage(languageContext?.userLanguage, error),
          'error'
        )
      }
    }
  }

  useEffect(() => {
    if (formState.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
    } else {
      window.onbeforeunload = null
    }
  }, [formState.isDirty])

  // funcion para agregar una nueva tarjeta
  async function onSubmit(data) {
    setDisableButton(true)
    await toggleLoader(true)
    try {
      let response = await fetch(process.env.REACT_APP_API_URL + 'events', {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...data,
          projectId: projectId ? projectId : undefined,
          date: value
        })
      })
      await serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        async (data) => {
          reset(data)
          await toggleLoader(false)
          setDisableButton(false)
          enqueueNotification(languageContext.dictionary['success'], 'success')
          let d = {
            description: data.event.body,
            id: data.event.id,
            status: data.event.status,
            title: data.event.title,
            date: new Date(data.event.date_end),
            date_start: new Date(data.event.date_start),
            date_end: new Date(data.event.date_end)
          }
          setDates([...dates, { data: d, date: value }])
          let e = [...events, d]
          e.sort((a, b) => new Date(a.date) - new Date(b.date))

          setEvents(e)
          setOpen(false)
        },
        async (error) => {
          if (error?.error?.code === 8014 && projectId) {
            onOpenProjectPlansModal()
          }
          setDisableButton(false)
          await toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      setDisableButton(false)
      await toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  const [openCancel, setOpenCancel] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(null)

  function onOpenModalCancel(id) {
    setSelectedEvent(id)
    setOpenCancel(true)
  }

  async function submitCancelEvent() {
    await toggleLoader(true)
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'events/delete',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            eventId: selectedEvent
          })
        }
      )
      serverValidation(
        response,
        null,
        languageContext.userLanguage,
        async () => {
          enqueueNotification(languageContext.dictionary['success'], 'success')
          await toggleLoader(false)
          setOpenCancel(false)
          history.go(0)
        },
        async (e) => {
          await toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, e.errors.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  const defaultValuesEdit = {
    id: '',
    title: '',
    description: '',
    time: format(new Date(value), 'HH:mm'),
    endTime: format(new Date(value), 'HH:mm')
  }

  const [openEdit, setOpenEdit] = useState(false)
  function onOpenEdit(event) {
    resetEdit({
      id: event.id,
      title: event.title,
      description: event.description,
      time: format(new Date(event.date_start), 'HH:mm'),
      endTime: format(new Date(event.date_end), 'HH:mm')
    })
    setOpenEdit(true)
  }

  function onCloseEdit() {
    setOpenEdit(false)
    resetEdit(defaultValuesEdit)
  }

  const {
    reset: resetEdit,
    control: controlEdit,
    setError: setErrorEdit,
    handleSubmit: handleSubmitEdit
  } = useForm({
    mode: 'onChange',
    defaultValuesEdit
  })

  async function onSubmitEdit(data) {
    setDisableButton(true)
    await toggleLoader(true)
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'events/update',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...data,
            projectId: projectId ? projectId : undefined,
            date: value
          })
        }
      )
      await serverValidation(
        response,
        setErrorEdit,
        languageContext.userLanguage,
        async () => {
          enqueueNotification(languageContext.dictionary['success'], 'success')
          setOpenEdit(false)
          //reload page
          history.go(0)
        },
        async (error) => {
          if (error?.error?.code === 8014 && projectId) {
            onOpenProjectPlansModal()
          }
          setDisableButton(false)
          await toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      setDisableButton(false)
      await toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  return (
    <div className='diinco-calendar-container'>
      <Calendar
        {...props}
        onChange={onChange}
        value={value}
        prevLabel={<span data-uk-icon='icon: chevron-left; ratio: 2'></span>}
        prev2Label={null}
        nextLabel={<span data-uk-icon='icon: chevron-right; ratio: 2'></span>}
        next2Label={null}
        tileContent={tileContent}
        locale={props.locale}
        formatMonthYear={(locale, date) => {
          if (locale === 'es-ES')
            return format(date, 'MMMM yyyy', { locale: es })
          else {
            return format(date, 'MMMM yyyy')
          }
        }}
      />
      <Card
        color={languageContext.theme === 'dark' ? 'dark' : 'grey'}
        className='leo-card-calendar'
        variant='filled'
      >
        <div className='' ref={myRef}></div>
        {!events.length ? (
          <>
            <Typography theme='dark' disableMargin>
              {lang['noEvents'] + value.toLocaleDateString(dateLang, options)}
            </Typography>
          </>
        ) : (
          <>
            <ul>
              {events.map((x, i) => (
                <li
                  key={'EVENT_' + i + '_' + x.title}
                  className='diinco-time-display'
                >
                  <div className='diinco-time-hour'>
                    <div
                      className='diinco-time'
                      onClick={(event) => {
                        toggleEventStatus(event, i)
                      }}
                    >
                      {x.status ? (
                        <img
                          className='diinco-time-check'
                          alt='check'
                          src={checkIcon}
                          style={{
                            filter:
                              languageContext.theme === 'dark'
                                ? 'brightness(0) saturate(100%) invert(21%) sepia(89%) saturate(0%) hue-rotate(270deg) brightness(80%) contrast(118%)'
                                : ''
                          }}
                        />
                      ) : x.date_start && x.date_end ? (
                        format(x.date_start, 'h:mm') +
                        ' - ' +
                        format(x.date_end, 'h:mm a')
                      ) : (
                        x.date && format(x.date, 'h:mm')
                      )}
                    </div>
                  </div>
                  <div className='diinco-time-body'>
                    <div className='uk-flex uk-width-1 uk-flex-between'>
                      <Typography theme='dark' variant='subtitle'>
                        {x.title}
                      </Typography>
                      {((props.projectStatus &&
                        props.projectStatus !== 'finished') ||
                        !props.projectStatus) &&
                        value >= constDate &&
                        hasPermission('event-create') && (
                          <div>
                            <a
                              className='column-more'
                              data-uk-icon='icon: more-vertical'
                            />
                            <div data-uk-dropdown='mode: click; pos: bottom-justify'>
                              <ul
                                className='uk-list'
                                style={{
                                  marginBottom: 0
                                }}
                              >
                                <li
                                  className='dropdown-option uk-dropdown-close first-option-calendar'
                                  onClick={() => onOpenEdit(x)}
                                >
                                  {lang.editEvent}
                                </li>
                                <li
                                  className='dropdown-option uk-dropdown-close last-option-calendar'
                                  onClick={() => onOpenModalCancel(x.id)}
                                >
                                  {lang.cancelEvent}
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                    </div>
                    <span>
                      <Typography
                        variant='body2'
                        style={{
                          textTransform: 'inherit'
                        }}
                        theme='dark'
                      >
                        {x.description}
                      </Typography>
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
        {projectId ? (
          <>
            {props.projectStatus &&
              props.projectStatus !== 'finished' &&
              value >= constDate &&
              hasPermission('event-create', 'or', projectId, 'project') && (
                <div className='buttons-container leo-buttons-container'>
                  <IconButton
                    icon='create'
                    variant='filled'
                    color='dark'
                    onClick={onOpen}
                    iconColor={'light'}
                  />
                </div>
              )}
          </>
        ) : (
          <>
            {value >= constDate && hasPermission('event-create') && (
              <div className='buttons-container leo-buttons-container'>
                <IconButton
                  icon='create'
                  variant='filled'
                  color='dark'
                  onClick={onOpen}
                  iconColor={'light'}
                />
              </div>
            )}
          </>
        )}
      </Card>
      {/* modal */}
      <Modal
        open={open}
        onClose={onClose}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        classNames={{
          modal: 'calendarModal'
        }}
        container={myRef.current}
      >
        <Typography variant='h6'>{lang['addEvent']} </Typography>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <p className='modal-title-calendar'>
            {value.toLocaleDateString(dateLang, options)}
          </p>
          <CTextInput
            type='time'
            label={languageContext.dictionary['startHour']}
            name='time'
            control={control}
            required
          />
          <CTextInput
            type='time'
            label={languageContext.dictionary['endHour']}
            name='endTime'
            control={control}
            required
          />
          <CTextInput
            label={languageContext.dictionary['title']}
            name='title'
            control={control}
            required
            maxLength={45}
          />
          <CTextInput
            label={languageContext.dictionary['desc']}
            name='description'
            type='textarea'
            control={control}
            maxLength={200}
            charCount
          />
          <div className='create-appointment'>
            <Button
              color='secondary'
              size='small'
              type='submit'
              buttonStyle={{ color: 'black' }}
              containerStyle={{ margin: 0 }}
              disabled={disableButton}
            >
              {lang['schedule']}
            </Button>
          </div>
        </form>
      </Modal>
      {/* MODAL DE CANCELAR DATE */}
      <Modal
        open={openCancel}
        onClose={() => setOpenCancel(false)}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        classNames={{
          modal: 'calendarModal'
        }}
        container={myRef.current}
      >
        <Typography variant='h6' color='secondary' style={{ marginTop: 15 }}>
          {lang.sureToCancel}{' '}
        </Typography>
        <Typography style={{ marginBottom: 30, marginTop: 30 }}>
          {lang.irreversible}
        </Typography>
        <div className='uk-flex uk-width-1 uk-flex-between'>
          <Button
            type={'button'}
            onClick={() => setOpenCancel(false)}
            variant='contained'
            color='grey'
          >
            {languageContext.dictionary.cancel}
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={submitCancelEvent}
          >
            {languageContext.dictionary.accept}
          </Button>
        </div>
      </Modal>
      {/* MODAL DE EDITAR DATE */}
      <Modal
        open={openEdit}
        onClose={onCloseEdit}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        classNames={{
          modal: 'calendarModal'
        }}
        container={myRef.current}
      >
        <Typography variant='h6'>{lang.editEvent} </Typography>
        <form onSubmit={handleSubmitEdit(onSubmitEdit)} noValidate>
          <p className='modal-title-calendar'>
            {value.toLocaleDateString(dateLang, options)}
          </p>
          <CTextInput
            type='time'
            label={languageContext.dictionary['startHour']}
            name='time'
            control={controlEdit}
            required
          />
          <CTextInput
            type='time'
            label={languageContext.dictionary['endHour']}
            name='endTime'
            control={controlEdit}
            required
          />
          <CTextInput
            label={languageContext.dictionary['title']}
            name='title'
            control={controlEdit}
            required
            maxLength={45}
          />
          <CTextInput
            label={languageContext.dictionary['desc']}
            name='description'
            type='textarea'
            control={controlEdit}
            maxLength={200}
            charCount
          />
          <div className='create-appointment'>
            <Button
              color='secondary'
              size='small'
              type='submit'
              buttonStyle={{ color: 'black' }}
              containerStyle={{ margin: 0 }}
              disabled={disableButton}
            >
              {languageContext.dictionary.save}
            </Button>
          </div>
        </form>
      </Modal>
      {projectId && (
        <ProjectPlansModal
          projectId={projectId}
          open={openProjectPlansModal}
          onClose={onCloseProjectPlansModal}
        />
      )}
    </div>
  )
}
CustomCalendar.defaultProps = {
  dates: [],
  ref: null,
  projectId: null,
  locale: '',
  projectStatus: ''
}
CustomCalendar.propTypes = {
  dates: PropTypes.array,
  ref: PropTypes.any,
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  locale: PropTypes.string,
  projectStatus: PropTypes.string
}
