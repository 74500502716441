import React from 'react'
import './styles/style.css'
import PropTypes from 'prop-types'

export default function Divider(props) {
  const {
    color,
    className,

    ...rest
  } = props
  return (
    <div
      className={[
        'cj-divider-component',
        'cj-divider-component-' + color.toLowerCase(),
        className ?? ''
        // theme !== "default" && "cj-input-theme-" + theme
      ].join(' ')}
      {...rest}
    >
      {props.children}
    </div>
  )
}

Divider.defaultProps = {
  color: 'secondary',
  children: undefined,
  theme: '',
  className: ''
}

Divider.propTypes = {
  color: PropTypes.oneOf(['secondary', 'grey', 'primary', 'light']),
  children: PropTypes.any,
  theme: PropTypes.string,
  className: PropTypes.string
}
