import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { LanguageContext } from '../../context/language/context'
import './styles/style.css'
import { message } from '../../functions/validation.functions'
import { Controller } from 'react-hook-form'
import { ReactComponent as EyeIcon } from './assets/eye-outline.svg'
import { ReactComponent as EyeOffIcon } from './assets/eye-off-outline.svg'
import { IconButton } from '../IconButton'

const TextInput = React.forwardRef(
  (
    {
      type,
      rows,
      label,
      placeholder,
      required,
      charCount,
      classes,
      error,
      autoCorrect,
      autoComplete,
      disableFocusBorder,
      helperText,
      variant,
      limit,

      value: valueFromProps,
      onChange: onChangeFromProps,
      onBlur,
      defaultValue,

      maxLength,
      minLength,

      lengthCount,
      disabled,
      theme: themeFromProps,

      ...rest
    },
    ref
  ) => {
    const [selected, setSelected] = useState(false)
    const { containerClass, fieldClass, errorClass, labelClass } = classes
    // A component can be considered controlled when its value prop is
    // not undefined.
    const isControlled = typeof valueFromProps !== 'undefined'
    // When a component is not controlled, it can have a defaultValue.
    const hasDefaultValue = typeof defaultValue !== 'undefined'
    // If a defaultValue is specified, we will use it as our initial
    // state.  Otherwise, we will simply use an empty string.
    const [internalValue, setInternalValue] = useState(
      hasDefaultValue ? defaultValue : ''
    )
    // Internally, we need to deal with some value. Depending on whether
    // the component is controlled or not, that value comes from its
    // props or from its internal state.
    const value = isControlled ? valueFromProps : internalValue
    const letterCount = value ? value.length : 0
    const onChange = (e) => {
      // When the user types, we will call props.onChange if it exists.
      // We do this even if there is no props.value (and the component
      // is uncontrolled.)
      if (onChangeFromProps) {
        onChangeFromProps(e)
      }
      // If the component is uncontrolled, we need to update our
      // internal value here.
      if (!isControlled) {
        setInternalValue(e.target.value)
      }
    }

    const [password, setPassword] = useState('password')

    const Eye = () => {
      if (password === 'password') {
        setPassword('text')
      } else {
        setPassword('password')
      }
    }

    const { theme, dictionary } = useContext(LanguageContext)

    let themeProp = {}

    if (themeFromProps !== 'default') {
      if (themeFromProps === 'inverted')
        themeProp['data-input-theme'] = theme === 'light' ? 'dark' : 'light'
      else themeProp['data-input-theme'] = themeFromProps
    }

    if (disabled) {
      error = null
    }

    return (
      //Container
      <div
        className={
          containerClass
            ? 'cj-input-label-container ' +
              (disableFocusBorder ? '' : 'cj-input-label-container-focus ') +
              containerClass
            : (disableFocusBorder ? '' : 'cj-input-label-container-focus ') +
              'cj-input-label-container uk-width-1-1 cj-input-margin-bottom'
        }
        style={type === 'textarea' ? { paddingTop: '10px' } : {}}
        {...themeProp}
      >
        {type === 'textarea' ? (
          <>
            <textarea
              ref={ref}
              value={value}
              onChange={onChange}
              rows={rows}
              placeholder={
                placeholder
                  ? label
                    ? selected
                      ? !value
                        ? placeholder
                        : undefined
                      : undefined
                    : placeholder
                  : undefined
              }
              aria-invalid={error ? 'true' : 'false'}
              className={[
                'diinco',
                'uk-textarea',
                variant === 'outlined'
                  ? 'cj-input-outlined'
                  : 'cj-input-standard'
              ].join(' ')}
              onFocus={() => {
                setSelected(true)
              }}
              onBlur={(e) => {
                setSelected(false)

                if (onBlur) onBlur(e)
              }}
              disabled={disabled}
              required={required}
              maxLength={
                limit && (maxLength || lengthCount)
                  ? lengthCount ?? maxLength
                  : undefined
              }
              {...rest}
            />
          </>
        ) : (
          <>
            <input
              ref={ref}
              value={value}
              onChange={onChange}
              type={type === 'password' ? password : type}
              placeholder={
                placeholder
                  ? label
                    ? selected
                      ? !value
                        ? placeholder
                        : undefined
                      : undefined
                    : placeholder
                  : undefined
              }
              autoCorrect={autoCorrect}
              autoComplete={autoComplete}
              aria-invalid={error ? 'true' : 'false'}
              className={[
                'diinco',
                'uk-input',
                'cj-input-' + variant.toLowerCase(),
                fieldClass ?? '',
                theme !== 'default' && 'cj-input-theme-' + theme
              ].join(' ')}
              onFocus={() => {
                setSelected(true)
              }}
              onBlur={(e) => {
                setSelected(false)

                if (onBlur) onBlur(e)
              }}
              disabled={disabled}
              required={required}
              maxLength={limit && maxLength ? maxLength : undefined}
              {...rest}
            />
          </>
        )}
        {label && (
          <label
            className={
              labelClass
                ? labelClass +
                  (type === 'date' ? ' filled' : value && ' filled')
                : type === 'date'
                ? 'filled'
                : value && 'filled'
            }
          >
            <span>
              {label} {required ? '' : ' (' + dictionary.optional + ')'}
            </span>
          </label>
        )}
        {(helperText || error || maxLength || minLength) && (
          <div
            className={
              error
                ? errorClass
                  ? errorClass + ' uk-flex cj-helper-text'
                  : 'cj-input-error uk-flex cj-helper-text'
                : 'uk-flex cj-helper-text'
            }
          >
            {error && (
              <span>
                <span data-uk-icon='icon: warning; ratio: 0.6'></span>
                {error.message}
              </span>
            )}
            {helperText && (
              <span className={error ? 'uk-margin-small-left' : ''}>
                {helperText}
              </span>
            )}
            {((charCount && (maxLength || minLength)) ||
              (error &&
                (error.type === 'maxLength' ||
                  error.type === 'minLength'))) && (
              <span className='uk-margin-auto-left'>
                {maxLength
                  ? value
                    ? `${lengthCount ?? letterCount}/${maxLength}`
                    : `0/${maxLength}`
                  : value
                  ? `${lengthCount ?? letterCount}/${minLength}`
                  : `0/${minLength}`}
              </span>
            )}
          </div>
        )}
        {/* <span class="uk-form-icon" uk-icon="icon: user"></span> */}
        {type === 'password' && (
          <IconButton
            className='cj-icon-eye'
            onClick={Eye}
            tabIndex='-1'
            variant='borderless'
            disableRipple={false}
          >
            {password === 'password' ? <EyeIcon /> : <EyeOffIcon />}
          </IconButton>
        )}
      </div>
    )
  }
)

// 👇️ set display name
TextInput.displayName = 'TextInput'

TextInput.defaultProps = {
  type: 'text',
  rows: 2,
  label: null,
  placeholder: null,
  required: false,
  charCount: false,
  classes: {},
  error: null,
  autoCorrect: 'off',
  autoComplete: 'ÑÖcompletes',
  disableFocusBorder: false,
  helperText: undefined,
  variant: 'standard',
  theme: 'default',
  limit: false,

  value: undefined,
  onChange: undefined,
  onBlur: undefined,
  defaultValue: undefined,

  maxLength: null,
  minLength: null,

  lengthCount: null,

  disabled: false
}

TextInput.propTypes = {
  type: PropTypes.string,
  rows: PropTypes.number,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  charCount: PropTypes.bool,
  classes: PropTypes.object,
  error: PropTypes.object,
  autoCorrect: PropTypes.oneOf(['on', 'off']),
  autoComplete: PropTypes.string,
  disableFocusBorder: PropTypes.bool,
  helperText: PropTypes.any,
  variant: PropTypes.oneOf(['standard', 'outlined', 'code']),
  theme: PropTypes.oneOf(['default', 'inverted', 'light', 'dark']),
  limit: PropTypes.bool,

  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  defaultValue: PropTypes.any,

  maxLength: PropTypes.number,
  minLength: PropTypes.number,

  lengthCount: PropTypes.number,

  disabled: PropTypes.bool
}

export const CTextInput = ({
  name,
  control,
  defaultValue,
  shouldUnregister,

  required,
  maxLength,
  minLength,
  max,
  min,
  pattern,
  validate,
  setValueAs,
  valueAsNumber,
  valueAsDate,
  disabled,
  deps,
  type,

  ...rest
}) => {
  const languageContext = useContext(LanguageContext)

  var registerOptions = {
    required: {
      value: required,
      message: message(languageContext.userLanguage, 'required')
    },
    maxLength: {
      value: maxLength,
      message: minLength
        ? maxLength === minLength
          ? message(languageContext.userLanguage, 'sizeLength', { maxLength })
          : message(languageContext.userLanguage, 'betweenLength', {
              minLength,
              maxLength
            })
        : message(languageContext.userLanguage, 'maxLength', { maxLength })
    },
    minLength: {
      value: minLength,
      message: maxLength
        ? maxLength === minLength
          ? message(languageContext.userLanguage, 'sizeLength', { maxLength })
          : message(languageContext.userLanguage, 'betweenLength', {
              minLength,
              maxLength
            })
        : message(languageContext.userLanguage, 'minLength', { minLength })
    },
    max: {
      value: max,
      message: message(languageContext.userLanguage, 'max', { max })
    },
    min: {
      value: min,
      message: message(languageContext.userLanguage, 'min', { min })
    },
    pattern:
      type === 'email'
        ? {
            value:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: message(languageContext.userLanguage, 'email')
          }
        : {
            value: pattern,
            message: message(languageContext.userLanguage, 'pattern')
          },
    validate: validate,
    valueAsNumber: valueAsNumber,
    valueAsDate: valueAsDate,
    setValueAs: setValueAs,
    disabled: disabled,
    deps: deps
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={registerOptions}
      shouldUnregister={shouldUnregister}
      render={({
        field: { onChange, onBlur, value, name },
        fieldState: { error }
      }) => {
        return (
          <TextInput
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={error}
            name={name}
            disabled={disabled}
            required={required}
            maxLength={maxLength}
            minLength={minLength}
            type={type}
            {...rest}
          />
        )
      }}
    />
  )
}

CTextInput.defaultProps = {
  defaultValue: undefined,
  shouldUnregister: false,

  valueAsNumber: false,
  valueAsDate: false,

  maxLength: null,
  minLength: null,
  required: false
}

CTextInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  shouldUnregister: PropTypes.bool,

  type: PropTypes.string,
  rows: PropTypes.number,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  charCount: PropTypes.bool,
  classes: PropTypes.object,
  error: PropTypes.object,
  autoCorrect: PropTypes.oneOf(['on', 'off']),
  autoComplete: PropTypes.string,
  disableFocusBorder: PropTypes.bool,
  helperText: PropTypes.any,
  variant: PropTypes.oneOf(['standard', 'outlined', 'code']),
  theme: PropTypes.oneOf(['default', 'inverted', 'light', 'dark']),
  limit: PropTypes.bool,

  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  pattern: PropTypes.any,
  validate: PropTypes.object,
  setValueAs: PropTypes.any,
  valueAsNumber: PropTypes.bool,
  valueAsDate: PropTypes.bool,
  disabled: PropTypes.bool,
  deps: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

export default TextInput
