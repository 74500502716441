import React, { useContext, useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { useLocation } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import SubscribeComponent from '../Components/SubscribeComponent'
import { LanguageContext } from '../../../context/language/context'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const Subscribe = () => {
  const location = useLocation()

  const [styles, setStyles] = React.useState(null)
  const [theme_container, setThemeContainer] = React.useState(null)
  const languageContext = useContext(LanguageContext)

  // Get the lookup key for the price from the previous page redirect.
  const [clientSecret] = useState(location.state.clientSecret)
  const [appearance, setApperance] = React.useState({
    theme: 'stripe',
    variables: {
      colorPrimary: '#fbb857',
      colorBackground: '#ffffff',
      colorText: '#262626',
      colorDanger: '#fbb857'
      // See all possible variables below
    }
  })

  useEffect(() => {
    if (theme_container) {
      console.log('theme container')
      setStyles(getComputedStyle(theme_container))
    } else {
      setThemeContainer(document.getElementById('diinco_theme_container'))
    }
  }, [theme_container, languageContext?.theme])

  useEffect(() => {
    if (styles) {
      setApperance({
        theme: 'stripe',
        variables: {
          colorPrimary: styles.getPropertyValue('--secondary-color'),
          colorBackground: styles.getPropertyValue('--bg-color'),
          colorText: styles.getPropertyValue('--text-color'),
          colorDanger: styles.getPropertyValue('--red-color'),
          iconColor: styles.getPropertyValue('--text-color')
          // See all possible variables below
        }
      })
    }
  }, [styles])

  const options = {
    clientSecret,
    appearance
  }

  return (
    <>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <SubscribeComponent />
        </Elements>
      )}
    </>
  )
}

export default Subscribe
