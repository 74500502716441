import React, { useContext, useEffect, useState } from 'react'
import './styles/style.css'

import _Utils from '../../context/utils'
import _Auth from '../../context/auth'

import { CDropZoneFiles } from '../../components/DropZoneFiles'
import { useParams, useHistory } from 'react-router-dom'
import ImageThumbsContainer from '../../components/ImageThumbsContainer'

import { LanguageContext } from '../../context/language/context'
import { _handleErrorMessage } from '../../functions/error.functions'
import { CSelectInput } from '../../components/SelectInput'

import { CTextInput } from '../../components/TextInput'
import { useForm } from 'react-hook-form'
import { CDropZoneCoverImage } from '../../components/DropZoneCoverImage'
import { serialize } from '../../functions/validation.functions'
import { Prompt } from 'react-router-dom'
import { serverValidation } from '../../functions/validation.functions'
import Button from '../../components/Button'
import StandardView from '../../layouts/StandardView'
import { compressFilesInFormData } from '../../functions/files.functions'
import ProjectPlansModal from '../../components/ProjectPlansModal'

export default function ReportForm() {
  const { id } = useParams()

  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  let history = useHistory()
  const { toggleLoader, enqueueNotification } = useContext(_Utils.Context)
  const { token } = useContext(_Auth.Context)
  const [disableButton, setDisableButton] = useState(false)

  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])

  const [openProjectPlansModal, setOpenProjectPlansModal] = useState(false)
  function onOpenProjectPlansModal() {
    setOpenProjectPlansModal(true)
  }

  function onCloseProjectPlansModal() {
    setOpenProjectPlansModal(false)
  }

  const {
    reset,
    setValue,
    getValues,
    control,
    setError,
    handleSubmit,
    formState
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: '',
      description: '',
      stage: '',
      files: [],
      documents: []
    }
  })

  useEffect(() => {
    if (formState.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
    } else {
      window.onbeforeunload = null
    }
  }, [formState.isDirty])

  async function onSubmit(data) {
    await toggleLoader(true)
    setDisableButton(true)
    data = { ...data }
    let formData = serialize(data, {
      dotsForObjectNotation: true,
      noArrayNotation: true,
      noFilesWithArrayNotation: true
    })

    formData = await compressFilesInFormData(formData, 'files')

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'projects/' + id + '/reports',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data'
          },
          body: formData
        }
      )

      await serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        async () => {
          enqueueNotification(languageContext.dictionary['success'], 'success')
          reset()
          await toggleLoader(false)
          setDisableButton(false)
          history.push('/Projects/' + id + '/Reports')
        },
        async (e) => {
          if (e?.error?.code === 8011 || e?.error?.code === 8013) {
            onOpenProjectPlansModal()
          }
          await toggleLoader(false)
          setDisableButton(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, e.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      setDisableButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  const [stages, setStages] = useState(null)
  useEffect(async () => {
    if (token) {
      try {
        let response = await fetch(
          process.env.REACT_APP_API_URL + 'projects/' + id + '/reports/create',
          {
            method: 'GET',
            credentials: 'include',
            mode: 'cors'
          }
        )

        await serverValidation(
          response,
          null,
          languageContext.userLanguage,
          async (data) => {
            setStages(data)
          },
          async (error) => {
            enqueueNotification(
              _handleErrorMessage(languageContext?.userLanguage, error.error),
              'error'
            )
          }
        )
      } catch (error) {
        enqueueNotification(
          _handleErrorMessage(languageContext?.userLanguage, error),
          'error'
        )
      }
    }
  }, [token])

  const [stageOption, setStageOption] = useState([
    {
      value: '',
      label: lang.noPhase
    }
  ])

  useEffect(() => {
    console.log(stages, 'stages')
    if (stages && stages.length > 1) {
      var stagesOption = [
        {
          value: '',
          label: lang.noPhase
        }
      ]
      stages.forEach((stage) => {
        if (stage.name !== 'General') {
          stagesOption.push({
            value: stage.id,
            label: stage.name
          })
        }
      })
      setStageOption(stagesOption)
    }
  }, [stages])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onError = () => {
    enqueueNotification(
      _handleErrorMessage(languageContext?.userLanguage, { code: 1000 }),
      'error'
    )
  }

  return (
    <StandardView
      title={lang.report}
      links={[
        {
          link: '/Dashboard/Projects',
          title: languageContext.dictionary.dashboard
        },
        {
          link: '/Projects/' + id + '/Reports',
          title: languageContext.dictionary.projectDashboard
        }
      ]}
    >
      <div className='uk-margin-medium-top uk-width-1-1 uk-flex uk-flex-column'>
        <form onSubmit={handleSubmit(onSubmit, onError)} noValidate>
          <Prompt
            when={formState.isDirty}
            message={JSON.stringify({
              title: languageContext.dictionary.discard,
              message: languageContext.dictionary.areYouSure,
              accept: languageContext.dictionary.accept,
              cancel: languageContext.dictionary.cancel
            })}
          />
          <CTextInput
            label={lang['reportTitle']}
            name='title'
            control={control}
            required
            maxLength={45}
          />
          <CTextInput
            type='textarea'
            label={languageContext.dictionary['desc']}
            name='description'
            control={control}
            rows={3}
            maxLength={2000}
            charCount
          />

          <CSelectInput
            name='stage'
            control={control}
            label={lang.phase}
            options={stageOption}
            required
          />

          <div className='uk-flex uk-flex-between'>
            <div className='uk-width-3-5'>
              <CDropZoneCoverImage
                name={'files'}
                control={control}
                ContainerClasses='diinco-project-form-dropzone-container cj-height-dropzone'
                ImageClasses='diinco-project-form-dropzone-image'
                onChange={() => forceUpdate()}
              />
              <ImageThumbsContainer
                control={control}
                thumbs={getValues('files')}
                containerStyle='diinco-thumbs-container-project-form'
                thumbStyle='diinco-thumbs-style-project-form'
                deleteButtonStyle='diinco-thumbs-delete-project-form'
                handleRemove={(event, file, index) => {
                  const newValue = getValues('files').filter(
                    (o, i) => i !== index
                  )
                  setValue('files', newValue)
                  forceUpdate()
                }}
              />
            </div>
            <div className='uk-width-1-3 uk-height-medium'>
              <CDropZoneFiles
                inputClasses='cj-height-dropzone-f'
                name='documents'
                control={control}
              />
            </div>
          </div>
          <div className='uk-flex uk-flex-between'>
            <Button
              onClick={() => {
                history.goBack()
              }}
              variant='text'
              size='small'
            >
              {languageContext.dictionary.cancel}
            </Button>
            <Button
              type='submit'
              size='small'
              color='secondary'
              disabled={disableButton}
            >
              {languageContext.dictionary['save']}
            </Button>
          </div>
        </form>
      </div>
      <ProjectPlansModal
        projectId={id}
        open={openProjectPlansModal}
        onClose={onCloseProjectPlansModal}
      />
    </StandardView>
  )
}
