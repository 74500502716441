import React, { useContext, useState } from 'react'

import _Utils from '../../context/utils'

import './styles/style.css'

import imgGrid1 from './assets/horizon.png'
import imgGrid2 from './assets/plano_s.png'
import imgGrid3 from './assets/walk_s.png'
import { LanguageContext } from '../../context/language/context'

import { CTextInput } from '../../components/TextInput'
import { _handleErrorMessage } from '../../functions/error.functions'
// import Form from "../../components/Form";
import { useForm } from 'react-hook-form'
import { serverValidation } from '../../functions/validation.functions'
import Button from '../../components/Button'

export default function AboutUs() {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const { toggleLoader, enqueueNotification } = useContext(_Utils.Context)
  const [disabled, setDisabled] = useState(false)

  const { control, reset, setError, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      email: '',
      message: ''
    }
  })

  async function onSubmit(data) {
    if (disabled) {
      enqueueNotification(lang.wait, 'error')
      return
    }

    await toggleLoader(true)
    setDisabled(true)

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'comments/send',
        {
          method: 'POST',
          headers: {
            Accept: 'multipart/form-data',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }
      )
      await serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        async () => {
          await toggleLoader(false)
          reset()
          setTimeout(() => {
            setDisabled(false)
          }, 60000)
          enqueueNotification(lang.messageSend, 'success')
        },
        async (error) => {
          await toggleLoader(false)
          setDisabled(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      setDisabled(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  return (
    <div className='uk-margin-large-bottom'>
      <div className='uk-grid uk-grid-match uk-child-width@s'>
        <div className='uk-width-1-1'>
          <div className='dc-relative'>
            <img src={imgGrid1} className='uk-height-1-1' alt='' />
            <span className='dc-overlay-bottom-left dc-uppercase'>
              {lang['innovation']}
            </span>
          </div>
        </div>
        <div className='uk-width-3-5@m '>
          <div className='dc-relative'>
            <img src={imgGrid2} className='uk-height-1-1' alt='' />
            <span className='dc-overlay-bottom-left dc-uppercase'>
              {lang['transparency']}
            </span>
          </div>
        </div>
        <div className='uk-width-2-5@m ' style={{ paddingLeft: 0 }}>
          <div className='dc-relative'>
            <img src={imgGrid3} className='uk-height-1-1' alt='' />
            <span className='dc-overlay-bottom-left dc-uppercase'>
              {lang['excellence']}
            </span>
          </div>
        </div>
      </div>

      <section>
        <div className='uk-container'>
          <div className='uk-margin-large-top dc-text-gray cj-aboutus-text-form'>
            {lang.info}
          </div>

          <div className='uk-margin-large-top'>
            <span className='dc-face-project-title'>{lang.contactUs}</span>

            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className='uk-grid uk-margin-top uk-flex uk-flex-right'>
                <div className='uk-width-1-2'>
                  <CTextInput
                    label={lang.name}
                    name='name'
                    control={control}
                    required
                    maxLength={120}
                  />
                </div>
                <div className='uk-width-1-2'>
                  <CTextInput
                    label={lang.email}
                    name='email'
                    control={control}
                    required
                    type='email'
                    maxLength={100}
                  />
                </div>
                <div className='uk-width-1-1'>
                  <CTextInput
                    label={lang.message}
                    placeholder={lang.messagePlaceholder}
                    name='message'
                    type='textarea'
                    control={control}
                    required
                    maxLength={2000}
                    rows={5}
                    charCount
                  />
                </div>

                <Button type='submit' disabled={disabled} color='dark'>
                  {languageContext.dictionary.send}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  )
}
