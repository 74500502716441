import React, { useContext, useEffect, useState } from 'react'
import './styles/style.css'

import ProfessionalCard from '../../components/ProfessionalCard'
//import ProjectSearchResult from "../../components/ProjectSearchResult";
import { AlbumSearchResult, ProjectSearchResult } from '../../components/'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import _Utils from '../../context/utils'
import NoData from '../../components/NoData'
import StandardView from '../../layouts/StandardView'
import Typography from '../../components/Typography'

import { LanguageContext } from '../../context/language/context'
import { _handleErrorMessage } from '../../functions/error.functions'
import { serverValidation } from '../../functions/validation.functions'

export default function Search() {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }
  const { setError } = useForm()
  const { search } = useParams()
  const [professionals, setProfessionals] = useState([])
  const [projects, setProjects] = useState([])
  const [albums, setAlbums] = useState([])
  const { enqueueNotification, toggleLoader } = useContext(_Utils.Context)

  async function Init() {
    toggleLoader(true)
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'search/tag/' + search,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          mode: 'cors'
        }
      )
      await serverValidation(
        response,
        setError,
        languageContext?.userLanguage,
        (data) => {
          console.log('aaaaaaaaaaaaa', data)
          setProfessionals(data.professionals)
          setProjects(data.projects)
          setAlbums(data.albums)
        },
        (error) => {
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    await toggleLoader(false)
  }

  useEffect(() => {
    Init(search)
  }, [search])

  return (
    <StandardView title={search ? '#' + search : lang['search']}>
      <>
        <Typography
          variant={'h4'}
          style={{ textTransform: 'uppercase' }}
          color='secondary'
        >
          {languageContext.dictionary['projects']}
        </Typography>
        <div className='uk-grid uk-grid-row-large'>
          {projects.length !== 0 ? (
            projects.map((project, index) => (
              <div className='uk-width-1-3' key={index + 'project'}>
                <ProjectSearchResult
                  id={project.id}
                  name={project.title}
                  img={project.banner}
                  tags={project.Tags}
                  selectedTag={search}
                  users={project.Users}
                  description={project.description}
                  city={project.Location.City.name}
                  state={project.Location.City.State.name}
                  country={project.Location.City.State.Country.name}
                />
              </div>
            ))
          ) : (
            <NoData />
          )}
        </div>
        <Typography
          variant={'h4'}
          style={{ textTransform: 'uppercase' }}
          color='secondary'
        >
          {languageContext.dictionary['albums']}
        </Typography>
        <div className='uk-grid uk-grid-row-large'>
          {albums && albums.length !== 0 ? (
            albums.map((album, index) => (
              <div className='uk-width-1-3' key={index}>
                <AlbumSearchResult
                  id={album.id}
                  name={album.title}
                  img={album.cover}
                  tags={album.Tags}
                  users={
                    album.Users && album.Users.length
                      ? album.Users
                      : album.Stage &&
                        album.Stage.Project &&
                        album.Stage.Project.User_Projects &&
                        album.Stage.Project.User_Projects.length
                      ? [album.Stage.Project.User_Projects[0].User]
                      : []
                  }
                  description={album.description}
                  city={album.Location.City.name}
                  state={album.Location.City.State.name}
                  country={album.Location.City.State.Country.name}
                />
              </div>
            ))
          ) : (
            <NoData />
          )}
        </div>
        <Typography
          variant={'h4'}
          style={{ textTransform: 'uppercase' }}
          color='secondary'
        >
          {languageContext.dictionary['professionals']}
        </Typography>
        <div className=' diinco-horizontal-overflow-container'>
          {professionals && professionals.length !== 0 ? (
            professionals.map((professional, index) => (
              <div className='uk-width-1-5' key={index + 'professional'}>
                <ProfessionalCard
                  img={professional.image}
                  name={professional.name}
                  nickname={professional.nickname}
                  profession={professional.Services.map(
                    (service) => service.title
                  ).join(' / ')}
                  city={professional.Locations[0]?.City.name}
                />
              </div>
            ))
          ) : (
            <NoData />
          )}
        </div>
      </>
    </StandardView>
  )
}
