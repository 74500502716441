import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import AsyncCreatableSelect from 'react-select/async-creatable'
import {
  selectStyle,
  selectStandardStyle,
  selectLightStyle
} from './styles/style.js'
import '../TextInput/styles/style.css'
import makeAnimated from 'react-select/animated'

import { LanguageContext } from '../../context/language/context'
import { message } from '../../functions/validation.functions.js'
import { Controller } from 'react-hook-form'

const animatedComponents = makeAnimated()
export default function SelectInput(props) {
  const {
    disableAnimation,
    isClearable,
    isRtl,

    label,
    error,
    helperText,
    helperTextRight,
    variant,

    name,
    placeholder,
    isDisabled,
    required,
    theme,
    disableFocusBorder,
    options,
    classes,
    onChange,
    value,
    isCreatable,
    openMenuOnFocus,

    isAsync,
    loadOptions,
    allowCreateWhileLoading,
    createOptionPosition,
    cacheOptions,

    ...rest
  } = props

  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const { containerClass, fieldClass, errorClass, labelClass } = classes

  let themeProp = {}
  if (theme !== 'default') {
    if (theme === 'inverted')
      themeProp['data-input-theme'] =
        languageContext.theme === 'light' ? 'dark' : 'light'
    else themeProp['data-input-theme'] = theme
  }

  return (
    <div
      className={[
        containerClass
          ? 'cj-input-label-container ' +
            (disableFocusBorder ? '' : 'cj-input-label-container-focus ') +
            containerClass
          : (disableFocusBorder ? '' : 'cj-input-label-container-focus ') +
            'cj-input-label-container uk-width-1-1 uk-margin-medium-bottom' +
            (error && !isDisabled
              ? variant === 'outlined'
                ? ' cj-error-select-border'
                : ' cj-error-select-border-bottom'
              : ''),
        isDisabled && 'cj-input-label-container-disabled'
      ].join(' ')}
      {...themeProp}
    >
      {isCreatable ? (
        isAsync ? (
          <AsyncCreatableSelect
            components={disableAnimation || animatedComponents}
            styles={
              variant === 'outlined'
                ? selectStyle
                : theme === 'light' ||
                  (theme === 'default' && languageContext.theme === 'light')
                ? selectLightStyle
                : selectStandardStyle
            }
            cacheOptions={cacheOptions}
            defaultOptions
            loadOptions={loadOptions}
            value={value}
            onChange={onChange}
            allowCreateWhileLoading={allowCreateWhileLoading}
            createOptionPosition={createOptionPosition}
            placeholder={placeholder}
            isClearable={isClearable}
            isDisabled={isDisabled}
            isRtl={isRtl}
            noOptionsMessage={() => languageContext.dictionary['noOptions']}
            loadingMessage={() => lang.loading}
            className={[fieldClass].join(' ')}
            classNamePrefix='cj-react-select'
            isCreatable
            openMenuOnFocus={openMenuOnFocus}
            {...rest}
          />
        ) : (
          <CreatableSelect
            components={disableAnimation || animatedComponents}
            styles={
              variant === 'outlined'
                ? selectStyle
                : theme === 'light' ||
                  (theme === 'default' && languageContext.theme === 'light')
                ? selectLightStyle
                : selectStandardStyle
            }
            options={options}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            isClearable={isClearable}
            isDisabled={isDisabled}
            isRtl={isRtl}
            noOptionsMessage={() => languageContext.dictionary['noOptions']}
            className={[fieldClass].join(' ')}
            classNamePrefix='cj-react-select'
            isCreatable
            openMenuOnFocus={openMenuOnFocus}
            {...rest}
          />
        )
      ) : (
        <Select
          components={disableAnimation || animatedComponents}
          styles={
            variant === 'outlined'
              ? selectStyle
              : theme === 'light' ||
                (theme === 'default' && languageContext.theme === 'light')
              ? selectLightStyle
              : selectStandardStyle
          }
          options={options}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          isClearable={isClearable}
          isDisabled={isDisabled}
          isRtl={isRtl}
          noOptionsMessage={() => languageContext.dictionary['noOptions']}
          className={[fieldClass].join(' ')}
          classNamePrefix='cj-react-select'
          openMenuOnFocus={openMenuOnFocus}
          {...rest}
        />
      )}
      {label && (
        <label
          className={
            labelClass
              ? labelClass +
                (value &&
                (value.length > 0 ||
                  (typeof value === 'object' && Object.keys(value).length > 0))
                  ? ' filled'
                  : '')
              : value &&
                (value.length > 0 ||
                  (typeof value === 'object' && Object.keys(value).length > 0))
              ? 'filled'
              : ''
          }
          htmlFor={name}
        >
          <span>
            {label}{' '}
            {required ? '' : '(' + languageContext.dictionary.optional + ')'}
          </span>
        </label>
      )}

      {(helperText || error || helperTextRight) && (
        <div
          className={
            error
              ? errorClass
                ? errorClass + ' uk-flex cj-helper-text'
                : 'cj-input-error uk-flex cj-helper-text'
              : 'uk-flex cj-helper-text'
          }
        >
          {error && !isDisabled && (
            <span>
              <span data-uk-icon='icon: warning; ratio: 0.6'></span>
              {Array.isArray(error) && error.length > 0
                ? error.find((x) => x !== undefined).message
                : error.message ?? Object.values(error)[0].message}
            </span>
          )}
          {helperText && (
            <span className={error ? 'uk-margin-small-left' : ''}>
              {helperText}
            </span>
          )}
          {helperTextRight && (
            <span className='uk-margin-auto-left'>{helperTextRight}</span>
          )}
        </div>
      )}
    </div>
  )
}

SelectInput.defaultProps = {
  variant: 'standard',
  classes: {},
  disableAnimation: false,
  isClearable: true,
  isRtl: false,
  defaultValue: null,
  label: '',

  name: '',
  placeholder: '',
  isDisabled: false,
  required: false,
  theme: 'default',
  options: [],
  error: null,
  helperText: undefined,
  helperTextRight: undefined,
  disableFocusBorder: false,
  onChange: undefined,
  isCreatable: false,
  isAsync: false,
  loadOptions: (inputValue, callback) => callback([]),
  allowCreateWhileLoading: true,
  createOptionPosition: 'first',
  openMenuOnFocus: true,
  cacheOptions: true,
  value: null
}
SelectInput.propTypes = {
  classes: PropTypes.object,
  variant: PropTypes.oneOf(['standard', 'outlined']),
  disableAnimation: PropTypes.bool,
  isClearable: PropTypes.bool,
  isRtl: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  helperText: PropTypes.any,
  helperTextRight: PropTypes.any,
  disableFocusBorder: PropTypes.bool,

  name: PropTypes.string,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  required: PropTypes.bool,
  theme: PropTypes.oneOf(['default', 'inverse', 'light', 'dark']),
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        label: PropTypes.any
      })
    ),
    PropTypes.array
  ]),
  onChange: PropTypes.func,
  isCreatable: PropTypes.bool,
  isAsync: PropTypes.bool,
  loadOptions: PropTypes.func,
  allowCreateWhileLoading: PropTypes.bool,
  createOptionPosition: PropTypes.oneOf(['first', 'last']),
  openMenuOnFocus: PropTypes.bool,
  cacheOptions: PropTypes.bool,

  value: PropTypes.any
}

export const CSelectInput = ({
  name,
  control,
  defaultValue,
  shouldUnregister,

  required,
  validate,
  setValueAs,
  disabled,
  deps,
  onChange: onChangeFromProps,
  useCustomOnChange,

  ...rest
}) => {
  const languageContext = useContext(LanguageContext)

  var registerOptions = {
    required: {
      value: required,
      message: message(languageContext.userLanguage, 'required')
    },
    validate: validate,
    setValueAs: setValueAs,
    disabled: disabled,
    deps: deps
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={registerOptions}
      shouldUnregister={shouldUnregister}
      render={({
        field: { onChange, onBlur, value, name },
        fieldState: { error }
      }) => {
        return (
          <SelectInput
            value={value}
            onChange={(e) => {
              if (onChangeFromProps) {
                if (useCustomOnChange) {
                  onChange(onChangeFromProps(e))
                  return
                } else onChangeFromProps(e)
              }
              onChange(e)
            }}
            onBlur={onBlur}
            error={error}
            name={name}
            isDisabled={disabled}
            required={required}
            {...rest}
          />
        )
      }}
    />
  )
}

CSelectInput.defaultProps = {
  defaultValue: undefined,
  shouldUnregister: false,

  useCustomOnChange: false,

  maxLength: null,
  minLength: null,
  required: false,
  onChange: undefined
}

CSelectInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  shouldUnregister: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

  classes: PropTypes.object,
  variant: PropTypes.oneOf(['standard', 'outlined']),
  disableAnimation: PropTypes.bool,
  isClearable: PropTypes.bool,
  isRtl: PropTypes.bool,

  useCustomOnChange: PropTypes.bool,

  required: PropTypes.bool,
  validate: PropTypes.object,
  setValueAs: PropTypes.any,
  disabled: PropTypes.bool,
  deps: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  helperText: PropTypes.any,
  helperTextRight: PropTypes.any,
  disableFocusBorder: PropTypes.bool,

  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  theme: PropTypes.oneOf(['default', 'inverse', 'light', 'dark']),
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        label: PropTypes.any
      })
    ),
    PropTypes.array
  ]),
  onChange: PropTypes.func,
  isCreatable: PropTypes.bool,
  isAsync: PropTypes.bool,
  loadOptions: PropTypes.func,
  allowCreateWhileLoading: PropTypes.bool,
  createOptionPosition: PropTypes.oneOf(['first', 'last']),
  openMenuOnFocus: PropTypes.bool,
  cacheOptions: PropTypes.bool
}

export const CCreatableSelectInput = ({
  name,
  control,
  defaultValue,
  shouldUnregister,

  required,
  validate,
  setValueAs,
  disabled,
  deps,
  onChange: onChangeFromProps,
  useCustomOnChange,

  ...rest
}) => {
  const languageContext = useContext(LanguageContext)

  var registerOptions = {
    required: {
      value: required,
      message: message(languageContext.userLanguage, 'required')
    },
    validate: validate,
    setValueAs: setValueAs,
    disabled: disabled,
    deps: deps
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={registerOptions}
      shouldUnregister={shouldUnregister}
      render={({
        field: { onChange, onBlur, value, name },
        fieldState: { error }
      }) => {
        return (
          <SelectInput
            value={value}
            onChange={(e) => {
              if (onChangeFromProps) {
                if (useCustomOnChange) onChange(onChangeFromProps(e))
                else onChangeFromProps(e)
              }
              onChange(e)
            }}
            onBlur={onBlur}
            error={error}
            name={name}
            isDisabled={disabled}
            required={required}
            {...rest}
          />
        )
      }}
    />
  )
}

CCreatableSelectInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  shouldUnregister: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

  useCustomOnChange: PropTypes.bool,

  required: PropTypes.bool,
  validate: PropTypes.object,
  setValueAs: PropTypes.any,
  disabled: PropTypes.bool,
  deps: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

  onChange: PropTypes.func
}

CCreatableSelectInput.defaultProps = {
  required: false,
  validate: undefined,
  setValueAs: undefined,
  disabled: false,
  deps: undefined,
  onChange: undefined,
  useCustomOnChange: false
}
