export default function (state, action) {
  const { payload, type } = action
  switch (type) {
    case 'TOGGLE_LOADER':
      return {
        ...state,
        loader: payload
      }

    case 'TOGGLE_LANG':
      return {
        ...state,
        lang: payload
      }

    case 'TOGGLE_SNACK':
      return {
        ...state,
        notification: payload
      }

    default:
      return state
  }
}
