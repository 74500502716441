import React, { useContext, useEffect, useRef, useState } from 'react'
import './styles/style.css'
import { CTextInput } from '../../components/TextInput'
import _Utils from '../../context/utils'
import _Auth from '../../context/auth'
import { useHistory } from 'react-router'
import ReCAPTCHA from 'react-google-recaptcha'

import { LanguageContext } from '../../context/language/context'
import { useForm } from 'react-hook-form'
import {
  message,
  serverValidation,
  validationRules
} from '../../functions/validation.functions'
import { Prompt } from 'react-router-dom'
import { CCheckBox } from '../../components/CheckBox'
import Button from '../../components/Button'
import { CSelectInput } from '../../components/SelectInput'
import _Services from '../../context/services'
import { _handleErrorMessage } from '../../functions/error.functions'
import CNumberFormat from '../../components/CNumberFormat'

import { useLocation } from 'react-router-dom'

import PropTypes from 'prop-types'

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}
export default function Signup() {
  const languageContext = useContext(LanguageContext)
  const { theme } = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const { toggleLoader, logo, enqueueNotification } = useContext(_Utils.Context)
  const { register: registerUser } = useContext(_Auth.Context)
  const history = useHistory()
  const type = useRef(null)
  const is_professional = useRef(null)
  const captcha = useRef(null)
  const [disableButton, setDisableButton] = useState(false)
  const [areHumanMessage, setAreHumanMessage] = useState(null)
  const { reset, getValues, control, setError, handleSubmit, formState } =
    useForm({
      mode: 'onChange',
      defaultValues: {
        name: '',
        nickname: '',
        email: '',
        country: null,
        phone: '',
        birth_date: '',
        password: '',
        repeat_password: '',
        terms: false
      }
    })

  async function onSubmit(data) {
    try {
      if (!captcha.current.getValue()) {
        setAreHumanMessage(
          message(languageContext.dictionary.areHuman, 'required')
        )
        throw new Error(3001)
      } else {
        setAreHumanMessage(null)
      }

      let pass = data.password
      let passr = data.repeat_password
      if (pass !== passr) {
        enqueueNotification(
          languageContext.dictionary.passwordsDifferent,
          'error'
        )
        throw new Error(3001)
      }

      setDisableButton(true)

      await toggleLoader(true)

      const response = await registerUser({
        name: data.name,
        password: data.password,
        email: data.email,
        phone: data.phone,
        nickname: data.nickname,
        country: data.country,
        birth_date: data.birth_date,
        prospectId: dataToken,
        type: typeUser === 'client' ? true : false
      })

      await serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        () => {
          reset(data)
          setDisableButton(false)
          enqueueNotification(lang['success'], 'success')
          history.push('/login')
        },
        (error) => {
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
          setDisableButton(false)
        }
      )
    } catch (error) {
      setDisableButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    toggleLoader(false)
    setDisableButton(false)
  }

  useEffect(() => {
    if (formState.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
    } else {
      window.onbeforeunload = null
    }
  }, [formState.isDirty])

  const { countries } = useContext(_Services.Context)

  const CountryValue = (props) => {
    const { innerProps, innerRef } = props
    return (
      <div
        ref={Object.keys(innerRef).length ? innerRef : null}
        {...innerProps}
        className='uk-flex uk-flex-between dc-country-value'
        style={{
          color: languageContext.theme === 'light' ? '#262626' : '#ffffff',
          gridArea: '1/1/2/3',
          marginLeft: '2px',
          marginRight: '2px',
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          boxSizing: 'border-box'
        }}
        data-uk-tooltip={
          'title: ' + props.data.name + ': ' + props.data.phone_ext
        }
      >
        {props.data.flag && (
          <img
            className='dc-country-value-img'
            src={props.data.flag}
            alt={props.data.name}
          />
        )}
        {!props.data.flag && props.data.name && (
          <div>{props.data.name + ': '}</div>
        )}
        <div>{props.data.phone_ext}</div>
      </div>
    )
  }

  CountryValue.defaultProps = {
    data: {
      flag: '',
      name: '',
      phone_ext: ''
    },
    innerProps: {},
    innerRef: {}
  }

  CountryValue.propTypes = {
    innerProps: PropTypes.object,
    innerRef: PropTypes.object,
    data: PropTypes.object
  }

  const CountryOption = (props) => {
    const { innerProps, innerRef } = props
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className='uk-flex uk-flex-between dc-country-option'
      >
        {props.data.flag && (
          <img
            className='dc-country-option-img'
            src={props.data.flag}
            alt={props.data.name}
          />
        )}
        {!props.data.flag && props.data.name && (
          <div>{props.data.name + ': '}</div>
        )}
        <div>{props.data.phone_ext}</div>
      </div>
    )
  }

  CountryOption.defaultProps = {
    innerProps: {},
    innerRef: {},
    data: {
      flag: '',
      name: '',
      phone_ext: ''
    }
  }

  CountryOption.propTypes = {
    innerProps: PropTypes.object,
    innerRef: PropTypes.any,
    data: PropTypes.object
  }

  let query = useQuery()
  const typeUser = query.get('type')
  const dataToken = query.get('solicitudeId')

  useEffect(() => {
    if (typeUser !== 'client' && typeUser !== 'professional')
      history.push('/signup-info')
  }, [])

  return (
    <div className='register_container'>
      <div className='card register-card'>
        <img
          src={theme === 'dark' ? logo.colour : logo.black}
          className='register_diinco'
          alt=''
        />
        <h1 className='register_diinco'>{lang['registerNow']}</h1>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <ul
            className='uk-tab uk-child-width-expand'
            data-uk-tab="{connect:'#my-id'}"
          >
            {typeUser === 'client' ? (
              <li className='uk-active'>
                <a ref={type} aria-expanded={true} className='leo-tab'>
                  {lang['asClient']}
                </a>
              </li>
            ) : (
              <li className='uk-active'>
                <a
                  ref={is_professional}
                  aria-expanded={false}
                  className='leo-tab'
                >
                  {lang['asProfessional']}
                </a>
              </li>
            )}
          </ul>

          <Prompt
            when={formState.isDirty}
            message={JSON.stringify({
              title: languageContext.dictionary.discard,
              message: languageContext.dictionary.areYouSure,
              accept: languageContext.dictionary.accept,
              cancel: languageContext.dictionary.cancel
            })}
          />

          <CTextInput
            name='name'
            label={languageContext.dictionary.name}
            control={control}
            required
            maxLength={100}
          />

          <CTextInput
            name='nickname'
            label='Nickname'
            control={control}
            required
            validate={{
              alphaDash: (v) =>
                validationRules.alphaDash(v, languageContext.userLanguage),
              noSpaces: (v) =>
                validationRules.noSpaces(v, languageContext.userLanguage)
            }}
            maxLength={20}
          />

          <CTextInput
            name='email'
            label='Email'
            control={control}
            required
            type='email'
            maxLength={100}
          />

          <div data-uk-grid className='uk-grid uk-child-width-1-3'>
            <div>
              <CSelectInput
                name='country'
                control={control}
                label={languageContext.dictionary['country']}
                options={countries.map((country) => {
                  return {
                    value: country.phone_ext,
                    name: country.name,
                    flag: country.flag,
                    phone_ext: country.phone_ext
                  }
                })}
                components={{
                  Option: CountryOption,
                  SingleValue: CountryValue
                }}
              />
            </div>
            <div className='uk-width-2-3'>
              <CNumberFormat
                name='phone'
                control={control}
                label={languageContext.dictionary.phoneNumber}
                required
                maxLength={10}
                minLength={10}
                format='(###) ###-####'
                mask='_'
              />
            </div>
          </div>

          <CTextInput
            type='date'
            name='birth_date'
            label={languageContext.dictionary.birthDate}
            control={control}
            required
          />

          <CTextInput
            name='password'
            type='password'
            label={languageContext.dictionary.newPassword}
            helperText={lang['alpha']}
            control={control}
            required
            validate={{
              noSpaces: (v) =>
                validationRules.noSpaces(v, languageContext.userLanguage),
              hasUppercase: (v) =>
                validationRules.hasUppercase(v, languageContext.userLanguage),
              hasLowercase: (v) =>
                validationRules.hasLowercase(v, LanguageContext.userLanguage),
              hasNumber: (v) =>
                validationRules.hasNumber(v, languageContext.userLanguage)
            }}
            maxLength={120}
            minLength={8}
          />

          <CTextInput
            name='repeat_password'
            type='password'
            label={languageContext.dictionary.confirmPassword}
            control={control}
            required
            validate={{
              same: (v) =>
                validationRules.same(
                  v,
                  languageContext.userLanguage,
                  getValues,
                  'password',
                  'passwordMatch'
                )
            }}
          />

          <div className='recaptcha-container uk-flex uk-flex-column uk-flex-left'>
            <ReCAPTCHA
              ref={captcha}
              sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
              theme={theme === 'dark' ? 'dark' : 'light'}
            />
            {areHumanMessage && (
              <div className='leo-error-message'>
                <span data-uk-icon='icon: warning; ratio: 0.6'></span>{' '}
                {areHumanMessage}
              </div>
            )}
          </div>

          <CCheckBox
            name='terms'
            control={control}
            required
            label={lang['acceptThe']}
            link={
              <a
                onClick={() => {
                  window.open('/TermsAndConditions')
                }}
                style={{
                  textTransform: 'none',
                  fontWeight: 'bold',
                  marginLeft: '5px'
                }}
              >
                {lang['termsAndConditions']}
              </a>
            }
          />

          <Button
            size='small'
            type='submit'
            disabled={disableButton}
            centered
            containerStyle={{ marginBottom: '20px' }}
          >
            {lang.register}
          </Button>

          <Button
            size='small'
            type='button'
            variant='text'
            centered
            containerStyle={{ marginBottom: '180px' }}
            onClick={() => history.push('/signup-info')}
          >
            {lang.back}
          </Button>
        </form>
      </div>
    </div>
  )
}
