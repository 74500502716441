import React from 'react'
import { useHistory } from 'react-router-dom'
import './styles/style.css'
import PropTypes from 'prop-types'

//Components
import Avatar from '../Avatar'
import Typography from '../Typography'
import banner from '../../assets/images/banner.webp'

//import Tag from '../Tag'

export function ProjectSearchResult(props) {
  const {
    id,
    img,
    name,
    tags = [],
    selectedTag,
    users = [],
    description,
    city,
    state,
    country
  } = props

  const history = useHistory()

  const handleClick = () => {
    history.push('/Preview/Project/' + id)
  }

  return (
    <div className='uk-width-1-1' onClick={handleClick}>
      <div className='project-search-result-container'>
        <div className='project-search-result-cover'>
          <img
            className='project-search-result-img'
            src={img ? process.env.REACT_APP_API_URL + img : banner}
            alt={name}
          />
          <div className='project-search-result-gradient'></div>
          <div className='project-search-result-professionals'>
            {users
              .slice(0, 3)
              .map((user, index) =>
                index < 2 ? (
                  <Avatar
                    key={'Avatar-Project-SR-' + index}
                    alt={user.name}
                    src={user.image}
                    link={'/Profile/' + user.nickname}
                  />
                ) : (
                  <Avatar
                    key={'Avatar-Project-SR-' + index}
                    alt={'+ 3'}
                    src='+'
                  />
                )
              )}
            {users && users.length === 1 && (
              <Typography variant={'body2'} style={{ color: 'black' }}>
                {users[0].name}
              </Typography>
            )}
          </div>
          <Typography className='project-search-result-title'>
            {name}
          </Typography>
          <div className='project-search-result-tags'>
            {/* {tags.map((tag, index) =>
              tag.name !== selectedTag ? (
                <Tag
                  key={'Tag-Project-SR-' + index}
                  tagName={tag.name}
                  editable={false}
                />
              ) : (
                <Tag
                  tagName={tag.name}
                  selected={true}
                  editable={false}
                  key={'Tag-Project-SR-' + index}
                />
              )
            )} */}
            {tags.map((tag, index) => (
              // <Tag
              //     tagName={tag.name}
              //     editable={false}
              //     key={tag.id}
              // />
              <Typography
                key={index + 'Typo'}
                theme='dark'
                variant={'body2'}
                onClick={(event) => {
                  event.stopPropagation()
                  history.push('/Tag/' + tag.name)
                }}
                style={
                  tag.name === selectedTag
                    ? { color: '#FFC107', marginLeft: 5, cursor: 'pointer' }
                    : {
                        marginLeft: 5,
                        cursor: 'pointer'
                      }
                }
              >
                #{tag.name}
              </Typography>
            ))}
          </div>
        </div>
        <Typography
          color='secondary'
          variant={'body2'}
          align='right'
          className='uk-margin-small-top'
        >
          {city}, {state}, {country}
        </Typography>
        <Typography
          variant={'body2'}
          className={'project-search-result-description'}
        >
          {description}
        </Typography>
      </div>
    </div>
  )
}

ProjectSearchResult.defaultProps = {
  id: 0,
  img: null,
  name: '',
  tags: [],
  selectedTag: '',
  users: [],
  description: '',
  city: '',
  state: '',
  country: ''
}

ProjectSearchResult.propTypes = {
  id: PropTypes.number.isRequired,
  img: PropTypes.string,
  name: PropTypes.string.isRequired,
  tags: PropTypes.array,
  selectedTag: PropTypes.string,
  users: PropTypes.array,
  description: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string
}
