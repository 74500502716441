import React, { useContext } from 'react'

import './styles/style.css'

import fbIcon from './assets/facebook-icon.svg'
import igIcon from './assets/instagram-icon.svg'
import msIcon from './assets/message-icon.svg'
import waIcon from './assets/whatsapp-icon.svg'

import { LanguageContext } from '../../context/language/context'
import Typography from '../Typography'
import { Link } from 'react-router-dom'

export default function Footer() {
  const { theme } = useContext(LanguageContext)
  return (
    <div className='diinco-footer-container uk-flex uk-flex-center uk-flex-middle'>
      <div className='uk-flex uk-width-1-2 links-container-footer'>
        <Link
          to={{
            pathname: '/TermsAndConditions',
            state: { privacity: false }
          }}
          style={{ textDecoration: 'none' }}
        >
          <Typography style={{ color: '#ea6732' }} variant='body2'>
            Términos y Condiciones del Servicio
          </Typography>
        </Link>
        <Link
          to={{
            pathname: '/TermsAndConditions',
            state: { privacity: true }
          }}
          style={{ textDecoration: 'none' }}
        >
          <Typography style={{ color: '#ea6732' }} variant='body2'>
            Aviso de Privacidad
          </Typography>
        </Link>
      </div>
      <div className='uk-flex uk-width-1-2 links-container-footer'>
        <div className='diinco-footer-item-container'>
          <img
            className='diinco-footer-icon'
            alt=''
            src={fbIcon}
            style={
              theme === 'light'
                ? {
                  filter:
                    'invert(57%) sepia(29%) saturate(5669%) hue-rotate(340deg) brightness(95%) contrast(94%)'
                }
                : {
                  filter:
                    'invert(52%) sepia(89%) saturate(2798%) hue-rotate(342deg) brightness(97%) contrast(89%)'
                }
            }
          />
          <a
            className='diinco-footer-text'
            href='https://www.facebook.com/Diinco-103458568500925'
            target='_blank'
            rel='noreferrer'
          >
            Diinco
          </a>
        </div>
        <div className='diinco-footer-item-container'>
          <img
            className='diinco-footer-icon'
            alt=''
            src={igIcon}
            style={
              theme === 'light'
                ? {
                  filter:
                    'invert(57%) sepia(29%) saturate(5669%) hue-rotate(340deg) brightness(95%) contrast(94%)'
                }
                : {
                  filter:
                    'invert(52%) sepia(89%) saturate(2798%) hue-rotate(342deg) brightness(97%) contrast(89%)'
                }
            }
          />
          <a
            className='diinco-footer-text'
            href='https://www.instagram.com/diincomx/'
            target='_blank'
            rel='noreferrer'
          >
            diincomx
          </a>
        </div>
        <div className='diinco-footer-item-container'>
          <img
            className='diinco-footer-icon'
            alt=''
            src={msIcon}
            style={
              theme === 'light'
                ? {
                  filter:
                    'invert(57%) sepia(29%) saturate(5669%) hue-rotate(340deg) brightness(95%) contrast(94%)'
                }
                : {
                  filter:
                    'invert(52%) sepia(89%) saturate(2798%) hue-rotate(342deg) brightness(97%) contrast(89%)'
                }
            }
          />
          <a
            className='diinco-footer-text'
            href='mailto:info@diinco.com'
            target='_blank'
            rel='noreferrer'
          >
            info@diinco.com
          </a>

        </div>
        <div className='diinco-footer-item-container'>
          <img
            className='diinco-footer-icon'
            alt=''
            src={waIcon}
            style={
              theme === 'light'
                ? {
                  filter:
                    'invert(57%) sepia(29%) saturate(5669%) hue-rotate(340deg) brightness(95%) contrast(94%)'
                }
                : {
                  filter:
                    'invert(52%) sepia(89%) saturate(2798%) hue-rotate(342deg) brightness(97%) contrast(89%)'
                }
            }
          />
          {/* <a
            className='diinco-footer-text'
            href='https://wa.me/message/HRMJGZJAC3HOA1'
            target='_blank'
            rel='noreferrer'
          >
            Escríbenos al WhatsApp
          </a> */}
        </div>
      </div>
    </div>
  )
}
