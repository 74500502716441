import React, { useContext, useEffect } from 'react'
import _Auth from '../../context/auth'

import { LanguageContext } from '../../context/language/context'
import './styles/style.css'
import { useHistory } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import Button from '../Button'
import CheckoutForm from './components/CheckoutForm'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

export default function PaymentForm() {
  const [clientSecret, setClientSecret] = React.useState('')
  const [styles, setStyles] = React.useState(null)
  const history = useHistory()
  const { self } = useContext(_Auth.Context)
  const languageContext = useContext(LanguageContext)
  const [appearance, setApperance] = React.useState({
    theme: 'stripe',
    variables: {
      colorPrimary: '#fbb857',
      colorBackground: '#ffffff',
      colorText: '#262626',
      colorDanger: '#fbb857'
      // See all possible variables below
    }
  })
  const [theme_container, setThemeContainer] = React.useState(null)
  React.useEffect(() => {
    if (self) {
      // Create PaymentIntent as soon as the page loads
      fetch(process.env.REACT_APP_API_URL + 'stripe', {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ items: [{ id: 'xl-tshirt' }] })
      })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret))
    }
  }, [self])

  useEffect(() => {
    if (theme_container) {
      console.log('theme container')
      setStyles(getComputedStyle(theme_container))
    } else {
      setThemeContainer(document.getElementById('diinco_theme_container'))
    }
  }, [theme_container, languageContext?.theme])

  useEffect(() => {
    if (styles) {
      setApperance({
        theme: 'stripe',
        variables: {
          colorPrimary: styles.getPropertyValue('--secondary-color'),
          colorBackground: styles.getPropertyValue('--bg-color'),
          colorText: styles.getPropertyValue('--text-color'),
          colorDanger: styles.getPropertyValue('--red-color'),
          iconColor: styles.getPropertyValue('--text-color')
          // See all possible variables below
        }
      })
    }
  }, [styles])

  const options = {
    clientSecret,
    appearance
  }

  return !self ? (
    <div className='diinco-unauthorized-payment-intent'>
      Please log in!
      <Button
        className='diinco-payment-plan-button'
        size='auto'
        onClick={() => {
          history.push('/login')
        }}
      >
        Log in!
      </Button>
    </div>
  ) : (
    <div className='diinco-stripe-form-container'>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  )
}
