import React, { useState, useEffect, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import _Auth from '../../../context/auth'

const Prices = () => {
  const [prices, setPrices] = useState([])
  const [subscriptionData, setSubscriptionData] = useState(null)

  const { self } = useContext(_Auth.Context)

  useEffect(() => {
    const fetchPrices = async () => {
      console.log('fetch')
      const { prices } = await fetch(
        process.env.REACT_APP_API_URL + 'stripe/config',
        {
          credentials: 'include',
          mode: 'cors'
        }
      ).then((r) => r.json())
      setPrices(prices)
    }
    fetchPrices()
  }, [])

  const createSubscription = async (priceId) => {
    const { subscriptionId, clientSecret } = await fetch(
      process.env.REACT_APP_API_URL + 'stripe/subscription',
      {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          priceId,
          customerId: self.customerId
        })
      }
    ).then((r) => r.json())

    setSubscriptionData({ subscriptionId, clientSecret })
  }

  if (subscriptionData) {
    return (
      <Redirect
        to={{
          pathname: '/subscribe',
          state: subscriptionData
        }}
      />
    )
  }

  return (
    <div>
      <h1>Select a plan</h1>

      <div className='price-list'>
        {prices.map((price) => {
          return (
            <div key={price.id}>
              <h3>{price.product.name}</h3>

              <p>${price.unit_amount / 100} / month</p>

              <button onClick={() => createSubscription(price.id)}>
                Select
              </button>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Prices
