import React, { useContext, useEffect } from 'react'
import { LanguageContext } from '../../context/language/context'
import Typography from '../../components/Typography'
import { IconButton } from '../../components/IconButton'
import Button from '../../components/Button'
import _Auth from '../../context/auth'
import ProjectPlansInfoModal from '../../components/ProjectPlansInfoModal'
import { serverValidation } from '../../functions/validation.functions'
import { _handleErrorMessage } from '../../functions/error.functions'
import _Utils from '../../context/utils'

const SubscriptionsClient = () => {
  const { self, token } = useContext(_Auth.Context)
  const { enqueueNotification } = useContext(_Utils.Context)
  const [priceId, setPriceId] = React.useState(null)

  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  async function Init() {
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'stripe/config',
        {
          method: 'GET',
          credentials: 'include',
          mode: 'cors'
        }
      )
      serverValidation(
        response,
        null,
        languageContext.userLanguage,
        (data) => {
          setPriceId(data.priceId)
        },
        (error) => {
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  useEffect(() => {
    if (!token) return
    Init()
  }, [token])

  const [openProjectPlansInfoModal, setOpenProjectPlansInfoModal] =
    React.useState(false)
  function onOpenProjectPlansInfoModal() {
    setOpenProjectPlansInfoModal(true)
  }
  function onCloseProjectPlansInfoModal() {
    setOpenProjectPlansInfoModal(false)
  }

  async function createCheckoutSession() {
    try {
      if (!priceId) return
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'stripe/create-checkout-session',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            priceId: priceId
          })
        }
      )
      serverValidation(
        response,
        null,
        languageContext.userLanguage,
        (data) => {
          window.location.href = data.url
        },
        (error) => {
          enqueueNotification(
            _handleErrorMessage(languageContext.userLanguage, error.error),
            'error'
          )
          history.push('/Dashboard')
        }
      )
    } catch (error) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  return (
    <div>
      <div className='uk-grid'>
        {/* IMAGE */}
        <div className='uk-width-1-3 uk-visible@m'>
          <div
            className='uk-flex uk-flex-center uk-flex-middle uk-height-viewport'
            style={{
              backgroundImage:
                'url(https://images.pexels.com/photos/8730006/pexels-photo-8730006.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderTopRightRadius: '20px',
              //border just the left and top side
              borderRight: '5px solid var(--bg-dark-color)',
              borderTop: '5px solid var(--bg-dark-color)'
            }}
          ></div>
        </div>

        <div className='uk-width-2-3'>
          <div className='uk-container'>
            {/* flex both elements are in the same row and together */}

            <div className='uk-flex uk-flex-middle uk-margin-top'>
              <IconButton
                icon={'arrowLeft'}
                variant='borderless'
                size='small'
                onClick={() => {
                  if (window.history.length === 1) {
                    window.location.href = '/'
                  } else window.history.back()
                }}
                className='cj-text-component-margin'
              />
              <Typography
                variant='h4'
                fontWeight={700}
                align={'left'}
                style={{
                  color: 'var(--bg-dark-color)'
                }}
              >
                {lang.client}
              </Typography>
            </div>
            <Typography>{lang.clientText}</Typography>

            {/* Create a table with three columns and where each row is divided with the <Divider /> component */}
            <table className='uk-table uk-table-divider uk-table-responsive uk-margin-top'>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <Typography
                      style={{
                        color: 'var(--bg-dark-color)'
                      }}
                      fontWeight={600}
                      align={'center'}
                    >
                      {lang.free}
                    </Typography>
                  </th>
                  <th>
                    <Typography
                      style={{
                        color: 'var(--bg-dark-color)'
                      }}
                      fontWeight={600}
                      align={'center'}
                    >
                      {lang.pro}
                    </Typography>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='uk-width-1-3'>
                    <Typography className={'uk-margin-small-top'}>
                      {lang.search}
                    </Typography>
                  </td>
                  <td className='uk-width-1-3'>
                    <IconButton
                      icon={'check'}
                      iconColor='primary'
                      variant='borderless'
                      className='cj-text-component-margin uk-margin-small-top'
                      style={{
                        width: '100%'
                      }}
                    />
                  </td>
                  <td className='uk-width-1-3'>
                    <IconButton
                      icon={'check'}
                      iconColor='primary'
                      variant='borderless'
                      className='cj-text-component-margin uk-margin-small-top'
                      style={{
                        width: '100%'
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='uk-width-1-3'>
                    <Typography className={'uk-margin-small-top'}>
                      {lang.projectParticipation}
                    </Typography>
                  </td>
                  <td className='uk-width-1-3'></td>
                  <td className='uk-width-1-3'>
                    <IconButton
                      icon={'check'}
                      iconColor='primary'
                      variant='borderless'
                      className='cj-text-component-margin uk-margin-small-top'
                      style={{
                        width: '100%'
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='uk-width-1-3'>
                    <div className='uk-flex uk-flex-middle uk-margin-small-top'>
                      <Typography>{lang.createProjects}</Typography>
                      <IconButton
                        icon={'question'}
                        variant='borderless'
                        iconColor='primary'
                        className='cj-text-component-margin'
                        data-uk-tooltip={
                          'title: ' + lang.createProjectsTooltip + '; pos: top'
                        }
                        onClick={onOpenProjectPlansInfoModal}
                      />
                    </div>
                  </td>
                  <td className='uk-width-1-3'></td>
                  <td className='uk-width-1-3'>
                    <IconButton
                      icon={'check'}
                      iconColor='primary'
                      variant='borderless'
                      className='cj-text-component-margin uk-margin-small-top'
                      style={{
                        width: '100%'
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='uk-width-1-3'>
                    <Typography className={'uk-margin-small-top'}>
                      {lang.requests}
                    </Typography>
                  </td>
                  <td className='uk-width-1-3'>
                    <Typography
                      align={'center'}
                      className={'uk-margin-small-top'}
                    >
                      3
                    </Typography>
                  </td>
                  <td className='uk-width-1-3'>
                    <Typography
                      align={'center'}
                      className={'uk-margin-small-top'}
                    >
                      {lang.unlimited}
                    </Typography>
                  </td>
                </tr>

                <tr>
                  <td className='uk-width-1-3'>
                    <Typography className={'uk-margin-small-top'}>
                      {lang.storage}
                    </Typography>
                  </td>
                  <td className='uk-width-1-3'>
                    <Typography
                      align={'center'}
                      className={'uk-margin-small-top'}
                    >
                      1GB
                    </Typography>
                  </td>
                  <td className='uk-width-1-3'>
                    <Typography
                      align={'center'}
                      className={'uk-margin-small-top'}
                    >
                      3GB
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* BUY BUTTON */}
            {!self ? (
              <div
                data-uk-grid
                className='uk-child-width-1-3 uk-grid-match uk-margin-top'
              >
                <div></div>
                <div>
                  <Button
                    size='auto'
                    color='primary'
                    onClick={() => {
                      window.location.href = '/signup?type=client'
                    }}
                  >
                    {lang.createYourAccount}
                  </Button>
                </div>
                <div></div>
              </div>
            ) : (
              <div
                data-uk-grid
                className='uk-child-width-1-3 uk-grid-match uk-margin-top'
              >
                <div></div>
                <div></div>
                <div>
                  <Button
                    size='auto'
                    color='primary'
                    onClick={() => createCheckoutSession('plan.priceId')}
                    disabled={!priceId}
                  >
                    {lang.obtainPackage}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <ProjectPlansInfoModal
        open={openProjectPlansInfoModal}
        onClose={onCloseProjectPlansInfoModal}
      />
    </div>
  )
}

export default SubscriptionsClient
