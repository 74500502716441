import React, { useContext } from 'react'
import Modal from 'react-responsive-modal'
import PropTypes from 'prop-types'
import { IconButton } from '../IconButton'
import Typography from '../Typography'
import './styles/style.css'
import PlansImage from './assets/plans.webp'
import Button from '../Button'
import { LanguageContext } from '../../context/language/context'
import { Link } from 'react-router-dom'

const PlansModal = ({ open, onClose, proPlan, showClose, creating }) => {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      showCloseIcon={showClose}
      closeIcon={<IconButton icon='close' component='div' />}
      classNames={{ modal: 'customModalCRM-dark-plan' }}
    >
      <Typography
        color={'secondary'}
        className={'uk-margin-top uk-margin-medium-bottom'}
      >
        {proPlan ? lang.limit : lang.upgrade}
      </Typography>

      <img
        src={PlansImage}
        crossOrigin='use-credentials'
        className='dc-fit-cover'
        alt=''
      />
      {!proPlan ? (
        <Link to='/subscription'>
          <Button size='auto' className='uk-margin-medium-top'>
            {lang.button}
          </Button>
        </Link>
      ) : (
        creating && (
          <Link to='/Albums/Admin'>
            <Button size='auto' className='uk-margin-medium-top'>
              {lang.back}
            </Button>
          </Link>
        )
      )}
    </Modal>
  )
}

export default PlansModal

PlansModal.defaultProps = {
  open: false,
  onClose: () => {},
  proPlan: false,
  showClose: true,
  creating: false
}
PlansModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  proPlan: PropTypes.bool,
  showClose: PropTypes.bool,
  creating: PropTypes.bool
}
