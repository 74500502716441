import React from 'react'
import './styles/style.css'
import PropTypes from 'prop-types'

export default function ProgressBar(props) {
  if (props.percentage <= 0) {
    return (
      <div className='fofo-bar-container'>
        <div className='fofo-bars'></div>
      </div>
    )
  } else if (props.percentage <= 33) {
    return (
      <div className='fofo-bar-container'>
        <div className='fofo-bars'>
          <div
            className='fofo-bar'
            style={{
              backgroundColor: 'var(--primary-color)',
              width: props.percentage + '%'
            }}
            key={1}
          ></div>
        </div>
      </div>
    )
  } else if (props.percentage <= 66) {
    return (
      <div className='fofo-bar-container'>
        <div className='fofo-bars'>
          <div
            className='fofo-bar'
            style={{ backgroundColor: 'var(--primary-color)', width: '33%' }}
            key={1}
          ></div>
          <div
            className='fofo-bar'
            style={{
              backgroundColor: 'var(--secondary-color)',
              width: props.percentage - 33 + '%'
            }}
            key={2}
          ></div>
        </div>
      </div>
    )
  } else if (props.percentage <= 100) {
    return (
      <div className='fofo-bar-container'>
        <div className='fofo-bars'>
          <div
            className='fofo-bar'
            style={{ backgroundColor: 'var(--primary-color)', width: '33%' }}
            key={1}
          ></div>
          <div
            className='fofo-bar'
            style={{ backgroundColor: 'var(--secondary-color)', width: '33%' }}
            key={2}
          ></div>
          <div
            className='fofo-bar'
            style={{
              backgroundColor: 'var(--hover-color)',
              width: props.percentage - 66 + '%'
            }}
            key={3}
          ></div>
        </div>
      </div>
    )
  } else {
    return (
      <div className='fofo-bar-container'>
        <div className='fofo-bars'></div>
      </div>
    )
  }
}

ProgressBar.defaultProps = {
  percentage: 0
}

ProgressBar.propTypes = {
  percentage: PropTypes.number
}
