const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#707070',
  borderStyle: 'solid',
  backgroundColor: 'transparent',
  color: '#cbcbcb',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer'
}

const focusedStyle = {
  borderColor: '#cbcbcb'
}

const acceptStyle = {
  borderColor: '#3abd5a'
}

const rejectStyle = {
  borderColor: '#c94643'
}

//Preview Styles
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
  marginBottom: 20
}

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #cbcbcb',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
}

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
}

export {
  baseStyle,
  focusedStyle,
  acceptStyle,
  rejectStyle,
  thumbsContainer,
  thumb,
  thumbInner,
  img
}
