import React from 'react'
import Typography from '../../components/Typography'
import { useContext } from 'react'
import { LanguageContext } from '../../context/language/context'
import Card from '../../components/Card'

const Subscriptions = () => {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }
  return (
    <div className='uk-container'>
      <Typography
        variant='h3'
        style={{ color: 'var(--primary-color)' }}
        fontWeight={700}
      >
        {lang.SubscriptionsDIINCO}
      </Typography>
      <Typography>{lang.text}</Typography>

      <div
        className='uk-grid uk-child-width-1-2@m uk-child-width-1-1@s uk-margin-top uk-grid-match'
        data-uk-grid
      >
        {/* First column */}
        <div>
          <Card
            color={'primary'}
            style={{ borderRadius: '15px', cursor: 'pointer' }}
            className={
              'uk-card-hover uk-padding-remove-bottom uk-padding-remove-horizontal'
            }
            onClick={() => {
              window.location.href = '/subscriptions/client'
            }}
          >
            <Typography
              style={{ color: 'var(--primary-color)' }}
              variant='h4'
              fontWeight={700}
              align={'center'}
            >
              {lang.client}
            </Typography>
            <Typography align={'center'} className={'uk-margin-bottom'}>
              {lang.clientText}
            </Typography>

            {/* PUT an image that is in the assets folder named pexels-alena-darmel-7641901.jpg that has the borther bottom radius of 15px */}
            <img
              src='https://images.pexels.com/photos/7641901/pexels-photo-7641901.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
              alt='pexels-alena-darmel-7641901'
              style={{
                borderBottomLeftRadius: '15px',
                borderBottomRightRadius: '15px'
              }}
            />
          </Card>
        </div>
        {/* Second column */}
        <div>
          <Card
            color={'secondary'}
            style={{ borderRadius: '15px', cursor: 'pointer' }}
            className={
              'uk-card-hover uk-padding-remove-bottom uk-padding-remove-horizontal'
            }
            onClick={() => {
              window.location.href = '/subscriptions/professional'
            }}
          >
            <Typography
              variant='h4'
              fontWeight={700}
              align={'center'}
              color={'secondary'}
            >
              {lang.professional}
            </Typography>
            <Typography align={'center'} className={'uk-margin-bottom'}>
              {lang.professionalText}
            </Typography>

            {/* PUT an image that is in the assets folder named pexels-alena-darmel-7642113.jpg that has the borther bottom radius of 15px */}
            <img
              src='https://images.pexels.com/photos/7642113/pexels-photo-7642113.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
              alt='pexels-alena-darmel-7642113'
              style={{
                borderBottomLeftRadius: '15px',
                borderBottomRightRadius: '15px'
              }}
            />
          </Card>
        </div>
      </div>
    </div>
  )
}

export default Subscriptions
