import React from 'react'
import ReactDOM from 'react-dom'
import '../../views/Login/styles/style.css'
import '../../views/Face/styles/style.css'
import Modal from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import Button from '../Button'
import { IconButton } from '../IconButton'

const delay = 0.2

const UserConfirmation = (message, callback) => {
  const container = document.createElement('div')
  document.body.appendChild(container)
  container.id = 'notification-container'

  const closeModal = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container)
    document.body.removeChild(container)
    callback(callbackState)
  }

  const text = JSON.parse(message)

  setTimeout(
    () =>
      // eslint-disable-next-line react/no-render-return-value
      ReactDOM.render(
        <Modal
          open={true}
          onClose={() => closeModal(false)}
          center
          classNames={{
            modal: 'dc-code-verification'
          }}
          closeOnOverlayClick={false}
          closeIcon={
            <IconButton
              color={'light'}
              iconColor={'primary'}
              icon='close'
              component='div'
            />
          }
        >
          <div className='uk-flex uk-flex-column uk-flex-middle uk-flex-center'>
            <h2
              className='dc-code-verification-title'
              style={{ maxWidth: 280 }}
            >
              {text.title}
            </h2>
            <div
              className='dc-code-verification-placeholder'
              style={{ maxWidth: 350 }}
            >
              {text.message}
            </div>

            <Button
              type='submit'
              color='secondary'
              onClick={() => closeModal(true)}
              size='small'
              containerStyle={{ marginBottom: 5, marginTop: 15 }}
            >
              {text.accept}
            </Button>
            {/* <button
                    type="submit"
                    className="dc-button-dark uk-margin-top dc-code-verification-button"
                    onClick={()=>closeModal(false)}
                >
                    {text.cancel}
                </button> */}
          </div>
        </Modal>,
        container
      ),
    delay * 1000
  )
}
export default UserConfirmation
