import React, { useState, useRef, useContext, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Modal } from 'react-responsive-modal'
import CustomGallery from '../CustomGallery'
import { useHistory } from 'react-router-dom'
// Import Swiper styles
import _Utils from '../../context/utils'
import { LanguageContext } from '../../context/language/context'
import PropTypes from 'prop-types'

import './styles/style.css'
import { IconButton } from '../IconButton'
import { useForm } from 'react-hook-form'
import { serialize } from '../../functions/validation.functions'
import { _handleErrorMessage } from '../../functions/error.functions'
import _Auth from '../../context/auth'
import ProjectPlansModal from '../ProjectPlansModal'

export default function ProjectGallery(props) {
  const { album, projectId } = props
  const [open, setOpen] = useState(false)
  const [current, setCurrent] = useState('')
  const history = useHistory()

  const languageContext = useContext(LanguageContext)
  const { hasPermission } = useContext(_Auth.Context)
  const onOpenModal = (index) => {
    setCurrent(index)
    setOpen(true)
  }
  const onCloseModal = () => setOpen(false)
  const myRef = useRef(null)
  let images = []
  if (album.cover) {
    images.push({
      url: process.env.REACT_APP_API_URL + album.cover
    })
  }
  album.Images.forEach((item) => {
    images.push({
      url: process.env.REACT_APP_API_URL + item.url
    })
  })

  //ADD FILE
  const { toggleLoader, enqueueNotification, notify } = useContext(
    _Utils.Context
  )

  const { reset, setValue, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      files: []
    }
  })

  const [files, setFiles] = useState(null)
  const [idAlbum, setIdAlbum] = useState(null)
  const inputFile = useRef(null)
  const addFiles = (id) => {
    setIdAlbum(id)
    inputFile.current.click()
  }

  const changeHandler = (event) => {
    // Retrieve FileList boject
    const files = event.target.files

    // Convert FileList to array
    const arr = Array.from(files)
    setValue('files', arr)
    setFiles(arr)
  }

  useEffect(() => {
    if (files) {
      handleUpdate()
    }
  }, [files])

  const [openProjectPlansModal, setOpenProjectPlansModal] = useState(false)
  function onOpenProjectPlansModal() {
    setOpenProjectPlansModal(true)
  }

  function onCloseProjectPlansModal() {
    setOpenProjectPlansModal(false)
  }

  const handleUpdate = async () => {
    await toggleLoader(true)
    const formData = serialize(getValues(), {
      dotsForObjectNotation: true,
      noArrayNotation: true,
      noFilesWithArrayNotation: true
    })
    formData.append('id', idAlbum)

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'albums/update',
        {
          method: 'PUT',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/formdata'
          },
          body: formData
        }
      )
      setFiles(null)
      reset({ files: [] })
      const answer = await response.json()
      if (answer.message === 'success') {
        notify(
          languageContext.dictionary['success'],
          setTimeout(() => {
            history.go(0)
          }, 1000)
        )
      } else {
        if (answer.code === 8011) {
          onOpenProjectPlansModal()

          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, answer),
            'error'
          )
        } else if (answer.code === 3001) {
          let message = ''
          if (languageContext.userLanguage === 'es')
            message = answer.details.errors[0].msg.es
          else message = answer.details.errors[0].msg.en
          enqueueNotification(message, 'error')
        } else if (answer.code === 8001) {
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, answer),
            'error'
          )
        } else throw new Error('error inesperado')
      }
    } catch (error) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    await toggleLoader(false)
  }

  return (
    <div ref={myRef}>
      <input
        type={'file'}
        style={{ display: 'none' }}
        multiple
        ref={inputFile}
        accept='image/*'
        onChange={changeHandler}
      />

      <Swiper
        slidesPerView={4}
        spaceBetween={30}
        freeMode={true}
        className='swiper-project-gallery'
      >
        {album && album.cover && (
          <SwiperSlide className='leo-swipes' onClick={() => onOpenModal(0)}>
            <img alt='' src={process.env.REACT_APP_API_URL + album.cover} />
          </SwiperSlide>
        )}
        {album &&
          album.Images &&
          album.Images.map((ima, index) => {
            return (
              <SwiperSlide
                className='leo-swipes'
                key={index}
                onClick={() => onOpenModal(index + 1)}
              >
                <img alt='' src={process.env.REACT_APP_API_URL + ima.url} />
              </SwiperSlide>
            )
          })}
        {hasPermission('album-update', 'or', projectId, 'project') && (
          <SwiperSlide className='leo-swipes'>
            <IconButton
              icon='image'
              shape='square'
              variant='outlined'
              color='grey'
              size='large'
              borderWidth='100%'
              borderHeight='100%'
              cardStyle={{ padding: 0 }}
              // onClick={() =>
              //     history.push("/Albums/Admin/" + album.id)
              // }
              onClick={() => addFiles(album.id)}
              style={{ width: '98%', height: '98%' }}
            />
          </SwiperSlide>
        )}
      </Swiper>

      <Modal
        open={open}
        onClose={onCloseModal}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        container={myRef.current}
        classNames={{ modal: 'customModalGalleryProject' }}
      >
        <CustomGallery images={images} navigation={true} current={current} />
      </Modal>
      {projectId && (
        <ProjectPlansModal
          projectId={projectId}
          open={openProjectPlansModal}
          onClose={onCloseProjectPlansModal}
        />
      )}
    </div>
  )
}

ProjectGallery.propTypes = {
  album: PropTypes.object,
  projectId: PropTypes.number
}

ProjectGallery.defaultProps = {
  album: {},
  projectId: 0
}
