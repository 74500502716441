import React from 'react'
import './styles/style.css'
import PropTypes from 'prop-types'
export default function HomeLoader({ className, ...rest }) {
  return (
    <div
      {...rest}
      data-uk-spinner='ratio: 1'
      className={'diinco-loader-spinner ' + (className ?? '')}
    />
  )
}

HomeLoader.propTypes = {
  className: PropTypes.string
}
