import React, { useContext, useEffect, useState, useRef } from 'react'
import _Auth from '../../context/auth'
import _Utils from '../../context/utils'
import './styles/style.css'

import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'

import { LanguageContext } from '../../context/language/context'
import { _handleErrorMessage } from '../../functions/error.functions'
import { serverValidation } from '../../functions/validation.functions'

import Error from '../Error'
import Typography from '../../components/Typography'
import Divider from '../../components/Divider'
import Button from '../../components/Button'
import { IconButton } from '../../components/IconButton'
import { compressFilesInFormData } from '../../functions/files.functions'
import { Modal } from 'react-responsive-modal'
import { useForm } from 'react-hook-form'
import CloseIcon from './assets/close.svg'
import { CTextInput } from '../../components/TextInput'
import Avatar from '../../components/Avatar'
import banner from '../../assets/images/banner.webp'
import defaultImage from './assets/img/image-solid.svg'
import { SocialSharer } from '../../components/SocialSharer'
import PlansModal from '../../components/PlansModal'

export default function Profile() {
  const { nickname } = useParams()
  const history = useHistory()
  const { theme } = useContext(LanguageContext)
  const { self, reloadData } = useContext(_Auth.Context)
  const [user, setUser] = useState(null)
  const [didMount, setDidMount] = useState(false)
  const { HomeLoader, toggleLoader, enqueueNotification, notify } = useContext(
    _Utils.Context
  )
  const [loader, setLoader] = useState(false)

  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  useEffect(() => {
    async function Init() {
      toggleLoader(true)
      setLoader(true)
      try {
        let data = await fetch(
          process.env.REACT_APP_API_URL + 'professional/getone/' + nickname,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        data = await data.json()
        if (data.message !== 'not-found' && data.Roles && data.Roles.length) {
          data.Roles.forEach((role) => {
            if (role.name === 'client') {
              history.push('/Dashboard')
            }
          })
          setUser(data)
        } else {
          setUser(null)
        }
        toggleLoader(false)
        setLoader(false)
      } catch (error) {
        toggleLoader(false)
        setLoader(false)
      }
    }
    setDidMount(true)
    Init()
  }, [])

  function youtube_parser(url) {
    var ID = ''
    url = url
      .replace(/(>|<)/gi, '')
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
    if (url[2] !== undefined) {
      ID = url[2].split(/[^0-9a-z_-]/i)
      ID = ID[0]
    } else {
      ID = url
    }
    return ID
  }

  const [file, setFile] = useState(null)
  const [image, setImage] = useState(null)

  let bannerInput = useRef(null)
  let imageInput = useRef(null)

  function handleBannerChange() {
    let input = bannerInput.current
    input.click()
  }

  function handleImageChange() {
    let input = imageInput.current
    input.click()
  }

  const changeHandler = (event) => {
    setFile(event.target.files[0])
  }

  const imageHandler = (event) => {
    setImage(event.target.files[0])
  }

  useEffect(() => {
    if (file) {
      handleSubmission()
    }
  }, [file])

  useEffect(() => {
    if (image) {
      handleImageSubmission()
    }
  }, [image])

  const handleSubmission = async () => {
    await toggleLoader(true)
    let formData = new FormData()

    formData.append('File', file)

    formData = await compressFilesInFormData(formData, 'File')

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL +
          'professional/' +
          self?.id +
          '/updateBanner',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/formdata'
          },

          body: formData
        }
      )

      await serverValidation(
        response,
        null,
        languageContext.userLanguage,
        async (data) => {
          notify(languageContext.dictionary['success'])
          let userAux = user
          userAux.banner = data.banner
          setUser(userAux)
          reloadData()
        },
        async (error) => {
          if (error?.error?.code === 8011) {
            onOpenPlansModal()
          }
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }

    setFile(null)
    await toggleLoader(false)
  }

  const handleImageSubmission = async () => {
    await toggleLoader(true)
    let formData = new FormData()

    formData.append('File', image)

    formData = await compressFilesInFormData(formData, 'File')

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL +
          'professional/' +
          user?.id +
          '/updateImage',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/formdata'
          },

          body: formData
        }
      )

      await serverValidation(
        response,
        null,
        languageContext.userLanguage,
        async (data) => {
          notify(languageContext.dictionary['success'])
          let userAux = user
          userAux.image = data.image
          setUser(userAux)
          reloadData()
        },
        async (error) => {
          if (error?.error?.code === 8011) {
            onOpenPlansModal()
          }
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }

    setFile(null)
    await toggleLoader(false)
  }

  const [disabled, setDisabled] = useState(false)
  const [open, setOpen] = useState(false)
  const myRef = useRef(null)

  const { reset, control, setError, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: '',
      description: ''
    }
  })

  const [openPlansModal, setOpenPlansModal] = useState(false)
  function onOpenPlansModal() {
    setOpenPlansModal(true)
  }

  function onClosePlansModal() {
    setOpenPlansModal(false)
  }

  async function sendSolicitude(data) {
    data = { ...data, email: user?.email || '' }
    setDisabled(true)
    await toggleLoader(true)

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'dashboard/taskClient',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }
      )
      serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        async () => {
          enqueueNotification(languageContext.dictionary['success'], 'success')
          await toggleLoader(false)
          setDisabled(false)
          setOpen(false)
          reset()
          history.push('/Dashboard')
        },
        async (error) => {
          if (error?.error?.code === 8010) {
            onOpenPlansModal()
          }
          await toggleLoader(false)
          setDisabled(false)
          setOpen(false)
          reset()
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      setDisabled(false)
      reset()
      await toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }

    await toggleLoader(false)
    setDisabled(false)
  }

  async function showAlbum(id) {
    history.push('/Albums/' + id)
  }

  const [ratings, setRatings] = useState(null)
  const [badges, setBadges] = useState(null)

  function hash(o) {
    return o.name
  }

  useEffect(() => {
    if (user) {
      let provisional = []
      let provbadges = []
      for (let index = 0; index < user.User_Projects.length; index++) {
        const relation = user.User_Projects[index]
        for (let j = 0; j < relation.Ratings.length; j++) {
          const rating = relation.Ratings[j]
          provisional.push(rating)
          rating.Badges.forEach((badge) => {
            provbadges.push(badge)
          })
        }
      }
      setRatings(provisional)

      const result = Object.entries(
        provbadges.reduce((acc, { id }) => {
          acc[id] = (acc[id] || 0) + 1
          return acc
        }, {})
      ).map(([k, v]) => ({ id: parseInt(k, 10), count: v }))

      result.sort(function (a, b) {
        return b.count - a.count
      })

      let prov2 = []

      result.forEach((element) => {
        for (let index = 0; index < provbadges.length; index++) {
          const badge = provbadges[index]
          if (badge.id === element.id) {
            prov2.push(badge)
          }
        }
      })

      var hashesFound = {}

      prov2.forEach(function (o) {
        hashesFound[hash(o)] = o
      })

      var clean = Object.keys(hashesFound).map(function (k) {
        return hashesFound[k]
      })

      setBadges(clean)
    }
  }, [user])

  return loader ? (
    <div className='uk-container'>
      <h1
        style={{
          marginTop: '270px',
          alignSelf: 'center',
          textAlign: 'center'
        }}
      >
        <HomeLoader />
      </h1>
    </div>
  ) : !user && didMount ? (
    <Error />
  ) : user ? (
    <div className='uk-container'>
      <div className='uk-margin-medium-top' style={{ position: 'relative' }}>
        {
          self && self?.id === user.id && (
            <div>
              <IconButton
                icon='edit'
                className='oski-banner-edit'
                onClick={handleBannerChange}
              />
              <form>
                <input
                  type={'file'}
                  style={{ display: 'none' }}
                  ref={bannerInput}
                  accept='image/*'
                  onChange={changeHandler}
                />
              </form>
            </div>
          )
          // <div className="oski-banner-edit" onClick={handleBannerChange}>
          // 	<form>
          // 		<input type={'file'} style={{ display: "none" }} ref={bannerInput} accept="image/*" onChange={changeHandler} />
          // 	</form>
          // 	<img src={EditBanner} />
          // </div>
        }
        <img
          src={
            user.banner ? process.env.REACT_APP_API_URL + user.banner : banner
          }
          alt='banner'
          className='uk-width-1-1 uk-border-rounded'
          style={{ maxHeight: '350px', objectFit: 'cover' }}
        />
      </div>

      <div
        className='uk-width-1-1 uk-grid uk-grid-match'
        style={
          badges && badges.length > 0
            ? { paddingTop: 40, paddingBottom: 50 }
            : {}
        }
      >
        <div className='uk-width-1-4 dc-position-relative'>
          <div className='position-absolute-profile-img'>
            <div style={{ position: 'relative' }}>
              {user && user.image ? (
                <img
                  src={process.env.REACT_APP_API_URL + user.image}
                  className={
                    self?.id === user.id
                      ? 'uk-width-3-5 dc-profile-img uk-border-circle uk-align-center oski-img-clickable'
                      : 'uk-width-3-5 dc-profile-img uk-border-circle uk-align-center'
                  }
                  alt='profile'
                  onClick={self?.id === user.id ? handleImageChange : null}
                />
              ) : (
                <>
                  <Avatar
                    src={user.image}
                    alt={user.name}
                    style={{
                      marginTop: 5,
                      marginBottom: 50,
                      marginLeft: 50
                    }}
                    height='200px'
                    width='200px'
                    size='large'
                    onClick={self?.id === user.id ? handleImageChange : null}
                  />
                </>
              )}
              {user && self?.id === user.id && (
                <Typography
                  className='oski-text-clickable'
                  onClick={handleImageChange}
                >
                  {lang.changeImage}
                </Typography>
              )}
              <form>
                <input
                  type={'file'}
                  style={{ display: 'none' }}
                  ref={imageInput}
                  accept='image/*'
                  onChange={imageHandler}
                />
              </form>
              {badges && badges.length > 0 && (
                <div
                  style={{
                    marginTop: '-22px',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  {badges.map((badge, index) => {
                    return (
                      <img
                        key={index}
                        src={badge.url}
                        alt='badge'
                        style={{
                          width: 40,
                          height: 40,
                          marginLeft: 4,
                          marginRight: 4
                        }}
                      />
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='uk-width-3-4 uk-grid'>
          <div className='uk-width-2-3 uk-flex uk-flex-column'>
            <article className='uk-article uk-margin-left'>
              <Typography
                variant='h4'
                style={{ marginBottom: 5, marginTop: 10 }}
              >
                {user.name}
              </Typography>

              {user?.User_Professions &&
                user.User_Professions.length > 0 &&
                user.User_Professions.map((user_profession, index) => (
                  <div key={index}>
                    <Typography
                      style={{
                        textTransform: 'capitalize'
                      }}
                    >
                      {user_profession.Profession?.name}
                      &nbsp;
                      {user_profession.User_Profession_Specialties.map(
                        (user_profession_specialties) =>
                          lang.specialtyOn +
                          ' ' +
                          user_profession_specialties.Specialty?.name
                      )}
                    </Typography>
                  </div>
                ))}
              {user.Locations.length > 0 ? (
                <Typography style={{ marginBottom: 15 }}>
                  {user.Locations[0].City.name}
                  {' / '}
                  {user.Locations[0].City.State.name}
                  {' / '}
                  {user.Locations[0].City.State.Country.name}
                </Typography>
              ) : (
                <Typography>{lang['location']}</Typography>
              )}
            </article>
          </div>
          <div
            className='uk-width-1-3 uk-flex uk-flex-wrap uk-flex-column uk-flex-center uk-margin-small-top uk-margin-small-bottom'
            ref={myRef}
            style={{
              position: 'relative'
            }}
          >
            {self && self?.id !== user.id && (
              <Button
                onClick={() => setOpen(true)}
                // onClick={()=> window.open('https://api.whatsapp.com/send?phone=7774942332', '_blank')}
                containerStyle={{ marginBottom: 0 }}
              >
                {lang.sendS}
              </Button>
            )}

            <div
              className=''
              style={{
                position: 'absolute',
                right: '-70px'
              }}
            >
              <SocialSharer
                route={'Profile/'}
                id={user.nickname}
                url={
                  user && process.env.REACT_APP_URL + 'Profile/' + user.nickname
                }
                title={user && user.name}
              />
            </div>
          </div>
        </div>

        <Modal
          container={myRef.current}
          open={open}
          onClose={() => setOpen(false)}
          center
          closeIcon={
            <img
              src={CloseIcon}
              alt='close'
              style={
                theme === 'light'
                  ? {
                      filter:
                        'brightness(0) saturate(100%) invert(17%) sepia(0%) saturate(38%) hue-rotate(240deg) brightness(99%) contrast(106%)'
                    }
                  : {}
              }
            />
          }
          classNames={
            theme === 'dark'
              ? {
                  modal: 'customModalCRM-dark'
                }
              : {
                  modal: 'customModalCRM-light'
                }
          }
        >
          <h3 className='prospect-modal-title'>{lang.newProspect}</h3>
          <form onSubmit={handleSubmit(sendSolicitude)} noValidate>
            <CTextInput
              label={languageContext.dictionary.title}
              name='title'
              control={control}
              required
              maxLength={255}
            />
            <CTextInput
              type='textarea'
              label={languageContext.dictionary.desc}
              name='description'
              control={control}
              maxLength={2000}
              charCount
              rows={3}
            />

            <div className='create-project'>
              <Button disabled={disabled} color='secondary' type='submit'>
                {languageContext.dictionary.send}
              </Button>
            </div>
          </form>
        </Modal>
      </div>

      <Divider style={{ marginTop: 15 }} />

      <div className='uk-width-1-1 uk-grid uk-grid-collapse'>
        <div className='uk-width-1-3 uk-margin-top'>
          {/* CARRERA */}
          <div className='uk-flex uk-flex-column uk-flex-center'>
            {user?.User_Occupations.map((user_occupation, index) => (
              <Typography
                key={index}
                align={'center'}
                style={{
                  color: '#f9b74e',
                  fontSize: 15,
                  marginBottom: 0,
                  textTransform: 'capitalize'
                }}
              >
                {
                  languageContext.dictionary.occupations[
                    user_occupation.Occupation?.name
                  ]
                }
              </Typography>
            ))}
            {user?.User_Universities.map((user_university, index) => (
              <>
                <Typography
                  key={index}
                  align={'center'}
                  style={{
                    fontSize: 15,
                    marginBottom: 0,
                    textTransform: 'capitalize'
                  }}
                >
                  {user_university.University?.name}
                </Typography>
                {user_university.User_University_Careers.map(
                  (user_university_Career, index2) => (
                    <Typography
                      key={index2}
                      align={'center'}
                      style={{
                        color: '#f9b74e',
                        fontSize: 15,
                        marginBottom: 0,
                        textTransform: 'capitalize'
                      }}
                    >
                      {user_university_Career.Career?.name}
                    </Typography>
                  )
                )}
              </>
            ))}

            {user?.Services && (
              <Typography
                align='center'
                style={{
                  fontSize: 15,
                  marginBottom: 0
                }}
              >
                {user &&
                  user.Services &&
                  user.Services.map((service, i) => {
                    return (i !== 0 ? '/ ' : '') + lang[service.id]
                  })}
              </Typography>
            )}

            {user?.cv_url && (
              <a
                href={process.env.REACT_APP_API_URL + user.cv_url}
                target={'_blank'}
                className='oski-orange-link'
                rel='noreferrer'
              >
                CV: {user?.cv_title ? user.cv_title : lang.showCV}
              </a>
            )}
          </div>

          {/* DESCRIPTION */}
          <div className='uk-panel' style={{ margin: '20px 0px' }}>
            <Typography style={{ fontSize: 15 }} align='justify'>
              {user && user.description
                ? user.description
                : lang['noDescription']}
            </Typography>
          </div>

          <Divider />

          {/* COMENTARIOS */}
          {ratings && ratings.length > 0 && (
            <div className='uk-flex uk-flex-column uk-flex-center'>
              <Typography
                align={'center'}
                style={{ color: '#f9b74e' }}
                className='uk-margin-top'
              >
                {lang.comments}
              </Typography>

              {ratings.map((rating) => (
                <>
                  <Typography variant={'body'}>{rating.User.name}</Typography>
                  <Typography
                    style={{
                      fontSize: 15,
                      marginBottom: 20
                    }}
                  >
                    {rating.comment}
                  </Typography>
                </>
              ))}
            </div>
          )}
          {/* HASHTAGS */}
          <div
            className='uk-flex uk-flex-wrap uk-margin-bottom'
            style={{ gap: 5 }}
          >
            {user &&
              user.Tags &&
              user.Tags.length > 0 &&
              user.Tags.map((tag, index) => (
                <a key={index + 'tag'} href={'/tag/' + tag.name}>
                  <Typography key={index} style={{ fontSize: '13px' }}>
                    {'#' + tag.name}
                  </Typography>
                </a>
              ))}
          </div>
        </div>
        {/* GALLERY */}
        <div className='uk-width-2-3 uk-padding uk-margin-top uk-padding-remove-top oski-max-height'>
          {user &&
            !user.video &&
            (!user.Albums || user.Albums.length === 0) &&
            user?.id === self?.id && (
              <>
                <div
                  className='oski-empty-profile'
                  onClick={() => history.push('/Albums/Admin')}
                >
                  <img
                    src={defaultImage}
                    alt='default'
                    style={{
                      width: 50,
                      filter: 'invert(50%)'
                    }}
                  />
                </div>
              </>
            )}
          {user && user.video && (
            <iframe
              width='100%'
              height='400px'
              src={
                'https://www.youtube.com/embed/' + youtube_parser(user.video)
              }
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; full-screen'
              allowFullScreen
              style={{ marginBottom: '15px' }}
            />
          )}

          {user && user.Albums && user.Albums.length > 0 && (
            <div data-uk-grid='masonry: true' className='uk-grid-small'>
              {user.Albums.map((album, index) => (
                <img
                  key={index}
                  src={process.env.REACT_APP_API_URL + album.cover}
                  className='oski-masonry'
                  alt='album'
                  onClick={() => showAlbum(album.id)}
                />
              ))}
              {user &&
                user.Albums.length === 1 &&
                user.Albums[0].Images &&
                user.Albums[0].Images.length > 0 &&
                user.Albums[0].Images.map((image, index) => (
                  <img
                    key={index}
                    src={process.env.REACT_APP_API_URL + image.url}
                    className='oski-masonry'
                    alt='album'
                    onClick={() => showAlbum(user.Albums[0].id)}
                  />
                ))}
            </div>
          )}
        </div>
      </div>
      <PlansModal open={openPlansModal} onClose={onClosePlansModal} />
    </div>
  ) : (
    <div className='uk-container'>
      <h1
        style={{
          marginTop: '270px',
          alignSelf: 'center',
          textAlign: 'center'
        }}
      >
        {lang['professional']}
      </h1>
    </div>
  )
}
