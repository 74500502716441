import React, { useContext, useState, useEffect, useRef } from 'react'
import { useHistory, Link } from 'react-router-dom'
import Card from '../../../components/Card'
import Divider from '../../../components/Divider'
import Typography from '../../../components/Typography'
import { LanguageContext } from '../../../context/language/context'
import _Utils from '../../../context/utils'
import { _handleErrorMessage } from '../../../functions/error.functions'
import { serverValidation } from '../../../functions/validation.functions'
import _Auth from '../../../context/auth'
import NoData from '../../../components/NoData'
import { format } from 'date-fns'
import Button from '../../../components/Button'
import Avatar from '../../../components/Avatar'
import { IconButton } from '../../../components/IconButton'
import { useForm } from 'react-hook-form'
import { CTextInput } from '../../../components/TextInput'
import Modal from 'react-responsive-modal'
import PlansModal from '../../../components/PlansModal'
import DiincoLogo from '../../../assets/images/logo_white.png'

const ClientSolicitudes = () => {
  const success = new URLSearchParams(window.location.search).get('success')
  const [openSuccess, setOpenSuccess] = useState(true)

  const onClose = () => setOpenSuccess(false)

  let history = useHistory()
  const [loading, setLoading] = useState(false)
  const [solicitudes, setSolicitudes] = useState(null)
  const [prospects, setProspects] = useState(null)

  const { userLanguage, dictionary, theme } = useContext(LanguageContext)

  const { token, self, reloadData, features } = useContext(_Auth.Context)

  const { HomeLoader, enqueueNotification, toggleLoader } = useContext(
    _Utils.Context
  )

  var lang
  if (userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const Loader = (
    <div className='uk-width-1-1 uk-height-1-1'>
      <div
        className='uk-margin-auto uk-margin-medium-top uk-margin-medium-bottom uk-width-auto'
        style={{ width: 'fit-content' }}
      >
        <HomeLoader />
      </div>
    </div>
  )
  const refContainer = useRef(null)

  async function Init() {
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'clients/' + self.id + '/index',
        {
          method: 'GET',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      await serverValidation(
        response,
        null,
        userLanguage,
        async (data) => {
          setLoading(false)
          setSolicitudes(data.User_Solicitudes)
          setProspects(data.User_Prospects)
        },
        async (error) => {
          setLoading(false)
          enqueueNotification(
            _handleErrorMessage(userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      setLoading(false)
      enqueueNotification(_handleErrorMessage(userLanguage, error), 'error')
    }
  }

  useEffect(() => {
    if (!token) return
    Init()
  }, [])

  //comment
  const { reset, control, handleSubmit, setError, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      comment: ''
    }
  })
  const {
    reset: resetProspect,
    control: controlProspect,
    handleSubmit: handleSubmitProspect,
    setError: setErrorProspect,
    setValue: setValueProspect
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      comment: ''
    }
  })
  const [edit, setEdit] = useState(null)
  const [editProspect, setEditProspect] = useState(null)
  const [disableButton, setDisableButton] = useState(false)
  const [disableButtonProspect, setDisableButtonProspect] = useState(false)

  const onSubmit = async (data) => {
    try {
      setDisableButton(true)

      await toggleLoader(true)

      if (!edit) {
        throw new Error()
      }

      let response = await fetch(
        process.env.REACT_APP_API_URL +
          'clients/' +
          self.id +
          '/solicitudes/' +
          edit +
          '/comment',
        {
          method: 'PUT',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }
      )

      await serverValidation(
        response,
        setError,
        userLanguage,
        () => {
          reset()
          setDisableButton(false)
          enqueueNotification(dictionary['success'], 'success')
          history.go(0)
        },
        (error) => {
          enqueueNotification(
            _handleErrorMessage(userLanguage, error.error),
            'error'
          )
          setDisableButton(false)
        }
      )
    } catch (error) {
      setDisableButton(false)
      enqueueNotification(_handleErrorMessage(userLanguage, error), 'error')
    }
    await toggleLoader(false)
    setDisableButton(false)
  }

  const onSubmitProspect = async (data) => {
    try {
      setDisableButton(true)

      await toggleLoader(true)

      if (!editProspect) {
        throw new Error()
      }

      let response = await fetch(
        process.env.REACT_APP_API_URL +
          'clients/' +
          self.id +
          '/prospects/' +
          editProspect +
          '/comment',
        {
          method: 'PUT',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }
      )

      await serverValidation(
        response,
        setErrorProspect,
        userLanguage,
        () => {
          resetProspect()
          setDisableButtonProspect(false)
          enqueueNotification(dictionary['success'], 'success')
          history.go(0)
        },
        (error) => {
          enqueueNotification(
            _handleErrorMessage(userLanguage, error.error),
            'error'
          )
          setDisableButtonProspect(false)
        }
      )
    } catch (error) {
      setDisableButtonProspect(false)
      enqueueNotification(_handleErrorMessage(userLanguage, error), 'error')
    }
    await toggleLoader(false)
    setDisableButtonProspect(false)
  }

  //MODAL
  const [modalInfo, setModalInfo] = useState(null)
  const [open, setOpen] = useState(false)
  function onOpenModal() {
    setOpen(true)
  }
  const onCloseModal = () => {
    setOpen(false)
  }

  const [openAccept, setOpenAccept] = useState(false)
  const [openReject, setOpenReject] = useState(false)

  function onOpenAccept() {
    setOpenAccept(true)
  }

  function onCloseAccept() {
    setOpenAccept(false)
  }

  function onOpenReject() {
    setOpenReject(true)
  }

  function onCloseReject() {
    setOpenReject(false)
  }

  const [openPlansModal, setOpenPlansModal] = useState(false)
  function onOpenPlansModal() {
    setOpenPlansModal(true)
  }

  function onClosePlansModal() {
    setOpenPlansModal(false)
  }

  async function updateSolicitude(data) {
    console.log(
      features['request-limit'],
      features['max-request'],
      prospects.length
    )
    if (features['request-limit']) {
      if (
        prospects &&
        features['max-request'] > 0 &&
        prospects.length >= features['max-request']
      ) {
        onOpenPlansModal()
      }
    }
    await toggleLoader(true)
    try {
      if (!modalInfo) throw new Error()

      let info = { status: data }

      let response = await fetch(
        process.env.REACT_APP_API_URL +
          'clients/' +
          self.id +
          '/solicitudes/' +
          modalInfo.Solicitude?.id,
        {
          method: 'PUT',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(info)
        }
      )

      await serverValidation(
        response,
        null,
        userLanguage,
        async () => {
          await toggleLoader(false)
          enqueueNotification(dictionary['success'], 'success')
          onCloseAccept()
          onCloseReject()
          reloadData(() => {})
        },
        async (error) => {
          await toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      enqueueNotification(_handleErrorMessage(userLanguage, error), 'error')
    }
  }

  const [isOwner, setIsOwner] = useState(false)

  return (
    <>
      {loading ? (
        <>{Loader}</>
      ) : (
        <div ref={refContainer}>
          <Card
            color={theme === 'dark' ? 'dark' : 'lightgrey'}
            variant={'filled'}
            className='uk-margin-medium-bottom uk-padding-large'
          >
            {prospects && prospects.length ? (
              <div data-uk-grid className='uk-child-width-1-1'>
                {prospects.map((prospect, index) => (
                  //SOLICITUDE CARD
                  <div key={'solicitude-' + index}>
                    <Card
                      color={theme === 'dark' ? 'grey' : 'light'}
                      variant='filled'
                    >
                      <div
                        data-uk-grid
                        className='uk-child-width-expand uk-grid-small uk-margin-large-right uk-margin-large-left'
                      >
                        {/* INFO */}
                        <Link
                          to={'Prospect/' + prospect.Prospect.id + '/Proposal'}
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'none'
                          }}
                        >
                          <Typography>{prospect.Prospect?.title}</Typography>
                          <Typography variant={'body2'} disableMargin>
                            {prospect.Prospect?.description}
                          </Typography>
                          <Typography variant={'body2'} disableMargin>
                            {format(
                              new Date(prospect.Prospect?.createdAt),
                              'dd/MM/yy'
                            )}
                          </Typography>
                        </Link>

                        {/* USERS */}
                        <div className='uk-flex uk-flex-middle'>
                          <Button
                            color='secondary'
                            size='auto'
                            containerStyle={{
                              width: '80%'
                            }}
                            disableTypography
                          >
                            <div data-uk-grid className='uk-grid-small'>
                              {prospect.Prospect?.User_Prospects &&
                              prospect.Prospect?.User_Prospects.length ? (
                                // THE PROSPECT HAS USERS
                                <>
                                  {prospect.Prospect?.User_Prospects.length <=
                                  2 ? (
                                    // The prospect has one admin
                                    <>
                                      {prospect.Prospect?.User_Prospects.map(
                                        (user, index) => {
                                          if (
                                            user.Role?.name === 'prospect-admin'
                                          )
                                            return (
                                              <React.Fragment
                                                key={'admins-' + index}
                                              >
                                                <div className='uk-width-auto uk-flex uk-flex-middle'>
                                                  <Avatar
                                                    src={user.User.image}
                                                    alt={user.User.name}
                                                    link={
                                                      user.User?.User_Roles?.some(
                                                        (uRole) =>
                                                          uRole.Role?.name ===
                                                          'professional'
                                                      ) &&
                                                      user.User &&
                                                      user.User.nickname
                                                        ? '/Profile/' +
                                                          user.User.nickname
                                                        : null
                                                    }
                                                  />
                                                </div>
                                                <div className='uk-width-expand'>
                                                  <Typography
                                                    variant={'body2'}
                                                    theme='light'
                                                    disableMargin
                                                  >
                                                    {user.User.name}
                                                  </Typography>
                                                  <Typography
                                                    variant={'body2'}
                                                    disableMargin
                                                  >
                                                    {user.User &&
                                                    user.User
                                                      .User_Occupations &&
                                                    user.User.User_Occupations
                                                      .length
                                                      ? dictionary.occupations[
                                                          user.User
                                                            .User_Occupations[0]
                                                            .Occupation.name
                                                        ]
                                                      : ''}
                                                  </Typography>
                                                </div>
                                              </React.Fragment>
                                            )
                                          else return ''
                                        }
                                      )}
                                    </>
                                  ) : (
                                    //the prospect has many users
                                    <>
                                      <div className='uk-width-1-1'>
                                        <Typography
                                          disableMargin
                                          align={'center'}
                                        >
                                          {lang.members}
                                        </Typography>
                                      </div>
                                      <div className='uk-width-1-1'>
                                        <div
                                          className='uk-flex uk-flex-center uk-flex-middle'
                                          style={{
                                            cursor: 'pointer'
                                          }}
                                        >
                                          {prospect.Prospect?.User_Prospects.forEach(
                                            (prospectUser, index) => {
                                              //just displays first 3 users
                                              if (index < 3)
                                                return (
                                                  <Avatar
                                                    src={
                                                      prospectUser.User.image
                                                    }
                                                    alt={prospectUser.User.name}
                                                    key={
                                                      'project-users-' + index
                                                    }
                                                    className='uk-margin-small-right'
                                                    border={
                                                      prospectUser.Role &&
                                                      prospectUser.Role.name ===
                                                        'prospect-admin'
                                                    }
                                                    link={
                                                      prospectUser.User?.User_Roles?.some(
                                                        (uRole) =>
                                                          uRole.Role?.name ===
                                                          'professional'
                                                      ) &&
                                                      prospectUser.User &&
                                                      prospectUser.User.nickname
                                                        ? '/Profile/' +
                                                          prospectUser.User
                                                            .nickname
                                                        : null
                                                    }
                                                  />
                                                )
                                            }
                                          )}
                                          {prospect.Prospect?.User_Prospects
                                            .length > 3 && (
                                            <Avatar
                                              total={
                                                prospect.Prospect
                                                  ?.User_Prospects.length - 3
                                              }
                                              className='uk-margin-small-right'
                                              tooltip={
                                                'pos: right; title: ' +
                                                prospect.Prospect?.User_Prospects.slice(
                                                  3
                                                )
                                                  .map((prospectUser) => {
                                                    return prospectUser.User
                                                      .name
                                                  })
                                                  .join('<br/>')
                                              }
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ) : (
                                // THE PROSPECT HAS NO USERS
                                <>
                                  <div className='uk-width-1-1'>
                                    <Typography disableMargin align={'center'}>
                                      {lang.noUsers}
                                    </Typography>
                                  </div>
                                </>
                              )}
                            </div>
                          </Button>
                        </div>

                        {/* COMMENT */}
                        <div className='uk-width-1-1'>
                          <div
                            data-uk-grid
                            className='uk-child-width-1-1 uk-grid-small'
                          >
                            <div>
                              <Divider />
                            </div>

                            {prospect.comment ? (
                              <div>
                                <Card variant={'filled'} color='light'>
                                  {(!edit ||
                                    edit !== prospect.Prospect?.id) && (
                                    <div className='uk-flex uk-flex-between'>
                                      <Typography
                                        color={'secondary'}
                                        variant='div'
                                      >
                                        {lang.comment}{' '}
                                        <IconButton
                                          icon='question'
                                          padding='1px'
                                          color='secondary'
                                          size='x-small'
                                          data-uk-tooltip={
                                            'title: ' + lang.commentQuestion
                                          }
                                        />
                                      </Typography>
                                      <IconButton
                                        icon={'edit'}
                                        onClick={() => {
                                          setEditProspect(prospect.Prospect?.id)
                                          setValueProspect(
                                            'comment',
                                            prospect.comment
                                          )
                                        }}
                                        variant='filled'
                                        iconColor='grey'
                                      />
                                    </div>
                                  )}
                                  {editProspect &&
                                  editProspect === prospect.Prospect?.id ? (
                                    <div>
                                      <form
                                        onSubmit={handleSubmitProspect(
                                          onSubmitProspect
                                        )}
                                      >
                                        <div
                                          data-uk-grid
                                          className='uk-child-width-1-1 uk-grid-collapse'
                                        >
                                          <div>
                                            <CTextInput
                                              control={controlProspect}
                                              name='comment'
                                              label={'comentario'}
                                              type={'textarea'}
                                              maxLength={1000}
                                              charCount
                                              rows={5}
                                              theme='light'
                                            />
                                          </div>

                                          <div className='uk-width-1-1 uk-flex uk-flex-between'>
                                            <Button
                                              color={'grey'}
                                              onClick={() => {
                                                setEditProspect(null)
                                                resetProspect()
                                              }}
                                              disableMargin
                                            >
                                              {dictionary.cancel}
                                            </Button>

                                            <Button
                                              type='submit'
                                              color='secondary'
                                              disabled={disableButtonProspect}
                                              disableMargin
                                            >
                                              {dictionary.save}
                                            </Button>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  ) : (
                                    <Typography theme='light'>
                                      {prospect.comment}
                                    </Typography>
                                  )}
                                </Card>
                              </div>
                            ) : (
                              <>
                                {(!editProspect ||
                                  editProspect !== prospect.Prospect?.id) && (
                                  <div className='uk-flex uk-flex-right'>
                                    <IconButton
                                      icon={'create'}
                                      onClick={() => {
                                        setEditProspect(prospect.Prospect?.id)
                                        resetProspect()
                                      }}
                                      variant='filled'
                                      color='secondary'
                                      data-uk-tooltip={
                                        'title: ' + lang.addComment
                                      }
                                    />
                                  </div>
                                )}

                                {editProspect &&
                                  editProspect === prospect.Prospect?.id && (
                                    <div>
                                      <form
                                        onSubmit={handleSubmitProspect(
                                          onSubmitProspect
                                        )}
                                      >
                                        <Card variant={'filled'} color='light'>
                                          <div
                                            data-uk-grid
                                            className='uk-child-width-1-1 uk-grid-collapse'
                                          >
                                            <div>
                                              <CTextInput
                                                control={controlProspect}
                                                name='comment'
                                                label={lang.comment}
                                                required
                                                type={'textarea'}
                                                maxLength={1000}
                                                charCount
                                                rows={5}
                                                theme='light'
                                              />
                                            </div>

                                            <div className='uk-width-1-1 uk-flex uk-flex-between'>
                                              <Button
                                                color={'grey'}
                                                onClick={() => {
                                                  setEditProspect(null)
                                                  resetProspect()
                                                }}
                                                disableMargin
                                              >
                                                {dictionary.cancel}
                                              </Button>

                                              <Button
                                                type='submit'
                                                color='secondary'
                                                disabled={disableButtonProspect}
                                                disableMargin
                                              >
                                                {dictionary.save}
                                              </Button>
                                            </div>
                                          </div>
                                        </Card>
                                      </form>
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
              </div>
            ) : (
              <NoData className='uk-margin-medium-top' />
            )}
          </Card>

          {/* ------- REQUESTS ------- */}
          <Typography variant={'h6'}>{dictionary.requests}</Typography>

          <Divider className='uk-margin-medium-bottom' />

          <Card
            color={theme === 'dark' ? 'dark' : 'lightgrey'}
            variant={'filled'}
            className='uk-margin-medium-bottom uk-padding-large'
          >
            {solicitudes && solicitudes.length ? (
              <div data-uk-grid className='uk-child-width-1-1'>
                {solicitudes.map((solicitude, index) => (
                  //SOLICITUDE CARD
                  <div key={'solicitude-' + index}>
                    <Card
                      color={theme === 'dark' ? 'grey' : 'light'}
                      variant='filled'
                    >
                      <div
                        data-uk-grid
                        className='uk-child-width-expand uk-grid-small uk-margin-large-right uk-margin-large-left'
                      >
                        {/* INFO */}
                        <div
                          onClick={() => {
                            setModalInfo(solicitude)
                            setIsOwner(false)
                            onOpenModal()
                          }}
                          style={{
                            cursor: 'pointer'
                          }}
                        >
                          <Typography>
                            {solicitude.Solicitude?.title}
                          </Typography>
                          <Typography variant={'body2'} disableMargin>
                            {solicitude.Solicitude?.description}
                          </Typography>
                          <Typography variant={'body2'} disableMargin>
                            {format(
                              new Date(solicitude.Solicitude?.createdAt),
                              'dd/MM/yy'
                            )}
                          </Typography>
                        </div>

                        {/* USERS */}
                        <div className='uk-flex uk-flex-middle'>
                          <Button
                            color='secondary'
                            size='auto'
                            containerStyle={{
                              width: '80%'
                            }}
                            disableTypography
                          >
                            <div data-uk-grid className='uk-grid-small'>
                              {solicitude.Solicitude?.User_Solicitudes &&
                              solicitude.Solicitude?.User_Solicitudes.length ? (
                                // THE SOLICITUDE HAS USERS
                                <>
                                  {solicitude.Solicitude?.User_Solicitudes
                                    .length <= 2 ? (
                                    // The solicitude has one owner
                                    <>
                                      {solicitude.Solicitude?.User_Solicitudes.map(
                                        (user, index) => {
                                          if (user.isOwner)
                                            return (
                                              <React.Fragment
                                                key={'solicitude-user-' + index}
                                              >
                                                <div className='uk-width-auto uk-flex uk-flex-middle'>
                                                  <Avatar
                                                    src={user.User.image}
                                                    alt={user.User.name}
                                                    link={
                                                      user.User?.User_Roles?.some(
                                                        (uRole) =>
                                                          uRole.Role?.name ===
                                                          'professional'
                                                      ) &&
                                                      user.User &&
                                                      user.User.nickname
                                                        ? '/Profile/' +
                                                          user.User.nickname
                                                        : null
                                                    }
                                                  />
                                                </div>
                                                <div className='uk-width-expand'>
                                                  <Typography
                                                    variant={'body2'}
                                                    theme='light'
                                                    disableMargin
                                                  >
                                                    {user.User.name}{' '}
                                                    {self.id === user.User.id
                                                      ? `(${dictionary.you})`
                                                      : ''}
                                                  </Typography>
                                                  <Typography
                                                    variant={'body2'}
                                                    disableMargin
                                                  >
                                                    {user.User &&
                                                    user.User
                                                      .User_Occupations &&
                                                    user.User.User_Occupations
                                                      .length
                                                      ? dictionary.occupations[
                                                          user.User
                                                            .User_Occupations[0]
                                                            .Occupation.name
                                                        ]
                                                      : ''}
                                                  </Typography>
                                                </div>
                                              </React.Fragment>
                                            )
                                          else return ''
                                        }
                                      )}
                                    </>
                                  ) : (
                                    //the prospect has many users
                                    <>
                                      <div className='uk-width-1-1'>
                                        <Typography
                                          disableMargin
                                          align={'center'}
                                        >
                                          {lang.members}
                                        </Typography>
                                      </div>
                                      <div className='uk-width-1-1'>
                                        <div
                                          className='uk-flex uk-flex-center uk-flex-middle'
                                          style={{
                                            cursor: 'pointer'
                                          }}
                                        >
                                          {solicitude.Solicitude?.User_Solicitudes.forEach(
                                            (solicitudeUser, index) => {
                                              //just displays first 6 users
                                              if (index < 3)
                                                return (
                                                  <Avatar
                                                    src={
                                                      solicitudeUser.User.image
                                                    }
                                                    alt={
                                                      solicitudeUser.User.name
                                                    }
                                                    key={
                                                      'project-users-' + index
                                                    }
                                                    className='uk-margin-small-right'
                                                    border={
                                                      solicitudeUser.isOwner
                                                    }
                                                    link={
                                                      solicitudeUser.User?.User_Roles?.some(
                                                        (uRole) =>
                                                          uRole.Role?.name ===
                                                          'professional'
                                                      ) &&
                                                      solicitudeUser.User &&
                                                      solicitudeUser.User
                                                        .nickname
                                                        ? '/Profile/' +
                                                          solicitudeUser.User
                                                            .nickname
                                                        : null
                                                    }
                                                  />
                                                )
                                            }
                                          )}
                                          {solicitude.Solicitude
                                            ?.User_Solicitudes.length > 3 && (
                                            <Avatar
                                              total={
                                                solicitude.Solicitude
                                                  ?.User_Solicitudes.length - 3
                                              }
                                              className='uk-margin-small-right'
                                              tooltip={
                                                'pos: right; title: ' +
                                                solicitude.Solicitude?.User_Solicitudes.slice(
                                                  3
                                                )
                                                  .map((solicitudeUser) => {
                                                    return solicitudeUser.User
                                                      .name
                                                  })
                                                  .join('<br/>')
                                              }
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <div className='uk-width-1-1'>
                                    <Typography disableMargin align={'center'}>
                                      {lang.noUsers}
                                    </Typography>
                                  </div>
                                </>
                              )}
                            </div>
                          </Button>
                        </div>

                        <div className='uk-width-1-1'>
                          <div
                            data-uk-grid
                            className='uk-child-width-1-1 uk-grid-small'
                          >
                            <div>
                              <Divider />
                            </div>

                            {solicitude.comment ? (
                              <div>
                                <Card variant={'filled'} color='light'>
                                  {(!edit ||
                                    edit !== solicitude.Solicitude?.id) && (
                                    <div className='uk-flex uk-flex-between'>
                                      <Typography
                                        color={'secondary'}
                                        variant='div'
                                      >
                                        {lang.comment}{' '}
                                        <IconButton
                                          icon='question'
                                          padding='1px'
                                          color='secondary'
                                          size='x-small'
                                          data-uk-tooltip={
                                            'title: ' + lang.commentQuestion
                                          }
                                        />
                                      </Typography>
                                      <IconButton
                                        icon={'edit'}
                                        onClick={() => {
                                          setEdit(solicitude.Solicitude?.id)
                                          setValue(
                                            'comment',
                                            solicitude.comment
                                          )
                                        }}
                                        variant='filled'
                                        iconColor='grey'
                                      />
                                    </div>
                                  )}
                                  {edit &&
                                  edit === solicitude.Solicitude?.id ? (
                                    <div>
                                      <form onSubmit={handleSubmit(onSubmit)}>
                                        <div
                                          data-uk-grid
                                          className='uk-child-width-1-1 uk-grid-collapse'
                                        >
                                          <div>
                                            <CTextInput
                                              control={control}
                                              name='comment'
                                              label={lang.comment}
                                              type={'textarea'}
                                              maxLength={1000}
                                              charCount
                                              rows={5}
                                              theme='light'
                                            />
                                          </div>

                                          <div className='uk-width-1-1 uk-flex uk-flex-between'>
                                            <Button
                                              onClick={() => {
                                                setEdit(null)
                                                reset()
                                              }}
                                              disableMargin
                                              color={'grey'}
                                            >
                                              {dictionary.cancel}
                                            </Button>

                                            <Button
                                              color='secondary'
                                              type='submit'
                                              disabled={disableButton}
                                              disableMargin
                                            >
                                              {dictionary.save}
                                            </Button>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  ) : (
                                    <Typography theme='light'>
                                      {solicitude.comment}
                                    </Typography>
                                  )}
                                </Card>
                              </div>
                            ) : (
                              <>
                                {(!edit ||
                                  edit !== solicitude.Solicitude?.id) && (
                                  <div className='uk-flex uk-flex-right'>
                                    <IconButton
                                      icon={'create'}
                                      onClick={() => {
                                        setEdit(solicitude.Solicitude?.id)
                                        reset()
                                      }}
                                      variant='filled'
                                      color='secondary'
                                      data-uk-tooltip={
                                        'title: ' + lang.addComment
                                      }
                                    />
                                  </div>
                                )}

                                {edit && edit === solicitude.Solicitude?.id && (
                                  <div>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                      <Card variant={'filled'} color='light'>
                                        <div
                                          data-uk-grid
                                          className='uk-child-width-1-1 uk-grid-collapse'
                                        >
                                          <div>
                                            <CTextInput
                                              control={control}
                                              name='comment'
                                              label={lang.comment}
                                              required
                                              type={'textarea'}
                                              maxLength={1000}
                                              charCount
                                              rows={5}
                                              theme='light'
                                            />
                                          </div>

                                          <div className='uk-width-1-1 uk-flex uk-flex-between'>
                                            <Button
                                              onClick={() => {
                                                setEdit(null)
                                                reset()
                                              }}
                                              disableMargin
                                              color={'grey'}
                                            >
                                              {dictionary.cancel}
                                            </Button>

                                            <Button
                                              color='secondary'
                                              type='submit'
                                              disabled={disableButton}
                                              disableMargin
                                            >
                                              {dictionary.save}
                                            </Button>
                                          </div>
                                        </div>
                                      </Card>
                                    </form>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
              </div>
            ) : (
              <NoData className='uk-margin-medium-top' />
            )}
          </Card>
        </div>
      )}
      <Modal
        open={open}
        onClose={(e) => {
          onCloseModal(e)
          setModalInfo(null)
        }}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        // classNames={{ modal: "modalReject" }}
        classNames={
          theme === 'dark'
            ? { modal: 'modalPayment-dark' }
            : { modal: 'modalPayment' }
        }
        container={refContainer.current}
      >
        <Typography color={'secondary'}>
          {isOwner ? lang.requestSent : lang.newRequest}
        </Typography>

        <Typography>
          {isOwner ? lang.sentRequest : lang.requestReceived}
        </Typography>

        {/* ---- USUARIOS ---- */}
        <div data-uk-grid className='uk-padding uk-padding-remove-horizontal'>
          <div className='uk-width-1-1'>
            <Typography
              color={'secondary'}
              style={{ textTransform: 'uppercase' }}
            >
              {dictionary.members}
            </Typography>
          </div>
          {modalInfo?.Solicitude?.User_Solicitudes &&
            modalInfo.Solicitude.User_Solicitudes.length &&
            modalInfo.Solicitude.User_Solicitudes.map((user, index) => {
              if (!isOwner && user.isOwner && user.User.id === self.id)
                setIsOwner(true)
              return (
                <div key={'s-user-' + index}>
                  <div data-uk-grid>
                    <div className='uk-width-auto'>
                      <Avatar
                        size={'medium'}
                        src={user.User.image}
                        alt={user.User.name}
                        border={user.isOwner}
                        link={
                          user.User?.User_Roles?.some(
                            (uRole) => uRole.Role?.name === 'professional'
                          ) &&
                          user.User &&
                          user.User.nickname
                            ? '/Profile/' + user.User.nickname
                            : null
                        }
                      />
                    </div>
                    <div className='uk-width-expand uk-flex uk-flex-middle'>
                      <div
                        data-uk-grid
                        className='uk-grid-collapse uk-child-width-1-1'
                      >
                        <div>
                          {user.User?.User_Roles?.some(
                            (uRole) => uRole.Role?.name === 'professional'
                          ) &&
                          user.User &&
                          user.User.nickname ? (
                            <Link to={'/Profile/' + user.User.nickname}>
                              <Typography color={'secondary'}>
                                {user.User.name}{' '}
                                {user.User.id === self.id &&
                                  '(' + dictionary.you + ')'}
                              </Typography>
                            </Link>
                          ) : (
                            <Typography color={'secondary'}>
                              {user.User.name}{' '}
                              {user.User.id === self.id &&
                                '(' + dictionary.you + ')'}
                            </Typography>
                          )}
                        </div>
                        <div>
                          <Typography>
                            {user.User &&
                            user.User.User_Occupations &&
                            user.User.User_Occupations.length
                              ? dictionary.occupations[
                                  user.User.User_Occupations[0].Occupation.name
                                ]
                              : ''}
                          </Typography>
                        </div>
                        <div>
                          <Typography color={'secondary'}>
                            {user.isOwner ? lang.sender : lang.receiver}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>

        <Typography>{modalInfo?.Solicitude?.title}</Typography>

        <Typography>
          <b>{dictionary.description}</b>:{' '}
          {modalInfo?.Solicitude?.description
            ? modalInfo?.Solicitude?.description
            : dictionary.noDescription}
        </Typography>

        {!isOwner && modalInfo?.Solicitude?.status === null && (
          <div
            data-uk-grid
            className='uk-child-width-expand uk-padding-large uk-padding-remove-horizontal uk-padding-remove-bottom'
          >
            <div>
              <Button
                disableMargin
                centered
                color='grey'
                onClick={onOpenReject}
              >
                {dictionary.reject}
              </Button>
            </div>
            <div>
              <Button
                disableMargin
                centered
                color='secondary'
                onClick={onOpenAccept}
              >
                {dictionary.accept}
              </Button>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        open={openAccept}
        onClose={onCloseAccept}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        classNames={{ modal: 'modalAccept' }}
      >
        <Typography
          theme='light'
          variant={'body2'}
          fontWeight='600'
          align={'center'}
          style={{ maxWidth: '230px' }}
          className='uk-margin-small-top'
        >
          {lang.modalAccept}
        </Typography>
        <Button
          className='uk-margin-top'
          color={'grey'}
          onClick={() => updateSolicitude(1)}
          centered
        >
          {lang.accept}
        </Button>
      </Modal>

      <Modal
        open={openReject}
        onClose={onCloseReject}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        classNames={{ modal: 'modalReject' }}
      >
        <Typography
          theme='light'
          variant={'body2'}
          fontWeight='600'
          align={'center'}
          style={{ maxWidth: '230px' }}
          className='uk-margin-small-top'
        >
          {lang.modalReject}
        </Typography>
        <Button
          className='uk-margin-top'
          color={'grey'}
          onClick={() => updateSolicitude(0)}
          centered
        >
          {lang.reject}
        </Button>
      </Modal>

      <Modal
        open={Boolean(success) && openSuccess}
        onClose={onClose}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        classNames={{ modal: 'custom-modal-buy' }}
      >
        <div data-uk-grid>
          <div>
            <Typography
              variant={'h6'}
              align={'center'}
              color={'secondary'}
              className={'uk-margin-medium-top uk-margin-medium-bottom'}
              style={{ textTransform: 'uppercase' }}
            >
              {lang.congratulations}
            </Typography>

            <img
              src={DiincoLogo}
              crossOrigin='use-credentials'
              className='dc-fit-cover'
            />

            <Typography
              variant={'h6'}
              align={'center'}
              color={'secondary'}
              className={'uk-margin-medium-top uk-margin-medium-bottom'}
              style={{ textTransform: 'uppercase' }}
            >
              {lang.enjoy}
            </Typography>
          </div>
        </div>
      </Modal>
      <PlansModal open={openPlansModal} onClose={onClosePlansModal} />
    </>
  )
}

export default ClientSolicitudes
