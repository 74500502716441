import React, { useContext, useState, useEffect } from 'react'
import StandardView from '../../layouts/StandardView'
import { LanguageContext } from '../../context/language/context'
import Divider from '../../components/Divider'
import Typography from '../../components/Typography'
import CustomGalleryVertical from '../../components/CustomGalleryVertical'
import { _handleErrorMessage } from '../../functions/error.functions'
import _Utils from '../../context/utils'
import { useParams } from 'react-router-dom'
import { serverValidation } from '../../functions/validation.functions'
import NoData from '../../components/NoData'
import ProfessionalCard from '../../components/ProfessionalCard'

import { SocialSharer } from '../../components/SocialSharer'

export default function ProjectPublic() {
  const [images, setImages] = useState([])
  const [project, setProject] = useState(null)
  const [professionals, setProfessionals] = useState([])
  const { toggleLoader, enqueueNotification } = useContext(_Utils.Context)
  const languageContext = useContext(LanguageContext)
  const { dictionary } = useContext(LanguageContext)
  const { id } = useParams()

  useEffect(() => {
    if (project) {
      var items = []
      var album = project.Stages[0].Album
      if (album.cover) {
        let item = {
          url: process.env.REACT_APP_API_URL + album.cover
        }
        items.push(item)
      }
      if (album.Images) {
        album.Images.forEach((imagen) => {
          let item = {
            url: process.env.REACT_APP_API_URL + imagen.url
          }
          items.push(item)
        })
      }
      setImages(items)
    }
  }, [project])

  useEffect(() => {
    if (project) {
      let data = []
      if (project.User_Projects.length > 0) {
        project.User_Projects.forEach((user_p) => {
          data.push(user_p.User)
        })
        setProfessionals(data)
      }
    }
  }, [project])

  async function init() {
    try {
      toggleLoader(true)
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'projects/getpublic/' + id,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      await serverValidation(
        response,
        null,
        languageContext.userLanguage,
        (data) => {
          toggleLoader(false)

          if (!data.Location.int_number) {
            data.Location.int_number = ''
          }
          if (!data.Location.street) {
            data.Location.street = ''
          }
          if (!data.Location.ext_number) {
            data.Location.ext_number = ''
          }
          if (!data.Location.neightborhood) {
            data.Location.neightborhood = ''
          } else {
            data.Location.neightborhood = data.Location.neightborhood + ','
          }
          setProject(data)
        },
        async (error) => {
          toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
          //history.push("/dashboard");
        }
      )
    } catch (error) {
      toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
      //history.push("/dashboard");
    }
    toggleLoader(false)
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <StandardView title={dictionary.projects}>
      <div
        className='uk-width-1 uk-flex uk-flex-right'
        style={{ position: 'relative', top: 25, zIndex: 55 }}
      >
        <SocialSharer
          route={'Preview/Project/'}
          id={id}
          url={id && process.env.REACT_APP_URL + 'Preview/Project/' + id}
          title={project && project.title}
        />
      </div>
      <CustomGalleryVertical images={images} />
      <Typography variant={'h6'} style={{ marginTop: 20 }}>
        {project && project.title}
      </Typography>
      {project && project.description && (
        <>
          <Divider
            color={'light'}
            style={{ marginTop: 10, marginBottom: 10 }}
          />
          <Typography variant={'body2'}>{project.description}</Typography>
        </>
      )}
      {project && project.Location && (
        <>
          <Divider
            color={'light'}
            style={{ marginTop: 10, marginBottom: 10 }}
          />
          <Typography variant={'body2'}>
            {project.Location.street +
              ' ' +
              project.Location.ext_number +
              ' ' +
              project.Location.int_number +
              ' ' +
              project.Location.neightborhood +
              ' ' +
              project.Location.City?.name +
              ', ' +
              project.Location.City?.State?.name}
          </Typography>
        </>
      )}
      {project && project.Tags && project.Tags.length > 0 && (
        <>
          <Divider
            color={'light'}
            style={{ marginTop: 10, marginBottom: 10 }}
          />
          <Typography variant={'body2'}>
            {project.Tags.map((tag) => {
              return '#' + tag.name + '  '
            })}
          </Typography>
        </>
      )}
      <span className='dc-face-project-title'>
        {languageContext.dictionary['professionals']}
      </span>
      <div className=' diinco-horizontal-overflow-container'>
        {professionals.length > 0 ? (
          professionals.map((professional, index) => (
            <div className='uk-width-1-5' key={index + 'professional'}>
              <ProfessionalCard
                img={professional.image}
                name={professional.name}
                nickname={professional.nickname}
                profession={
                  professional.Services &&
                  professional.Services.map((service) => service.title).join(
                    ' / '
                  )
                }
                city={professional.Locations[0]?.City?.name}
              />
            </div>
          ))
        ) : (
          <NoData />
        )}
      </div>
    </StandardView>
  )
}
