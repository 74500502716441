import React from 'react'
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import DiincoFace from '../views/Face'
import DiincoAboutUs from '../views/AboutUs'
import DiincoTerms from '../views/TermsAndConds'

import Auth from '../context/auth'
import AlbumForm from '../views/AlbumForm'
import UpdateAlbum from '../views/UpdateAlbum'
import Profile from '../views/Profile'
import Services from '../context/services'
import Utils from '../context/utils'
import TestView from '../views/TestView'
import TempView from '../views/Temp'
import Signup from '../views/Signup'
import SignupPre from '../views/SignupPre'
import NewProspectForm from '../views/NewProspectForm'
import Verify from '../views/verify'
// import ProjectDashboard from "../views/ProjectDashboard";
import ProjectDashboard from '../layouts/ProjectDashboard'
import ProjectReview from '../views/ProjectReview'
import ProjectForm from '../views/ProjectForm'
import ProjectPublic from '../views/ProjectPublic'
import ProfileForm from '../views/ProfileForm'
import ReportForm from '../views/ReportForm'
// import ClientDashboard from "../views/ClientDashboard";
import ClientDashboard from '../layouts/ClientDashboard'
import TagSearch from '../views/Tag'
import Crm from '../views/Crm'
import ResetPassword from '../views/Recuperation'
import ForgottenPassword from '../views/ForgottenPassword'
import Login from '../views/Login'
import Invited from '../views/invited'
import Footer from '../components/Footer'
import Search from '../views/Search'
import SearchAll from '../views/SearchAll'
import Error from '../views/Error'
import ClientForm from '../views/ClientForm'
import UpdateProject from '../views/UpdateProject'
import './styles/style.css'
import 'react-responsive-modal/styles.css'
import PaymentForm from '../views/PaymentForm'
import WorkInProgress from '../views/WorkInProgress'
import { useMatchMedia } from '../functions/useMatchMedia'
import Report from '../views/Report'
import FAQ from '../views/FAQ'
import Prospect from '../views/Prospect'
import ProjectStages from '../views/Stages'

import ModalBug from '../components/ModalBug'

import UserConfirmation from '../components/UserConfirmation'
import Documents from '../views/Documents'
import ProtectedRoute from '../components/ProtectedRoute'
import ProfDashboard from '../layouts/ProfDashboard'
import { Calendar } from '../layouts/ProfDashboard/components/Calendar'
import { Projects } from '../layouts/ProfDashboard/components/Projects'
import ProjectCalendar from '../views/Projects/ProjectCalendar'
import ProjectReports from '../views/Projects/ProjectReports'
import ProjectPayments from '../views/Projects/ProjectPayments'
import ClientSolicitudes from '../views/Client/ClientSolicitudes'
import ClientCalendar from '../views/Client/ClientCalendar'
import ClientProjects from '../views/Client/ClientProjects'
import { AlbumAdmin } from '../views/AlbumAdmin'
import { AlbumUpdate } from '../views/AlbumUpdate'
import PaymentTest from '../views/PaymentTest'
import { AlbumCreate } from '../views/AlbumCreate'
import Invitation from '../views/Invitation'
import PaymentSuccess from '../views/PaymentSuccess'
import ChangeMail from '../views/ChangeMail'

//	mail test
import MailsView from '../views/MailsView'
import { AlbumPublic } from '../views/AlbumPublic'
import Prices from '../views/Stripe/Prices'
import Subscribe from '../views/Stripe/Subscribe'
//Stats
import ShowStats from '../views/Stats'
import Bugs from '../views/Bugs/Show'
import Subscriptions from '../views/Subscriptions'
import SubscriptionsProfessional from '../views/SubscriptionsProfessional'
import SubscriptionsClient from '../views/SubscriptionsClient'

export default function Router() {
  const isDesktopResolution = useMatchMedia('(min-width:80px)', true)
  // const isDesktopResolution = useMatchMedia('(min-width:992px)', true)

  return (
    <BrowserRouter
      basename='/'
      getUserConfirmation={(message, callback) =>
        UserConfirmation(message, callback)
      }
    >
      {isDesktopResolution ? (
        <>
          <div className='dc-router'>
            <Auth.State>
              <Utils.State>
                <Services.State>
                  <Switch>
                    <Route exact path='/'>
                      <DiincoFace />
                    </Route>
                    <Route exact path='/TermsAndConditions'>
                      <DiincoTerms />
                    </Route>
                    <Route exact path='/signup'>
                      <Signup />
                    </Route>
                    <Route exact path='/signup-info'>
                      <SignupPre />
                    </Route>
                    <Route exact path='/newprospect/:token'>
                      <NewProspectForm />
                    </Route>
                    <Route exact path='/changeMail'>
                      <ChangeMail />
                    </Route>
                    <Route exact path='/verify/:token'>
                      <Verify />
                    </Route>
                    <Route exact path='/Recuperation/:token'>
                      <ResetPassword />
                    </Route>
                    <Route exact path='/ForgottenPassword'>
                      <ForgottenPassword />
                    </Route>
                    <Route exact path='/AlbumForm'>
                      <AlbumForm />
                    </Route>
                    <Route exact path='/Albums/Admin'>
                      <ProtectedRoute roles='professional'>
                        <AlbumAdmin />
                      </ProtectedRoute>
                    </Route>
                    <Route exact path='/Albums/:id'>
                      <AlbumPublic />
                    </Route>
                    <Route exact path='/Albums/Admin/Create'>
                      <AlbumCreate />
                    </Route>
                    <Route exact path='/Albums/Admin/:id'>
                      <AlbumUpdate />
                    </Route>
                    <Route exact path='/AlbumForm/:id'>
                      <UpdateAlbum />
                    </Route>
                    <Route exact path='/Temp'>
                      <TempView />
                    </Route>
                    <Route exact path='/Login'>
                      <Login />
                    </Route>
                    <Route exact path='/AboutUs'>
                      <DiincoAboutUs />
                    </Route>
                    <Route exact path='/Subscriptions'>
                      <Subscriptions />
                    </Route>
                    <Route exact path='/Subscriptions/Professional'>
                      <SubscriptionsProfessional />
                    </Route>
                    <Route exact path='/Subscriptions/Client'>
                      <SubscriptionsClient />
                    </Route>
                    <Route exact path='/Subscription'>
                      <ProtectedRoute
                        roleRender={{
                          role: 'client',
                          component: <SubscriptionsClient />
                        }}
                        redirectPath='/subscriptions'
                        showErrorMessage={false}
                      >
                        <SubscriptionsProfessional />
                      </ProtectedRoute>
                    </Route>

                    <Route exact path='/Search/:search'>
                      <ProtectedRoute>
                        <SearchAll />
                      </ProtectedRoute>
                    </Route>

                    <Route exact path='/Preview/Project/:id'>
                      <ProjectPublic />
                    </Route>

                    {/* ----------------------------- USER ROUTES ----------------------------- */}

                    {/* EDIT PROFILE */}
                    <Route exact path='/Profile/Edit'>
                      <ProtectedRoute
                        roleRender={{
                          role: 'client',
                          component: <ClientForm />
                        }}
                      >
                        <ProfileForm />
                      </ProtectedRoute>
                    </Route>

                    <Route exact path='/Profile/:nickname'>
                      <Profile />
                    </Route>

                    {/* ----------------------------- /USER ROUTES ----------------------------- */}

                    {/* ----------------------------- PROJECTS ROUTES ----------------------------- */}
                    {/* <Route exact path="/Project/:id">
											<ProjectDashboard />
										</Route> */}
                    <Route exact path='/Projects/Create'>
                      <ProtectedRoute roles='professional'>
                        <ProjectForm />
                      </ProtectedRoute>
                    </Route>
                    <Route
                      exact
                      path={[
                        '/Projects/:id',
                        '/Projects/:id/Reports',
                        '/Projects/:id/Payments'
                      ]}
                    >
                      <ProjectDashboard>
                        <Route exact path={'/Projects/:id'}>
                          <ProtectedRoute
                            permissions={['project-read', 'event-read']}
                            type='project'
                          >
                            <ProjectCalendar />
                          </ProtectedRoute>
                        </Route>
                        <Route exact path={'/Projects/:id/Reports'}>
                          <ProtectedRoute
                            permissions={['project-read', 'report-read']}
                            type='project'
                          >
                            <ProjectReports />
                          </ProtectedRoute>
                        </Route>
                        <Route exact path={'/Projects/:id/Payments'}>
                          <ProtectedRoute
                            permissions={['project-read', 'report-read']}
                            type='project'
                          >
                            <ProjectPayments />
                          </ProtectedRoute>
                        </Route>
                      </ProjectDashboard>
                    </Route>
                    <Route exact path='/Projects/:id/Edit'>
                      <ProtectedRoute
                        permissions={'project-update'}
                        type='project'
                      >
                        <UpdateProject />
                      </ProtectedRoute>
                    </Route>
                    <Route exact path='/Project/Review'>
                      <ProtectedRoute redirectPath='/login'>
                        <ProjectReview />
                      </ProtectedRoute>
                    </Route>
                    <Route exact path='/Projects/:id/Stages'>
                      <ProtectedRoute permissions={'stage-read'} type='project'>
                        <ProjectStages />
                      </ProtectedRoute>
                    </Route>
                    <Route exact path='/Projects/:id/Reports/Create'>
                      <ProtectedRoute
                        permissions={'report-create'}
                        type='project'
                      >
                        <ReportForm />
                      </ProtectedRoute>
                    </Route>

                    <Route exact path='/Projects/:projectId/Reports/:id'>
                      <Report />
                    </Route>

                    <Route exact path='/Projects/:id/Payments/Create'>
                      <ProtectedRoute
                        permissions={'payment-create'}
                        type={'project'}
                      >
                        <PaymentForm />
                      </ProtectedRoute>
                    </Route>

                    <Route exact path='/Projects/:id/Documents'>
                      <Documents />
                    </Route>

                    {/* ----------------------------- /PROJECTS ROUTES ----------------------------- */}

                    {/* ----------------------------- CLIENT ROUTES ----------------------------- */}
                    {/* ----------------------------- /CLIENT ROUTES ----------------------------- */}

                    {/* <Route exact path="/Dashboard">
											<ProfessionalDashboard />
										</Route> */}
                    <Route
                      exact
                      path={[
                        '/Dashboard',
                        '/Dashboard/Projects',
                        '/Dashboard/Calendar'
                      ]}
                    >
                      <ProtectedRoute
                        roleRender={{
                          role: 'client',
                          component: (
                            <ClientDashboard>
                              <Route exact path={'/Dashboard'}>
                                <ClientSolicitudes />
                              </Route>
                              <Route exact path={'/Dashboard/Calendar'}>
                                <ClientCalendar />
                              </Route>
                              <Route exact path={'/Dashboard/Projects'}>
                                <ClientProjects />
                              </Route>
                            </ClientDashboard>
                          )
                        }}
                      >
                        <ProfDashboard>
                          <Route exact path={'/Dashboard'}>
                            <Calendar />
                          </Route>
                          <Route exact path={'/Dashboard/Projects'}>
                            <Projects />
                          </Route>
                        </ProfDashboard>
                      </ProtectedRoute>
                    </Route>
                    <Route exact path='/Home'>
                      <ClientDashboard />
                    </Route>
                    <Route exact path='/Crm'>
                      <ProtectedRoute
                      // permissions={'phase-read'}
                      // type="plan"
                      >
                        <Crm />
                      </ProtectedRoute>
                    </Route>
                    <Route exact path='/Tag/:search'>
                      <TagSearch />
                    </Route>
                    <Route exact path='/invited/:token'>
                      <Invited />
                    </Route>
                    <Route exact path='/Search/City/:city/:type'>
                      <Search />
                    </Route>
                    <Route exact path='/FAQ'>
                      <FAQ />
                    </Route>
                    <Route exact path='/Report/:id'>
                      <Report />
                    </Route>
                    <Route exact path='/Project/:id/Documents'>
                      <Documents />
                    </Route>

                    <Route exact path='/invitation/project/:id'>
                      <Invitation />
                    </Route>

                    <Route exact path='/Prospect/:id/Proposal'>
                      <ProtectedRoute
                        permissions={'prospect-read'}
                        type={'prospect'}
                      >
                        <Prospect type='proposal' />
                      </ProtectedRoute>
                    </Route>

                    <Route exact path='/Prospect/:id'>
                      <ProtectedRoute
                        permissions={'prospect-read'}
                        type={'prospect'}
                        roles='professional'
                      >
                        <Prospect type='prospect' />
                      </ProtectedRoute>
                    </Route>

                    <Route exact path='/Prospect/:id'>
                      <ProtectedRoute
                        permissions={'prospect-update'}
                        type={'prospect'}
                      >
                        <Prospect />
                      </ProtectedRoute>
                    </Route>

                    {/* ******************************************************* TEST ******************************************************* */}
                    <Route exact path='/testview'>
                      <TestView />
                    </Route>
                    <Route exact path='/mailsview'>
                      <MailsView />
                    </Route>
                    <Route exact path='/paymentview'>
                      <PaymentTest />
                    </Route>
                    <Route exact path='/paymentsuccess'>
                      <PaymentSuccess />
                    </Route>
                    {/* ******************************************************* TEST ******************************************************* */}

                    {/* ******************************************************* STRIPE ******************************************************* */}
                    <Route exact path='/prices'>
                      <Prices />
                    </Route>
                    <Route exact path='/subscribe'>
                      <Subscribe />
                    </Route>

                    {/* ******************************************************* Statistics ******************************************************* */}

                    <Route exact path='/stats'>
                      <ShowStats />
                    </Route>

                    <Route exact path='/bugs'>
                      <Bugs />
                    </Route>
                    {/* ******************************************************* STRIPE ******************************************************* */}

                    {/* Let error component at last*/}
                    <Route>
                      <Error />
                    </Route>
                  </Switch>
                  <ModalBug />
                </Services.State>
              </Utils.State>
            </Auth.State>
            {/* <Footer /> */}
          </div>
          <div className='dc-footer-pin'>
            <Footer />
          </div>
        </>
      ) : (
        // <WorkInProgress />

        <div className='dc-router'>
          <Auth.State>
            <Utils.State>
              <Services.State>
                <Switch>
                  <Route path='/'>
                    <WorkInProgress />
                  </Route>
                  <Route exact path='/verify/:token'>
                    <Verify />
                  </Route>

                  <Route>
                    <WorkInProgress />
                  </Route>
                </Switch>
                {/* <ModalBug /> */}
              </Services.State>
            </Utils.State>
          </Auth.State>
          {/* <Footer /> */}
        </div>
      )}
    </BrowserRouter>
  )
}
