import React, { useContext, useEffect, useState } from 'react'
import './styles/style.css'

import _Utils from '../../context/utils'
import _Services from '../../context/services'
import _Auth from '../../context/auth'

import { CDropZoneCoverImage } from '../../components/DropZoneCoverImage'
import { CTagsInput } from '../../components/TagsInput'
import ImageThumbsContainer from '../../components/ImageThumbsContainer'
import { useHistory, useParams } from 'react-router-dom'
import { LanguageContext } from '../../context/language/context'
import { _handleErrorMessage } from '../../functions/error.functions'
import { Prompt } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  serverValidation,
  validationRules
} from '../../functions/validation.functions'
import { serialize } from '../../functions/validation.functions'
import { CTextInput } from '../../components/TextInput'
import Button from '../../components/Button'
import StandardView from '../../layouts/StandardView'
import { CSelectInput } from '../../components/SelectInput'
import { compressFilesInFormData } from '../../functions/files.functions'
import PlansModal from '../../components/PlansModal'

export const AlbumUpdate = () => {
  const { toggleLoader, enqueueNotification, notify } = useContext(
    _Utils.Context
  )
  const { isLoaded } = useContext(_Auth.Context)
  const history = useHistory()
  const { id } = useParams()

  const [openPlansModal, setOpenPlansModal] = useState(false)

  function onOpenPlansModal() {
    setOpenPlansModal(true)
  }

  function onClosePlansModal() {
    setOpenPlansModal(false)
  }

  const languageContext = useContext(LanguageContext)
  const { userLanguage } = useContext(LanguageContext)
  var lang
  if (userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const [loading, setLoading] = useState(true)
  setTimeout(() => {
    setLoading(false)
  }, 1000)

  const [disabledLocation, setDisabledLocation] = useState(true)
  const [disabledState, setDisabledState] = useState(true)
  const [disabledCity, setDisabledCity] = useState(true)
  const {
    countries,
    states,
    cities,
    services,
    checkUpdates,
    selectedCountry,
    selectedState,
    setSelectedCountry,
    setSelectedState,
    updateStates,
    updateCities
  } = useContext(_Services.Context)

  const countriesOptions = countries.map((country) => ({
    value: country,
    label: country.name
  }))

  const statesOptions = states.map((state) => ({
    value: state,
    label: state.name
  }))

  const citiesOptions = cities.map((city) => ({
    value: city,
    label: city.name
  }))

  const servicesOptions = services.map((service) => ({
    value: service.id,
    label: service.title
  }))

  const [loadedData, setLoadedData] = useState({
    countries: false,
    states: false,
    cities: false,
    rest: false
  })

  useEffect(() => {
    async function Init() {
      await toggleLoader(true)
      await checkUpdates()
      await toggleLoader(false)
      setLoadedData({ ...loadedData, countries: true })
    }
    Init()
  }, [])

  const [contador, setContador] = useState(0)

  useEffect(() => {
    async function Init() {
      if (
        isLoaded &&
        contador < 3 &&
        id &&
        !loadedData.rest &&
        loadedData.countries
      )
        try {
          setContador(contador + 1)
          await toggleLoader(true)
          let data = await fetch(
            process.env.REACT_APP_API_URL + 'albums/getone/' + id,
            {
              method: 'GET',
              credentials: 'include',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )
          data = await data.json()
          //TAGS
          var tags = []
          data &&
            data.Tags &&
            data.Tags.length > 0 &&
            data.Tags.map((tag) => {
              return tags.push(tag.name)
            })

          //location
          const location = data.Location ? data.Location : {}

          if (location.City && (!loadedData.States || !loadedData.Cities)) {
            if (
              !selectedCountry ||
              selectedCountry.id !== location.City.State.Country.id
            ) {
              setSelectedCountry(location.City.State.Country)
              setLoadedData({
                ...loadedData,
                countries: true,
                states: false,
                cities: false
              })
            } else if (
              !selectedState ||
              selectedState.id !== location.City.State.id
            ) {
              await updateStates(location.City.State.Country.id)
              setSelectedState(location.City.State)
              setLoadedData({
                ...loadedData,
                states: true,
                cities: false
              })
            } else {
              await updateCities(location.City.State.id)
              setLoadedData({
                ...loadedData,
                countries: true,
                states: true,
                cities: true
              })
            }
          }

          setDisabledState(!location.City)
          setDisabledCity(!location.City)
          setDisabledLocation(!location.City)

          //images
          let images = []
          if (data.cover) {
            images.push(data.cover)
          }
          if (data.Images && data.Images.length > 0) {
            data.Images.forEach((image) => {
              images.push(image.url)
            })
          }

          var servicios = []
          data &&
            data.Services &&
            data.Services.length > 0 &&
            data.Services.map((service) => {
              return servicios.push(
                servicesOptions.find((c) => c.value === service.id)
              )
            })

          reset({
            title: data.title,
            description: data.description,
            country: location.City
              ? countriesOptions.find(
                  (c) => c.value.id === location.City.State.Country.id
                )
              : null,
            state: location.City
              ? statesOptions.find((c) => c.value.id === location.City.State.id)
              : null,
            city: location.City
              ? citiesOptions.find((c) => c.value.id === location.City.id)
              : null,
            street: location.street ?? '',
            int_number: location.int_number ?? '',
            ext_number: location.ext_number ?? '',
            neightborhood: location.neightborhood ?? '',
            postal_code: location.postal_code ?? '',
            tags: tags,
            files: [],
            oldImages: images,
            currentImage: images[0],
            services: servicios
          })
          setLoadedData({
            ...loadedData,
            rest: true
          })
          await toggleLoader(false)
        } catch (error) {
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error),
            'error'
          )
        }
    }
    Init()
  }, [isLoaded, loadedData])

  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])

  const {
    reset,
    setValue,
    getValues,
    control,
    setError,
    handleSubmit,
    formState
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: '',
      description: '',
      tags: [],
      files: [],
      oldImages: [],
      currentImage: undefined,
      country: null,
      state: undefined,
      city: undefined,
      street: undefined,
      int_number: undefined,
      ext_number: undefined,
      neightborhood: undefined,
      postal_code: undefined,
      services: null
    }
  })

  useEffect(() => {
    if (formState.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
    } else {
      window.onbeforeunload = null
    }
  }, [formState.isDirty])

  const [disableButton, setDisableButton] = useState(false)

  async function onSubmit(data) {
    if (!loadedData) {
      enqueueNotification(
        languageContext.dictionary.error + ' ' + languageContext.dictionary.try,
        'error'
      )
      return
    }

    await toggleLoader(true)
    setDisableButton(true)
    try {
      let formData = serialize(data, {
        dotsForObjectNotation: true,
        noArrayNotation: true,
        noFilesWithArrayNotation: true
      })

      formData = await compressFilesInFormData(formData, 'files')

      formData.append('albumId', id)
      let response = await fetch(process.env.REACT_APP_API_URL + 'albums', {
        method: 'PUT',
        credentials: 'include',
        mode: 'cors',
        headers: {
          Accept: 'multipart/form-data'
        },
        body: formData
      })
      await serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        async () => {
          reset(data)
          await toggleLoader(false)
          setDisableButton(false)
          notify(
            languageContext.dictionary['success'],
            setTimeout(function () {
              history.push('/Albums/Admin')
            }, 1000)
          )
        },
        async (error) => {
          if (error?.error?.code === 8011) {
            onOpenPlansModal()
          }
          setDisableButton(false)
          await toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      toggleLoader(false)
      setDisableButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    toggleLoader(false)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onError = () => {
    enqueueNotification(
      _handleErrorMessage(languageContext?.userLanguage, { code: 1000 }),
      'error'
    )
  }

  return (
    <StandardView
      loading={loading}
      title={lang.title}
      links={[
        {
          link: '/Dashboard',
          title: languageContext.dictionary.dashboard
        },
        {
          link: '/Albums/Admin',
          title: languageContext.dictionary.albumsManagement
        }
      ]}
    >
      <div className='uk-margin-medium-top uk-margin-medium-bottom uk-width-1-1 uk-flex uk-flex-column'>
        <form onSubmit={handleSubmit(onSubmit, onError)} noValidate>
          <Prompt
            when={formState.isDirty}
            message={JSON.stringify({
              title: languageContext.dictionary.discard,
              message: languageContext.dictionary.areYouSure,
              accept: languageContext.dictionary.accept,
              cancel: languageContext.dictionary.cancel
            })}
          />
          <div data-uk-grid>
            <div className='uk-width-1'>
              {/* <DropZoneImage /> */}
              <div className='uk-width-1'>
                <CDropZoneCoverImage
                  name={'files'}
                  control={control}
                  ContainerClasses='diinco-project-form-dropzone-container cj-height-dropzone'
                  ImageClasses='diinco-project-form-dropzone-image'
                  onChange={() => forceUpdate()}
                  Image={getValues('currentImage')} //checar lo mismo que el file
                />
              </div>
              <div className='uk-flex'>
                <ImageThumbsContainer
                  control={control}
                  thumbs={getValues('files')}
                  containerStyle='diinco-thumbs-container-project-form'
                  thumbStyle='diinco-thumbs-style-project-form'
                  deleteButtonStyle='diinco-thumbs-delete-project-form'
                  handleRemove={(event, file, index) => {
                    if (typeof file === 'string') {
                      const newValue = getValues('oldImages').filter(
                        (o, i) => i !== index
                      )
                      setValue('oldImages', newValue)
                      forceUpdate()
                    } else {
                      const newValue = getValues('files').filter(
                        (o, i) => i !== index
                      )
                      setValue('files', newValue)
                      forceUpdate()
                    }
                  }}
                  rootImages={getValues('oldImages')}
                  onClick={(file) => {
                    console.log(getValues('currentImage'))

                    if (typeof file === 'object') {
                      file = getValues('oldImages')[0]
                    }
                    setValue('currentImage', file)
                    forceUpdate()
                  }}
                />
              </div>
            </div>
          </div>
          <CTextInput
            label={languageContext.dictionary.title}
            name='title'
            control={control}
            maxLength={45}
            required
          />
          <CTextInput
            label={languageContext.dictionary.desc}
            name='description'
            type='textarea'
            control={control}
            rows={2}
            maxLength={2000}
            charCount
          />

          <div className='uk-grid'>
            <div className='uk-width-1-3'>
              <CSelectInput
                name='country'
                options={countriesOptions}
                control={control}
                onChange={(selectedOption) => {
                  setDisabledState(!selectedOption)
                  setDisabledCity(true)
                  setSelectedCountry(selectedOption?.value)
                  setValue('state', null)
                  setValue('city', null)
                }}
                label={languageContext.dictionary.country}
              />
            </div>
            <div className='uk-width-1-3'>
              <CSelectInput
                name='state'
                control={control}
                label={languageContext.dictionary.state}
                disabled={disabledState}
                options={statesOptions}
                onChange={(selectedOption) => {
                  setDisabledCity(!selectedOption)
                  setSelectedState(selectedOption?.value)
                  setValue('city', null)
                }}
                required
              />
            </div>
            <div className='uk-width-1-3'>
              <CSelectInput
                name='city'
                control={control}
                label={languageContext.dictionary.city}
                onChange={(e) => {
                  if (e) setDisabledLocation(false)
                  else setDisabledLocation(true)
                }}
                disabled={disabledCity}
                options={citiesOptions}
              />
            </div>
            <div className='uk-width-3-5'>
              <CTextInput
                label={languageContext.dictionary.street}
                name='street'
                control={control}
                disabled={disabledLocation}
                deps={'city'}
                maxLength={100}
              />
            </div>
            <div className='uk-width-1-5'>
              <CTextInput
                label={languageContext.dictionary.externalNum}
                name='ext_number'
                control={control}
                disabled={disabledLocation}
                maxLength={5}
                validate={{
                  alphaDash: (v) =>
                    validationRules.alphaDash(v, languageContext.userLanguage)
                }}
              />
            </div>
            <div className='uk-width-1-5'>
              <CTextInput
                label={languageContext.dictionary.internalNum}
                name='int_number'
                control={control}
                disabled={disabledLocation}
                maxLength={7}
                validate={{
                  alphaDash: (v) =>
                    validationRules.alphaDash(v, languageContext.userLanguage)
                }}
              />
            </div>
            <div className='uk-width-3-5'>
              <CTextInput
                label={languageContext.dictionary.neighborhood}
                name='neightborhood'
                control={control}
                disabled={disabledLocation}
                maxLength={100}
              />
            </div>
            <div className='uk-width-2-5'>
              <CTextInput
                label={languageContext.dictionary.postalCode}
                name='postal_code'
                control={control}
                type='number'
                disabled={disabledLocation}
                maxLength={5}
                minLength={5}
                charCount
              />
            </div>
          </div>

          <CSelectInput
            name='services'
            control={control}
            label={languageContext.dictionary.service}
            isMulti
            options={servicesOptions}
            required
          />

          <div className='uk-grid'>
            <div className='uk-width-1'>
              <CTagsInput
                name='tags'
                control={control}
                classes={{
                  tagsContainer: 'cj-tags-container'
                }}
              />
            </div>
          </div>
          <div className='uk-flex uk-flex-between'>
            <Button
              onClick={() => {
                history.goBack()
              }}
              variant='contained'
              color='grey'
            >
              {languageContext.dictionary.cancel}
            </Button>
            <Button
              color='secondary'
              centered={true}
              type='submit'
              disabled={disableButton}
            >
              {languageContext.dictionary.save}
            </Button>
          </div>
        </form>
      </div>
      <PlansModal open={openPlansModal} onClose={onClosePlansModal} />
    </StandardView>
  )
}
