import React from 'react'
import PaymentForm from '../../components/PaymentForm'

export default function PaymentTest() {
  return (
    <div style={{ padding: 50, marginTop: 50 }}>
      TESTING
      <PaymentForm />
    </div>
  )
}
