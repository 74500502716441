import React, { useState, useEffect, useContext } from 'react'

import './styles/style.css'
import StandardView from '../../layouts/StandardView'
import _Auth from '../../context/auth'
import _Utils from '../../context/utils'

import { CTextInput } from '../../components/TextInput'
import { useForm } from 'react-hook-form'
import Button from '../../components/Button'
import { useHistory } from 'react-router-dom'
import Typography from '../../components/Typography'
import Avatar from '../../components/Avatar'
import { LanguageContext } from '../../context/language/context'
import { _handleErrorMessage } from '../../functions/error.functions'
import { serverValidation } from '../../functions/validation.functions'

import DefaultBanner from '../../assets/images/banner.webp'
import { useLocation } from 'react-router-dom'
import Error from '../Error'

function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

export default function ProjectReview() {
  const { token } = useContext(_Auth.Context)
  const { enqueueNotification, notify, toggleLoader } = useContext(
    _Utils.Context
  )
  const [project, setProject] = useState(null)
  const [badges, setBadges] = useState([])
  const [activeBadge, setActiveBadge] = useState([])
  const [professionals, setProfessionals] = useState([])
  const [activeProfessional, setActiveProfessional] = useState(0)
  const [data, setData] = useState({})
  const history = useHistory()
  let query = useQuery()
  const projectId = query.get('token')
  const userId = query.get('user')

  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }
  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])

  const [use404, setUse404] = useState(false)

  async function Init() {
    if (!projectId || !userId) {
      setUse404(true)
    }
    try {
      toggleLoader(true)
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'reviews/project',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            projectId,
            userId
          })
        }
      )

      await serverValidation(
        response,
        null,
        languageContext.userLanguage,
        (data) => {
          reset()
          setDisabledButton(false)
          toggleLoader(false)
          if (data.message && data.message === 'repeat') {
            enqueueNotification(lang.repeated, 'error')
            history.push('/projects/' + data.details.projectId)
          }
          setProject(data)
          setProfessionals(data.User_Projects)
        },
        async (error) => {
          toggleLoader(false)
          setDisabledButton(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
          console.log(error)
          history.push('/dashboard')
        }
      )
    } catch (error) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
      history.push('/')
    }
  }

  async function Init2() {
    try {
      let data = await fetch(process.env.REACT_APP_API_URL + 'reviews/badges', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      data = await data.json()
      if (data.code) {
        throw new Error(1000)
      }
      setBadges(data)
    } catch (error) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  useEffect(() => {
    if (!token) return
    Init()
    Init2()
  }, [token])

  const { reset, getValues, control, trigger } = useForm({
    mode: 'onChange',
    defaultValues: {
      commentary: ''
    }
  })

  const [setDisabledButton] = useState(false)

  const changeActive = (id) => {
    if (!activeBadge.includes(id)) {
      setActiveBadge((old) => [...old, id])
    } else {
      let copia = activeBadge
      var index = copia.indexOf(id)
      if (index !== -1) {
        copia.splice(index, 1)
      }
      setActiveBadge(copia)
      setBadges(badges)
      forceUpdate()
    }
  }

  const changeBack = () => {
    let numero = activeProfessional
    numero = Number(numero)
    if (numero !== 0) {
      guardarData()
      numero--
      setActiveProfessional(numero)
    }
  }

  const changeNext = () => {
    if (getValues('commentary') === '') {
      trigger('commentary')
      return
    }
    let numero = activeProfessional
    numero = Number(numero)
    if (numero < professionals.length) {
      guardarData()
      numero++
      setActiveProfessional(numero)
    }
  }

  const guardarData = () => {
    let json = data
    json[activeProfessional] = {
      badges: activeBadge,
      professional: professionals[activeProfessional],
      commentary: getValues('commentary')
    }
    setData(json)
  }

  useEffect(() => {
    if (Object.hasOwnProperty.call(data, activeProfessional)) {
      setActiveBadge(data[activeProfessional].badges)
      reset({
        commentary: data[activeProfessional].commentary
      })
    } else {
      setActiveBadge([])
      reset({
        commentary: ''
      })
    }
  }, [activeProfessional])

  const submitReview = async () => {
    if (getValues('commentary') === '') {
      trigger('commentary')
      return
    }
    guardarData()
    await toggleLoader(true)
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'reviews/create',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ data })
        }
      )

      await serverValidation(
        response,
        null,
        languageContext.userLanguage,
        () => {
          reset()
          setDisabledButton(false)
          toggleLoader(false)
          notify(
            lang.success,
            history.push('/projects/' + data[0].professional.ProjectId)
          )
        },
        async (e) => {
          toggleLoader(false)
          setDisabledButton(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, e),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  return use404 ? (
    <Error />
  ) : (
    <StandardView title={lang.title}>
      {/* project banner */}
      <div className='uk-grid uk-grid-collapse banner-relative'>
        {/* <div className="banner-relative"> */}

        {/* la imagen es absoluta */}
        <img
          src={project ? project.banner ?? DefaultBanner : DefaultBanner}
          alt='banner'
          className='uk-width-1-1 uk-height-max-large diinco-project-banner'
        />
        <span className='project-name-absolute uppercase uk-text-truncate dc-project-title leo-project-title'>
          {project ? project.title : 'Titulo'}
        </span>
      </div>

      <div className='uk-grid uk-grid-collapse uk-margin-medium-top uk-flex uk-flex-center'>
        <div className='uk-width-1'>
          <Typography variant='body2' color='secondary' align='center'>
            {lang.congratulations}
          </Typography>
        </div>
        <div className='uk-width-1 uk-margin-top'>
          <Typography variant='body' color='primary' align='center'>
            {lang.review}
          </Typography>
        </div>
        <div className='uk-width-1 uk-margin-medium-top uk-flex uk-flex-center uk-flex-middle'>
          <Avatar
            alt={professionals && professionals[activeProfessional]?.User?.name}
            src={
              professionals && professionals[activeProfessional]?.User?.image
            }
            size={'medium'}
            style={{ border: 'solid 5px #f9b74e' }}
          />
          <div className='uk-margin-left'>
            <Typography variant='body' color='primary' align='center'>
              {professionals && professionals[activeProfessional]?.User?.name}
            </Typography>
            <Typography variant='body' color='secondary' align='center'>
              {'Arquitecto'}
            </Typography>
          </div>
        </div>
        {/* badges */}
        <div className='uk-width-1 uk-margin-medium-top'>
          <Typography variant='body' color='primary' align='center'>
            {'Selecciona una o más insignias para el profesional'}
          </Typography>
        </div>
        <div
          className='uk-width-1 uk-margin-small-top  uk-margin-bottom uk-flex uk-flex-center'
          style={{ alignItems: 'center' }}
        >
          {badges &&
            badges.length > 0 &&
            badges.map((badge, index) => {
              let texto = ''
              switch (badge.name) {
                case 'Puntualidad':
                  texto = lang.punctuality
                  break
                case 'Comunicacion':
                  texto = lang.communication
                  break
                case 'Organizado':
                  texto = lang.organized
                  break
                case 'Calidad':
                  texto = lang.quality
                  break
                default:
                  texto = ''
              }
              if (index === 0) {
                return (
                  <div
                    key={index}
                    className='uk-flex uk-flex-center'
                    style={
                      activeBadge.includes(badge.id)
                        ? {
                            alignItems: 'center',
                            width: 'fit-content',
                            flexDirection: 'column'
                          }
                        : {
                            alignItems: 'center',
                            width: 'fit-content',
                            flexDirection: 'column',
                            opacity: 0.6
                          }
                    }
                  >
                    <div
                      className={
                        activeBadge.includes(badge.id)
                          ? 'leo-badge-container active'
                          : 'leo-badge-container'
                      }
                      style={{ width: 'fit-content' }}
                    >
                      <div
                        className={
                          activeBadge.includes(badge.id)
                            ? 'leo-badge active'
                            : 'leo-badge'
                        }
                        onClick={() => changeActive(badge.id)}
                      >
                        <img src={badge.url} alt='' />
                      </div>
                    </div>
                    <Typography
                      variant='body'
                      color='primary'
                      align='center'
                      className='uk-margin-small-top'
                    >
                      {texto}
                    </Typography>
                  </div>
                )
              } else {
                return (
                  <div
                    key={index}
                    className='uk-flex uk-flex-center uk-margin-medium-left'
                    style={
                      activeBadge.includes(badge.id)
                        ? {
                            alignItems: 'center',
                            width: 'fit-content',
                            flexDirection: 'column'
                          }
                        : {
                            alignItems: 'center',
                            width: 'fit-content',
                            flexDirection: 'column',
                            opacity: 0.6
                          }
                    }
                  >
                    <div
                      className={
                        activeBadge.includes(badge.id)
                          ? 'leo-badge-container active'
                          : 'leo-badge-container'
                      }
                      style={{ width: 'fit-content' }}
                    >
                      <div
                        className={
                          activeBadge.includes(badge.id)
                            ? 'leo-badge active'
                            : 'leo-badge'
                        }
                        onClick={() => changeActive(badge.id)}
                      >
                        <img src={badge.url} alt='' />
                      </div>
                    </div>
                    <Typography
                      variant='body'
                      color='primary'
                      align='center'
                      className='uk-margin-small-top'
                    >
                      {texto}
                    </Typography>
                  </div>
                )
              }
            })}
        </div>

        <div className='uk-margin-medium-top uk-width-2-3 uk-flex uk-flex-column'>
          <form>
            <CTextInput
              label={lang.commentary}
              name='commentary'
              type='textarea'
              control={control}
              rows={5}
              maxLength={2000}
              charCount
              className='leo-text-area'
              required
            />
            {/* <div className="uk-flex uk-flex-right">
                            <Button
                                type="button"
                                size="small"
                                color="dark"
                                disabled={disabledButton}
                                onClick={guardarData}
                            >
                                {languageContext.dictionary["save"]}
                            </Button>
                        </div> */}
          </form>
        </div>

        <div className='uk-width-1-1 uk-margin-medium-top uk-margin-bottom uk-flex uk-flex-between'>
          {activeProfessional === 0 ? (
            <div></div>
          ) : (
            <Button
              type='button'
              size='small'
              color='dark'
              onClick={changeBack}
            >
              {lang.back}
            </Button>
          )}
          {activeProfessional === professionals.length - 1 ? (
            <Button
              type='button'
              size='small'
              color='dark'
              onClick={submitReview}
            >
              {lang.finish}
            </Button>
          ) : (
            <Button
              type='button'
              size='small'
              color='dark'
              onClick={changeNext}
            >
              {lang.next}
            </Button>
          )}
        </div>
      </div>
    </StandardView>
  )
}
