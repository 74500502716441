import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './styles/style.css'

export default function TextAreaInput(props) {
  const { onChange, name, required, placeholder, disabled, theme, init } = props
  const [value, setValue] = useState(init)
  const [selected, setSelected] = useState(false)

  //onChange returns input value
  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  return (
    <div className='uk-margin-medium-bottom'>
      <textarea
        {...props}
        rows={3}
        value={value}
        onChange={(event) => {
          setValue(event.target.value)
          onChange(event)
        }}
        name={name}
        placeholder={placeholder + (required ? '*' : '')}
        disabled={disabled}
        className={[
          'diinco',
          'uk-input',
          selected ? 'diinco-selected' : '',
          theme === 'dark' ? 'diinco-textarea-dark' : 'diinco-textarea-light'
        ].join(' ')}
        onFocus={() => {
          setSelected(true)
        }}
        onBlur={() => {
          setSelected(false)
        }}
      />
      <div className='invalid-feedback cj-primary-color' />
    </div>
  )
}

TextAreaInput.defaultProps = {
  onChange: () => {},
  name: '',
  placeholder: '',
  disabled: false,
  theme: 'default',
  init: '',
  value: null,
  required: false
}

TextAreaInput.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  theme: PropTypes.oneOf(['default', 'dark']),
  init: PropTypes.any,
  value: PropTypes.any,
  required: PropTypes.bool
}
