import React, { useContext, useEffect, useState, useRef } from 'react'
import './styles/style.css'

import _Utils from '../../context/utils'
import _Services from '../../context/services'
import _Auth from '../../context/auth'

import { Modal } from 'react-responsive-modal'

import { CTextInput } from '../../components/TextInput'
import { CSelectInput } from '../../components/SelectInput'
import { CSwitchInput } from '../../components/SwitchInput'
import { CTagsInput } from '../../components/TagsInput'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { LanguageContext } from '../../context/language/context'
import { _handleErrorMessage } from '../../functions/error.functions'
import { Prompt } from 'react-router-dom'
import {
  serialize,
  serverValidation,
  validationRules
} from '../../functions/validation.functions'

import { format } from 'date-fns'
import Button from '../../components/Button'
import StandardView from '../../layouts/StandardView'
import { IconButton } from '../../components/IconButton'
import ProjectPlansModal from '../../components/ProjectPlansModal'

export default function ProjectForm() {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const { toggleLoader, enqueueNotification, notify } = useContext(
    _Utils.Context
  )
  const { reloadData, isLoaded } = useContext(_Auth.Context)
  const history = useHistory()
  const { id } = useParams()

  const [openProjectPlansModal, setOpenProjectPlansModal] = useState(false)
  function onOpenProjectPlansModal() {
    setOpenProjectPlansModal(true)
  }
  const onCloseProjectPlansModal = () => setOpenProjectPlansModal(false)

  const [open, setOpen] = useState(false)

  function onOpenModal() {
    setOpen(true)
  }
  const onCloseModal = () => setOpen(false)

  const myRef = useRef(null)

  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])

  const [disabledLocation, setDisabledLocation] = useState(true)
  const [disabledState, setDisabledState] = useState(true)
  const [disabledCity, setDisabledCity] = useState(true)
  const [loadedData, setLoadedData] = useState({
    countries: false,
    states: false,
    cities: false,
    rest: false
  })
  const [disabledButton, setDisabledButton] = useState(false)

  const {
    countries,
    states,
    cities,
    services,
    checkUpdates,
    selectedCountry,
    selectedState,
    setSelectedCountry,
    setSelectedState,
    updateStates,
    updateCities
  } = useContext(_Services.Context)

  const frecuencyOptions = [
    {
      value: 'S',
      label: lang.sixmonthly
    },
    {
      value: 'T',
      label: lang.trimonthly
    },
    {
      value: 'B',
      label: lang.bimonthly
    },
    {
      value: 'M',
      label: lang.monthly
    },
    {
      value: 'Q',
      label: lang.biweekly
    },
    {
      value: 'W',
      label: lang.weekly
    }
  ]

  const statuses = [
    {
      value: 'active',
      label: lang.active
    },
    {
      value: 'canceled',
      label: lang.canceled
    },
    {
      value: 'finished',
      label: lang.finished
    },
    {
      value: 'holding',
      label: lang.holding
    },
    {
      value: 'suspended',
      label: lang.suspended
    }
  ]

  const countriesOptions = countries.map((country) => ({
    value: country,
    label: country.name
  }))

  const statesOptions = states.map((state) => ({
    value: state,
    label: state.name
  }))

  const citiesOptions = cities.map((city) => ({
    value: city,
    label: city.name
  }))

  const servicesOptions = services.map((service) => ({
    value: service.id,
    label: service.title
  }))

  useEffect(() => {
    async function Init() {
      await toggleLoader(true)
      await checkUpdates()
      setLoadedData({ ...loadedData, countries: true })
    }
    Init()
  }, [])

  const [contador, setContador] = useState(0)

  useEffect(() => {
    async function Init() {
      if (
        isLoaded &&
        contador < 3 &&
        id &&
        !loadedData.rest &&
        loadedData.countries
      )
        try {
          setContador(contador + 1)
          await toggleLoader(true)
          let data = await fetch(
            process.env.REACT_APP_API_URL + 'projects/getone/' + id,
            {
              method: 'GET',
              credentials: 'include',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )
          data = await data.json()

          //phases
          var phases = []
          data &&
            data.Stages &&
            data.Stages.length > 0 &&
            data.Stages.map((stage) => {
              if (stage.date_start === null) {
                return null
              } else {
                let start = stage.date_start.split('T')[0]
                let end = stage.date_end.split('T')[0]
                let arreglo = {
                  name: stage.name,
                  start_date: start,
                  end_date: end,
                  description: stage.description,
                  id: stage.id
                }
                return phases.push(arreglo)
              }
            })

          //services
          var services = []
          data &&
            data.Services &&
            data.Services.length > 0 &&
            data.Services.map((service) => {
              return services.push(
                servicesOptions.find((c) => c.value === service.id)
              )
            })

          //TAGS
          var tags = []
          data &&
            data.Tags &&
            data.Tags.length > 0 &&
            data.Tags.map((tag) => {
              return tags.push(tag.name)
            })

          //location
          const location = data.Location ? data.Location : {}

          if (location.City && (!loadedData.States || !loadedData.Cities)) {
            if (
              !selectedCountry ||
              selectedCountry.id !== location.City.State.Country.id
            ) {
              setSelectedCountry(location.City.State.Country)
              setLoadedData({
                ...loadedData,
                countries: true,
                states: false,
                cities: false
              })
            } else if (
              !selectedState ||
              selectedState.id !== location.City.State.id
            ) {
              await updateStates(location.City.State.Country.id)
              setSelectedState(location.City.State)
              setLoadedData({
                ...loadedData,
                states: true,
                cities: false
              })
            } else {
              await updateCities(location.City.State.id)
              setLoadedData({
                ...loadedData,
                countries: true,
                states: true,
                cities: true
              })
            }
          }

          setDisabledState(!location.City)
          setDisabledCity(!location.City)
          setDisabledLocation(!location.City)

          //images
          let images = []
          if (data.Stages) {
            let indexGeneral
            for (let i = 0; i < data.Stages.length; i++) {
              if (data.Stages[i].name === 'General') {
                indexGeneral = i
                break
              }
            }
            if (data.Stages[indexGeneral].Album) {
              if (data.Stages[indexGeneral].Album.cover)
                images.push(data.Stages[indexGeneral].Album.cover)
              if (data.Stages[indexGeneral].Album.Images.length > 0) {
                data.Stages[indexGeneral].Album.Images.forEach((image) => {
                  if (image.url) images.push(image.url)
                })
              }
            }
          }
          reset({
            title: data.title,
            description: data.description,
            payment_model: frecuencyOptions.find(
              (c) => c.value === data.payment_model
            ),
            country: location.City
              ? countriesOptions.find(
                  (c) => c.value.id === location.City.State.Country.id
                )
              : null,
            state: location.City
              ? statesOptions.find((c) => c.value.id === location.City.State.id)
              : null,
            city: location.City
              ? citiesOptions.find((c) => c.value.id === location.City.id)
              : null,
            street: location.street ?? '',
            int_number: location.int_number ?? '',
            ext_number: location.ext_number ?? '',
            neightborhood: location.neightborhood ?? '',
            postal_code: location.postal_code ?? '',
            reference: location.reference ?? '',
            date_start: data.date_start,
            date_end: data.date_end,
            phases: phases,
            services: services,
            isPublic: data.public === 1 ? true : false,
            tags: tags,
            files: [],
            oldImages: images,
            status: statuses.find((s) => s.value === data.status)
          })
          setLoadedData({
            ...loadedData,
            rest: true
          })
          await toggleLoader(false)
        } catch (error) {
          console.log(error)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error),
            'error'
          )
        }
    }
    Init()
  }, [isLoaded, loadedData])

  const {
    register,
    reset,
    setValue,
    getValues,
    control,
    setError,
    handleSubmit,
    formState
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: '',
      description: '',
      payment_model: null,
      country: null,
      state: null,
      city: null,
      street: '',
      int_number: '',
      ext_number: '',
      neightborhood: '',
      postal_code: '',
      reference: '',
      date_start: '',
      date_end: '',
      phases: [],
      services: null,
      isPublic: false,
      tags: [],
      files: [],
      oldImages: [],
      status: null
    }
  })

  //PHASES FORM
  const {
    control: controlPhase,
    reset: resetPhase,
    handleSubmit: handleSubmitPhase,
    setValue: setValuePhases
  } = useForm({
    defaultValues: {
      phase_name: '',
      phase_start: '',
      phase_end: '',
      phase_description: '',
      index: '',
      phase_id: ''
    }
  })

  function onSubmitPhase(data) {
    setValue('phases', [
      ...getValues('phases'),
      {
        name: data.phase_name,
        start_date: data.phase_start,
        end_date: data.phase_end,
        description: data.phase_description,
        id: ' '
      }
    ])
    resetPhase()
    onCloseModal()
  }

  function deletePhase(index) {
    let phases = getValues('phases')
    phases.splice(index, 1)
    setValue('phases', phases)
    forceUpdate()
  }

  const [phaseButton, setPhaseButton] = useState(false)

  function editPhase(index) {
    let phase = getValues('phases')
    phase = phase[index]
    setValuePhases('phase_name', phase.name)
    setValuePhases('phase_start', phase.start_date)
    setValuePhases('phase_end', phase.end_date)
    setValuePhases('phase_description', phase.description)
    setValuePhases('index', index)
    setValuePhases('phase_id', phase.id)
    setPhaseButton(true)
    onOpenModal()
  }

  function replacePhase(data) {
    let newPhase = {
      name: data.phase_name,
      start_date: data.phase_start,
      end_date: data.phase_end,
      description: data.phase_description,
      id: data.phase_id
    }

    let phases = getValues('phases')
    phases[data.index] = newPhase
    resetPhase()
    onCloseModal()
  }

  function resetPhaseValues() {
    resetPhase()
    setPhaseButton(false)
  }

  useEffect(() => {
    register('phases')
  }, [])

  async function onSubmit(data) {
    if (!loadedData.rest) {
      enqueueNotification(
        languageContext.dictionary.error + ' ' + languageContext.dictionary.try,
        'error'
      )
      return
    }

    await toggleLoader(true)
    setDisabledButton(true)

    try {
      const formData = serialize(data, {
        dotsForObjectNotation: true,
        noArrayNotation: true,
        noFilesWithArrayNotation: true
      })
      formData.append('projectId', id)

      let response = await fetch(
        process.env.REACT_APP_API_URL + 'projects/' + id,
        {
          method: 'PUT',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data'
          },
          body: formData
        }
      )

      await serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        () => {
          reset(data)
          setDisabledButton(false)
          notify(
            languageContext.dictionary['success'],
            setTimeout(function () {
              reloadData(() => {
                history.push('/projects/' + id)
              })
            }, 1000)
          )
        },
        async (e) => {
          if (e?.error?.code === 8015) {
            onOpenProjectPlansModal()
          }
          setDisabledButton(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, e.error),
            'error'
          )
        }
      )
    } catch (error) {
      setDisabledButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    await toggleLoader(false)
  }

  useEffect(() => {
    if (formState.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
    } else {
      window.onbeforeunload = null
    }
  }, [formState.isDirty])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onError = () => {
    enqueueNotification(
      _handleErrorMessage(languageContext?.userLanguage, { code: 1000 }),
      'error'
    )
  }

  return (
    <StandardView
      title={lang.panel}
      links={[
        {
          link: '/Dashboard',
          title: languageContext.dictionary.dashboard
        },
        {
          link: '/Projects/' + id,
          title: languageContext.dictionary.projectDashboard
        }
      ]}
    >
      <div
        className='uk-margin-medium-top uk-width-1-1 uk-flex uk-flex-column'
        ref={myRef}
      >
        <form onSubmit={handleSubmit(onSubmit, onError)} noValidate>
          <Prompt
            when={formState.isDirty}
            message={JSON.stringify({
              title: languageContext.dictionary.discard,
              message: languageContext.dictionary.areYouSure,
              accept: languageContext.dictionary.accept,
              cancel: languageContext.dictionary.cancel
            })}
          />
          <div className='uk-grid'>
            <div className='uk-width-3-4'>
              <CTextInput
                label={languageContext.dictionary.title}
                name='title'
                control={control}
                maxLength={45}
                required
              />
            </div>
            <div className='uk-width-1-4'>
              <CSelectInput
                name='status'
                control={control}
                label={lang.status}
                options={statuses}
                required
              />
            </div>
          </div>

          <CTextInput
            label={languageContext.dictionary.desc}
            name='description'
            type='textarea'
            control={control}
            rows={3}
            maxLength={2000}
            charCount
          />

          <CSelectInput
            name='payment_model'
            control={control}
            label={lang.paymentFrequency}
            options={frecuencyOptions}
            required
          />

          <div className='uk-grid'>
            <div className='uk-width-1-3'>
              <CSelectInput
                name='country'
                options={countriesOptions}
                control={control}
                onChange={(selectedOption) => {
                  setDisabledState(!selectedOption)
                  setDisabledCity(true)
                  setSelectedCountry(selectedOption?.value)
                  setValue('state', null)
                  setValue('city', null)
                }}
                label={languageContext.dictionary.country}
              />
            </div>
            <div className='uk-width-1-3'>
              <CSelectInput
                name='state'
                control={control}
                label={languageContext.dictionary.state}
                disabled={disabledState}
                options={statesOptions}
                onChange={(selectedOption) => {
                  setDisabledCity(!selectedOption)
                  setSelectedState(selectedOption?.value)
                  setValue('city', null)
                }}
                required
              />
            </div>
            <div className='uk-width-1-3'>
              <CSelectInput
                name='city'
                control={control}
                label={languageContext.dictionary.city}
                onChange={(e) => {
                  if (e) setDisabledLocation(false)
                  else setDisabledLocation(true)
                }}
                disabled={disabledCity}
                required
                options={citiesOptions}
              />
            </div>
            <div className='uk-width-3-5'>
              <CTextInput
                label={languageContext.dictionary.street}
                name='street'
                control={control}
                disabled={disabledLocation}
                deps={'city'}
                required
                maxLength={100}
              />
            </div>
            <div className='uk-width-1-5'>
              <CTextInput
                label={languageContext.dictionary.externalNum}
                name='ext_number'
                control={control}
                disabled={disabledLocation}
                required
                maxLength={5}
                validate={{
                  alphaDash: (v) =>
                    validationRules.alphaDash(v, languageContext.userLanguage)
                }}
              />
            </div>
            <div className='uk-width-1-5'>
              <CTextInput
                label={languageContext.dictionary.internalNum}
                name='int_number'
                control={control}
                disabled={disabledLocation}
                maxLength={7}
                validate={{
                  alphaDash: (v) =>
                    validationRules.alphaDash(v, languageContext.userLanguage)
                }}
              />
            </div>
            <div className='uk-width-3-5'>
              <CTextInput
                label={languageContext.dictionary.neighborhood}
                name='neightborhood'
                control={control}
                disabled={disabledLocation}
                required
                maxLength={100}
              />
            </div>
            <div className='uk-width-2-5'>
              <CTextInput
                label={languageContext.dictionary.postalCode}
                name='postal_code'
                control={control}
                type='number'
                disabled={disabledLocation}
                required
                maxLength={5}
                minLength={5}
                charCount
              />
            </div>
            <div className='uk-width-1-1'>
              <CTextInput
                label={languageContext.dictionary.reference}
                name='reference'
                control={control}
                type='textarea'
                placeholder={lang.reference}
                disabled={disabledLocation}
                maxLength={200}
                charCount
              />
            </div>
          </div>
          <div className='uk-grid'>
            <div className='uk-width-1-2'>
              <CTextInput
                type='date'
                label={languageContext.dictionary.startDate}
                name='date_start'
                control={control}
                required
              />
            </div>
            <div className='uk-width-1-2'>
              <CTextInput
                type='date'
                label={languageContext.dictionary.endDate}
                name='date_end'
                control={control}
                required
              />
            </div>
          </div>

          {/* phases button */}
          <button
            className='uk-button uk-button-default oski-button-phase'
            onClick={() => {
              resetPhaseValues()
              onOpenModal()
            }}
            type='button'
          >
            {lang.addPhase} ({languageContext.dictionary.optional})
          </button>

          <div className='uk-margin-middle' style={{ marginBottom: 33 }}>
            {getValues('phases').map((p, index) => {
              return (
                <div
                  key={index + 'phases'}
                  className='uk-width-1-2@m uk-column-1-2 uk-margin uk-flex uk-flex-middle'
                >
                  <div
                    onClick={() => editPhase(index)}
                    className='oski-phase-object'
                  >
                    <div className='oski-phase-title'>
                      {lang.phase} {index + 1}
                    </div>
                    <div className='oski-phase-subtitle'>
                      {p.name}{' '}
                      {format(new Date(p.start_date + ' 00:00'), 'dd/MM/yyyy')}{' '}
                      - {format(new Date(p.end_date + ' 00:00'), 'dd/MM/yyyy')}
                    </div>
                  </div>
                  <div>
                    <button
                      type='button'
                      onClick={() => deletePhase(index)}
                      data-uk-icon='icon: close'
                      className='oski-button-close'
                    ></button>
                  </div>
                </div>
              )
            })}
            {formState.errors && formState.errors.phases && (
              <div className='leo-error-message'>
                <span data-uk-icon='icon: warning; ratio: 0.6'></span>{' '}
                {formState.errors.phases.message}
              </div>
            )}
          </div>

          <CSelectInput
            name='services'
            control={control}
            label={languageContext.dictionary.service}
            isMulti
            options={servicesOptions}
            required
          />

          <div className='uk-grid'>
            {/* <div className="uk-width-3-5">
							<CDropZoneCoverImage
								name={"files"}
								control={control}

								ContainerClasses="diinco-project-form-dropzone-container cj-height-dropzone"
								ImageClasses="diinco-project-form-dropzone-image"
								onChange={(e) => forceUpdate()}
								Image={getValues("oldImages")[0]}
							/>
							{formState.errors && formState.errors.oldImages &&
								<div className="leo-error-message">
									<span uk-icon="icon: warning; ratio: 0.6"></span> {formState.errors.oldImages.message}
								</div>
							}
							<ImageThumbsContainer
								thumbs={getValues("files")}
								containerStyle="diinco-thumbs-container-project-form"
								thumbStyle="diinco-thumbs-style-project-form"
								deleteButtonStyle="diinco-thumbs-delete-project-form"
								handleRemove={(event, file, index) => {
									if (typeof file === "string") {
										const newValue = getValues("oldImages").filter((o, i) => i !== index)
										setValue("oldImages", newValue)
										forceUpdate()
									} else {
										const newValue = getValues("files").filter((o, i) => i !== index)
										setValue("files", newValue)
										forceUpdate()
									}
								}}
								rootImages={getValues("oldImages")}
							/>
						</div> */}
            <div className='uk-width-5-5'>
              <CSwitchInput
                name='isPublic'
                control={control}
                textActive={languageContext.dictionary['public']}
                textInactive={languageContext.dictionary['private']}
              />
              <CTagsInput
                name='tags'
                control={control}
                classes={{
                  tagsContainer: 'cj-tags-container'
                }}
              />
            </div>
          </div>
          <div className='uk-flex uk-flex-between'>
            <Button
              onClick={() => {
                history.goBack()
              }}
              variant='contained'
              color='grey'
            >
              {languageContext.dictionary.cancel}
            </Button>
            <Button type='submit' color='secondary' disabled={disabledButton}>
              {languageContext.dictionary['save']}
            </Button>
          </div>
        </form>

        <Modal
          open={open}
          onClose={onCloseModal}
          center
          container={myRef.current}
          classNames={{ modal: 'customModalPhase' }}
          closeIcon={<IconButton icon='close' component='div' />}
        >
          <h2 className='oski-title-modal'>
            {!phaseButton ? lang.addPhase : lang.editPhase}
          </h2>
          <form
            onSubmit={
              !phaseButton
                ? handleSubmitPhase(onSubmitPhase)
                : handleSubmitPhase(replacePhase)
            }
            key={2}
            noValidate
          >
            <div className='uk-grid'>
              <div className='uk-width-1'>
                <CTextInput
                  control={controlPhase}
                  name='phase_name'
                  required
                  label={lang.phaseName}
                  maxLength={50}
                />
              </div>
              <div className='uk-width-1-2'>
                <CTextInput
                  type='date'
                  control={controlPhase}
                  name='phase_start'
                  required
                  label={languageContext.dictionary.startDate}
                />
              </div>
              <div className='uk-width-1-2'>
                <CTextInput
                  type='date'
                  control={controlPhase}
                  name='phase_end'
                  required
                  label={languageContext.dictionary.endDate}
                />
              </div>
              <div className='uk-width-1'>
                <CTextInput
                  control={controlPhase}
                  name='phase_description'
                  label={languageContext.dictionary.desc}
                  maxLength={2000}
                  type='textarea'
                  charCount
                />
              </div>
            </div>
            <div className='uk-flex uk-flex-right'>
              {!phaseButton ? (
                <Button type='submit' color='secondary'>
                  {languageContext.dictionary.add}
                </Button>
              ) : (
                <Button type='submit' color='secondary'>
                  {languageContext.dictionary.save}
                </Button>
              )}
            </div>
          </form>
        </Modal>
      </div>
      <ProjectPlansModal
        projectId={id}
        open={openProjectPlansModal}
        onClose={onCloseProjectPlansModal}
      />
    </StandardView>
  )
}
