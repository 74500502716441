// import Compressor from 'compressorjs'

export async function compressFilesInFormData(formData, key) {
  let tmp = []
  let newFormData = new FormData()
  console.log({ formData })
  for (const file of formData.entries()) {
    if (file[0] === key) {
      let value = await compressFiles(file[1])
      tmp.push(value)
    } else {
      newFormData.append(file[0], file[1])
    }
  }
  if (tmp.length === 1) {
    newFormData.append(key, tmp[0])
  } else if (tmp.length > 1) {
    for (let index = 0; index < tmp.length; index++) {
      const element = tmp[index]
      newFormData.append(key, element)
    }
  }

  return newFormData
}

export async function compressFiles(file) {
  //   console.log({ file }, 'compressFiles')
  //   function compress(file) {
  //     return new Promise((resolve, reject) => {
  //       new Compressor(file, {
  //         quality: 0.7,
  //         maxHeight: 1080,
  //         maxWidth: 1920,
  //         success: resolve,
  //         error: reject
  //       })
  //     })
  //   }

  //   let compressed = await compress(file)
  //   let image = new File([compressed], compressed.name, {
  //     type: compressed.type
  //   })
  //   return image
  return file
}

export function handleOnPastCapture(event) {
  const clipboardItems = event.clipboardData.items
  const items = [].slice.call(clipboardItems).filter(function (item) {
    return item.type.indexOf('image') !== -1
  })
  if (items.length === 0) {
    return undefined
  }
  const item = items[0]
  const blob = item.getAsFile()
  return blob
}

export function requestImage(filename) {
  if (!filename) return undefined
  return process.env.REACT_APP_API_URL + 'file/' + filename
}
