import React, { useContext } from 'react'
import './styles/style.css'
import PropTypes from 'prop-types'
import { LanguageContext } from '../../context/language/context'

export default function Card(props) {
  const {
    variant,
    color,
    hover,
    hoverColor,
    hoverVariant,
    className,
    padding,
    margin,
    style,
    icon = false,

    theme: themeFromProps,
    ...rest
  } = props

  const { theme } = useContext(LanguageContext)

  let themeProp = {}

  if (themeFromProps !== 'default') {
    if (themeFromProps === 'inverted')
      themeProp['data-input-theme'] = theme === 'light' ? 'dark' : 'light'
    else themeProp['data-input-theme'] = themeFromProps
  }

  return (
    <div
      className={[
        'cj-card-component',
        'cj-card-component-' + variant.toLowerCase(),
        'cj-card-component-' +
          (icon
            ? color === 'lightgrey'
              ? theme === 'light'
                ? 'lightgrey'
                : 'grey'
              : color.toLowerCase()
            : color.toLowerCase()),
        hover
          ? [
              'cj-card-component-hover-' + hoverVariant.toLowerCase(),
              'cj-card-component-hover-' + hoverColor.toLowerCase()
            ].join(' ')
          : '',
        className ?? '',
        theme !== 'default' ? 'cj-input-theme-' + theme : '',
        'uk-padding-small'
      ].join(' ')}
      style={{ padding, margin, ...style }}
      {...rest}
      {...themeProp}
    >
      {props.children}
    </div>
  )
}

Card.defaultProps = {
  variant: 'outlined',
  color: 'dark',
  hover: false,
  hoverColor: 'secondary',
  hoverVariant: 'outlined',
  theme: 'default',
  className: undefined,
  padding: undefined,
  margin: '0px',
  icon: false,
  style: undefined,
  children: undefined
}

Card.propTypes = {
  icon: PropTypes.bool,
  variant: PropTypes.oneOf(['filled', 'outlined', 'borderless']),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'grey',
    'dark',
    'light',
    'lightgrey',
    'transparent'
  ]),
  hover: PropTypes.bool,
  hoverColor: PropTypes.oneOf([
    'secondary',
    'grey',
    'lightgrey',
    'dark',
    'light',
    'lightgrey',
    'transparent'
  ]),
  hoverVariant: PropTypes.oneOf(['filled', 'outlined', 'borderless']),
  theme: PropTypes.oneOf(['default', 'inverted', 'light', 'dark']),
  className: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  style: PropTypes.any,
  children: PropTypes.any
}
