import React, { useContext, useEffect, useState } from 'react'
import { serverValidation } from '../../../functions/validation.functions'

//context
import { LanguageContext } from '../../../context/language/context'
import _Auth from '../../../context/auth'
import _Utils from '../../../context/utils'
import { Link, useHistory, useParams } from 'react-router-dom'
import { _handleErrorMessage } from '../../../functions/error.functions'
import NoData from '../../../components/NoData'
import Card from '../../../components/Card'
import { IconButton } from '../../../components/IconButton'
import Avatar from '../../../components/Avatar'
import Typography from '../../../components/Typography'
import { format } from 'date-fns'

import './styles/style.css'

const ProjectReports = () => {
  const { id } = useParams()
  const history = useHistory()
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }
  const { token, hasPermission } = useContext(_Auth.Context)
  const { HomeLoader, enqueueNotification } = useContext(_Utils.Context)
  const [status, setStatus] = useState(null)
  const [features, setFeatures] = useState(null)
  const [hasPurchase, setHasPurchase] = useState(false)
  const Header = (
    <div className='uk-width-1-1 uk-flex uk-flex-between'>
      <div></div>
      {/* {status !== 'finished' && hasPermission("report-create", null, id, "project") &&
        <Link to={"/Projects/" + id + "/Reports/Create"}>
          <IconButton icon="create" color="secondary" variant="filled" />
        </Link>
      } */}
    </div>
  )

  const Loader = (
    <div className='uk-width-1-1 uk-height-1-1'>
      <div
        className='uk-margin-auto uk-margin-medium-top uk-width-auto'
        style={{ width: 'fit-content' }}
      >
        <HomeLoader />
      </div>
    </div>
  )

  const [loading, setLoading] = useState(true)
  const [reports, setReports] = useState(null)

  async function Init() {
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'projects/' + id + '/reports',
        {
          method: 'GET',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      serverValidation(
        response,
        null,
        languageContext.userLanguage,
        (data) => {
          setLoading(false)
          setReports(data.reports)
          setStatus(data.status)
          setFeatures(data.features)
          setHasPurchase(data.hasPurchase)
        },
        (error) => {
          setLoading(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
          history.push('/Dashboard')
        }
      )
    } catch (error) {
      setLoading(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
      history.push('/Dashboard')
    }
  }

  useEffect(() => {
    if (!token) return
    Init()
  }, [])

  return (
    <div data-uk-grid className='uk-grid-small'>
      {Header}
      {loading ? (
        <>{Loader}</>
      ) : (
        <div className='uk-width-1-1'>
          {reports && reports.length ? (
            <div
              style={{
                height: 370,
                overflow: 'auto',
                paddingRight: 15
              }}
            >
              {!hasPurchase && features && features['max-reports'] && (
                <div className='uk-width-1-1 cj-hover-reports'>
                  <Typography align={'right'}>
                    {lang.reports} {features['max-reports'] - reports.length}
                  </Typography>
                </div>
              )}
              {reports.map((report, index) => (
                <div
                  key={'report-card-' + index}
                  className='uk-width-1-1 cj-hover-reports'
                >
                  <Link
                    to={'/Projects/' + id + '/Reports/' + report.id}
                    className='uk-width-1-1'
                    style={{
                      textTransform: 'none',
                      textDecoration: 'none'
                    }}
                  >
                    <Card
                      color={
                        languageContext.theme === 'dark' ? 'dark' : 'lightgrey'
                      }
                      hover
                      hoverColor={
                        languageContext.theme === 'dark' ? 'dark' : 'lightgrey'
                      }
                      hoverVariant='filled'
                      className='uk-width-1-1 uk-margin-small-bottom'
                    >
                      <div data-uk-grid>
                        <div className='uk-width-auto'>
                          <Avatar
                            src={report.User?.image}
                            alt={report.User?.name}
                            size='large'
                          />
                        </div>
                        <div className='uk-width-expand'>
                          <div
                            data-uk-grid
                            className='uk-child-width-1-1 uk-grid-collapse'
                          >
                            <div>
                              <Typography
                                variant={'body'}
                                color='inherit'
                                disableMargin
                              >
                                {report.title}
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                variant={'body2'}
                                color='inherit'
                                disableMargin
                              >
                                {report.User?.name}
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                color='inherit'
                                disableMargin
                                className='uk-margin-small-top'
                              >
                                {format(
                                  new Date(report.createdAt),
                                  'MM/dd/yyyy'
                                )}
                              </Typography>
                            </div>
                          </div>
                        </div>
                        <div className='uk-width-expand'>
                          <div
                            data-uk-grid
                            className='uk-child-width-1-1 uk-grid-collapse'
                          >
                            <div>
                              <Typography
                                align={'right'}
                                disableMargin
                                color={'secondary'}
                              >
                                {report.Stage?.name}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Link>
                </div>
              ))}
            </div>
          ) : (
            <NoData />
          )}
          <div className='uk-width-1-1 uk-flex uk-flex-between'>
            <div></div>
            {status !== 'finished' &&
              hasPermission('report-create', null, id, 'project') && (
                <Link
                  to={'/Projects/' + id + '/Reports/Create'}
                  style={{ marginRight: 40 }}
                >
                  <IconButton
                    icon='create'
                    color='secondary'
                    variant='filled'
                  />
                </Link>
              )}
          </div>
        </div>
      )}
    </div>
  )
}

export default ProjectReports
