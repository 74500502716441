import React, { useContext, useState, useEffect } from 'react'
import 'react-responsive-modal/styles.css'

import { LanguageContext } from '../../../context/language/context'
import Avatar from '../../Avatar'
import Typography from '../../Typography'
import PropTypes from 'prop-types'

export default function Card(props) {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('../languages/en.json')
  } else {
    lang = require('../languages/es.json')
  }

  const [info, setInfo] = useState([])

  useEffect(() => {
    setInfo(props.task)
  }, [props])

  //info has User_Prospects, User_Prospects has User and Role, Role has id.
  //function that searches the User_Prospects.Role where id is 5
  function findOwner() {
    let owner = info?.User_Prospects?.find(
      (prospectUser) => prospectUser.RoleId === 5
    )
    return owner
  }
  const owner = findOwner()

  return (
    <>
      <div
        className={
          !info.isOwner
            ? 'oski-request-container'
            : 'oski-request-container-owner'
        }
      >
        <div className='oski-request-title'>{info?.title}</div>
        <div className='oski-request-users'>
          <div
            className='uk-flex uk-flex-center uk-flex-middle oski-request-users-text'
            style={{
              cursor: 'pointer'
            }}
          >
            {owner ? (
              <Typography>{owner.User.name}</Typography>
            ) : (
              <Typography>{lang.otherUser}</Typography>
            )}
          </div>
        </div>
        {/* uikit grid divided in two columns*/}
        <div className='uk-grid uk-grid-small'>
          <div className='uk-width-1-2'>
            <div className='oski-request-date'>{info?.description}</div>
          </div>
          <div className='uk-width-1-2'>
            <div
              className='uk-flex uk-flex-right uk-flex-middle'
              style={{
                cursor: 'pointer'
              }}
            >
              {info?.User_Prospects?.map((prospectUser, index) => {
                //just displays first 6 users
                if (index < 3)
                  return (
                    <Avatar
                      src={prospectUser.User.image}
                      alt={prospectUser.User.name}
                      key={'project-users-' + index}
                      className='uk-margin-small-right'
                      border={
                        prospectUser.Role &&
                        prospectUser.Role.name === 'project-admin'
                      }
                    />
                  )
                else return null
              })}
              {info?.User_Prospects?.length > 3 && (
                <Avatar
                  total={info?.User_Prospects?.length - 3}
                  className='uk-margin-small-right'
                  tooltip={
                    'pos: right; title: ' +
                    info?.User_Prospects?.slice(3)
                      .map((prospectUser) => {
                        return prospectUser.User.name
                      })
                      .join('<br/>')
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Card.defaultProps = {
  task: {}
}

Card.propTypes = {
  task: PropTypes.object
}
