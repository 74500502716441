import React, { useContext, useState, useEffect, useRef } from 'react'
import './styles/style.css'

import _Services from '../../context/services'
import _Utils from '../../context/utils'
import _Auth from '../../context/auth'

import StandardView from '../../layouts/StandardView'
import Typography from '../../components/Typography'
import Card from '../../components/Card'
import Button from '../../components/Button'
import { useForm } from 'react-hook-form'
import {
  message,
  serialize,
  serverValidation,
  validationRules
} from '../../functions/validation.functions'
import { CTextInput } from '../../components/TextInput'
import { CSelectInput } from '../../components/SelectInput'
import { CSwitchInput } from '../../components/SwitchInput'
import { CTagsInput } from '../../components/TagsInput'
import CNumberFormat from '../../components/CNumberFormat'
import { IconButton } from '../../components/IconButton'
import ProgressBar from '../../components/ProgressBar'

import { LanguageContext } from '../../context/language/context'
import { Link, Prompt, useHistory } from 'react-router-dom'
import debounce from 'lodash.debounce'
import { _handleErrorMessage } from '../../functions/error.functions'
import { CDropZoneFiles } from '../../components/DropZoneFiles'
import PlansModal from '../../components/PlansModal'
import Modal from 'react-responsive-modal'
import ReCAPTCHA from 'react-google-recaptcha'

let UniversityId = null
let ProfessionId = null
const filterOptions = (array, inputValue) => {
  return array.filter((i) =>
    i.label.toLowerCase().includes(inputValue.toLowerCase())
  )
}

const inputValueFormatter = (value) => {
  //trim and capitalize
  return value.replace(/\w\S*/g, (w) =>
    w.replace(/^\w/, (c) => c.toUpperCase())
  )
}

//University input
const getUniversities = (inputValue, callback) => {
  if (!inputValue) {
    return callback([])
  }

  try {
    fetch(
      process.env.REACT_APP_API_URL +
      'professional/edit?' +
      new URLSearchParams({
        university: inputValue.trim().toLowerCase()
      }),
      {
        method: 'GET',
        credentials: 'include',
        mode: 'cors'
      }
    ).then((response) => {
      response.json().then((data) => {
        try {
          var universities = []
          data?.Universities.forEach((university) => {
            universities.push({
              value: university.name,
              label: inputValueFormatter(university.name),
              id: university.id
            })
          })
          callback(filterOptions(universities, inputValue))
        } catch (error) {
          return callback([])
        }
      })
    })
  } catch (error) {
    return callback([])
  }
}

const loadUniversities = debounce(getUniversities, 500)

//Career input
const getCareers = (inputValue, callback) => {
  if (!inputValue) {
    return callback([])
  }

  try {
    fetch(
      process.env.REACT_APP_API_URL +
      'professional/edit?' +
      new URLSearchParams({
        career: inputValue.trim().toLowerCase(),
        UniversityId
      }),
      {
        method: 'GET',
        credentials: 'include',
        mode: 'cors'
      }
    ).then((response) => {
      response.json().then((data) => {
        try {
          var careers = []
          data?.Careers.forEach((career) => {
            careers.push({
              value: career.name,
              label: inputValueFormatter(career.name)
            })
          })
          callback(filterOptions(careers, inputValue))
        } catch (error) {
          return callback([])
        }
      })
    })
  } catch (error) {
    return callback([])
  }
}

const loadCareers = debounce(getCareers, 500)

//Profession input
const getProfessions = (inputValue, callback) => {
  if (!inputValue) {
    return callback([])
  }

  try {
    fetch(
      process.env.REACT_APP_API_URL +
      'professional/edit?' +
      new URLSearchParams({
        profession: inputValue.trim().toLowerCase()
      }),
      {
        method: 'GET',
        credentials: 'include',
        mode: 'cors'
      }
    ).then((response) => {
      response.json().then((data) => {
        try {
          var professions = []
          data?.Professions.forEach((profession) => {
            professions.push({
              value: profession.name,
              label: inputValueFormatter(profession.name),
              id: profession.id
            })
          })
          callback(filterOptions(professions, inputValue))
        } catch (error) {
          return callback([])
        }
      })
    })
  } catch (error) {
    return callback([])
  }
}

const loadProfessions = debounce(getProfessions, 500)

//Specialty input
const getSpecialties = (inputValue, callback) => {
  if (!inputValue) {
    return callback([])
  }

  try {
    fetch(
      process.env.REACT_APP_API_URL +
      'professional/edit?' +
      new URLSearchParams({
        specialty: inputValue.trim().toLowerCase(),
        ProfessionId
      }),
      {
        method: 'GET',
        credentials: 'include',
        mode: 'cors'
      }
    ).then((response) => {
      response.json().then((data) => {
        try {
          var specialties = []
          data?.Specialties.forEach((specialty) => {
            specialties.push({
              value: specialty.name,
              label: inputValueFormatter(specialty.name)
            })
          })
          callback(filterOptions(specialties, inputValue))
        } catch (error) {
          return callback([])
        }
      })
    })
  } catch (error) {
    return callback([])
  }
}

const loadSpecialties = debounce(getSpecialties, 500)

export default function ProfileForm() {
  const captcha = useRef(null)
  const languageContext = useContext(LanguageContext)
  const { userLanguage, dictionary, theme } = useContext(LanguageContext)
  var lang
  if (userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }
  const history = useHistory()

  const [openPlansModal, setOpenPlansModal] = useState(false)

  function onOpenPlansModal() {
    setOpenPlansModal(true)
  }

  function onClosePlansModal() {
    setOpenPlansModal(false)
  }

  const [disabledLocation, setDisabledLocation] = useState(true)
  const [disabledState, setDisabledState] = useState(true)
  const [disabledCity, setDisabledCity] = useState(true)
  const [disabledNewPassword, setDiabledNewPassword] = useState(true)
  const [disabledButtonProfileInfo, setDisabledButtonProfileInfo] =
    useState(true)
  const [disabledButtonContactInfo, setDisabledButtonContactInfo] =
    useState(true)
  const [disabledButtonAccountInfo, setDisabledButtonAccountInfo] =
    useState(true)
  const [disabledButtonPreferencesInfo, setDisabledButtonPreferencesInfo] =
    useState(true)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false) //for delete account modal
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState()
  const [areHumanMessage, setAreHumanMessage] = useState(null)

  const {
    countries,
    states,
    cities,
    services,
    checkUpdates,
    selectedCountry,
    selectedState,
    setSelectedCountry,
    setSelectedState,
    updateStates,
    updateCities
  } = useContext(_Services.Context)

  const { token, self, reloadData, isLoaded, features } = useContext(
    _Auth.Context
  )

  const { enqueueNotification, toggleLoader, notify } = useContext(
    _Utils.Context
  )

  const [occupationOptions, setOccupationOptions] = useState([])
  const [disabledInfoInputs, setDisabledInfoInputs] = useState(true)
  const [loadedData, setLoadedData] = useState({
    countries: false,
    states: false,
    cities: false,
    rest: false
  })

  // const [subscriptions, setSubscriptions] = useState([])
  const [contador, setContador] = useState(0)
  const [storageUsage, setStorageUsage] = useState(0)

  useEffect(() => {
    async function Init() {
      // //plans
      // let response = await fetch(
      //     process.env.REACT_APP_API_URL + "stripe/subscriptions",
      //     {
      //         method: "GET",
      //         credentials: "include",
      //         mode: "cors",
      //     }
      // );
      // serverValidation(
      //     response,
      //     null,
      //     userLanguage,
      //     (data) => {
      //         setSubscriptions(data.data)
      // 	},
      // 	(error, status) => {
      // 		enqueueNotification(_handleErrorMessage(userLanguage, error.error), "error");
      // 		history.push("/Dashboard");
      // 	}
      // )

      if (
        isLoaded &&
        contador < 3 &&
        token &&
        !loadedData.rest &&
        loadedData.countries
      )
        try {
          setContador(contador + 1)
          await toggleLoader(true)

          //location
          const location = self.Locations.length > 0 ? self.Locations[0] : {}

          if (location.City && (!loadedData.States || !loadedData.Cities)) {
            if (
              !selectedCountry ||
              selectedCountry.id !== location.City.State.Country.id
            ) {
              setSelectedCountry(location.City.State.Country)
              setLoadedData({
                ...loadedData,
                countries: true,
                states: false,
                cities: false
              })
            } else if (
              !selectedState ||
              selectedState.id !== location.City.State.id
            ) {
              await updateStates(location.City.State.Country.id)
              setSelectedState(location.City.State)
              setLoadedData({
                ...loadedData,
                states: true,
                cities: false
              })
            } else {
              await updateCities(location.City.State.id)
              setLoadedData({
                ...loadedData,
                countries: true,
                states: true,
                cities: true
              })
            }
          }

          setDisabledState(!location.City)
          setDisabledCity(!location.City)

          setDisabledLocation(self.Locations[0]?.street ? false : true)

          resetContactInfo({
            contactPhone: self.contact_phone,
            contactMail: self.contact_email,
            street: self.Locations[0]?.street,
            extNumber: self.Locations[0]?.ext_number,
            intNumber: self.Locations[0]?.int_number,
            neighborhood: self.Locations[0]?.neightborhood,
            postalCode: self.Locations[0]?.postal_code,
            country: location.City
              ? countriesOptions.find(
                (c) => c.value.id === location.City.State.Country.id
              )
              : null,
            state: location.City
              ? statesOptions.find((c) => c.value.id === location.City.State.id)
              : null,
            city: location.City
              ? citiesOptions.find((c) => c.value.id === location.City.id)
              : null
          })
          resetAccountInfo({
            nickname: self.nickname,
            accountPhone: self.phone,
            accountMail: self.email
          })
          resetPreferencesInfo({
            newsLetter: self.Preference?.news_letter,
            mailNotification: self.Preference?.mail_notifications,
            isPublic: self.Preference?.private
          })
          setLoadedData({
            ...loadedData,
            rest: true
          })
          await toggleLoader(false)
        } catch (error) {
          await toggleLoader(false)
        }
    }
    Init()
  }, [token, isLoaded, loadedData])

  const [progress, setProgress] = useState(0)

  useEffect(() => {
    async function Init() {
      try {
        let response = await fetch(
          process.env.REACT_APP_API_URL + 'professional/edit',
          {
            method: 'GET',
            credentials: 'include',
            mode: 'cors'
          }
        )

        await serverValidation(
          response,
          null,
          userLanguage,
          async (data) => {
            setOccupationOptions(
              data?.Occupations.map((occupation) => ({
                value: occupation.name,
                label: dictionary.occupations[occupation.name]
              }))
            )

            if (data.storage) setStorageUsage(data.storage)

            //set input defaults
            //INFO
            //TAGS
            var tags = []
            self.Tags &&
              self.Tags.length > 0 &&
              self.Tags.map((tag) => {
                return tags.push(tag.name)
              })

            //CAREERS & UNIVERSITIES
            var university = null
            var career = null
            if (self.User_Universities && self.User_Universities.length) {
              university = {
                value: self.User_Universities[0].University.name,
                label: inputValueFormatter(
                  self.User_Universities[0].University.name
                ),
                id: self.User_Universities[0].University.id
              }
              career = {
                value:
                  self.User_Universities[0].User_University_Careers[0].Career
                    .name,
                label: inputValueFormatter(
                  self.User_Universities[0].User_University_Careers[0].Career
                    .name
                )
              }
            }

            //SPECIALTIES & PROFESSIONS
            var profession = null
            var specialty = null
            if (self.User_Professions && self.User_Professions.length) {
              profession = {
                value: self.User_Professions[0].Profession.name,
                label: inputValueFormatter(
                  self.User_Professions[0].Profession.name
                ),
                id: self.User_Professions[0].Profession.id
              }
              if (
                self.User_Professions[0].User_Profession_Specialties &&
                self.User_Professions[0].User_Profession_Specialties[0] &&
                self.User_Professions[0].User_Profession_Specialties[0]
                  .Specialty
              ) {
                specialty = {
                  value:
                    self.User_Professions[0].User_Profession_Specialties[0]
                      .Specialty.name,
                  label: inputValueFormatter(
                    self.User_Professions[0].User_Profession_Specialties[0]
                      .Specialty.name
                  )
                }
              }
            }

            //OCCUPATIONS
            var occupation = null
            if (self.User_Occupations && self.User_Occupations.length) {
              occupation = {
                value: self.User_Occupations[0].Occupation.name,
                label:
                  dictionary.occupations[
                  self.User_Occupations[0].Occupation.name
                  ]
              }
            }

            // //location
            // const location = self.Locations.length > 0 ? self.Locations[0] : {}

            // if(location.City && (!loadedData.States || !loadedData.Cities)){
            //     if (!selectedCountry || selectedCountry.id !== location.City.State.Country.id) {
            //         setSelectedCountry(location.City.State.Country)
            //         setLoadedData({
            //             ...loadedData,
            //             countries: true,
            //             states: false,
            //             cities: false
            //         })
            //     }
            //     else if (!selectedState || selectedState.id !== location.City.State.id) {
            //         await updateStates(location.City.State.Country.id)
            //         setSelectedState(location.City.State)
            //         setLoadedData({
            //             ...loadedData,
            //             states: true,
            //             cities: false
            //         })
            //     }
            //     else {
            //         await updateCities(location.City.State.id)
            //         setLoadedData({
            //             ...loadedData,
            //             countries: true,
            //             states: true,
            //             cities: true
            //         })
            //     }
            // }

            // setDisabledState(!Boolean(location.City))
            // setDisabledCity(!Boolean(location.City))

            var servicios = []
            self &&
              self.Services &&
              self.Services.length > 0 &&
              self.Services.map((service) => {
                return servicios.push(
                  servicesOptions.find((c) => c.value === service.id)
                )
              })

            resetAccountInfo({
              nickname: self.nickname ?? '',
              accountPhone: self.phone ?? '',
              accountMail: self.email ?? ''
            })
            resetPreferencesInfo({
              newsLetter: self.Preference?.news_letter,
              mailNotification: self.Preference?.mail_notifications,
              isPublic: self.Preference?.private
            })
            resetProfileInfo({
              name: self.name ?? '',
              occupation: occupation,
              company: self.company,
              university: university,
              career: career,
              profession: profession,
              specialty: specialty,
              description: self.description ?? '',
              tags,
              services: servicios,
              video: self.video ?? ''
            })
            // CALCULATING PROGRESSBAR
            let initProgress = 0
            if (
              self.name &&
              occupation &&
              university &&
              career &&
              profession &&
              specialty &&
              self.description &&
              tags &&
              self.video
            )
              initProgress = initProgress + 20
            if (
              self.contact_phone &&
              self.contact_email &&
              self.Locations[0]?.street &&
              self.Locations[0]?.ext_number &&
              self.Locations[0]?.neightborhood &&
              self.Locations[0]?.postal_code
            )
              initProgress = initProgress + 20
            if (self.banner) initProgress = initProgress + 20
            if (self.banner) initProgress = initProgress + 20
            if (self.Albums && self.Albums.length > 0)
              initProgress = initProgress + 20

            setProgress(initProgress)
          },
          async (error) => {
            enqueueNotification(
              _handleErrorMessage(userLanguage, error.error),
              'error'
            )
          }
        )
      } catch (error) {
        enqueueNotification(_handleErrorMessage(userLanguage, error), 'error')
      }
    }
    if (!token) return
    Init()
  }, [token])

  useEffect(() => {
    setOccupationOptions(
      occupationOptions?.map((occupation) => {
        return {
          value: occupation.value,
          label: dictionary.occupations[occupation.value]
        }
      })
    )
  }, [userLanguage])

  // useEffect(() => {
  // 	async function Init() {
  // 		// await toggleLoader(true);
  // 		await checkUpdates();
  // 		// await toggleLoader(false);
  // 	}
  // 	Init();
  // }, []);

  useEffect(() => {
    async function Init() {
      await toggleLoader(true)
      await checkUpdates()
      setLoadedData({ ...loadedData, countries: true })
      await toggleLoader(false)
    }
    Init()
  }, [])

  const countriesOptions = countries.map((country) => ({
    value: country,
    label: country.name
  }))

  const statesOptions = states.map((state) => ({
    value: state,
    label: state.name
  }))

  const citiesOptions = cities.map((city) => ({
    value: city,
    label: city.name
  }))

  const servicesOptions = services.map((service) => ({
    value: service.id,
    label: service.title
  }))

  //  profile information form
  const {
    register: registerProfileInfo,
    setError: setErrorProfileInfo,
    control: controlProfileInfo,
    reset: resetProfileInfo,
    handleSubmit: handleSubmitProfileInfo,
    setValue: setValueProfileInfo,
    formState: formStateProfileInfo,
    getValues: getValuesProfileInfo
  } = useForm({
    // mode: 'onChange',
    defaultValues: {
      name: '',
      occupation: null,
      company: '',
      university: null,
      career: null,
      profession: '',
      specialty: null,
      description: '',
      tags: [],
      cv: [],
      services: null,
      deleteCV: false,
      video: ''
    }
  })

  useEffect(() => {
    if (formStateProfileInfo.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
      setDisabledButtonProfileInfo(false)
    } else {
      window.onbeforeunload = null
      setDisabledButtonProfileInfo(true)
    }
  }, [formStateProfileInfo.isDirty])

  //  contact information form
  const {
    control: controlContactInfo,
    reset: resetContactInfo,
    setError,
    handleSubmit: handleSubmitContactInfo,
    setValue: setValueContactInfo,
    formState: formStateContactInfo
  } = useForm({
    // mode: 'onChange',
    defaultValues: {
      contactPhone: '',
      contactMail: '',
      street: undefined,
      extNumber: undefined,
      intNumber: undefined,
      neighborhood: undefined,
      postalCode: undefined,
      country: null,
      state: undefined,
      city: undefined
    }
  })

  useEffect(() => {
    if (formStateContactInfo.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
      setDisabledButtonContactInfo(false)
    } else {
      window.onbeforeunload = null
      setDisabledButtonContactInfo(true)
    }
  }, [formStateContactInfo.isDirty])

  // useEffect(() => {
  // 	async function Init() {
  // 		toggleLoader(true);
  // 		setLoader(true);
  // 		try {
  // 			let data = await fetch(
  // 				process.env.REACT_APP_API_URL + "auth/getone",
  // 				{
  // 					method: "POST",
  // 					headers: {
  // 						"Content-Type": "application/json",
  //                         Authorization: "Bearer " + token,
  // 					},
  // 				}
  // 			);
  // 			data = await data.json();
  // 			if (data.message !== "not-found") {
  //                 console.log(data);
  // 				setUser(data);
  // 			}
  // 			else {
  // 				setUser(null);
  // 			}
  // 			toggleLoader(false);
  // 			setLoader(false);

  // 		} catch (error) {
  // 			console.log(error);
  // 			toggleLoader(false);
  // 			setLoader(false);
  // 		}

  // 	}
  // 	if (token) {
  // 		toggleLoader(true);
  // 		Init();
  // 		toggleLoader(false);
  // 	}
  // }, [token]);

  //  account information form
  const {
    setError: setErrorAccount,
    control: controlAccountInfo,
    reset: resetAccountInfo,
    handleSubmit: handleSubmitAccountInfo,
    setValue: setValueAccountInfo,
    getValues: getValuesAccountInfo,
    formState: formStateAccountInfo
  } = useForm({
    // mode: 'onChange',
    defaultValues: {
      nickname: '',
      accountPhone: '',
      accountMail: '',
      oldPass: undefined,
      newPass: '',
      newPassConfirm: ''
    }
  })

  useEffect(() => {
    if (formStateAccountInfo.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
      setDisabledButtonAccountInfo(false)
    } else {
      window.onbeforeunload = null
      setDisabledButtonAccountInfo(true)
    }
  }, [formStateAccountInfo.isDirty])

  useEffect(() => {
    if (formStateAccountInfo.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
      setDisabledButtonAccountInfo(false)
    } else {
      window.onbeforeunload = null
      setDisabledButtonAccountInfo(true)
    }
  }, [formStateAccountInfo.isDirty])

  const setUniversityId = () => {
    const universityId = getValuesProfileInfo('university')?.id
    UniversityId = universityId ? universityId : null
  }

  useEffect(() => {
    setUniversityId()
  }, [getValuesProfileInfo('university')])

  const setProfessionId = () => {
    const professionId = getValuesProfileInfo('profession')?.id
    ProfessionId = professionId ? professionId : null
  }

  useEffect(() => {
    setProfessionId()
  }, [getValuesProfileInfo('profession')])

  useEffect(() => {
    const occupation = getValuesProfileInfo('occupation')
    if (
      occupation?.value === 'student' ||
      occupation?.value === 'estate-agent'
    ) {
      if (getValuesProfileInfo('profession'))
        setValueProfileInfo('profession', null)
      if (getValuesProfileInfo('specialty'))
        setValueProfileInfo('specialty', null)
      setDisabledInfoInputs(true)
    } else setDisabledInfoInputs(false)
  }, [getValuesProfileInfo('occupation')])

  // useEffect(() => {
  //   if(disabledInfoInputs){
  //     console.log("inputs deshabilitados",getValuesProfileInfo(), getValuesProfileInfo("profession"));
  //     if(getValuesProfileInfo("profession")){
  //         console.log("setProfession");
  //         setValueProfileInfo('profession', null)
  //     }

  //     if(getValuesProfileInfo("specialty"))
  //         setValueProfileInfo('specialty', null)
  //   }
  // }, [disabledInfoInputs])

  //  preferences form
  const {
    control: controlPreferences,
    reset: resetPreferencesInfo,
    handleSubmit: handleSubmitPreferences,
    formState: formStatePreferences
  } = useForm({
    // mode: 'onChange',
    defaultValues: {
      mailNotification: false,
      newsLetter: false,
      isPublic: false
    }
  })

  useEffect(() => {
    if (formStatePreferences.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
      setDisabledButtonPreferencesInfo(false)
    } else {
      window.onbeforeunload = null
      setDisabledButtonPreferencesInfo(true)
    }
  }, [formStatePreferences.isDirty])

  async function onSubmitProfileInfo(data) {
    setDisabledButtonProfileInfo(true)
    await toggleLoader(true)
    try {
      const formData = serialize(data, {
        dotsForObjectNotation: true,
        noArrayNotation: true,
        noFilesWithArrayNotation: true
      })

      let response = await fetch(
        process.env.REACT_APP_API_URL + 'professional/updateProfileInfo',
        {
          method: 'PUT',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data'
          },
          body: formData
        }
      )

      await serverValidation(
        response,
        setErrorProfileInfo,
        userLanguage,
        () => {
          resetProfileInfo(data)
          setDisabledButtonProfileInfo(false)
          notify(
            dictionary['success'],
            setTimeout(function () {
              reloadData(() => {
                history.push('/Profile/Edit')
              })
            }, 1000)
          )
        },
        async (e) => {
          if (e?.error?.code === 8011) {
            onOpenPlansModal()
          }
          setDisabledButtonProfileInfo(false)
          enqueueNotification(
            _handleErrorMessage(userLanguage, e.error),
            'error'
          )
        }
      )
    } catch (error) {
      setDisabledButtonProfileInfo(false)
      enqueueNotification(_handleErrorMessage(userLanguage, error), 'error')
    }
    await toggleLoader(false)
  }

  function onCancelProfileInfo() {
    resetProfileInfo()
  }

  async function onSubmitContactInfo(data) {
    await toggleLoader(true)
    setDisabledButtonContactInfo(true)

    try {
      const formData = serialize(data, {
        dotsForObjectNotation: true,
        noArrayNotation: true,
        noFilesWithArrayNotation: true
      })

      let response = await fetch(
        process.env.REACT_APP_API_URL + 'professional/updateContactInfo',
        {
          method: 'PUT',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data'
          },
          body: formData
        }
      )

      await serverValidation(
        response,
        setError,
        userLanguage,
        () => {
          resetContactInfo(data)
          setDisabledButtonContactInfo(false)
          notify(
            dictionary['success'],
            setTimeout(function () {
              reloadData(() => {
                history.push('/Profile/Edit')
              })
            }, 1000)
          )
        },
        async (e) => {
          setDisabledButtonContactInfo(false)
          enqueueNotification(
            _handleErrorMessage(userLanguage, e.error),
            'error'
          )
        }
      )
    } catch (error) {
      setDisabledButtonContactInfo(false)
      enqueueNotification(_handleErrorMessage(userLanguage, error), 'error')
    }
    await toggleLoader(false)
  }

  function onCancelContactInfo() {
    resetContactInfo()
  }

  async function onSubmitAccountInfo(data) {
    await toggleLoader(true)
    setDisabledButtonAccountInfo(true)

    try {
      const formData = serialize(data, {
        dotsForObjectNotation: true,
        noArrayNotation: true,
        noFilesWithArrayNotation: true
      })

      let response = await fetch(
        process.env.REACT_APP_API_URL + 'professional/updateAccountInfo',
        {
          method: 'PUT',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data'
          },
          body: formData
        }
      )

      await serverValidation(
        response,
        setErrorAccount,
        userLanguage,
        () => {
          resetAccountInfo(data)
          setDisabledButtonAccountInfo(false)
          notify(
            dictionary['success'],
            setTimeout(function () {
              reloadData(() => {
                history.push('/Profile/Edit')
              })
            }, 1000)
          )
        },
        async (e) => {
          setDisabledButtonAccountInfo(false)
          enqueueNotification(
            _handleErrorMessage(userLanguage, e.error),
            'error'
          )
        }
      )
    } catch (error) {
      setDisabledButtonAccountInfo(false)
      enqueueNotification(_handleErrorMessage(userLanguage, error), 'error')
    }
    await toggleLoader(false)
  }

  function onCancelAccountInfo() {
    resetAccountInfo()
  }

  async function onSubmitPreferences(data) {
    await toggleLoader(true)
    setDisabledButtonPreferencesInfo(true)

    try {
      const formData = serialize(data, {
        dotsForObjectNotation: true,
        noArrayNotation: true,
        noFilesWithArrayNotation: true
      })

      let response = await fetch(
        process.env.REACT_APP_API_URL + 'professional/updatePreferencesInfo',
        {
          method: 'PUT',
          credentials: 'include',
          mode: 'cors',
          headers: {
            Accept: 'multipart/form-data'
          },
          body: formData
        }
      )

      await serverValidation(
        response,
        setError,
        userLanguage,
        () => {
          resetPreferencesInfo(data)
          setDisabledButtonPreferencesInfo(false)
          notify(
            dictionary['success'],
            setTimeout(function () {
              reloadData(() => {
                history.push('/Profile/Edit')
              })
            }, 1000)
          )
        },
        async (e) => {
          setDisabledButtonPreferencesInfo(false)
          enqueueNotification(
            _handleErrorMessage(userLanguage, e.error),
            'error'
          )
        }
      )
    } catch (error) {
      setDisabledButtonPreferencesInfo(false)
      enqueueNotification(_handleErrorMessage(userLanguage, error), 'error')
    }
    await toggleLoader(false)
  }

  function onCancelPreferences() {
    resetPreferencesInfo()
  }

  // DELETE PROFESSIONAL ACCOUNT HANDLER

  function onDeleteClose() {
    setIsOpenDeleteModal(false);
  }
  // 1st open modal
  function openDeleteModal() {
    setIsOpenDeleteModal(true);
  }
  // 2nd fetch delete account api
  function deleteAccount() {

    console.log('Deleting Professional')
  }
  // END OF DELETE PROFESSIONAL ACCOUNT HANDLER


  // const [open, setOpen] = useState(false);
  // const [subscriptionId, setSubscriptionId] = useState(null)
  // function onOpen() {
  //     setOpen(true);
  // }

  // function onClose() {
  //     setOpen(false);
  // }

  // async function cancelSubscription(){
  //     try {
  //         if(!subscriptionId)
  //             throw new Error

  //         let response = await fetch(
  // 			process.env.REACT_APP_API_URL + "stripe/subscriptions",
  // 			{
  // 				method: "DELETE",
  //                 credentials: "include",
  //                 mode: "cors",
  // 				headers: {
  // 					"Content-Type": "application/json",
  // 				},
  // 				body: JSON.stringify({
  //                     subscriptionId
  //                 })
  // 			}
  // 		);

  //         await serverValidation(
  // 			response,
  // 			null,
  // 			userLanguage,
  // 			(dataR) => {
  // 				notify(
  // 					dictionary["success"],
  // 					setTimeout(function () {
  // 						reloadData(() => {
  // 							history.push("/Profile/Edit");
  // 						})
  // 					}, 1000)
  // 				)
  // 			},
  // 			async (e, status) => {
  // 				enqueueNotification(
  // 					_handleErrorMessage(userLanguage, e.error),
  // 					"error"
  // 				);
  // 			}
  // 		)
  //     } catch (error) {
  //         enqueueNotification(
  // 			_handleErrorMessage(userLanguage, error),
  // 			"error"
  // 		);
  //     }
  // }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onError = () => {
    enqueueNotification(
      _handleErrorMessage(userLanguage, { code: 1000 }),
      'error'
    )
  }

  return (
    <StandardView
      links={[
        {
          link: '/Dashboard',
          title: dictionary.dashboard
        }
      ]}
      title={lang.title}
    >
      <ProgressBar percentage={progress}></ProgressBar>
      <br />
      <ul data-uk-accordion='multiple: true'>
        <li className='uk-open'>
          <a className='uk-accordion-title' href='#'>
            <Card
              color='secondary'
              variant='filled'
              padding='15px'
              className='fofo-accordion-title'
            >
              {lang.profileInformation}
            </Card>
          </a>
          <div className='uk-accordion-content'>
            <form
              onSubmit={handleSubmitProfileInfo(onSubmitProfileInfo, onError)}
              key={1}
              noValidate
            >
              <Prompt
                when={formStateProfileInfo.isDirty}
                message={JSON.stringify({
                  title: dictionary.discard,
                  message: dictionary.areYouSure,
                  accept: dictionary.accept,
                  cancel: dictionary.cancel
                })}
              />

              <CTextInput
                label={lang.name}
                name='name'
                control={controlProfileInfo}
                maxLength={100}
                required
              />

              <div className='uk-grid'>
                <div className='uk-width-1-2'>
                  <CSelectInput
                    label={dictionary.occupation}
                    name='occupation'
                    options={occupationOptions}
                    control={controlProfileInfo}
                  />
                </div>
                <div className='uk-width-1-2'>
                  <CTextInput
                    label={dictionary.company}
                    name='company'
                    control={controlProfileInfo}
                    maxLength={100}
                  />
                </div>
                <div className='uk-width-1-2'>
                  <CSelectInput
                    label={dictionary.university}
                    name='university'
                    control={controlProfileInfo}
                    isCreatable
                    isAsync
                    loadOptions={loadUniversities}
                    onChange={(option) => {
                      loadUniversities.cancel()
                      if (option)
                        return {
                          ...option,
                          label: inputValueFormatter(option.value)
                        }
                      else return null
                    }}
                    formatCreateLabel={(iValue) => {
                      return (
                        <div>
                          {dictionary.create} &quot;
                          {inputValueFormatter(iValue)}
                          &quot;
                        </div>
                      )
                    }}
                    useCustomOnChange
                    blurInputOnSelect={false}
                    noOptionsMessage={() => null}
                  />
                </div>
                <div className='uk-width-1-2'>
                  <CSelectInput
                    label={dictionary.career}
                    name='career'
                    control={controlProfileInfo}
                    isCreatable
                    isAsync
                    loadOptions={loadCareers}
                    cacheOptions={false}
                    onChange={(option) => {
                      loadCareers.cancel()
                      if (option)
                        return {
                          ...option,
                          label: inputValueFormatter(option.value)
                        }
                      else return null
                    }}
                    formatCreateLabel={(iValue) => {
                      return (
                        <div>
                          {dictionary.create} &quot;
                          {inputValueFormatter(iValue)}
                          &quot;
                        </div>
                      )
                    }}
                    useCustomOnChange
                    blurInputOnSelect={false}
                    noOptionsMessage={() => null}
                  />
                </div>
              </div>

              <div className='uk-grid uk-margin-remove-top'>
                <div className='uk-width-1-2'>
                  <CSelectInput
                    label={dictionary.profession}
                    name='profession'
                    control={controlProfileInfo}
                    isCreatable
                    isAsync
                    loadOptions={loadProfessions}
                    disabled={disabledInfoInputs}
                    onChange={(option) => {
                      loadProfessions.cancel()
                      if (option)
                        return {
                          ...option,
                          label: inputValueFormatter(option.value)
                        }
                      else return null
                    }}
                    formatCreateLabel={(iValue) => {
                      return (
                        <div>
                          {dictionary.create} &quot;
                          {inputValueFormatter(iValue)}
                          &quot;
                        </div>
                      )
                    }}
                    useCustomOnChange
                    blurInputOnSelect={false}
                    noOptionsMessage={() => null}
                  />
                </div>
                <div className='uk-width-1-2'>
                  <CSelectInput
                    label={dictionary.specialty}
                    name='specialty'
                    control={controlProfileInfo}
                    isCreatable
                    isAsync
                    loadOptions={loadSpecialties}
                    cacheOptions={false}
                    disabled={disabledInfoInputs}
                    onChange={(option) => {
                      loadSpecialties.cancel()
                      if (option)
                        return {
                          ...option,
                          label: inputValueFormatter(option.value)
                        }
                      else return null
                    }}
                    formatCreateLabel={(iValue) => {
                      return (
                        <div>
                          {dictionary.create} &quot;
                          {inputValueFormatter(iValue)}
                          &quot;
                        </div>
                      )
                    }}
                    useCustomOnChange
                    blurInputOnSelect={false}
                    noOptionsMessage={() => null}
                  />
                </div>
              </div>

              <CSelectInput
                name='services'
                control={controlProfileInfo}
                label={dictionary.service}
                isMulti
                options={servicesOptions}
                required
              />

              <CTextInput
                label={lang.description}
                name='description'
                type='textarea'
                control={controlProfileInfo}
                rows={3}
                maxLength={2000}
                charCount
              />
              <CTagsInput
                name='tags'
                classes={{ tagsContainer: 'leo-tag-sin-borde' }}
                control={controlProfileInfo}
              />
              <div style={{ display: 'flex' }}>
                <CTextInput
                  label={lang.video}
                  name='video'
                  control={controlProfileInfo}
                  maxLength={250}
                />
                <IconButton
                  icon='question'
                  variant='borderless'
                  iconColor='dark'
                  uk-tooltip={'title: ' + lang.onlyYoutube}
                  className='fofo-details-icon'
                  style={{ marginBottom: '30px' }}
                />
              </div>

              {/* FILES */}
              {Boolean(getValuesProfileInfo('cv'))}
              <div className='uk-flex uk-flex-middle uk-margin-medium-bottom'>
                <div>
                  {((getValuesProfileInfo('deleteCV') &&
                    !getValuesProfileInfo('cv')?.length) ||
                    (!self.cv_url && !self.cv_title)) && (
                      <Typography
                        color={'secondary'}
                        variant='body'
                        disableMargin
                        style={{ marginRight: 20 }}
                      >
                        {lang.noCV}
                      </Typography>
                    )}
                  {getValuesProfileInfo('cv')?.length > 0 ||
                    getValuesProfileInfo('deleteCV') ||
                    (self.cv_url && self.cv_title && (
                      <div className='uk-width-1-1 uk-flex uk-flex-between uk-flex-middle'>
                        <Typography
                          color={'secondary'}
                          variant='body'
                          disableMargin
                          style={{ marginRight: 20 }}
                        >
                          {self.cv_title + ' '}
                        </Typography>
                        <IconButton
                          className='uk-margin-left uk-margin-small-right'
                          size='x-small'
                          color='secondary'
                          icon='close'
                          onClick={() => {
                            setValueProfileInfo('deleteCV', true, {
                              shouldTouch: true
                            })
                          }}
                        />
                      </div>
                    ))}
                  {getValuesProfileInfo('cv')?.length > 0 && (
                    <div className='uk-width-1-1 uk-flex uk-flex-between uk-flex-middle'>
                      <Typography
                        color={'secondary'}
                        variant='body'
                        disableMargin
                        style={{ marginRight: 20 }}
                      >
                        {getValuesProfileInfo('cv')[0].path}
                      </Typography>
                    </div>
                  )}
                </div>
                <div>
                  <div>
                    <input
                      accept='application/pdf, application/txt, application/svg, application/doc'
                      type='checkbox'
                      style={{ display: 'none' }}
                      {...registerProfileInfo('deleteCV')}
                    />
                    <CDropZoneFiles
                      name='cv'
                      control={controlProfileInfo}
                      maxFiles={1}
                      icon
                      accept='application/pdf, application/doc, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                    />
                  </div>
                </div>
              </div>

              <div className='uk-container uk-container-xsmall uk-flex uk-flex-around'>
                <Button
                  color='grey'
                  containerStyle={{ width: 'fit-content' }}
                  disabled={disabledButtonProfileInfo}
                  onClick={onCancelProfileInfo}
                  type='reset'
                >
                  {dictionary.cancel}
                </Button>
                <Button
                  color='secondary'
                  containerStyle={{ width: 'fit-content' }}
                  type='submit'
                  disabled={disabledButtonProfileInfo}
                >
                  {dictionary.save}
                </Button>
              </div>
            </form>
          </div>
        </li>
        <li>
          <a className='uk-accordion-title' href='#'>
            <Card
              color='secondary'
              variant='filled'
              padding='15px'
              className='fofo-accordion-title'
            >
              {lang.contactInformation}
            </Card>
          </a>
          <div className='uk-accordion-content'>
            <form
              onSubmit={handleSubmitContactInfo(onSubmitContactInfo, onError)}
              key={2}
              noValidate
            >
              <Prompt
                when={formStateContactInfo.isDirty}
                message={JSON.stringify({
                  title: dictionary.discard,
                  message: dictionary.areYouSure,
                  accept: dictionary.accept,
                  cancel: dictionary.cancel
                })}
              />
              <CNumberFormat
                name='contactPhone'
                control={controlContactInfo}
                label={dictionary.phone}
                maxLength={10}
                minLength={10}
                format='(###) ###-####'
                mask='_'
              />
              <CTextInput
                label={dictionary.email}
                name='contactMail'
                control={controlContactInfo}
                type='email'
                maxLength={100}
              />
              <div className='uk-grid'>
                <div className='uk-width-3-5'>
                  <CTextInput
                    label={dictionary.street}
                    name='street'
                    control={controlContactInfo}
                    onChange={(e) => {
                      if (e.target.value !== '') {
                        setDisabledLocation(false)
                        setValueContactInfo('street', e.target.value)
                      } else {
                        setDisabledLocation(true)
                        setValueContactInfo('extNumber', '')
                        setValueContactInfo('intNumber', '')
                        setValueContactInfo('neighborhood', '')
                        setValueContactInfo('postalCode', '')
                        setValueContactInfo('street', '')
                      }
                    }}
                    maxLength={100}
                  />
                </div>
                <div className='uk-width-1-5'>
                  <CTextInput
                    label={dictionary.externalNum}
                    name='extNumber'
                    control={controlContactInfo}
                    maxLength={5}
                    validate={{
                      alphaDash: (v) =>
                        validationRules.alphaDash(v, userLanguage)
                    }}
                    disabled={disabledLocation}
                    required={!disabledLocation}
                  />
                </div>
                <div className='uk-width-1-5'>
                  <CTextInput
                    label={dictionary.internalNum}
                    name='intNumber'
                    control={controlContactInfo}
                    maxLength={5}
                    validate={{
                      alphaDash: (v) =>
                        validationRules.alphaDash(v, userLanguage)
                    }}
                    disabled={disabledLocation}
                  />
                </div>
                <div className='uk-width-3-5'>
                  <CTextInput
                    label={dictionary.neighborhood}
                    name='neighborhood'
                    control={controlContactInfo}
                    maxLength={100}
                    disabled={disabledLocation}
                    required={!disabledLocation}
                  />
                </div>
                <div className='uk-width-2-5'>
                  <CTextInput
                    label={dictionary.postalCode}
                    name='postalCode'
                    control={controlContactInfo}
                    type='number'
                    maxLength={5}
                    minLength={5}
                    charCount
                    disabled={disabledLocation}
                    required={!disabledLocation}
                  />
                </div>

                <div className='uk-grid uk-width-1-1'>
                  <div className='uk-width-1-3'>
                    <CSelectInput
                      label={dictionary.country}
                      name='country'
                      control={controlContactInfo}
                      options={countriesOptions}
                      onChange={(selectedOption) => {
                        setDisabledState(!selectedOption)
                        setDisabledCity(true)
                        setSelectedCountry(selectedOption?.value)
                        setValueContactInfo('state', null)
                        setValueContactInfo('city', null)
                      }}
                    />
                  </div>
                  <div className='uk-width-1-3'>
                    <CSelectInput
                      label={dictionary.state}
                      name='state'
                      control={controlContactInfo}
                      options={statesOptions}
                      disabled={disabledState}
                      onChange={(selectedOption) => {
                        setDisabledCity(!selectedOption)
                        setSelectedState(selectedOption?.value)
                        setValueContactInfo('city', null)
                      }}
                      required
                    />
                  </div>
                  <div className='uk-width-1-3'>
                    <CSelectInput
                      name='city'
                      control={controlContactInfo}
                      label={dictionary.city}
                      disabled={disabledCity}
                      required
                      options={citiesOptions}
                    />
                  </div>
                </div>
              </div>
              <div className='uk-container uk-container-xsmall uk-flex uk-flex-around'>
                <Button
                  color='grey'
                  containerStyle={{ width: 'fit-content' }}
                  disabled={disabledButtonContactInfo}
                  onClick={onCancelContactInfo}
                  type='reset'
                >
                  {dictionary.cancel}
                </Button>
                <Button
                  color='secondary'
                  type='submit'
                  disabled={disabledButtonContactInfo}
                >
                  {dictionary.save}
                </Button>
              </div>
            </form>
          </div>
        </li>
        <li>
          <a className='uk-accordion-title' href='#'>
            <Card
              color='secondary'
              variant='filled'
              padding='15px'
              className='fofo-accordion-title'
            >
              {lang.accountInformation}
            </Card>
          </a>
          <div className='uk-accordion-content'>
            <form
              onSubmit={handleSubmitAccountInfo(onSubmitAccountInfo, onError)}
              key={3}
              noValidate
            >
              <Prompt
                when={formStateAccountInfo.isDirty}
                message={JSON.stringify({
                  title: dictionary.discard,
                  message: dictionary.areYouSure,
                  accept: dictionary.accept,
                  cancel: dictionary.cancel
                })}
              />
              <CTextInput
                label={dictionary.userName}
                name='nickname'
                control={controlAccountInfo}
                validate={{
                  alphaDash: (v) => validationRules.alphaDash(v, userLanguage),
                  noSpaces: (v) => validationRules.noSpaces(v, userLanguage)
                }}
                maxLength={20}
                required
              />
              <CNumberFormat
                label={dictionary.phone}
                name='accountPhone'
                control={controlAccountInfo}
                maxLength={10}
                minLength={10}
                format='(###) ###-####'
                mask='_'
                required
              />
              <CTextInput
                label={dictionary.email}
                name='accountMail'
                control={controlAccountInfo}
                type='email'
                maxLength={100}
                required
              />
              <Typography variant='subtitle' color='secondary'>
                {dictionary.changePassword}
              </Typography>
              <div className='uk-grid'>
                <div className='uk-width-1-3'>
                  <CTextInput
                    label={dictionary.password}
                    name='oldPass'
                    control={controlAccountInfo}
                    onChange={(e) => {
                      if (e.target.value !== '') {
                        setDiabledNewPassword(false)
                        setValueAccountInfo('oldPass', e.target.value)
                      } else {
                        setDiabledNewPassword(true)
                        setValueAccountInfo('newPass', '')
                        setValueAccountInfo('newPassConfirm', '')
                        setValueAccountInfo('oldPass', '')
                      }
                    }}
                    type='password'
                    maxLength={100}
                  />
                </div>
                <div className='uk-width-1-3'>
                  <CTextInput
                    label={dictionary.newPassword}
                    name='newPass'
                    control={controlAccountInfo}
                    type='password'
                    disabled={disabledNewPassword}
                    validate={{
                      noSpaces: (v) =>
                        validationRules.noSpaces(v, userLanguage),
                      hasUppercase: (v) =>
                        validationRules.hasUppercase(v, userLanguage),
                      hasLowercase: (v) =>
                        validationRules.hasLowercase(v, userLanguage),
                      hasNumber: (v) =>
                        validationRules.hasNumber(v, userLanguage)
                    }}
                    maxLength={120}
                    minLength={8}
                    required={!disabledNewPassword}
                  />
                </div>
                <div className='uk-width-1-3'>
                  <CTextInput
                    label={dictionary.confirmPassword}
                    name='newPassConfirm'
                    control={controlAccountInfo}
                    type='password'
                    disabled={disabledNewPassword}
                    validate={{
                      same: (v) =>
                        validationRules.same(
                          v,
                          userLanguage,
                          getValuesAccountInfo,
                          'newPass',
                          'passwordMatch'
                        )
                    }}
                    maxLength={120}
                    minLength={8}
                    required={!disabledNewPassword}
                  />
                </div>
              </div>

              <div data-uk-grid className='uk-margin-remove-top'>
                <div className='uk-margin-top uk-flex uk-flex-center uk-flex-wrap uk-width-1-1'>
                  <progress
                    className='uk-progress leo-progress uk-width-1-1'
                    value={storageUsage}
                    // features is an array that can contain "max-storage", max is equal to the features "max-storage", extract "max-storage" from features and set it as the max value of the progress bar
                    max={features['max-storage'] ?? 0}
                  ></progress>
                  <div>
                    <Typography variant={'body2'} disableMargin>
                      Almacenamiento utilizado: {storageUsage} MB de{' '}
                      {features['max-storage'] ?? 0} MB
                    </Typography>
                  </div>
                </div>
              </div>
              {/* <Typography variant={"h5"}>
                                {lang.yourPlan}
                            </Typography> */}
              {/* {(subscriptions && subscriptions.length) ?
                                subscriptions.map(subscription => (
                                    <div className="uk-flex uk-flex-left uk-flex-middle fofo-plan-container">
                                        <Typography className="uk-margin-right" variant={"h6"} color={"secondary"} disableMargin>
                                            {subscription.plan?.product?.name ?? "Plan"}
                                        </Typography>
                                        <Typography disableMargin>
                                        ${(subscription.plan?.amount ? ((subscription.plan?.amount/100) ?? 0) : 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} / {lang[subscription.plan?.interval]}
                                        </Typography>
                                        <Button
                                            size="small"
                                            variant="text"
                                            disableMargin
                                            onClick={() => {
                                                setSubscriptionId(subscription.id)
                                                onOpen()
                                            }}
                                        >
                                            {dictionary.cancel} plan
                                        </Button>
                                    </div>
                                ))
                                :
                                <Typography>
                                    {lang.noPlans}
                                </Typography>
                            } */}
              <div data-uk-grid className='uk-margin-remove-top'>
                <div>
                  <Button
                    onClick={async () => {
                      let response = await fetch(
                        process.env.REACT_APP_API_URL +
                        'stripe/create-customer-portal-session',
                        {
                          method: 'POST',
                          credentials: 'include',
                          mode: 'cors'
                        }
                      )
                      serverValidation(
                        response,
                        null,
                        userLanguage,
                        (data) => {
                          window.location.href = data.url
                        },
                        (error) => {
                          enqueueNotification(
                            _handleErrorMessage(userLanguage, error.error),
                            'error'
                          )
                          history.push('/Dashboard')
                        }
                      )
                    }}
                    color='secondary'
                    style={{ color: 'black' }}
                  >
                    {lang.managePlans}
                  </Button>
                </div>
                <div>
                  <Link to='/subscription'>
                    <Button variant='text'>{lang.benefits}</Button>
                  </Link>
                </div>
              </div>

              <div className='uk-container uk-container-xsmall uk-flex uk-flex-around'>
                <Button
                  color='grey'
                  containerStyle={{ width: 'fit-content' }}
                  disabled={disabledButtonAccountInfo}
                  onClick={onCancelAccountInfo}
                  type='reset'
                >
                  {dictionary.cancel}
                </Button>
                <Button
                  color='secondary'
                  type='submit'
                  disabled={disabledButtonAccountInfo}
                >
                  {dictionary.save}
                </Button>
              </div>
            </form>
          </div>
        </li>
        <li>
          <a className='uk-accordion-title' href='#'>
            <Card
              color='secondary'
              variant='filled'
              padding='15px'
              className='fofo-accordion-title'
            >
              {lang.preferences}
            </Card>
          </a>
          <div className='uk-accordion-content'>
            <form
              onSubmit={handleSubmitPreferences(onSubmitPreferences, onError)}
              key={4}
              noValidate
            >
              <Prompt
                when={formStatePreferences.isDirty}
                message={JSON.stringify({
                  title: dictionary.discard,
                  message: dictionary.areYouSure,
                  accept: dictionary.accept,
                  cancel: dictionary.cancel
                })}
              />
              <div className='uk-container uk-container-xsmall'>
                <div className='uk-flex uk-flex-between uk-flex-top'>
                  <div className='uk-flex '>
                    <Typography variant='body' align='center' color='primary'>
                      {dictionary.privateProfile}
                    </Typography>
                    <IconButton
                      icon='question'
                      variant='borderless'
                      iconColor='dark'
                      uk-tooltip={'title: ' + lang.privateTooltip}
                      className='fofo-details-icon'
                    />
                  </div>
                  <div>
                    <CSwitchInput
                      name='isPublic'
                      control={controlPreferences}
                    />
                  </div>
                </div>
                <div className='uk-flex uk-flex-between'>
                  <Typography variant='body' align='center' color='primary'>
                    {dictionary.emailNofication}
                  </Typography>
                  <CSwitchInput
                    name='mailNotification'
                    control={controlPreferences}
                  />
                </div>
                <div className='uk-flex uk-flex-between'>
                  <Typography variant='body' align='center' color='primary'>
                    {dictionary.newsLetter}
                  </Typography>
                  <CSwitchInput
                    name='newsLetter'
                    control={controlPreferences}
                  />
                </div>
              </div>
              <div className='uk-container uk-container-xsmall uk-flex uk-flex-around'>
                <Button
                  color='grey'
                  containerStyle={{ width: 'fit-content' }}
                  disabled={disabledButtonPreferencesInfo}
                  onClick={onCancelPreferences}
                  type='reset'
                >
                  {dictionary.cancel}
                </Button>
                <Button
                  color='secondary'
                  type='submit'
                  disabled={disabledButtonPreferencesInfo}
                >
                  {dictionary.save}
                </Button>
              </div>
            </form>

            {/* DELETE PROFESSIONAL ACCOUNT */}
            <div className='uk-container uk-container-xsmall uk-flex uk-flex-around'>
              <Button
                color={'grey'}
                containerStyle={{ width: 'fit-content' }}
                // disabled={disabledButtonPreferencesInfo}
                onClick={openDeleteModal}
              // type='reset'
              >
                {/* PROFESSIONAL DELETE */}
                {dictionary.deleteAccount}
              </Button>
              {/* <Button
                  color='secondary'
                  containerStyle={{ width: 'fit-content' }}
                  type='submit'
                  disabled={disabledButtonPreferencesInfo}
                >
                  {languageContext.dictionary.save}
                </Button> */}
            </div>

          </div>
        </li>
      </ul>

      {/* DELETE ACCOUNT MODAL */}
      <Modal
        open={isOpenDeleteModal}
        onClose={onDeleteClose}
        center
        classNames={{
          modal: 'dc-code-verification'
        }}
        closeOnOverlayClick={false}
        closeIcon={
          <IconButton
            color={'light'}
            iconColor={'primary'}
            icon='close'
            component='div'
          />
        }
      >
        <div className='uk-flex uk-flex-column uk-flex-middle uk-flex-center'>
          <h2 className='dc-code-verification-title'>{lang['verifCode']}</h2>
          {/* <form onSubmit={() => {
            if (!captcha.current.getValue()) {
              alert('No captcha correct')
              setAreHumanMessage(message(languageContext.dictionary.areHuman, 'required'))
            } else {
              alert('YES CAPTCHA')
            }
          }} > */}


          <div className='uk-flex uk-flex-column uk-flex-left'>
            <ReCAPTCHA
              size='normal'
              ref={captcha}
              sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
              theme={theme === 'dark' ? 'dark' : 'light'}
            />
            {areHumanMessage && (
              <div className='leo-error-message' style={{ color: 'white' }}>
                <span data-uk-icon='icon: warning; ratio: 0.6'></span>{' '}
                {areHumanMessage}
              </div>
            )}
          </div>

          <Button
            size='small'
            color='secondary'
            // type='submit'
            buttonStyle={{ marginTop: '20px' }}
            containerStyle={{ marginBottom: '0px' }}
            disabled={isDeleteButtonDisabled}
            onClick={() => {
              if (!captcha.current.getValue()) {
                // alert('No captcha correct')
                setAreHumanMessage(message(languageContext.dictionary.areHuman, 'required'))
              } else {
                // alert('YES CAPTCHA')
              }
            }}
          >
            {languageContext.dictionary.confirm}
            {/* CONFIRM! */}
          </Button>
          {/* </form> */}
        </div>
      </Modal>


      <PlansModal open={openPlansModal} onClose={onClosePlansModal} />
    </StandardView >
  )
}
