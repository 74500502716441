import React, { useEffect, useState, useContext } from 'react'
import logo from './assets/images/logo_white.png'
import './App.css'
import Router from './routes/Router'
import { CTextInput } from './components/TextInput'
import { LanguageContext } from './context/language/context'
import { useForm } from 'react-hook-form'
import {
  serverValidation,
  validationRules
} from './functions/validation.functions'
import { _handleErrorMessage } from './functions/error.functions'

function App() {
  const languageContext = useContext(LanguageContext)

  const [session] = useState(true)
  const { theme } = useContext(LanguageContext)
  const { control, handleSubmit, setValue, setError } = useForm()

  useEffect(() => {
    let data = localStorage.getItem('beta')
    //setSession(s ? true : false);

    setValue('account', data, { shouldDirty: true })
  }, [])

  async function onSubmit(data) {
    try {
      let response = await fetch(process.env.REACT_APP_API_URL + 'auth/beta', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      })

      await serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        //success
        (data) => {
          //setSession(true);
          localStorage.setItem('beta', data.nickname)
          sessionStorage.setItem('beta', true)
        },
        //error
        async (error) => {
          alert(_handleErrorMessage(languageContext.userLanguage, error.error))
        }
      )
    } catch (error) {
      alert(_handleErrorMessage(languageContext.userLanguage, error))
    }
  }

  return session ? (
    <div className='l-background' data-theme={theme}>
      <Router />
    </div>
  ) : (
    <>
      <div className='diinco-security-filter-container' data-theme={theme}>
        <img src={logo} />
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <CTextInput
            label='Usuario'
            name='account'
            validate={{
              noSpaces: (v) =>
                validationRules.noSpaces(v, languageContext.userLanguage)
            }}
            maxLength={100}
            required
            control={control}
            theme='dark'
          />
          <CTextInput
            type='password'
            label='Contraseña'
            name='password'
            control={control}
            required
            maxLength={30}
            minLength={8}
            theme='dark'
          />
          <button type='submit' className='diinco'>
            {languageContext.dictionary.login}
          </button>
        </form>
        <div className='disculpa-molestias'>
          Disculpa las molestias, por el momento la página se encuentra en
          mantenimiento.
        </div>
      </div>
    </>
  )
}

export default App
