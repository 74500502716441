import React, { useContext } from 'react'
import './styles/style.css'
import _Utils from '../../context/utils'
import { useHistory } from 'react-router'

import { LanguageContext } from '../../context/language/context'
import Button from '../../components/Button'
import Typography from '../../components/Typography'

export default function Signup() {
  const languageContext = useContext(LanguageContext)
  const { theme } = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const { logo } = useContext(_Utils.Context)

  const history = useHistory()

  return (
    <div className='pre_register_container'>
      <div className='card pre-register-card'>
        <Typography
          variant='body'
          color='primary'
          className='uk-flex uk-flex-middle'
          style={{ margin: '0px' }}
        >
          {lang.welcome}
        </Typography>
        <img
          src={theme === 'dark' ? logo.colour : logo.black}
          className='register_diinco'
          alt=''
        />
        <Typography
          variant='subtitle'
          color='primary'
          className='uk-flex uk-flex-middle uk-margin-top'
          style={{ margin: '0px' }}
        >
          {lang.how}
        </Typography>

        <div className='uk-child-width-1-2 uk-grid-match' data-uk-grid>
          <div>
            <div
              className='uk-card uk-card-default uk-flex uk-flex-wrap uk-flex-center uk-card-body'
              style={{ backgroundColor: 'transparent', marginLeft: 20 }}
            >
              <Button
                size='medium'
                type='button'
                variant='outlined'
                className='pre-button'
                containerStyle={{ marginBottom: '0px' }}
                containerClass={'button-hover'}
                onClick={() => history.push('/signup?type=client')}
              >
                {lang.client}
              </Button>
              <div className='uk-width-1 uk-flex uk-flex-center texto-hover'>
                <Typography
                  variant='body2'
                  color='primary'
                  align='center'
                  className='uk-flex uk-flex-center uk-margin-top'
                  style={{
                    maxWidth: '200px',
                    textAlign: 'center',
                    fontSize: '13px'
                  }}
                >
                  {lang.clientDesc}
                </Typography>
              </div>
            </div>
          </div>
          <div>
            <div
              className='uk-card uk-card-default uk-flex uk-flex-wrap uk-flex-center uk-card-body'
              style={{ backgroundColor: 'transparent', marginRight: 20 }}
            >
              <Button
                size='medium'
                type='button'
                variant='outlined'
                className='pre-button'
                containerStyle={{ marginBottom: '0px' }}
                containerClass={'button-hover'}
                onClick={() => history.push('/signup?type=professional')}
              >
                {lang.professional}
              </Button>
              <div className='uk-width-1 uk-flex uk-flex-center texto-hover'>
                <Typography
                  variant='body2'
                  color='primary'
                  align='center'
                  className='uk-flex uk-flex-center uk-margin-top'
                  style={{
                    maxWidth: '200px',
                    textAlign: 'center',
                    fontSize: '13px'
                  }}
                >
                  {lang.professionalDesc}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
