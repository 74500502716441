import React, { useContext, useEffect, useState, useRef } from 'react'
import { serverValidation } from '../../../functions/validation.functions'

//context
import { LanguageContext } from '../../../context/language/context'
import _Auth from '../../../context/auth'
import _Utils from '../../../context/utils'
import { Link, useHistory, useParams } from 'react-router-dom'
import { _handleErrorMessage } from '../../../functions/error.functions'
import NoData from '../../../components/NoData'
import Card from '../../../components/Card'
import { IconButton } from '../../../components/IconButton'
import Avatar from '../../../components/Avatar'
import Typography from '../../../components/Typography'
import { format } from 'date-fns'
import { Modal } from 'react-responsive-modal'
import FileIcon from '../../../components/PaymentCard/assets/file.svg'
import CustomGallery from '../../../components/CustomGallery'
import '../ProjectReports/styles/style.css'
import Button from '../../../components/Button'

const ProjectPayments = () => {
  const { id } = useParams()
  const history = useHistory()
  const languageContext = useContext(LanguageContext)
  const { token, hasPermission } = useContext(_Auth.Context)
  const { HomeLoader, enqueueNotification } = useContext(_Utils.Context)
  const [status, setStatus] = useState(null)
  const Header = (
    <div className='uk-width-1-1 uk-flex uk-flex-between'>
      <div></div>
      {/* {status !== 'finished' && hasPermission("payment-create", null, id, "project") && (
                <Link to={"/Projects/" + id + "/Payments/Create"}>
                    <IconButton
                        icon="create"
                        color="secondary"
                        variant="filled"
                    />
                </Link>
            )} */}
    </div>
  )

  const Loader = (
    <div className='uk-width-1-1 uk-height-1-1'>
      <div
        className='uk-margin-auto uk-margin-medium-top uk-width-auto'
        style={{ width: 'fit-content' }}
      >
        <HomeLoader />
      </div>
    </div>
  )

  const [loading, setLoading] = useState(true)
  const [payments, setPayments] = useState(null)

  async function Init() {
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'projects/' + id + '/payments',
        {
          method: 'GET',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      serverValidation(
        response,
        null,
        languageContext.userLanguage,
        (data) => {
          setLoading(false)
          setPayments(data.payments)
          setStatus(data.status)
        },
        (error) => {
          setLoading(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
          history.push('/Dashboard')
        }
      )
    } catch (error) {
      setLoading(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
      history.push('/Dashboard')
    }
  }

  useEffect(() => {
    if (!token) return
    Init()
  }, [])

  function formatter(number) {
    return parseFloat(number)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  const myRef = useRef(null)
  const [activePayment, setActivePayment] = useState(null)
  const [openPayment, setOpenPayment] = useState(false)
  var langPayment //provisional
  if (languageContext.userLanguage === 'en') {
    langPayment = require('../../../components/PaymentCard/languages/en.json')
  } else {
    langPayment = require('../../../components/PaymentCard/languages/es.json')
  }

  function onOpenPayment(index) {
    setActivePayment(payments[index])
    setOpenPayment(true)
  }

  function onClosePayment() {
    setOpenPayment(false)
    setActivePayment(null)
  }

  const [current, setCurrent] = useState('')
  const [openGallery, setOpenGallery] = useState(false)

  const onOpenGallery = (index) => {
    setCurrent(index)
    setOpenGallery(true)
  }
  const onCloseGallery = () => setOpenGallery(false)
  const [imagesGall, setImagesGall] = useState(null)
  const [disabled, setDisabled] = useState(false)

  async function onSubmitUpdatePayment(status) {
    try {
      setDisabled(true)
      let response = await fetch(
        process.env.REACT_APP_API_URL +
          'projects/' +
          id +
          '/payments/' +
          activePayment.id,
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            status: status,
            paymentId: activePayment.id
          })
        }
      )
      serverValidation(
        response,
        null,
        languageContext.userLanguage,
        () => {
          setDisabled(false)
          onClosePayment()
          Init()
        },
        (error) => {
          setLoading(false)
          setDisabled(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      setLoading(false)
      setDisabled(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  useEffect(() => {
    if (activePayment && activePayment.Images) {
      let prov = []
      activePayment.Images.forEach((image) => {
        prov.push({
          id: image.id,
          url: process.env.REACT_APP_API_URL + image.url
        })
      })
      setImagesGall(prov)
    }
  }, [activePayment])

  return (
    <div data-uk-grid className='uk-grid-small'>
      {Header}
      {loading ? (
        <>{Loader}</>
      ) : (
        <div className='uk-width-1-1' ref={myRef}>
          {payments && payments.length ? (
            <div
              style={{
                height: 381,
                overflow: 'auto',
                paddingRight: 15
              }}
            >
              {payments.map((payment, index) => (
                <div
                  key={'payment-' + index}
                  className='uk-width-1-1 cj-hover-reports'
                >
                  <Card
                    color={
                      languageContext.theme === 'dark' ? 'dark' : 'lightgrey'
                    }
                    hover
                    hoverColor={
                      languageContext.theme === 'dark' ? 'dark' : 'lightgrey'
                    }
                    hoverVariant='filled'
                    className='uk-width-1-1 uk-margin-small-bottom'
                    onClick={() => onOpenPayment(index)}
                    style={{ cursor: 'pointer' }}
                  >
                    <div data-uk-grid>
                      <div className='uk-width-auto'>
                        <Avatar
                          src={payment.User?.image}
                          alt={payment.User?.name}
                          width='60px'
                          height='60px'
                        />
                      </div>
                      <div className='uk-width-expand'>
                        <div
                          data-uk-grid
                          className='uk-child-width-1-1 uk-grid-collapse'
                        >
                          <div>
                            <Typography variant={'h6'} disableMargin>
                              {payment.concept}
                            </Typography>
                          </div>
                          <div>
                            <Typography variant={'body2'} disableMargin>
                              {payment.User?.name}
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              disableMargin
                              className='uk-margin-small-top'
                            >
                              {format(
                                new Date(payment.createdAt),
                                'MM/dd/yyyy'
                              )}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <div className='uk-width-expand'>
                        <div
                          data-uk-grid
                          className='uk-child-width-1-1 uk-grid-collapse'
                        >
                          <div>
                            <Typography
                              align={'right'}
                              disableMargin
                              color={'secondary'}
                            >
                              {payment.Stage?.name}
                            </Typography>
                          </div>
                          <div>
                            <Typography align={'right'} disableMargin>
                              ${' '}
                              {parseFloat(payment.amount)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </Typography>
                          </div>
                          <div className='uk-margin-small-top'>
                            <Typography
                              align={'right'}
                              variant={'body2'}
                              disableMargin
                            >
                              {payment.status === 0
                                ? languageContext.dictionary.status0
                                : payment.status === 1
                                ? languageContext.dictionary.status1
                                : languageContext.dictionary.status2}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              ))}
              {/* PAYMENT MODAL */}
              <Modal
                open={openPayment}
                onClose={onClosePayment}
                center
                closeIcon={<IconButton icon='close' component='div' />}
                classNames={
                  languageContext.theme === 'dark'
                    ? { modal: 'modalPayment-dark' }
                    : { modal: 'modalPayment' }
                }
                container={myRef.current}
              >
                <Typography variant='h6' color='secondary'>
                  {langPayment.infoPayment}
                </Typography>

                {activePayment ? (
                  <div data-uk-grid className='uk-width1-1 uk-child-width-1-1'>
                    {/* sender */}
                    <div style={{ marginTop: 5 }}>
                      <Typography>
                        {langPayment.sender}: {activePayment.User.name}
                      </Typography>
                    </div>
                    {/* receiver */}
                    {activePayment.UserConfirm && (
                      <div style={{ marginTop: 5 }}>
                        <Typography>
                          {langPayment.receiver}:{' '}
                          {activePayment.UserConfirm.name}
                        </Typography>
                      </div>
                    )}
                    {/* date */}
                    <div style={{ marginTop: 15 }}>
                      <Typography>
                        {langPayment.datePayment}:{' '}
                        {format(
                          new Date(
                            activePayment.createdAt?.split('T')[0] + ' 00:00'
                          ),
                          'dd/MM/yyyy'
                        )}
                      </Typography>
                    </div>
                    {/* concept */}
                    <div style={{ marginTop: 15 }}>
                      <Typography>
                        {langPayment.concept}: {activePayment.concept}
                      </Typography>
                    </div>
                    {/* payment form */}
                    <div style={{ marginTop: 15 }}>
                      <Typography>
                        {langPayment.formPayment}: {activePayment.type}
                      </Typography>
                    </div>
                    {/* amount */}
                    <div style={{ marginTop: 15 }}>
                      <Typography>
                        {langPayment.amount}: ${' '}
                        {formatter(activePayment.amount)}
                      </Typography>
                    </div>
                    {/* stage */}
                    {activePayment.Stage && (
                      <div style={{ marginTop: 30 }}>
                        <Typography>
                          {langPayment.stage}: {activePayment.Stage.name}
                        </Typography>
                      </div>
                    )}
                    <div
                      className='uk-flex uk-flex-middle'
                      style={{ marginTop: 15 }}
                    >
                      <div className='uk-margin-right'>
                        <Typography>{langPayment.status}:</Typography>
                      </div>
                      <Button size='small' color='dark'>
                        {activePayment.status === 0
                          ? langPayment.status0
                          : activePayment.status === 1
                          ? langPayment.status1
                          : langPayment.status2}
                      </Button>
                    </div>
                    {activePayment.status === 1 && (
                      <div className='uk-margin-top'>
                        <Typography>
                          {langPayment.confirmed}: {activePayment.User.name}
                        </Typography>
                        {activePayment.date_confirmation && (
                          <Typography>
                            {langPayment.dateC}:{' '}
                            {format(
                              new Date(
                                activePayment.date_confirmation.split('T')[0] +
                                  ' 00:00'
                              ),
                              'dd/MM/yyyy'
                            )}
                          </Typography>
                        )}
                      </div>
                    )}
                    {activePayment.status === 2 && (
                      <div className='uk-margin-top'>
                        <Typography>
                          {langPayment.rejected}:{' '}
                          {activePayment.UserConfirm.name}
                        </Typography>
                        <Typography>
                          {langPayment.dateR}:{' '}
                          {format(
                            new Date(
                              activePayment.date_confirmation.split('T')[0] +
                                ' 00:00'
                            ),
                            'dd/MM/yyyy'
                          )}
                        </Typography>
                      </div>
                    )}
                    {activePayment.Documents &&
                      activePayment.Documents.length > 0 && (
                        <div className='uk-flex uk-flex-top uk-margin-top'>
                          <img
                            src={FileIcon}
                            className='uk-margin-small-right'
                            alt='file'
                            style={{
                              marginTop: 15
                            }}
                          />
                          <div
                            style={{
                              paddingLeft: 15
                            }}
                          >
                            {activePayment.Documents.map((document, index) => (
                              <a
                                key={'payment-doc-' + index}
                                href={
                                  process.env.REACT_APP_API_URL + document.url
                                }
                                target={'_blank'}
                                rel='noreferrer'
                                className='uk-flex uk-flex-middle uk-margin-small-top'
                                style={{
                                  textDecoration: 'underline'
                                }}
                              >
                                <Typography disableMargin>
                                  {document.title}
                                </Typography>
                              </a>
                            ))}
                          </div>
                        </div>
                      )}

                    {activePayment.Images &&
                      activePayment.Images.length > 0 && (
                        <div>
                          {activePayment.Images.map((image, index) => (
                            <img
                              key={'payment-image-' + index}
                              src={process.env.REACT_APP_API_URL + image.url}
                              alt='payment'
                              style={{
                                height: 90,
                                width: 90,
                                marginRight: 15,
                                objectFit: 'cover',
                                cursor: 'pointer'
                              }}
                              onClick={() => onOpenGallery(index + 1)}
                            />
                          ))}
                        </div>
                      )}
                    {hasPermission('payment-update', 'or', id, 'project') &&
                      activePayment.status === 0 && (
                        <div>
                          <Typography color='secondary'>
                            {langPayment.important}
                          </Typography>

                          {/* make a grid with a space between flex */}
                          <div className='uk-flex uk-flex-between uk-margin-top'>
                            <Button
                              color='grey'
                              onClick={async () =>
                                await onSubmitUpdatePayment(2)
                              }
                              disabled={disabled}
                            >
                              {langPayment.reject}
                            </Button>
                            <Button
                              color='secondary'
                              onClick={async () =>
                                await onSubmitUpdatePayment(1)
                              }
                              disabled={disabled}
                            >
                              {langPayment.accept}
                            </Button>
                          </div>
                        </div>
                      )}
                  </div>
                ) : (
                  <div>{langPayment.noData}</div>
                )}
              </Modal>

              <Modal
                open={openGallery}
                onClose={onCloseGallery}
                center
                closeIcon={<IconButton icon='close' component='div' />}
                container={myRef.current}
                classNames={{
                  modal: 'customModalGalleryProject'
                }}
              >
                {activePayment &&
                  activePayment.Images &&
                  activePayment.Images.length > 0 && (
                    <CustomGallery
                      images={imagesGall}
                      navigation={true}
                      current={current}
                    />
                  )}
              </Modal>
            </div>
          ) : (
            <NoData />
          )}
          <div className='uk-width-1-1 uk-flex uk-flex-between'>
            <div></div>
            {status !== 'finished' &&
              hasPermission('payment-create', null, id, 'project') && (
                <Link
                  to={'/Projects/' + id + '/Payments/Create'}
                  style={{ marginRight: 40 }}
                >
                  <IconButton
                    icon='create'
                    color='secondary'
                    variant='filled'
                  />
                </Link>
              )}
          </div>
        </div>
      )}
    </div>
  )
}

export default ProjectPayments
