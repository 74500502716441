import React, { useState, useContext } from 'react'
import './styles/style.css'
import PropTypes from 'prop-types'

import { LanguageContext } from '../../context/language/context'
import { message } from '../../functions/validation.functions'
import { Controller } from 'react-hook-form'
import SelectInput from '../SelectInput'
import debounce from 'lodash/debounce'

const getOptions = (inputValue, callback) => {
  if (!inputValue) {
    return callback([])
  }

  try {
    fetch(process.env.REACT_APP_API_URL + 'tags/getall/' + inputValue, {
      method: 'GET'
    }).then((response) => {
      response.json().then((data) => {
        try {
          var tags = []
          data.forEach((tag) => {
            tags.push({ value: tag.name, label: tag.name })
          })
          callback(filterOptions(tags, inputValue))
        } catch (error) {
          return callback([])
        }
      })
    })
  } catch (error) {
    return callback([])
  }
}

const filterOptions = (array, inputValue) => {
  return array.filter((i) =>
    i.label.toLowerCase().includes(inputValue.toLowerCase())
  )
}

const loadOptions = debounce(getOptions, 500)

const TagsInput = (props) => {
  const {
    name,
    value: valueFromProps,
    onChange: onChangeFromProps,
    defaultValue,

    classes,

    //tag length
    maxLength,
    minLength,

    //tags array elemets
    max,

    error,

    ...rest
  } = props
  // A component can be considered controlled when its value prop is
  // not undefined.
  const isControlled = typeof valueFromProps !== 'undefined'
  // When a component is not controlled, it can have a defaultValue.
  const hasDefaultValue = typeof defaultValue !== 'undefined'
  // If a defaultValue is specified, we will use it as our initial
  // state.  Otherwise, we will simply use an empty string.
  const [internalValue, setInternalValue] = useState(
    hasDefaultValue ? defaultValue : []
  )
  // Internally, we need to deal with some value. Depending on whether
  // the component is controlled or not, that value comes from its
  // props or from its internal state.
  const value = isControlled ? valueFromProps : internalValue
  const tagsCount = value ? value.length : 0

  const [inputValue, setInputValue] = useState('')
  const [errorInput, setErrorInput] = useState(null)
  const { container } = classes

  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const inputValueFormatter = (value) => {
    //trim
    value = value.trim()

    //delete spaces
    let res = []
    var words = value.split(/[_\s]/)
    for (var i = 0; i < words.length; i++) {
      var word_clean = words[i].split('.').join('')
      res.push(word_clean)
    }
    value = res.join('_')

    return value
  }

  const addTag = (tagProp) => {
    const tag = inputValueFormatter(tagProp)

    if (!/^[a-zA-Z\d_]*$/.test(tag)) {
      setErrorInput({
        message: message(languageContext.userLanguage, 'alphaNumericUnderscore')
      })
      return
    }

    if (tag.length <= 0) {
      setErrorInput({
        message: message(languageContext.userLanguage, 'invalidFormat')
      })
      return
    }

    if (tag.length > maxLength) {
      setErrorInput({
        message: message(languageContext.userLanguage, 'maxLength', {
          maxLength
        })
      })
      return
    }
    if (tag.length < minLength) {
      setErrorInput({
        message: message(languageContext.userLanguage, 'minLength', {
          minLength
        })
      })
      return
    }

    const newTags = [...value]

    if (newTags.length >= max) {
      setErrorInput({
        message: message(languageContext.userLanguage, 'arrayMax', {
          max
        })
      })
    }
    if (newTags.indexOf(tag) === -1) newTags.push(tag)
    else {
      setErrorInput({
        message: message(languageContext.userLanguage, 'arrayExists')
      })
    }
    //-------------------------------------------------------

    // When the user types, we will call props.onChange if it exists.
    // We do this even if there is no props.value (and the component
    // is uncontrolled.)
    if (onChangeFromProps) {
      onChangeFromProps(newTags)
    }
    // If the component is uncontrolled, we need to update our
    // internal value here.
    if (!isControlled) {
      setInternalValue(newTags)
    }
  }

  function handleTagsChange(tags) {
    const newTags = tags.length
      ? tags.map((tagProp) => {
          const tag = inputValueFormatter(tagProp.value)
          if (!/^[a-zA-Z\d_]*$/.test(tag)) {
            setErrorInput({
              message: message(
                languageContext.userLanguage,
                'alphaNumericUnderscore'
              )
            })
            return null
          }

          if (tag.length <= 0) {
            setErrorInput({
              message: message(languageContext.userLanguage, 'invalidFormat')
            })
            return null
          }

          if (tag.length > maxLength) {
            setErrorInput({
              message: message(languageContext.userLanguage, 'maxLength', {
                maxLength
              })
            })
            return null
          }
          if (tag.length < minLength) {
            setErrorInput({
              message: message(languageContext.userLanguage, 'minLength', {
                minLength
              })
            })
            return null
          }
          return tag
        })
      : []

    //-------------------------------------------------------

    // When the user types, we will call props.onChange if it exists.
    // We do this even if there is no props.value (and the component
    // is uncontrolled.)
    if (onChangeFromProps) {
      onChangeFromProps(newTags)
    }
    // If the component is uncontrolled, we need to update our
    // internal value here.
    if (!isControlled) {
      setInternalValue(newTags)
    }
  }

  return (
    <div className={container ?? ''}>
      {!isControlled && (
        <input type='hidden' value={JSON.stringify(value)} name={name} />
      )}
      <SelectInput
        label={lang['placeholder']}
        isCreatable
        isAsync
        isMulti
        loadOptions={loadOptions}
        value={
          value.length ? value.map((tag) => ({ value: tag, label: tag })) : null
        }
        onChange={(options) => {
          if (errorInput) setErrorInput(null)
          handleTagsChange(options)

          loadOptions.cancel()
        }}
        onKeyDown={(e) => {
          if (e.key === ' ') {
            e.preventDefault()

            if (errorInput) setErrorInput(null)
            if (inputValue) addTag(inputValue)

            setInputValue('')
          }
        }}
        inputValue={inputValue}
        onInputChange={(v) => {
          if (errorInput) setErrorInput(null)
          setInputValue(v)
          loadOptions.cancel()
        }}
        formatCreateLabel={(iValue) => {
          return (
            <div>
              {lang.create} &quot;{inputValueFormatter(iValue)}&quot;
            </div>
          )
        }}
        error={errorInput ?? error}
        helperTextRight={tagsCount + '/' + max}
        blurInputOnSelect={false}
        noOptionsMessage={() => null}
        {...rest}
      />
    </div>
  )
}

TagsInput.defaultProps = {
  value: undefined,
  onChange: undefined,
  defaultValue: undefined,
  classes: {},

  maxLength: 45,
  minLength: 1,
  max: 10,
  min: null
}

TagsInput.propTypes = {
  value: PropTypes.array,
  defaultValue: PropTypes.array,
  onChange: PropTypes.func,
  classes: PropTypes.object,

  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,

  name: PropTypes.string,
  error: PropTypes.string
}

export const CTagsInput = ({
  name,
  control,
  defaultValue,
  shouldUnregister,

  required,
  //tag length
  maxLength,
  minLength,
  //tags array elemets
  max,
  min,

  validate,
  setValueAs,
  disabled,
  deps,

  ...rest
}) => {
  const languageContext = useContext(LanguageContext)

  var registerOptions = {
    required: {
      value: required,
      message: message(languageContext.userLanguage, 'required')
    },
    validate: validate,

    setValueAs: setValueAs,
    disabled: disabled,
    deps: deps
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={registerOptions}
      shouldUnregister={shouldUnregister}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error }
      }) => {
        return (
          <TagsInput
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={error}
            required={required}
            maxLength={maxLength}
            minLength={minLength}
            max={max}
            min={min}
            {...rest}
          />
        )
      }}
    />
  )
}

CTagsInput.defaultProps = {
  defaultValue: undefined,
  shouldUnregister: false,

  maxLength: 45,
  minLength: null,
  max: 10,
  min: null,
  required: false
}

CTagsInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  shouldUnregister: PropTypes.bool,

  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,

  validate: PropTypes.object,
  setValueAs: PropTypes.any,
  disabled: PropTypes.bool,
  deps: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

  required: PropTypes.bool,

  classes: PropTypes.object,
  onChange: PropTypes.func
}

export default TagsInput
