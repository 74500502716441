import React, { useContext, useState, useEffect } from 'react'
import { LanguageContext } from '../../context/language/context'
import { useParams } from 'react-router-dom'
import _Utils from '../../context/utils'
import _Auth from '../../context/auth'
import { format } from 'date-fns'

import DocumentIcon from './assets/note_white.svg'

import CustomGallery from '../../components/CustomGallery'
import StandardView from '../../layouts/StandardView'

import './styles/style.css'

export default function Report() {
  const { token } = useContext(_Auth.Context)
  const { projectId, id } = useParams()
  const languageContext = useContext(LanguageContext)
  const { toggleLoader, enqueueNotification } = useContext(_Utils.Context)
  const [report, setReport] = useState(null)
  const [images, setImages] = useState([])

  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }
  useEffect(() => {
    if (token) {
      loadData()
    }
  }, [token])

  async function loadData() {
    await toggleLoader(true)

    try {
      fetch(
        process.env.REACT_APP_API_URL +
          'projects/' +
          projectId +
          '/reports/' +
          id,
        {
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then((response) => {
          toggleLoader(false)
          return response.json()
        })
        .then((reporte) => {
          setReport(reporte)
        })
        .catch(() => {
          toggleLoader(false)
          enqueueNotification(
            languageContext.dictionary['somethingWrong'],
            'error'
          )
        })
    } catch (error) {
      enqueueNotification(languageContext.dictionary['somethingWrong'], 'error')
    }
    toggleLoader(false)
  }

  useEffect(() => {
    if (report) {
      loadImages()
    }
  }, [report])

  async function loadImages() {
    var items = []
    if (report && report.Images) {
      report.Images.forEach((imagen) => {
        let item = {
          url: process.env.REACT_APP_API_URL + imagen.url
        }
        items.push(item)
      })
    }
    setImages(items)
  }

  return (
    <StandardView
      title={lang.reports}
      links={[
        {
          link: '/Dashboard/Projects',
          title: languageContext.dictionary.dashboard
        },
        {
          link: '/Projects/' + projectId + '/Reports',
          title: languageContext.dictionary.projectDashboard
        }
      ]}
    >
      {report && (
        <div className='uk-container  oski-margin-bottom'>
          {report.title && (
            <div className='oski-report-title'>{report.title}</div>
          )}
          {report.date && (
            <div className='oski-report-date'>
              {format(new Date(report.date), 'dd/MM/yyyy')}
            </div>
          )}
          {report.Stage && (
            <div className='oski-report-stage'>
              {lang.stage}: {report.Stage.name}
            </div>
          )}
          {report.description && (
            <div className='oski-report-description'>{report.description}</div>
          )}
          <div className='uk-container uk-flex'>
            <div id='galleryDiv' className='uk-width-2-3'>
              {images && images.length > 0 && <CustomGallery images={images} />}
            </div>
            {report.Documents && report.Documents.length > 0 && (
              <div className='uk-width-1-3@m'>
                <div className='oski-text-orange uk-width-1 oski-padding-left'>
                  {lang.files}
                </div>
                {report.Documents.map((document, index) => (
                  // eslint-disable-next-line react/jsx-no-target-blank
                  <a
                    href={process.env.REACT_APP_API_URL + document.url}
                    target='_blank'
                    className='uk-width-1 uk-flex oski-padding-left oski-doc-style'
                    key={index}
                  >
                    <img src={DocumentIcon} alt='Document' />
                    <div className='oski-doc-style'>{document.title}</div>
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </StandardView>
  )
}
