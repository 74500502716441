import React, { useContext, useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import './styles/style.css'

import _Utils from '../../context/utils'
import _Services from '../../context/services'
import _Auth from '../../context/auth'

import { Modal } from 'react-responsive-modal'

import { CTextInput } from '../../components/TextInput'
import { CSelectInput } from '../../components/SelectInput'
import { CSwitchInput } from '../../components/SwitchInput'
import { CTagsInput } from '../../components/TagsInput'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { LanguageContext } from '../../context/language/context'
import { _handleErrorMessage } from '../../functions/error.functions'
import { Prompt } from 'react-router-dom'
import {
  serverValidation,
  validationRules
} from '../../functions/validation.functions'
import { serialize } from '../../functions/validation.functions'
import StandardView from '../../layouts/StandardView'

import { format } from 'date-fns'
import Button from '../../components/Button'
import { compressFilesInFormData } from '../../functions/files.functions'
import CNumberFormat from '../../components/CNumberFormat'
import { IconButton } from '../../components/IconButton'
import ProjectPlansInfoModal from '../../components/ProjectPlansInfoModal'

export default function ProjectForm() {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const location = useLocation()
  let previus = null
  if (location && location.state && location.state.prospect) {
    previus = location.state.prospect
  }

  const { toggleLoader, enqueueNotification, notify } = useContext(
    _Utils.Context
  )
  const { reloadData } = useContext(_Auth.Context)
  const history = useHistory()

  const [open, setOpen] = useState(false)

  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  const myRef = useRef(null)

  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])

  const [openPlansInfoModal, setOpenPlansInfoModal] = useState(false)
  function onOpenPlansInfoModal() {
    setOpenPlansInfoModal(true)
  }
  function onClosePlansInfoModal() {
    setOpenPlansInfoModal(false)
  }

  const [disabledLocation, setDisabledLocation] = useState(true)
  const [disabledState, setDisabledState] = useState(true)
  const [disabledCity, setDisabledCity] = useState(true)
  const [disabledButton, setDisabledButton] = useState(false)
  const {
    countries,
    states,
    cities,
    services,
    checkUpdates,
    setSelectedState,
    setSelectedCountry
  } = useContext(_Services.Context)

  useEffect(() => {
    async function Init() {
      await toggleLoader(true)
      await checkUpdates()
      await toggleLoader(false)
    }
    Init()
  }, [])

  //FORM
  const {
    register,
    reset,
    setValue,
    getValues,
    control,
    setError,
    handleSubmit,
    formState
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: previus?.title || '',
      description: previus?.description || '',
      payment_model: null,
      phases: [],
      country: null,
      state: undefined,
      city: undefined,
      street: undefined,
      int_number: undefined,
      ext_number: undefined,
      neightborhood: undefined,
      postal_code: undefined,
      reference: undefined,
      date_start: '',
      date_end: '',
      services: null,
      files: [],
      isPublic: false,
      tags: [],
      users: previus?.users || null,
      prospectId: previus?.id || null,
      initial_inversion: 0
    }
  })

  const frecuencyOptions = [
    {
      value: 'S',
      label: lang.sixmonthly
    },
    {
      value: 'T',
      label: lang.trimonthly
    },
    {
      value: 'B',
      label: lang.bimonthly
    },
    {
      value: 'M',
      label: lang.monthly
    },
    {
      value: 'Q',
      label: lang.biweekly
    },
    {
      value: 'W',
      label: lang.weekly
    }
  ]

  const countriesOptions = countries.map((country) => ({
    value: country,
    label: country.name
  }))

  const statesOptions = states.map((state) => ({
    value: state,
    label: state.name
  }))

  const citiesOptions = cities.map((city) => ({
    value: city,
    label: city.name
  }))

  const servicesOptions = services.map((service) => ({
    value: service.id,
    label: service.title
  }))

  async function onSubmit(data) {
    await toggleLoader(true)
    setDisabledButton(true)
    try {
      let formData = serialize(data, {
        dotsForObjectNotation: true,
        noArrayNotation: true,
        noFilesWithArrayNotation: true
      })
      formData = await compressFilesInFormData(formData, 'files')

      let response = await fetch(process.env.REACT_APP_API_URL + 'projects', {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
          Accept: 'multipart/form-data'
        },
        body: formData
      })

      await serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        async (dataRes) => {
          await toggleLoader(false)
          setDisabledButton(false)
          reset(data)
          notify(
            languageContext.dictionary['success'],
            reloadData(() => {
              if (dataRes.details && dataRes.details.id)
                history.push('/Projects/' + dataRes.details.id)
              else history.push('/Dashboard')
            })
          )
        },
        async (e) => {
          if (e?.error?.code === 8015) {
            onOpenPlansInfoModal()
          }
          await toggleLoader(false)
          setDisabledButton(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, e.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      setDisabledButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  useEffect(() => {
    if (formState.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
    } else {
      window.onbeforeunload = null
    }
  }, [formState.isDirty])

  //PHASES FORM
  const {
    control: controlPhase,
    reset: resetPhase,
    handleSubmit: handleSubmitPhase,
    setValue: setValuePhases
  } = useForm({
    defaultValues: {
      phase_name: '',
      phase_start: '',
      phase_end: '',
      phase_description: '',
      index: ''
    }
  })

  function onSubmitPhase(data) {
    setValue('phases', [
      ...getValues('phases'),
      {
        name: data.phase_name,
        start_date: data.phase_start,
        end_date: data.phase_end,
        description: data.phase_description
      }
    ])
    resetPhase()
    onCloseModal()
  }

  function deletePhase(index) {
    let phases = getValues('phases')
    phases.splice(index, 1)
    setValue('phases', phases)
    forceUpdate()
  }

  const [phaseButton, setPhaseButton] = useState(false)

  function editPhase(index) {
    let phase = getValues('phases')
    phase = phase[index]
    setValuePhases('phase_name', phase.name)
    setValuePhases('phase_start', phase.start_date)
    setValuePhases('phase_end', phase.end_date)
    setValuePhases('phase_description', phase.description)
    setValuePhases('index', index)
    setPhaseButton(true)
    onOpenModal()
  }

  function replacePhase(data) {
    let newPhase = {
      name: data.phase_name,
      start_date: data.phase_start,
      end_date: data.phase_end,
      description: data.phase_description
    }

    let phases = getValues('phases')
    phases[data.index] = newPhase
    resetPhase()
    onCloseModal()
  }

  function resetPhaseValues() {
    resetPhase()
    setPhaseButton(false)
  }

  useEffect(() => {
    register('phases')
    window.scrollTo(0, 0)
  }, [])

  const onError = () => {
    enqueueNotification(
      _handleErrorMessage(languageContext?.userLanguage, { code: 1000 }),
      'error'
    )
  }

  return (
    <StandardView
      title={lang.panel}
      links={[
        {
          link: '/Dashboard',
          title: languageContext.dictionary.dashboard
        }
      ]}
    >
      <div
        className='uk-margin-medium-top uk-width-1-1 uk-flex uk-flex-column'
        ref={myRef}
      >
        <form onSubmit={handleSubmit(onSubmit, onError)} key={1} noValidate>
          <Prompt
            when={formState.isDirty}
            message={JSON.stringify({
              title: languageContext.dictionary.discard,
              message: languageContext.dictionary.areYouSure,
              accept: languageContext.dictionary.accept,
              cancel: languageContext.dictionary.cancel
            })}
          />
          <CTextInput
            label={languageContext.dictionary.title}
            name='title'
            control={control}
            maxLength={45}
            required
          />
          <CTextInput
            label={languageContext.dictionary.desc}
            name='description'
            type='textarea'
            control={control}
            rows={3}
            maxLength={2000}
            charCount
          />

          <div className='uk-grid'>
            <div className='uk-width-2-3'>
              <CSelectInput
                name='payment_model'
                control={control}
                label={lang.paymentFrequency}
                options={frecuencyOptions}
                required
              />
            </div>
            <div className='uk-width-1-3'>
              <CNumberFormat
                name='initial_inversion'
                control={control}
                label={lang.inversion}
                max={9999999999}
                prefix={'$ '}
                thousandSeparator={true}
                limit
                decimalScale={0}
              />
            </div>
          </div>

          <div className='uk-grid'>
            <div className='uk-width-1-3'>
              <CSelectInput
                name='country'
                options={countriesOptions}
                control={control}
                onChange={(selectedOption) => {
                  setDisabledState(!selectedOption)
                  setDisabledCity(true)
                  setSelectedCountry(selectedOption?.value)
                  setValue('state', null)
                  setValue('city', null)
                }}
                label={languageContext.dictionary.country}
              />
            </div>
            <div className='uk-width-1-3'>
              <CSelectInput
                name='state'
                control={control}
                label={languageContext.dictionary.state}
                disabled={disabledState}
                options={statesOptions}
                onChange={(selectedOption) => {
                  setDisabledCity(!selectedOption)
                  setSelectedState(selectedOption?.value)
                  setValue('city', null)
                }}
                required
              />
            </div>
            <div className='uk-width-1-3'>
              <CSelectInput
                name='city'
                control={control}
                label={languageContext.dictionary.city}
                onChange={(e) => {
                  if (e) setDisabledLocation(false)
                  else setDisabledLocation(true)
                }}
                disabled={disabledCity}
                required
                options={citiesOptions}
              />
            </div>
            <div className='uk-width-3-5'>
              <CTextInput
                label={languageContext.dictionary.street}
                name='street'
                control={control}
                disabled={disabledLocation}
                deps={'city'}
                required
                maxLength={100}
              />
            </div>
            <div className='uk-width-1-5'>
              <CTextInput
                label={languageContext.dictionary.externalNum}
                name='ext_number'
                control={control}
                disabled={disabledLocation}
                required
                maxLength={5}
                validate={{
                  alphaDash: (v) =>
                    validationRules.alphaDash(v, languageContext.userLanguage)
                }}
              />
            </div>
            <div className='uk-width-1-5'>
              <CTextInput
                label={languageContext.dictionary.internalNum}
                name='int_number'
                control={control}
                disabled={disabledLocation}
                maxLength={7}
                validate={{
                  alphaDash: (v) =>
                    validationRules.alphaDash(v, languageContext.userLanguage)
                }}
              />
            </div>
            <div className='uk-width-3-5'>
              <CTextInput
                label={languageContext.dictionary.neighborhood}
                name='neightborhood'
                control={control}
                disabled={disabledLocation}
                required
                maxLength={100}
              />
            </div>
            <div className='uk-width-2-5'>
              <CTextInput
                label={languageContext.dictionary.postalCode}
                name='postal_code'
                control={control}
                type='number'
                disabled={disabledLocation}
                required
                maxLength={5}
                minLength={5}
                charCount
              />
            </div>
            <div className='uk-width-1-1'>
              <CTextInput
                label={languageContext.dictionary.reference}
                name='reference'
                control={control}
                type='textarea'
                placeholder={lang.reference}
                disabled={disabledLocation}
                maxLength={200}
                charCount
              />
            </div>
            <div className='uk-width-1-2'>
              <CTextInput
                type='date'
                label={languageContext.dictionary.startDate}
                name='date_start'
                control={control}
                required
              />
            </div>
            <div className='uk-width-1-2'>
              <CTextInput
                type='date'
                label={languageContext.dictionary.endDate}
                name='date_end'
                control={control}
                required
              />
            </div>
          </div>

          <button
            className='uk-button uk-button-default oski-button-phase'
            onClick={() => {
              resetPhaseValues()
              onOpenModal()
              //   onOpenPlansInfoModal()
            }}
            type='button'
          >
            {lang.addPhase} ({languageContext.dictionary.optional})
          </button>

          {getValues('phases') &&
            getValues('phases').map((p, index) => {
              return (
                <div
                  className='uk-margin-middle'
                  style={{ marginBottom: 33 }}
                  key={index + 'phases'}
                >
                  <div
                    key={index}
                    className={
                      index === 0
                        ? 'uk-width-1-2@m uk-column-1-2 uk-flex uk-flex-middle'
                        : 'uk-width-1-2@m uk-column-1-2 uk-margin-top uk-flex uk-flex-middle'
                    }
                  >
                    <div
                      onClick={() => editPhase(index)}
                      className='oski-phase-object'
                    >
                      <div className='oski-phase-title'>
                        {lang.phase} {index + 1}
                      </div>
                      <div className='oski-phase-subtitle'>
                        {p.name}{' '}
                        {format(
                          new Date(p.start_date + ' 00:00'),
                          'dd/MM/yyyy'
                        )}{' '}
                        -{' '}
                        {format(new Date(p.end_date + ' 00:00'), 'dd/MM/yyyy')}
                      </div>
                    </div>
                    <div>
                      <button
                        type='button'
                        onClick={() => deletePhase(index)}
                        data-uk-icon='icon: close'
                        className='oski-button-close'
                      ></button>
                    </div>
                  </div>
                  {formState.errors && formState.errors.phases && (
                    <div className='leo-error-message'>
                      <span data-uk-icon='icon: warning; ratio: 0.6'></span>{' '}
                      {formState.errors.phases.message}
                    </div>
                  )}
                </div>
              )
            })}

          <CSelectInput
            name='services'
            control={control}
            label={languageContext.dictionary.service}
            isMulti
            options={servicesOptions}
            required
          />

          <div className='uk-grid'>
            {/* <div className="uk-width-3-5">
							<CDropZoneCoverImage
								name={"files"}
								control={control}

								ContainerClasses="diinco-project-form-dropzone-container cj-height-dropzone"
								ImageClasses="diinco-project-form-dropzone-image"
								onChange={(e) => forceUpdate()}
							/>

							<ImageThumbsContainer
								thumbs={getValues("files")}
								containerStyle="diinco-thumbs-container-project-form"
								thumbStyle="diinco-thumbs-style-project-form"
								deleteButtonStyle="diinco-thumbs-delete-project-form"
								handleRemove={(event, file, index) => {
									const newValue = getValues("files").filter((o, i) => i !== index)
									setValue("files", newValue)
									forceUpdate()
								}}
							/>
						</div> */}
            <div className='uk-width-5-5'>
              <CSwitchInput
                name='isPublic'
                control={control}
                textActive={languageContext.dictionary['public']}
                textInactive={languageContext.dictionary['private']}
              />
              <CTagsInput
                name='tags'
                control={control}
                classes={{
                  tagsContainer: 'cj-tags-container'
                }}
              />
            </div>
          </div>
          <div className='uk-flex uk-flex-between'>
            <Button
              onClick={() => {
                history.goBack()
              }}
              variant='contained'
              color='grey'
            >
              {languageContext.dictionary.cancel}
            </Button>
            <Button type='submit' color='secondary' disabled={disabledButton}>
              {languageContext.dictionary['save']}
            </Button>
          </div>
        </form>

        <Modal
          open={open}
          onClose={onCloseModal}
          center
          container={myRef.current}
          classNames={{ modal: 'customModalPhase' }}
          closeIcon={<IconButton icon='close' component='div' />}
        >
          <h2 className='oski-title-modal'>
            {!phaseButton ? lang.addPhase : lang.editPhase}
          </h2>
          <form
            onSubmit={
              !phaseButton
                ? handleSubmitPhase(onSubmitPhase)
                : handleSubmitPhase(replacePhase)
            }
            key={2}
            noValidate
          >
            <div className='uk-grid'>
              <div className='uk-width-1'>
                <CTextInput
                  control={controlPhase}
                  name='phase_name'
                  required
                  label={lang.phaseName}
                  maxLength={50}
                />
              </div>
              <div className='uk-width-1-2'>
                <CTextInput
                  type='date'
                  control={controlPhase}
                  name='phase_start'
                  required
                  label={languageContext.dictionary.startDate}
                />
              </div>
              <div className='uk-width-1-2'>
                <CTextInput
                  type='date'
                  control={controlPhase}
                  name='phase_end'
                  required
                  label={languageContext.dictionary.endDate}
                />
              </div>
              <div className='uk-width-1'>
                <CTextInput
                  control={controlPhase}
                  name='phase_description'
                  label={languageContext.dictionary.desc}
                  maxLength={2000}
                  type='textarea'
                  charCount
                />
              </div>
            </div>
            <div className='uk-flex uk-flex-right'>
              {!phaseButton ? (
                <Button type='submit' color='secondary'>
                  {languageContext.dictionary.add}
                </Button>
              ) : (
                <Button type='submit' color='secondary'>
                  {languageContext.dictionary.save}
                </Button>
              )}
            </div>
          </form>
        </Modal>
      </div>
      <ProjectPlansInfoModal
        open={openPlansInfoModal}
        onClose={onClosePlansInfoModal}
      />
    </StandardView>
  )
}
