import React, { useContext } from 'react'
import Typography from '../../components/Typography'
import PropTypes from 'prop-types'
import Divider from '../../components/Divider'
import _Utils from '../../context/utils'
import { Link } from 'react-router-dom'
import { IconButton } from '../../components/IconButton'

const StandardView = (props) => {
  const { title, loading, links } = props
  const { HomeLoader } = useContext(_Utils.Context)
  const Loader = (
    <div className='uk-container uk-position-center'>
      {' '}
      <HomeLoader />{' '}
    </div>
  )

  return (
    <div className='uk-container uk-margin-medium-top uk-margin-medium-bottom'>
      {/* TITLE */}
      <div className='uk-flex'>
        {links &&
          links.length > 0 &&
          links.map((link, index) => (
            <React.Fragment key={'standard-view-link-' + index}>
              <Link to={link.link}>
                <Typography variant='h6' style={{ textTransform: 'uppercase' }}>
                  {link.title}
                </Typography>
              </Link>

              <IconButton
                variant='borderless'
                icon='link'
                iconColor='secondary'
                style={{ marginBottom: '0.35em' }}
              />
            </React.Fragment>
          ))}
        <Typography variant='h6' style={{ textTransform: 'uppercase' }}>
          {title}
        </Typography>
      </div>
      <Divider className='uk-margin-medium-bottom' />
      {loading ? Loader : props.children}
    </div>
  )
}
StandardView.defaultProps = {
  title: 'DIINCO',
  loading: false,
  links: [],
  children: null
}

StandardView.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  links: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}
export default StandardView
