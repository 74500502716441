import React from 'react'
import './styles/style.css'
import { useHistory } from 'react-router-dom'
import format from 'date-fns/format'
import PropTypes from 'prop-types'

export default function ReportCard(props) {
  const { title, name, img, date, even, id } = props
  let light
  if (even === 1) {
    light = 'dc-report-card-light'
  } else {
    light = ''
  }
  const history = useHistory()
  return (
    <div
      onClick={() => {
        history.push('/Report/' + id)
      }}
      className={light}
    >
      <div className='uk-grid uk-grid-collapse uk-height-small dc-report-card'>
        <div className='uk-width-1-4'>
          <img
            alt=''
            className='uk-border-circle uk-align-center uk-margin-top dc-img-cover'
            src={img}
          />
        </div>
        <div className='uk-width-3-4 uk-flex uk-flex-column'>
          <article className='uk-article uk-margin-right uk-margin-top uk-flex-wrap-between'>
            <p className='uk-text-truncate dc-report-card-title dc-text-gray'>
              {title}
            </p>
            <p className='uk-text-truncate dc-report-card-text dc-text-gray'>
              {name}
            </p>
            <p className='uk-text-truncate dc-report-card-text dc-text-gray'>
              {format(new Date(date), 'dd/MM/yyyy HH:mm')}
            </p>
          </article>
        </div>
      </div>
    </div>
  )
}

ReportCard.defaultProps = {
  title: '',
  name: '',
  img: '',
  date: '',
  even: 0,
  id: 0
}

ReportCard.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  img: PropTypes.string,
  date: PropTypes.string.isRequired,
  even: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired
}
