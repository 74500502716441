import React, { useContext, useRef, useState } from 'react'
import './styles/style.css'
import _Utils from '../../context/utils'
import { CTextInput } from '../TextInput'
import _Auth from '../../context/auth'
import { useHistory } from 'react-router'
import Modal from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import ReCAPTCHA from 'react-google-recaptcha'

import { LanguageContext } from '../../context/language/context'
import { useForm } from 'react-hook-form'
import { message, serverValidation } from '../../functions/validation.functions'
import { _handleErrorMessage } from '../../functions/error.functions'
import Button from '../Button'
import { IconButton } from '../IconButton'

export default function LoginCard() {
  const { logo, toggleLoader, enqueueNotification } = useContext(_Utils.Context)
  const { control, handleSubmit, setError } = useForm({
    mode: 'onChange'
  })
  const history = useHistory()
  const { login, codeAuth } = useContext(_Auth.Context)
  const [open, setOpen] = useState(false)
  const [token_data, setTokenData] = useState()
  const captcha = useRef(null)
  const [areHumanMessage, setAreHumanMessage] = useState(null)
  const [disableButton, setDisableButton] = useState(false)
  const [disableButtonCode, setDisableButtonCode] = useState(false)

  const languageContext = useContext(LanguageContext)
  const { theme } = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  let date = new Date()
  date.setMinutes(date.getMinutes() + 5)
  date = date.toISOString()
  let timerStart = 'date: ' + date

  const onCloseModal = () => setOpen(false)

  async function onSubmit(data) {
    try {
      toggleLoader(true)
      setDisableButton(true)

      let { account, password } = data
      const response = await login(account, password)

      await serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        (data) => {
          setDisableButton(false)
          setTokenData(data.token)
          setOpen(true)
        },
        (error) => {
          setDisableButton(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )

      toggleLoader(false)
    } catch (error) {
      setDisableButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  const {
    control: controlCode,
    handleSubmit: handleSubmitCode,
    setError: setErrorCode
  } = useForm()

  async function handleCode(data) {
    try {
      if (!captcha.current.getValue()) {
        setAreHumanMessage(
          message(languageContext.dictionary.areHuman, 'required')
        )
        throw [3001]
      }
      toggleLoader(true)
      setDisableButtonCode(true)

      let { code } = data

      const error = await codeAuth(
        code,
        token_data,
        setErrorCode,
        languageContext?.userLanguage
      )
      if (!error) {
        enqueueNotification(languageContext.dictionary.welcome + '!', 'success')
        history.push('/Dashboard')
      } else {
        if (error === 'Wrong attempts') {
          enqueueNotification(languageContext.dictionary.try + '!', 'error')
          setOpen(false)
        } else
          enqueueNotification(
            languageContext.dictionary.somethingWrong,
            'error'
          )
      }

      toggleLoader(false)
      setDisableButtonCode(false)
    } catch (error) {
      setDisableButtonCode(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  return (
    <div className='logincard-container'>
      <img src={theme === 'dark' ? logo.colour : logo.black} alt='logo' />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <CTextInput
          type='text'
          name='account'
          label={lang.account}
          placeholder={languageContext.dictionary.accountPlaceholder}
          control={control}
          limit
          required
          maxLength={100}
          tabIndex={1}
          theme={theme}
        />
        <CTextInput
          type='password'
          name='password'
          label={lang.pass}
          control={control}
          required
          minLength={8}
          maxLength={120}
          tabIndex={2}
          theme={theme}
        />
        <div className='diinco-centered'>
          <Button
            size='small'
            onClick={() => {
              history.push('/ForgottenPassword')
            }}
            variant='text'
            color='secondary'
            centered
            containerStyle={{ marginBottom: '22px', textTransform: 'none' }}
            tabIndex={3}
          >
            {lang.forgotPass}
          </Button>

          <Button
            type='submit'
            size='auto'
            containerStyle={{ marginBottom: '22px' }}
            tabIndex={4}
            disabled={disableButton}
          >
            {languageContext.dictionary.login}
          </Button>

          <Button
            size='small'
            onClick={() => {
              history.push('/signup')
            }}
            variant='text'
            centered
            tabIndex={5}
            style={{ textTransform: 'none' }}
          >
            {lang.register}
          </Button>
        </div>
      </form>
      <div>
        <Modal
          open={open}
          onClose={onCloseModal}
          center
          classNames={{
            modal: 'dc-code-verification'
          }}
          closeOnOverlayClick={false}
          closeIcon={
            <IconButton
              color={'light'}
              iconColor={'primary'}
              icon='close'
              component='div'
            />
          }
        >
          <div className='uk-flex uk-flex-column uk-flex-middle uk-flex-center'>
            <h2 className='dc-code-verification-title'>{lang.verifCode}</h2>
            <form onSubmit={handleSubmitCode(handleCode)} noValidate>
              <CTextInput
                name='code'
                control={controlCode}
                placeholder={lang.enterCode}
                variant='code'
                limit
                type='number'
                required
                maxLength={6}
                minLength={6}
              />
              <div className='dc-code-verification-placeholder'>
                {lang.codeMail}
              </div>
              <div
                className='uk-flex uk-flex-center uk-margin-small-bottom'
                data-uk-countdown={timerStart}
              >
                <div>
                  <div className='uk-countdown-number uk-countdown-minutes dc-code-verification-placeholder'></div>
                </div>
                <div className='uk-countdown-separator dc-code-verification-placeholder'>
                  :
                </div>
                <div>
                  <div className='uk-countdown-number uk-countdown-seconds dc-code-verification-placeholder'></div>
                </div>
              </div>
              <div className='recaptcha-container uk-flex uk-flex-column uk-flex-left'>
                <ReCAPTCHA
                  size='normal'
                  ref={captcha}
                  sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
                  theme={theme === 'dark' ? 'dark' : 'light'}
                />
                {areHumanMessage && (
                  <div className='leo-error-message' style={{ color: 'white' }}>
                    <span data-uk-icon='icon: warning; ratio: 0.6'></span>{' '}
                    {areHumanMessage}
                  </div>
                )}
              </div>

              <Button
                size='small'
                color='secondary'
                type='submit'
                buttonStyle={{ marginTop: '20px' }}
                containerStyle={{ marginBottom: '0px' }}
                disabled={disableButtonCode}
              >
                {languageContext.dictionary.confirm}
              </Button>
            </form>
          </div>
        </Modal>
      </div>
    </div>
  )
}
