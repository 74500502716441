import React, { useContext } from 'react'

import './styles/style.css'

import { LanguageContext } from '../../context/language/context'

export default function AboutUs() {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  return (
    <section>
      <div className='uk-container uk-margin-large-bottom'>
        <div className='uk-margin-large-top uk-width-1-1 oski-title dc-text-white'>
          {' '}
          {lang.FAQ}{' '}
        </div>
        <div className='uk-width-1-1 uk-margin-small-top dc-separator dc-bg-yellow'></div>

        <div className='uk-margin-large-top oski-subtitle'>
          TARJETAS DEL CRM
        </div>
        <div className='uk-margin-medium-top oski-question dc-text-white'>
          <b>¿Cómo agrego tarjetas al CRM?</b>
        </div>
        <div className='uk-margin-top oski-answer dc-text-white'>
          Agregar o eliminar tarjetas lskdjbkasfgksig Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. Nullam porttitor elementum elit ac
          condimentum. Nulla ac feugiat erat. Sed ornare quis libero eu
          malesuada. Vivamus interdum, mi id dictum mollis, leo quam elementum
          ex, non facilisis mi sapien non massa. Nullam non elementum ex, ut
          tincidunt nunc. Curabitur eu aliquet nunc, sit amet tincidunt ex.
          Donec aliquam magna eget nibh eleifend dignissim. Mauris et suscipit
          arcu. Praesent vel orci a risus dapibus sagittis.
        </div>

        <div className='uk-margin-medium-top oski-question dc-text-white'>
          <b>¿Cómo agrego tarjetas al CRM?</b>
        </div>
        <div className='uk-margin-top oski-answer dc-text-white'>
          Agregar o eliminar tarjetas lskdjbkasfgksig Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. Nullam porttitor elementum elit ac
          condimentum. Nulla ac feugiat erat. Sed ornare quis libero eu
          malesuada. Vivamus interdum, mi id dictum mollis, leo quam elementum
          ex, non facilisis mi sapien non massa. Nullam non elementum ex, ut
          tincidunt nunc. Curabitur eu aliquet nunc, sit amet tincidunt ex.
          Donec aliquam magna eget nibh eleifend dignissim. Mauris et suscipit
          arcu. Praesent vel orci a risus dapibus sagittis.
        </div>

        <div className='uk-margin-medium-top oski-question dc-text-white'>
          <b>¿Cómo agrego tarjetas al CRM?</b>
        </div>
        <div className='uk-margin-top oski-answer dc-text-white'>
          Agregar o eliminar tarjetas lskdjbkasfgksig Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. Nullam porttitor elementum elit ac
          condimentum. Nulla ac feugiat erat. Sed ornare quis libero eu
          malesuada. Vivamus interdum, mi id dictum mollis, leo quam elementum
          ex, non facilisis mi sapien non massa. Nullam non elementum ex, ut
          tincidunt nunc. Curabitur eu aliquet nunc, sit amet tincidunt ex.
          Donec aliquam magna eget nibh eleifend dignissim. Mauris et suscipit
          arcu. Praesent vel orci a risus dapibus sagittis.
        </div>

        <div className='uk-margin-large-top oski-subtitle'>
          INICIO DE SESIÓN
        </div>
        <div className='uk-margin-medium-top oski-question dc-text-white'>
          <b>¿Cómo recupero mi contraseña?</b>
        </div>
        <div className='uk-margin-top oski-answer dc-text-white'>
          Agregar o eliminar tarjetas lskdjbkasfgksig Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. Nullam porttitor elementum elit ac
          condimentum. Nulla ac feugiat erat. Sed ornare quis libero eu
          malesuada. Vivamus interdum, mi id dictum mollis, leo quam elementum
          ex, non facilisis mi sapien non massa. Nullam non elementum ex, ut
          tincidunt nunc. Curabitur eu aliquet nunc, sit amet tincidunt ex.
          Donec aliquam magna eget nibh eleifend dignissim. Mauris et suscipit
          arcu. Praesent vel orci a risus dapibus sagittis.
        </div>

        <div className='uk-margin-medium-top oski-question dc-text-white'>
          <b>¿Cómo cambio mi correo?</b>
        </div>
        <div className='uk-margin-top oski-answer dc-text-white'>
          Agregar o eliminar tarjetas lskdjbkasfgksig Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. Nullam porttitor elementum elit ac
          condimentum. Nulla ac feugiat erat. Sed ornare quis libero eu
          malesuada. Vivamus interdum, mi id dictum mollis, leo quam elementum
          ex, non facilisis mi sapien non massa. Nullam non elementum ex, ut
          tincidunt nunc. Curabitur eu aliquet nunc, sit amet tincidunt ex.
          Donec aliquam magna eget nibh eleifend dignissim. Mauris et suscipit
          arcu. Praesent vel orci a risus dapibus sagittis.
        </div>

        <div className='uk-margin-medium-top oski-question dc-text-white'>
          <b>¿Cómo agrego tarjetas al CRM?</b>
        </div>
        <div className='uk-margin-top oski-answer dc-text-white'>
          Agregar o eliminar tarjetas lskdjbkasfgksig Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. Nullam porttitor elementum elit ac
          condimentum. Nulla ac feugiat erat. Sed ornare quis libero eu
          malesuada. Vivamus interdum, mi id dictum mollis, leo quam elementum
          ex, non facilisis mi sapien non massa. Nullam non elementum ex, ut
          tincidunt nunc. Curabitur eu aliquet nunc, sit amet tincidunt ex.
          Donec aliquam magna eget nibh eleifend dignissim. Mauris et suscipit
          arcu. Praesent vel orci a risus dapibus sagittis.
        </div>
      </div>
    </section>
  )
}
