import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import '../styles/style.css'
import PropTypes from 'prop-types'

const getItemStyle = (isDragging, draggableStyle) => ({
  // change background colour if dragging
  background: isDragging ? 'var(--secondary-color)' : 'var(--text-gray-color)',

  // styles we need to apply on draggables
  ...draggableStyle
})

export default function Task(props) {
  // console.log("TASK PROPS: ",props);

  return (
    <Draggable draggableId={'row' + props.task.id} index={props.index}>
      {/* container */}
      {(provided, snapshot) => (
        <div
          className='task-container'
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          {props.task.content}
        </div>
      )}
    </Draggable>
  )
}

Task.defaultProps = {
  task: {},
  index: 0
}

Task.propTypes = {
  task: PropTypes.object,
  index: PropTypes.number
}

// definir aqui los props
