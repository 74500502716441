import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import './styles/style.css'
import Typography from '../Typography'
import { LanguageContext } from '../../context/language/context'

const Button = React.forwardRef(
  (
    {
      onClick,
      className,
      containerClass,

      buttonStyle,
      containerStyle,

      type,
      disabled,
      size,
      color,
      variant,

      disableBorderRadius,
      centered,
      disableMargin,

      typography,
      children,

      theme: themeFromProps,
      disableTypography,

      ...rest
    },
    ref
  ) => {
    const { theme } = useContext(LanguageContext)

    let themeProp = {}

    if (themeFromProps !== 'default') {
      if (themeFromProps === 'inverted')
        themeProp['data-input-theme'] = theme === 'light' ? 'dark' : 'light'
      else themeProp['data-input-theme'] = themeFromProps
    }

    return (
      <div
        ref={ref}
        className={[
          'cj-button-component-container',
          disableMargin ? 'cj-button-component-container-no-margin' : '',
          centered ? 'uk-flex uk-flex-center' : '',
          containerClass
        ].join(' ')}
        style={containerStyle}
        {...themeProp}
      >
        <button
          className={[
            'cj-button-component',
            disableBorderRadius && 'disabled-border',
            'cj-button-component-' + size.toLowerCase(),
            'cj-button-component-color-' + color.toLowerCase(),
            'cj-button-component-' + variant.toLowerCase(),
            disabled && 'cj-button-component-disabled',
            className
          ].join(' ')}
          onClick={onClick}
          type={type}
          disabled={disabled}
          style={buttonStyle}
          {...rest}
        >
          {!disableTypography ? (
            <Typography
              align='center'
              variant={typography}
              color='inherit'
              disableMargin
            >
              {children}
            </Typography>
          ) : (
            children
          )}
        </button>
      </div>
    )
  }
)

Button.defaultProps = {
  onClick: () => {},
  className: '',
  containerClass: '',

  buttonStyle: {},
  containerStyle: {},

  type: 'button',
  disabled: false,
  size: 'medium',
  color: 'primary',
  variant: 'contained',

  disableBorderRadius: false,
  centered: false,
  disableMargin: false,
  typography: 'body',

  disableTypography: false,
  theme: 'default',

  children: ''
}

Button.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string,
  containerClass: PropTypes.string,
  theme: PropTypes.string,
  disableTypography: PropTypes.bool,
  buttonStyle: PropTypes.object,
  containerStyle: PropTypes.object,

  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'auto']),

  disableBorderRadius: PropTypes.bool,
  centered: PropTypes.bool,

  color: PropTypes.oneOf(['primary', 'secondary', 'dark', 'grey']),

  variant: PropTypes.oneOf(['text', 'contained', 'outlined', 'underlined']),
  disableMargin: PropTypes.bool,

  typography: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle',
    'subtitle2',
    'body',
    'body2',
    'div'
  ])
}

Button.displayName = 'Button'
export default Button
