import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { message } from '../../functions/validation.functions'
import { Controller } from 'react-hook-form'
import { LanguageContext } from '../../context/language/context'
import TextInput from '../TextInput'
import NumberFormat from 'react-number-format'

const CNumberFormat = ({
  name,
  control,
  defaultValue,
  shouldUnregister,

  required,
  maxLength,
  minLength,
  max,
  min,
  pattern,
  validate,
  setValueAs,
  valueAsNumber,
  valueAsDate,
  disabled,
  deps,
  type,

  ...rest
}) => {
  const languageContext = useContext(LanguageContext)

  var registerOptions = {
    required: {
      value: required,
      message: message(languageContext.userLanguage, 'required')
    },
    maxLength: {
      value: maxLength,
      message: minLength
        ? maxLength === minLength
          ? message(languageContext.userLanguage, 'sizeLength', { maxLength })
          : message(languageContext.userLanguage, 'betweenLength', {
              minLength,
              maxLength
            })
        : message(languageContext.userLanguage, 'maxLength', { maxLength })
    },
    minLength: {
      value: minLength,
      message: maxLength
        ? maxLength === minLength
          ? message(languageContext.userLanguage, 'sizeLength', { maxLength })
          : message(languageContext.userLanguage, 'betweenLength', {
              minLength,
              maxLength
            })
        : message(languageContext.userLanguage, 'minLength', { minLength })
    },
    max: {
      value: max,
      message: message(languageContext.userLanguage, 'max', { max })
    },
    min: {
      value: min,
      message: message(languageContext.userLanguage, 'min', { min })
    },
    pattern:
      type === 'email'
        ? {
            value:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: message(languageContext.userLanguage, 'email')
          }
        : {
            value: pattern,
            message: message(languageContext.userLanguage, 'pattern')
          },
    validate: validate,
    valueAsNumber: valueAsNumber,
    valueAsDate: valueAsDate,
    setValueAs: setValueAs,
    disabled: disabled,
    deps: deps
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={registerOptions}
      shouldUnregister={shouldUnregister}
      render={({
        field: { onChange, onBlur, value, name },
        fieldState: { error }
      }) => {
        return (
          <NumberFormat
            onValueChange={(v) => onChange(v.value)}
            value={value}
            onBlur={onBlur}
            customInput={TextInput}
            error={error}
            name={name}
            disabled={disabled}
            required={required}
            maxLength={maxLength}
            minLength={minLength}
            type={type}
            lengthCount={value ? value.length : 0}
            {...rest}
          />
        )
      }}
    />
  )
}

CNumberFormat.defaultProps = {
  defaultValue: undefined,
  shouldUnregister: false,

  valueAsNumber: false,
  valueAsDate: false,

  maxLength: null,
  minLength: null,
  required: false
}

CNumberFormat.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  shouldUnregister: PropTypes.bool,

  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  pattern: PropTypes.any,
  validate: PropTypes.object,
  setValueAs: PropTypes.any,
  valueAsNumber: PropTypes.bool,
  valueAsDate: PropTypes.bool,
  disabled: PropTypes.bool,
  deps: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

  //TextInput
  type: PropTypes.string,
  rows: PropTypes.number,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  charCount: PropTypes.bool,
  classes: PropTypes.object,
  error: PropTypes.object,
  autoCorrect: PropTypes.oneOf(['on', 'off']),
  autoComplete: PropTypes.string,
  disableFocusBorder: PropTypes.bool,
  helperText: PropTypes.any,
  variant: PropTypes.oneOf(['standard', 'outlined', 'code']),
  theme: PropTypes.oneOf(['default', 'inverted', 'light', 'dark']),
  limit: PropTypes.bool
}

export default CNumberFormat
