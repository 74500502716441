import React, { useContext } from 'react'
import Modal from 'react-responsive-modal'
import { IconButton } from '../IconButton'
import Typography from '../Typography'
import './styles/style.css'
import { LanguageContext } from '../../context/language/context'
import PropTypes from 'prop-types'

const ProjectPlansInfoModal = ({ open, onClose }) => {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      closeIcon={<IconButton icon='close' component='div' color='dark' />}
      //   classNames={{ modal: 'customModalCRM-dark' }}
    >
      <Typography
        variant='h4'
        fontWeight={700}
        style={{
          color: 'var(--text-gray-color)'
        }}
      >
        {lang.packages}
      </Typography>

      <Typography>{lang.text}</Typography>

      <table className='uk-table uk-table-divider uk-table-responsive uk-margin-top uk-table-small'>
        <thead>
          <tr>
            <th></th>
            <th>
              <Typography
                style={{
                  color: 'var(--text-gray-color)'
                }}
                fontWeight={600}
                align={'center'}
              >
                {lang.start}
              </Typography>
            </th>
            <th>
              <Typography
                fontWeight={600}
                style={{
                  color: 'var(--text-gray-color)'
                }}
                align={'center'}
              >
                {lang.package}
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='uk-width-1-3'>
              <Typography className={'uk-margin-small-top'}>
                {lang.info}
              </Typography>
            </td>
            <td className='uk-width-1-3'>
              <IconButton
                icon={'check'}
                iconColor='dark'
                variant='borderless'
                className='cj-text-component-margin uk-margin-small-top'
                style={{
                  width: '100%'
                }}
              />
            </td>
            <td className='uk-width-1-3'>
              <IconButton
                icon={'check'}
                iconColor='dark'
                variant='borderless'
                className='cj-text-component-margin uk-margin-small-top'
                style={{
                  width: '100%'
                }}
              />
            </td>
          </tr>

          <tr>
            <td className='uk-width-1-3'>
              <Typography className={'uk-margin-small-top'}>
                {lang.payments}
              </Typography>
            </td>
            <td className='uk-width-1-3'>
              <IconButton
                icon={'check'}
                iconColor='dark'
                variant='borderless'
                className='cj-text-component-margin uk-margin-small-top'
                style={{
                  width: '100%'
                }}
              />
            </td>
            <td className='uk-width-1-3'>
              <IconButton
                icon={'check'}
                iconColor='dark'
                variant='borderless'
                className='cj-text-component-margin uk-margin-small-top'
                style={{
                  width: '100%'
                }}
              />
            </td>
          </tr>

          <tr>
            <td className='uk-width-1-3'>
              <Typography className={'uk-margin-small-top'}>
                {lang.documents}
              </Typography>
            </td>
            <td className='uk-width-1-3'>
              <IconButton
                icon={'check'}
                iconColor='dark'
                variant='borderless'
                className='cj-text-component-margin uk-margin-small-top'
                style={{
                  width: '100%'
                }}
              />
            </td>
            <td className='uk-width-1-3'>
              <IconButton
                icon={'check'}
                iconColor='dark'
                variant='borderless'
                className='cj-text-component-margin uk-margin-small-top'
                style={{
                  width: '100%'
                }}
              />
            </td>
          </tr>

          <tr>
            <td className='uk-width-1-3'>
              <Typography className={'uk-margin-small-top'}>
                {lang.events}
              </Typography>
            </td>
            <td className='uk-width-1-3'></td>
            <td className='uk-width-1-3'>
              <IconButton
                icon={'check'}
                iconColor='dark'
                variant='borderless'
                className='cj-text-component-margin uk-margin-small-top'
                style={{
                  width: '100%'
                }}
              />
            </td>
          </tr>

          <tr>
            <td className='uk-width-1-3'>
              <div className='uk-flex uk-flex-middle uk-margin-small-top'>
                <Typography>{lang.stages}</Typography>
                <IconButton
                  icon={'question'}
                  variant='borderless'
                  iconColor='dark'
                  className='cj-text-component-margin'
                  data-uk-tooltip={
                    'title: ' + lang.stagesTooltip + '; pos: top'
                  }
                />
              </div>
            </td>
            <td className='uk-width-1-3'></td>
            <td className='uk-width-1-3'>
              <IconButton
                icon={'check'}
                iconColor='dark'
                variant='borderless'
                className='cj-text-component-margin uk-margin-small-top'
                style={{
                  width: '100%'
                }}
              />
            </td>
          </tr>

          <tr>
            <td className='uk-width-1-3'>
              <Typography className={'uk-margin-small-top'}>
                {lang.reports}
              </Typography>
            </td>
            <td className='uk-width-1-3'>
              <Typography align={'center'} className={'uk-margin-small-top'}>
                5
              </Typography>
            </td>
            <td className='uk-width-1-3'>
              <Typography align={'center'} className={'uk-margin-small-top'}>
                {lang.unlimited}
              </Typography>
            </td>
          </tr>

          <tr>
            <td className='uk-width-1-3'>
              <Typography className={'uk-margin-small-top'}>
                {lang.users}
              </Typography>
            </td>
            <td className='uk-width-1-3'>
              <Typography align={'center'} className={'uk-margin-small-top'}>
                2
              </Typography>
            </td>
            <td className='uk-width-1-3'>
              <Typography align={'center'} className={'uk-margin-small-top'}>
                5
              </Typography>
            </td>
          </tr>

          <tr>
            <td className='uk-width-1-3'>
              <Typography className={'uk-margin-small-top'}>
                {lang.storage}
              </Typography>
            </td>
            <td className='uk-width-1-3'>
              <Typography align={'center'} className={'uk-margin-small-top'}>
                2GB
              </Typography>
            </td>
            <td className='uk-width-1-3'>
              <Typography align={'center'} className={'uk-margin-small-top'}>
                5GB
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>
    </Modal>
  )
}

ProjectPlansInfoModal.defaultProps = {
  open: false,
  onClose: () => {}
}

ProjectPlansInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ProjectPlansInfoModal
