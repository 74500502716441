import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Thumb from './Components/Thumb'
import { CDropZoneCoverImage } from '../../components/DropZoneCoverImage'

export default function DropZoneImage(props) {
  const { containerStyle, thumbStyle, deleteButtonStyle } = props
  const [thumbs, setThumbs] = useState([])
  const [files, setFiles] = useState([])

  useEffect(() => {
    setThumbs(props.thumbs)
  }, [props.thumbs])

  useEffect(() => {
    if (thumbs) {
      let val = thumbs.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      })

      setFiles(val)
    }
  }, [thumbs])

  return (
    <div className={containerStyle}>
      {props.rootImages.map((r, i) => (
        <div
          className={thumbStyle}
          key={i + 'thumbImage'}
          onClick={() => {
            props.onClick(r)
          }}
        >
          <Thumb
            className={thumbStyle}
            file={process.env.REACT_APP_API_URL + r}
          />
          <span
            className={deleteButtonStyle}
            onClick={(event) => {
              props.handleRemove(event, r, i)
            }}
          >
            x
          </span>
        </div>
      ))}
      {files.map((r, i) => (
        <div
          key={'DZI' + i}
          className={thumbStyle}
          onClick={() => {
            props.onClick(r)
          }}
        >
          <Thumb className={thumbStyle} file={r} />
          <span
            className={deleteButtonStyle}
            onClick={(event) => {
              props.handleRemove(event, r, i)
            }}
          >
            x
          </span>
        </div>
      ))}

      <CDropZoneCoverImage
        name={'files'}
        control={props.control}
        ContainerClasses='diinco-thumbs-container-project-form'
        ImageClasses='diinco-project-form-dropzone-image'
        button={true}
      />
    </div>
  )
}

DropZoneImage.defaultProps = {
  containerStyle: '',
  thumbStyle: '',
  deleteButtonStyle: '',
  handleRemove: () => {},
  thumbs: [],
  rootImages: [],
  onClick: () => {},
  control: {}
}
DropZoneImage.propTypes = {
  containerStyle: PropTypes.string,
  thumbStyle: PropTypes.string,
  deleteButtonStyle: PropTypes.string,
  handleRemove: PropTypes.func,
  thumbs: PropTypes.array,
  rootImages: PropTypes.array,
  onClick: PropTypes.func,
  control: PropTypes.object
}
