import React, { useEffect, useReducer, useContext, useState } from 'react'
import Reducer from './reducer'
import Context from './context'
import _Utils from '../utils'
import PropTypes from 'prop-types'

const Services = (props) => {
  const initialState = {
    countries: [],
    states: [],
    cities: [],
    services: [],
    UUIDS: {
      countries: null,
      states: null,
      cities: null,
      services: null
    }
  }
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedState, setSelectedState] = useState(null)
  const [selectedCity, setSelectedCity] = useState(null)
  const [selectedServices, setSelectedServices] = useState(null)
  const { toggleLoader } = useContext(_Utils.Context)

  const [state, dispatch] = useReducer(Reducer, initialState)
  useEffect(() => {
    async function init() {
      await updateStates(selectedCountry.id)
    }
    if (selectedCountry) init()
    // console.log(selectedCountry);
  }, [selectedCountry])

  useEffect(() => {
    async function init() {
      // console.log(state.states, selectedState);
      await updateCities(selectedState.id)
    }
    if (selectedState) init()
  }, [selectedState])

  async function checkUpdates() {
    // try {
    // 	let data = await fetch("http://localhost:4000/updates", {
    // 		method: "GET",
    // 	});
    // 	let { countries, states, cities, services } = await data.json();
    // 	await checkCountries(countries);
    // 	await checkStates(states);
    // 	await checkCities(cities);
    // 	await checkServices(services);
    // } catch (error) {
    // 	console.error(error);
    // }
    await updateServices()
    await updateCountries()
    // await updateStates();
    // await updateCities();
  }

  // async function checkCountries(UUID) {
  //   let response = localStorage.getItem('COUNTRIES')
  //   if (response === UUID) {
  //     dispatch({ type: 'SET_COUNTRIES_UUID', payload: UUID })
  //     let countries = localStorage.getItem('countries')
  //     countries = JSON.parse(countries)
  //     dispatch({ type: 'SET_COUNTRIES', payload: countries })
  //     return
  //   } else {
  //     const countries = await updateCountries()
  //     if (!countries || countries === [] || countries === '')
  //       throw new Error('EMPTY SET')
  //     localStorage.setItem('COUNTRIES', UUID)
  //     dispatch({ type: 'SET_COUNTRIES_UUID', payload: UUID })
  //   }
  // }

  // async function checkStates(UUID) {
  //   let response = localStorage.getItem('STATES')
  //   if (response === UUID) {
  //     dispatch({ type: 'SET_STATES_UUID', payload: UUID })
  //     let states = localStorage.getItem('states')
  //     states = JSON.parse(states)
  //     dispatch({ type: 'SET_STATES', payload: states })
  //     return
  //   } else {
  //     const states = await updateStates()
  //     if (!states || states === [] || states === '')
  //       throw new Error('EMPTY SET')
  //     localStorage.setItem('STATES', UUID)
  //     dispatch({ type: 'SET_STATES_UUID', payload: UUID })
  //   }
  // }

  // async function checkCities(UUID) {
  //   let response = localStorage.getItem('CITIES')
  //   if (response === UUID) {
  //     dispatch({ type: 'SET_CITIES_UUID', payload: UUID })
  //     let cities = localStorage.getItem('cities')
  //     cities = JSON.parse(cities)
  //     dispatch({ type: 'SET_CITIES', payload: cities })
  //     return
  //   } else {
  //     const cities = await updateCities()
  //     if (!cities || cities === [] || cities === '')
  //       throw new Error('EMPTY SET')
  //     localStorage.setItem('CITIES', UUID)
  //     dispatch({ type: 'SET_CITIES_UUID', payload: UUID })
  //   }
  // }

  // async function checkServices(UUID) {
  //   let response = localStorage.getItem('SERVICES')
  //   if (response === UUID) {
  //     dispatch({ type: 'SET_SERVICES_UUID', payload: UUID })
  //     let services = localStorage.getItem('services')
  //     services = JSON.parse(services)
  //     dispatch({ type: 'SET_SERVICES', payload: services })
  //     return
  //   } else {
  //     const services = await updateServices()
  //     if (!services || services === [] || services === '')
  //       throw new Error('EMPTY SET')
  //     localStorage.setItem('SERVICES', UUID)
  //     dispatch({ type: 'SET_SERVICES_UUID', payload: UUID })
  //   }
  // }

  async function updateCountries() {
    try {
      let data = await fetch(
        process.env.REACT_APP_API_URL + 'countries/getall',
        {
          method: 'GET'
        }
      )

      if (data.status === 400) throw new Error(data.statusText)
      data = await data.json()
      dispatch({ type: 'SET_COUNTRIES', payload: data })

      // localStorage.setItem("countries", JSON.stringify(data));
      setSelectedCountry(data[0])
      return data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async function updateStates(countryId) {
    try {
      let data = await fetch(
        process.env.REACT_APP_API_URL + 'states/getall/' + countryId,
        {
          method: 'GET'
        }
      )

      if (data.status === 400) throw new Error(data.statusText)
      data = await data.json()
      dispatch({ type: 'SET_STATES', payload: data })
      setSelectedState(data[0])
      // localStorage.setItem("states", JSON.stringify(data));
      return data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async function updateCities(stateId) {
    try {
      let data = await fetch(
        process.env.REACT_APP_API_URL + 'cities/getall/' + stateId,
        {
          method: 'GET'
        }
      )

      if (data.status === 400) throw new Error(data.statusText)
      data = await data.json()
      dispatch({ type: 'SET_CITIES', payload: data })
      setSelectedCity(data[0])
      // localStorage.setItem("cities", JSON.stringify(data));
      return data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async function updateServices() {
    try {
      let data = await fetch(
        process.env.REACT_APP_API_URL + 'services/getall',
        {
          method: 'GET'
        }
      )

      if (data.status === 400) throw new Error(data.statusText)
      data = await data.json()
      dispatch({ type: 'SET_SERVICES', payload: data })

      // localStorage.setItem("services", JSON.stringify(data));
      return data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  useEffect(() => {
    async function Init() {
      toggleLoader(true)
      await checkUpdates()
      toggleLoader(false)
    }
    Init()
  }, [])

  return (
    <Context.Provider
      value={{
        ...state,
        checkUpdates,
        setSelectedCity,
        setSelectedCountry,
        setSelectedState,
        selectedCity,
        selectedCountry,
        selectedState,
        selectedServices,
        setSelectedServices,
        updateCountries,
        updateStates,
        updateCities
      }}
    >
      {props.children}
    </Context.Provider>
  )
}

Services.defaultProps = {
  children: null
}

Services.propTypes = {
  children: PropTypes.node
}

export default Services
