import React, { useContext, useState, useEffect, useRef } from 'react'
import './styles/style.css'
import { _handleErrorMessage } from '../../functions/error.functions'
import { Link, useParams } from 'react-router-dom'
import _Utils from '../../context/utils'
import _Auth from '../../context/auth'
import { serverValidation } from '../../functions/validation.functions'
import { useHistory } from 'react-router-dom'
import Button from '../../components/Button'
import { LanguageContext } from '../../context/language/context'
import Typography from '../../components/Typography'
import Avatar from '../../components/Avatar'
import StandardView from '../../layouts/StandardView'
import Divider from '../../components/Divider'
import DefaultBanner from '../../assets/images/banner.webp'
import { Modal } from 'react-responsive-modal'
import { IconButton } from '../../components/IconButton'
import PlansModal from '../../components/PlansModal'

export default function Prospect() {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const { token, reloadData, self } = useContext(_Auth.Context)
  let { id } = useParams()
  const history = useHistory()
  const { toggleLoader, enqueueNotification } = useContext(_Utils.Context)

  const [project, setProject] = useState(true)
  const [related, setRelated] = useState(false)
  const [open, setOpen] = useState(false)
  const myRef = useRef(null)

  const [openPlansModal, setOpenPlansModal] = useState(false)
  function onOpenPlansModal() {
    setOpenPlansModal(true)
  }

  function onClosePlansModal() {
    setOpenPlansModal(false)
  }

  useEffect(() => {
    Init()
  }, [])

  async function Init() {
    await toggleLoader(true)
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'projects/invitation/' + id,
        {
          method: 'GET',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      serverValidation(
        response,
        null,
        languageContext.userLanguage,
        async (data) => {
          await toggleLoader(false)
          setProject(data)
          if (data.User_Projects) {
            data.User_Projects.forEach((relation) => {
              if (relation.UserId === self?.id) setRelated(true)
            })
          }
        },
        async (error) => {
          await toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
          history.push('/Dashboard')
        }
      )
    } catch (error) {
      await toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
      history.push('/Dashboard')
    }
  }

  async function acceptInvitation() {
    await toggleLoader(true)

    if (!token) {
      setOpen(true)
      return false
    }

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'projects/invitation/accept/' + id,
        {
          method: 'GET',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      serverValidation(
        response,
        null,
        languageContext.userLanguage,
        async (data) => {
          await toggleLoader(false)
          enqueueNotification(languageContext.dictionary.success, 'success')
          reloadData(() => {
            history.push('/Projects/' + data)
          })
        },
        async (error) => {
          if (error?.error?.code === 8016) {
            onOpenPlansModal()
          }
          await toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  return (
    <StandardView title={lang.invitation}>
      <div
        className='uk-flex uk-flex-center uk-margin-small-bottom'
        ref={myRef}
      >
        <Typography variant={'h6'} color='secondary'>
          {lang.invited}
        </Typography>
      </div>
      <div className='uk-grid uk-flex-column'>
        <div
          className='uk-width-1-2 uk-align-center'
          style={{
            position: 'relative'
          }}
        >
          <img
            src={
              project && project.banner
                ? process.env.REACT_APP_API_URL + project.banner
                : DefaultBanner
            }
            alt='banner'
            className='oski-invitation-banner'
          />
          <div className='oski-project-title'>
            <Typography color={'secondary'}>
              {project && project.title}
            </Typography>
          </div>
        </div>
        <div
          className='uk-width-1-2 uk-margin-small-top'
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
        >
          <Typography variant={'body2'} align='justify'>
            {project && project.description}
          </Typography>
          {project &&
            project.User_Projects &&
            project.User_Projects.length > 0 && (
              <>
                <Typography
                  color='secondary'
                  className='uk-margin-small-bottom'
                >
                  {languageContext.dictionary.members}
                </Typography>
                <div
                  className='uk-flex uk-flex-wrap uk-flex-between'
                  style={{ gap: 10 }}
                >
                  {project.User_Projects.map((projectUser, index) => (
                    <div
                      className='uk-flex uk-flex-middle uk-margin-bottom'
                      key={index}
                    >
                      <div>
                        <Avatar
                          src={projectUser.User.image}
                          alt={projectUser.User.name}
                          border={
                            projectUser.Role &&
                            projectUser.Role.name === 'project-admin'
                          }
                          className='uk-margin-small-right'
                        />
                      </div>
                      <div>
                        <Typography variant='subtitle2' disableMargin>
                          {projectUser.User.name}
                        </Typography>
                        <Typography variant='body2' disableMargin>
                          {projectUser.User.email}
                        </Typography>
                        {projectUser.Role &&
                          projectUser.Role.name === 'project-admin' && (
                            <Typography
                              color='secondary'
                              variant='body2'
                              disableMargin
                            >
                              {lang.admin}
                            </Typography>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}

          {related === true && (
            <Typography variant={'body2'} color='secondary' align={'right'}>
              {lang.alreadyIn}
            </Typography>
          )}
          <div className='uk-flex uk-flex-right'>
            {related === false ? (
              <Button onClick={acceptInvitation}>
                {lang.acceptInvitation}
              </Button>
            ) : (
              <Link to={'/Projects/' + project.id}>
                <Button variant='text' buttonStyle={{ padding: 0 }}>
                  {lang.seeProject}
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        container={myRef.current}
        classNames={{ modal: 'customModalPhase' }}
      >
        <div className='uk-padding uk-flex uk-flex-center uk-flex-column'>
          <Typography align={'center'} variant='h6' color={'secondary'}>
            {lang.log}
          </Typography>
          <Link
            to={{
              pathname: '/login',
              state: { nextRoute: '/invitation/project/' + id }
            }}
            style={{ textDecoration: 'none' }}
          >
            <Button className='uk-flex uk-margin-top'>
              {languageContext.dictionary.login}
            </Button>
          </Link>
          <div className='uk-flex uk-flex-middle' style={{ gap: 5 }}>
            <div className='uk-width-1-2'>
              <Divider></Divider>
            </div>
            <Typography>{lang.o}</Typography>
            <div className='uk-width-1-2'>
              <Divider></Divider>
            </div>
          </div>
          <Link to='/signup-info' style={{ textDecoration: 'none' }}>
            <Button className='uk-flex uk-margin-medium-top'>
              {lang.signUp}
            </Button>
          </Link>
        </div>
      </Modal>
      <PlansModal open={openPlansModal} onClose={onClosePlansModal} />
    </StandardView>
  )
}
