//React hooks
import React, { useContext, useEffect, useState } from 'react'

//Libraries installed
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

//Components
import NoData from '../../components/NoData'
import ProfessionalCard from '../../components/ProfessionalCard'
import Button from '../../components/Button'
import Typography from '../../components/Typography'
import { CSelectInput } from '../../components/SelectInput'
import { AlbumSearchResult, ProjectSearchResult } from '../../components/'

//Styles
import './styles/style.css'

//Context
import _Utils from '../../context/utils'
import _Services from '../../context/services'
import { LanguageContext } from '../../context/language/context'

//Functions
import { _handleErrorMessage } from '../../functions/error.functions'
import { serverValidation } from '../../functions/validation.functions'

export default function Search() {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const { city } = useParams()
  const { type } = useParams()
  const { enqueueNotification, toggleLoader } = useContext(_Utils.Context)
  const [professionals, setProfessionals] = useState([])
  const [projects, setProjects] = useState([])
  const [albums, setAlbums] = useState([])
  const [cityName, setCityName] = useState(city)

  const [disabledButton, setDisabledButton] = useState(false)

  const {
    states,
    cities,
    services,
    setSelectedCity,
    setSelectedState,
    selectedCity,
    setSelectedServices,
    selectedServices
  } = useContext(_Services.Context)

  const serevicesSelect = services.map((service) => ({
    value: service.title,
    label: service.title
  }))

  const statesSelect = states.map((state) => ({
    value: state,
    label: state.name
  }))

  const citiesSelect = cities.map((city) => ({
    value: city,
    label: city.name
  }))

  const { control, handleSubmit, setValue, setError } = useForm()

  function onSubmit() {
    if (selectedCity.name) Init(selectedCity.name)
    else enqueueNotification(lang['fill'], 'error')
  }

  async function Init(c) {
    await toggleLoader(true)
    try {
      if (selectedServices) {
        setDisabledButton(true)
        let response = await fetch(
          process.env.REACT_APP_API_URL + 'search/city/' + c + '/' + type,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              services: selectedServices?.map((s) => s.value)
            })
          }
        )
        await serverValidation(
          response,
          setError,
          languageContext?.userLanguage,
          (data) => {
            setCityName(c)
            setDisabledButton(false)
            setProfessionals(data.professionals)
            setProjects(data.projects)
            setAlbums(data.albums)

            var servicios = []

            data.services &&
              data.services.length > 0 &&
              data.services.map((service) => {
                return servicios.push(
                  serevicesSelect.find((c) => c.value === service.id)
                )
              })
          },
          (error) => {
            setDisabledButton(false)
            enqueueNotification(
              _handleErrorMessage(languageContext?.userLanguage, error.error),
              'error'
            )
          }
        )
      }
    } catch (error) {
      setDisabledButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    await toggleLoader(false)
  }

  useEffect(() => {
    Init(city)
  }, [])

  return (
    <div className='uk-container'>
      <div style={{ marginTop: 35 }}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className='uk-grid uk-margin-top'>
            <div className='uk-width-2-5'>
              <CSelectInput
                name='services'
                control={control}
                label={lang['whatToDo']}
                options={serevicesSelect}
                isMulti
                onChange={(e) => setSelectedServices(e)}
                required
              />
            </div>
            <div className='uk-width-1-5'>
              <CSelectInput
                name='state'
                control={control}
                label={lang['state']}
                options={statesSelect}
                onChange={(selectedOption) => {
                  setValue('city', null)
                  setSelectedState(selectedOption?.value)
                }}
                required
                isClearable={true}
              />
            </div>
            <div className='uk-width-1-5'>
              <CSelectInput
                name='city'
                control={control}
                label={lang['cityInput']}
                options={citiesSelect}
                onChange={(selectedOption) => {
                  setValue('city', null)
                  setSelectedCity(selectedOption.value)
                }}
                required
                isClearable={true}
              />
            </div>
            <div className='uk-width-1-5'>
              <Button
                size='auto'
                color='dark'
                type='submit'
                disabled={disabledButton}
              >
                {languageContext.dictionary['search']}
              </Button>
            </div>
          </div>
        </form>
      </div>
      <div className='uk-width-1-1  uk-margin-bottom dc-separator dc-bg-yellow'></div>
      <div className='uk-margin-medium-top uk-margin-medium-bottom uk-width-1-1 dc-project-form-title'>
        <Typography variant={'h4'}>
          {cityName ? cityName : lang['city']}
        </Typography>
      </div>
      <>
        <span className='dc-face-project-title'>
          <Typography
            variant={'h6'}
            color='secondary'
            style={{ textTransform: 'uppercase' }}
          >
            {languageContext.dictionary['professionals']}
          </Typography>
        </span>
        <div className=' diinco-horizontal-overflow-container'>
          {professionals.length !== 0 ? (
            professionals.map((professional, index) => (
              <div className='uk-width-1-5' key={index + 'professional'}>
                <ProfessionalCard
                  img={professional.image}
                  name={professional.name}
                  nickname={professional.nickname}
                  profession={professional.Services.map(
                    (service) => service.title
                  ).join(' / ')}
                  city={city}
                />
              </div>
            ))
          ) : (
            <NoData />
          )}
        </div>
        <span className='dc-face-project-title'>
          <Typography
            variant={'h6'}
            color='secondary'
            style={{ textTransform: 'uppercase' }}
          >
            {languageContext.dictionary['projects']}
          </Typography>
        </span>
        <div className='uk-grid uk-grid-row-large'>
          {projects.length !== 0 ? (
            projects.map((project, index) => (
              <div className='uk-width-1-3' key={index + 'project'}>
                <ProjectSearchResult
                  name={project.title}
                  img={project.banner}
                  tags={project.Tags}
                  users={project.Users}
                  description={project.description}
                  city={project.Location.City.name}
                  state={project.Location.City.State.name}
                  country={project.Location.City.State.Country.name}
                />
              </div>
            ))
          ) : (
            <NoData />
          )}
        </div>
        <span className='dc-face-project-title'>
          <Typography
            variant={'h6'}
            color='secondary'
            style={{ textTransform: 'uppercase' }}
          >
            {languageContext.dictionary['albums']}
          </Typography>
        </span>
        <div className='uk-grid uk-grid-row-large'>
          {albums.length !== 0 ? (
            albums.map((album, index) => (
              <div className='uk-width-1-3' key={index + 'album'}>
                <AlbumSearchResult
                  id={album.id}
                  name={album.title}
                  img={album.cover}
                  tags={album.Tags}
                  users={album.Users}
                  description={album.description}
                  city={album.Location.City.name}
                  state={album.Location.City.State.name}
                  country={album.Location.City.State.Country.name}
                />
              </div>
            ))
          ) : (
            <NoData />
          )}
        </div>
      </>
    </div>
  )
}
