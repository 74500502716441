import React, { useContext, useState, useEffect, useRef } from 'react'
import _Utils from '../../../context/utils'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import { useHistory } from 'react-router'

import { LanguageContext } from '../../../context/language/context'
import { _handleErrorMessage } from '../../../functions/error.functions'
import { IconButton } from '../../IconButton'
import Button from '../../Button'
import { serverValidation } from '../../../functions/validation.functions'
import PropTypes from 'prop-types'

export default function Card(props) {
  const history = useHistory()
  const languageContext = useContext(LanguageContext)
  const { theme } = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('../languages/en.json')
  } else {
    lang = require('../languages/es.json')
  }

  const { toggleLoader, enqueueNotification, notify } = useContext(
    _Utils.Context
  )

  const [content, setContent] = useState({
    title: '',
    description: '',
    users: [],
    appointment: {
      date: new Date(),
      title: '',
      description: ''
    },
    note: {
      title: '',
      description: ''
    }
  })

  const [open, setOpen] = useState(false)
  const myRef = useRef(null)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    // console.log("THIS ARE THE PROPS", props);
    let tmp = {
      title: props.title,
      description: props.description
    }

    if (props.people.length !== 0) {
      tmp = {
        ...tmp,
        users: props.people
      }
      // setUsers(props.people);
    }
    if (props.appointment) {
      tmp = {
        ...tmp,
        appointment: {
          date: new Date(props.appointment.date),
          title: props.appointment.title,
          description: props.appointment.description
        }
      }
    }
    if (props.note) {
      tmp = {
        ...tmp,
        note: props.note
      }
    }
    setContent({ ...content, ...tmp })
  }, [])

  async function archieveTask() {
    await toggleLoader(true)
    setDisabled(true)
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'prospects/archive',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ id: props.id })
        }
      )
      await serverValidation(
        response,
        null,
        languageContext.userLanguage,
        async () => {
          await toggleLoader(false)
          notify(languageContext.dictionary['success'])
          setDisabled(false)
          history.go(0)
        },
        async (error) => {
          await toggleLoader(false)
          setDisabled(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      toggleLoader(false)
      setDisabled(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    toggleLoader(false)
  }

  return (
    <>
      <div className='card-container' ref={myRef}>
        <div className='prospect-title'>{props.title}</div>
        <div className='prospect-container'>
          {props.people.length !== 0 ? (
            <>
              {/* at least 1 person */}
              <div>
                {props.people.map((person, index) => (
                  <div key={'person-card-' + index}>{person.name}</div>
                ))}
              </div>
              {/* if exist an appointment or a note */}
              {props.appointment || props.note ? (
                <div className='prospect-info'>
                  <div className='prospect-info-icons'></div>
                  <div className='prospect-photos-container'>
                    {props.people.map((person, index) => (
                      <img
                        key={'person-img-card-' + index}
                        className='user-photo uk-border-circle'
                        src={person.photo}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <div className='prospect-photos-container'>
                  {props.people.map((person, index) => (
                    <img
                      key={'person-img-card-' + index}
                      className='user-photo uk-border-circle'
                      src={person.photo}
                    />
                  ))}
                </div>
              )}
            </>
          ) : (
            <>
              {/* no persons */}
              <div className='prospect-description'>{props.description}</div>
              {/* if exist an appointment or a note*/}
              {props.appointment || props.note ? (
                <div className='prospect-info'>
                  <div className='prospect-info-icons'></div>
                </div>
              ) : null}
            </>
          )}
        </div>
        <div className='uk-flex uk-flex-right'>
          <IconButton
            icon={'box'}
            size={'mini'}
            data-uk-tooltip={lang.archive}
            style={{ filter: 'brightness(0) saturate(100%)' }}
            variant='borderless'
            onClick={() => setOpen(true)}
          />
        </div>
      </div>
      <div className='oski-card-container'>
        <div
          className='oski-card-text'
          onClick={() => history.push('/Prospect/' + props.id)}
        >
          {lang.expedient}
        </div>
        <div
          style={{
            color: theme === 'light' ? 'var(--bg-dark-gray)' : 'white'
          }}
        >
          |
        </div>
        <div
          className='oski-card-text'
          onClick={() => history.push('/Prospect/' + props.id + '/Proposal')}
        >
          {lang.proposal}
        </div>
      </div>
      <Modal
        container={myRef.current}
        open={open}
        onClose={() => setOpen(false)}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        classNames={
          theme === 'dark'
            ? {
                modal: 'customModalCRM-dark'
              }
            : {
                modal: 'customModalCRM-light'
              }
        }
      >
        <div
          className='oski-sol-container uk-margin-medium-bottom uk-text-center'
          style={{ textTransform: 'none' }}
        >
          {lang.sureToArchive}
        </div>
        <div className="'uk-width-1 uk-flex uk-flex-between uk-flex-middle">
          <Button color={'grey'} onClick={() => setOpen(false)}>
            {languageContext.dictionary.cancel}
          </Button>
          <Button onClick={() => archieveTask()} disabled={disabled}>
            {lang.archive}
          </Button>
        </div>
      </Modal>
    </>
  )
}

Card.defaultProps = {
  id: '',
  title: '',
  description: '',
  people: [],
  appointment: null,
  note: null
}

Card.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  people: PropTypes.array,
  appointment: PropTypes.object,
  note: PropTypes.object
}
