import React, { useContext, useEffect, useRef, useState } from 'react'
import CustomCalendar from '../../../components/CustomCalendar'
import { LanguageContext } from '../../../context/language/context'
import _Utils from '../../../context/utils'
import _Auth from '../../../context/auth'
import { _handleErrorMessage } from '../../../functions/error.functions'
import { serverValidation } from '../../../functions/validation.functions'

const ClientCalendar = () => {
  const [loading, setLoading] = useState(true)
  const [events, setEvents] = useState([])
  const calendar = useRef(null)
  const { userLanguage } = useContext(LanguageContext)
  const { token, self } = useContext(_Auth.Context)
  const { enqueueNotification, HomeLoader } = useContext(_Utils.Context)

  const Loader = (
    <div className='uk-width-1-1 uk-height-1-1'>
      <div
        className='uk-margin-auto uk-margin-medium-top uk-margin-medium-bottom uk-width-auto'
        style={{ width: 'fit-content' }}
      >
        <HomeLoader />
      </div>
    </div>
  )

  useEffect(() => {
    async function Init() {
      try {
        let response = await fetch(
          process.env.REACT_APP_API_URL + 'clients/' + self.id + '/events',
          {
            credentials: 'include',
            mode: 'cors'
          }
        )

        await serverValidation(
          response,
          null,
          userLanguage,
          async (data) => {
            setLoading(false)
            let tmp = []

            if (data.Events) {
              data.Events.forEach((event) => {
                tmp.push({
                  date: new Date(event.date_end),
                  data: {
                    title: event.title,
                    date: new Date(event.date_end),
                    date_start: new Date(event.date_start),
                    date_end: new Date(event.date_end),
                    description: event.body,
                    status: event.status,
                    id: event.id,
                    stage: 0
                  }
                })
              })
            }

            if (data.Projects) {
              data.Projects.forEach((project) => {
                if (project.Events) {
                  project.Events.forEach((event) => {
                    tmp.push({
                      date: new Date(event.date_end),
                      data: {
                        title: event.title,
                        date: new Date(event.date_end),
                        date_start: new Date(event.date_start),
                        date_end: new Date(event.date_end),
                        description: event.body,
                        status: event.status,
                        id: event.id,
                        stage: 0,
                        projectId: project.id
                      }
                    })
                  })
                }
              })
            }

            if (data.Prospects) {
              data.Prospects.forEach((prospect) => {
                if (prospect.Events) {
                  prospect.Events.forEach((event) => {
                    tmp.push({
                      date: new Date(event.date_end),
                      data: {
                        title: event.title,
                        date: new Date(event.date_end),
                        date_start: new Date(event.date_start),
                        date_end: new Date(event.date_end),
                        description: event.body,
                        status: event.status,
                        id: event.id,
                        stage: 0
                      }
                    })
                  })
                }
              })
            }
            // tmp.sort((a, b) => new Date(a.date) - new Date(b.date));
            setEvents([...tmp])
          },
          async (error) => {
            setLoading(false)
            enqueueNotification(
              _handleErrorMessage(userLanguage, error.error),
              'error'
            )
          }
        )
      } catch (error) {
        setLoading(false)
        enqueueNotification(_handleErrorMessage(userLanguage, error), 'error')
      }
    }
    if (token) Init()
  }, [token])

  return (
    <>
      {loading ? (
        <>{Loader}</>
      ) : (
        <div className='uk-padding-large uk-padding-remove-vertical'>
          <CustomCalendar
            dates={events}
            inputRef={calendar}
            locale={userLanguage === 'es' ? 'es-ES' : 'en-US'}
          />
        </div>
      )}
    </>
  )
}

export default ClientCalendar
