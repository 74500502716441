import React, { useContext, useEffect, useState } from 'react'
import './styles/style.css'

import _Utils from '../../context/utils'
import _Services from '../../context/services'
import _Auth from '../../context/auth'

import { CTextInput } from '../../components/TextInput'
import { CDropZoneCoverImage } from '../../components/DropZoneCoverImage'
import { CTagsInput } from '../../components/TagsInput'
import ImageThumbsContainer from '../../components/ImageThumbsContainer'
import { useHistory, useParams } from 'react-router-dom'

import { LanguageContext } from '../../context/language/context'
import { _handleErrorMessage } from '../../functions/error.functions'
import { Prompt } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { serverValidation } from '../../functions/validation.functions'
import { serialize } from '../../functions/validation.functions'
import Button from '../../components/Button'

export default function ProjectForm() {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const { toggleLoader, enqueueNotification, notify } = useContext(
    _Utils.Context
  )
  const { token, self } = useContext(_Auth.Context)
  const history = useHistory()
  const { id } = useParams()

  const [loadedData, setLoadedData] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)

  const { checkUpdates } = useContext(_Services.Context)

  useEffect(() => {
    async function Init() {
      toggleLoader(true)
      await checkUpdates()
      toggleLoader(false)
    }
    Init()
  }, [])

  useEffect(() => {
    async function Init() {
      if (token && id && !loadedData)
        try {
          let data = await fetch(
            process.env.REACT_APP_API_URL + 'albums/getone/' + id,
            {
              method: 'GET',
              credentials: 'include',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )
          data = await data.json()
          console.log('ALBUM DATA', data)
          //TAGS
          var tags = []
          data &&
            data.tags &&
            data.tags.length > 0 &&
            data.tags.map((tag) => {
              return tags.push(tag.name)
            })

          //images
          let images = []
          if (data.cover) {
            images.push(data.cover)
          }
          if (data.images && data.images.length > 0) {
            data.images.forEach((image) => {
              images.push(image.url)
            })
          }

          reset({
            title: data.title,
            description: data.description,
            tags: tags,
            oldImages: images
          })
          setLoadedData(true)
        } catch (error) {
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error),
            'error'
          )
          history.push('/Dashboard')
        }
    }
    Init()
  }, [token, loadedData])

  // useEffect(() => {
  // 	if (old) {
  // 		setValue('title', old.title);
  // 		setValue('description', old.description);
  // 		setBanner(old.cover);
  // 		let etiquetas = []
  // 		old.tags.map((t) => etiquetas.push(t.name))
  // 		setValue('tags', etiquetas);

  // 		setOldImages(old.images);

  // 		let imagenes = []
  // 		imagenes.push(old.cover)
  // 		if (old.images && typeof old.images === 'object') {
  // 			old.images.map((i) => { imagenes.push(i.url) })
  // 		}
  // 		else {
  // 			imagenes.push(old.images.url)
  // 		}
  // 		console.log('---->', imagenes)
  // 		//setValue('files', imagenes)
  // 	}
  // }, [old]);

  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])

  const {
    reset,
    setValue,
    getValues,
    control,
    setError,
    handleSubmit,
    formState
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: '',
      description: '',
      tags: [],
      files: [],
      oldImages: []
    }
  })

  useEffect(() => {
    if (formState.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
    } else {
      window.onbeforeunload = null
    }
  }, [formState.isDirty])

  // async function onSubmit(event) {
  // 	await toggleLoader(true);
  // 	console.log(startDate);
  // 	setIsBlocking(false);

  // 	try {
  // 		let data = new FormData(event.target);
  // 		let tags = [];
  // 		JSON.parse(data.get("tags_string")).map((tag) => {
  // 			tags.push(tag);
  // 		});
  // 		data.append("tags", tags);
  // 		data.append("albumId", id);
  // 		if (files) {
  // 			for (const [index, file] of files.entries()) {
  // 				if (index === 0)
  // 					data.append("banner", files[0], files[0].name);
  // 				else data.append("images", files[index], files[index].name);
  // 			}
  // 		}
  // 		if (oldImages) {
  // 			for (const [index, file] of oldImages.entries()) {
  // 				data.append("OldImages", file.url);
  // 				data.append("images", file.url);
  // 			}
  // 		}
  // 		console.log(oldImages, "sada");

  // 		// if (banner) data.append("banner", banner[0], banner[0].name);
  // 		let response = await fetch(
  // 			process.env.REACT_APP_API_URL + "albums",
  // 			{
  // 				method: "PUT",
  // 				headers: {
  // 					Accept: "multipart/form-data",
  // 					Authorization: "Bearer " + token,
  // 				},
  // 				body: data,
  // 			}
  // 		);

  // 		response = await response.json();

  // 		console.log(response);
  // 		notify("Success!", reloadData);

  // 		// enqueueNotification("ok");

  // 		// notify(
  // 		// 	"Success!",
  // 		// 	reloadData(() => {
  // 		// 		history.push("/dashboard");
  // 		// 	})
  // 		// );
  // 	} catch (error) {
  // 		enqueueNotification(
  // 			_handleErrorMessage(languageContext?.userLanguage, error),
  // 			"error"
  // 		);
  // 	}
  // 	toggleLoader(false);
  // }

  async function onSubmit(data) {
    if (!loadedData) {
      enqueueNotification(
        languageContext.dictionary.error + ' ' + languageContext.dictionary.try,
        'error'
      )
      return
    }

    await toggleLoader(true)
    setDisabledButton(true)

    try {
      const formData = serialize(data, {
        dotsForObjectNotation: true,
        noArrayNotation: true,
        noFilesWithArrayNotation: true
      })
      formData.append('albumId', id)

      let response = await fetch(process.env.REACT_APP_API_URL + 'albums', {
        method: 'PUT',
        credentials: 'include',
        mode: 'cors',
        headers: {
          Accept: 'multipart/form-data'
        },
        body: formData
      })

      await serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        () => {
          reset(data)
          setDisabledButton(false)
          notify(
            languageContext.dictionary['success'],
            setTimeout(function () {
              history.push('/profile/' + self?.nickname)
            }, 1000)
          )
        },
        async (e) => {
          setDisabledButton(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, e),
            'error'
          )
        }
      )
    } catch (error) {
      setDisabledButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    await toggleLoader(false)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onError = () => {
    enqueueNotification(
      _handleErrorMessage(languageContext?.userLanguage, { code: 1000 }),
      'error'
    )
  }

  return (
    <div className='uk-container'>
      <div className='uk-margin-medium-top uk-width-1-1 dc-project-form-title'>
        {lang['album']}
      </div>
      <div className='uk-width-1-1 uk-margin-small-top dc-separator dc-bg-yellow'></div>
      <div className='uk-margin-medium-top uk-margin-medium-bottom uk-width-1-1 uk-flex uk-flex-column'>
        <form onSubmit={handleSubmit(onSubmit, onError)} noValidate>
          <Prompt
            when={formState.isDirty}
            message={JSON.stringify({
              title: languageContext.dictionary.discard,
              message: languageContext.dictionary.areYouSure,
              accept: languageContext.dictionary.accept,
              cancel: languageContext.dictionary.cancel
            })}
          />
          <CTextInput
            label={languageContext.dictionary.title}
            name='title'
            control={control}
            maxLength={45}
            required
          />
          <CTextInput
            label={languageContext.dictionary.desc}
            name='description'
            type='textarea'
            control={control}
            rows={2}
            maxLength={120}
            charCount
          />

          <div className='uk-grid'>
            <div className='uk-width-3-5'>
              <CDropZoneCoverImage
                name={'files'}
                control={control}
                ContainerClasses='diinco-project-form-dropzone-container leo-height-dropzone'
                ImageClasses='diinco-project-form-dropzone-image'
                onChange={() => forceUpdate()}
                Image={getValues('oldImages')[0]}
              />
              {formState.errors && formState.errors.oldImages && (
                <div className='leo-error-message'>
                  <span data-uk-icon='icon: warning; ratio: 0.6'></span>{' '}
                  {formState.errors.oldImages.message}
                </div>
              )}
              <ImageThumbsContainer
                control={control}
                thumbs={getValues('files')}
                containerStyle='diinco-thumbs-container-project-form'
                thumbStyle='diinco-thumbs-style-project-form'
                deleteButtonStyle='diinco-thumbs-delete-project-form'
                handleRemove={(event, file, index) => {
                  if (typeof file === 'string') {
                    const newValue = getValues('oldImages').filter(
                      (o, i) => i !== index
                    )
                    setValue('oldImages', newValue)
                    forceUpdate()
                  } else {
                    const newValue = getValues('files').filter(
                      (o, i) => i !== index
                    )
                    setValue('files', newValue)
                    forceUpdate()
                  }
                }}
                rootImages={getValues('oldImages')}
              />
            </div>
            <div className='uk-width-2-5'>
              <CTagsInput
                name='tags'
                control={control}
                classes={{
                  tagsContainer: 'leo-tags-container'
                }}
              />
            </div>
          </div>
          <div className='uk-margin-top uk-flex uk-flex-between'>
            <Button
              onClick={() => {
                history.goBack()
              }}
              variant='text'
              size='small'
            >
              {languageContext.dictionary.cancel}
            </Button>
            <Button
              color='primary'
              size='medium'
              centered={false}
              type='submit'
              disabled={disabledButton}
            >
              {languageContext.dictionary.save}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
