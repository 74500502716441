import React, { useContext, useEffect, useState } from 'react'
import _Auth from '../../context/auth'
import _Utils from '../../context/utils'
import { useHistory, useParams } from 'react-router'
import './styles/style.css'

import { LanguageContext } from '../../context/language/context'
import { _handleErrorMessage } from '../../functions/error.functions'
import {
  serverValidation,
  validationRules
} from '../../functions/validation.functions'
import { CTextInput } from '../../components/TextInput'
import { useForm } from 'react-hook-form'

import { Prompt } from 'react-router-dom'
import Button from '../../components/Button'

export default function Index() {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const history = useHistory()
  const dataToken = useParams().token
  const { toggleLoader, enqueueNotification } = useContext(_Utils.Context)
  const { login, token } = useContext(_Auth.Context)
  const [disableButton, setDisableButton] = useState(false)

  const { reset, getValues, control, setError, handleSubmit, formState } =
    useForm({
      mode: 'onChange',
      defaultValues: {
        email: '',
        password: '',
        password_confirm: ''
      }
    })

  useEffect(() => {
    if (formState.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
    } else {
      window.onbeforeunload = null
    }
  }, [formState.isDirty])

  useEffect(() => {
    if (token) {
      enqueueNotification('Already logged in!', 'error')
      history.push('/Dashboard')
    }
  }, [token])

  useEffect(() => {
    if (!dataToken) history.push('/')
  }, [])

  async function onSubmit(data) {
    await toggleLoader(true)
    setDisableButton(true)
    let email = data.email
    let password = data.password
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'auth/newPassword',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + dataToken
          },
          body: JSON.stringify(data)
        }
      )
      await serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        async () => {
          await toggleLoader(false)
          setDisableButton(false)
          reset()
          setTimeout(() => {}, 6000)
          enqueueNotification(languageContext.dictionary['success'], 'success')
          await login(email, password)
          history.push('/')
        },
        async (error) => {
          await toggleLoader(false)
          setDisableButton(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      setDisableButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  return (
    <div>
      <div className='uk-container diinco-reset-container'>
        <div
          className='uk-card uk-card-default uk-card-body uk-width-1-2@m'
          style={{ backgroundColor: 'transparent' }}
        >
          <h3 className='uk-card-title leo-title-reset'>
            {lang['reset'] + ' ' + languageContext.dictionary['password']}
          </h3>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Prompt
              when={formState.isDirty}
              message={JSON.stringify({
                title: languageContext.dictionary.discard,
                message: languageContext.dictionary.areYouSure,
                accept: languageContext.dictionary.accept,
                cancel: languageContext.dictionary.cancel
              })}
            />
            <CTextInput
              label={'Email'}
              name='email'
              control={control}
              required
              type='email'
              maxLength={100}
            />
            <CTextInput
              name='password'
              type='password'
              label={languageContext.dictionary.newPassword}
              helperText={lang['alpha']}
              control={control}
              required
              validate={{
                noSpaces: (v) =>
                  validationRules.noSpaces(v, languageContext.userLanguage),
                hasUppercase: (v) =>
                  validationRules.hasUppercase(v, languageContext.userLanguage),
                hasLowercase: (v) =>
                  validationRules.hasLowercase(v, LanguageContext.userLanguage),
                hasNumber: (v) =>
                  validationRules.hasNumber(v, languageContext.userLanguage)
              }}
              maxLength={120}
              minLength={8}
            />

            <CTextInput
              name='password_confirm'
              type='password'
              label={languageContext.dictionary.confirmPassword}
              control={control}
              required
              validate={{
                same: (v) =>
                  validationRules.same(
                    v,
                    languageContext.userLanguage,
                    getValues,
                    'password',
                    'passwordMatch'
                  )
              }}
            />
            <Button type='submit' disabled={disableButton}>
              {languageContext.dictionary['confirm']}
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}
