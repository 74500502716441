import React, { useContext, useEffect, useState, useRef } from 'react'

import _Utils from '../../context/utils'
import _Auth from '../../context/auth'

import { LanguageContext } from '../../context/language/context'

import { _handleErrorMessage } from '../../functions/error.functions'
import { useParams } from 'react-router-dom'
import { serverValidation } from '../../functions/validation.functions'
import StandardView from '../../layouts/StandardView'
import Typography from '../../components/Typography'
import { format } from 'date-fns'
import Button from '../../components/Button'
import { useHistory } from 'react-router-dom'
import { IconButton } from '../../components/IconButton'
import { Modal } from 'react-responsive-modal'
import { useForm } from 'react-hook-form'
import { CTextInput } from '../../components/TextInput'

import FileIcon from '../../components/PaymentCard/assets/file.svg'

import '../../components/PaymentCard/styles/style.css'
import StageCard from './components/StageCard'
import CustomGallery from '../../components/CustomGallery'

export default function Stages() {
  const { id } = useParams()

  const languageContext = useContext(LanguageContext)
  const { toggleLoader, enqueueNotification, notify } = useContext(
    _Utils.Context
  )
  const { token, reloadData, hasPermission } = useContext(_Auth.Context)

  var lang
  var langPayment //provisional
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
    langPayment = require('../../components/PaymentCard/languages/en.json')
  } else {
    lang = require('./languages/es.json')
    langPayment = require('../../components/PaymentCard/languages/es.json')
  }
  const [loading, setLoading] = useState(true)
  const [stages, setStages] = useState([])
  const [general, setGeneral] = useState([])
  const history = useHistory()

  useEffect(() => {
    async function fetchData() {
      if (token)
        try {
          await toggleLoader(true)
          let response = await fetch(
            process.env.REACT_APP_API_URL + 'projects/' + id + '/stages',
            {
              method: 'GET',
              credentials: 'include',
              mode: 'cors',
              headers: {
                // "Content-Type": "application/json",
              }
            }
          )
          serverValidation(
            response,
            null,
            languageContext.userLanguage,
            async (data) => {
              if (data && data.length) {
                data.forEach((stage) => {
                  if (stage.name === 'General') {
                    setGeneral([stage])
                  }
                })
                setStages(
                  data.filter((stage) => {
                    return stage.name !== 'General'
                  })
                )
              }
              setLoading(false)
              await toggleLoader(false)
            },
            async (error) => {
              await toggleLoader(false)
              setLoading(false)
              if (error.error && error.error.code !== 2000)
                enqueueNotification(
                  _handleErrorMessage(
                    languageContext?.userLanguage,
                    error.error
                  ),
                  'error'
                )
              if (error.error && error.error.code === 4000) history.push('/')
            }
          )
        } catch (error) {
          await toggleLoader(false)
          setLoading(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error),
            'error'
          )
          if (error.code === 4000) history.push('/')
        }
    }
    fetchData()
  }, [token])

  //STAGES
  const [disabledButton, setDisabledButton] = useState(false)
  const [open, setOpen] = useState(false)
  function onOpenModal() {
    setOpen(true)
  }
  const onCloseModal = () => {
    setOpen(false)
    reset()
  }

  const myRef = useRef(null)

  const { control, reset, handleSubmit, setValue, setError, getValues } =
    useForm({
      mode: 'onChange',
      defaultValues: {
        name: '',
        date_start: '',
        date_end: '',
        description: '',
        id: ''
      }
    })

  async function onSubmit(data) {
    await toggleLoader(true)
    setDisabledButton(true)

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'stages/' + getValues('id'),
        {
          method: 'PUT',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }
      )

      await serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        () => {
          reset(data)
          setDisabledButton(false)
          notify(
            languageContext.dictionary['success'],
            setTimeout(function () {
              reloadData(() => {
                // history.push("/project/" + id);
              })
            }, 1000)
          )
          onCloseModal()
        },
        async (error) => {
          setDisabledButton(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      setDisabledButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  function editPhase(index) {
    try {
      const stage = stages[index]
      setValue('name', stage.name)
      setValue('date_start', stage.date_start?.split('T')[0])
      setValue('date_end', stage.date_end?.split('T')[0])
      setValue('description', stage.description)
      setValue('id', stage.id)
      onOpenModal()
    } catch (error) {
      enqueueNotification(languageContext.dictionary['somethingWrong'], 'error')
    }
  }

  //PAYMENTS
  const [payment, setPayment] = useState(null)
  const [openPayment, setOpenPayment] = useState(false)

  function onOpenPayment(index, pIndex) {
    setPayment({
      ...stages[index]?.Payments[pIndex],
      Stage: { id: stages[index]?.id, name: stages[index]?.name }
    })
    setOpenPayment(true)
  }

  function onOpenPaymentGeneral(index, pIndex) {
    setPayment({
      ...general[index]?.Payments[pIndex],
      Stage: { id: general[index]?.id, name: general[index]?.name }
    })
    setOpenPayment(true)
  }

  function onClosePayment() {
    setOpenPayment(false)
    setPayment(null)
  }

  function formatter(number) {
    return parseFloat(number)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  const [openAccept, setOpenAccept] = useState(false)
  const [openReject, setOpenReject] = useState(false)

  function onCloseAccept() {
    setOpenAccept(false)
  }

  function onCloseReject() {
    setOpenReject(false)
  }

  async function updatePayment(data) {
    await toggleLoader(true)
    try {
      let info = { status: data }

      let response = await fetch(
        process.env.REACT_APP_API_URL +
          'projects/' +
          id +
          '/payments/' +
          payment.id,
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(info)
        }
      )

      await serverValidation(
        response,
        null,
        languageContext.userLanguage,
        async () => {
          await toggleLoader(false)
          enqueueNotification(langPayment.updated, 'success')
          onCloseAccept()
          onCloseReject()
          reloadData(() => {})
        },
        async (error) => {
          await toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      await toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  const [current, setCurrent] = useState('')
  const [openGallery, setOpenGallery] = useState(false)

  const onOpenGallery = (index) => {
    setCurrent(index)
    setOpenGallery(true)
  }
  const onCloseGallery = () => setOpenGallery(false)
  const [imagesGall, setImagesGall] = useState(null)

  useEffect(() => {
    if (payment && payment.Images) {
      let prov = []
      payment.Images.forEach((image) => {
        prov.push({
          id: image.id,
          url: process.env.REACT_APP_API_URL + image.url
        })
      })
      setImagesGall(prov)
    }
  }, [payment])

  return (
    <StandardView
      title={lang.stages}
      links={[
        {
          link: '/Dashboard/Projects',
          title: languageContext.dictionary.dashboard
        },
        {
          link: '/Projects/' + id,
          title: languageContext.dictionary.projectDashboard
        }
      ]}
      loading={loading}
    >
      <div data-uk-margin='margin:uk-margin-small-top' ref={myRef}>
        {stages && stages.length > 0 ? (
          <>
            {stages.map((stage, index) => {
              return (
                <StageCard
                  stage={stage}
                  index={index}
                  key={'stage-' + index}
                  onOpenPayment={onOpenPayment}
                  editPhase={editPhase}
                  hasPermission={hasPermission}
                />
              )
            })}
          </>
        ) : (
          <div></div>
        )}

        {general &&
          general.length &&
          general.map((generalStage, index) => {
            return (
              <StageCard
                stage={generalStage}
                index={index}
                key={'stage-' + index}
                onOpenPayment={onOpenPaymentGeneral}
                general={true}
                hasPermission={hasPermission}
              />
            )
          })}
      </div>

      {/* EDIT MODAL */}
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        container={myRef.current}
        classNames={{ modal: 'customModalPhase' }}
        closeIcon={<IconButton icon='close' component='div' />}
      >
        <h2 className='oski-title-modal'>{lang.editPhase}</h2>
        <form onSubmit={handleSubmit(onSubmit)} key={2} noValidate>
          <div className='uk-grid'>
            <div className='uk-width-1'>
              <CTextInput
                control={control}
                name='name'
                required
                label={lang.phaseName}
                maxLength={50}
              />
            </div>
            <div className='uk-width-1-2'>
              <CTextInput
                type='date'
                control={control}
                name='date_start'
                required
                label={languageContext.dictionary.startDate}
              />
            </div>
            <div className='uk-width-1-2'>
              <CTextInput
                type='date'
                control={control}
                name='date_end'
                required
                label={languageContext.dictionary.endDate}
              />
            </div>
            <div className='uk-width-1'>
              <CTextInput
                control={control}
                name='description'
                label={languageContext.dictionary.desc}
                maxLength={2000}
                type='textarea'
                charCount
              />
            </div>
          </div>
          <div className='uk-flex uk-flex-right'>
            <Button type='submit' color='secondary' disabled={disabledButton}>
              {lang.editPhase}
            </Button>
          </div>
        </form>
      </Modal>

      {/* PAYMENT MODAL */}
      <Modal
        open={openPayment}
        onClose={onClosePayment}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        classNames={
          languageContext.theme === 'dark'
            ? { modal: 'modalPayment-dark' }
            : { modal: 'modalPayment' }
        }
        container={myRef.current}
      >
        <Typography variant='h6' color='secondary'>
          {langPayment.infoPayment}
        </Typography>

        {payment ? (
          <div data-uk-grid className='uk-width1-1 uk-child-width-1-1'>
            {/* sender */}
            <div style={{ marginTop: 5 }}>
              <Typography>
                {langPayment.sender}: {payment.User.name}
              </Typography>
            </div>
            {/* receiver */}
            {payment.UserConfirm && (
              <div style={{ marginTop: 15 }}>
                <Typography>
                  {langPayment.receiver}: {payment.UserConfirm.name}
                </Typography>
              </div>
            )}
            {/* date */}
            <div style={{ marginTop: 15 }}>
              <Typography>
                {langPayment.datePayment}:{' '}
                {format(
                  new Date(payment.createdAt?.split('T')[0] + ' 00:00'),
                  'dd/MM/yyyy'
                )}
              </Typography>
            </div>

            {/* concept */}
            <div style={{ marginTop: 15 }}>
              <Typography>
                {langPayment.concept}: {payment.concept}
              </Typography>
            </div>
            {/* payment form */}
            <div style={{ marginTop: 15 }}>
              <Typography>
                {langPayment.formPayment}: {payment.type}
              </Typography>
            </div>
            {/* amount */}
            <div style={{ marginTop: 15 }}>
              <Typography>
                {langPayment.amount}: $ {formatter(payment.amount)}
              </Typography>
            </div>
            {/* <div
                            className="uk-flex uk-flex-middle"
                            style={{ marginTop: 15 }}
                        >
                            <div className="uk-margin-right">
                                <Typography>{langPayment.status}:</Typography>
                            </div>
                            <Button size="small" color="dark">
                                {payment.status === 0
                                    ? langPayment.status0
                                    : payment.status === 1
                                    ? langPayment.status1
                                    : langPayment.status2}
                            </Button>
                        </div> */}
            {/* stage */}
            {payment.Stage && (
              <div style={{ marginTop: 30 }}>
                <Typography>
                  {langPayment.stage}: {payment.Stage.name}
                </Typography>
              </div>
            )}
            {payment.status === 1 && (
              <div className='uk-margin-top'>
                {/* <Typography>
                                    {langPayment.confirmed}:  {payment.User.name}
                                </Typography> */}
                {payment.date_confirmation && (
                  <Typography>
                    {langPayment.dateC}:{' '}
                    {format(
                      new Date(
                        payment.date_confirmation.split('T')[0] + ' 00:00'
                      ),
                      'dd/MM/yyyy'
                    )}
                  </Typography>
                )}
              </div>
            )}
            {payment.status === 2 && (
              <div className='uk-margin-top'>
                <Typography>
                  {langPayment.rejected}: {payment.UserConfirm.name}
                </Typography>
                <Typography>
                  {langPayment.dateR}:{' '}
                  {format(
                    new Date(
                      payment.date_confirmation.split('T')[0] + ' 00:00'
                    ),
                    'dd/MM/yyyy'
                  )}
                </Typography>
              </div>
            )}
            {payment.Documents && payment.Documents.length > 0 && (
              <div className='uk-flex uk-flex-top uk-margin-top'>
                <img
                  src={FileIcon}
                  className='uk-margin-small-right'
                  alt='file'
                  style={{
                    marginTop: 15
                  }}
                />
                <div
                  style={{
                    paddingLeft: 15
                  }}
                >
                  {payment.Documents.map((document, index) => (
                    <a
                      key={index}
                      href={process.env.REACT_APP_API_URL + document.url}
                      target={'_blank'}
                      rel='noopener noreferrer'
                      className='uk-flex uk-flex-middle uk-margin-small-top'
                      style={{
                        textDecoration: 'underline'
                      }}
                    >
                      {document.title}
                    </a>
                  ))}
                </div>
              </div>
            )}
            {payment.Images && payment.Images.length > 0 && (
              <div>
                {payment.Images.map((image, index) => (
                  <img
                    key={index}
                    src={process.env.REACT_APP_API_URL + image.url}
                    alt='payment'
                    style={{
                      height: 90,
                      width: 90,
                      marginRight: 15,
                      objectFit: 'cover',
                      cursor: 'pointer'
                    }}
                    onClick={() => onOpenGallery(index + 1)}
                  />
                ))}
              </div>
            )}
            {/* {hasPermission('payment-update', 'or', id, 'project') && payment.status === 0 &&
                            <div>
                                <Typography color="secondary">
                                    {langPayment.important}
                                </Typography>

                                <div className="leo-button-container">
                                    <button className="leo-button-black" onClick={onOpenReject}>{langPayment.reject}</button>
                                    <button className="leo-button-orange" onClick={onOpenAccept}>{langPayment.accept}</button>
                                </div>
                            </div>
                        } */}
          </div>
        ) : (
          <div>{langPayment.noData}</div>
        )}
      </Modal>

      <Modal
        open={openGallery}
        onClose={onCloseGallery}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        container={myRef.current}
        classNames={{
          modal: 'customModalGalleryProject'
        }}
      >
        {payment && payment.Images && payment.Images.length > 0 && (
          <CustomGallery
            images={imagesGall}
            navigation={true}
            current={current}
          />
        )}
      </Modal>

      <Modal
        open={openAccept}
        onClose={onCloseAccept}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        classNames={{ modal: 'modalAccept' }}
      >
        <div className='leo-text-modal'>{langPayment.modalAccept}</div>
        <div className='uk-flex' style={{ width: '100%' }}>
          <button
            className='leo-button-black uk-margin-top'
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
            onClick={() => updatePayment(1)}
          >
            {langPayment.accept}
          </button>
        </div>
      </Modal>

      <Modal
        open={openReject}
        onClose={onCloseReject}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        classNames={{ modal: 'modalReject' }}
      >
        <div className='leo-text-modal'>{langPayment.modalReject}</div>
        <div className='uk-flex' style={{ width: '100%' }}>
          <button
            className='leo-button-black uk-margin-top'
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
            onClick={() => updatePayment(2)}
          >
            {langPayment.reject}
          </button>
        </div>
      </Modal>
    </StandardView>
  )
}
