import React, { useContext } from 'react'
import logo from '../../assets/images/diinco_colour.png'
import './styles/style.css'
import computerIcon from './assets/computer.svg'
import { LanguageContext } from '../../context/language/context'

export default function WorkInProgress() {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  return (
    <section className='cj-wip-section' style={{ height: '100vh' }}>
      <div className='uk-container'>
        <div className='uk-grid uk-child-width-1'>
          <div className='uk-grid uk-child-width-1-3 uk-margin-large-top'>
            <div>
              <img src={logo} alt='logo' />
            </div>
          </div>

          <div className='cj-wip-text-message dc-fill-yellow uk-margin-medium-top'>
            {lang.message}
          </div>

          <div className='uk-margin-medium-top'>
            <img
              src={computerIcon}
              className='cj-icon-computer-wip'
              alt='computer'
            />
          </div>
        </div>
      </div>
    </section>
  )
}
