import EN from '../languages/error.en.json'
import ES from '../languages/error.es.json'

export async function _handleResponse(response, verbose = false) {
  if (process.env.REACT_APP_ERROR_DEBUG === 'true') verbose = true
  else verbose = false
  if (response.status === 200) {
    response = await response.json()
    if (verbose) console.log(response)
    return response
  } else {
    let error = await response.json()
    if (verbose) console.error('Something went wrong!', { error })
    throw { error }
  }
}

export function _handleErrorMessage(lang = 'en', error) {
  const { code } = error
  let verbose = false
  if (process.env.REACT_APP_ERROR_DEBUG === 'true') verbose = true
  if (verbose) console.log({ lang, error })
  switch (code) {
    case 1000:
      if (lang === 'es') return ES[1000]
      else return EN[1000]

    case 2000:
      if (lang === 'es') return ES['2000']
      else return EN['2000']

    case 3000:
      if (lang === 'es') return ES['3000']
      else return EN['3000']

    case 4000:
      if (lang === 'es') return ES['4000']
      else return EN['4000']

    case 4001:
      if (lang === 'es') return ES['4001']
      else return EN['4001']

    case 4002:
      if (lang === 'es') return ES['4002']
      else return EN['4002']

    case 4003:
      if (lang === 'es') return ES['4003']
      else return EN['4003']

    case 4004:
      if (lang === 'es') return ES['4004']
      else return EN['4004']

    case 4005:
      if (lang === 'es') return ES['4005']
      else return EN['4005']

    case 5000:
      if (lang === 'es') return ES['5000']
      else return EN['5000']

    case 6000:
      if (lang === 'es') return ES['6000']
      else return EN['6000']

    case 7000:
      if (lang === 'es') return ES['7000']
      else return EN['7000']

    case 8001:
      if (lang === 'es') return ES['8001']
      else return EN['8001']

    case 8002:
      if (lang === 'es') return ES['8002']
      else return EN['8002']

    case 8003:
      if (lang === 'es') return ES['8003']
      else return EN['8003']

    case 8004:
      if (lang === 'es') return ES['8004']
      else return EN['8004']

    case 8005:
      if (lang === 'es') return ES['8005']
      else return EN['8005']

    case 8006:
      if (lang === 'es') return ES['8006']
      else return EN['8006']

    case 8007:
      if (lang === 'es') return ES['8007']
      else return EN['8007']

    case 8008:
      if (lang === 'es') return ES['8008']
      else return EN['8008']

    case 8009:
      if (lang === 'es') return ES['8009']
      else return EN['8009']
    case 8010:
      if (lang === 'es') return ES['8010']
      else return EN['8010']
    case 8011:
      if (lang === 'es') return ES['8011']
      else return EN['8011']
    case 8012:
      if (lang === 'es') return ES['8012']
      else return EN['8012']
    case 8013:
      if (lang === 'es') return ES['8013']
      else return EN['8013']
    case 8014:
      if (lang === 'es') return ES['8014']
      else return EN['8014']
    case 8015:
      if (lang === 'es') return ES['8015']
      else return EN['8015']
    case 8016:
      if (lang === 'es') return ES['8016']
      else return EN['8016']

    default:
      if (lang === 'es') return ES[1000]
      else return EN[1000]
  }
}
