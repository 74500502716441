export default function (state, action) {
  const { payload, type } = action
  switch (type) {
    case 'LOGIN':
      return {
        payload
      }

    case 'SET_COUNTRIES':
      return {
        ...state,
        countries: payload
      }

    case 'SET_COUNTRIES_UUID':
      return {
        ...state,
        UUIDS: {
          ...state.UUIDS,
          countries: payload
        }
      }

    case 'SET_STATES':
      return {
        ...state,
        states: payload
      }

    case 'SET_STATES_UUID':
      return {
        ...state,
        UUIDS: {
          ...state.UUIDS,
          states: payload
        }
      }

    case 'SET_CITIES':
      return {
        ...state,
        cities: payload
      }

    case 'SET_CITIES_UUID':
      return {
        ...state,
        UUIDS: {
          ...state.UUIDS,
          cities: payload
        }
      }

    case 'SET_SERVICES':
      return {
        ...state,
        services: payload
      }

    case 'SET_SERVICES_UUID':
      return {
        ...state,
        UUIDS: {
          ...state.UUIDS,
          services: payload
        }
      }

    default:
      return state
  }
}
