import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import './styles/style.css'

import { LanguageContext } from '../../context/language/context'
import Avatar from '../Avatar'
import Typography from '../Typography'
import Button from '../Button'
import PropTypes from 'prop-types'
export default function ProfessionalCard(props) {
  const { img, nickname, name, profession, city } = props
  const history = useHistory()

  const languageContext = useContext(LanguageContext)

  return (
    <div className='uk-width-1-1 dc-margin-yellow'>
      <div className='uk-flex uk-flex-center uk-margin-bottom uk-margin-top'>
        <Avatar alt={name} src={img} width={100} height={100} disableToolTip />
      </div>
      <Typography align={'center'}>{name}</Typography>
      <Typography align={'center'} color='secondary'>
        {profession}
      </Typography>
      <Typography align={'center'} color='secondary'>
        {city}
      </Typography>
      <div className='uk-flex uk-flex-center uk-margin-top'>
        <Button
          onClick={() => {
            history.push('/Profile/' + nickname)
          }}
          color='dark'
        >
          {languageContext.dictionary.profile}
        </Button>
      </div>
      <div></div>
    </div>
  )
}

ProfessionalCard.defaultProps = {
  img: '',
  nickname: '',
  name: '',
  profession: '',
  city: ''
}

ProfessionalCard.propTypes = {
  img: PropTypes.string,
  nickname: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  profession: PropTypes.string,
  city: PropTypes.string
}
