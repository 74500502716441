import React, { useContext, useState, useEffect } from 'react'
import 'react-responsive-modal/styles.css'
import { format } from 'date-fns'
import PropTypes from 'prop-types'

import { LanguageContext } from '../../../context/language/context'

export default function Card(props) {
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('../languages/en.json')
  } else {
    lang = require('../languages/es.json')
  }

  const [info, setInfo] = useState([])

  useEffect(() => {
    setInfo(props.task)
  }, [props])

  return (
    <>
      <div
        className={
          !info.isOwner
            ? 'oski-request-container'
            : 'oski-request-container-owner'
        }
      >
        <div className='oski-request-title'>{info.Solicitude?.title}</div>
        <div className='oski-request-users'>
          {/* <Avatar
						src={null}
						alt={'Ricardo Arévalos'}
					/> */}
          <div className='oski-request-users-text'>
            {!info?.isOwner
              ? lang.from +
                ' ' +
                info.Solicitude?.Users[0]?.name +
                ' ' +
                lang.toMe
              : info.Solicitude?.Users.length > 0
              ? lang.fromMe + lang.to + ' ' + info.Solicitude?.Users[0]?.name
              : lang.fromMe + lang.to + ' ' + lang.otherUser}
          </div>
        </div>
        <div className='oski-request-date'>
          {info?.Solicitude?.createdAt &&
            format(new Date(info.Solicitude.createdAt), 'dd/MM/yyyy HH:mm')}
        </div>
      </div>
    </>
  )
}

Card.defaultProps = {
  task: {}
}

Card.propTypes = {
  task: PropTypes.object
}
