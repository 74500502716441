import React, { useContext, useEffect, useState } from 'react'
import './styles/style.css'

import _Utils from '../../context/utils'
import _Services from '../../context/services'
import _Auth from '../../context/auth'

import { CDropZoneCoverImage } from '../../components/DropZoneCoverImage'
import { CTagsInput } from '../../components/TagsInput'
import ImageThumbsContainer from '../../components/ImageThumbsContainer'
import { useHistory } from 'react-router-dom'
import { LanguageContext } from '../../context/language/context'
import { _handleErrorMessage } from '../../functions/error.functions'
import { Prompt } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { serverValidation } from '../../functions/validation.functions'
import { serialize } from '../../functions/validation.functions'
import { CTextInput } from '../../components/TextInput'
import Button from '../../components/Button'
import { compressFilesInFormData } from '../../functions/files.functions'

export default function AlbumForm() {
  const { toggleLoader, enqueueNotification } = useContext(_Utils.Context)
  const { token, self } = useContext(_Auth.Context)
  const history = useHistory()

  const languageContext = useContext(LanguageContext)

  const { checkUpdates } = useContext(_Services.Context)

  useEffect(() => {
    async function Init() {
      await toggleLoader(true)
      await checkUpdates()
      await toggleLoader(false)
    }
    Init()
  }, [])

  useEffect(() => {}, [token])

  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])

  const {
    reset,
    setValue,
    getValues,
    control,
    setError,
    handleSubmit,
    formState
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: '',
      description: '',
      tags: [],
      files: []
    }
  })

  useEffect(() => {
    if (formState.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
    } else {
      window.onbeforeunload = null
    }
  }, [formState.isDirty])

  const [disableButton, setDisableButton] = useState(false)

  async function onSubmit(data) {
    await toggleLoader(true)
    setDisableButton(true)
    try {
      let formData = serialize(data, {
        dotsForObjectNotation: true,
        noArrayNotation: true,
        noFilesWithArrayNotation: true
      })
      formData = await compressFilesInFormData(formData, 'files')
      let response = await fetch(process.env.REACT_APP_API_URL + 'albums', {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
          Accept: 'multipart/form-data'
        },
        body: formData
      })
      await serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        async () => {
          reset(data)
          enqueueNotification(languageContext.dictionary['success'], 'success')
          await toggleLoader(false)
          setDisableButton(false)
          history.push('/Profile/' + self?.nickname)
        },
        async (error) => {
          setDisableButton(false)
          await toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      toggleLoader(false)
      setDisableButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    toggleLoader(false)
  }

  return (
    <div className='uk-container'>
      <div className='uk-margin-medium-top uk-width-1-1 dc-project-form-title'>
        {languageContext.dictionary['album']}
      </div>
      <div className='uk-width-1-1 uk-margin-small-top dc-separator dc-bg-yellow'></div>
      <div className='uk-margin-medium-top uk-margin-medium-bottom uk-width-1-1 uk-flex uk-flex-column'>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Prompt
            when={formState.isDirty}
            message={JSON.stringify({
              title: languageContext.dictionary.discard,
              message: languageContext.dictionary.areYouSure,
              accept: languageContext.dictionary.accept,
              cancel: languageContext.dictionary.cancel
            })}
          />
          <CTextInput
            label={languageContext.dictionary.title}
            name='title'
            control={control}
            maxLength={45}
            required
          />
          <CTextInput
            label={languageContext.dictionary.desc}
            name='description'
            type='textarea'
            control={control}
            rows={2}
            maxLength={120}
            charCount
          />

          <div className='uk-grid'>
            <div className='uk-width-3-5'>
              {/* <DropZoneImage /> */}
              <CDropZoneCoverImage
                name={'files'}
                control={control}
                required
                ContainerClasses='diinco-project-form-dropzone-container cj-height-dropzone'
                ImageClasses='diinco-project-form-dropzone-image'
                onChange={() => forceUpdate()}
              />

              <ImageThumbsContainer
                control={control}
                thumbs={getValues('files')}
                containerStyle='diinco-thumbs-container-project-form'
                thumbStyle='diinco-thumbs-style-project-form'
                deleteButtonStyle='diinco-thumbs-delete-project-form'
                handleRemove={(event, file, index) => {
                  const newValue = getValues('files').filter(
                    (o, i) => i !== index
                  )
                  setValue('files', newValue)
                  forceUpdate()
                }}
              />
            </div>
            <div className='uk-width-2-5'>
              <CTagsInput
                name='tags'
                control={control}
                classes={{
                  tagsContainer: 'cj-tags-container'
                }}
              />
            </div>
          </div>
          <div className='uk-flex uk-flex-between'>
            <Button
              onClick={() => {
                history.goBack()
              }}
              color={'grey'}
            >
              {languageContext.dictionary.cancel}
            </Button>
            <Button
              color='secondary'
              centered={true}
              type='submit'
              disabled={disableButton}
            >
              {languageContext.dictionary.save}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
