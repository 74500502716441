import * as React from 'react'
import PropTypes from 'prop-types'
import './styles/style.css'
import Typography from '../Typography'
import { Link } from 'react-router-dom'

function initials(name) {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')
  let initials = name ? [...name.matchAll(rgx)] || [] : []
  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase()
  return name ? initials : 'U'
}

/**
 * Este componente detecta si el texto de una celda del datagrid se corta (ej. Fernando Adri...) y muestra un popper con el valor completo.
 */
function Avatar({
  alt,
  src,
  size,
  width,
  height,
  border,
  total,
  fontSize,
  variant,
  disableToolTip,
  style,
  className,
  tooltip,
  link,
  ...rest
}) {
  // if(checkIfImageExists(src)){
  //     return (
  //         // <AvatarMUI alt={alt} src={src ?? null} sx={{ width: width ?? 35, height: height ?? 35, fontSize: fontSize ?? 18 }}>{iniciales(alt)}</AvatarMUI>
  //         <img
  //             className="member-image dc-img-cover"
  //             src={src}
  //             data-uk-tooltip={
  //                 alt
  //             }
  //         />
  //     );
  // }
  // else{

  // }
  const [, setImage] = React.useState(null)
  const [imageExists, setImageExists] = React.useState(false)

  React.useEffect(() => {
    const img = new Image()
    if (src) {
      img.src = process.env.REACT_APP_API_URL + src
      if (img.complete) {
        setImageExists(true)
      } else {
        img.onload = () => {
          setImageExists(true)
        }

        img.onerror = () => {
          setImageExists(false)
        }
      }
      setImage(img)
    }
  }, [src])

  // var avatarSize = (width && height) ?
  // {
  //     width,
  //     height
  // }
  // :
  // {}

  // var avatarFontSize = {
  //     fontSize
  // }

  switch (size) {
    case 'small':
      variant = 'body2'
      break

    case 'medium':
      variant = 'h5'
      break

    case 'large':
      variant = 'h4'
      break

    case 'x-large':
      variant = 'h1'
      break

    default:
      break
  }

  const content = (
    <div
      className={[
        'member-avatar-wrapper',
        'member-avatar-wrapper-size-' + size.toLowerCase(),
        border ? 'member-avatar-wrapper-border' : '',
        className ?? ''
        // theme !== "default" && "cj-input-theme-" + theme,
      ].join(' ')}
      {...rest}
      style={{ width, height, ...style }}
      data-uk-tooltip={
        tooltip ??
        (!disableToolTip
          ? alt
            ? 'title: ' + alt + '; delay: 200'
            : 'User; delay: 200'
          : '')
      }
    >
      {imageExists ? (
        <div className='avatar-img-wrapper' style={{ width, height }}>
          <img
            className='avatar-img'
            src={process.env.REACT_APP_API_URL + src}
            alt='avatar'
          />
        </div>
      ) : (
        <div className={'avatar-initials-wrapper'} style={{ width, height }}>
          <div className='avatar-initials'>
            <Typography
              disableMargin
              style={{ fontSize }}
              variant={variant}
              color='secondary'
              theme='dark'
            >
              {total ? <>+{total}</> : initials(alt)}
            </Typography>
          </div>
        </div>
      )}
    </div>
  )

  return link ? <Link to={link}>{content}</Link> : <div>{content}</div>
}

Avatar.defaultProps = {
  src: null,
  alt: null,
  size: 'small',
  width: null,
  height: null,
  border: false,
  fontSize: null,
  variant: 'body',
  disableToolTip: false,
  style: {},
  className: '',
  total: null,
  tooltip: null,
  link: null
}

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'x-large']),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.string,
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle',
    'subtitle2',
    'body',
    'body2'
  ]),
  border: PropTypes.bool,
  disableToolTip: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  total: PropTypes.number,
  tooltip: PropTypes.string,
  link: PropTypes.string
}

export default Avatar
