import React from 'react'

//SWIPER JS
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'
import './styles/style.css'
import slide1 from '../../assets/1.jpg'
import slide2 from '../../assets/2.jpg'
import slide3 from '../../assets/3.jpg'
import slide4 from '../../assets/4.jpg'
import slide5 from '../../assets/5.jpg'

SwiperCore.use([Navigation, Pagination, Autoplay])

// import slides
export default function Slider() {
  return (
    <Swiper
      className='slider'
      navigation
      pagination
      loop={true}
      autoplay={{
        delay: 6000,
        disableOnInteraction: false
      }}
    >
      <SwiperSlide>
        <img
          src={slide1}
          className={'slide-img'}
          alt='slide1'
          style={{
            maxHeight: '2000px',
            minHeight: '600px',
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={slide2}
          alt='slide2'
          style={{
            maxHeight: '2000px',
            minHeight: '600px',
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={slide3}
          alt='slide3'
          style={{
            maxHeight: '2000px',
            minHeight: '600px',
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={slide4}
          alt='slide4'
          style={{
            maxHeight: '2000px',
            minHeight: '600px',
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={slide5}
          alt='slide5'
          style={{
            maxHeight: '2000px',
            minHeight: '600px',
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        />
      </SwiperSlide>
    </Swiper>
  )
}
