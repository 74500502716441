const selectStyle = {
  menu: (provided, state) => ({
    ...provided,
    color: state.selectProps.menuColor,
    padding: 10
  }),
  //Select style
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: '2px solid ' + (state.isFocused ? '#fbb857' : '#707070'),
    // This line disable the blue border
    boxShadow: state.isFocused ? '#707070' : 0,
    '&:hover': {
      // borderBottom: '2px solid #707070'
      border: '2px solid ' + (state.isFocused ? '#fbb857' : '#707070')
    },
    borderRadius: '5px'
  }),
  //Options style
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected
      ? '#ffffff'
      : state.isFocused
      ? '#ffffff'
      : '#6b6e6f',
    backgroundColor: state.isSelected
      ? '#f36f39'
      : state.isFocused
      ? '#cbcbcb'
      : undefined
  }),
  input: (provided) => ({
    ...provided,
    color: '#ffffff',
    caretColor: '#ffffff'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#cbcbcb',
    opacity: '0.5'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#ffffff'
  }),
  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: '#707070'
    }
  },
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#fbb857'
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    ':hover': {
      backgroundColor: '#fbb857',
      color: 'white',
      cursor: 'pointer'
    }
  }),

  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  })
}

const selectStandardStyle = {
  menu: (provided, state) => ({
    ...provided,
    color: state.selectProps.menuColor,
    padding: 10
  }),
  //Select style
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: '2px solid ' + (state.isFocused ? '#fbb857' : '#707070'),
    // This line disable the blue border
    boxShadow: state.isFocused ? '#707070' : 0,
    '&:hover': {
      borderBottom: '2px solid ' + (state.isFocused ? '#fbb857' : '#707070')
    },
    '&:has( > .cj-react-select__input-container)': {
      borderBottom:
        '2px solid ' +
        (state.isFocused
          ? 'rgb(201, 70, 67) !important'
          : 'rgb(201, 70, 67) !important')
    },
    borderRadius: '0'
  }),
  container: (provided) => ({
    ...provided,
    '& + label': {
      left: '0px !important'
    }
  }),
  //Options style
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected
      ? '#ffffff'
      : state.isFocused
      ? '#ffffff'
      : '#6b6e6f',
    backgroundColor: state.isSelected
      ? '#f36f39'
      : state.isFocused
      ? '#cbcbcb'
      : undefined
  }),
  input: (provided) => ({
    ...provided,
    color: '#ffffff',
    caretColor: '#ffffff'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#ffffff',
    opacity: '0.5'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#707070'
  }),
  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: '#707070'
    }
  },
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#fbb857'
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    ':hover': {
      backgroundColor: '#fbb857',
      color: 'white',
      cursor: 'pointer'
    }
  }),

  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0
  })
}

const selectLightStyle = {
  menu: (provided, state) => ({
    ...provided,
    color: state.selectProps.menuColor,
    padding: 10
  }),
  //Select style
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: '2px solid ' + (state.isFocused ? '#fbb857' : '#707070'),
    // This line disable the blue border
    boxShadow: state.isFocused ? '#707070' : 0,
    '&:hover': {
      borderBottom: '2px solid ' + (state.isFocused ? '#fbb857' : '#707070')
    },
    '&:has( > .cj-react-select__input-container)': {
      borderBottom:
        '2px solid ' +
        (state.isFocused
          ? 'rgb(201, 70, 67) !important'
          : 'rgb(201, 70, 67) !important')
    },
    borderRadius: '0'
  }),
  container: (provided) => ({
    ...provided,
    '& + label': {
      left: '0px !important'
    }
  }),
  //Options style
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected
      ? '#ffffff'
      : state.isFocused
      ? '#ffffff'
      : '#6b6e6f',
    backgroundColor: state.isSelected
      ? '#f36f39'
      : state.isFocused
      ? '#cbcbcb'
      : undefined
  }),
  input: (provided) => ({
    ...provided,
    color: '#707070',
    caretColor: '#707070'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#707070',
    opacity: '0.5'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#707070'
  }),
  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: '#cbcbcb'
    }
  },
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#707070'
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    ':hover': {
      backgroundColor: '#fbb857',
      color: 'white',
      cursor: 'pointer'
    }
  }),

  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0
  })
}

export { selectStyle, selectStandardStyle, selectLightStyle }
