import React, { useRef, useState, useContext } from 'react'
import { IconButton } from '../IconButton'
import _Utils from '../../context/utils'
import _Auth from '../../context/auth'
import { LanguageContext } from '../../context/language/context'
import Modal from 'react-responsive-modal'
import Typography from '../Typography'
import Divider from '../Divider'
import PropTypes from 'prop-types'

import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share'

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon,
  WhatsappIcon
} from 'react-share'

export const SocialSharer = (props) => {
  const { route, id, url, title } = props
  const modalRef = useRef(null)
  const [open, setOpen] = useState(false)
  const { enqueueNotification } = useContext(_Utils.Context)
  const { theme } = useContext(_Auth.Context)

  const { userLanguage } = useContext(LanguageContext)
  var lang
  if (userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  async function copySecret() {
    navigator.clipboard.writeText(
      id ? process.env.REACT_APP_URL + route + id : ''
    )
    enqueueNotification(lang.copied, 'success')
  }

  return (
    <div ref={modalRef}>
      <IconButton
        icon='share'
        iconColor={'secondary'}
        variant={'borderless'}
        onClick={() => setOpen(true)}
      ></IconButton>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        center
        container={modalRef.current}
        closeIcon={<IconButton icon='close' component='div' />}
        classNames={
          theme !== 'dark'
            ? { modal: 'customModalCRM-dark customShare' }
            : { modal: 'customModalCRM-light customShare' }
        }
      >
        <Typography variant='h6' align={'center'} color={'secondary'}>
          {lang.share}
        </Typography>
        <Divider className='' />
        <FacebookShareButton url={url} hashtag='#DIINCO'>
          <FacebookIcon size={30} />
        </FacebookShareButton>
        <WhatsappShareButton url={url} title={title}>
          <WhatsappIcon size={30} />
        </WhatsappShareButton>
        <FacebookMessengerShareButton
          url={url}
          appId={process.env.REACT_APP_FACEBOOK_ID}
        >
          <FacebookMessengerIcon size={30} />
        </FacebookMessengerShareButton>
        <EmailShareButton url={url} subject={'DIINCO'}>
          <EmailIcon size={30} />
        </EmailShareButton>
        <TwitterShareButton url={url} hashtags={['Diinco']}>
          <TwitterIcon size={30} />
        </TwitterShareButton>
        <Divider className='uk-margin-small-bottom' />
        <Typography
          variant={'body2'}
          color='secondary'
          style={{ cursor: 'pointer' }}
          uk-tooltip={'title: ' + lang.clickCopy + '; pos: bottom'}
          onClick={copySecret}
        >
          {url}
        </Typography>
      </Modal>
    </div>
  )
}

SocialSharer.defaultProps = {
  route: '',
  id: 0,
  url: '',
  title: ''
}

SocialSharer.propTypes = {
  route: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  url: PropTypes.string.isRequired,
  title: PropTypes.string
}
