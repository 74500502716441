import React, { useContext, useEffect, useState, useRef } from 'react'
import _Auth from '../../context/auth'
import _Utils from '../../context/utils'
import './styles/style.css'

import check_gray from './assets/img/checkmark_gray.svg'
import check_yellow from './assets/img/checkmark_yellow.svg'

import { LanguageContext } from '../../context/language/context'
import Typography from '../../components/Typography'
import Button from '../../components/Button'
import { Link, useHistory } from 'react-router-dom'
import { serverValidation } from '../../functions/validation.functions'
import { _handleErrorMessage } from '../../functions/error.functions'
import Modal from 'react-responsive-modal'
import { IconButton } from '../../components/IconButton'
import Card from '../../components/Card'
import Divider from '../../components/Divider'
import PropTypes from 'prop-types'

export default function Subscription({ message }) {
  let history = useHistory()
  const { token, self } = useContext(_Auth.Context)
  const { enqueueNotification } = useContext(_Utils.Context)
  const [products, setProducts] = useState([])

  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  //   const [prices, setPrices] = useState([])

  const onClose = () => setOpen(false)

  async function Init() {
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'stripe/config',
        {
          method: 'GET',
          credentials: 'include',
          mode: 'cors'
        }
      )
      serverValidation(
        response,
        null,
        languageContext.userLanguage,
        (data) => {
          setProducts(data)
          //   setPrices(data[0].Plans)
          setLoading(false)
        },
        (error) => {
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
          history.push('/Dashboard')
        }
      )
    } catch (error) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
      history.push('/Dashboard')
    }
  }

  useEffect(() => {
    if (!token) return
    Init()
  }, [token])

  async function createCheckoutSession(priceId) {
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'stripe/create-checkout-session',
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            priceId
          })
        }
      )
      serverValidation(
        response,
        null,
        languageContext.userLanguage,
        (data) => {
          window.location.href = data.url
        },
        (error) => {
          enqueueNotification(
            _handleErrorMessage(languageContext.userLanguage, error.error),
            'error'
          )
          history.push('/Dashboard')
        }
      )
    } catch (error) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  const [openLogin, setOpenLogin] = useState(false)
  const [openAlready, setOpenAlready] = useState(false)
  const myRef = useRef(null)

  //   const [subscriptionData, setSubscriptionData] = useState(null)

  //   const createSubscription = async (priceId) => {
  //     const { subscriptionId, clientSecret } = await fetch(
  //       process.env.REACT_APP_API_URL + 'stripe/subscription',
  //       {
  //         method: 'POST',
  //         credentials: 'include',
  //         mode: 'cors',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           priceId
  //         })
  //       }
  //     ).then((r) => r.json())

  //     setSubscriptionData({ subscriptionId, clientSecret })
  //   }

  //   if (subscriptionData) {
  //     toggleLoader(false)
  //     return (
  //       <Redirect
  //         to={{
  //           pathname: '/subscribe',
  //           state: subscriptionData
  //         }}
  //       />
  //     )
  //   }

  //   const [disableButton, setDisableButton] = useState(false)
  //   const handleOpenLogin = async () => {
  //     if (!token) {
  //       setOpenLogin(true)
  //     } else {
  //       if (self?.Subscriptions?.length) return setOpenAlready(true)
  //       await toggleLoader(true)
  //       setDisableButton(true)
  //       try {
  //         let response = await fetch(
  //           process.env.REACT_APP_API_URL + 'stripe/create-free-trial',
  //           {
  //             method: 'POST',
  //             credentials: 'include',
  //             mode: 'cors',
  //             headers: {
  //               Accept: 'multipart/form-data'
  //             }
  //           }
  //         )
  //         await serverValidation(
  //           response,
  //           null,
  //           languageContext.userLanguage,
  //           async (dataR) => {
  //             // enqueueNotification(
  //             //     languageContext.dictionary["success"],
  //             //     "success"
  //             // );
  //             // setTimeout(() => {}, 1000);
  //             // await toggleLoader(false);
  //             // setDisableButton(false);
  //             setDisableButton(false)
  //             await toggleLoader(false)

  //             reloadData(() => {
  //               history.push(dataR.url)
  //             })
  //           },
  //           async (error) => {
  //             setDisableButton(false)
  //             await toggleLoader(false)
  //             enqueueNotification(
  //               _handleErrorMessage(languageContext?.userLanguage, error.error),
  //               'error'
  //             )
  //           }
  //         )
  //       } catch (error) {
  //         toggleLoader(false)
  //         setDisableButton(false)
  //         enqueueNotification(
  //           _handleErrorMessage(languageContext?.userLanguage, error),
  //           'error'
  //         )
  //       }
  //       toggleLoader(false)
  //     }
  //   }

  return (
    <div>
      <div className='uk-container' ref={myRef}>
        <div className='uk-overflow-auto'>
          {message && (
            <div className='uk-flex uk-flex-between uk-flex-middle uk-margin-xlarge-top'>
              <Typography align={'center'} color={'secondary'}>
                {lang.upgrade}
              </Typography>
              {history.length > 0 && (
                <Button
                  variant='text'
                  disableMargin
                  onClick={() => history.goBack()}
                >
                  Regresar
                </Button>
              )}
            </div>
          )}
          <table
            className={
              'uk-table uk-text-center dc-text-white dc-text-uppercase' +
              (message ? '' : ' uk-margin-xlarge-top')
            }
          >
            <thead className=''>
              <tr>
                <th className='uk-width-1-4 uk-text-center dc-text-uppercase uk-text-middle'>
                  {/* <div className="dc-height-custom-4 dc-bg-orange uk-card-small uk-card-default uk-card-body uk-light"> */}
                  <Card
                    variant={'filled'}
                    color='grey'
                    style={{ height: '61px' }}
                  >
                    <Typography
                      align={'center'}
                      variant='subtitle'
                      theme='dark'
                      disableMargin
                    >
                      {lang['service']}
                    </Typography>
                  </Card>
                  {/* </div> */}
                </th>
                <th className='uk-width-1-4 uk-text-center dc-text-uppercase uk-text-middle'>
                  {/* <div className="dc-height-custom-4 dc-bg-dark-gray uk-card-small uk-card-default uk-card-body uk-light"> */}
                  <Card variant={'filled'} color='primary'>
                    <Typography
                      align={'center'}
                      variant='subtitle'
                      theme='dark'
                      disableMargin
                    >
                      DIINCO FREE
                      <br />
                      GRATIS
                    </Typography>
                  </Card>
                  {/* </div> */}
                </th>
                <th className='uk-width-1-4 uk-text-center dc-text-uppercase uk-text-middle'>
                  {/* <div className="dc-height-custom-4  dc-bg-yellow uk-card-small uk-card-default uk-card-body uk-light"> */}
                  <Card variant={'filled'} color='secondary'>
                    <Typography
                      align={'center'}
                      variant='subtitle'
                      disableMargin
                    >
                      DIINCO PRO
                      <br />
                      {lang.freeLimited}
                    </Typography>
                  </Card>
                  {/* </div> */}
                </th>
              </tr>
            </thead>
            <tfoot>
              <tr className='uk-margin-top'>
                <td colSpan='4'>
                  {lang['consult']}
                  <a>{lang['terms']}</a>
                  {lang['eachMebreship']}
                </td>
              </tr>
            </tfoot>
            <tbody className='uk-text-default'>
              <tr>
                <td>
                  <Typography align={'center'}>
                    {lang.personalCalendar}
                  </Typography>
                </td>
                <td>
                  <img
                    src={check_gray}
                    alt='check'
                    className='dc-width-xsmall'
                  />
                </td>
                <td>
                  <img
                    src={check_yellow}
                    alt='check'
                    className='dc-width-xsmall'
                  />
                </td>
                {/* <td>
                                    <img
                                        src={check_yellow}
                                        className="dc-width-xsmall"
                                    />
                                </td> */}
              </tr>

              <tr>
                <td>
                  <Typography align={'center'}>
                    {lang.profilePersonalization}
                  </Typography>
                </td>
                <td>
                  <img
                    src={check_gray}
                    alt='check'
                    className='dc-width-xsmall'
                  />
                </td>
                <td>
                  <img
                    src={check_yellow}
                    alt='check'
                    className='dc-width-xsmall'
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Typography align={'center'}>{lang.crm}</Typography>
                </td>
                <td></td>
                <td>
                  <img
                    src={check_yellow}
                    alt='check'
                    className='dc-width-xsmall'
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Typography align={'center'}>{lang.prospects}</Typography>
                </td>
                <td></td>
                <td>
                  <img
                    src={check_yellow}
                    alt='check'
                    className='dc-width-xsmall'
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Typography align={'center'}>{lang.expedient}</Typography>
                </td>
                <td></td>
                <td>
                  <img
                    src={check_yellow}
                    alt='check'
                    className='dc-width-xsmall'
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Typography align={'center'}>{lang.projects}</Typography>
                </td>
                <td></td>
                <td>
                  <Typography align={'center'}>{lang.up6}</Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography align={'center'}>{lang.storage}</Typography>
                </td>
                <td>
                  <Typography align={'center'}>{lang.limited}</Typography>
                </td>
                <td>
                  <Typography align={'center'}>10 Gb</Typography>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>
                  <Button
                    onClick={async () => {
                      //   await toggleLoader(true)
                      //   createSubscription(prices[0]?.id)
                      setOpen(true)
                    }}
                    disabled={loading}
                    color='secondary'
                    style={{ color: 'black' }}
                  >
                    {lang.buy}
                  </Button>
                  {/* <Button disabled={disableButton} onClick={handleOpenLogin}>
                    {lang.freeTrial}
                  </Button> */}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        open={open}
        onClose={onClose}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        classNames={{ modal: 'custom-modal-buy' }}
      >
        <div data-uk-grid>
          <div className='uk-width-1 uk-margin-medium-top'>
            <Typography color={'secondary'} align='center'>
              {lang.choose}
            </Typography>
            {self?.trial === true && (
              <div className='uk-width-1 uk-flex uk-flex-center'>
                <Typography theme='dark'>{lang.trial}</Typography>
              </div>
            )}
          </div>

          <div className='uk-flex uk-width-1 uk-flex-center uk-margin-bottom uk-margin-top'>
            <div data-uk-grid>
              {products &&
                products.length &&
                products[0].Plans &&
                products[0].Plans.length &&
                products[0].Plans.map((plan, index) => (
                  <div key={index}>
                    <Card color={index % 2 === 0 ? 'primary' : 'secondary'}>
                      <Typography
                        align='center'
                        color={'secondary'}
                        className={'uk-margin-medium-top'}
                        style={{
                          textTransform: 'uppercase'
                        }}
                      >
                        PLAN {products[0].name}
                      </Typography>

                      <Typography theme='dark' disableMargin align={'center'}>
                        ${plan.amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')} /{' '}
                        {lang[plan.period]}
                      </Typography>

                      <Typography
                        variant={'body2'}
                        theme='dark'
                        style={{ fontSize: 10 }}
                      >
                        {lang.enjoy}
                      </Typography>

                      <div className='uk-flex uk-width-1 uk-flex-center'>
                        <Button
                          onClick={() => createCheckoutSession(plan.priceId)}
                          color={index % 2 === 0 ? 'primary' : 'secondary'}
                          style={{ color: 'black' }}
                          className='uk-margin-medium-top'
                        >
                          {lang.buy}
                        </Button>
                      </div>
                    </Card>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openLogin}
        onClose={() => setOpenLogin(false)}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        container={myRef.current}
        classNames={{ modal: 'customModalPhase' }}
      >
        <div className='uk-padding uk-flex uk-flex-center uk-flex-column'>
          <Typography align={'center'} variant='h6' color={'secondary'}>
            {lang.log}
          </Typography>
          <Link
            to={{
              pathname: '/login',
              state: { nextRoute: '/subscription' }
            }}
            style={{ textDecoration: 'none' }}
          >
            <Button className='uk-flex uk-margin-top'>
              {languageContext.dictionary.login}
            </Button>
          </Link>
          <div className='uk-flex uk-flex-middle' style={{ gap: 5 }}>
            <div className='uk-width-1-2'>
              <Divider></Divider>
            </div>
            <Typography>{lang.o}</Typography>
            <div className='uk-width-1-2'>
              <Divider></Divider>
            </div>
          </div>
          <Link
            to='/signup-info' //
            style={{ textDecoration: 'none' }}
          >
            <Button className='uk-flex uk-margin-medium-top'>
              {lang.signUp}
            </Button>
          </Link>
        </div>
      </Modal>

      <Modal
        open={openAlready}
        onClose={() => setOpenAlready(false)}
        center
        closeIcon={<IconButton icon='close' component='div' />}
        container={myRef.current}
        classNames={{ modal: 'customModalPhase' }}
      >
        <div className='uk-padding uk-flex uk-flex-center uk-flex-column'>
          <Typography align={'center'} variant='h6'>
            {lang.already}
          </Typography>
        </div>
      </Modal>
    </div>
  )
}

Subscription.defaultProps = {
  message: null
}

Subscription.propTypes = {
  message: PropTypes.string
}
