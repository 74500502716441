import React, { useContext, useEffect, useState } from 'react'
import './styles/style.css'

import _Utils from '../../context/utils'
import _Services from '../../context/services'
import _Auth from '../../context/auth'

import { CDropZoneCoverImage } from '../../components/DropZoneCoverImage'
import { CTagsInput } from '../../components/TagsInput'
import ImageThumbsContainer from '../../components/ImageThumbsContainer'
import { useHistory } from 'react-router-dom'
import { LanguageContext } from '../../context/language/context'
import { _handleErrorMessage } from '../../functions/error.functions'
import { Prompt } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  serverValidation,
  validationRules
} from '../../functions/validation.functions'
import { serialize } from '../../functions/validation.functions'
import { CTextInput } from '../../components/TextInput'
import Button from '../../components/Button'
import StandardView from '../../layouts/StandardView'
import { CSelectInput } from '../../components/SelectInput'
import { compressFilesInFormData } from '../../functions/files.functions'
import PlansModal from '../../components/AlbumPlansModal'

export const AlbumCreate = () => {
  const { toggleLoader, enqueueNotification } = useContext(_Utils.Context)
  const { token, features, reloadData } = useContext(_Auth.Context)
  const history = useHistory()

  const languageContext = useContext(LanguageContext)
  const { userLanguage } = useContext(LanguageContext)
  var lang
  if (userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  const [loading, setLoading] = useState(false)

  const [disabledLocation, setDisabledLocation] = useState(true)
  const [disabledState, setDisabledState] = useState(true)
  const [disabledCity, setDisabledCity] = useState(true)
  const {
    countries,
    states,
    cities,
    services,
    checkUpdates,
    setSelectedState,
    setSelectedCountry
  } = useContext(_Services.Context)

  const servicesOptions = services.map((service) => ({
    value: service.id,
    label: service.title
  }))

  useEffect(() => {
    async function Init() {
      await toggleLoader(true)
      await checkUpdates()
      await toggleLoader(false)
    }
    Init()
  }, [])

  useEffect(() => {}, [token])

  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])

  const {
    reset,
    setValue,
    getValues,
    control,
    setError,
    handleSubmit,
    formState
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: '',
      description: '',
      tags: [],
      files: [],
      country: null,
      state: undefined,
      city: undefined,
      street: undefined,
      int_number: undefined,
      ext_number: undefined,
      neightborhood: undefined,
      postal_code: undefined,
      services: null
    }
  })

  useEffect(() => {
    if (formState.isDirty) {
      window.onbeforeunload = function () {
        return ''
      }
    } else {
      window.onbeforeunload = null
    }
  }, [formState.isDirty])

  const [disableButton, setDisableButton] = useState(false)

  async function onSubmit(data) {
    await toggleLoader(true)
    setDisableButton(true)
    try {
      let formData = serialize(data, {
        dotsForObjectNotation: true,
        noArrayNotation: true,
        noFilesWithArrayNotation: true
      })

      formData = await compressFilesInFormData(formData, 'files')

      let response = await fetch(process.env.REACT_APP_API_URL + 'albums', {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
          Accept: 'multipart/form-data'
        },
        body: formData
      })
      await serverValidation(
        response,
        setError,
        languageContext.userLanguage,
        async () => {
          reset(data)
          enqueueNotification(languageContext.dictionary['success'], 'success')
          setTimeout(() => {}, 1000)
          await toggleLoader(false)
          setDisableButton(false)
          reloadData(() => {
            history.push('/Albums/Admin')
          })
        },
        async (error) => {
          if (error?.error?.code === 8011) {
            onOpenPlansModal()
          }
          setDisableButton(false)
          await toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
        }
      )
    } catch (error) {
      toggleLoader(false)
      setDisableButton(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
    toggleLoader(false)
  }

  const countriesOptions = countries.map((country) => ({
    value: country,
    label: country.name
  }))

  const statesOptions = states.map((state) => ({
    value: state,
    label: state.name
  }))

  const citiesOptions = cities.map((city) => ({
    value: city,
    label: city.name
  }))

  const [count, setCount] = useState(0)

  async function init() {
    try {
      toggleLoader(true)
      let response = await fetch(
        process.env.REACT_APP_API_URL + 'albums/count',
        {
          method: 'GET',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      await serverValidation(
        response,
        null,
        languageContext.userLanguage,
        (data) => {
          toggleLoader(false)
          setLoading(false)
          setCount(data)
        },
        async (error) => {
          toggleLoader(false)
          enqueueNotification(
            _handleErrorMessage(languageContext?.userLanguage, error.error),
            'error'
          )
          history.push('/dashboard')
        }
      )
    } catch (error) {
      toggleLoader(false)
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
      history.push('/dashboard')
    }
    toggleLoader(false)
  }

  useEffect(() => {
    if (token) {
      init()
    }
  }, [token])

  useEffect(() => {
    if (count) {
      if (features['profile-video'] === true) {
        setProplan(true)
      }
    }
  }, [count])

  const [proplan, setProplan] = useState(false)

  const [openPlansModal, setOpenPlansModal] = useState(false)
  function onOpenPlansModal() {
    setOpenPlansModal(true)
  }
  function onClosePlansModal() {
    setOpenPlansModal(false)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onError = () => {
    enqueueNotification(
      _handleErrorMessage(languageContext?.userLanguage, { code: 1000 }),
      'error'
    )
  }

  return (
    <StandardView
      loading={loading}
      title={lang.title}
      links={[
        {
          link: '/Dashboard',
          title: languageContext.dictionary.dashboard
        },
        {
          link: '/Albums/Admin',
          title: languageContext.dictionary.albumsManagement
        }
      ]}
    >
      <div className='uk-margin-medium-top uk-margin-medium-bottom uk-width-1-1 uk-flex uk-flex-column'>
        <form onSubmit={handleSubmit(onSubmit, onError)} noValidate>
          <Prompt
            when={formState.isDirty}
            message={JSON.stringify({
              title: languageContext.dictionary.discard,
              message: languageContext.dictionary.areYouSure,
              accept: languageContext.dictionary.accept,
              cancel: languageContext.dictionary.cancel
            })}
          />
          <div data-uk-grid>
            <div className='uk-width-1'>
              {/* <DropZoneImage /> */}
              <CDropZoneCoverImage
                name={'files'}
                control={control}
                required
                ContainerClasses='diinco-project-form-dropzone-container cj-height-dropzone'
                ImageClasses='diinco-project-form-dropzone-image'
                onChange={() => forceUpdate()}
              />

              <ImageThumbsContainer
                control={control}
                thumbs={getValues('files')}
                containerStyle='diinco-thumbs-container-project-form'
                thumbStyle='diinco-thumbs-style-project-form'
                deleteButtonStyle='diinco-thumbs-delete-project-form'
                handleRemove={(event, file, index) => {
                  const newValue = getValues('files').filter(
                    (o, i) => i !== index
                  )
                  setValue('files', newValue)
                  forceUpdate()
                }}
              />
            </div>
          </div>
          <CTextInput
            label={languageContext.dictionary.title}
            name='title'
            control={control}
            maxLength={45}
            required
          />
          <CTextInput
            label={languageContext.dictionary.desc}
            name='description'
            type='textarea'
            control={control}
            rows={2}
            maxLength={2000}
            charCount
          />

          <div className='uk-grid'>
            <div className='uk-width-1-3'>
              <CSelectInput
                name='country'
                options={countriesOptions}
                control={control}
                onChange={(selectedOption) => {
                  setDisabledState(!selectedOption)
                  setDisabledCity(true)
                  setSelectedCountry(selectedOption?.value)
                  setValue('state', null)
                  setValue('city', null)
                }}
                label={languageContext.dictionary.country}
              />
            </div>
            <div className='uk-width-1-3'>
              <CSelectInput
                name='state'
                control={control}
                label={languageContext.dictionary.state}
                disabled={disabledState}
                options={statesOptions}
                onChange={(selectedOption) => {
                  setDisabledCity(!selectedOption)
                  setSelectedState(selectedOption?.value)
                  setValue('city', null)
                }}
                required
              />
            </div>
            <div className='uk-width-1-3'>
              <CSelectInput
                name='city'
                control={control}
                label={languageContext.dictionary.city}
                onChange={(e) => {
                  if (e) setDisabledLocation(false)
                  else setDisabledLocation(true)
                }}
                disabled={disabledCity}
                required
                options={citiesOptions}
              />
            </div>
            <div className='uk-width-3-5'>
              <CTextInput
                label={languageContext.dictionary.street}
                name='street'
                control={control}
                disabled={disabledLocation}
                deps={'city'}
                maxLength={100}
              />
            </div>
            <div className='uk-width-1-5'>
              <CTextInput
                label={languageContext.dictionary.externalNum}
                name='ext_number'
                control={control}
                disabled={disabledLocation}
                maxLength={5}
                validate={{
                  alphaDash: (v) =>
                    validationRules.alphaDash(v, languageContext.userLanguage)
                }}
              />
            </div>
            <div className='uk-width-1-5'>
              <CTextInput
                label={languageContext.dictionary.internalNum}
                name='int_number'
                control={control}
                disabled={disabledLocation}
                maxLength={7}
                validate={{
                  alphaDash: (v) =>
                    validationRules.alphaDash(v, languageContext.userLanguage)
                }}
              />
            </div>
            <div className='uk-width-3-5'>
              <CTextInput
                label={languageContext.dictionary.neighborhood}
                name='neightborhood'
                control={control}
                disabled={disabledLocation}
                maxLength={100}
              />
            </div>
            <div className='uk-width-2-5'>
              <CTextInput
                label={languageContext.dictionary.postalCode}
                name='postal_code'
                control={control}
                type='number'
                disabled={disabledLocation}
                maxLength={5}
                minLength={5}
                charCount
              />
            </div>
          </div>

          <CSelectInput
            name='services'
            control={control}
            label={languageContext.dictionary.service}
            isMulti
            options={servicesOptions}
            required
          />

          <div className='uk-grid'>
            <div className='uk-width-1'>
              <CTagsInput
                name='tags'
                control={control}
                classes={{
                  tagsContainer: 'cj-tags-container'
                }}
              />
            </div>
          </div>
          <div className='uk-flex uk-flex-between'>
            <Button
              onClick={() => {
                history.goBack()
              }}
              variant='contained'
              color='grey'
            >
              {languageContext.dictionary.cancel}
            </Button>
            <Button
              color='secondary'
              centered={true}
              type='submit'
              disabled={disableButton}
            >
              {languageContext.dictionary.save}
            </Button>
          </div>
        </form>
      </div>
      <PlansModal
        open={openPlansModal}
        onClose={() => {}}
        proPlan={proplan}
        showClose={false}
        creating={true}
      />
      <PlansModal open={openPlansModal} onClose={onClosePlansModal} />
    </StandardView>
  )
}
