import React, { useContext, useEffect } from 'react'
import _Auth from '../../context/auth'
import _Utils from '../../context/utils'
import './styles/style.css'

export default function PaymentTest() {
  const clientSecret = new URLSearchParams(window.location.search).get(
    'payment_intent_client_secret'
  )
  const { self } = useContext(_Auth.Context)
  const { notify } = useContext(_Utils.Context)

  useEffect(() => {
    const Init = async (clientSecret) => {
      try {
        let data = await fetch(
          process.env.REACT_APP_API_URL + 'stripe/validate',
          {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ clientSecret })
          }
        )
        data = await data.json()
        notify('Success', () => {
          //notification callback, send to the dashboard
          //history.push("/");
        })
        return data
      } catch (error) {
        console.log(error)
        return { error }
      }
    }

    if (!clientSecret) {
      console.log('something went wrong!')
    } else {
      if (self) {
        Init(clientSecret)
      }
    }
  }, [self])

  return <div className='diinco-success-stripe-payment-container'></div>
}
