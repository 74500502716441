import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
// import * as serviceWorker from "./serviceWorkerRegistration";

import { Tools } from './context/language/context'

ReactDOM.render(
  <React.StrictMode>
    <Tools>
      <App />
    </Tools>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// serviceWorker.register();
reportWebVitals()
