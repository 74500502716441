import React, { useContext, useState } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { Redirect } from 'react-router-dom'
import _Utils from '../../../../context/utils'
import Button from '../../../../components/Button'
import StandardView from '../../../../layouts/StandardView'
import { LanguageContext } from '../../../../context/language/context'

const SubscribeComponent = () => {
  const languageContext = useContext(LanguageContext)
  const [isLoading, setIsLoading] = React.useState(false)
  const { logo } = React.useContext(_Utils.Context)
  const [messages, _setMessages] = useState('')
  const [paymentIntent] = useState()

  // Initialize an instance of stripe.
  const stripe = useStripe()
  const elements = useElements()

  React.useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    )

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!')
          break
        case 'processing':
          setMessage('Your payment is processing.')
          break
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.')
          break
        default:
          setMessage('Something went wrong.')
          break
      }
    })
  }, [stripe])

  // helper for displaying status messages.
  const setMessage = (message) => {
    _setMessages(`${messages}\n\n${message}`)
  }

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return ''
  }

  // When the subscribe-form is submitted we do a few things:
  //
  //   1. Tokenize the payment method
  //   2. Create the subscription
  //   3. Handle any next actions like 3D Secure that are required for SCA.
  const handleSubmit = async (e) => {
    e.preventDefault()

    setIsLoading(true)

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: process.env.REACT_APP_URL + ''
      }
    })

    setIsLoading(false)

    if (error) {
      // show error and collect new card details.
      setMessage(error.message)
      return
    }
    // setPaymentIntent(paymentIntent);
  }

  if (paymentIntent && paymentIntent.status === 'succeeded') {
    return <Redirect to={{ pathname: '/account' }} />
  }

  return (
    <>
      <StandardView
        title={languageContext.dictionary.subscribe}
        links={[
          {
            link: '/subscription',
            title: languageContext.dictionary.plans
          }
        ]}
      >
        <form id='payment-form' onSubmit={handleSubmit}>
          <img
            src={logo.colour}
            className='diinco-payment-plan-img'
            alt='diinco logo'
          />

          <PaymentElement id='payment-element' />

          <Button
            disabled={isLoading || !stripe || !elements}
            id='submit'
            size='auto'
            className='diinco-payment-plan-button'
            type='submit'
          >
            <span id='button-text'>
              {isLoading ? (
                <div className='spinner' id='spinner'></div>
              ) : (
                'Paga ahora'
              )}
            </span>
          </Button>

          {messages && <div id='payment-message'>{messages}</div>}
        </form>
      </StandardView>
    </>
  )
}

export default SubscribeComponent
