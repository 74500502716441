import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext
} from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import {
  baseStyle,
  focusedStyle,
  acceptStyle,
  rejectStyle
} from './styles/style.js'

import imgIcon from './assets/upload-img.svg'
import './styles/style.css'

import { LanguageContext } from '../../context/language/context'
import { message } from '../../functions/validation.functions.js'
import { Controller } from 'react-hook-form'

export default function DropZoneCoverImage(props) {
  const {
    handleDrop,
    onChange,
    value,
    ContainerClasses,
    Brute,
    Image,
    ImageClasses,

    error,
    accept,
    maxFiles
  } = props

  const languageContext = useContext(LanguageContext)

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length) {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      )
      setNewImage(true)
      const reader = new FileReader()
      let file = acceptedFiles[0]

      reader.onloadend = () => {
        setNewImage(reader.result)
      }
      if (handleDrop) handleDrop(acceptedFiles)
      reader.readAsDataURL(file)

      if (onChange) onChange(acceptedFiles)
    }
  }, [])
  const [newImage, setNewImage] = useState(false)
  const IMAGE = useRef(null)
  const [files, setFiles] = useState([])
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isDragActive
  } = useDropzone({
    accept: accept,
    onDrop: onDrop,
    maxFiles: maxFiles
  })

  useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : ''),
      ...(isDragAccept ? acceptStyle : ''),
      ...(isDragReject ? rejectStyle : '')
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  useEffect(() => {
    if (value) setFiles(value)
  }, [value])

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks

    if (files && files.length) {
      files.forEach((file) => URL.revokeObjectURL(file.preview))
      const reader = new FileReader()
      reader.readAsDataURL(files[0])
      reader.onloadend = () => {
        setNewImage(reader.result)
      }
    } else {
      setNewImage(false)
    }
  }, [files])

  return (
    <div {...getRootProps()}>
      <div className={ContainerClasses}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <div className='diinco-custom-dropzone-div'>
            <img src={imgIcon} alt='' />
            <p> {languageContext.dictionary.dropHere} </p>
          </div>
        ) : !Brute ? (
          !newImage ? (
            <div className='diinco-custom-dropzone-div'>
              {Image ? (
                <img
                  src={process.env.REACT_APP_API_URL + Image}
                  className={ImageClasses}
                  alt=''
                />
              ) : (
                <>
                  <img src={imgIcon} alt='' />
                  <p> {languageContext.dictionary.clickHere} </p>
                </>
              )}
            </div>
          ) : props.button ? (
            <img src={imgIcon} alt='' />
          ) : (
            <img
              ref={IMAGE}
              src={newImage.toString()}
              className={ImageClasses}
              alt=''
            />
          )
        ) : (
          <div className='diinco-custom-dropzone-div'>
            {Image ? (
              <img src={Image} className={ImageClasses} alt='' />
            ) : (
              <>
                <img src={imgIcon} alt='' />
                <p> {languageContext.dictionary.clickHere} </p>
              </>
            )}
          </div>
        )}
      </div>
      {error && (
        <div className='cj-error-message-DZCI'>
          <span data-uk-icon='icon: warning; ratio: 0.6'></span> {error.message}
        </div>
      )}
    </div>
  )
}

DropZoneCoverImage.defaultProps = {
  Image: null,
  ImageClasses: '',
  ContainerClasses: '',
  Brute: false,
  handleDrop: () => {},
  accept: 'image/*',
  maxFiles: 0,

  theme: 'default',
  onChange: undefined,
  value: undefined,
  error: {},
  button: false
}

DropZoneCoverImage.propTypes = {
  Image: PropTypes.any,
  ImageClasses: PropTypes.string,
  ContainerClasses: PropTypes.string,
  handleDrop: PropTypes.func,
  Brute: PropTypes.bool,
  accept: PropTypes.string,
  maxFiles: PropTypes.number,

  theme: PropTypes.oneOf(['default', 'reverse', 'light', 'dark']),
  onChange: PropTypes.func,
  value: PropTypes.array,
  error: PropTypes.object,
  button: PropTypes.bool
}

export const CDropZoneCoverImage = ({
  name,
  control,

  shouldUnregister,

  required,
  validate,
  setValueAs,
  disabled,
  deps,
  onChange: onChangeFromProps,
  useCustomOnChange,

  ...rest
}) => {
  const languageContext = useContext(LanguageContext)

  var registerOptions = {
    required: {
      value: required,
      message: message(languageContext.userLanguage, 'required')
    },
    validate: validate,
    setValueAs: setValueAs,
    disabled: disabled,
    deps: deps
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={registerOptions}
      shouldUnregister={shouldUnregister}
      render={({
        field: { onChange, onBlur, value, name },
        fieldState: { error }
      }) => {
        return (
          <DropZoneCoverImage
            value={value}
            onChange={(e) => {
              onChange(e)
              if (onChangeFromProps) {
                if (useCustomOnChange) onChange(onChangeFromProps(e))
                else onChangeFromProps(e)
              }
            }}
            onBlur={onBlur}
            error={error}
            name={name}
            {...rest}
          />
        )
      }}
    />
  )
}

CDropZoneCoverImage.defaultProps = {
  shouldUnregister: false,

  useCustomOnChange: false,

  maxLength: null,
  minLength: null,
  required: false,
  onChange: () => {},

  error: {}
}

CDropZoneCoverImage.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  shouldUnregister: PropTypes.bool,

  useCustomOnChange: PropTypes.bool,

  Image: PropTypes.any,
  ImageClasses: PropTypes.string,
  ContainerClasses: PropTypes.string,
  handleDrop: PropTypes.func,
  Brute: PropTypes.bool,
  maxFiles: PropTypes.number,
  theme: PropTypes.oneOf(['default', 'reverse', 'light', 'dark']),

  validate: PropTypes.object,
  setValueAs: PropTypes.any,
  disabled: PropTypes.bool,
  deps: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

  onChange: PropTypes.func,
  required: PropTypes.bool,

  error: PropTypes.object
}
