import React, { useEffect, useState } from 'react'
import './snack.css'
import PropTypes from 'prop-types'

const delay = 3
export default function Snack(props) {
  const { message, status } = props
  const [show, setShow] = useState(false)

  useEffect(() => {
    let timer = setTimeout(() => setShow(true), delay * 1000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  function closeDialog() {
    setShow(true)
  }

  return !show ? (
    <div className={'notification ' + status}>
      <p>{message}</p>
      <span data-uk-icon='icon: close' onClick={closeDialog}></span>
    </div>
  ) : null
}

Snack.propTypes = {
  message: PropTypes.string,
  status: PropTypes.oneOf(['success', 'error', 'default'])
}
