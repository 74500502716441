import React, { useContext } from 'react'
import './styles/style.css'
import { LanguageContext } from '../../context/language/context'
import _Utils from '../../context/utils'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

import { _handleErrorMessage } from '../../functions/error.functions'
import { serialize } from '../../functions/validation.functions'
import Avatar from '../Avatar'

export default function Notification(props) {
  const { id, userImage, userlink, user, type, date, projectId, project } =
    props

  const history = useHistory()
  const { enqueueNotification } = useContext(_Utils.Context)
  const languageContext = useContext(LanguageContext)
  var lang
  if (languageContext.userLanguage === 'en') {
    lang = require('./languages/en.json')
  } else {
    lang = require('./languages/es.json')
  }

  //Stores difference
  let time = ''
  //Stores min, hours, days
  let timeDescription = ''

  function getDifferenceInDays(date1, date2) {
    const diffInMs = Math.abs(date2 - date1)
    return diffInMs / (1000 * 60 * 60 * 24)
  }

  function getDifferenceInHours(date1, date2) {
    const diffInMs = Math.abs(date2 - date1)
    return diffInMs / (1000 * 60 * 60)
  }

  function getDifferenceInMinutes(date1, date2) {
    const diffInMs = Math.abs(date2 - date1)
    return diffInMs / (1000 * 60)
  }

  if (date !== '') {
    let oldDate = new Date(date)
    let newDate = new Date()

    time = getDifferenceInMinutes(oldDate, newDate)
    timeDescription = lang.time.minutes

    if (time > 60) {
      time = getDifferenceInHours(oldDate, newDate)
      timeDescription = lang.time.hours
    }
    if (time > 24) {
      time = getDifferenceInDays(oldDate, newDate)
      timeDescription = lang.time.days
    }
  }

  function handleSubClick() {
    history.push('/Profile/' + userlink)
  }

  function handleSubClick2() {
    history.push('/Projects/' + projectId)
  }

  async function handleClick() {
    let data = {}
    try {
      const formData = serialize(data, {
        dotsForObjectNotation: true,
        noArrayNotation: true,
        noFilesWithArrayNotation: true
      })

      formData.append('notificationId', id)

      await fetch(process.env.REACT_APP_API_URL + 'notifications/', {
        method: 'PUT',
        credentials: 'include',
        mode: 'cors',
        headers: {
          Accept: 'multipart/form-data'
        },
        body: formData
      })

      if (projectId) {
        history.push('/Projects/' + projectId)
      } else {
        history.push('/Crm')
      }
    } catch (error) {
      enqueueNotification(
        _handleErrorMessage(languageContext?.userLanguage, error),
        'error'
      )
    }
  }

  let text = ''
  text = lang.notifications[type]
  let notification

  if (
    text.includes(lang.conditionals.User) &&
    text.includes(lang.conditionals.Project)
  ) {
    text = text.replace(lang.conditionals.User, '')
    text = text.replace(lang.conditionals.Project, '')

    notification = (
      <div className='dc-notification-description'>
        <Avatar src={userImage} alt={user} />
        <a
          className='dc-notification-sublink'
          onClick={(e) => {
            e.stopPropagation()
            handleSubClick()
          }}
        >
          &nbsp; {user} &nbsp;
        </a>
        {text}
        <a
          className='dc-notification-sublink'
          onClick={(e) => {
            e.stopPropagation()
            handleSubClick2()
          }}
        >
          &nbsp;{project}
        </a>
      </div>
    )
  } else if (text.includes(lang.conditionals.User)) {
    text = text.replace(lang.conditionals.User, '')
    notification = (
      <div className='dc-notification-description'>
        <Avatar src={userImage} alt={user} />
        <a
          className='dc-notification-sublink'
          onClick={(e) => {
            e.stopPropagation()
            handleSubClick()
          }}
        >
          &nbsp; {user} &nbsp;
        </a>
        {text}
      </div>
    )
  } else if (text.includes(lang.conditionals.Project)) {
    text = text.replace(lang.conditionals.Project, '')
    notification = (
      <p className='dc-notification-description'>
        {text}
        <a
          className='dc-notification-sublink'
          onClick={(e) => {
            e.stopPropagation()
            handleSubClick2()
          }}
        >
          {project}.
        </a>
      </p>
    )
  } else {
    notification = <p className='dc-notification-description'>{text}</p>
  }

  return (
    <div className='dc-notification-item-container' onClick={handleClick}>
      {notification}
      <p className='dc-aqsa'>
        {parseInt(time)} {timeDescription}
      </p>
    </div>
  )
}

Notification.defaultProps = {
  userImage: '',
  userlink: '',
  user: '',
  projectId: 0,
  project: '',
  date: new Date().toString()
}

Notification.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,

  userImage: PropTypes.string,
  userlink: PropTypes.string,
  user: PropTypes.string,
  date: PropTypes.string,
  projectId: PropTypes.number,
  project: PropTypes.string
}
